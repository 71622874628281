import React from 'react';

import { Stack, TextField } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';

import globalTheme from 'common/theme';
import DropDown from 'components/dropDown/DropDown';
import { DropDownValueType } from 'components/dropDown/type';
import Modal from 'components/modal/Modal';

type RenameModalProps<T, K> = {
  title: string | JSX.Element,
  label: string,
  selectOptions: T[],
  value: K,
  errors: FormikErrors<K>,
  touched: FormikTouched<K>,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  field: {
    select: {
      name: keyof K extends string ? keyof K : never,
      getLabel: (value: T) => string
      getValue: (value: T) => DropDownValueType
    },
    textField: {
      name: keyof K extends string ? keyof K : never,
    },
  },
  isLoading: boolean,
  onSubmit: Function,
  onCancel: Function,
  isOpen: boolean,
}

function RenameModal<T extends object, K>(props: RenameModalProps<T, K>) {
  const {
    title,
    label,
    value,
    selectOptions,
    touched,
    errors,
    field,
    onChange,
    isLoading,
    onSubmit,
    onCancel,
    isOpen,
  } = props;

  return (
    <Modal
      title={title}
      actionConfirm={onSubmit}
      actionCancel={onCancel}
      isOpen={isOpen}
      displayLoading={isLoading}
      isProcessLoading={isLoading}
      dataTestId="rename-modal"
    >
      <Stack
        direction="column"
        alignItems="center"
        p={1}
        spacing={1}
        sx={{ width: 1 }}
      >
        <DropDown<T>
          value={value[field.select.name] as string | number}
          options={selectOptions}
          name={field.select.name}
          onChange={(e) => onChange(e as React.ChangeEvent<HTMLInputElement>)}
          getOptionLabel={field.select.getLabel}
          getOptionValue={field.select.getValue}
          error={touched[field.select.name] && !!errors[field.select.name]}
          fullWidth
          size="small"
          label={label}
          data-testid="rename-modal-dropdown"
        />
        <TextField
          value={value[field.textField.name]}
          onChange={onChange}
          name={field.textField.name}
          label="Nouveau nom"
          data-testid="rename-modal-textfield-field"
          size="small"
          fullWidth
          slotProps={{
            input: {
              sx: { fontSize: globalTheme.fontSize.medium },
            },
          }}
          error={touched[field.textField.name] && !!errors[field.textField.name]}
          helperText={touched[field.textField.name] && errors[field.textField.name] as string}
        />
      </Stack>
    </Modal>
  );
}

export default RenameModal;
