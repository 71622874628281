import React from 'react';

import { Box, Paper } from '@mui/material';

import globalTheme from 'common/theme';
import formatThousands from 'utils/formatThousands';

interface TooltipProps {
  active?: boolean,
  payload?: any[],
  label?: string,
  tooltipLabel?: string
  percentLabel?: boolean,
}

function TooltipComponent(props: TooltipProps) {
  const {
    active = false,
    payload = [],
    label = '',
    tooltipLabel = '',
    percentLabel,
  } = props;

  const getLabel = (pl: any) => {
    if (percentLabel) {
      return `${pl.payload[`${pl.dataKey}-percentage`]}%`;
    }

    return formatThousands(pl.value);
  };

  if (active && payload && payload.length) {
    return (
      <Box
        component={Paper}
        sx={{
          padding: '0px 10px',
          textAlign: 'left',
          fontSize: globalTheme.fontSize.xsmall,
          border: 'none',
        }}
      >
        <Box>{label}</Box>
        {
          payload.map((pl) => (
            <Box
              key={`barTooltip-${pl.name}-${Math.random() * 10000}`}
              color={pl.color}
            >
              {`${tooltipLabel || pl.name} : ${getLabel(pl)}`}
            </Box>
          ))
        }
      </Box>
    );
  }
  return null;
}

export default TooltipComponent;
