import React, { useState } from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Button, Typography } from '@mui/material';

import Modal from 'components/modal/Modal';

type GlobalUpdateDataFileProps = {
  dataFileIds: number[],
  handleConfirm: Function,
  error: boolean,
}

export default function GlobalUpdateDataFile(props: GlobalUpdateDataFileProps): JSX.Element {
  const {
    dataFileIds,
    handleConfirm,
    error,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Box>
      <Button
        variant="contained"
        onClick={
          () => { setIsOpen(true); }
        }
        size="small"
        startIcon={<AccessTimeIcon />}
        data-testid="global-update-data-file-button"
      >
        Traiter
      </Button>
      <Modal
        title={(
          (
            error && (
              <Typography>
                Vous devez
                {' '}
                <Typography component="span" variant="bold">
                  qualifier
                </Typography>
                {' '}
                {dataFileIds.length > 1 ? 'les fichiers' : 'le fichier'}
              </Typography>
            )
          ) || (
            <Typography>
              <Typography component="span" variant="bold">Traiter</Typography>
              {' '}
              {dataFileIds.length > 1 ? `ces ${dataFileIds.length}` : 'ce'}
              {' '}
              fichier
              {dataFileIds.length > 1 ? 's' : ''}
            </Typography>
          )
        )}
        displayConfirm={!error}
        actionConfirm={
          () => {
            handleConfirm();
            setIsOpen(false);
          }
        }
        actionCancel={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        dataTestId="global-update-data-file-update-modal"
      />
    </Box>
  );
}
