import React from 'react';

import { Typography } from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';

export default function AgenceWam(): JSX.Element {
  useDocumentTitle('benhur | en direct de WAM');
  return (
    <Typography component="h1">Agence Wam Page</Typography>
  );
}
