import React, { useState } from 'react';

import { SelectChangeEvent, Stack, Typography } from '@mui/material';

import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';

import SearchVolumeComputedDropDown from './SearchVolumeComputedDropDown';

type UpdateSearchVolumeComputedModalProps = {
  currentValue?: string,
  handleConfirm: (newValue: string) => void,
  handleClose: Function,
  isOpen: boolean,
  isLoading: boolean,
}

function UpdateSearchVolumeComputedModal(props: UpdateSearchVolumeComputedModalProps) {
  const {
    currentValue = '',
    isOpen,
    handleConfirm,
    handleClose,
    isLoading,
  } = props;
  const [selectedValue, setSelectedValue] = useState(currentValue);
  const [error, setError] = useState(false);

  const handleChange = (e: SelectChangeEvent<string>) => {
    setSelectedValue(e.target.value);

    if (error) setError(false);
  };

  return (
    <Modal
      title={(
        <Typography>
          <Typography component="span" variant="bold">
            Paramétrer
            {' '}
          </Typography>
          la récupération de la demande mensuelle
        </Typography>
      )}
      actionConfirm={() => (selectedValue ? handleConfirm(selectedValue) : setError(true))}
      actionCancel={handleClose}
      isOpen={isOpen}
      displayLoading={isLoading}
      isProcessLoading={isLoading}
      dataTestId="update-search-volume-computed-modal"
    >
      <Stack
        direction="column"
        alignItems="center"
        width={1}
        padding={1}
        spacing={3}
        data-testid="update-search-volume-computed-form"
      >
        <Typography sx={{ fontSize: globalTheme.fontSize.xsmall }}>
          Attention cette option entraîne
          {' '}
          <Typography component="span" variant="bold">
            un surcoût !
          </Typography>
        </Typography>
        <SearchVolumeComputedDropDown
          value={selectedValue}
          onChange={handleChange}
          error={error}
        />
      </Stack>
    </Modal>
  );
}

export default UpdateSearchVolumeComputedModal;
