import React from 'react';

import {
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';

import UsersDatagridActions from './UsersDatagridActions';
import USER_TYPES from '../CONSTANTS';
import UserAvatarWithLabel from '../infos/AvatarWithLabelView';
import UserLastLogin from '../infos/LastLoginView';
import { UserType } from '../types';
import UserUpdateStatusChip from '../update/UpdateStatusChip';
import UsersUtils from '../UsersUtils';

const createColumns = (classes: any, isExternal: boolean): GridColDef<UserType>[] => ([
  {
    field: 'active',
    headerClassName: 'super-app-theme--header',
    renderHeader: () => (
      <StyledHeader label="Statut" />
    ),
    renderCell: (params) => (
      <UserUpdateStatusChip userItem={params.row} />
    ),
    flex: 0.3,
    sortComparator: (a) => (a ? 1 : -1),
  },
  {
    field: 'first_name',
    renderHeader: () => (
      <StyledHeader label="Utilisateur" />
    ),
    renderCell: (params) => (
      <UserAvatarWithLabel userItem={params.row} isExternal={isExternal} />
    ),
    flex: 1,
  },
  {
    field: 'email',
    renderHeader: () => (
      <StyledHeader label="Email" />
    ),
    renderCell: (params) => (
      <Typography className={classes.labelsClassic}>
        {params.value}
      </Typography>
    ),
    flex: 1,
  },
  {
    field: 'user_type_id',
    renderHeader: () => (
      <StyledHeader label="Rôle" />
    ),
    renderCell: (params) => (
      <Typography className={classes.labelsClassic}>
        {
          params.row.user_type_id === USER_TYPES.internal.id
            ? ''
            : USER_TYPES[
              params.row.user_type.label as keyof typeof USER_TYPES
            ].label
        }
      </Typography>
    ),
    flex: 0.5,
  },
  {
    field: 'last_login',
    renderHeader: () => (
      <StyledHeader label="Dernière connexion" />
    ),
    renderCell: (params) => (
      <UserLastLogin userItem={params.row} />
    ),
    flex: 1,
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    flex: 1.5,
    align: 'right',
    renderCell: (params) => (
      <UsersDatagridActions
        user={params.row}
        isExternal={isExternal}
        showEditUser={isExternal || UsersUtils.isUserPP(params.row.email)}
      />
    ),
  },
]);

export default createColumns;
