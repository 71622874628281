import React from 'react';

import { SelectInputProps } from '@mui/material/Select/SelectInput';

import globalTheme from 'common/theme';
import DropDown from 'components/dropDown/DropDown';

import { DATA_FILE_STATUS_WITH_LABEL } from '../CONSTANT';

type DataFileStatusDropDownProps = {
  value: number | number[],
  onChange: SelectInputProps<any>['onChange'],
  name?: string,
  multiple?: boolean,
  emptyOption?: string
  size?: 'small' | 'medium',
  margin?: number,
  height?: string,
  width?: string,
  fontSize?: keyof typeof globalTheme.fontSize,
  label?: string,
  className?: {
    select?: string,
  },
  displayEmptyInField?: boolean,
  displayEmptyInList?: boolean,
};

function DataFileStatusDropDown(props: DataFileStatusDropDownProps) {
  const {
    value,
    onChange,
    name,
    multiple = false,
    emptyOption,
    size,
    margin,
    fontSize,
    height,
    width,
    label,
    className,
    displayEmptyInField,
    displayEmptyInList,
  } = props;

  return (
    <DropDown<typeof DATA_FILE_STATUS_WITH_LABEL[number]>
      name={name ?? 'actors'}
      fullWidth
      value={value}
      options={DATA_FILE_STATUS_WITH_LABEL.slice().sort((a, b) => a.label.localeCompare(b.label))}
      label={label}
      onChange={onChange}
      getOptionLabel={(status) => status.label}
      getOptionValue={(status) => status.id}
      multiple={multiple}
      emptyOption={emptyOption}
      size={size}
      fontSize={fontSize}
      margin={margin}
      height={height}
      width={width}
      className={className}
      displayEmptyInField={displayEmptyInField}
      displayEmptyInList={displayEmptyInList}
      data-testid="select-datafile-status-drop-down"
    />
  );
}

export default DataFileStatusDropDown;
