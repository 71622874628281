import React from 'react';

import { SelectInputProps } from '@mui/material/Select/SelectInput';

import DropDown from 'components/dropDown/DropDown';
import { devicesList } from 'features/deviceType/CONSTANTS';

type SearchEngineDropDownProps = {
  value: number,
  onChange: SelectInputProps<any>['onChange'],
  name?: string,
  size?: 'small' | 'medium',
}

const options = Object.values(devicesList);

function DeviceDropDown(props: SearchEngineDropDownProps) {
  const {
    value,
    onChange,
    name,
    size,
  } = props;

  return (
    <DropDown
      size={size}
      label="Appareil"
      name={name}
      onChange={onChange}
      options={options}
      getOptionLabel={(se) => se.label}
      getOptionValue={(se) => se.id}
      value={value}
    />
  );
}

export default DeviceDropDown;
