export const DEFAULT_SA_ID = 0;
export const DEFAULT_SITE_COUNT = 10;
export const DEFAULT_RANK_MIN = 1;
export const DEFAULT_RANK_MAX = 20;
export const DEFAULT_VOL_MIN = 0;
export const DEFAULT_VOL_MAX = -1;

export const SI_ALL = { id: 0, label: 'Toutes' };
export const SI_NC = { id: -1, label: 'n.c.' };

export const SITECOUNT_LIST = [5, 10, 15, 20];
