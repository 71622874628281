import { DataFileFilters, DataFileType } from '../types';

const filterDataFilesList = (filters: DataFileFilters, list: DataFileType[]) => {
  let filteredList = list;

  Object.entries(filters).forEach(([filterKey, value]) => {
    const filter = filterKey as keyof typeof filters;

    if (value === -1) {
      filteredList = filteredList.filter((df) => !!df[filter] === false);
    } else {
      filteredList = filteredList.filter((df) => (
        value
          ? df[filter] === filters[filter]
          : true
      ));
    }
  });
  return filteredList;
};

export default filterDataFilesList;
