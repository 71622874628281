import React, { useEffect, useState } from 'react';

import {
  Stack,
  Select,
  Typography,
  MenuItem,
  Checkbox,
  styled,
  StackProps,
  FormControl,
  InputLabel,
  Button,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';

import { useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import { ActorType } from 'features/actors/types';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';
import { METRIC } from 'features/dataviz/CONSTANTS';
import generateChartKey from 'features/dataviz/utils/chartKey';
import { DEVICE_TYPE } from 'features/deviceType/CONSTANTS';
import KeywordAutocomplete from 'features/keywords/components/KeywordAutocomplete';
import { SerpAnalysisKeyword } from 'features/keywords/types';
import { KpiViewType } from 'features/kpiView/types';
import RankingTypesDropDown from 'features/rankingTypes/components/RankingTypesDropDown';
import BENCHMARK_ONLY_RANKING_TYPES from 'features/rankingTypes/CONSTANTS';
import { RANK_SLICES } from 'features/rankSlice/CONSTANT';
import { Analysis, RankingEvolutionFilters } from 'features/serpAnalysis/types';
import AvailableDataDate from 'features/serpAnalysisScrapping/components/AvailableDataDate';

import {
  getActorNameFromActorId,
  getIntentLabelFromIntentId,
  getSliceNameFromSliceId,
} from './utils/RankingFilters';

const PREFIX = 'RankingFilters';

const classes = {
  root: `${PREFIX}-root`,
  form: `${PREFIX}-form`,
  columnFilter: `${PREFIX}-columnFilter`,
  fullColumn: `${PREFIX}-fullColumn`,
  select: `${PREFIX}-select`,
  inputSelectMultiple: `${PREFIX}-inputSelectMultiple`,
  mediumSelect: `${PREFIX}-mediumSelect`,
  filterLabels: `${PREFIX}-filterLabels`,
  inputLabel: `${PREFIX}-inputLabel`,
  datePicker: `${PREFIX}-datePicker`,
  submitBtn: `${PREFIX}-submitBtn`,
  submitBtnActive: `${PREFIX}-submitBtnActive`,
  radioLabel: `${PREFIX}-radioLabel`,
  radioGroup: `${PREFIX}-radioGroup`,
  submitCtn: `${PREFIX}-submit-ctn`,
  footerText: `${PREFIX}-footerText`,
  adjustRadio: `${PREFIX}-adjustRadio`,
  adjustRadio2: `${PREFIX}-adjustRadio2`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: '300px',
    width: '95%',
    margin: '0 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  [`& .${classes.select}`]: {
    height: '2rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  [`& .${classes.inputSelectMultiple}`]: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  [`& .${classes.mediumSelect}`]: {
    height: '2rem',
    fontSize: theme.fontSize.xsmall,
  },

  [`& .${classes.form}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },

  [`& .${classes.columnFilter}`]: {
    flex: 1,
  },

  [`& .${classes.fullColumn}`]: {
    width: '100%',
    marginTop: theme.spacing(2),
  },

  [`& .${classes.filterLabels}`]: {
    fontSize: theme.fontSize.xsmall,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginRight: '.5rem',
    marginLeft: '1rem',
  },

  [`& .${classes.datePicker}`]: {
    fontSize: theme.fontSize.xsmall,
  },

  [`& .${classes.submitBtn}`]: {
    marginLeft: '1rem',
  },

  [`& .${classes.submitBtnActive}`]: {
    marginLeft: '1rem',
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.secondary.main,
  },

  [`& .${classes.radioLabel}`]: {
    fontSize: theme.fontSize.xsmall,
    marginRight: '.5rem',
  },

  [`& .${classes.radioGroup}`]: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '300px',
    height: '2rem',
  },

  [`& .${classes.submitCtn}`]: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  [`& .${classes.footerText}`]: {
    fontSize: theme.fontSize.xsmall,
    color: theme.palette.info.main,
  },
  [`& .${classes.adjustRadio}`]: {
    marginRight: '11.3%',
  },
  [`& .${classes.adjustRadio2}`]: {
    marginRight: '15.7%',
  },
}));

type RankingFiltersProps = {
  filters: RankingEvolutionFilters,
  actors: ActorType[],
  handleSubmit: Function,
  intentList: Array<{
    id: number,
    label: string,
  }>,
  analysis: Analysis | undefined,
  selectedKpiView?: KpiViewType,
}

let previousKpiViewId: undefined | number;

function RankingFilters(props: RankingFiltersProps) {
  const {
    filters,
    actors,
    handleSubmit,
    intentList,
    analysis,
    selectedKpiView,
  } = props;

  const [keywordInputValue, setKeywordInputValue] = useState('');
  const [formData, setFormData] = useState<RankingEvolutionFilters>({
    type: 'rankingEvolutionFilters',
    datavizMode: filters.datavizMode,
    rankingTypeId: filters.rankingTypeId,
    actorIds: filters.actorIds || [],
    periodeBegin: filters.periodeBegin,
    periodeEnd: filters.periodeEnd,
    intentionIds: filters.intentionIds,
    timeSeries: filters.timeSeries,
    rankSliceIds: filters.rankSliceIds,
    deviceTypeId: filters.deviceTypeId,
    keywordIds: filters.keywordIds,
  });

  useEffect(() => {
    setFormData({
      type: 'rankingEvolutionFilters',
      datavizMode: filters.datavizMode,
      rankingTypeId: filters.rankingTypeId,
      actorIds: filters.actorIds || [],
      periodeBegin: filters.periodeBegin,
      periodeEnd: filters.periodeEnd,
      intentionIds: filters.intentionIds,
      timeSeries: filters.timeSeries,
      rankSliceIds: filters.rankSliceIds,
      deviceTypeId: filters.deviceTypeId,
      keywordIds: filters.keywordIds,
    });

    if (selectedKpiView?.id !== previousKpiViewId) {
      setKeywordInputValue('');
    }

    previousKpiViewId = selectedKpiView?.id;
  }, [filters]);

  const handleChange = (e: any) => {
    const { target: { name, value } } = e;
    let newPeriodEnd = moment(formData.periodeEnd);
    const newPeriodBegin = moment(formData.periodeBegin);

    if (
      name === 'timeSeries'
      && value === 'day'
      && newPeriodEnd.diff(newPeriodBegin, 'months') >= 3
    ) {
      newPeriodEnd = newPeriodBegin.clone().endOf('month');
    }

    setFormData(
      {
        ...formData,
        [name]: value,
        periodeEnd: newPeriodEnd.format(),
      },
    );
  };

  const handleChangeSelectMultiple = (e: any, selected: Array<any>, allOpt: Array<any>) => {
    const { target: { name, value } } = e;
    if (value[value.length - 1] === 'all') {
      setFormData(
        {
          ...formData,
          [name]: selected.length === allOpt.length ? [] : allOpt,
        },
      );
      return;
    }
    setFormData(
      {
        ...formData,
        [name]: value,
      },
    );
  };

  const handleChangeDatePicker = (name: string, value: Moment | null) => {
    let newPeriodeBegin = moment(formData.periodeBegin);
    let newPeriodeEnd = moment(formData.periodeEnd);
    if (
      name === 'periodeBegin'
      && value !== null
    ) {
      newPeriodeBegin = value;
    } else if (
      name === 'periodeEnd'
      && value !== null
    ) {
      newPeriodeEnd = value;
    }

    if (newPeriodeBegin > newPeriodeEnd) {
      newPeriodeEnd = newPeriodeBegin.clone().endOf('month');
    } else if (
      formData.timeSeries === 'day'
      && newPeriodeEnd.diff(newPeriodeBegin, 'months') >= 3
    ) {
      newPeriodeEnd = newPeriodeBegin.clone().add(3, 'month');
    }

    setFormData(
      {
        ...formData,
        periodeBegin: newPeriodeBegin.format(),
        periodeEnd: newPeriodeEnd.format(),
      },
    );
  };

  const handleChangeAutocomplete = (
    name: string,
    value: (SerpAnalysisKeyword | string)[],
    selected: SerpAnalysisKeyword[],
    allOpt: SerpAnalysisKeyword[],
    filter: string,
  ) => {
    if (typeof value[value.length - 1] === 'string') {
      if (filter) {
        const filteredOpt = allOpt.filter(
          (keyword) => keyword.keyword.label.toLowerCase().includes(filter.toLowerCase()),
        );
        const filterOptId = filteredOpt.map((keyword) => keyword.keyword_id);
        const currentSelectedFilterOpt = selected.filter(
          (keyword) => filterOptId.includes(keyword.keyword_id),
        );
        // This mean all filtered options are already selected so we de-select them
        if (currentSelectedFilterOpt.length === filteredOpt.length) {
          setFormData(
            {
              ...formData,
              [name]: selected
                .filter((keyword) => !filterOptId.includes(keyword.keyword_id))
                .map((keyword) => keyword.keyword_id),
            },
          );
        } else { // And this mean filtered options are not all selected so we select them
          setFormData(
            {
              ...formData,
              [name]: selected
                .map((keyword) => keyword.keyword_id)
                .concat(
                  filterOptId.filter(
                    (optionId) => !selected
                      .map((keyword) => keyword.keyword_id)
                      .includes(optionId),
                  ),
                ),
            },
          );
        }
      } else {
        setFormData(
          {
            ...formData,
            [name]: selected.length === allOpt.length
              ? []
              : allOpt.map((keyword) => keyword.keyword_id),
          },
        );
      }
    } else {
      setFormData({
        ...formData,
        [name]: value.filter(
          (val) => typeof val !== 'string',
        ).map((val) => (
          typeof val === 'string'
            ? 'all'
            : val.keyword_id
        )),
      });
    }
  };

  const chartKey = generateChartKey(
    METRIC.count_with_filters_group_by_actors_rank.label,
    [],
    (analysis?.id || 0),
    '',
    undefined,
    filters,
  );

  const chart = useAppSelector((state) => state.dataviz.charts[chartKey]);
  return (
    <StyledStack direction="row" className={classes.root}>
      <TitleComponent title="Configuration de la vue" capitalLetter />
      <Box
        component="form"
        onSubmit={(e) => handleSubmit(e, formData)}
        className={classes.form}
      >
        <Stack spacing={2}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="normal"
          >
            <FormControl className={classes.columnFilter}>
              <KeywordAutocomplete
                inputValue={keywordInputValue}
                onInputValueChanged={setKeywordInputValue}
                handleChange={
                  (
                    e: any,
                    value: any,
                    selected: any[],
                    allOpt: any[],
                    filter: string,
                  ) => {
                    handleChangeAutocomplete(
                      'keywordIds',
                      value,
                      selected,
                      allOpt,
                      filter,
                    );
                  }
                }
                name="keywordIds"
                label="Mots clés"
                serpAnalysisId={analysis ? analysis.id : 0}
                keywordIdsSelected={formData.keywordIds}
              />
            </FormControl>
            <FormControl className={classes.columnFilter}>
              <InputLabel id="select-actors-label" shrink>Acteurs</InputLabel>
              <Select
                multiple
                value={formData.actorIds}
                onChange={
                  (e) => handleChangeSelectMultiple(
                    e,
                    formData.actorIds ?? [],
                    actors.map((act) => act.id),
                  )
                }
                size="small"
                labelId="select-actors-label"
                label="Acteurs"
                name="actorIds"
                data-testid="select-actors-multiple"
                inputProps={{
                  'data-testid': 'select-actors-multiple-input',
                }}
                notched
                className={classes.select}
                renderValue={
                  (selected) => (
                    <Typography
                      variant="body2"
                      className={classes.inputSelectMultiple}
                      sx={{ fontSize: globalTheme.fontSize.xsmall, paddingTop: '.2rem' }}
                    >
                      {getActorNameFromActorId(selected, actors).join(', ')}
                    </Typography>
                  )
                }
              >
                <MenuItem
                  key={-1}
                  dense
                  value="all"
                  disableGutters
                  sx={{ height: '1rem' }}
                >
                  <Checkbox
                    checked={
                      formData.actorIds?.length === actors.length
                    }
                    indeterminate={
                      formData.actorIds
                      && formData.actorIds.length > 0
                      && formData.actorIds.length < actors.length
                    }
                    size="small"
                  />
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ fontSize: globalTheme.fontSize.xsmall }}
                  >
                    Tous / Aucun
                  </Typography>
                </MenuItem>
                {actors.map((actor) => (
                  <MenuItem
                    key={actor.id}
                    value={actor.id}
                    dense
                    disableGutters
                    sx={{ height: '1rem' }}
                  >
                    <Checkbox checked={formData.actorIds?.includes(actor.id || 0)} size="small" />
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{ fontSize: globalTheme.fontSize.xsmall }}
                    >
                      {actor.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            spacing={2}
            direction="row"
          >
            <FormControl className={classes.columnFilter}>
              <InputLabel
                id="select-intention-label"
                size="small"
                className={classes.inputLabel}
                shrink
              >
                Intentions de recherche
              </InputLabel>
              <Select
                value={
                  formData.intentionIds
                }
                onChange={
                  (e) => handleChangeSelectMultiple(
                    e,
                    formData.intentionIds,
                    intentList.map((int) => int.id),
                  )
                }
                labelId="select-intention-label"
                label="Intentions de recherche"
                name="intentionIds"
                data-testid="select-intentList-multiple"
                notched
                className={classes.select}
                inputProps={{ sx: { paddingTop: '.8rem' } }}
                multiple
                renderValue={
                  (selected) => (
                    <Typography
                      variant="body2"
                      className={classes.inputSelectMultiple}
                      sx={{ fontSize: globalTheme.fontSize.xsmall, paddingTop: '.2rem' }}
                    >
                      {getIntentLabelFromIntentId(selected, intentList).join(', ')}
                    </Typography>
                  )
                }
              >
                <MenuItem
                  key={-1}
                  dense
                  value="all"
                  disableGutters
                  sx={{ height: '1rem' }}
                >
                  <Checkbox
                    checked={
                      formData.intentionIds.length === intentList.length
                    }
                    indeterminate={
                      formData.intentionIds.length > 0
                      && formData.intentionIds.length < intentList.length
                    }
                    size="small"
                  />
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ fontSize: globalTheme.fontSize.xsmall }}
                  >
                    Toutes / Aucune
                  </Typography>
                </MenuItem>
                {intentList.map((intent) => (
                  <MenuItem
                    value={intent.id}
                    key={intent.id}
                    dense
                    sx={{ height: '1rem' }}
                    disableGutters
                  >
                    <Checkbox
                      checked={
                        formData.intentionIds?.includes(intent.id)
                      }
                      size="small"
                    />
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{ fontSize: globalTheme.fontSize.xsmall }}
                    >
                      {intent.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.columnFilter}>
              <InputLabel id="select-rankSlice-label" shrink>Rang(s)</InputLabel>
              <Select
                multiple
                value={formData.rankSliceIds}
                onChange={
                  (e) => handleChangeSelectMultiple(
                    e,
                    formData.rankSliceIds,
                    RANK_SLICES.map((rs) => rs.id),
                  )
                }
                size="small"
                labelId="select-rankSlice-label"
                label="Rang(s)"
                name="rankSliceIds"
                data-testid="select-rankSlices-multiple"
                notched
                className={classes.mediumSelect}
                renderValue={
                  (selected) => (
                    <Typography
                      variant="body2"
                      className={classes.inputSelectMultiple}
                      sx={{ fontSize: globalTheme.fontSize.xsmall }}
                    >
                      {getSliceNameFromSliceId(selected, RANK_SLICES).join(', ')}
                    </Typography>
                  )
                }
              >
                <MenuItem
                  key={-1}
                  dense
                  value="all"
                  disableGutters
                  sx={{ height: '1rem' }}
                >
                  <Checkbox
                    checked={
                      formData.rankSliceIds.length === RANK_SLICES.length
                    }
                    indeterminate={
                      formData.rankSliceIds.length > 0
                      && formData.rankSliceIds.length < RANK_SLICES.length
                    }
                    size="small"
                  />
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ fontSize: globalTheme.fontSize.xsmall }}
                  >
                    Tous / Aucun
                  </Typography>
                </MenuItem>
                {RANK_SLICES.map((rankSlice) => (
                  <MenuItem
                    key={rankSlice.id}
                    value={rankSlice.id}
                    dense
                    disableGutters
                    sx={{ height: '1rem' }}
                  >
                    <Checkbox
                      checked={formData.rankSliceIds?.includes(rankSlice.id)}
                      size="small"
                    />
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{ fontSize: globalTheme.fontSize.xsmall }}
                    >
                      {rankSlice.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            spacing={2}
            direction="row"
          >
            <FormControl className={classes.columnFilter}>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={moment.locale('fr')}
              >
                <DatePicker
                  className={classes.datePicker}
                  label="Début"
                  value={moment(formData.periodeBegin)}
                  onChange={(val) => handleChangeDatePicker('periodeBegin', val)}
                  format="DD/MM/YYYY"
                  maxDate={moment()}
                  data-testid="date-picker-periode-begin"
                  slotProps={{
                    textField: {
                      size: 'small',
                      label: 'Début',
                      error: false,
                      InputProps: {
                        sx: {
                          fontSize: globalTheme.fontSize.xsmall,
                          height: '2rem',
                        },
                      },
                      value: moment(formData.periodeBegin),
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl className={classes.columnFilter}>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={moment.locale('fr')}
              >
                <DatePicker
                  className={classes.datePicker}
                  label="Fin"
                  value={moment(formData.periodeEnd)}
                  onChange={(val) => handleChangeDatePicker('periodeEnd', val)}
                  format="DD/MM/YYYY"
                  minDate={moment(formData.periodeBegin)}
                  maxDate={
                    formData.timeSeries === 'day'
                    && moment(formData.periodeBegin).add(3, 'month') < moment().endOf('month')
                      ? moment(formData.periodeBegin).add(3, 'month')
                      : moment().endOf('month')
                  }
                  data-testid="date-picker-periode-end"
                  slotProps={{
                    textField: {
                      size: 'small',
                      label: 'Fin',
                      error: false,
                      InputProps: {
                        sx: {
                          fontSize: globalTheme.fontSize.xsmall,
                          height: '2rem',
                        },
                      },
                      value: moment(formData.periodeEnd),
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Stack>
        </Stack>
        <Box>
          <Box className={classes.fullColumn}>
            <RankingTypesDropDown
              value={formData.rankingTypeId}
              name="rankingTypeId"
              onChange={handleChange}
              label="Type de résultat"
              size="small"
              height="2rem"
              fontSize="xsmall"
              optionsFilter={BENCHMARK_ONLY_RANKING_TYPES} // Filter out the options you don't want
            />
          </Box>
          <FormControl>
            <RadioGroup
              defaultValue={
                analysis?.device_type_id === DEVICE_TYPE.DESKTOP
                  ? DEVICE_TYPE.DESKTOP
                  : DEVICE_TYPE.MOBILE
                }
              value={formData.deviceTypeId}
              name="deviceTypeId"
              onChange={handleChange}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value={DEVICE_TYPE.MOBILE}
                className={classes.adjustRadio}
                control={<Radio />}
                label={<Typography className={classes.radioLabel}>SERP mobile</Typography>}
                disabled={analysis?.device_type_id === DEVICE_TYPE.DESKTOP}
              />
              <FormControlLabel
                value={DEVICE_TYPE.DESKTOP}
                control={<Radio />}
                label={<Typography className={classes.radioLabel}>SERP desktop</Typography>}
                disabled={analysis?.device_type_id === DEVICE_TYPE.MOBILE}
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <RadioGroup
              defaultValue="day"
              value={formData.timeSeries}
              name="timeSeries"
              onChange={handleChange}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value="day"
                control={<Radio />}
                label={<Typography className={classes.radioLabel}>Vue quotidienne</Typography>}
              />
              <FormControlLabel
                value="month"
                control={<Radio />}
                label={<Typography className={classes.radioLabel}>Vue mensuelle</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box className={classes.submitCtn}>
          <AvailableDataDate idAnalysis={analysis?.id || 0} />
          <Button
            size="small"
            variant="contained"
            type="submit"
            className={chart?.isLoading ? classes.submitBtnActive : classes.submitBtn}
            disabled={chart?.isLoading}
          >
            Filtrer
          </Button>
        </Box>
      </Box>
    </StyledStack>
  );
}

export default RankingFilters;
