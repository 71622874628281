import React from 'react';

import {
  Typography,
  Box,
  TextField,
  styled,
  BoxProps,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import {
  FormikErrors,
  FormikHandlers,
  FormikTouched,
} from 'formik';
import { MuiColorInput } from 'mui-color-input';

import { MODAL_MODE } from '../CONSTANTS';
import { ActorForm } from '../types';
import getActorColor from '../utils/format';

const PREFIX = 'ActorModal';

const classes = {
  core: `${PREFIX}-core`,
  text: `${PREFIX}-text`,
  text_link_like: `${PREFIX}-text-link-like`,
  bold: `${PREFIX}-bold`,
  input: `${PREFIX}-input`,
  inputContent: `${PREFIX}-inputContent`,
  checkContainer: `${PREFIX}-checkContainer`,
  colorPickerInput: `${PREFIX}-colorPickerInput`,
};

const StyledBox = styled(Box)<BoxProps<'form'>>(({ theme }) => ({
  [`&.${classes.core}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    paddingTop: 0,
    alignItems: 'center',
    width: '100%',
  },
  [`& .${classes.text}`]: {
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.bold}`]: {
    fontWeight: 'bold',
  },
  [`& .${classes.input}`]: {
    width: '100%',
  },
  [`& .${classes.inputContent}`]: {
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.checkContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  [`& .${classes.text_link_like}`]: {
    color: theme.palette.primary.light,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'inline',
    margin: '0px',
    padding: '0px',
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.text_link_like}:hover`]: {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    textDecorationThickness: '2px',
  },
  [`& .${classes.colorPickerInput}`]: {
    fontSize: theme.fontSize.medium,
  },
}));

type ActorModalProps = {
  mode: number,
  values: ActorForm,
  touched: FormikTouched<ActorForm>,
  errors: FormikErrors<ActorForm>,
  onChange: FormikHandlers['handleChange'],
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ActorForm>>,
  onBlur?: any,
  patternCount?: number,
}

export default function ActorModal(props: ActorModalProps): JSX.Element {
  const {
    mode,
    values,
    touched,
    errors,
    onBlur,
    onChange,
    setFieldValue,
    patternCount = 0,
  } = props;

  return (
    <StyledBox
      className={classes.core}
    >
      <TextField
        margin="normal"
        id="name"
        label="Nom de l'acteur"
        type="text"
        name="name"
        variant="outlined"
        size="small"
        className={classes.input}
        slotProps={{
          input: {
            className: classes.inputContent,
            inputProps: { 'data-testid': 'actor-modal-name-field' },
          },
        }}
        value={values.name}
        onChange={onChange}
        onBlur={onBlur}
        disabled={[MODAL_MODE.add, MODAL_MODE.update].includes(mode)}
        error={
          touched.name
          && Boolean(errors.name)
        }
        helperText={
          touched.name
          && errors.name
        }
      />
      <TextField
        margin="normal"
        id="domain"
        label="Nom de domaine"
        type="text"
        name="domain"
        variant="outlined"
        size="small"
        className={classes.input}
        slotProps={{
          input: {
            className: classes.inputContent,
            inputProps: { 'data-testid': 'actor-modal-domain-field' },
          },
        }}
        value={values.domain}
        onChange={onChange}
        onBlur={onBlur}
        error={
          touched.domain
          && Boolean(errors.domain)
        }
        helperText={
          touched.domain
          && errors.domain
        }
      />
      <Box className={classes.checkContainer}>
        <FormControlLabel
          control={(
            <Checkbox
              name="subdomains"
              checked={values.subdomains}
              onChange={onChange}
              onBlur={onBlur}
              data-testid="actor-modal-subdomain-checkbox"
            />
          )}
          label={(
            <Typography variant="body2">
              Prendre en compte les sous-domaines
              {' '}
              <Typography variant="body2" component="span" className={classes.bold}>
                {
                values.domain.match(/https?:\/\//)
                  ? values.domain.replace(/https?:\/\//, '(.*).')
                  : `(.*).${values.domain}`
                }
              </Typography>
            </Typography>
          )}
        />
        {
          ((mode === MODAL_MODE.create) && (
            <FormControlLabel
              control={<Checkbox />}
              label={(
                <Typography variant="body2">
                  Cet acteur est le
                  {' '}
                  <Typography variant="body2" component="span" className={classes.bold}>
                    CLIENT
                  </Typography>
                </Typography>
              )}
              name="isClient"
              checked={values.isClient}
              onChange={onChange}
              onBlur={onBlur}
              data-testid="actor-modal-isclient-checkbox"
            />
          )) || (
            <FormControlLabel
              control={<Checkbox />}
              label={
                <Typography variant="body2">Gérer cette règle comme une exclusion</Typography>
              }
              name="exclude"
              checked={values.exclude}
              onChange={onChange}
              onBlur={onBlur}
              disabled={mode === MODAL_MODE.add ? patternCount < 1 : patternCount <= 1}
              title={
                mode !== MODAL_MODE.add && patternCount <= 1
                  ? 'L’exclusion n’est pas permise '
                      + 'lorsqu’un acteur ne possède qu’une seule règle.'
                  : ''
              }
              data-testid="actor-modal-exclude-checkbox"
            />
          )
        }
      </Box>
      {
        mode !== MODAL_MODE.add
        && (
          <Box
            alignItems="start"
            sx={{ width: 1 }}
            data-testid="actor-modal-color-picker"
          >
            <MuiColorInput
              value={getActorColor(values.isClient, values.datavizColor)}
              onChange={(value) => {
                setFieldValue('datavizColor', value, true);
                setFieldValue('datavizColorDefault', false);
              }}
              fallbackValue="#000000"
              format="hex"
              name="datavizColor"
              isAlphaHidden
              fullWidth
              size="small"
              label="Couleur"
              margin="normal"
              slotProps={{
                input: {
                  className: classes.colorPickerInput,
                  inputProps: { 'data-testid': 'actor-modal-color-input' },
                },
              }}
              error={
                touched.datavizColor
                && Boolean(errors.datavizColor)
              }
              helperText={
                touched.datavizColor
                && errors.datavizColor
              }
            />
            <Button
              variant="text"
              disableRipple
              className={classes.text_link_like}
              onClick={() => {
                setFieldValue('datavizColor', undefined, true);
                setFieldValue('datavizColorDefault', true);
              }}
              data-testid="actor-modal-default-color-button"
            >
              Couleur par défaut
            </Button>
          </Box>
        )
      }
    </StyledBox>
  );
}
