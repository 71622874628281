/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';

import { useAppSelector } from 'common/reduxHooks';
import Spinner from 'components/loading/Spinner';
import { selectProjectStateById } from 'features/projects/state/slice';
import { Project } from 'features/projects/types';
import { splitIdName } from 'utils/urlManipulation';

function WithProject({ Component }: {
  Component: React.ComponentType<{ project: Project }>
}) {
  const { idNameProject } = useParams();
  const { id: idProject } = splitIdName(idNameProject);

  const { project, isLoading } = useAppSelector(
    ((state) => selectProjectStateById(state.projects, idProject)),
  );

  if (isLoading) {
    return (
      <Stack direction="column" alignItems="center" spacing={1}>
        <Spinner />
        <Typography variant="body2">
          Chargement en cours...
        </Typography>
      </Stack>
    );
  }

  if (!project) {
    return <Navigate to="/" />;
  }

  return <Component project={project} />;
}

export default React.memo(WithProject);
