/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  UserCreatePayload,
  UserDeletePayload,
  UserType,
  UserUpdatePayload,
  UserUpdateProjectsPayload,
  UsersFetchPayload,
} from '../types';

export interface State {
  isLoading: boolean;
  users: UserType[];
  error: string;
}

const initialState: State = {
  isLoading: false,
  users: [],
  error: '',
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsers: (state: State, action: PayloadAction<UsersFetchPayload>) => {
      state.isLoading = true;
    },
    fetchSuccess: (state: State, action: PayloadAction<UserType[]>) => {
      state.users = action.payload;
      state.isLoading = false;
    },
    fetchFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createUser: (state: State, action: PayloadAction<UserCreatePayload>) => {
      state.isLoading = true;
    },
    createUserSuccess: (state: State, action: PayloadAction<UserType>) => {
      state.users = [...state.users, action.payload];
      state.isLoading = false;
    },
    createUserFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateUser: (state: State, action: PayloadAction<UserUpdatePayload>) => {
      state.isLoading = true;
    },
    updateSuccess: (state: State, action: PayloadAction<UserType>) => {
      state.users = state.users.map(
        (us) => (us.id === action.payload.id
          ? action.payload
          : us
        ),
      );
      state.isLoading = false;
    },
    updateFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateUserProjects: (state: State, action: PayloadAction<UserUpdateProjectsPayload>) => {
      state.isLoading = true;
    },
    updateUserProjectsSuccess: (state: State, action: PayloadAction<UserType>) => {
      state.users = state.users.map(
        (us) => (us.id === action.payload.id
          ? action.payload
          : us
        ),
      );
      state.isLoading = false;
    },
    updateUserProjectsFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteUser: (state: State, action: PayloadAction<UserDeletePayload>) => {
      state.isLoading = true;
    },
    deleteUserSuccess: (state: State, action: PayloadAction<UserType>) => {
      state.users = state.users.filter((us) => us.id !== action.payload.id);
      state.isLoading = false;
    },
    deleteUserFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const selectUserById = createSelector(
  (state: State) => state.users,
  (_: any, id: number | string | undefined) => id,
  (users, id) => {
    if (id === undefined) return undefined;
    let userId = id;
    if (typeof id === 'string') {
      userId = parseInt(id, 10);
    }
    const user = users.find((userItem) => userItem.id === userId);
    return user;
  },
);

export const selectUserByType = createSelector(
  (state: State) => state.users,
  (_: any, isExternal: boolean | undefined) => isExternal,
  (users, isExternal) => {
    if (isExternal === undefined) return users;
    const usersList = users.filter((userItem) => userItem.user_type.is_external === isExternal);
    return usersList;
  },
);

export const selectUserFilteredByType = createSelector(
  (state: State) => state.users,
  (_: any, userTypeIds: number[]) => userTypeIds,
  (users, userTypeIds) => users.filter((user) => (userTypeIds.length
    ? userTypeIds.includes(user.user_type_id)
    : true)),
);

export const {
  fetchUsers,
  fetchSuccess,
  fetchFailure,
  createUser,
  createUserSuccess,
  createUserFailure,
  updateUser,
  updateSuccess,
  updateFailure,
  updateUserProjects,
  updateUserProjectsSuccess,
  updateUserProjectsFailure,
  deleteUser,
  deleteUserSuccess,
  deleteUserFailure,
} = usersSlice.actions;

export default usersSlice.reducer;
