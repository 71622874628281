import { AppDispatch } from 'common/store';

import {
  addSiteUrlPattern,
  deleteSiteUrlPattern,
  updateSiteUrlPattern,
} from './state/slice';
import {
  ActorForm,
  ActorPatternsDataGrid,
  ActorType,
  Site,
} from './types';

export default class Actor {
  private id?: number | undefined;

  private name: string = '';

  private scope_id: number | undefined;

  private is_client: boolean = false;

  private dataviz_color: string | null | undefined;

  private sites: Site[] = [];

  constructor(actor: ActorType) {
    this.id = actor.id;
    this.name = actor.name;
    this.scope_id = actor.scope_id;
    this.is_client = actor.is_client;
    this.dataviz_color = actor.dataviz_color;
    this.sites = actor.sites;
  }

  get actorName() {
    return this.name;
  }

  get actorIsClient() {
    return this.is_client;
  }

  get actorScope() {
    return this.scope_id;
  }

  get actorId() {
    return this.id;
  }

  get actorSites() {
    return this.sites;
  }

  get datavizColor() {
    return this.dataviz_color;
  }

  formatPatternsForDatagrid() {
    const patternList: ActorPatternsDataGrid[] = [];

    this.sites.forEach((site) => {
      site.site_url_patterns.forEach((pattern) => {
        patternList.push({
          ...pattern,
          id: pattern.id ?? 0,
          actorName: this.name,
          isClient: this.is_client,
          actorId: this.id!,
          datavizColor: this.dataviz_color,
        });
      });
    });
    return patternList;
  }

  addSiteUrlPattern(dispatch: AppDispatch, values: ActorForm, siteId: number) {
    const payload = {
      actorId: this.actorId || 0,
      siteId,
      pattern: {
        url_pattern: values.domain,
        include: !values.exclude,
        site_url_pattern_type_id: 2,
        pattern_start_type_id: values.subdomains ? 2 : 1,
        site_id: siteId,
      },
    };
    dispatch(addSiteUrlPattern(payload));
  }

  updateSiteUrlPattern(
    dispatch: AppDispatch,
    values: ActorForm,
    siteId: number,
    patternId: number,
  ) {
    const payload = {
      actorId: this.actorId || 0,
      siteId,
      pattern: {
        url_pattern: values.domain,
        include: !values.exclude,
        site_url_pattern_type_id: 2,
        pattern_start_type_id: values.subdomains ? 2 : 1,
        site_id: siteId,
        id: patternId,
      },
    };
    dispatch(updateSiteUrlPattern(payload));
  }

  deleteSiteUrlPattern(dispatch: AppDispatch, patternId: number, siteId: number) {
    const payload = {
      actorId: this.actorId || 0,
      siteId,
      patternId,
    };
    dispatch(deleteSiteUrlPattern(payload));
  }
}
