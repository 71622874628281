import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'common/reduxHooks';

import {
  fetchKeywords,
  deleteKeywords,
  addKeywords,
  selectKeywordsByAnalysis,
} from './slice';
import { AddKeywordsFormType, formKeywordsToKeywords } from '../modals/addKeywords/config';

type KeywordsHookProps = {
  idAnalysis: number,
}

const useKeywords = (props: KeywordsHookProps) => {
  const { idAnalysis } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (idAnalysis) {
      dispatch(fetchKeywords(idAnalysis));
    }
  }, [idAnalysis, dispatch]);

  const deletingKeywords = (keywordsToDelete: number[]) => {
    if (idAnalysis) {
      const payload = keywordsToDelete.map((kw) => ({
        serp_analysis_id: idAnalysis || 0,
        keyword_id: kw,
      }));
      dispatch(deleteKeywords(payload));
    }
  };

  const addingKeywords = (values: AddKeywordsFormType, scopeId: number | null) => {
    if (idAnalysis && scopeId) {
      const payloadKeywords = formKeywordsToKeywords(values, scopeId, idAnalysis);
      dispatch(addKeywords(payloadKeywords));
    }
  };

  const keywords = useAppSelector(
    (state) => selectKeywordsByAnalysis(state.keywords, idAnalysis),
  );
  const { isLoading } = useAppSelector((state) => state.keywords);

  return {
    keywords,
    deletingKeywords,
    addingKeywords,
    isLoading,
  };
};

export default useKeywords;
