import React from 'react';

import { Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import CategoryValue from 'features/categoryGroups/classes/CategoryValue';
import CATEGORY_TYPE_ID from 'features/categoryGroups/CONSTANTS';
import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';

import DataGridActions from '../actions/DataGridActions';
import SaveCategoryValueEdit from '../actions/SaveCategoryValue';
import EditCategoryIncludeField from '../components/EditCategoryIncludeField';
import EditCategoryTypeField from '../components/EditCategoryTypeField';
import EditPatternField from '../components/EditPatternField';
import { EditCategoryValueType, HandleUpdateCategoryValueType } from '../type';
import getCategoryValueInputLabel from '../utils/includeLabel';

const CAT_COLUMN: GridColDef<CategoryValue> = {
  field: 'categories',
  headerClassName: 'super-app-theme--header',
  renderHeader: () => (
    <StyledHeader label="Catégories" />
  ),
  renderCell: (params) => (
    <Typography variant="body2">{params.row.catName}</Typography>
  ),
  valueGetter: (value, row) => (
    row.catName
  ),
  flex: 1,
};

const createColumns = (
  editedRowValue: EditCategoryValueType,
  handleSetEditRowValue: (id: number) => void,
  handleUpdateEditRowValue: ({ key, value }: HandleUpdateCategoryValueType) => void,
  categoryGroupType: number,
): GridColDef<CategoryValue>[] => {
  const firstColumns: GridColDef[] = [];
  let patternName = '';

  if (categoryGroupType === CATEGORY_TYPE_ID.url) {
    patternName = 'RÈGLES D\'URL';
    firstColumns.push(CAT_COLUMN);
  } else if (categoryGroupType === CATEGORY_TYPE_ID.keywords) {
    patternName = 'RÈGLES DE MOT-CLÉ';
    firstColumns.push(CAT_COLUMN);
  } else if (categoryGroupType === CATEGORY_TYPE_ID.brand) {
    patternName = 'REQUÊTES DE NOTORIÉTÉ';
  }

  const columns = firstColumns.concat([
    {
      field: 'pattern',
      renderHeader: () => (
        <StyledHeader label={patternName} />
      ),
      renderCell: (params) => (
        editedRowValue.id === params.row.catValId
          ? (
            <EditPatternField
              value={editedRowValue.url}
              categoryType={editedRowValue.categoryType}
              onUpdate={(value) => handleUpdateEditRowValue({ key: 'url', value })}
            />
          )
          : <Typography variant="body2">{params.value}</Typography>
      ),
      valueGetter: (value, row) => (
        row.catValValue
      ),
      flex: 4,
    },
    {
      field: 'correspondance',
      renderHeader: () => (
        <StyledHeader label="CORRESPONDANCES" />
      ),
      renderCell: (params: GridRenderCellParams<CategoryValue>) => (
        editedRowValue.id === params.row.catValId
          ? (
            <EditCategoryTypeField
              value={editedRowValue.categoryType}
              onUpdate={(value) => handleUpdateEditRowValue({ key: 'categoryType', value })}
            />
          )
          : <Typography variant="body2">{params.row.catValCatType}</Typography>
      ),
      valueGetter: (value, row) => (
        row.catValCatType
      ),
      flex: 1.2,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'exclusion',
      renderHeader: () => (
        <StyledHeader label="EXCLUSIONS" />
      ),
      renderCell: (params: GridRenderCellParams<CategoryValue>) => (
        editedRowValue.id === params.row.catValId
          ? (
            <EditCategoryIncludeField
              value={editedRowValue.include}
              onUpdate={(value) => handleUpdateEditRowValue({ key: 'include', value })}
            />
          )
          : (
            <Typography variant="body2">
              {getCategoryValueInputLabel(params.row.catValInclude)}
            </Typography>
          )
      ),
      valueGetter: (value, row) => getCategoryValueInputLabel(row.catValInclude),
      flex: 1.2,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      flex: 1,
      align: 'right',
      renderCell: (params: GridRenderCellParams<CategoryValue>) => (
        editedRowValue.id === params.row.catValId
          ? (
            <SaveCategoryValueEdit
              categoryValue={params.row}
              editedRowValue={editedRowValue}
              onCategoryValueSave={() => handleSetEditRowValue(0)}
            />
          )
          : (
            <DataGridActions
              categoryValue={params.row}
              onEditRowValueSelected={handleSetEditRowValue}
              categoryGroupType={categoryGroupType}
            />
          )
      ),
    },
  ]);

  return columns;
};

export default createColumns;
