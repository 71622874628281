import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import ApiActors from './api';
import {
  fetchActors,
  fetchActorsSuccess,
  fetchActorsFailure,
  updateActors,
  updateActorsSuccess,
  updateActorsFailure,
  updateActor,
  updateActorSuccess,
  updateActorFailure,
  addActor,
  addActorSuccess,
  addActorFailure,
  addSiteUrlPattern,
  addSiteUrlPatternSuccess,
  addSiteUrlPatternFailure,
  updateSiteUrlPattern,
  updateSiteUrlPatternSuccess,
  updateSiteUrlPatternFailure,
  deleteSiteUrlPattern,
  deleteSiteUrlPatternSuccess,
  deleteSiteUrlPatternFailure,
  deleteActor,
  deleteActorSuccess,
  deleteActorFailure,
} from './slice';
import {
  ActorsUpdatePayload,
  ActorUpdatePayload,
  ActorsFetchPayload,
  ActorType,
  SiteUrlPatternPayload,
  SiteUrlPatternDelete,
} from '../types';

export function* workFetchActors(action: PayloadAction<ActorsFetchPayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiActors.fetchActors,
      action.payload.scopeId,
    );

    yield put(fetchActorsSuccess(response.data));
  } catch (e: any) {
    yield put(fetchActorsFailure(e.message));
  }
}

export function* workUpdateActorsWithSitesByScope(action: PayloadAction<ActorsUpdatePayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiActors.updateActorsByScope,
      action.payload.scopeId,
      action.payload.actors,
    );

    yield put(updateActorsSuccess(response.data));
  } catch (e: any) {
    yield put(updateActorsFailure(e.message));
  }
}

export function* workUpdateActor(action: PayloadAction<ActorUpdatePayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiActors.updateActor,
      action.payload.actorId,
      action.payload.actor,
    );

    yield put(updateActorSuccess(response.data));
  } catch (e: any) {
    yield put(updateActorFailure(e.message));
  }
}

export function* workAddActor(action: PayloadAction<ActorType>) {
  try {
    const response: AxiosResponse = yield call(
      ApiActors.addActor,
      action.payload,
    );
    const { data } = response;
    yield put(addActorSuccess(data));
  } catch (e: any) {
    yield put(addActorFailure(e.message));
  }
}

export function* workAddSiteUrlPattern(action: PayloadAction<SiteUrlPatternPayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiActors.addSiteUrlPattern,
      action.payload.pattern,
    );
    const { data } = response;
    yield put(addSiteUrlPatternSuccess({
      pattern: data,
      actorId: action.payload.actorId,
      siteId: action.payload.siteId,
    }));
  } catch (e: any) {
    yield put(addSiteUrlPatternFailure(e.message));
  }
}

export function* workUpdateSiteUrlPattern(action: PayloadAction<SiteUrlPatternPayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiActors.updateSiteUrlPattern,
      action.payload.pattern,
    );
    const { data } = response;
    yield put(updateSiteUrlPatternSuccess({
      pattern: data,
      actorId: action.payload.actorId,
      siteId: action.payload.siteId,
    }));
  } catch (e: any) {
    yield put(updateSiteUrlPatternFailure(e.message));
  }
}

export function* workDeleteSiteUrlPattern(action: PayloadAction<SiteUrlPatternDelete>) {
  try {
    const response: AxiosResponse = yield call(
      ApiActors.deleteSiteUrlPattern,
      { patternId: action.payload.patternId },
    );
    const { data } = response;
    yield put(deleteSiteUrlPatternSuccess({
      pattern: data,
      actorId: action.payload.actorId,
      siteId: action.payload.siteId,
    }));
  } catch (e: any) {
    yield put(deleteSiteUrlPatternFailure(e.message));
  }
}

export function* workDeleteActor(action: PayloadAction<{ actorId: number }>) {
  try {
    const response: AxiosResponse = yield call(
      ApiActors.deleteActor,
      action.payload,
    );
    const { data } = response;
    yield put(deleteActorSuccess(data));
  } catch (e: any) {
    yield put(deleteActorFailure(e.message));
  }
}

function* sagaFetchActors() {
  yield takeEvery(
    fetchActors.type,
    workFetchActors,
  );
}

function* sagaUpdateActorsWithSites() {
  yield takeEvery(
    updateActors.type,
    workUpdateActorsWithSitesByScope,
  );
}

function* sagaUpdateActor() {
  yield takeEvery(
    updateActor.type,
    workUpdateActor,
  );
}

function* sagaAddActorWithSites() {
  yield takeEvery(
    addActor.type,
    workAddActor,
  );
}

function* sagaAddSiteUrlPattern() {
  yield takeEvery(
    addSiteUrlPattern.type,
    workAddSiteUrlPattern,
  );
}

function* sagaUpdateSiteUrlPattern() {
  yield takeEvery(
    updateSiteUrlPattern.type,
    workUpdateSiteUrlPattern,
  );
}

function* sagaDeleteSiteUrlPattern() {
  yield takeEvery(
    deleteSiteUrlPattern,
    workDeleteSiteUrlPattern,
  );
}

function* sagaDeleteActorWithSites() {
  yield takeEvery(
    deleteActor.type,
    workDeleteActor,
  );
}

export {
  sagaFetchActors,
  sagaUpdateActorsWithSites,
  sagaUpdateActor,
  sagaAddActorWithSites,
  sagaAddSiteUrlPattern,
  sagaUpdateSiteUrlPattern,
  sagaDeleteSiteUrlPattern,
  sagaDeleteActorWithSites,
};
