import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import ApiSerpAnalysisIndex from './api';
import {
  fetchSerpAnalysisIndexRequested,
  fetchSerpAnalysisIndexFailure,
  fetchSerpAnalysisIndexSuccess,
} from './slice';

export function* fetchSerpAnalysisIndex() {
  try {
    const response: AxiosResponse = yield call(ApiSerpAnalysisIndex.fetchSerpAnalysisIndex);
    const serpAnalysisIndexes = response.data;
    yield put(fetchSerpAnalysisIndexSuccess(serpAnalysisIndexes));
  } catch (e: any) {
    let error = e.message;
    if (e.response?.data?.message) {
      error = e.response.data.message;
    }
    yield put(fetchSerpAnalysisIndexFailure(error));
  }
}

export function* sagaFetchSerpAnalysisIndex() {
  yield takeEvery(fetchSerpAnalysisIndexRequested, fetchSerpAnalysisIndex);
}
