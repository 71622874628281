import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import { RootState } from 'common/store';

import { fetchFiscalYears } from './slice';

const useFiscalYears = () => {
  const dispatch = useAppDispatch();

  const { isLoading, error, fiscalYears } = useAppSelector(
    (state: RootState) => state.fiscalYears,
  );

  useEffect(() => {
    if (!isLoading) {
      dispatch(fetchFiscalYears());
    }
  }, [dispatch, fetchFiscalYears]);

  return {
    isLoading,
    error,
    fiscalYears,
  };
};

export default useFiscalYears;
