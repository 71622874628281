import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Button,
  Menu,
  Box,
  styled,
  BoxProps,
} from '@mui/material';

import { Project } from 'features/projects/types';
import USER_TYPES from 'features/users/CONSTANTS';
import { UserType } from 'features/users/types';

import ArchiveProject from './ArchiveProject';
import JoinProject from './JoinProject';
import ManageUsers from './ManageUsers';
import QuitProject from './QuitProject';
import RenameProject from './RenameProject';

const PREFIX = 'ProjectsDatagridActions';

const classes = {
  dropdown: `${PREFIX}-dropdown`,
  button: `${PREFIX}-button`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.dropdown}`]: {
    fontSize: theme.fontSize.xlarge,
  },

  [`& .${classes.button}`]: {
    color: '#737476',
    fontSize: theme.fontSize.medium,
    height: '30px',
    width: '115px',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      color: '#737476',
      backgroundColor: '#e8e8e8',
    },
  },
}));

type ActorDatagridActionsProps = {
  project: Project,
  isManager: boolean,
  isDircli: boolean,
  userId: number,
  currentUser: UserType | undefined,
}

export default function ProjectsDatagridActions(props: ActorDatagridActionsProps): JSX.Element {
  const {
    project,
    isManager,
    isDircli,
    userId,
    currentUser,
  } = props;

  const [openModals, setOpenModals] = useState<Array<number>>([]);
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const openMenu = Boolean(anchorMenu);

  const handleOpenMenu = (event: any) => {
    setAnchorMenu(event.target);
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };
  const isInProject = project.users.find(
    (user) => user.id === currentUser?.id,
  );
  const hasExternalUsers = project.users.some(
    (user) => user.user_type.is_external === true,
  );

  return (
    <StyledBox>
      <Button
        id={project.id.toString()}
        variant="contained"
        disableElevation
        onClick={(e) => { setOpenModals([...openModals, project.id]); handleOpenMenu(e); }}
        endIcon={<KeyboardArrowDownIcon className={classes.dropdown} />}
        size="small"
        className={classes.button}
        color="info"
        component="button"
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorMenu}
        open={openMenu && openModals.includes(project.id)}
        onClose={handleCloseMenu}
        keepMounted
        id="menuActionsProjects"
        data-testid="menu-actions-project"
      >
        {
          (
            isManager || currentUser?.user_type_id === USER_TYPES.admin.id
            || currentUser?.user_type_id === USER_TYPES.dircli.id
          )
          && (
            <Box>
              <ArchiveProject
                id={project.id}
                handleCloseMenu={handleCloseMenu}
                hasExternalUsers={hasExternalUsers}
              />
              <RenameProject
                project={project}
                handleCloseMenu={handleCloseMenu}
              />
              <ManageUsers
                project={project}
                handleCloseMenu={handleCloseMenu}
                currentUser={currentUser}
              />
            </Box>
          )
        }
        {!isInProject && !isManager && !isDircli && (
          <JoinProject
            id={project.id}
            handleCloseMenu={handleCloseMenu}
            userId={userId}
          />
        )}
        { isInProject && !isManager && !isDircli && (
          <QuitProject
            id={project.id}
            handleCloseMenu={handleCloseMenu}
            userId={userId}
          />
        )}
      </Menu>
    </StyledBox>
  );
}
