import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Box,
  Typography,
  styled,
  BoxProps,
} from '@mui/material';
import moment from 'moment';

import { useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import { ChartComponentProps } from 'features/dataviz/types';
import {
  METRICS,
  GRAPH_TYPES,
  DEFAULT_PERIODE,
  PERFORMANCE_QUERIES,
} from 'features/performance/CONSTANT';
import { MetricType } from 'features/performance/types';
import usePeriod from 'features/period/state/usePeriod';
import useRankingTypes from 'features/rankingTypes/state/useRankingTypes';
import { RANK_SLICES } from 'features/rankSlice/CONSTANT';
import {
  DATE_ENGLISH,
  DATE_FULL_NAME_MONTH_YEAR,
  convertDate,
} from 'utils/formatDate';

import {
  getBarColor,
  getMissingTypes,
  getFormattedData,
  getDatakeys,
} from './performanceByRankingTypesFormat';
import SeoPerformanceBarChart from '../SeoPerformanceBarChart';

const PREFIX = 'PerformanceByRankingTypes';

const classes = {
  allCaps: `${PREFIX}-allCaps`,
};

const StyledBox = styled(Box)<BoxProps>(() => ({
  [`& .${classes.allCaps}`]: {
    textTransform: 'uppercase',
  },
}));

function PerformanceByRankingTypes(props: ChartComponentProps) {
  const {
    datas,
    link,
    isPageLoading,
    isLoading,
    size,
    isChartWithoutDatas,
    datavizFilters,
    scopeId,
    handleExport,
    isExportLoading,
  } = props;
  const [searchParams] = useSearchParams();

  const { periods, hasPeriods } = usePeriod(scopeId ?? 0, [1]);
  let actorsList = useAppSelector((state) => state.actors.actors);
  actorsList = [...actorsList].sort((a1, a2) => a1.name.localeCompare(a2.name));

  const period = convertDate(
    moment(searchParams.get(PERFORMANCE_QUERIES.period) ?? (
      hasPeriods
        ? convertDate(moment(periods[0].period).toDate(), DATE_ENGLISH)
        : DEFAULT_PERIODE
    )).toDate(),
    DATE_FULL_NAME_MONTH_YEAR,
  );

  const actorIds = (
    datavizFilters?.type === 'rankingEvolutionFilters'
      ? datavizFilters.actorIds
      : []
  ) ?? [];
  const { rankingTypes } = useRankingTypes();

  const performanceMetric: string = (datavizFilters?.type === 'rankingEvolutionFilters'
  && datavizFilters?.datavizMode) || METRICS[0];
  const rankSliceIdsFilter = (
    datavizFilters?.type === 'rankingEvolutionFilters'
      ? datavizFilters?.rankSliceIds
      : []
  );

  const notorietyDataKeys = [
    {
      dataKey: 'Marque',
      color: globalTheme.palette.primary.light,
      stackId: 1,
    },
    {
      dataKey: 'Hors-Marque',
      color: globalTheme.palette.info.main,
      stackId: 1,
    },
  ];

  const setBarColor = () => getBarColor(actorIds, actorsList);

  const rankList = RANK_SLICES
    .filter((slice) => rankSliceIdsFilter.includes(slice.id))
    .map((slice) => slice.label);

  const setFormattedData = (currentGraphType: keyof typeof GRAPH_TYPES) => (
    getFormattedData(
      currentGraphType,
      datas.data.values,
      actorIds,
      actorsList,
      performanceMetric as MetricType,
      rankList,
    )
  );

  const generateDatakeys = () => getDatakeys(actorIds, rankSliceIdsFilter, actorsList);

  return (
    <StyledBox>
      <SeoPerformanceBarChart
        keySubject="ranking_type"
        datas={datas}
        missingValues={
          getMissingTypes(
            rankingTypes,
            datavizFilters?.type === 'rankingEvolutionFilters'
              ? datavizFilters?.rankingTypeId
              : [],
            setFormattedData(GRAPH_TYPES.rank_all.label),
          )
        }
        formattedData={setFormattedData(GRAPH_TYPES.rank_all.label)}
        title={(
          <Typography variant="bold" className={classes.allCaps}>
            {
              GRAPH_TYPES.rank_all.titles[
                performanceMetric as keyof typeof GRAPH_TYPES.rank_all.titles
              ]
            }
            {' '}
            <Typography
              color={globalTheme.palette.primary.light}
              variant="bold"
              className={classes.allCaps}
            >
              {period}
            </Typography>
          </Typography>
        )}
        link={link}
        isPageLoading={isPageLoading}
        isLoading={isLoading}
        size={size}
        isChartWithoutDatas={isChartWithoutDatas}
        setBarColor={setBarColor}
        performanceMetric={performanceMetric}
        generateDatakeys={generateDatakeys}
        graphType={GRAPH_TYPES.rank_all.label}
        handleExport={handleExport}
        isExportLoading={isExportLoading}
      />
      <SeoPerformanceBarChart
        keySubject="ranking_type"
        datas={datas}
        missingValues={
          getMissingTypes(
            rankingTypes,
            datavizFilters?.type === 'rankingEvolutionFilters'
              ? datavizFilters?.rankingTypeId
              : [],
            setFormattedData(GRAPH_TYPES.rank_brand.label),
          )
        }
        formattedData={setFormattedData(GRAPH_TYPES.rank_brand.label)}
        title={(
          <Typography variant="bold" className={classes.allCaps}>
            {
              GRAPH_TYPES.rank_brand.titles[
                performanceMetric as keyof typeof GRAPH_TYPES.rank_brand.titles
              ]
            }
            {' '}
            <Typography
              color={globalTheme.palette.primary.light}
              variant="bold"
              className={classes.allCaps}
            >
              {period}
            </Typography>
          </Typography>
        )}
        link={link}
        isPageLoading={isPageLoading}
        isLoading={isLoading}
        size={size}
        isChartWithoutDatas={isChartWithoutDatas}
        setBarColor={setBarColor}
        performanceMetric={performanceMetric}
        generateDatakeys={generateDatakeys}
        graphType={GRAPH_TYPES.rank_brand.label}
        handleExport={handleExport}
        isExportLoading={isExportLoading}
      />
      <SeoPerformanceBarChart
        keySubject="ranking_type"
        datas={datas}
        missingValues={
          getMissingTypes(
            rankingTypes,
            datavizFilters?.type === 'rankingEvolutionFilters'
              ? datavizFilters?.rankingTypeId
              : [],
            setFormattedData(GRAPH_TYPES.rank_not_brand.label),
          )
        }
        formattedData={setFormattedData(GRAPH_TYPES.rank_not_brand.label)}
        title={(
          <Typography variant="bold" className={classes.allCaps}>
            {
              GRAPH_TYPES.rank_not_brand.titles[
                performanceMetric as keyof typeof GRAPH_TYPES.rank_not_brand.titles
              ]
            }
            {' '}
            <Typography
              color={globalTheme.palette.primary.light}
              variant="bold"
              className={classes.allCaps}
            >
              {period}
            </Typography>
          </Typography>
        )}
        link={link}
        isPageLoading={isPageLoading}
        isLoading={isLoading}
        size={size}
        isChartWithoutDatas={isChartWithoutDatas}
        setBarColor={setBarColor}
        performanceMetric={performanceMetric}
        generateDatakeys={generateDatakeys}
        graphType={GRAPH_TYPES.rank_not_brand.label}
        handleExport={handleExport}
        isExportLoading={isExportLoading}
      />
      <SeoPerformanceBarChart
        keySubject="ranking_type"
        datas={datas}
        noData={actorIds.length > 1}
        missingValues={
          getMissingTypes(
            rankingTypes,
            datavizFilters?.type === 'rankingEvolutionFilters'
              ? datavizFilters?.rankingTypeId
              : [],
            setFormattedData(GRAPH_TYPES.notoriety.label),
          )
        }
        formattedData={setFormattedData(GRAPH_TYPES.notoriety.label)}
        title={(
          <Typography variant="bold" className={classes.allCaps}>
            {
              GRAPH_TYPES.notoriety.titles[
                performanceMetric as keyof typeof GRAPH_TYPES.notoriety.titles
              ]
            }
            {' '}
            <Typography
              color={globalTheme.palette.primary.light}
              variant="bold"
              className={classes.allCaps}
            >
              {period}
            </Typography>
          </Typography>
        )}
        link={link}
        isPageLoading={isPageLoading}
        isLoading={isLoading}
        size={size}
        isChartWithoutDatas={isChartWithoutDatas}
        setBarColor={setBarColor}
        performanceMetric={performanceMetric}
        generateDatakeys={() => notorietyDataKeys}
        graphType={GRAPH_TYPES.notoriety.label}
        handleExport={handleExport}
        isExportLoading={isExportLoading}
      />
    </StyledBox>
  );
}

export default PerformanceByRankingTypes;
