export const RESSOURCE = {
  keyword: 'keyword',
  rank_tracking: 'rank_tracking',
  ranking: 'ranking',
  serp_analysis: 'serp_analysis',
  seo_performance: 'seo_performance',
  overall: 'overall',
  keyword_serp_detail: 'keyword_serp_detail',
  url: 'url',
};

export const API_METRIC = {
  count_by_ranking_type_group_by_domain: 'count_by_ranking_type_group_by_domain',
  sum_volume_by_ranking_type_group_by_domain: 'sum_volume_by_ranking_type_group_by_domain',
  get_detailed_datas_by_ranking_type: 'get_detailed_datas_by_ranking_type',
  count_group_by_ranking_type: 'count_group_by_ranking_type',
  count_group_by_ranking_type_condensed: 'count_group_by_ranking_type_condensed',
  count_with_scrapped: 'count_with_scrapped',
  list_all: 'list_all',
  list_all_by_serp_analysis: 'list_all_by_serp_analysis',
  list_keyword_with_rank_and_url: 'list_keyword_with_rank_and_url',
  count_with_sa_filters_group_by_actor_or_rank_slice:
  'count_with_sa_filters_group_by_actor_or_rank_slice',
  detailed_datas_with_sa_filters: 'detailed_datas_with_sa_filters',
  count_by_period_and_serp_analysis_scrapping: 'count_by_period_and_serp_analysis_scrapping',
  get_all_by_actors: 'get_all_by_actors',
  get_all_by_url_categories: 'get_all_by_url_categories',
  get_period_list_by_data_source: 'get_period_list_by_data_source',
  get_performance_export: 'get_performance_export',
  get_performance_by_url_export: 'get_performance_by_url_export',
  get_all_by_ranking_type: 'get_all_by_ranking_type',
  get_all_search_volumes: 'get_all_search_volumes',
  get_all_actor_urls: 'get_all_actor_urls',
};

export const METRIC = {
  count_by_ranking_type_group_by_domain: {
    label: 'count_by_ranking_type_group_by_domain',
    key: API_METRIC.count_by_ranking_type_group_by_domain,
  },
  sum_volume_by_ranking_type_group_by_domain: {
    label: 'sum_volume_by_ranking_type_group_by_domain',
    key: API_METRIC.sum_volume_by_ranking_type_group_by_domain,
  },
  get_detailed_datas_by_ranking_type: {
    label: 'get_detailed_datas_by_ranking_type',
    key: API_METRIC.get_detailed_datas_by_ranking_type,
  },
  count_group_by_ranking_type: {
    label: 'count_group_by_ranking_type',
    key: API_METRIC.count_group_by_ranking_type,
  },
  count_group_by_ranking_type_condensed: {
    label: 'count_group_by_ranking_type_condensed',
    key: API_METRIC.count_group_by_ranking_type_condensed,
  },
  count_with_scrapped: {
    label: 'count_with_scrapped',
    key: API_METRIC.count_with_scrapped,
  },
  list_all: {
    label: 'list_all',
    key: API_METRIC.list_all,
  },
  list_all_by_serp_analysis: {
    label: 'list_all_by_serp_analysis',
    key: API_METRIC.list_all_by_serp_analysis,
  },
  list_keyword_with_rank_and_url: {
    label: 'list_keyword_with_rank_and_url',
    key: API_METRIC.list_keyword_with_rank_and_url,
  },
  count_with_filters_group_by_actors_rank: {
    label: 'count_with_filters_group_by_actors_rank',
    key: API_METRIC.count_with_sa_filters_group_by_actor_or_rank_slice,
  },
  count_by_period_and_serp_analysis_scrapping: {
    label: 'count_by_period_and_serp_analysis_scrapping',
    key: API_METRIC.count_by_period_and_serp_analysis_scrapping,
  },
  detailed_datas_with_sa_filters: {
    label: 'detailed_datas_with_sa_filters',
    key: API_METRIC.detailed_datas_with_sa_filters,
  },
  get_all_by_actors: {
    label: 'get_all_by_actors',
    key: API_METRIC.get_all_by_actors,
  },
  get_all_by_url_categories: {
    label: 'get_all_by_url_categories',
    key: API_METRIC.get_all_by_url_categories,
  },
  get_period_list_by_data_source: {
    label: 'get_period_list_by_data_source',
    key: API_METRIC.get_period_list_by_data_source,
  },
  get_performance_export: {
    label: 'get_performance_export',
    key: API_METRIC.get_performance_export,
  },
  get_performance_by_url_categories_export: {
    label: 'get_performance_by_url_export',
    key: API_METRIC.get_performance_by_url_export,
  },
  get_all_by_ranking_type: {
    label: 'get_all_by_ranking_type',
    key: API_METRIC.get_all_by_ranking_type,
  },
  get_all_search_volumes: {
    label: 'get_all_search_volumes',
    key: API_METRIC.get_all_search_volumes,
  },
  get_all_urls_by_actors: {
    label: 'get_all_urls_by_actors',
    key: API_METRIC.get_all_actor_urls,
  },
};

export const RANKING_TYPE = {
  ORGANIC: 1,
  FEATURED_SNIPPET: 2,
  PEOPLE_ALSO_ASK: 3,
  LOCAL_PACK: 14,
  VIDEO: 23,
  IMAGES: 11,
};

export const rankingModeSlice = 1;

export const BASE_DATAVIZ_REQUEST = {
  scope_id: 0,
  site_url_pattern_ids: [],
  data_file_group_ids: [],
  ranking_type_ids: [],
  serp_analysis_ids: [],
  limit: 0,
  skip: 0,
  periode: undefined,
};
