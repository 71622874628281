import * as yup from 'yup';

import REGEX_LABEL from 'utils/formControl';

import { NB_MAX_KEYWORD } from '../../CONSTANTS';
import { KeywordsAdd } from '../../types';

export interface AddKeywordsFormType {
  keywords: string;
}

const DEFAULT_KW_ADD: AddKeywordsFormType = {
  keywords: '',
};

export const initialValues: AddKeywordsFormType = {
  ...DEFAULT_KW_ADD,
};

export const formKeywordsToKeywords = (
  form: AddKeywordsFormType,
  scopeId: number | null,
  serpAnalysisId: number,
) => {
  const keywordsArray: KeywordsAdd[] = [];
  form.keywords
    .split('\n')
    .map((keyword) => keyword.trim().toLowerCase())
    .filter((keyword) => keyword)
    .forEach((keyword) => {
      const keywordObject: KeywordsAdd = {
        serp_analysis_id: serpAnalysisId,
        sa_keyword_intention_id: null,
        keyword: {
          label: keyword,
          default_search_volume: 0,
          scope_id: scopeId,
        },
      };
      keywordsArray.push(keywordObject);
    });
  return keywordsArray;
};

function checkKeywordListFormat(keywordList: string | undefined): boolean {
  return keywordList?.split('\n').find(
    (line) => line !== '' && !line.match(REGEX_LABEL),
  ) === undefined;
}

function checkKeywordMaxSize(
  keywordList: string | undefined,
  maxSize: number,
): boolean {
  return (
    keywordList === undefined
    || keywordList.split('\n').length <= maxSize
  );
}

function checkSeparator(keywordList: string | undefined): boolean {
  if (keywordList?.includes(',') || keywordList?.includes(';')) {
    return false;
  }
  return true;
}

export const validationSchema = (
  setHasWrongSeparator: Function,
  forceValidation: boolean,
) => yup.object().shape({
  keywords: yup.string().required('Ce champ est obligatoire').trim()
    .test(
      'check-invalid-characters',
      'Au moins une lettre ou un chiffre requis par mot-clé',
      (value) => checkKeywordListFormat(value),
    )
    .test(
      'check-max-size',
      `Un ajout manuel de mots clés est limité à 500.
      Utilisez l’upload CSV pour contourner cette limite`,
      (value) => checkKeywordMaxSize(value, NB_MAX_KEYWORD),
    )
    .test(
      'check-separator',
      '',
      (value) => {
        if (forceValidation) {
          setHasWrongSeparator(false);
          return true;
        }
        if (!checkSeparator(value)) {
          setHasWrongSeparator(true);
        }
        return true;
      },
    ),
});
