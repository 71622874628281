import React from 'react';

import {
  FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';

import globalTheme from 'common/theme';
import { COMPUTED_VALUES } from 'features/serpAnalysis/CONSTANT';

type SearchVolumeComputedDropDownProps = {
  value: string,
  onChange: (e: SelectChangeEvent<string>) => void,
  error?: boolean,
}

function SearchVolumeComputedDropDown(props: SearchVolumeComputedDropDownProps) {
  const {
    value,
    onChange,
    error,
  } = props;

  return (
    <FormControl
      size="small"
      fullWidth
      sx={{ m: '2px' }}
      error={error}
    >
      <InputLabel
        id="select-computed-value"
        data-testid="select-computed-value"
        size="small"
      >
        Demande mensuelle
      </InputLabel>
      <Select
        value={value}
        label="Demande mensuelle"
        name="search-volume-computed-dropdown"
        onChange={onChange}
        inputProps={{
          sx: {
            fontSize: globalTheme.fontSize.medium,
          },
        }}
      >
        { COMPUTED_VALUES.map((computedValue) => (
          <MenuItem
            key={computedValue.id}
            value={computedValue.value}
            sx={{
              fontSize: globalTheme.fontSize.medium,
            }}
          >
            {computedValue.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SearchVolumeComputedDropDown;
