import React from 'react';

import { Link, Typography } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';

import globalTheme from 'common/theme';
import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';
import { DATE_MONTH_YEAR, DATE_NO_HOUR, convertDate } from 'utils/formatDate';
import formatThousands from 'utils/formatThousands';
import { generateSerpUrl } from 'utils/urlManipulation';

export const fields = {
  keyword_label: 'keyword_label',
  actor_name: 'actor_name',
  value_vol: 'value_vol',
  keyword_intent: 'keyword_intent',
  best_ranking: 'best_ranking',
};

export const generationColsPeriode = (
  begin: string,
  end: string,
  type: moment.unitOfTime.Diff,
): GridColDef[] => {
  const fromDate = moment(begin);
  const toDate = moment(end);
  const diff = toDate.diff(fromDate, type) + 1;
  const range = [];
  for (let i = 0; i < diff; i += 1) {
    range.push(moment(begin).add(i, type));
  }
  const formatDate = type === 'months' ? DATE_MONTH_YEAR : DATE_NO_HOUR;
  return range.map((date): GridColDef => ({
    field: convertDate(date.toDate(), formatDate),
    headerName: convertDate(date.toDate(), formatDate),
    renderHeader: () => (
      <StyledHeader label={convertDate(date.toDate(), formatDate)} />
    ),
    renderCell: (params: GridCellParams<any>) => (
      <Typography sx={{ fontSize: globalTheme.fontSize.xsmall }}>
        {
          (
            typeof params.value === 'number'
            && Math.round(params.value * 10) / 10
          )
        }
      </Typography>
    ),
    type: 'string',
    editable: false,
    flex: 0.5,
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
  }));
};

export const columnsDetailedRankTracking = (searchEngineId?: number): GridColDef[] => ([
  {
    field: fields.keyword_label,
    headerName: 'Mots clés',
    renderHeader: () => (
      <StyledHeader label="Mots clés" />
    ),
    type: 'string',
    editable: false,
    flex: 1,
    minWidth: 100,
    renderCell: (params: GridCellParams<any>) => (
      <Link
        href={
          params.row.serp_url
            ? params.row.serp_url
            : generateSerpUrl(params.row.keyword_label, searchEngineId)
        }
        target="_blank"
        rel="noopener"
        variant="body3"
      >
        {params.row.keyword_label}
      </Link>
    ),
  },
  {
    field: fields.value_vol,
    headerName: 'Volume',
    renderHeader: () => (
      <StyledHeader label="Volume" />
    ),
    type: 'string',
    editable: false,
    flex: 0.5,
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridCellParams) => formatThousands(params.row.value_vol),
  },
  {
    field: fields.keyword_intent,
    headerName: 'Intention',
    renderHeader: () => (
      <StyledHeader label="Intention" />
    ),
    type: 'string',
    editable: false,
    flex: 0.5,
    minWidth: 100,
  },
  {
    field: fields.actor_name,
    headerName: 'Acteur',
    renderHeader: () => (
      <StyledHeader label="Acteur" />
    ),
    type: 'string',
    editable: false,
    flex: 0.5,
    minWidth: 100,
  },
  {
    field: fields.best_ranking,
    headerName: 'Meilleure',
    renderHeader: () => (
      <StyledHeader label="Meilleure" />
    ),
    renderCell: (params: GridCellParams<any>) => (
      <Typography sx={{ fontSize: '0.75rem' }}>
        {
          (
            typeof params.value === 'number'
            && params.value > 0
            && params.value <= 100
          )
            ? Math.round(params.value * 10) / 10
            : ''
        }
      </Typography>
    ),
    type: 'string',
    editable: false,
    flex: 0.5,
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
  },
]);
