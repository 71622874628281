import React, { useState } from 'react';

import { Box, MenuItem } from '@mui/material';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';

import UserUpdateStatusModalTitle from './UpdateStatusModalTitle';
import { UserType } from '../types';
import User from '../User';

type UpdateUserStatusProps = {
  userItem: UserType,
  handleCloseMenu: Function,
}

function UpdateUserStatus (props: UpdateUserStatusProps) {
  const { userItem, handleCloseMenu } = props;
  const dispatch = useAppDispatch();

  const user = new User(userItem);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const changeStatus = () => {
    user.updateStatus(dispatch);
  };
  return (
    <Box>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => { if (!openModal) setOpenModal(true); handleCloseMenu(); }
        }
      >
        Activer | Désactiver
      </MenuItem>
      <Modal
        title={<UserUpdateStatusModalTitle user={user} />}
        actionConfirm={() => {
          changeStatus();
          setOpenModal(false);
        }}
        actionCancel={() => setOpenModal(false)}
        isOpen={openModal}
        displayCancel
        dataTestId="modal-update-user-status"
      />
    </Box>
  );
}

export default UpdateUserStatus;
