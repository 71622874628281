import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Grid from '@mui/material/Grid2';
import { useFormik } from 'formik';

import { Category } from 'features/categoryGroups/types';
import serializeSearchParams from 'utils/querySerializer';

import PerformanceFilters from './PerformanceFilters';
import SaveFiltersBox from './SaveFilterBox';
import validationSchema from './setupPerformanceFilters';
import { PERFORMANCE_QUERIES } from '../CONSTANT';
import { PerformanceFiltersType, PerformancePages } from '../types';
import DefaultPerformanceFilters from '../utils/DefaultPerformanceFilters';

type PerformanceFiltersBlockProps = {
  onFiltersChange: (values: PerformanceFiltersType) => void,
  defaultFilters: DefaultPerformanceFilters,
  resetFilters: Function,
  baseUrl: string,
  scopeId: number,
  page: PerformancePages,
  updateQuery?: boolean,
}

function PerformanceFiltersBlock(props: PerformanceFiltersBlockProps) {
  const {
    onFiltersChange,
    defaultFilters,
    resetFilters,
    baseUrl,
    scopeId,
    page,
    updateQuery = true,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const getQuery = (queryParam: string) => {
    if (updateQuery) {
      return searchParams.get(queryParam);
    }

    return null;
  };

  const getAllQuery = (queryParam: string) => {
    if (updateQuery) {
      return searchParams.getAll(queryParam);
    }

    return null;
  };

  const [isFilter, setIsFilter] = useState(searchParams.size > 0);
  const periodParam = getQuery(PERFORMANCE_QUERIES.period);
  const period = defaultFilters.getDefaultPeriod(periodParam);

  const metric = defaultFilters.getDefaultMetric(getQuery(PERFORMANCE_QUERIES.metric));

  const rankSlices = defaultFilters.getDefaultRankSlice(getAllQuery(PERFORMANCE_QUERIES.rankSlice));

  const actorsParams = getAllQuery(PERFORMANCE_QUERIES.actorIds);
  const defaultActors = defaultFilters.getDefaultActors(actorsParams);

  const categoryGroupParam = Number(getQuery(PERFORMANCE_QUERIES.categoryGroupId)) || 0;
  const defaultCategoryGroup = defaultFilters.getDefaultCategoryGroup(categoryGroupParam) ?? 0;
  const categoryParams = getAllQuery(PERFORMANCE_QUERIES.categoryIds);
  const defaultCategory = defaultFilters.getDefaultCategory(categoryGroupParam, categoryParams);
  const serpAnalysesParams = getAllQuery(PERFORMANCE_QUERIES.serpAnalysisIds);
  const defaultSerpAnalysisIds = defaultFilters.getDefaultSerpAnalysis(serpAnalysesParams);

  const rankingTypesParams = getAllQuery(PERFORMANCE_QUERIES.rankingTypeIds);
  const defaultRankingTypeIds = defaultFilters.getDefaultRankingTypeIds(
    rankingTypesParams,
  );

  const bestRankModeParam = getQuery(PERFORMANCE_QUERIES.bestRankMode);
  const defaultBestRankMode = defaultFilters.getDefaultBestRankMode(bestRankModeParam);

  const defaultAggregateMode = defaultFilters.getDefaultAggregateMode();

  const includeUncategorizedParam = getQuery(PERFORMANCE_QUERIES.includeUncategorized);
  const defaultIncludeUncategorized = defaultFilters
    .getDefaultIncludeUncategorized(includeUncategorizedParam);

  const handleSubmit = (values: PerformanceFiltersType) => {
    onFiltersChange(values);
    if (updateQuery) {
      setSearchParams(serializeSearchParams({
        [PERFORMANCE_QUERIES.period]: values.period,
        [PERFORMANCE_QUERIES.metric]: values.metric,
        [PERFORMANCE_QUERIES.rankSlice]: values.rankSlices,
        [PERFORMANCE_QUERIES.actorIds]: values.actorIds,
        [PERFORMANCE_QUERIES.categoryGroupId]: values.categoryGroupId,
        [PERFORMANCE_QUERIES.categoryIds]: values.categoryGroupId
          ? values.categoryIds
          : '',
        [PERFORMANCE_QUERIES.serpAnalysisIds]: values.serpAnalysisIds,
        [PERFORMANCE_QUERIES.rankingTypeIds]: values.rankingTypeIds,
        [PERFORMANCE_QUERIES.bestRankMode]: values.bestRankMode,
        [PERFORMANCE_QUERIES.includeUncategorized]: values.includeUncategorized,
      }));
    }
  };

  const formik = useFormik<PerformanceFiltersType>({
    initialValues: {
      period,
      metric,
      rankSlices,
      actorIds: defaultActors,
      categoryGroupId: defaultCategoryGroup,
      categoryIds: defaultCategory,
      serpAnalysisIds: defaultSerpAnalysisIds,
      rankingTypeIds: defaultRankingTypeIds,
      bestRankMode: defaultBestRankMode,
      aggregateMode: defaultAggregateMode,
      includeUncategorized: defaultIncludeUncategorized,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const cancelFilters = () => {
    navigate(baseUrl);
    formik.resetForm();
    resetFilters();
    setIsFilter(false);
  };

  const handleCategoryGroupChange = (value: number | '', categories: Category[]) => {
    formik.setValues({
      ...formik.values,
      categoryGroupId: (value !== '') ? value : 0,
      categoryIds: (value !== '')
        ? categories.map((category) => category.id)
        : [],
    });
  };

  const handleCategoryChange = (e:any) => {
    const { target: { value } } = e;
    formik.setValues({
      ...formik.values,
      categoryIds: value.filter((catId: number) => catId !== 0),
      includeUncategorized: value.includes(0),
    });
  };

  return (
    <Grid container direction="row" spacing={2} data-testid="performance-filters-block">
      <Grid size={4}>
        <SaveFiltersBox
          onFilter={() => { formik.submitForm(); setIsFilter(true); }}
          onCancel={cancelFilters}
          isFilter={isFilter}
          bestRankMode={formik.values.bestRankMode}
          aggregateMode={formik.values.aggregateMode}
          onChange={formik.handleChange}
          page={page}
          showReset={updateQuery}
        />
      </Grid>
      <Grid size={8}>
        <PerformanceFilters
          filters={formik.values}
          scopeId={scopeId}
          page={page}
          onChange={formik.handleChange}
          onCategoryGroupChange={handleCategoryGroupChange}
          handleCategoryChange={handleCategoryChange}
        />
      </Grid>
    </Grid>
  );
}

export default PerformanceFiltersBlock;
