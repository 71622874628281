import {
  featuredSnippetAbbr,
  featuredSnippetLabel,
  imageAbbr,
  imageLabel,
  localPackAbbr,
  localPackLabel,
  peopleAlsoAskAbbr,
  peopleAlsoAskLabel,
  videoAbbr,
  videoLabel,
} from 'pages/projects/serpDetails/config';

function getFeatureLabel(label: string) {
  let featLabel = '';

  switch (label) {
    case featuredSnippetAbbr:
      featLabel = featuredSnippetLabel;
      break;
    case peopleAlsoAskAbbr:
      featLabel = peopleAlsoAskLabel;
      break;
    case imageAbbr:
      featLabel = imageLabel;
      break;
    case videoAbbr:
      featLabel = videoLabel;
      break;
    case localPackAbbr:
      featLabel = localPackLabel;
      break;
    default:
      break;
  }

  return featLabel;
}

export default getFeatureLabel;
