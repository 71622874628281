import React from 'react';

export default function useInputSearch<T>(
  rows: T[],
  filterFunction: (inputSearch: string) => T[],
) {
  const [inputSearch, setInputSearch] = React.useState('');

  const handleInputChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputSearch(event.currentTarget.value);
  };

  const filterRows = (): T[] => {
    if (inputSearch === '') return rows;
    return filterFunction(inputSearch);
  };

  return {
    handleInputChange, filterRows,
  };
}
