import React from 'react';

import { Box, Typography } from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import MainBox from 'components/layout/MainBox';
import CreateProject from 'features/projects/form/CreateProject';

function Create() {
  useDocumentTitle('benhur | nouveau projet');

  return (
    <Box>
      <Typography variant="h1">Créer un projet</Typography>
      <MainBox>
        <CreateProject />
      </MainBox>
    </Box>
  );
}

export default Create;
