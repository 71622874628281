import { axiosAuthInstance } from 'common/axiosAuthInstance';

import {
  Category,
  CategoryCreateWithCategoryValues,
  CategoryGroup,
  CategoryGroupCreate,
  CategoryGroupCreateWithCategories,
  CategoryGroupUpdate,
  CategoryUpdate,
  CategoryValue,
  CategoryValueCreate,
  CategoryValueUpdate,
} from '../types';

export default class ApiCategoryGroups {
  static fetchCategoryGroups(scopeId: number) {
    return axiosAuthInstance.get<never, CategoryGroup[]>(`scopes/${scopeId}/category_groups`);
  }

  static updateCategoryGroup(groupId: number, payload: CategoryGroupUpdate) {
    return axiosAuthInstance.put<never, CategoryGroup>(
      `category_groups/${groupId}`,
      payload,
    );
  }

  static addCategoryGroup(payload: CategoryGroupCreate) {
    return axiosAuthInstance.post<never, CategoryGroup>(
      'category_groups/',
      payload,
    );
  }

  static addCategoryGroupWithCategories(payload: CategoryGroupCreateWithCategories) {
    return axiosAuthInstance.post<never, CategoryGroup>(
      'category_groups/with_categories',
      payload,
    );
  }

  static deleteCategoryGroup(payload: { groupId: number }) {
    return axiosAuthInstance.delete<never, CategoryGroup>(
      `category_groups/${payload.groupId}/with_categories`,
    );
  }

  static deleteCategory(payload: { categoryId: number }) {
    return axiosAuthInstance.delete<never, CategoryGroup>(
      `categories/${payload.categoryId}`,
    );
  }

  static updateCategory(id: number, payload: CategoryUpdate) {
    return axiosAuthInstance.put<never, Category>(
      `categories/${id}`,
      payload,
    );
  }

  static createCategoryWithValues(payload: CategoryCreateWithCategoryValues) {
    return axiosAuthInstance.post<never, Category>(
      'categories/with_category_values',
      payload,
    );
  }

  static createMultipleCategoryValue(payload: CategoryValueCreate[]) {
    return axiosAuthInstance.post<never, CategoryValue[]>(
      'category_values/multiple',
      payload,
    );
  }

  static updateCategoryValue(catValueId: number, payload: CategoryValueUpdate) {
    return axiosAuthInstance.put<never, CategoryValue>(
      `category_values/${catValueId}`,
      payload,
    );
  }

  static deleteCategoryValues(payload: number[]) {
    return axiosAuthInstance.post<never, CategoryValue[]>(
      'category_values/delete/multiple',
      payload,
    );
  }

  static updateCategoryValuesMultiple(payload: CategoryValueUpdate[]) {
    return axiosAuthInstance.put<never, CategoryValue[]>(
      'category_values/multiple',
      payload,
    );
  }
}
