import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import globalTheme from 'common/theme';
import { ActorType } from 'features/actors/types';
import getActorColor from 'features/actors/utils/format';

import BarChartVerticalComponent from '../generic/charts/BarChartVerticalComponent';
import { getBarColor } from '../seoPerformance/byActor/performanceByActorFormat';

type GroupResultGraphProps = {
  datas: any[],
  actors: ActorType[],
  rankingTypes: string[],
  actorNames: string[],
  isPageLoading?: boolean,
  handleClick?: Function
  showPercentage?: boolean,
  showValue?: boolean,
}

function GroupResultsByRankingTypeGraph(props: GroupResultGraphProps) {
  const {
    datas,
    actors,
    isPageLoading,
    handleClick,
    rankingTypes,
    actorNames,
    showPercentage,
    showValue,
  } = props;

  const [formatedDatas, setFormatedDatas] = useState<Record<string, number | string>[]>([]);

  // If 'autres' is in the result set, we add it into the actor names list
  if (datas.find((value) => value.actor_name === 'autres')) {
    actorNames.push('autres');
  }

  const AllActorsNameModel = actorNames.reduce((prev, cur) => {
    prev[cur] = 0;
    return prev;
  }, { } as Record<string, number>);

  const dataKeys: any[] = [{
    dataKey: 'autres',
    color: globalTheme.palette.info.main,
    stackId: 1,
  }];

  const actorsDataKeys = actors.map((a) => ({
    dataKey: a.name,
    color: getActorColor(a.is_client, a.dataviz_color),
    stackId: 1,
  }));

  if (actorsDataKeys) dataKeys.push(...actorsDataKeys);

  useEffect(() => {
    const tempDatas: Record<string, number | string>[] = rankingTypes.map((rt) => ({
      name: rt,
      ...AllActorsNameModel,
    }));

    datas.forEach((data) => {
      const toEdit = tempDatas.find((fData) => data.ranking_type_name === fData.name);
      if (toEdit) toEdit[data.actor_name] = data.count;
    });

    const finalDatas = tempDatas.reduce((prev, cur) => {
      const total = dataKeys.reduce((prevTotal: number, key) => {
        const { dataKey } = key;
        if (typeof cur[dataKey] === 'number') return prevTotal + cur[dataKey];
        return prevTotal;
      }, 0);

      const temp = cur;

      dataKeys.forEach((key) => {
        const { dataKey } = key;

        if (typeof cur[dataKey] === 'number') {
          if (cur[dataKey] > 0) {
            temp[`${dataKey}-percentage`] = Math.round((cur[dataKey] / total) * 100);
          } else temp[`${dataKey}-percentage`] = 0;
        }
      });

      prev.push(temp);

      return prev;
    }, [] as Record<string, number | string>[]);

    setFormatedDatas(finalDatas);
  }, [datas, rankingTypes, actorNames]);

  if (!datas || datas.length === 0) {
    return (
      <Box width="100%" textAlign="center" alignContent="center">
        vide
      </Box>
    );
  }

  return (
    <BarChartVerticalComponent
      datas={formatedDatas}
      title=""
      dataKeys={dataKeys.sort(
        (a, b) => (b.dataKey === 'autres' ? -1 : a.dataKey.localeCompare(b.dataKey)),
      )}
      isPageLoading={isPageLoading}
      tickMargin={5}
      handleClick={handleClick}
      isAxisLabelBold={false}
      withTooltip
      containerAspect={1}
      setBarColor={(actorName: string) => getBarColor(actorName, actors)}
      showLegend
      centerLabelName={showValue}
      percentLabel={showPercentage}
    />
  );
}

export default GroupResultsByRankingTypeGraph;
