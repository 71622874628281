export function downloadFile(file: Blob, fileName: string) {
  // Create an url to download this file
  const href = window.URL.createObjectURL(file);

  /*
  * And finally create a link with this url and click
  * on it so it automatically downloads the file
  */
  const link = document.createElement('a');
  link.download = fileName;
  link.href = href;
  link.click();
}

export default downloadFile;
