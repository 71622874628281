import Moment from 'moment';

// 29/11/2012 19:15
export const DATE_NUMBERS_HOURS = 1;

// 2012-29-11
export const DATE_ENGLISH = 2;

// 29/11/2012 19:15:40
export const DATE_NUMBERS_HOURS_SC = 3;

// 29/11/2012 19:15:40+02:00
export const DATE_NUMBERS_HOURS_SC_UTC = 4;

// 29/11/2012 19:15:40
export const DATE_NO_HOUR = 5;

// 29/11
export const DATE_DAY_MONTH = 6;

// 10/2023
export const DATE_MONTH_YEAR = 7;

// mardi 24 oct
export const DATE_NAME_DAY_MONTH = 8;
// oct 23
export const DATE_NAME_MONTH_YEAR = 9;

// octobre 2024
export const DATE_FULL_NAME_MONTH_YEAR = 10;

// 2024-10
export const DATE_YEAR_MONTH = 11;

// 202410
export const DATE_YEAR_MONTH_FUSE = 12;

// 2024-01-01T00:00:00Z
export const DATE_API = 13;

// 31/12/2024
export const DATE_FRENCH = 14;

const datesTransformationsList = [
  {
    type: DATE_NUMBERS_HOURS,
    format: 'DD/MM/YYYY HH:mm',
    transform: (date: Date) => {
      const newDate = Moment(date, Moment.ISO_8601).format('DD/MM/YYYY HH:mm');
      return newDate;
    },
  },
  {
    type: DATE_ENGLISH,
    format: 'YYYY-MM-DD',
    transform: (date: Date) => {
      const newDate = Moment(date, Moment.ISO_8601).format('YYYY-MM-DD');
      return newDate;
    },
  },
  {
    type: DATE_NUMBERS_HOURS_SC,
    format: 'YYYY-MM-DD HH:mm:ss',
    transform: (date: Date) => {
      const newDate = Moment(date, Moment.ISO_8601).format('YYYY-MM-DD HH:mm:ss');
      return newDate;
    },
  },
  {
    type: DATE_NO_HOUR,
    format: 'DD/MM/YYYY',
    transform: (date: Date) => {
      const newDate = Moment(date, Moment.ISO_8601).format('DD/MM/YYYY');
      return newDate;
    },
  },
  {
    type: DATE_NUMBERS_HOURS_SC_UTC,
    format: 'YYYY-MM-DD HH:mm:ssZ',
    transform: (date: Date) => {
      const newDate = Moment(date, Moment.ISO_8601).format('YYYY-MM-DD HH:mm:ssZ');
      return newDate;
    },
  },
  {
    type: DATE_DAY_MONTH,
    format: 'DD/MM',
    transform: (date: Date) => {
      const newDate = Moment(date, Moment.ISO_8601).format('DD/MM');
      return newDate;
    },
  },
  {
    type: DATE_MONTH_YEAR,
    format: 'MM/YYYY',
    transform: (date: Date) => {
      const newDate = Moment(date, Moment.ISO_8601).format('MM/YYYY');
      return newDate;
    },
  },
  {
    type: DATE_NAME_DAY_MONTH,
    format: 'dddd D MMM',
    transform: (date: Date) => {
      const newDate = Moment(date, Moment.ISO_8601).format('dddd D MMM');
      return newDate;
    },
  },
  {
    type: DATE_NAME_MONTH_YEAR,
    format: 'MMM YYYY',
    transform: (date: Date) => {
      const newDate = Moment(date, Moment.ISO_8601).format('MMM YYYY');
      return newDate;
    },
  },
  {
    type: DATE_FULL_NAME_MONTH_YEAR,
    format: 'MMMM YYYY',
    transform: (date: Date) => {
      const newDate = Moment(date, Moment.ISO_8601).format('MMMM YYYY');
      return newDate;
    },
  },
  {
    type: DATE_YEAR_MONTH,
    format: 'YYYY-MM',
    transform: (date: Date) => {
      const newDate = Moment(date, Moment.ISO_8601).format('YYYY-MM');
      return newDate;
    },
  },
  {
    type: DATE_YEAR_MONTH_FUSE,
    format: 'YYYYMM',
    transform: (date: Date) => {
      const newDate = Moment(date, Moment.ISO_8601).format('YYYYMM');
      return newDate;
    },
  },
  {
    type: DATE_API,
    format: 'YYYY-MM-DDTHH:mm:ss',
    transform: (date: Date) => {
      const newDate = `${Moment(date, Moment.ISO_8601).format('YYYY-MM-DDTHH:mm:ss')}Z`;
      return newDate;
    },
  },
  {
    type: DATE_FRENCH,
    format: 'DD/MM/YYYY',
    transform: (date: Date) => {
      const newDate = `${Moment(date, Moment.ISO_8601).format('DD/MM/YYYY')}`;
      return newDate;
    },
  },
];
/*
* Function finding in our list the right transformation per type,
* and calling the right function
* Input : date / type
* Output : date
*/
export function convertDate(date: Date, typeOfConversion: number): string {
  try {
    const newDate = datesTransformationsList?.find(
      (dateItem) => dateItem.type === typeOfConversion,
    )?.transform(date);
    if (newDate === undefined) {
      throw new Error('Invalid type of conversion');
    }
    return newDate;
  } catch {
    throw new Error('Invalid type of conversion');
  }
}

export function dateComparator(date1?: string, date2?: string, typeOfConversion?: number) {
  if (!date1) return 1;
  if (!date2) return -1;

  const format = datesTransformationsList.find((item) => item.type === typeOfConversion)?.format;

  if (format === undefined) {
    throw new Error(`type of conversion ${typeOfConversion} do not exist`);
  }

  return Moment(date1, format).diff(Moment(date2, format)) > 0 ? 1 : -1;
}
