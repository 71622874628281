import React from 'react';

import globalTheme from 'common/theme';
import MixedChart from 'features/dataviz/components/generic//charts/MixedChart';
import RankingEvolutionTooltipComponent
  from 'features/dataviz/components/generic/charts/common/RankingEvolutionTooltip';

import generateDatakeys from './CountWithFiltersFormat';
import { rankingModeSlice } from '../../CONSTANTS';
import { ChartComponentProps } from '../../types';
import formatcountWithFiltersData from '../../utils/formatData';

function CountWithFilters (props: ChartComponentProps) {
  const {
    datas,
    title,
    datavizMode,
    rankingMode,
    isLoading,
    isMonthly,
    yAxisLabel,
    isAnimationActive,
    containerAspect,
  } = props;
  const formattedData = formatcountWithFiltersData(datas.data.values || [], datavizMode || 'rank');

  const dataKeys = generateDatakeys(formattedData, rankingMode);

  const dataKeysRight = [{
    dataKey: 'Total scrappés',
    color: globalTheme.palette.primary.light,
    stackId: 2,
  }];

  return (
    <MixedChart
      isAnimationActive={isAnimationActive}
      containerAspect={containerAspect}
      datas={formattedData}
      title={title}
      dataKeys={dataKeys}
      mainType={
        rankingMode === rankingModeSlice
          ? 'Bar'
          : 'Line'
      }
      dataKeysRight={dataKeysRight}
      yAxisSecondaryLabel="Total de mots-clés scrappés"
      xAxisDatakey="periode"
      withTooltip
      xAxisInterval="preserveStartEnd"
      size="big"
      showLegend
      isLoading={isLoading}
      legendAngle={-60}
      showCursor
      tooltipContent={
        <RankingEvolutionTooltipComponent datavizMode={datavizMode} isMonthly={isMonthly} />
      }
      yAxisLabel={yAxisLabel}
    />
  );
}

export default CountWithFilters;
