import React from 'react';

import { Typography } from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';

export default function DataWam(): JSX.Element {
  useDocumentTitle('benhur | centre de données WAM');
  return (
    <Typography component="h1">DataWam Page</Typography>
  );
}
