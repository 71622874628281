import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';

import { uploadDataFiles } from 'features/dataFiles/state/slice';

import { ENVIRONMENT } from './config';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const devMode = ENVIRONMENT === 'development';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

export const setupStore = (preloadedState?: Partial<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    devTools: devMode,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          uploadDataFiles.type,
        ],
      },
    }).concat(sagaMiddleware),
    preloadedState,
  });
  sagaMiddleware.run(rootSaga);
  return store;
};

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
