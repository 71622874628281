import React, { useState } from 'react';

import { useAppSelector } from 'common/reduxHooks';
import LoginEmailCheckerForm from 'features/authentication/components/LoginEmailCheckerForm';
import LoginGoogleForm from 'features/authentication/components/LoginGoogleForm';

import LoginPasswordForm from './LoginPasswordForm';
import { UserAuthInfo } from '../types';

function LoginGlobalForm() {
  const {
    isLoading, error,
  } = useAppSelector(
    (state) => state.authentication,
  );

  const [userAuthInfo, setUserAuthInfo] = useState<UserAuthInfo>({
    email: '',
    isInternal: null,
  });

  const handleCancel = () => {
    setUserAuthInfo((prev) => ({ ...prev, isInternal: null }));
  };

  if (userAuthInfo.isInternal === null) {
    return (
      <LoginEmailCheckerForm
        onUserInfoChange={setUserAuthInfo}
        defaultEmail={userAuthInfo.email}
      />
    );
  }

  if (userAuthInfo.isInternal === true) {
    return (
      <LoginGoogleForm
        isLoading={isLoading}
        error={error}
        userEmail={userAuthInfo.email}
        onCancel={handleCancel}
      />
    );
  }

  return (
    <LoginPasswordForm
      email={userAuthInfo.email}
      onCancel={handleCancel}
    />
  );
}

export default LoginGlobalForm;
