import * as yup from 'yup';

import { UpdateValuesCategoryForm } from '../type';

export const updateValuesCategory: UpdateValuesCategoryForm = {
  categoryId: '',
  newName: '',
};

export const validateUpdateValuesCategory = yup.object().shape({
  categoryId: yup.number()
    .required('Merci de sélectionner une catégorie')
    .moreThan(0, 'Merci de sélectionner une catégorie'),
  newName: yup.string()
    .trim()
    .required('Ce champ est obligatoire')
    .max(100, '100 caractères maximum'),
});
