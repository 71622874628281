import globalTheme from 'common/theme';
import { ActorType } from 'features/actors/types';
import getActorColor from 'features/actors/utils/format';
import {
  formatByRankingTypeData,
  formatByRankingTypeNotoriety,
  formatByRankingTypesDataMultiple,
} from 'features/dataviz/utils/formatData';
import { GRAPH_TYPES } from 'features/performance/CONSTANT';
import { MetricType } from 'features/performance/types';
import { RankingTypeType } from 'features/rankingTypes/types';
import { RANK_SLICES } from 'features/rankSlice/CONSTANT';
import { RankLabel } from 'features/rankSlice/types';

export const getBarColor = (actorIds: number[], actorsList: ActorType[]) => {
  if (actorIds?.length === 1) {
    const actor = actorsList.find((atr) => atr.id === actorIds[0]);
    if (actor?.dataviz_color) return actor.dataviz_color;
    if (actor?.is_client) return globalTheme.palette.secondary.main;
    if (actor && !actor?.is_client) return globalTheme.palette.primary.light;
    return globalTheme.palette.info.main;
  }
  return globalTheme.palette.info.main;
};

export const getMissingTypes = (
  rankingTypes: RankingTypeType[],
  filterRankingTypeIds: number[],
  data: any[],
) => {
  const rankingTypesWithValues = data?.map((value: any) => value.ranking_type);
  const missingRankingTypes = rankingTypes.filter((rt) => (
    filterRankingTypeIds.includes(rt.id)
  )).map((rt) => rt.name)
    .filter((rt) => rankingTypesWithValues && !rankingTypesWithValues.includes(rt));

  missingRankingTypes.sort((a, b) => a.localeCompare(b));

  return missingRankingTypes;
};

export const getDatakeys = (
  actorIds: number[],
  rankSliceIdsFilter: number[],
  actorsList: ActorType[],
) => {
  if (actorIds.length === 1) {
    return [
      {
        rankId: RANK_SLICES[0].id,
        dataKey: RANK_SLICES[0].label,
        color: globalTheme.palette.dataviz[5],
        stackId: 1,
      },
      {
        rankId: RANK_SLICES[1].id,
        dataKey: RANK_SLICES[1].label,
        color: globalTheme.palette.dataviz[4],
        stackId: 1,
      },
      {
        rankId: RANK_SLICES[2].id,
        dataKey: RANK_SLICES[2].label,
        color: globalTheme.palette.dataviz[3],
        stackId: 1,
      },
      {
        rankId: RANK_SLICES[3].id,
        dataKey: RANK_SLICES[3].label,
        color: globalTheme.palette.dataviz[2],
        stackId: 1,
      },
    ]
      .filter((keySlice) => rankSliceIdsFilter.find((id) => keySlice.rankId === id));
  }
  return actorsList.map((actor) => ({
    actorId: actor.id,
    dataKey: actor.name,
    color: getActorColor(actor.is_client, actor.dataviz_color),
    stackId: 1,
  }))
    .filter((keySlice) => actorIds.find((id) => keySlice.actorId === id));
};

export const getFormattedData = (
  currentGraphType: keyof typeof GRAPH_TYPES,
  values: any[] | undefined,
  actorIds: number[],
  actorsList: ActorType[],
  performanceMetric: MetricType,
  rankList: RankLabel[],
) => {
  if (actorIds?.length === 1) {
    if (currentGraphType === GRAPH_TYPES.notoriety.label) {
      return formatByRankingTypeNotoriety(
        values || [],
        performanceMetric,
      );
    }

    return formatByRankingTypeData(
      values || [],
      performanceMetric,
      currentGraphType,
      rankList,
    );
  }

  if (actorIds.length > 1) {
    return formatByRankingTypesDataMultiple(
      values || [],
      performanceMetric,
      currentGraphType,
      actorsList.map((actor) => actor.name),
    );
  }

  return [];
};
