import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import CategoryValue from 'features/categoryGroups/classes/CategoryValue';

import { EditCategoryValueType } from '../type';
import isCategoryValueValueValid from '../utils/checkCategoryValueValueIsValid';

type SaveCategoryValueEditProps = {
  categoryValue: CategoryValue,
  editedRowValue: EditCategoryValueType,
  onCategoryValueSave: Function,
}

function SaveCategoryValueEdit(props: SaveCategoryValueEditProps) {
  const {
    categoryValue,
    editedRowValue,
    onCategoryValueSave,
  } = props;

  const [internalLoading, setInternalLoading] = useState(false);

  const { isLoading } = useAppSelector((state) => (state.categoryGroups));

  const dispatch = useAppDispatch();

  const handleSaveNewRowValue = () => {
    categoryValue.updateCatVal(
      dispatch,
      editedRowValue,
    );

    setInternalLoading(true);
  };

  useEffect(() => {
    if (!isLoading && internalLoading) {
      setInternalLoading(false);
      onCategoryValueSave(0);
    }
  }, [isLoading, internalLoading]);

  return (
    <Button
      variant="contained"
      onClick={handleSaveNewRowValue}
      size="small"
      data-testid="edit-category-values-save-button"
      disabled={
        isLoading
        || !isCategoryValueValueValid(
          editedRowValue.url,
          editedRowValue.categoryType,
        )
      }
    >
      Enregistrer
    </Button>
  );
}

export default SaveCategoryValueEdit;
