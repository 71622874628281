import React from 'react';

import globalTheme from 'common/theme';
import DropDown from 'components/dropDown/DropDown';

import USER_TYPES from '../CONSTANTS';

type UserTypesDropDownProps = {
  value: number,
  label: string,
  onChange: any,
  name: string,
  size?: 'medium' | 'small',
  height?: string,
  multiple?: boolean,
  emptyOption?: string,
  margin?: number,
  width?: string,
  fontSize?: keyof typeof globalTheme.fontSize,
  disabled?: boolean,
  className?: {
    select?: string,
  },
}

function UserTypesDropDown(props: UserTypesDropDownProps) {
  const {
    name,
    label,
    onChange,
    size,
    margin,
    value,
    height,
    multiple = false,
    emptyOption,
    fontSize,
    width,
    className,
    disabled = false,
  } = props;

  const userTypes = [
    USER_TYPES.internal,
    USER_TYPES.dircli,
    USER_TYPES.admin,
  ];

  return (
    <DropDown<typeof userTypes[number]>
      name={name}
      fullWidth
      value={value}
      options={userTypes}
      label={label}
      onChange={onChange}
      getOptionLabel={(ut) => ut.label}
      getOptionValue={(ut) => ut.id}
      multiple={multiple}
      emptyOption={emptyOption}
      size={size}
      fontSize={fontSize}
      margin={margin}
      height={height}
      width={width}
      className={className}
      disabled={disabled}
      data-testid="select-user-type-drop-down"
    />
  );
}

export default UserTypesDropDown;
