import React from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';

import {
  Box,
  Typography,
  Button,
  Stack,
  styled,
  StackProps,
} from '@mui/material';

import useCurrentUser from 'features/authentication/state/useCurrentUser';
import DatavizContainer from 'features/dataviz/components/generic/DatavizContainer';
import { METRIC, RANKING_TYPE } from 'features/dataviz/CONSTANTS';
import datavizConfig from 'features/dataviz/datavizConfig';
import { ResultPhysionomyProps } from 'features/serpAnalysis/components/serpDetails/types';
import { resultPhysionomyName } from 'pages/projects/serpDetails/config';
import {
  generateAnalysisUrl,
} from 'utils/urlManipulation';

import getFeatureLabel from './utils';

const PREFIX = 'ResultPhysionomy';

const classes = {
  core: `${PREFIX}-core`,
  buttonCtn: `${PREFIX}-buttonCtn`,
  text: `${PREFIX}-text`,
  link: `${PREFIX}-link`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`& .${classes.buttonCtn}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 10,
    marginTop: '17px',
  },

  [`& .${classes.text}`]: {
    fontSize: theme.fontSize.xsmall,
    color: theme.palette.info,
  },

  [`& .${classes.link}`]: {
    color: theme.palette.primary.light,
  },
}));

function ResultPhysionomy(props: ResultPhysionomyProps) {
  const {
    scopeId,
    analysis,
    project,
    periode,
    isPageLoading,
    blockHeight,
    datavizFilters,
    containerAspect,
  } = props;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const currentUser = useCurrentUser();

  const handleClick = (e: any, label: string) => {
    const featLabel = getFeatureLabel(label);
    if (!label) return;

    navigate(
      generateAnalysisUrl(
        project.id,
        project.name,
        analysis.id,
        analysis.label,
        featLabel,
        searchParams,
      ),
    );
  };

  return (
    <StyledStack height={blockHeight}>
      <DatavizContainer
        idScope={scopeId}
        datavizComp={datavizConfig[METRIC.count_group_by_ranking_type_condensed.label]}
        rankingTypeIds={[
          RANKING_TYPE.FEATURED_SNIPPET,
          RANKING_TYPE.PEOPLE_ALSO_ASK,
          RANKING_TYPE.IMAGES,
          RANKING_TYPE.VIDEO,
          RANKING_TYPE.LOCAL_PACK,
        ]}
        idSerpAnalysis={analysis.id}
        serpAnalysisStatus={analysis.serp_analysis_status_id}
        periode={periode}
        title={resultPhysionomyName.toUpperCase()}
        isPageLoading={isPageLoading}
        size="small"
        handleClick={handleClick}
        datavizFilters={datavizFilters}
        containerAspect={containerAspect}
      />
      <Box className={classes.buttonCtn}>
        <Typography className={classes.text}>
          Hors
          {' '}
          <NavLink
            to={
              generateAnalysisUrl(
                project.id,
                project.name,
                analysis.id,
                analysis.label,
                undefined,
                searchParams,
              )
            }
            className={classes.link}
          >
            Recherche Organique
          </NavLink>
        </Typography>
        <Button
          size="small"
          variant="contained"
          onClick={() => navigate(
            generateAnalysisUrl(
              project.id,
              project.name,
              analysis.id,
              analysis.label,
              'serp',
              searchParams,
            ),
          )}
          disabled={currentUser?.isExternal()}
        >
          Détails
        </Button>
      </Box>
    </StyledStack>
  );
}

export default ResultPhysionomy;
