import React from 'react';

import { Box } from '@mui/material';
import { FormikProps } from 'formik';

import Modal from 'components/modal/Modal';
import ValidateActionMessage from 'components/modal/ValidateActionMessage';

import { AddKeywordsFormType } from './config';
import AddKeywordsFormModal from './FormModal';
import SeparatorMessage from './SeparatorMessage';
import WrongSepTitle from './WrongSepTitle';

type AddKeywordsModalViewProps = {
  nameAnalysis: string | undefined,
  formik: FormikProps<AddKeywordsFormType>,
  openModal: boolean,
  isLoading: boolean,
  isKeywordsLoading: boolean,
  keywordsError: string,
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
  setIsConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>,
  handleSuccessClose: Function,
  isConfirmationOpen: boolean,
  handleFixWrongSteps: Function,
  hasWrongSeparator: boolean,
  disabledAddKeyword?: boolean,
}

function AddKeywordsModalsChoiceView(props: AddKeywordsModalViewProps) {
  const {
    nameAnalysis,
    isLoading,
    formik,
    openModal,
    isKeywordsLoading,
    keywordsError,
    setOpenModal,
    setIsConfirmationOpen,
    handleSuccessClose,
    isConfirmationOpen,
    handleFixWrongSteps,
    hasWrongSeparator,
    disabledAddKeyword,
  } = props;

  return (
    <Box>
      {
        !isLoading && (
          <Box>
            <AddKeywordsFormModal
              nameAnalysis={nameAnalysis}
              formik={formik}
              openModal={openModal}
              isKeywordsLoading={isKeywordsLoading}
              keywordsError={keywordsError}
              setOpenModal={setOpenModal}
              setIsConfirmationOpen={setIsConfirmationOpen}
              disabled={disabledAddKeyword}
            />
            <Modal
              title={<ValidateActionMessage />}
              displayCancel={false}
              actionConfirm={handleSuccessClose}
              isOpen={isConfirmationOpen}
            />
            <Modal
              title={<WrongSepTitle />}
              actionConfirm={formik.handleSubmit}
              actionCancel={handleFixWrongSteps}
              isOpen={hasWrongSeparator && !openModal}
            >
              <SeparatorMessage />
            </Modal>
          </Box>
        )
      }
    </Box>
  );
}

export default AddKeywordsModalsChoiceView;
