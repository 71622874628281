import React from 'react';

import {
  Dialog,
  Button,
  DialogTitle,
  styled,
  DialogActions,
  DialogContent,
  DialogProps,
  CircularProgress,
} from '@mui/material';

import smallSpinnerSize from 'components/loading/config';

const PREFIX = 'Modal';

const classes = {
  button: `${PREFIX}-button`,
  buttonLoading: `${PREFIX}-buttonLoading`,
  spinner: `${PREFIX}-spinner`,
  title: `${PREFIX}-title`,
  modal: `${PREFIX}-modal`,
  keywords: `${PREFIX}-keywords`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  errorIcon: `${PREFIX}-errorIcon`,
  warningIcon: `${PREFIX}-warningIcon`,
  successIcon: `${PREFIX}-successIcon`,
  container: `${PREFIX}-container`,
};

const StyledDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  [`& .${classes.button}`]: {
    width: '150px',
    margin: `0 ${theme.spacing(2)}`,
    fontSize: theme.fontSize.medium,
  },

  [`& .${classes.buttonLoading}`]: {
    width: '150px',
    margin: `0 ${theme.spacing(2)}`,
    fontSize: theme.fontSize.medium,
    backgroundColor: theme.palette.secondary.main,
    cursor: 'default',
    color: theme.palette.primary.light,
  },

  [`& .${classes.spinner}`]: {
    color: theme.palette.primary.light,
  },

  [`& .${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '70%',
    margin: '0 auto',
    padding: 0,
  },

  [`& .${classes.title}`]: {
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: '400',
  },

  [`& .${classes.keywords}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexGrow: 1,
    margin: theme.spacing(2),
  },
}));

type ModalProps = {
  title: JSX.Element | string,
  children?: JSX.Element | undefined,
  displayCancel?: boolean,
  displayConfirm?: boolean,
  disabledConfirm?: boolean,
  actionConfirm: any,
  actionCancel?: any,
  isOpen: boolean,
  displayLoading?: boolean,
  isProcessLoading?: boolean,
  dataTestId?: string,
}

function Modal (props: ModalProps) {
  const {
    title,
    children,
    displayCancel = true,
    displayConfirm = true,
    disabledConfirm = false,
    actionConfirm,
    actionCancel,
    isOpen = false,
    displayLoading = false,
    isProcessLoading = false,
    dataTestId,
  } = props;

  const confirmText = 'Valider';

  return (
    <StyledDialog
      open={isOpen}
      maxWidth="sm"
      fullWidth
      data-testid={dataTestId}
    >
      <DialogTitle className={classes.title}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.container}>
        {children}
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        {displayConfirm && (
          <Button
            onClick={!isProcessLoading ? actionConfirm : () => undefined}
            variant="contained"
            className={isProcessLoading ? classes.buttonLoading : classes.button}
            type="submit"
            disabled={disabledConfirm}
            data-testid="modal-confirm-button"
          >
            { isProcessLoading && displayLoading
              ? (
                <CircularProgress
                  className={classes.spinner}
                  size={smallSpinnerSize}
                />
              )
              : confirmText}
          </Button>
        )}
        {displayCancel && (
          <Button
            onClick={() => actionCancel?.()}
            variant="contained"
            color="info"
            className={classes.button}
            data-testid="modal-cancel-button"
          >
            Annuler
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
}

export default Modal;
