import React, { useEffect, useState } from 'react';

import { MenuItem } from '@mui/material';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import ValidateActionMessage from 'components/modal/ValidateActionMessage';
import UpdateDFSDataTypeModal
  from 'features/serpAnalysis/options/components/UpdateDFSDataTypeModal';
import { updateSerpAnalyses } from 'features/serpAnalysis/state/slice';
import useSerpAnalyses from 'features/serpAnalysis/state/useSerpAnalyses';
import { Analysis } from 'features/serpAnalysis/types';

type UpdateDFSDataTypeActionProps = {
  serpAnalysis: Analysis,
  handleCloseMenu: Function,
}

function UpdateDFSDataTypeAction(props: UpdateDFSDataTypeActionProps) {
  const {
    serpAnalysis,
    handleCloseMenu,
  } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { isLoading } = useSerpAnalyses({ scopeId: serpAnalysis.scope_id });

  const handleConfirm = (newValue: number) => {
    dispatch(updateSerpAnalyses([{ id: serpAnalysis.id, dfs_data_type_id: newValue }]));
    setInternalLoading(true);
  };

  useEffect(() => {
    if (!isLoading && internalLoading) {
      setConfirmationModalOpen(true);
      setInternalLoading(false);
    }
  }, [isLoading, internalLoading]);

  const handleClose = () => {
    setOpenModal(false);
    setConfirmationModalOpen(false);
  };

  return (
    <MenuItem
      sx={{ fontSize: globalTheme.fontSize.middlesmall }}
      onClick={() => { if (!openModal) setOpenModal(true); handleCloseMenu(); }}
      data-testid="update-dfs-data-type-menu-item"
    >
      Type de SERP
      {
            confirmationModalOpen
              ? (
                <Modal
                  title={<ValidateActionMessage />}
                  displayCancel={false}
                  actionConfirm={handleClose}
                  isOpen={confirmationModalOpen}
                  dataTestId="update-dfs-data-type-confirm-modal"
                />
              )
              : (
                <UpdateDFSDataTypeModal
                  currentValue={serpAnalysis.dfs_data_type_id}
                  isOpen={openModal}
                  handleConfirm={handleConfirm}
                  handleClose={handleClose}
                />
              )
          }
    </MenuItem>
  );
}

export default UpdateDFSDataTypeAction;
