import React, { useEffect, useState } from 'react';

import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Button, Stack } from '@mui/material';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import ValidateActionMessage from 'components/modal/ValidateActionMessage';

import { updateSerpAnalyses } from '../../state/slice';
import { SerpAnalysisUpdatePayload } from '../../types';
import UpdateDFSDataTypeModal from '../components/UpdateDFSDataTypeModal';
import UpdateSearchVolumeComputedModal from '../components/UpdateSearchVolumeModal';

type SerpAnalysesOptionsGroupActionsProps = {
  selection: number[],
  isLoading: boolean,
}

const formatHelpterText = (count: number) => (
  `${count} catégorie${count > 1 ? 's' : ''} sélectionnée${count > 1 ? 's' : ''}`
);

function SerpAnalysesOptionsGroupActions(props: SerpAnalysesOptionsGroupActionsProps) {
  const { selection, isLoading } = props;

  const [isUpdateSearchVolumeOpen, setIsUpdateSearchVolumeOpen] = useState(false);
  const [isUpdateDFSDataTypeOpen, setIsUpdateDFSDataTypeOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleConfirm = (newValue: any, updatedField: keyof SerpAnalysisUpdatePayload) => {
    dispatch(updateSerpAnalyses(
      selection.map((id) => ({ id, [updatedField]: newValue })),
    ));
    setInternalLoading(true);
  };

  useEffect(() => {
    if (!isLoading && internalLoading) {
      setIsUpdateSearchVolumeOpen(false);
      setIsUpdateDFSDataTypeOpen(false);
      setInternalLoading(false);
      setIsConfirmationOpen(true);
    }
  }, [isLoading, internalLoading]);

  return (
    <Stack
      direction="row"
      justifyContent="end"
      alignItems="center"
      spacing={1}
      width={1}
    >
      {selection.length > 0 && (
        <>
          <Box
            sx={{
              marginRight: '18px',
              fontSize: globalTheme.fontSize.middlesmall,
              fontWeight: '500',
            }}
            data-testid="help-label-selection-count"
          >
            {formatHelpterText(selection.length)}
          </Box>
          <Button
            variant="contained"
            size="small"
            onClick={() => setIsUpdateSearchVolumeOpen(true)}
            startIcon={<SettingsIcon />}
            data-testid="update-multiple-search-volume-computed-button"
          >
            Demande mensuelle
          </Button>
          {isUpdateSearchVolumeOpen && (
            <UpdateSearchVolumeComputedModal
              currentValue=""
              isOpen={isUpdateSearchVolumeOpen}
              isLoading={isLoading}
              handleClose={() => setIsUpdateSearchVolumeOpen(false)}
              handleConfirm={
                (value: string) => handleConfirm(value === 'enable', 'is_search_volume_computed')
              }
            />
          )}
          <Button
            variant="contained"
            size="small"
            onClick={() => setIsUpdateDFSDataTypeOpen(true)}
            startIcon={<SettingsIcon />}
            data-testid="update-multiple-dfs-data-type-button"
          >
            Type de SERP
          </Button>
          {isUpdateDFSDataTypeOpen && (
            <UpdateDFSDataTypeModal
              isOpen={isUpdateDFSDataTypeOpen}
              handleClose={() => setIsUpdateDFSDataTypeOpen(false)}
              handleConfirm={(e: any) => handleConfirm(e, 'dfs_data_type_id')}
            />
          )}
          {isConfirmationOpen && (
            <Modal
              title={<ValidateActionMessage />}
              displayCancel={false}
              actionConfirm={() => setIsConfirmationOpen(false)}
              isOpen={isConfirmationOpen}
              dataTestId="update-serp-analysis-options-confirm-modal"
            />
          )}
        </>
      )}
    </Stack>

  );
}

export default SerpAnalysesOptionsGroupActions;
