import React from 'react';

import { Box, Typography } from '@mui/material';

import TitleComponent from './charts/common/Title';

type ChartErrorComponentProps = {
  title: string | JSX.Element,
  children: string,
}

function ChartErrorComponent(props: ChartErrorComponentProps) {
  const {
    title,
    children,
  } = props;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <TitleComponent title={title} />
      <Box>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: (theme) => theme.fontSize.xsmall,
            color: (theme) => theme.palette.primary.main,
          }}
        >
          {children}
        </Typography>
      </Box>
    </Box>
  );
}

export default ChartErrorComponent;
