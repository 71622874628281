import React from 'react';

import {
  Stack, StackProps, styled, Typography,
} from '@mui/material';

import SEARCH_ENGINES from '../CONSTANT';

const PREFIX = 'search-engine-info';

const classes = {
  text: `${PREFIX}-text`,
  avatar: `${PREFIX}-avatar`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`& .${classes.text}`]: {
    fontSize: theme.fontSize.xsmall,
  },

  [`& .${classes.avatar}`]: {
    height: '15px',
    width: '15px',
  },
}));

type SearchEngineInfoProps = {
  searchEngineId: number,
};

function SearchEngineInfo(props: SearchEngineInfoProps) {
  const { searchEngineId } = props;

  let searchEngine = SEARCH_ENGINES.google;

  if (searchEngineId === SEARCH_ENGINES.google.id) {
    searchEngine = SEARCH_ENGINES.google;
  } else if (searchEngineId === SEARCH_ENGINES.bing.id) {
    searchEngine = SEARCH_ENGINES.bing;
  }

  return (
    <StyledStack direction="row" alignItems="center" gap={0.5}>
      <img src={searchEngine.logo} alt={`${searchEngine.name} logo`} className={classes.avatar} />
      <Typography className={classes.text}>{searchEngine.label}</Typography>
    </StyledStack>
  );
}

export default SearchEngineInfo;
