import React from 'react';

import GroupIcon from '@mui/icons-material/Group';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { EXTERNAL_USERS, USER_ADMIN } from 'pages/admin/ROUTES';

import AdminMenuView from './AdminMenuView';
import UserMenu from './types';

function AdminMenu() {
  const iconWidth = 20;
  const adminMenu: UserMenu[] = [
    {
      name: 'Gestion des utilisateurs',
      icon: <GroupIcon sx={{ width: iconWidth }} />,
      link: USER_ADMIN,
      blockedDircli: true,
    },
    {
      name: 'Accès des clients',
      icon: <LockOpenIcon sx={{ width: iconWidth }} />,
      link: EXTERNAL_USERS,
    },
  ];

  return (
    <AdminMenuView
      menuList={adminMenu}
    />
  );
}

export default AdminMenu;
