import React from 'react';

import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';

import CopyToClipboardButton from 'components/buttons/CopyToClipBoardButton';
import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';
import {
  DATE_NO_HOUR,
  DATE_MONTH_YEAR,
  DATE_YEAR_MONTH_FUSE,
  convertDate,
} from 'utils/formatDate';

import getFiltersList from './filtersList';
import EditViewBeginDate from '../components/actions/EditViewBeginDate';
import KpiViewActions from '../components/actions/KpiViewActions';
import SaveKpiViewEdit from '../components/actions/SaveKpiView';
import { KPI_VIEW_EXPORT_STATUS, KPI_VIEW_TYPE } from '../CONSTANTS';
import { KpiViewActionsWording, KpiViewInlineEditType, KpiViewType } from '../types';
import formatExportPath from '../utils/format';

const columns = {
  insertion_date: 'insertion_date',
  start_date: 'start_date',
  last_export_date: 'last_export_date',
  filters: 'filters',
  url: 'url',
  actions: 'actions',
  id: 'id',
  eligibility: 'eligibility',
  actors: 'actors',
} as const;

export const actionsColumn = (
  wordings?: KpiViewActionsWording,
  hideDelete?: boolean,
  editedRowValue?: any,
  handleSetEditRowValue?: (id: number) => void,
  flex: number = 0.7,
): GridColDef<KpiViewType> => ({
  field: columns.actions,
  renderHeader: () => (
    <StyledHeader label="" />
  ),
  renderCell: (params) => (
    editedRowValue?.id === params.row.id
      ? (
        <SaveKpiViewEdit
          kpiView={params.row}
          editedRowValue={editedRowValue}
          onKpiViewSave={() => handleSetEditRowValue?.(0)}
        />
      )
      : (
        <KpiViewActions
          view={params.row}
          hideLoad
          disabled={params.row.id <= 0}
          hideDelete={hideDelete}
          wordings={wordings}
          onEditRowValueSelected={handleSetEditRowValue}
        />
      )
  ),
  sortable: false,
  align: 'right',
  headerAlign: 'left',
  flex,
});

const createColumnsBenchmark = (
  projectId: number,
  scopeId: number,
  editedRowValue: KpiViewInlineEditType,
  handleSetEditRowValue: (id: number) => void,
  handleUpdateEditRowValue: ({ key, value }: any) => void,
): GridColDef<KpiViewType>[] => [
  {
    field: columns.insertion_date,
    renderHeader: () => (
      <StyledHeader label="CRÉÉ LE" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">
        {convertDate(moment(params.value).toDate(), DATE_NO_HOUR)}
      </Typography>
    ),
    align: 'left',
    headerAlign: 'left',
    width: 100,
  },
  {
    field: columns.last_export_date,
    renderHeader: () => (
      <StyledHeader label="Actualisé le" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">
        {params.value ? convertDate(moment(params.value).toDate(), DATE_NO_HOUR) : ''}
      </Typography>
    ),
    align: 'left',
    headerAlign: 'left',
    width: 140,
  },
  {
    field: columns.start_date,
    renderHeader: () => (
      <StyledHeader label="Débute le" />
    ),
    renderCell: (params) => (
      editedRowValue.id === params.row.id
        ? (
          <EditViewBeginDate
            value={editedRowValue.start_date || ''}
            onUpdate={(value) => handleUpdateEditRowValue({ key: 'start_date', value })}
            scopeId={scopeId}
          />
        )
        : (
          <Typography variant="body3">
            {convertDate(moment(params.value).toDate(), DATE_MONTH_YEAR)}
          </Typography>
        )
    ),
    align: 'left',
    headerAlign: 'left',
    width: 120,
  },
  {
    field: columns.filters,
    renderHeader: () => (
      <StyledHeader label="FILTRE(S)" />
    ),
    renderCell: (params) => (
      <Typography variant="body3" margin={1}>
        {params.value}
      </Typography>
    ),
    valueGetter: (value, row) => getFiltersList(row),
    align: 'left',
    headerAlign: 'left',
    flex: 2,
    sortable: false,
  },
  {
    field: columns.url,
    renderHeader: () => (
      <StyledHeader label="URL" />
    ),
    renderCell: (params) => (
      <>
        <CopyToClipboardButton
          textToCopy={params.value}
        />
        <Typography variant="body3">
          {params.value}
        </Typography>
      </>
    ),
    valueGetter: (value, row) => formatExportPath(
      projectId,
      scopeId,
      row.id,
      {
        type: row.kpi_view_type_id === KPI_VIEW_TYPE.benchmark.id
          ? KPI_VIEW_TYPE.benchmark.label
          : KPI_VIEW_TYPE.benchmarkLight.label,
      },
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 4,
  },
  actionsColumn(undefined, undefined, editedRowValue, handleSetEditRowValue),
];

export const createColumnsShareOfVoice = (
  projectId: number,
  scopeId: number,
  isEligible: boolean = false,
): GridColDef<KpiViewType>[] => [
  {
    field: columns.start_date,
    renderHeader: () => (
      <StyledHeader label="Période" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">
        {convertDate(moment(params.value).toDate(), DATE_MONTH_YEAR)}
      </Typography>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 1,
  },
  {
    field: columns.eligibility,
    renderHeader: () => (
      <StyledHeader label="Part de voix" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">
        {params.value}
      </Typography>
    ),
    valueGetter: (value, row) => (
      row.kpi_view_export_status_id === KPI_VIEW_EXPORT_STATUS.exported.id
      && 'éligible'
    ) || (row.kpi_view_export_status_id === KPI_VIEW_EXPORT_STATUS.inProgress.id
        && 'génération en cours...')
      || (isEligible && row.label)
      || 'non éligible',
    align: 'left',
    headerAlign: 'left',
    flex: 1.5,
  },
  {
    field: columns.url,
    renderHeader: () => (
      <StyledHeader label="URL" />
    ),
    renderCell: (params) => {
      if (params.row.id <= 0) return '';

      return (
        <>
          <CopyToClipboardButton
            textToCopy={params.value}
          />
          <Typography variant="body3">
            {params.value}
          </Typography>
        </>
      );
    },
    valueGetter: (value, row) => formatExportPath(
      projectId,
      scopeId,
      row.id,
      {
        type: KPI_VIEW_TYPE.captation.label,
        periode: convertDate(
          moment(row.start_date).toDate(),
          DATE_YEAR_MONTH_FUSE,
        ),
        serpAnalysisId: row.serp_analyses[0]?.id ?? 0,
      },
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 4,
  },
  actionsColumn(
    {
      download: {
        button: 'Télécharger',
        modalTitle: (
          <Typography>
            <Typography component="span" variant="bold">
              Télécharger
            </Typography>
            {' '}
            le rapport de part de voix
          </Typography>
        ),
      },
    },
    true,
    undefined,
    undefined,
    1,
  ),
];

export const createColumnsIndexability = (
  projectId: number,
  scopeId: number,
): GridColDef<KpiViewType>[] => [
  {
    field: columns.last_export_date,
    renderHeader: () => (
      <StyledHeader label="Mise à jour" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">
        {params.value ? convertDate(moment(params.value).toDate(), DATE_NO_HOUR) : ''}
      </Typography>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 1,
  },
  {
    field: columns.actors,
    renderHeader: () => (
      <StyledHeader label="Acteurs" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">
        {params.value}
      </Typography>
    ),
    valueGetter: (value, row) => row.actors[0]?.name ?? '',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
  },
  {
    field: columns.url,
    renderHeader: () => (
      <StyledHeader label="URL" />
    ),
    renderCell: (params) => (
      <>
        <CopyToClipboardButton
          textToCopy={params.value}
        />
        <Typography variant="body3">
          {params.value}
        </Typography>
      </>
    ),
    valueGetter: (value, row) => formatExportPath(
      projectId,
      scopeId,
      row.id,
      { type: KPI_VIEW_TYPE.indexability.label },
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 4,
  },
  actionsColumn(
    {
      download: {
        button: 'Télécharger',
        modalTitle: (
          <Typography>
            <Typography component="span" variant="bold">
              Télécharger
            </Typography>
            {' '}
            le rapport d&apos;indexabilité
          </Typography>
        ),
      },
    },
    true,
  ),
];

export default createColumnsBenchmark;
