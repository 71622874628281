const DELETE_OPTIONS = {
  deleteFiles: 1,
  keepFiles: 2,
};

export const KPI_VIEW_EXPORT = {
  withoutExport: { id: 1, label: 'Sans export' },
  withExport: { id: 2, label: 'Avec export' },
};

export const KPI_VIEW_TYPE = {
  tracking: { id: 1, label: 'Suivi du positionnement' },
  benchmark: { id: 2, label: 'Benchmark' },
  captation: { id: 3, label: 'Part de voix' },
  indexability: { id: 4, label: 'Indexabilité des url' },
  serpTracking: { id: 5, label: 'Suivi de SERP' },
  benchmarkLight: { id: 6, label: 'Benchmark agrégé' },
} as const;

export const KPI_VIEW_EXPORT_STATUS = {
  toExport: { id: 1, label: 'A exporter', helper: 'Vue en attente d\'export' },
  inProgress: { id: 2, label: 'Export en cours', helper: 'Vue en cours d\'export' },
  exported: { id: 3, label: 'Export terminé', helper: 'Vue exporter' },
  toDelete: { id: 10, label: 'Export à supprimer', helper: 'Vue en attente de suppression' },
  deleteInProgress: {
    id: 11,
    label: 'Export en cours de suppression',
    helper: 'Vue en cours de suppression',
  },
  deleted: { id: 12, label: 'Export supprimé', helper: 'Vue supprimé' },
  errorWithRetryExport: {
    id: 101,
    label: 'Erreur avec retry lors de l\'export',
    helper: 'Erreur avec retry lors de l\'export',
  },
  errorWithRetryDelete: {
    id: 110,
    label: 'Erreur avec retry lors de la suppression',
    helper: 'Erreur avec retry lors de la suppression',
  },
  errorExport: {
    id: 201,
    label: 'Erreur définitive lors de l\'export',
    helper: 'Erreur définitive lors de l\'export',
  },
  errorDelete: {
    id: 210,
    label: 'Erreur définitive lors de la suppression',
    helper: 'Erreur définitive lors de la suppression',
  },
} as const;

export default DELETE_OPTIONS;
