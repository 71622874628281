import React from 'react';

import { GridColDef } from '@mui/x-data-grid';

import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';

import { Analysis } from '../../types';
import DataGridActions from '../actions/DataGridActions';

const createColumns = (): GridColDef<Analysis>[] => [
  {
    field: 'label',
    headerClassName: 'super-app-theme--header',
    renderHeader: () => (
      <StyledHeader label="CATÉGORIES" />
    ),
    renderCell: (params) => (
      params.row.label
    ),
    flex: 4,
  },
  {
    field: 'is_search_volume_computed',
    renderHeader: () => (
      <StyledHeader label="DEMANDE MENSUELLE" />
    ),
    renderCell: (params) => (
      params.row.is_search_volume_computed ? 'automatisée' : 'manuelle'
    ),
    width: 210,
  },
  {
    field: 'is_premium',
    renderHeader: () => (
      <StyledHeader label="TYPE DE SERP" />
    ),
    renderCell: (params) => (
      params.row.dfs_data_type.label
    ),
    valueGetter: (value, row) => (
      row.dfs_data_type.label
    ),
    width: 150,
  },
  {
    field: 'actions',
    headerName: '',
    renderCell: (params) => (
      <DataGridActions serpAnalysis={params.row} />
    ),
    width: 140,
    sortable: false,
  },
];

export default createColumns;
