import React from 'react';

import {
  Box, Button, BoxProps, styled, CircularProgress,
} from '@mui/material';

import smallSpinnerSize from 'components/loading/config';

const PREFIX = 'ScrappeButton';

const classes = {
  spinner: `${PREFIX}-spinner`,
  spinning: `${PREFIX}-spinning`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.spinner}`]: {
    color: theme.palette.primary.light,
  },

  [`& .${classes.spinning}`]: {
    backgroundColor: theme.palette.secondary.main,
    cursor: 'default',
    color: theme.palette.primary.light,
  },

}));

type ScrappeButtonProps = {
  isRecalculatingKPIs: boolean,
  isLoading: boolean,
  handleOpenModal: Function,
  disabled?: boolean,
}

function ScrapeButton(props: ScrappeButtonProps): JSX.Element {
  const {
    isLoading,
    isRecalculatingKPIs,
    handleOpenModal,
    disabled = false,
  } = props;

  return (
    <StyledBox>
      <Button
        onClick={
              (isLoading || isRecalculatingKPIs)
                ? undefined
                : () => handleOpenModal()
            }
        size="small"
        variant="contained"
        startIcon={
          (isLoading || isRecalculatingKPIs)
            ? <CircularProgress size={smallSpinnerSize} className={classes.spinner} />
            : undefined
        }
        className={
          (isLoading || isRecalculatingKPIs)
            ? classes.spinning
            : ''
        }
        disabled={disabled}
        data-testid="scrapping-button"
      >
        { (isLoading || isRecalculatingKPIs)
          ? '20 min.'
          : 'Planifier' !}
      </Button>
    </StyledBox>
  );
}

export default ScrapeButton;
