import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import PayloadWithCallback from 'common/types';
import router from 'router/RouterConfig';

import ApiAuth from './api';
import {
  fetchLogin,
  fetchLoginSuccess,
  fetchLoginFailure,
  fetchLogout,
  fetchLogoutSuccess,
  fetchLogoutFailure,
  fetchRefreshToken,
  fetchRefreshTokenSuccess,
  fetchRefreshTokenFailure,
  fetchCurrentUser,
  fetchCurrentUserSuccess,
  fetchCurrentUserFailure,
  fetchIsInternalSuccess,
  fetchIsInternalFailure,
  fetchIsInternal,
  fetchLoginPassword,
  fetchLoginPasswordSuccess,
  fetchLoginPasswordFailure,
} from './slice';
import {
  CheckEmailResponse,
  LoginPasswordPayload,
  LoginPayload,
  LoginResponse,
} from '../types';

export function* workFetchRefreshToken() {
  try {
    const token: LoginResponse = yield call(ApiAuth.refreshToken);
    if (token.token_error) {
      yield put(fetchRefreshTokenFailure(('User not authenticated')));
    } else {
      yield put(fetchRefreshTokenSuccess(token));
    }
  } catch (e: any) {
    yield put(fetchRefreshTokenFailure(e));
  }
}

export function* workFetchLogin(action: PayloadAction<LoginPayload>) {
  try {
    const token: LoginResponse = yield call(ApiAuth.login, action.payload.payload.authCode);
    yield put(fetchLoginSuccess(token));
    router.navigate(action.payload.callbackSuccessUrl);
  } catch (e: any) {
    yield put(fetchLoginFailure(e));
    router.navigate(action.payload.callbackFailureUrl);
  }
}

export function* workFetchLoginPassword(action: PayloadAction<LoginPasswordPayload>) {
  try {
    const token: LoginResponse = yield call(
      ApiAuth.loginPassword,
      action.payload.payload.username,
      action.payload.payload.password,
    );

    yield put(fetchLoginPasswordSuccess(token));
    router.navigate(action.payload.callbackSuccessUrl);
  } catch (e: any) {
    yield put(fetchLoginPasswordFailure(e));
    router.navigate(action.payload.callbackFailureUrl);
  }
}

export function* workFetchLogout(action: PayloadAction<PayloadWithCallback>) {
  try {
    yield call(ApiAuth.logout);
    yield put(fetchLogoutSuccess());

    window.localStorage.setItem('logout', Date.now().toString());
  } catch (e: any) {
    yield put(fetchLogoutFailure(e));
    router.navigate(action.payload.callbackFailureUrl);
  }
}

export function* workFetchCurrentUser() {
  try {
    const response: AxiosResponse = yield call(
      ApiAuth.fetchCurrentUser,
    );
    const user = response.data;
    yield put(fetchCurrentUserSuccess(user));
  } catch (e: any) {
    yield put(fetchCurrentUserFailure(e.message));
  }
}

export function* workFetchIsInternal(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(
      ApiAuth.checkEmail,
      action.payload,
    );
    const emailResponse: CheckEmailResponse = response.data;
    yield put(fetchIsInternalSuccess(emailResponse.user_type));
  } catch (e: any) {
    yield put(fetchIsInternalFailure(e.message));
  }
}

export function* sagaLogin() {
  yield takeEvery(fetchLogin.type, workFetchLogin);
}

export function* sagaLoginPassword() {
  yield takeEvery(fetchLoginPassword.type, workFetchLoginPassword);
}

export function* sagaLogout() {
  yield takeEvery(fetchLogout.type, workFetchLogout);
}

export function* sagaRefreshToken() {
  yield takeEvery(fetchRefreshToken.type, workFetchRefreshToken);
}

export function* sagaFetchCurrentUser() {
  yield takeEvery(fetchCurrentUser.type, workFetchCurrentUser);
}

export function* sagaFetchIsInternal() {
  yield takeEvery(fetchIsInternal.type, workFetchIsInternal);
}
