import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import { RootState } from 'common/store';

import { fetchSerpAnalysisPlanning } from './slice';

const useSerpAnalysisPlannings = () => {
  const dispatch = useAppDispatch();

  const { isLoading, error, plannings } = useAppSelector(
    (state: RootState) => state.serpAnalysisPlanning,
  );

  useEffect(() => {
    if (!isLoading && plannings.length === 0) {
      dispatch(fetchSerpAnalysisPlanning());
    }
  }, [dispatch, fetchSerpAnalysisPlanning]);

  return {
    isLoading,
    error,
    plannings,
  };
};

export default useSerpAnalysisPlannings;
