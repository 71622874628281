import React from 'react';

import {
  Box,
  styled,
  Typography,
} from '@mui/material';
import { BoxProps } from '@mui/system';

import Spinner from 'components/loading/Spinner';

const PREFIX = 'ModalLoadingMessage';

const classes = {
  box: `${PREFIX}-box`,
  label: `${PREFIX}-label`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.box}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '85px',
  },

  [`& .${classes.label}`]: {
    fontSize: theme.fontSize.xsmall,
  },
}));

function ModalLoadingMessage() {
  return (
    <StyledBox data-testid="modal-add-serpanalysis-loading" className={classes.box}>
      <Typography className={classes.label} component="span">
        Merci de patienter jusqu&apos;à la fin du transfert
        <br />
        <br />
        <Spinner />
      </Typography>
    </StyledBox>
  );
}

export default ModalLoadingMessage;
