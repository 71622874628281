import React from 'react';
import { useNavigate } from 'react-router-dom';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  Button,
  Menu,
  Stack,
  StackProps,
  styled,
} from '@mui/material';
import moment from 'moment';

import { useAppSelector } from 'common/reduxHooks';
import useCurrentUser from 'features/authentication/state/useCurrentUser';
import AddSerpAnalysisDataFile
  from 'features/dataFiles/actions/AddSerpAnalysisDataFile/AddSerpAnalysisDataFile';
import { METRIC } from 'features/dataviz/CONSTANTS';
import datavizConfig from 'features/dataviz/datavizConfig';
import { DEVICE_TYPE } from 'features/deviceType/CONSTANTS';
import ExportAnalysesButton from 'features/serpAnalysis/components/buttons/ExportAnalysesButton';
import { selectMultipleById } from 'features/serpAnalysis/state/slice';
import { Analysis } from 'features/serpAnalysis/types';
import { isBudgetEnoughForSerpAnalysis } from 'features/serpAnalysis/utils/serpAnalysis';
import PLANNING_TYPE from 'features/serpAnalysisPlanning/state/CONSTANTS';

import CreateSerpAnalysisModalsChoice from '../modals/create/ModalsChoice';
import DeleteAnalysis from '../modals/DeleteAnalysis';
import PlanScrapping from '../modals/PlanScrapping';
import StopPlanification from '../modals/StopPlanification';

const PREFIX = 'ActionButton';

const classes = {
  core: `${PREFIX}-core`,
  button: `${PREFIX}-secondButton`,
  containedSuppr: `${PREFIX}-containedSuppr`,
  numberSelected: `${PREFIX}-numberSelected`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    marginRight: '1rem',
    width: '100%',
  },

  [`& .${classes.button}`]: {
    textTransform: 'none',
    fontSize: theme.fontSize.large,
    width: '120px',
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },

  [`& .${classes.containedSuppr}`]: {
    display: 'flex',
  },

  [`& .${classes.numberSelected}`]: {
    margin: 'auto 0 auto 0',
    marginRight: '18px',
    fontSize: theme.fontSize.middlesmall,
    fontWeight: '500',
  },
}));

type ButtonActionsProps = {
  idsSelected: number[],
  scopeId: number,
  projectId: number,
  projectName: string,
  isImportingKeywords: boolean,
  projectBudget: number,
  keywordPrice: number
}

export default function ActionButton(props: ButtonActionsProps) {
  const {
    idsSelected,
    scopeId,
    projectId,
    projectName,
    isImportingKeywords,
    projectBudget,
    keywordPrice,
  } = props;
  const navigate = useNavigate();

  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [menuWidth, setMenuWidth] = React.useState<number>(0);
  const openMenu = Boolean(anchorMenu);

  const analyses: Analysis[] | undefined = useAppSelector((state) => (
    selectMultipleById(state.serpAnalyses, idsSelected)));

  const currentUser = useCurrentUser();
  const isExternalConsultant = currentUser?.isConsultant(projectId);

  const hasSchedule = analyses?.find((analysis) => (analysis.serp_analysis_planning_id && (
    analysis.serp_analysis_planning_id !== PLANNING_TYPE.NO_REPEAT
    || (moment(analysis.planning_date) > moment())
  )));
  const handleOpenMenu = (event: any) => {
    setAnchorMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  React.useEffect(() => {
    if (anchorMenu && anchorMenu.offsetWidth !== menuWidth) {
      setMenuWidth(anchorMenu.offsetWidth);
    }
  }, [anchorMenu]);

  const totalKeyword = analyses.reduce(
    (acc, cur) => acc + cur.keywords_count * (
      cur.device_type_id === DEVICE_TYPE.DESKTOP_MOBILE
        ? 2
        : 1
    ),
    0,
  );

  const isBudgetEnough = isBudgetEnoughForSerpAnalysis(
    projectBudget,
    keywordPrice,
    totalKeyword,
    DEVICE_TYPE.DESKTOP, // to multiply by 1, the device multiplier is already apply
  );

  return (
    <StyledStack
      direction="row"
      className={classes.core}
      spacing={2}
    >
      <Stack direction="row" width="40%" gap={2}>
        <Button
          id="buttonAddAnalysis"
          aria-controls={openMenu ? 'menuAddAnalysis' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleOpenMenu}
          startIcon={<AddCircleOutlineIcon />}
          size="small"
          data-testid="serp-analysis-action-button"
          disabled={isExternalConsultant}
        >
          Ajouter des mots clés
        </Button>
        <Menu
          keepMounted
          id="menuAddAnalysis"
          MenuListProps={{
            'aria-labelledby': 'buttonAddAnalysis',
            sx: { width: menuWidth },
          }}
          anchorEl={anchorMenu}
          open={openMenu}
          onClose={handleCloseMenu}
          data-testid="serp-analysis-action-menu"
        >
          <CreateSerpAnalysisModalsChoice
            handleCloseMenu={handleCloseMenu}
            isImportingKw={isImportingKeywords}
          />
          <Box>
            <AddSerpAnalysisDataFile
              scopeId={scopeId}
              isImportingKeywords={isImportingKeywords}
              onClick={handleCloseMenu}
            />
          </Box>
        </Menu>
        <Button
          size="small"
          variant="contained"
          onClick={() => navigate(0)}
        >
          Actualiser
        </Button>
      </Stack>
      {idsSelected.length !== 0 && (
        <Stack
          direction="row"
          width="60%"
          className={classes.containedSuppr}
          justifyContent="end"
          spacing={1}
        >
          <Box className={classes.numberSelected}>
            {`${idsSelected.length} `}
            groupe
            {
              (idsSelected.length > 1
                ? 's '
                : ' '
              )
            }
            sélectionné
            {
              (idsSelected.length > 1
                ? 's'
                : ''
              )
            }
          </Box>
          <PlanScrapping
            ids={idsSelected}
            isButton
            projectId={projectId}
            scopeId={scopeId}
            projectName={projectName}
            hasIcon
            isProjectBudgetEnough={isBudgetEnough}
            planningChoice="serpManual"
          />
          {
            hasSchedule && (
              <StopPlanification
                ids={idsSelected}
                isButton
                disabled={isExternalConsultant}
              />
            )
          }
          <ExportAnalysesButton
            scopeId={scopeId}
            projectName={projectName}
            datavizComp={datavizConfig[METRIC.list_all.label]}
            idsSelected={idsSelected}
          />
          <DeleteAnalysis
            disabled={isExternalConsultant}
            ids={idsSelected}
            isButton
          />
        </Stack>
      )}
    </StyledStack>
  );
}
