import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import SerpAnalysisScrapping from 'features/serpAnalysisScrapping/types';

import ApiAnalysisScrapping from './api';
import {
  fetchAnalysisScrapping,
  fetchAnalysisScrappingSuccess,
  fetchAnalysisScrappingFailure,
} from './slice';

function* workFetchSerpAnalysisScrapping(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(
      ApiAnalysisScrapping.fetchAnalysisPlanning,
      action.payload,
    );
    const scrappings: SerpAnalysisScrapping[] = response.data;
    yield put(fetchAnalysisScrappingSuccess(scrappings));
  } catch (e: any) {
    yield put(fetchAnalysisScrappingFailure(e.message));
  }
}

export default function* sagaFetchserpAnalysisScrapping() {
  yield takeEvery(fetchAnalysisScrapping.type, workFetchSerpAnalysisScrapping);
}
