import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import { resetDataviz } from 'features/dataviz/state/slice';
import {
  KeywordsDelete, KeywordsAdd,
} from 'features/keywords/types';

import ApiKeywords from './api';
import {
  deleteKeywords,
  deleteKeywordsSuccess,
  deleteKeywordsFailure,
  addKeywords,
  addKeywordsSuccess,
  addKeywordsFailure,
  fetchKeywords,
  fetchKeywordsSuccess,
  fetchKeywordsFailure,
} from './slice';

function* workDeleteKeywords(action: PayloadAction<KeywordsDelete>) {
  try {
    yield call(
      ApiKeywords.deleteKeywords,
      action.payload,
    );
    yield put(deleteKeywordsSuccess(action.payload));
    yield put(resetDataviz());
  } catch (e: any) {
    yield put(deleteKeywordsFailure(e.message));
  }
}

export function* workAddKeywords(action: PayloadAction<KeywordsAdd[]>) {
  try {
    const response: AxiosResponse = yield call(
      ApiKeywords.addKeywords,
      action.payload,
    );
    const keywords = response.data;
    yield put(addKeywordsSuccess(keywords));
  } catch (e: any) {
    yield put(addKeywordsFailure(e.message));
  }
}

export function* workFetchKeywords(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(
      ApiKeywords.fetchKeywords,
      action.payload,
    );
    const keywords = response.data;
    yield put(fetchKeywordsSuccess(keywords));
  } catch (e: any) {
    yield put(fetchKeywordsFailure(e.message));
  }
}

export function* sagaDeleteKeywords() {
  yield takeEvery(deleteKeywords, workDeleteKeywords);
}

export function* sagaAddKeywords() {
  yield takeEvery(addKeywords, workAddKeywords);
}

export function* sagaFetchKeywords() {
  yield takeEvery(fetchKeywords.type, workFetchKeywords);
}
