import React from 'react';

import { Box } from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import { Project } from 'features/projects/types';

import SetupActorsView from './SetupActorsView';

type SetupActorsProps = {
  project?: Project,
}

function SetupActors(props: SetupActorsProps) {
  const { project } = props;

  const projectId = project?.id ?? 0;
  const scopeId = project?.scopes[0].id ?? 0;

  useDocumentTitle(`${project?.name} : paramétrage des sites | benhur`);

  return (
    <Box>
      <SetupActorsView
        scopeId={scopeId}
        projectId={projectId}
      />
    </Box>
  );
}

export default SetupActors;
