import React, { useState } from 'react';

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import Modal from 'components/modal/Modal';
import { KpiViewActionsWording } from 'features/kpiView/types';

import DELETE_OPTIONS from '../../CONSTANTS';
import KpiView from '../../KpiView';
import { selectKpiViewById } from '../../state/slice';

interface DeleteViewProps {
  idView: number,
  handleCloseMenu: Function,
  wordings?: KpiViewActionsWording,
  disabled?: boolean,
}

function DeleteView(props: DeleteViewProps) {
  const dispatch = useAppDispatch();
  const {
    handleCloseMenu,
    idView,
    wordings,
    disabled,
  } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [formData, setFormData] = useState<number>(1);

  const kpiView = useAppSelector((state) => selectKpiViewById(state.kpiViews, idView));
  const kpiViewObj = kpiView && new KpiView(kpiView);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (kpiViewObj) {
      kpiViewObj.delete(dispatch, formData === DELETE_OPTIONS.deleteFiles);
    }
    setOpenModal(false);
  };

  return (
    <Box>
      <MenuItem
        onClick={() => { setOpenModal(true); handleCloseMenu(); }}
        disabled={disabled}
      >
        <Typography variant="body2">
          {wordings?.delete?.button ?? 'Supprimer la vue'}
        </Typography>
      </MenuItem>
      <Modal
        title={wordings?.delete?.modalTitle ?? (
          <Typography>
            <Typography component="span" variant="bold">
              Supprimer
            </Typography>
            {' '}
            cette vue KPI
          </Typography>
        )}
        actionConfirm={handleSubmit}
        actionCancel={() => setOpenModal(false)}
        isOpen={openModal}
      >
        <Stack
          component="form"
          direction="column"
          sx={{ padding: '5px', width: '100%' }}
        >
          <FormControl>
            <InputLabel id="delete-options-label" shrink>Options</InputLabel>
            <Select
              value={formData}
              onChange={(e) => setFormData(
                typeof e.target.value === 'string'
                  ? parseInt(e.target.value, 10)
                  : e.target.value,
              )}
              labelId="delete-options-label"
              label="Options"
              name="serpAnalysisIds"
              notched
              size="small"
            >
              <MenuItem value={DELETE_OPTIONS.deleteFiles}>
                <Typography
                  variant="body2"
                  component="span"
                >
                  Supprimer le(s) fichier(s) CSV de Google Cloud Storage
                </Typography>
              </MenuItem>
              <MenuItem value={DELETE_OPTIONS.keepFiles}>
                <Typography
                  variant="body2"
                  component="span"
                >
                  Conserver l&apos;historique des fichiers CSV
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Modal>
    </Box>
  );
}

export default DeleteView;
