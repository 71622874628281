import React, { useState } from 'react';

import { Box, MenuItem, Typography } from '@mui/material';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';

import UserTypesDropDown from '../components/UserTypesDropdown';
import { UserType } from '../types';
import User from '../User';

type UpdateUserRoleProps = {
  userItem: UserType,
  handleCloseMenu: Function,
}

function UpdateUserRole (props: UpdateUserRoleProps) {
  const { userItem, handleCloseMenu } = props;
  const dispatch = useAppDispatch();

  const user = new User(userItem);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<number>(userItem.user_type_id);

  const onChange = (e: any) => {
    setFormValue(e.target.value);
  };

  const changeRole = () => {
    user.updateRole(dispatch, formValue);
  };
  return (
    <Box>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => { if (!openModal) setOpenModal(true); handleCloseMenu(); }
        }
      >
        Attribuer un rôle
      </MenuItem>
      <Modal
        title={
          (
            <Typography>
              <Typography component="span" variant="bold">
                Attribuer
              </Typography>
              {' '}
              un rôle à l&apos;utilisateur
            </Typography>
          )
        }
        actionConfirm={() => {
          changeRole();
          setOpenModal(false);
        }}
        actionCancel={() => setOpenModal(false)}
        isOpen={openModal}
        displayCancel
        dataTestId="modal-update-user-role"
      >
        <Box marginTop={1} width="100%">
          <UserTypesDropDown
            value={formValue}
            onChange={onChange}
            label="Rôle"
            name="userType"
            size="small"
            width="100%"
          />
        </Box>
      </Modal>
    </Box>
  );
}

export default UpdateUserRole;
