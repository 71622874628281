/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CategoryValue,
  Category,
  CategoryGroup,
  CategoryCreateWithCategoryValues,
  CategoryGroupCreate,
  CategoryGroupsFetchPayload,
  CategoryValueUpdate,
  CategoryGroupUpdate,
  CategoryUpdatedPayload,
  CategoryUpdatePayload,
  CategoryValueUpdatePayload,
  CategoryValueUpdatedPayload,
  CategoryGroupUpdatePayload,
  CategoryValuesDeletePayload,
  CategoryValueCreate,
  CategoryValuesCreated,
  CategoryValuesCreatePayload,
  CategoryGroupCreatePayload,
  CategoryValueUpdateMultiplePayload,
  CategoryValueUpdatedMultiplePayload,
} from '../types';

export interface State {
  isLoading: boolean;
  categoryGroups: CategoryGroup[];
  error: string;
}

export const initialState: State = {
  isLoading: false,
  categoryGroups: [],
  error: '',
};

export const categoryGroupsSlice = createSlice({
  name: 'categoryGroups',
  initialState,
  reducers: {
    fetchCategoryGroups: (state: State, action: PayloadAction<CategoryGroupsFetchPayload>) => {
      state.isLoading = true;
    },
    fetchCategoryGroupsSuccess: (state: State, action: PayloadAction<CategoryGroup[]>) => {
      state.categoryGroups = action.payload.sort(
        (a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1),
      );
      state.isLoading = false;
    },
    fetchCategoryGroupsFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateCategoryGroup: (
      state: State,
      action: PayloadAction<CategoryGroupUpdatePayload>,
    ) => {
      state.isLoading = true;
    },
    updateCategoryGroupSuccess: (state: State, action: PayloadAction<CategoryGroup>) => {
      const newCategoryGroup = state.categoryGroups
        .filter((cg) => cg.id !== action.payload.id);
      newCategoryGroup.push(action.payload);
      newCategoryGroup.sort(
        (a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1),
      );

      state.categoryGroups = newCategoryGroup;
      state.isLoading = false;
    },
    updateCategoryGroupFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    addCategoryGroup: (state: State, action: PayloadAction<CategoryGroupCreatePayload>) => {
      state.isLoading = true;
    },
    addCategoryGroupSuccess: (state: State, action: PayloadAction<CategoryGroup>) => {
      state.categoryGroups.push(action.payload);
      state.isLoading = false;
    },
    addCategoryGroupFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteCategoryGroups: (state: State, action: PayloadAction<number[]>) => {
      state.isLoading = true;
    },
    deleteCategoryGroupsSuccess: (state: State, action: PayloadAction<CategoryGroup[]>) => {
      const deletedCatGroupsIds = action.payload.map((cg) => cg.id);
      state.categoryGroups = state.categoryGroups.filter(
        (cg) => !deletedCatGroupsIds.includes(cg.id),
      );
      state.isLoading = false;
    },
    deleteCategoryGroupsFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateCategory: (state: State, action: PayloadAction<CategoryUpdatePayload>) => {
      state.isLoading = true;
    },
    updateCategorySuccess: (state: State, action: PayloadAction<CategoryUpdatedPayload>) => {
      const catGroup = state.categoryGroups.find(
        (cg) => cg.id === action.payload.category_group_id,
      );
      if (catGroup) {
        const newCats = catGroup.categories.filter(
          (c) => c.id !== action.payload.category.id,
        );
        newCats.push(action.payload.category);
        catGroup.categories = newCats;
      }

      state.isLoading = false;
    },
    updateCategoryFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createCategoryWithValues: (
      state: State,
      action: PayloadAction<CategoryCreateWithCategoryValues>,
    ) => {
      state.isLoading = true;
    },
    createCategoryWithValuesSuccess: (state: State, action: PayloadAction<Category>) => {
      state.isLoading = false;
      const catGroup = state.categoryGroups.find(
        (cg) => cg.id === action.payload.category_group_id,
      );
      if (catGroup) {
        catGroup.categories.push(action.payload);
      }
    },
    createCategoryWithValuesFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteCategorySuccess: (state: State, action: PayloadAction<Category[]>) => {
      const catIds = action.payload.map((category) => category.id);
      const catGroupIds = [...new Set(
        action.payload.map((category) => category.category_group_id),
      )];

      const catGroups = state.categoryGroups.filter(
        (cg) => catGroupIds.includes(cg.id),
      );
      if (catGroups.length > 0) {
        catGroups.forEach((catGroup) => {
          catGroup.categories = catGroup.categories
            .filter((cat) => !catIds.includes(cat.id));
        });
      }
      state.isLoading = false;
    },
    addCategoryValues: (state: State, action: PayloadAction<CategoryValuesCreatePayload>) => {
      state.isLoading = true;
    },
    addCategoryValuesSuccess: (
      state: State,
      action: PayloadAction<CategoryValuesCreated>,
    ) => {
      state.isLoading = false;
      const catGroup = state.categoryGroups.find(
        (cg) => cg.id === action.payload.groupId,
      );
      if (catGroup) {
        const cat = catGroup.categories.find((c) => c.id === action.payload.values[0].category_id);
        if (cat) {
          cat.category_values.push(...action.payload.values);
        }
      }
    },
    addCategoryValuesFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateCategoryValue: (state: State, action: PayloadAction<CategoryValueUpdatePayload>) => {
      state.isLoading = true;
    },
    updateCategoryValueSuccess: (
      state: State,
      action: PayloadAction<CategoryValueUpdatedPayload>,
    ) => {
      const catGroup = state.categoryGroups.find(
        (cg) => cg.id === action.payload.category_group_id,
      );
      if (catGroup) {
        const cat = catGroup.categories.find((c) => c.id === action.payload.category_id);
        if (cat) {
          const newCatVals = cat.category_values.filter(
            (c) => c.id !== action.payload.category_value.id,
          );
          newCatVals.push(action.payload.category_value);
          cat.category_values = newCatVals;
        }
      }

      state.isLoading = false;
    },
    updateCategoryValueFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteCategoryValues: (state: State, action: PayloadAction<CategoryValuesDeletePayload>) => {
      state.isLoading = true;
    },
    deleteCategoryValuesSuccess: (state: State, action: PayloadAction<CategoryValue[]>) => {
      const categoryValueIds = action.payload.map((categoryValue) => categoryValue.id);

      state.categoryGroups = state.categoryGroups.map(
        (categoryGroup) => {
          categoryGroup.categories = categoryGroup.categories.map(
            (category) => {
              category.category_values = category.category_values.filter(
                (categoryValue) => !categoryValueIds.includes(categoryValue.id),
              );
              return category;
            },
          );

          return categoryGroup;
        },
      );

      state.isLoading = false;
    },
    deleteCategoryValuesFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateCategoryValueMultiple: (
      state: State,
      action: PayloadAction<CategoryValueUpdateMultiplePayload>,
    ) => {
      state.isLoading = true;
    },
    updateCategoryValueMultipleSuccess: (
      state: State,
      action: PayloadAction<CategoryValueUpdatedMultiplePayload>,
    ) => {
      const catGroup = state.categoryGroups.find(
        (cg) => cg.id === action.payload.category_group_id,
      );
      if (catGroup) {
        action.payload.category_ids.forEach((catId) => {
          const cat = catGroup.categories.find((c) => c.id === catId);
          if (cat) {
            const ids = action.payload.category_values.map((cv) => cv.id);

            const newCatVals = cat.category_values.filter(
              (c) => !ids.includes(c.id),
            );

            action.payload.category_values.forEach((val) => {
              if (val.category_id === cat.id) {
                newCatVals.push(val);
              }
            });
            cat.category_values = newCatVals;
          }
        });
      }

      state.isLoading = false;
    },
    updateCategoryValueMultipleFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const selectCategoryGroupsByScopeId = createSelector(
  (state: State) => state.categoryGroups,
  (_: any, scopeId: number | null) => scopeId,
  (categoryGroups, scopeId) => categoryGroups.filter((cg) => cg.scope_id === scopeId),
);

export const selectCategoryGroupsByScopeIdAndType = createSelector(
  (state: State) => state.categoryGroups,
  (_: any, scopeId: number | null) => scopeId,
  (_: any, scopeId: number | null, catGroupTypeId: number | null) => catGroupTypeId,
  (categoryGroups, scopeId, catGroupTypeId) => (
    categoryGroups.filter(
      (cg) => cg.scope_id === scopeId && cg.category_type_id === catGroupTypeId,
    )
  ),
);

export const selectCategoryGroupById = createSelector(
  (state: State) => state.categoryGroups,
  (_: any, id: number) => id,
  (categoryGroups, id): CategoryGroup | undefined => categoryGroups.filter((cg) => cg.id === id)[0],
);

export const selectAllCategories = createSelector(
  (state: State) => state.categoryGroups,
  (categoryGroups) => categoryGroups.flatMap((catGroup) => catGroup.categories),
);

export const selectCategoryIdToDeleteByCategoryValueIds = createSelector(
  (state: State) => state.categoryGroups,
  (_: any, categoryValuesId: number[]) => categoryValuesId,
  (categoryGroups, categoryValuesId) => categoryGroups.flatMap(
    (catGroup) => catGroup.categories,
  ).filter((category) => (
    (
      category.category_values.length === 0
      || !category.category_values.some(
        (catVal) => !categoryValuesId.includes(catVal.id),
      )
    )
  )).map((cat) => cat.id),
);

export const selectCategoryValuesById = createSelector(
  (state: State) => state.categoryGroups,
  (_: any, categoryValuesId: number[]) => categoryValuesId,
  (categoryGroups, categoryValuesId) => categoryGroups.flatMap(
    (catGroup) => catGroup.categories,
  ).filter((category) => (
    (
      category.category_values.length === 0
      || !category.category_values.every(
        (catVal) => !categoryValuesId.includes(catVal.id),
      )
    )
  )).flatMap((cat) => cat.category_values)
    .filter((catVal) => categoryValuesId.includes(catVal.id)),
);

export const {
  fetchCategoryGroups,
  fetchCategoryGroupsSuccess,
  fetchCategoryGroupsFailure,
  updateCategoryGroup,
  updateCategoryGroupSuccess,
  updateCategoryGroupFailure,
  addCategoryGroup,
  addCategoryGroupSuccess,
  addCategoryGroupFailure,
  deleteCategoryGroups,
  deleteCategoryGroupsSuccess,
  deleteCategoryGroupsFailure,
  updateCategory,
  updateCategorySuccess,
  updateCategoryFailure,
  createCategoryWithValues,
  createCategoryWithValuesSuccess,
  createCategoryWithValuesFailure,
  deleteCategorySuccess,
  addCategoryValues,
  addCategoryValuesSuccess,
  addCategoryValuesFailure,
  updateCategoryValue,
  updateCategoryValueSuccess,
  updateCategoryValueFailure,
  deleteCategoryValues,
  deleteCategoryValuesSuccess,
  deleteCategoryValuesFailure,
  updateCategoryValueMultiple,
  updateCategoryValueMultipleSuccess,
  updateCategoryValueMultipleFailure,
} = categoryGroupsSlice.actions;

export default categoryGroupsSlice.reducer;
