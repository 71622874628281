import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import { DATA_SOURCE } from 'features/dataFiles/CONSTANT';
import useDataFiles from 'features/dataFiles/state/useDataFiles';
import {
  getDataFileImportStatus,
  getDataFileErrorStatus,
  getDataFileErrorMessage,
} from 'features/dataFiles/utils/getDataFileStatus';
import { Project } from 'features/projects/types';
import SerpAnalysisView from 'features/serpAnalysis/components/SerpAnalysisView';
import { ANALYSIS_STATUS } from 'features/serpAnalysis/CONSTANT';
import useSerpAnalyses from 'features/serpAnalysis/state/useSerpAnalyses';
import { Analysis } from 'features/serpAnalysis/types';
import getRecalculKPIsStatus from 'utils/getRecalculKPIsStatus';

type SerpAnalysisProps = {
  project?: Project,
}

function SerpAnalysis(props: SerpAnalysisProps) {
  const [isRecalculatingKPIs, setIsRecalculatingKPIs] = useState<boolean>(false);
  const [isImportingKeywords, setIsImportingKeywords] = useState<boolean>(false);
  const [isImportKeywordsError, setIsImportKeywordsError] = useState<boolean>(false);
  const [importErrorMessage, setImportErrorMessage] = useState<string | null>(null);
  const [isProjectBudgetEnough, setIsProjectBudgetEnough] = useState(false);

  const { project } = props;

  useDocumentTitle(`${project?.name} : tableau de bord | benhur`);

  const recalculKPIS = project?.scopes[0]?.recalcul_kpis_running;
  const scopeId = project?.scopes[0]?.id ? project?.scopes[0]?.id : 0;
  const { dataFiles } = useDataFiles({ scopeId });

  const dataFilesSerpAnalysis = dataFiles.filter(
    (df) => df.data_source_id === DATA_SOURCE.serp_analysis_v3.id,
  );

  const { serpAnalyses: analyses, isLoading } = useSerpAnalyses({ scopeId, doFetch: false });

  const [idsSelected, setIdsSelected] = useState<number[]>(
    analyses
      .filter((an) => an.serp_analysis_status_id !== ANALYSIS_STATUS.IN_PROGRESS
        && !isRecalculatingKPIs)
      .map((an) => an.id),
  );

  useEffect(() => {
    setIsRecalculatingKPIs(getRecalculKPIsStatus(recalculKPIS));
    setIsImportingKeywords(getDataFileImportStatus(dataFilesSerpAnalysis));
    setIsImportKeywordsError(getDataFileErrorStatus(dataFilesSerpAnalysis));
    setImportErrorMessage(getDataFileErrorMessage(dataFilesSerpAnalysis));
  }, [scopeId, dataFiles]);

  useEffect(() => {
    setIdsSelected(analyses
      .filter((an) => an.serp_analysis_status_id !== ANALYSIS_STATUS.IN_PROGRESS
        && !isRecalculatingKPIs)
      .map((an) => an.id));
  }, [analyses]);

  useEffect(() => {
    if (project) {
      const budget = parseInt(project.project_budget, 10);
      setIsProjectBudgetEnough(budget > 0);
    }
  }, [project]);

  const rows: Analysis[] = analyses;

  const handleSelect = (ids: Array<number>) => {
    setIdsSelected(ids);
  };

  return (
    <Box>
      <SerpAnalysisView
        rows={rows}
        loadingSerpAnalyses={isLoading}
        idsSelected={idsSelected}
        handleSelect={handleSelect}
        isRecalculatingKPIs={isRecalculatingKPIs}
        project={project}
        projectName={project?.name || ''}
        scopeId={scopeId}
        isImportingKeywords={isImportingKeywords}
        isImportKeywordsError={isImportKeywordsError}
        importErrorMessage={importErrorMessage}
        isProjectBudgetEnough={isProjectBudgetEnough}
      />
    </Box>
  );
}

export default SerpAnalysis;
