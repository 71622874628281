import USER_TYPES from './CONSTANTS';
import { UserTypeType } from './types';

export default class UserType {
  id: number;

  label: string;

  is_external: boolean;

  private insertion_date: string;

  private active: boolean;

  constructor(userType: UserTypeType) {
    this.id = userType.id;
    this.label = userType.label;
    this.is_external = userType.is_external;
    this.insertion_date = userType.insertion_date;
    this.active = userType.active;
  }

  getProperty(propName: string) {
    return this[propName as keyof typeof this];
  }

  getIsAdmin() {
    return this.id === USER_TYPES.admin.id;
  }
}
