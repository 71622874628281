import React from 'react';

import PrivateRoute from 'components/privateRoute/PrivateRoute';
import { CustomRouteObject } from 'pages/projects/types';

import Profile from './Profile';

const routes: CustomRouteObject[] = [
  {
    path: '',
    element: <PrivateRoute element={<Profile />} />,
  },
];

export default routes;
