import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import downloadFile from 'utils/downloadFile';

import ApiKpiView from './api';
import {
  fetchKpiViewRequested,
  fetchKpiViewSuccess,
  fetchKpiViewFailure,
  createKpiViewRequested,
  createKpiViewSuccess,
  createKpiViewFailure,
  updateKpiViewRequested,
  updateKpiViewSuccess,
  updateKpiViewFailure,
  deleteKpiViewRequested,
  deleteKpiViewSuccess,
  deleteKpiViewFailure,
  downloadKpiViewSuccess,
  downloadKpiViewFailure,
  downloadKpiViewRequested,
} from './slice';
import {
  KpiViewCreateUpdate, UpdatePayload, KpiViewDeletePayload, KpiViewDownloadPayload,
} from '../types';

export function* workFetchKpiViews(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(ApiKpiView.fetchKpiViews, action.payload);
    const kpiViews = response.data;
    yield put(fetchKpiViewSuccess(kpiViews));
  } catch (e: any) {
    let error = e.message;
    if (e.response?.data?.message) {
      error = e.response.data.message;
    }
    yield put(fetchKpiViewFailure(error));
  }
}

export function* workCreateKpiView(action: PayloadAction<KpiViewCreateUpdate>) {
  try {
    const response: AxiosResponse = yield call(ApiKpiView.createKpiView, action.payload);
    const kpiView = response.data;
    yield put(createKpiViewSuccess(kpiView));
  } catch (e: any) {
    let error = e.message;
    if (e.response?.data?.message) {
      error = e.response.data.message;
    }
    yield put(createKpiViewFailure(error));
  }
}

export function* workUpdateKpiView(action: PayloadAction<UpdatePayload>) {
  try {
    const response: AxiosResponse = yield call(ApiKpiView.updateKpiView, action.payload);
    const kpiView = response.data;
    yield put(updateKpiViewSuccess(kpiView));
  } catch (e: any) {
    let error = e.message;
    if (e.response?.data?.message) {
      error = e.response.data.message;
    }
    yield put(updateKpiViewFailure(error));
  }
}

export function* workDeleteKpiView(action: PayloadAction<KpiViewDeletePayload>) {
  try {
    const response: AxiosResponse = yield call(ApiKpiView.deleteKpiViews, action.payload);
    const kpiView = response.data;
    yield put(deleteKpiViewSuccess(kpiView));
  } catch (e: any) {
    let error = e.message;
    if (e.response?.data?.message) {
      error = e.response.data.message;
    }
    yield put(deleteKpiViewFailure(error));
  }
}

export function* workDownloadKpiView(action: PayloadAction<KpiViewDownloadPayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiKpiView.downloadKpiView,
      action.payload.viewId,
    );

    const file = new Blob([response.data], {
      type: 'text/csv;charset=utf-8;',
    });

    downloadFile(file, `data-${action.payload.viewId}.csv`);
    yield put(downloadKpiViewSuccess());
  } catch (e: any) {
    yield put(downloadKpiViewFailure(e.message));
  }
}

export function* sagaFetchKpiViews() {
  yield takeEvery(fetchKpiViewRequested.type, workFetchKpiViews);
}

export function* sagaCreateKpiViews() {
  yield takeEvery(createKpiViewRequested.type, workCreateKpiView);
}

export function* sagaUpdateKpiViews() {
  yield takeEvery(updateKpiViewRequested.type, workUpdateKpiView);
}

export function* sagaDeleteKpiViews() {
  yield takeEvery(deleteKpiViewRequested.type, workDeleteKpiView);
}

export function* sagaDownloadKpiViews() {
  yield takeEvery(downloadKpiViewRequested.type, workDownloadKpiView);
}
