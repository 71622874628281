import React from 'react';
import { Navigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { useAppSelector } from 'common/reduxHooks';
import Login from 'pages/authentication/Login';
import { PROJECTS } from 'router/CONSTANTS';

function Home(): JSX.Element {
  const {
    isLoggedIn,
    token,
  } = useAppSelector(
    (state) => state.authentication,
  );

  return (
    <Box>
      {
        (!isLoggedIn)
          ? (
            <Login />
          )
          : (
            token.access_token && <Navigate to={PROJECTS} />
          )
        }
    </Box>
  );
}

export default Home;
