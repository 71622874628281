import React, { useState } from 'react';

import { Box, Typography, MenuItem } from '@mui/material';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import { deleteAnalyses } from 'features/serpAnalysis/state/slice';

import DeleteAnalysesButton from '../buttons/DeleteAnalysesButton';

type DeleteAnalysisProps = {
  ids: number[],
  handleCloseMenu?: Function,
  isButton: boolean,
  disabled?: boolean,
}

function DeleteAnalysis (props: DeleteAnalysisProps) {
  const {
    ids,
    handleCloseMenu,
    isButton,
    disabled,
  } = props;
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleConfirm = () => {
    if (ids.length === 0) return;
    dispatch(deleteAnalyses(ids));
    setOpenModal(false);
  };

  return (
    <Box data-testid="delete-analysis-box">
      {
        isButton
          ? (
            <DeleteAnalysesButton
              handleOpenModal={() => setOpenModal(true)}
              disabled={disabled}
            />
          )
          : (
            <MenuItem
              sx={{ fontSize: globalTheme.fontSize.middlesmall }}
              onClick={() => { setOpenModal(true); handleCloseMenu?.(); }}
              disabled={disabled}
            >
              Supprimer le groupe
            </MenuItem>
          )
      }

      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">Supprimer les mots clés</Typography>
            {' '}
            et les données associées
          </Typography>
        )}
        actionConfirm={handleConfirm}
        actionCancel={() => setOpenModal(false)}
        isOpen={openModal}
        dataTestId="delete-analysis-modal"
      />
    </Box>
  );
}

export default DeleteAnalysis;
