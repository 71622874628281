/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import SerpAnalysisPlanning from '../types';

export interface State {
  isLoading: boolean;
  plannings: SerpAnalysisPlanning[]
  error: string;
}

const initialState: State = {
  isLoading: false,
  plannings: [],
  error: '',
};

export const serpAnalysisPlanningSlice = createSlice({
  name: 'serpAnalysisPlanning',
  initialState,
  reducers: {
    fetchSerpAnalysisPlanning: (state: State) => {
      state.isLoading = true;
    },
    fetchSerpAnalysisPlanningSuccess: (
      state: State,
      action: PayloadAction<SerpAnalysisPlanning[]>,
    ) => {
      state.plannings = action.payload;
      state.isLoading = false;
    },
    fetchSerpAnalysisPlanningFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const selectPlanningById = createSelector(
  (state: State) => state.plannings,
  (_: any, id: number) => id,
  (plannings, id) => plannings.find((pl) => pl.id === id),
);

export const {
  fetchSerpAnalysisPlanning,
  fetchSerpAnalysisPlanningSuccess,
  fetchSerpAnalysisPlanningFailure,
} = serpAnalysisPlanningSlice.actions;

export default serpAnalysisPlanningSlice.reducer;
