import React from 'react';

import { Box, Button, MenuItem } from '@mui/material';
import { FormikProps } from 'formik';

import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';

import { SerpAnalysisForm } from './createAnalysisConfig';
import CreateSaForm from './Form';
import AddKwModalTitle from './ModalTitle';

type FormModalProps = {
  formik: FormikProps<SerpAnalysisForm>,
  openModal: boolean,
  isAnalysisLoading: boolean,
  analysisError: string,
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
  setIsConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>,
  handleOpenMainModal: Function,
  isImportingKw: boolean,
  isButton?: boolean,
  buttonText?: string,
  disabled?: boolean,
}

function CreateSaFormModal(props: FormModalProps) {
  const {
    setOpenModal,
    formik,
    setIsConfirmationOpen,
    openModal,
    isAnalysisLoading,
    analysisError,
    handleOpenMainModal,
    isImportingKw,
    isButton = false,
    buttonText = 'Ajouter des mots clés',
    disabled,
  } = props;
  const { handleSubmit } = formik;

  return (
    <Box>
      {
        isButton
          ? (
            <Button
              variant="contained"
              size="small"
              onClick={() => handleOpenMainModal()}
              disabled={isImportingKw || disabled}
              data-testid="button-create-serp-manually"
            >
              {buttonText}
            </Button>
          )
          : (
            <MenuItem
              sx={{ fontSize: globalTheme.fontSize.middlesmall }}
              onClick={() => handleOpenMainModal()}
              disabled={isImportingKw || disabled}
              data-testid="menu-item-create-serp-manually"
            >
              Manuellement
            </MenuItem>
          )
      }
      <Modal
        title={<AddKwModalTitle />}
        actionConfirm={handleSubmit}
        actionCancel={() => {
          setOpenModal(false);
          setIsConfirmationOpen(false);
          formik.resetForm();
        }}
        isOpen={openModal}
        isProcessLoading={isAnalysisLoading}
        displayLoading
        dataTestId="create-sa-form-modal"
      >
        <CreateSaForm
          formik={formik}
          error={analysisError}
        />
      </Modal>
    </Box>
  );
}

export default CreateSaFormModal;
