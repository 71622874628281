import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import ApiFiscalYear from './api';
import {
  fetchFiscalYears,
  fetchFiscalYearsSuccess,
  fetchFiscalYearsFailure,
} from './slice';
import { FiscalYearType } from '../types';

function* workFetchFiscalYear() {
  try {
    const response: AxiosResponse = yield call(ApiFiscalYear.fetchFiscalYears);
    const fiscalYears: FiscalYearType[] = response.data;
    yield put(fetchFiscalYearsSuccess(fiscalYears));
  } catch (e: any) {
    yield put(fetchFiscalYearsFailure(e.message));
  }
}

export default function* sagaFetchFiscalYears() {
  yield takeEvery(fetchFiscalYears, workFetchFiscalYear);
}
