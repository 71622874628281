import React, { useState } from 'react';

import { MenuItem } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import {
  selectCategoryIdToDeleteByCategoryValueIds,
  deleteCategoryValues,
} from 'features/categoryGroups/state/slice';

import DeleteCategoryValueModal from '../components/DeleteCategoryValueModal';

type DeleteCategoryValueActionProps = {
  categoryValueId: number,
  handleCloseMenu: Function,
  categoryGroupType: number,
}

function DeleteCategoryValueAction(props: DeleteCategoryValueActionProps) {
  const { categoryValueId, handleCloseMenu, categoryGroupType } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useAppDispatch();
  const categoryGroups = useAppSelector((state) => state.categoryGroups);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const onConfirm = () => {
    const categoryIdsToDelete = selectCategoryIdToDeleteByCategoryValueIds(
      categoryGroups,
      [categoryValueId],
    );

    dispatch(deleteCategoryValues({
      category_value_ids: [categoryValueId],
      category_to_delete: categoryIdsToDelete,
    }));
    handleClose();
  };

  return (
    <MenuItem
      sx={{ fontSize: globalTheme.fontSize.middlesmall }}
      onClick={() => { if (!isModalOpen) setIsModalOpen(true); handleCloseMenu(); }}
      data-testid="delete-category-value-menu-item"
    >
      Supprimer
      <DeleteCategoryValueModal
        categoryValueIds={[categoryValueId]}
        isOpen={isModalOpen}
        onConfirm={onConfirm}
        handleClose={handleClose}
        categoryGroupType={categoryGroupType}
      />
    </MenuItem>
  );
}

export default DeleteCategoryValueAction;
