import globalTheme from 'common/theme';
import { ActorType } from 'features/actors/types';

export function getBarColor(actorName: string, actorsList: ActorType[]) {
  const actor = actorsList.find((atr) => atr.name === actorName);
  if (actor?.dataviz_color) {
    return actor.dataviz_color;
  }
  if (actor?.is_client) {
    return globalTheme.palette.secondary.main;
  }
  if (actor && !actor?.is_client) {
    return globalTheme.palette.primary.light;
  }
  return globalTheme.palette.info.main;
}

export function getMissingActors(
  actorsList: ActorType[],
  actorsInParams: number[],
  actorsWithValues: any[] | undefined,
) {
  return actorsList.filter((actor) => actorsInParams.includes(actor.id ?? 0))
    .map((actor) => actor.name)
    .filter(
      (actorName) => actorsWithValues && !actorsWithValues.includes(actorName),
    );
}
