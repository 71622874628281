/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import SerpAnalysisScrapping from 'features/serpAnalysisScrapping/types';

import {
  SerpAnalysisFetchPayload,
  SerpAnalysisUpdatePayload,
  Analysis,
  AnalysisCreate,
  Scrape,
} from '../types';

export interface State {
  isLoading: boolean;
  serpAnalyses: Analysis[];
  error: string;
  isSuccess: boolean;
}

const initialState: State = {
  isLoading: false,
  serpAnalyses: [],
  error: '',
  isSuccess: false,
};

export const SerpAnalysesSlice = createSlice({
  name: 'serpAnalyses',
  initialState,
  reducers: {
    fetchSerpAnalyses: (state: State, action: PayloadAction<SerpAnalysisFetchPayload>) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    fetchSerpAnalysesSuccess: (state: State, action: PayloadAction<Analysis[]>) => {
      state.serpAnalyses = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    fetchSerpAnalysesFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isSuccess = false;
    },
    updateSerpAnalyses: (state: State, action: PayloadAction<SerpAnalysisUpdatePayload[]>) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    updateSerpAnalysesSuccess: (state: State, action: PayloadAction<Analysis[]>) => {
      const updatedSerpIds = action.payload.map((serpAnalysis) => serpAnalysis.id);

      state.serpAnalyses = state.serpAnalyses
        .map((serpAnalysis) => (
          updatedSerpIds.includes(serpAnalysis.id)
            ? action.payload.find(
              (updatedSerpAnalysis) => updatedSerpAnalysis.id === serpAnalysis.id,
            ) as Analysis
            : serpAnalysis
        ));

      state.isLoading = false;
      state.isSuccess = true;
    },
    updateSerpAnalysesFailure: (state: State, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isSuccess = false;
    },
    deleteAnalyses: (state: State, action: PayloadAction<Array<number>>) => {
      state.isLoading = true;
    },
    deleteAnalysesSuccess: (state: State, action: PayloadAction<Array<number>>) => {
      state.serpAnalyses = state.serpAnalyses.filter(
        (an) => !action.payload.find((itemToDelete) => itemToDelete === an.id),
      );
      state.isLoading = false;
    },
    deleteAnalysesFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createAnalysis: (state: State, action: PayloadAction<AnalysisCreate>) => {
      state.isLoading = true;
    },
    createAnalysisSuccess: (state: State, action: PayloadAction<Analysis>) => {
      state.serpAnalyses.push(action.payload);
      state.isLoading = false;
    },
    createAnalysisFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createSerpAnalysisScrapping: (
      state: State,
      action: PayloadAction<Scrape>,
    ) => {
      state.isLoading = true;
    },
    createSerpAnalysisScrappingSuccess: (
      state: State,
      action: PayloadAction<SerpAnalysisScrapping>,
    ) => {
      state.serpAnalyses = state.serpAnalyses.map(
        (an) => {
          if (an.id === action.payload.serp_analysis_id) {
            an.serp_analysis_scrappings = an.serp_analysis_scrappings
              ? [...an.serp_analysis_scrappings, action.payload]
              : [action.payload];
          }
          return an;
        },
      );
      state.isLoading = false;
    },
    createSerpAnalysisScrappingFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetError: (state: State) => {
      state.error = '';
    },
  },
});

export const selectSerpAnalysesByScopeId = createSelector(
  (state: State) => state.serpAnalyses,
  (_: any, scopeId: number) => scopeId,
  (SerpAnalyses, scopeId) => SerpAnalyses.filter((sa) => sa.scope_id === scopeId),
);

export const selectAnalysisById = createSelector(
  (state: State) => state.serpAnalyses,
  (_: any, id: number | string | undefined) => id,
  (analyses, id) => {
    if (id === undefined) return undefined;
    let analyseId = id;
    if (typeof id === 'string') {
      analyseId = parseInt(id, 10);
    }
    const analyse = analyses.find((an) => an.id === analyseId);
    return analyse;
  },
);

export const selectMultipleById = createSelector(
  (state: State) => state.serpAnalyses,
  (_: any, ids: number[] | undefined) => ids,
  (analyses, ids) => {
    if (ids === undefined) return [];
    const filteredAnalyses = analyses.filter((an) => ids.find((id) => an.id === id));
    return filteredAnalyses;
  },
);

export const selectAnalysisWithLeastKeywords = createSelector(
  (state: State) => state.serpAnalyses,
  (analyses) => analyses.reduce((minAnalysis, currentAnalysis) => (
    currentAnalysis.keywords_count < minAnalysis.keywords_count
      ? currentAnalysis
      : minAnalysis
  ), analyses[0])
  ,
);

export const {
  fetchSerpAnalyses,
  fetchSerpAnalysesSuccess,
  fetchSerpAnalysesFailure,
  updateSerpAnalyses,
  updateSerpAnalysesSuccess,
  updateSerpAnalysesFailure,
  deleteAnalyses,
  deleteAnalysesSuccess,
  deleteAnalysesFailure,
  createAnalysis,
  createAnalysisSuccess,
  createAnalysisFailure,
  createSerpAnalysisScrapping,
  createSerpAnalysisScrappingSuccess,
  createSerpAnalysisScrappingFailure,
  resetError,
} = SerpAnalysesSlice.actions;

export default SerpAnalysesSlice.reducer;
