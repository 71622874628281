import React, { useEffect, useState } from 'react';

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Box, Button, Typography } from '@mui/material';
import { useFormik } from 'formik';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import RenameModal from 'components/modal/RenameModal';
import { updateCategory } from 'features/categoryGroups/state/slice';

import { updateValuesCategory, validateUpdateValuesCategory } from './setupConfig';
import { UpdateValuesCategoryForm } from '../type';

type RenameCategoryProps = {
  disabled?: boolean,
  categoryGroupId: number,
  categoryGroupType: number,
}

function RenameCategory(props: RenameCategoryProps) {
  const {
    disabled = false,
    categoryGroupId,
    categoryGroupType,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { isLoading, categoryGroups } = useAppSelector((state) => (state.categoryGroups));

  const categories = categoryGroups
    .filter((catGroup) => catGroup.category_type_id === categoryGroupType)
    .find((catGroup) => catGroup.id === categoryGroupId)
    ?.categories ?? [];
  const sortedCategories = [...categories].sort((cat1, cat2) => cat1.name.localeCompare(cat2.name));

  const handleSubmit = (
    values: UpdateValuesCategoryForm,
  ) => {
    dispatch(updateCategory({
      category: {
        name: values.newName,
        category_group_id: categoryGroupId,
      },
      category_id: values.categoryId as number,
    }));
    setInternalLoading(true);
  };

  const formik = useFormik<UpdateValuesCategoryForm>({
    initialValues: updateValuesCategory,
    validationSchema: validateUpdateValuesCategory,
    onSubmit: handleSubmit,
  });

  const handleCancel = () => {
    setIsOpen(false);
    formik.resetForm();
  };

  useEffect(() => {
    if (!isLoading && internalLoading) {
      setInternalLoading(false);
      setIsOpen(false);
      formik.resetForm();
    }
  }, [isLoading, internalLoading]);

  return (
    <Box>
      <Button
        variant="contained"
        onClick={
          () => setIsOpen(true)
        }
        size="small"
        startIcon={<DriveFileRenameOutlineIcon />}
        data-testid="rename-category-value-button"
        disabled={disabled}
      >
        Renommer une catégorie
      </Button>
      <RenameModal
        title={(
          <Typography>
            <Typography component="span" variant="bold">
              Renommer
              {' '}
            </Typography>
            une catégorie
          </Typography>
        )}
        label="Catégorie d'URL"
        selectOptions={sortedCategories}
        field={{
          select: {
            getLabel: (cat) => cat.name,
            getValue: (cat) => cat.id,
            name: 'categoryId',
          },
          textField: { name: 'newName' },
        }}
        value={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        onChange={formik.handleChange}
        onSubmit={formik.handleSubmit}
        isOpen={isOpen}
        onCancel={handleCancel}
        isLoading={internalLoading}
      />
    </Box>
  );
}

export default RenameCategory;
