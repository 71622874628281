import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import useDocumentTitle from 'common/documentTitleHook';
import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import useActors from 'features/actors/state/useActors';
import { DatavizFilters } from 'features/dataviz/types';
import { DEVICE_TYPE } from 'features/deviceType/CONSTANTS';
import { selectProjectById } from 'features/projects/state/slice';
import {
  DEFAULT_RANK_MAX,
  DEFAULT_RANK_MIN,
  DEFAULT_SA_ID,
  DEFAULT_SITE_COUNT,
  DEFAULT_VOL_MAX,
  DEFAULT_VOL_MIN,
} from 'features/serpAnalysis/components/serpDetails/parametersBlock/CONSTANT';
import SerpAnalysisDatavizDetailsView
  from 'features/serpAnalysis/components/serpDetails/SerpAnalysisDatavizDetailsView';
import {
  fetchSerpAnalyses,
  selectAnalysisById,
} from 'features/serpAnalysis/state/slice';
import useSerpAnalysisScrappings
  from 'features/serpAnalysisScrapping/state/useSerpAnalysisScrappings';
import {
  sortScrappingsAndFindScrappingFromUrl,
} from 'features/serpAnalysisScrapping/utils';
import getRecalculKPIsStatus from 'utils/getRecalculKPIsStatus';
import { generateAnalysisUrl, generateProjectBaseUrl, splitIdName } from 'utils/urlManipulation';

import { serpAnalysisDetailsRouting } from './config';

function SerpAnalysisDatavizDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isRecalculatingKPIs, setIsRecalculatingKPIs] = useState<boolean>(false);

  const {
    idNameProject, idNameAnalysis, feature,
  } = useParams();
  const { id: idProject } = splitIdName(idNameProject);
  const { id: idAnalysis } = splitIdName(idNameAnalysis);
  const idScrapping = parseInt(searchParams.get('idScrapping') ?? '', 10);
  const dvMode = searchParams.get('dvMode') ?? '';
  const sitesCount = searchParams.get('sitesCount') ?? `${DEFAULT_SITE_COUNT}`;
  const searchIntentId = searchParams.get('searchIntentId') ?? `${DEFAULT_SA_ID}`;
  const rankMin = searchParams.get('rankMin') ?? `${DEFAULT_RANK_MIN}`;
  const rankMax = searchParams.get('rankMax') ?? `${DEFAULT_RANK_MAX}`;
  const volMin = searchParams.get('volMin') ?? `${DEFAULT_VOL_MIN}`;
  const volMax = searchParams.get('volMax') ?? `${DEFAULT_VOL_MAX}`;
  const bestRankMode = searchParams.get('bestRankMode') === 'true';

  const project = useAppSelector((state) => (
    selectProjectById(state.projects, idProject)));
  const { isLoading: isProjectLoading } = useAppSelector((state) => state.projects);
  const scopeId = project?.scopes[0]?.id ? project.scopes[0].id : undefined;
  const analysis = useAppSelector((state) => (
    selectAnalysisById(state.serpAnalyses, idAnalysis)));

  const calculMaxVol = () => {
    if (analysis && parseInt(volMax, 10) > analysis.max_volume) {
      return analysis.max_volume.toString();
    }

    return volMax;
  };

  const datavizFilters: DatavizFilters = {
    type: 'datavizFilters',
    dvMode,
    sitesCount,
    searchIntentId,
    rankMin,
    rankMax,
    volMin,
    volMax: calculMaxVol(),
    bestRankMode,
  };

  let deviceType = parseInt(searchParams.get('deviceType') ?? `${analysis?.device_type_id}`, 10);
  // If analysis has both device types, pick mobile by default
  if (deviceType === DEVICE_TYPE.DESKTOP_MOBILE) deviceType = DEVICE_TYPE.MOBILE;

  const detailsToDisplay = serpAnalysisDetailsRouting.find(
    (page) => (feature ? page.label === feature : page.label === ''),
  );
  const { scrappings } = useSerpAnalysisScrappings(analysis?.id || 0);

  const { scrappingSelected, scrappingsSorted } = sortScrappingsAndFindScrappingFromUrl(
    scrappings,
    idScrapping,
    deviceType,
  );
  const recalculKPIS = project?.scopes[0]?.recalcul_kpis_running;
  useEffect(() => {
    if (scopeId && idAnalysis !== undefined && !analysis) {
      dispatch(fetchSerpAnalyses({ scopeId }));
    }
    setIsRecalculatingKPIs(getRecalculKPIsStatus(recalculKPIS));
  }, [scopeId, scrappingSelected?.scrapping_date, analysis]);

  const { actors } = useActors({ scopeId: scopeId || 0 });

  const pageName = detailsToDisplay?.pageName;
  if (!pageName) {
    navigate(
      project
        ? `${generateProjectBaseUrl(project.id, project.name)}`
        : '/',
    );
  }

  useDocumentTitle(`${pageName} : ${analysis?.label} | benhur`);

  useEffect(() => {
    if (scopeId && idAnalysis !== undefined && !analysis) {
      dispatch(fetchSerpAnalyses({ scopeId }));
    }
  }, []);

  const baseUrl = (
    project
    && analysis
    && generateAnalysisUrl(
      project.id,
      project?.name,
      analysis.id,
      analysis.label,
      feature,
      undefined,
    )
  ) || '';

  return (
    <SerpAnalysisDatavizDetailsView
      scopeId={scopeId}
      project={project}
      analysis={analysis}
      detailsToDisplay={detailsToDisplay}
      scrappingsSorted={scrappingsSorted}
      scrappingSelected={scrappingSelected}
      baseUrl={baseUrl}
      isRecalculatingKPIs={isRecalculatingKPIs}
      isLoading={(isProjectLoading || !project || !analysis)}
      actors={actors}
      scrappingId={scrappingSelected?.id}
      feature={feature}
      datavizFilters={datavizFilters}
    />
  );
}

export default SerpAnalysisDatavizDetails;
