import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import { RootState } from 'common/store';

import {
  fetchCategoryGroups,
  addCategoryGroup,
  selectCategoryGroupsByScopeIdAndType,
} from './slice';
import { CategoryGroupCreate } from '../types';

/**
  * doFetch is used as a quick fix to prevent an infinite loop if the previous fetch data is empty
  * @todo: Remove doFetch when the store values will be updated
  */
const useCategoryGroups = (scopeId: number, catGroupTypeId: number, doFetch: boolean = true) => {
  const dispatch = useAppDispatch();

  const { isLoading, error } = useAppSelector(
    (state: RootState) => state.categoryGroups,
  );
  const categoryGroups = useAppSelector(
    (state) => selectCategoryGroupsByScopeIdAndType(
      state.categoryGroups,
      scopeId,
      catGroupTypeId,
    ),
  );

  useEffect(() => {
    if (!isLoading && scopeId && categoryGroups.length === 0 && doFetch) {
      dispatch(fetchCategoryGroups({ scopeId }));
    }
  }, [dispatch, fetchCategoryGroups, scopeId]);

  const createCategoryGroup = (newGroup: CategoryGroupCreate) => {
    dispatch(addCategoryGroup({ value: newGroup, withCategories: false }));
  };

  return {
    isLoading,
    error,
    categoryGroups,
    createCategoryGroup,
  };
};

export default useCategoryGroups;
