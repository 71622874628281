import { useState } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import { routesConfig } from 'router/RouterConfig';

import { handleNavbar } from './slice';

const useNavbar = () => {
  const dispatch = useAppDispatch();
  const { isOpen: isOpenState } = useAppSelector(
    (state) => state.navbar,
  ); useState<boolean | undefined>(undefined);

  const location = useLocation();
  const match = matchRoutes(routesConfig, location);
  const route = match?.pop();
  const defaultCloseMenu = route?.route?.defaultCloseMenu ?? false;

  // handle isOpen undefined and default isOpen by page
  const isOpen = isOpenState !== undefined ? isOpenState : !defaultCloseMenu;

  const openNavbar = () => {
    dispatch(handleNavbar(true));
  };

  const closeNavbar = () => {
    dispatch(handleNavbar(false));
  };
  return {
    isOpen,
    openNavbar,
    closeNavbar,
  };
};

export default useNavbar;
