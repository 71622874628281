import globalTheme from 'common/theme';

import { RankSliceType } from './types';

export const empty = null;

export const RANK_SLICES: RankSliceType[] = [
  {
    label: '1-3',
    className: 'cell-color-1-3',
    range: [1, 3],
    backgroundColor: globalTheme.palette.dataviz[5],
    color: '#FFFFFF',
    id: 1,
  },
  {
    label: '4-10',
    className: 'cell-color-4-10',
    range: [4, 10],
    backgroundColor: globalTheme.palette.dataviz[4],
    color: '#FFFFFF',
    id: 2,
  },
  {
    label: '11-20',
    className: 'cell-color-11-20',
    range: [11, 20],
    backgroundColor: globalTheme.palette.dataviz[3],
    color: '#000000',
    id: 3,
  },
  {
    label: '21-50',
    className: 'cell-color-21-50',
    range: [21, 50],
    backgroundColor: globalTheme.palette.dataviz[2],
    color: '#000000',
    id: 6,
  },
  {
    label: '50+',
    className: 'cell-color-50-more',
    range: [51, Infinity],
    backgroundColor: globalTheme.palette.dataviz[1],
    color: '#000000',
    id: 7,
  },
] as const;
