import moment from 'moment';

import { ActorType } from 'features/actors/types';
import { CategoryGroup } from 'features/categoryGroups/types';
import { RANKING_TYPE } from 'features/dataviz/CONSTANTS';
import { PeriodType } from 'features/period/types';
import { RankingTypeType } from 'features/rankingTypes/types';
import { Analysis } from 'features/serpAnalysis/types';
import { DATE_ENGLISH, convertDate } from 'utils/formatDate';

import determineRankSliceFromQuery from './determineRankSlice';
import {
  DEFAULT_METRIC,
  DEFAULT_PERIODE,
  DEFAULT_RANK_SLICES,
  METRICS,
  defaultFiltersValue,
} from '../CONSTANT';
import { MetricType, PerformancePages } from '../types';

export default class DefaultPerformanceFilters {
  private currentPage: PerformancePages = 'actor';

  private periods: PeriodType[];

  private actors: ActorType[];

  private serpAnalysis?: Analysis[];

  private categoryGroups?: CategoryGroup[] = [];

  private defaultBestRank: boolean = false;

  private defaultMetrics = METRICS;

  private defaultDefaultRankSlices = DEFAULT_RANK_SLICES;

  private defaultRankingType = [RANKING_TYPE.ORGANIC, RANKING_TYPE.FEATURED_SNIPPET];

  private rankingTypes?: RankingTypeType[] = [];

  private defaultIncludeUncategorized: boolean = false;

  constructor(
    page: PerformancePages,
    periods: PeriodType[],
    actors: ActorType[],
    serpAnalysis?: Analysis[],
    categoryGroups?: CategoryGroup[],
    rankingTypes?: RankingTypeType[],
  ) {
    this.currentPage = page;
    this.periods = periods;
    this.actors = actors;
    this.serpAnalysis = serpAnalysis;
    this.categoryGroups = categoryGroups;
    this.rankingTypes = rankingTypes;
  }

  public getDefaultPeriod(periodParam?: string | null) {
    const periods = this.periods.map((period) => period.period);

    if (!periodParam) {
      if (periods.length) return convertDate(moment(periods[0]).toDate(), DATE_ENGLISH);

      return DEFAULT_PERIODE;
    }

    const isPeriodParamValid = periods.find((period) => period.includes(periodParam));

    if (isPeriodParamValid) return periodParam;

    return convertDate(moment(periods[0]).toDate(), DATE_ENGLISH);
  }

  public getDefaultMetric(metric?: string | null) {
    return this.defaultMetrics.includes(metric as any)
      ? metric as MetricType
      : DEFAULT_METRIC;
  }

  public getDefaultRankSlice(rankSlice?: string[] | null): number[] {
    return rankSlice?.length
      ? determineRankSliceFromQuery(rankSlice)
      : this.defaultDefaultRankSlices.map((rank) => rank.id);
  }

  public getDefaultPeriodBegin(periodParam?: string | null) {
    const periods = this.periods.map((period) => period.period);

    if (!periodParam) {
      if (periods.length) return moment(periods[0]).startOf('month').format();

      return defaultFiltersValue.periodeBegin;
    }

    const isPeriodParamValid = periods.find((period) => period.includes(periodParam));

    if (isPeriodParamValid) return moment(periodParam).startOf('month').format();

    return moment(periods[0]).startOf('month').format();
  }

  public getDefaultPeriodEnd(periodParam?: string | null) {
    const periods = this.periods.map((period) => period.period);

    if (!periodParam) {
      if (periods.length) return moment(periods[0]).endOf('month').format();

      return defaultFiltersValue.periodeEnd;
    }

    const isPeriodParamValid = periods.find((period) => period.includes(periodParam));

    if (isPeriodParamValid) return moment(periodParam).endOf('month').format();

    return moment(periods[0]).endOf('month').format();
  }

  public getDefaultActors(actorIdsParamString?: string[] | null) {
    const actorIdsParam = actorIdsParamString
      ?.map((actor) => Number(actor))
      .filter((actorId) => actorId !== undefined);

    if (!this.actors.length) return [];

    if (this.currentPage === 'kpi' && !actorIdsParam?.length) {
      return this.actors
        .filter((actor) => actor.is_client)
        .map((actor) => actor.id ?? 0);
    }

    return this.actors
      .map((actor) => actor.id ?? 0)
      .filter((actorId) => (actorIdsParam?.length ? actorIdsParam.includes(actorId) : true));
  }

  public getDefaultSerpAnalysis(serpAnalysisIdsParamString?: string[] | null) {
    if (
      !serpAnalysisIdsParamString
      || !this.serpAnalysis
      || !this.serpAnalysis.length
    ) return [];

    const serpAnalysisIdsParam = serpAnalysisIdsParamString
      .map((serpAnalysis) => Number(serpAnalysis))
      .filter((serpAnalysisId) => serpAnalysisId !== undefined);

    return this.serpAnalysis
      .map((serpAnalysis) => serpAnalysis.id ?? 0)
      .filter((serpAnalysisIds) => (serpAnalysisIdsParam.includes(serpAnalysisIds)));
  }

  public getDefaultCategoryGroup(categoryGroupId?: number | null) {
    if (!this.categoryGroups || !this.categoryGroups.length) return undefined;

    const catagoryGroupParam = categoryGroupId;
    const findCategoryGroup = this.categoryGroups.find(
      (categoryGroup) => categoryGroup.id === catagoryGroupParam,
    );
    if (catagoryGroupParam && findCategoryGroup) return catagoryGroupParam;

    if (this.currentPage === 'url') {
      return this.categoryGroups[0].id;
    }

    return undefined;
  }

  public getDefaultCategory(categoryGroupId?: number | null, categoryIds?: string[] | null) {
    if (!this.categoryGroups?.length) return [];
    const findCategoryGroup = this.getDefaultCategoryGroup(categoryGroupId);
    const categoryGroup = this.categoryGroups.find((catG) => catG.id === findCategoryGroup);

    const catGCategoryIds = categoryGroup?.categories.map((category) => category.id);

    if (!catGCategoryIds || !catGCategoryIds.length) return [];
    const findCategory = categoryIds
      ?.map((category) => Number(category))
      .filter((category) => category !== undefined
      && catGCategoryIds?.includes(category) && category !== 0);
    if (findCategory && findCategory.length) {
      return findCategory;
    }

    if (this.currentPage === 'url' && catGCategoryIds?.length) return catGCategoryIds;

    return [];
  }

  public getDefaultRankingTypeIds(
    rankingTypeIds?: string[] | null,
  ) {
    const defaultValue = (this.currentPage === 'serp' && this.rankingTypes)
      ? this.rankingTypes.map((rt) => parseInt(rt.id.toString(), 10))
      : this.defaultRankingType;
    if (!this.rankingTypes) return defaultValue;
    if (rankingTypeIds) {
      const rankingTypeIdsFromString = rankingTypeIds
        .map((id) => parseInt(id, 10))
        .filter((value) => !Number.isNaN(value));

      if (rankingTypeIdsFromString.length) return rankingTypeIdsFromString;

      return defaultValue;
    }
    return defaultValue;
  }

  public getDefaultBestRankMode(bestRankMode?: string | null) {
    if (!bestRankMode) return this.defaultBestRank;

    return bestRankMode === 'true';
  }

  public getDefaultAggregateMode() {
    if (this.currentPage === 'kpi') return true;

    return false;
  }

  public getDefaultIncludeUncategorized(includeUncategorized?: string | null) {
    if (!includeUncategorized) return this.defaultIncludeUncategorized;

    return includeUncategorized === 'true';
  }
}
