import React from 'react';

import {
  Box, Typography, styled, BoxProps,
} from '@mui/material';

import CustomAlert from 'components/alert/CustomAlert';
import MainBox from 'components/layout/MainBox';
import { Project } from 'features/projects/types';
import ActionButton from 'features/serpAnalysis/components/buttons/ActionButton';
import ConfigurationContainer from 'features/serpAnalysis/list/ConfigurationContainer';
import SerpAnalysisDataGrid from 'features/serpAnalysis/list/SerpAnalysisDataGrid';
import { Analysis } from 'features/serpAnalysis/types';

type DataGridSerpsViewProps = {
  loadingSerpAnalyses: boolean,
  rows: Analysis[],
  handleSelect: Function,
  idsSelected: number[],
  isRecalculatingKPIs: boolean,
  project?: Project,
  projectName: string,
  scopeId: number,
  isImportingKeywords: boolean,
  isImportKeywordsError: boolean,
  importErrorMessage: string | null,
  isProjectBudgetEnough?: boolean
}

const PREFIX = 'SerpAnalysisView';

const classes = {
  importError: `${PREFIX}-importError`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.importError}`]: {
    color: theme.palette.error.main,
  },
}));

function SerpAnalysisView(props: DataGridSerpsViewProps): JSX.Element {
  const {
    rows,
    loadingSerpAnalyses,
    handleSelect,
    idsSelected,
    isRecalculatingKPIs,
    project,
    projectName,
    scopeId,
    isImportingKeywords,
    isImportKeywordsError,
    importErrorMessage,
    isProjectBudgetEnough = false,
  } = props;

  return (
    <StyledBox>
      <Typography variant="h1">
        Vue d&apos;ensemble
      </Typography>
      {isImportKeywordsError && (
        <CustomAlert severity="error">
          <Typography
            variant="body2"
            className={classes.importError}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold' }}
              component="span"
            >
              L&apos;import de l&apos;étude de mots clés a échoué !
            </Typography>
            {' '}
            Contactez l&apos;équipe IT
            <Typography
              variant="body2"
              component="span"
              sx={{ fontStyle: 'italic', display: 'block' }}
            >
              {importErrorMessage}
            </Typography>
          </Typography>
        </CustomAlert>
      )}
      <MainBox BoxProps={{ height: '100%' }}>
        <ActionButton
          idsSelected={idsSelected}
          scopeId={scopeId}
          projectId={project ? project.id : 0}
          projectName={projectName}
          isImportingKeywords={isImportingKeywords}
          projectBudget={parseFloat(project?.project_budget ?? '0')}
          keywordPrice={parseFloat(project?.keyword_price ?? '0')}
        />
        <SerpAnalysisDataGrid
          rows={rows}
          loadingSerpAnalyses={loadingSerpAnalyses}
          handleSelect={handleSelect}
          isRecalculatingKPIs={isRecalculatingKPIs}
          projectId={project ? project.id : 0}
          scopeId={scopeId || 0}
          projectName={projectName}
          isImportingKeywords={isImportingKeywords}
          idsSelected={idsSelected}
          isProjectBudgetEnough={isProjectBudgetEnough}
          projectBudget={parseFloat(project?.project_budget ?? '0')}
          keywordPrice={parseFloat(project?.keyword_price ?? '0')}
        />
      </MainBox>
      <ConfigurationContainer
        projectId={project ? project.id : 0}
        scopeId={scopeId || 0}
        projectName={projectName}
        isImportingKeywords={isImportingKeywords}
      />
    </StyledBox>
  );
}

export default SerpAnalysisView;
