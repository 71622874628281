import * as yup from 'yup';

import REGEX_LABEL from 'utils/formControl';

export interface AddCatGroupForm {
  name: string;
}

export const initValues = {
  name: '',
};

export const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Ce champ est obligatoire').matches(
    REGEX_LABEL,
    'Au moins une lettre ou un chiffre requis',
  ),
});
