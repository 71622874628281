import React from 'react';

import { SelectInputProps } from '@mui/material/Select/SelectInput';
import moment from 'moment';

import globalTheme from 'common/theme';
import DropDown from 'components/dropDown/DropDown';
import { convertDate, DATE_ENGLISH, DATE_FULL_NAME_MONTH_YEAR } from 'utils/formatDate';

import usePeriod from '../state/usePeriod';

type PeriodsDropDownProps = {
  value: string | string[] | number,
  onChange: SelectInputProps<any>['onChange'],
  scopeId: number,
  name?: string,
  multiple?: boolean,
  emptyOption?: string
  size?: 'small' | 'medium',
  margin?: number,
  height?: string,
  width?: string,
  fontSize?: keyof typeof globalTheme.fontSize,
  label?: string,
  className?: {
    select?: string,
  },
  dataSources: number[],
  displayEmptyInField?: boolean,
  displayEmptyInList?: boolean,
  noPeriodsOption?: any,
};

function PeriodsDropDown(props: PeriodsDropDownProps) {
  const {
    value,
    onChange,
    scopeId,
    name,
    multiple = false,
    emptyOption,
    size,
    margin,
    fontSize,
    height,
    width,
    label,
    className,
    dataSources,
    displayEmptyInField = false,
    displayEmptyInList = false,
    noPeriodsOption = undefined,
  } = props;

  const { periods } = usePeriod(scopeId!, dataSources);

  const formattedPeriods = periods.map(({ period }) => ({
    label: convertDate(moment(period).utc().toDate(), DATE_FULL_NAME_MONTH_YEAR),
    value: convertDate(moment(period).utc().toDate(), DATE_ENGLISH),
  }));

  const sortedPeriods = formattedPeriods.slice().sort(
    (a, b) => moment(a.value).diff(moment(b.value)),
  );
  if (noPeriodsOption) {
    sortedPeriods.unshift(noPeriodsOption);
  }

  return (
    <DropDown<typeof formattedPeriods[number]>
      name={name ?? 'actors'}
      fullWidth
      value={value}
      options={sortedPeriods}
      label={label}
      onChange={onChange}
      getOptionLabel={(period) => period.label}
      getOptionValue={(period) => period.value}
      multiple={multiple}
      emptyOption={emptyOption}
      size={size}
      fontSize={fontSize}
      margin={margin}
      height={height}
      width={width}
      className={className}
      displayEmptyInField={displayEmptyInField}
      displayEmptyInList={displayEmptyInList}
      data-testid="select-period-drop-down"
    />
  );
}

export default PeriodsDropDown;
