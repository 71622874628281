const PLANNING_TYPE = {
  NO_REPEAT: 1,
  QUARTERLY: 2,
  MONTHLY: 3,
  WEEKLY: 4,
  DAILY: 5,
};

export const PLANNING_MANUEL = {
  daily: { label: 'jour(s)', id: 5 },
  monthly: { label: 'mois', id: 3 },
};

export default PLANNING_TYPE;
