import React from 'react';
import { Link } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';

import MainBox from 'components/layout/MainBox';
import MainLayout from 'components/layout/MainLayout';

function NotFound(): JSX.Element {
  return (
    <MainLayout>
      <Stack direction="row" spacing={2}>
        <MainBox>
          <Typography variant="h1">Oupsie, not found</Typography>
          <Link to="/">
            Retour à l&apos;accueil
          </Link>
        </MainBox>
      </Stack>
    </MainLayout>
  );
}

export default NotFound;
