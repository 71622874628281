import React from 'react';

import { Box, Paper } from '@mui/material';
import moment from 'moment';

import globalTheme from 'common/theme';
import { DATE_NAME_DAY_MONTH, DATE_NAME_MONTH_YEAR, convertDate } from 'utils/formatDate';
import formatThousands from 'utils/formatThousands';

export interface RankingEvolutionTooltipProps {
  active?: boolean,
  payload?: any[],
  label?: string,
  tooltipLabel?: string,
  isMonthly?: boolean,
  datavizMode?: string,
}

function RankingEvolutionTooltipComponent(props: RankingEvolutionTooltipProps) {
  const {
    active = false,
    payload = [],
    label = '',
    tooltipLabel = '',
    isMonthly = false,
    datavizMode = 'rank',
  } = props;

  if (active && payload && payload.length) {
    return (
      <Box
        component={Paper}
        sx={{
          padding: '0px 10px',
          textAlign: 'left',
          fontSize: globalTheme.fontSize.xsmall,
          border: 'none',
        }}
      >
        <Box>
          {
          isMonthly
            ? convertDate(moment(label, 'MM/YYYY').toDate(), DATE_NAME_MONTH_YEAR)
            : convertDate(moment(label, 'DD/MM/YYYY').toDate(), DATE_NAME_DAY_MONTH)
          }
        </Box>
        {
          payload.map((pl) => (
            <Box
              key={`barTooltip-${pl.name}-${Math.random() * 10000}`}
              color={pl.color}
            >
              {`${tooltipLabel || pl.name} : ${
                formatThousands(pl.value)
              } ${
                datavizMode === 'rank'
                  ? `${pl.value > 1 ? 'mots clés' : 'mot clé'}`
                  : `demande${pl.value > 1 ? 's' : ''}`
              }`}
            </Box>
          ))
        }
      </Box>
    );
  }
  return null;
}

export default RankingEvolutionTooltipComponent;
