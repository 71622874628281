import React, { useState } from 'react';

import { Box, Typography, MenuItem } from '@mui/material';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import { addUser } from 'features/projects/state/slice';

type JoinProjectProps = {
  id: number,
  handleCloseMenu: Function,
  userId: number,
}

function JoinProject (props: JoinProjectProps) {
  const { id, handleCloseMenu, userId } = props;
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleConfirm = () => {
    dispatch(addUser({
      project_id: id,
      user_id: userId,
      is_consultant: false,
    }));
    setOpenModal(false);
  };

  return (
    <Box>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => { if (!openModal) setOpenModal(true); handleCloseMenu(); }
        }
      >
        Rejoindre ce projet
      </MenuItem>
      <Modal
        title={(
          <Typography data-testid="modal-join-project">
            <Typography component="span" variant="bold">Rejoindre</Typography>
            {' '}
            ce projet
          </Typography>
        )}
        actionConfirm={handleConfirm}
        actionCancel={() => setOpenModal(false)}
        isOpen={openModal}
      />
    </Box>
  );
}

export default JoinProject;
