import React from 'react';

import ComputerIcon from '@mui/icons-material/Computer';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

export const DEVICE_TYPE = {
  DESKTOP: 1,
  MOBILE: 2,
  DESKTOP_MOBILE: 3,
};

export const devicesListWithoutDevicesCombination = [
  {
    id: DEVICE_TYPE.MOBILE,
    label: 'Mobile',
    icon: <SmartphoneIcon titleAccess="Mobile" aria-label="Mobile" />,
  },
  {
    id: DEVICE_TYPE.DESKTOP,
    label: 'Desktop',
    icon: <ComputerIcon titleAccess="Desktop" aria-label="Desktop" />,
  },
];

export const devicesList = [
  ...devicesListWithoutDevicesCombination,
  {
    id: DEVICE_TYPE.DESKTOP_MOBILE,
    label: 'Desktop + mobile',
    icon: (
      <>
        <SmartphoneIcon titleAccess="Mobile" aria-label="Mobile" />
        <ComputerIcon titleAccess="Desktop" aria-label="Desktop" />
      </>
    ),
  },
];
