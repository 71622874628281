import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FiscalYearType } from '../types';

export interface State {
  isLoading: boolean;
  fiscalYears: FiscalYearType[];
  error: string;
}

const initialState: State = {
  isLoading: false,
  fiscalYears: [],
  error: '',
};

export const fiscalYearSlice = createSlice({
  name: 'fiscalYear',
  initialState,
  reducers: {
    fetchFiscalYears: (state: State) => {
      state.isLoading = true;
    },
    fetchFiscalYearsSuccess: (state: State, action: PayloadAction<FiscalYearType[]>) => {
      state.fiscalYears = action.payload;
      state.isLoading = false;
    },
    fetchFiscalYearsFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchFiscalYears,
  fetchFiscalYearsSuccess,
  fetchFiscalYearsFailure,
} = fiscalYearSlice.actions;

export default fiscalYearSlice.reducer;
