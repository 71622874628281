import * as React from 'react';

import { ArrowUpward } from '@mui/icons-material';
import {
  Box,
  Fab,
  Fade,
  useScrollTrigger,
  styled,
  BoxProps,
} from '@mui/material';

import { buttonLength } from 'features/navbar/config';

const PREFIX = 'BackToTop';

const classes = {
  container: `${PREFIX}-container`,
  arrowBox: `${PREFIX}-arrowBox`,
  arrow: `${PREFIX}-arrow`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.container}`]: {
    position: 'fixed',
    bottom: 16,
    right: 16,
  },

  [`& .${classes.arrowBox}`]: {
    backgroundColor: theme.palette.primary.light,
    color: 'white',
    borderRadius: '5px',
    width: buttonLength,
    height: buttonLength,
    minHeight: 0,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.arrow}`]: {
    height: '20px',
    color: 'inherit',
  },
}));

export default function BackToTop(): JSX.Element {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <StyledBox>
      <Fade in={trigger}>
        <Box
          onClick={handleClick}
          role="presentation"
          className={classes.container}
        >
          <Fab
            size="small"
            aria-label="scroll back to top"
            className={classes.arrowBox}
          >
            <ArrowUpward className={classes.arrow} />
          </Fab>
        </Box>
      </Fade>
    </StyledBox>
  );
}
