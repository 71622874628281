import React, { useState } from 'react';

import { SelectChangeEvent, Stack, Typography } from '@mui/material';

import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';

import DFSDataTypeDropDown from './DFSDataTypeDropDown';

type UpdateDFSDataTypedModalProps = {
  currentValue?: number,
  handleConfirm: Function,
  handleClose: Function,
  isOpen: boolean,
}

function UpdateDFSDataTypeModal(props: UpdateDFSDataTypedModalProps) {
  const {
    currentValue,
    isOpen,
    handleConfirm,
    handleClose,
  } = props;
  const [selectedValue, setSelectedValue] = useState(currentValue);
  const [error, setError] = useState(false);

  const handleChange = (e: SelectChangeEvent<number>) => {
    setSelectedValue(e.target.value as number);

    if (error) setError(false);
  };

  return (
    <Modal
      title={(
        <Typography>
          <Typography component="span" variant="bold">
            Paramétrer
            {' '}
          </Typography>
          la récupération des SERP
        </Typography>
      )}
      actionConfirm={() => (selectedValue ? handleConfirm(selectedValue) : setError(true))}
      actionCancel={handleClose}
      isOpen={isOpen}
      dataTestId="update-dfs-data-type-modal"
    >
      <Stack
        direction="column"
        alignItems="center"
        width={1}
        padding={1}
        spacing={3}
        data-testid="update-dfs-data-type-form"
      >
        <Typography sx={{ fontSize: globalTheme.fontSize.xsmall }}>
          Attention cette option entraîne
          {' '}
          <Typography component="span" variant="bold">
            un surcoût !
          </Typography>
        </Typography>
        <DFSDataTypeDropDown
          value={selectedValue ?? ''}
          onChange={handleChange}
          error={error}
        />
      </Stack>
    </Modal>
  );
}

export default UpdateDFSDataTypeModal;
