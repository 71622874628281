import React from 'react';

import {
  Box,
  BoxProps,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridSlots,
  LoadingOverlayPropsOverrides,
} from '@mui/x-data-grid';

import CheckBoxDataGrid from 'components/checkbox/CheckBoxDataGrid';
import StatusFlag from 'components/statusFlag/StatusFlag';
import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';
import TextNavLinkDataGrid
  from 'features/dataviz/components/generic/datagrid/textNavLinkDataGrid/TextNavLinkDataGrid';
import DeviceIcon from 'features/deviceType/Icon';
import { ANALYSIS_STATUS } from 'features/serpAnalysis/CONSTANT';
import { Analysis } from 'features/serpAnalysis/types';
import CountryFlag from 'features/serpAnalysisIndex/components/CountryFlag';
import { convertDate, dateComparator, DATE_NUMBERS_HOURS } from 'utils/formatDate';
import formatProjectNameForUrl from 'utils/formatProjectNameForUrl';
import formatThousands from 'utils/formatThousands';

import LoadingOverlay from './LoadingOverlay';
import AnalysesDatagridActions from '../components/modals/AnalysesDatagridActions';
import getNextScrapDate from '../utils/serpAnalysis';

export type DataGridSerpsProps = {
  rows: Analysis[],
  loadingSerpAnalyses: boolean,
  handleSelect: Function,
  isRecalculatingKPIs: boolean,
  projectId: number,
  scopeId: number,
  projectName: string,
  isImportingKeywords: boolean,
  idsSelected: number[],
  isProjectBudgetEnough?: boolean,
  keywordPrice: number,
  projectBudget: number,
}

const PREFIX = 'SerpAnalysisDataGrid';

const classes = {
  container: `${PREFIX}-container`,
  labels: `${PREFIX}-labels`,
  devices: `${PREFIX}-devices`,
  loading: `${PREFIX}-loading`,
  loadingText: `${PREFIX}-loadingText`,
  loadingSpinner: `${PREFIX}-loadingSpinner`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.container}`]: {
    height: '100%',
    width: '100%',
    flex: '1',
  },

  [`& .${classes.labels}`]: {
    color: theme.palette.info.main,
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.devices}`]: {
    color: theme.palette.info.dark,
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.loading}`]: {
    height: '100%',
    margin: '0 auto',
    padding: '15px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  [`& .${classes.loadingText}`]: {
    color: theme.palette.info.dark,
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.loadingSpinner}`]: {
    color: theme.palette.primary.light,
  },
}));

export default function SerpAnalysisDataGrid(props: DataGridSerpsProps): JSX.Element {
  const {
    rows,
    loadingSerpAnalyses,
    handleSelect,
    isRecalculatingKPIs,
    projectId,
    scopeId,
    projectName,
    isImportingKeywords,
    idsSelected,
    projectBudget,
    keywordPrice,
    isProjectBudgetEnough = false,
  } = props;

  const columns: GridColDef<Analysis>[] = [
    {
      field: 'label',
      headerClassName: 'super-app-theme--header',
      renderHeader: () => (
        <StyledHeader label="Étude de mots clés" />
      ),
      renderCell: (params) => (
        <TextNavLinkDataGrid
          link={`${params.row.id}-${
            formatProjectNameForUrl(params.row.label)}`}
          label={params.row.label}
          alwaysUnderlined
        />
      ),
      flex: 0.75,
      align: 'left',
      headerAlign: 'center',
      minWidth: 170,
    },
    {
      field: 'keywords_count',
      renderHeader: () => (
        <StyledHeader label="Mots Clés" />
      ),
      renderCell: (params) => (
        <Typography className={classes.labels}>
          {formatThousands(params.row.keywords_count)}
        </Typography>
      ),
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      resizable: false,
    },
    {
      field: 'dfs_data_type',
      renderHeader: () => (
        <StyledHeader label="SERP" />
      ),
      renderCell: (params) => (
        <Typography className={classes.labels}>
          {params.value}
        </Typography>
      ),
      valueGetter: (value, row) => row.dfs_data_type.label,
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      resizable: false,
    },
    {
      field: 'last_scrapping_error_count',
      renderHeader: () => (
        <StyledHeader label="Erreurs" />
      ),
      renderCell: (params) => (
        <Typography className={classes.labels}>
          {params.value}
        </Typography>
      ),
      flex: 0.3,
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      resizable: false,
    },
    {
      field: 'serp_analysis_status_id',
      renderHeader: () => (
        <StyledHeader label="Statut" />
      ),
      renderCell: (params) => (
        <StatusFlag
          variant={params.row.serp_analysis_status_id}
          scrappingRunStatus={params.row.serp_analysis_scrappings_running[0]?.scrapping_status_id}
          isRecalculatingKPIs={isRecalculatingKPIs}
        />
      ),
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      resizable: false,
    },
    {
      field: 'planning_date',
      renderHeader: () => (
        <StyledHeader label="Planification" />
      ),
      valueGetter: (value, row) => getNextScrapDate(
        row.last_scrapping,
        row.planning_date,
        row.serp_analysis_planning_id,
        row.planning_delay,
        isProjectBudgetEnough,
        DATE_NUMBERS_HOURS,
        true,
      ),
      renderCell: (params) => (
        <Typography className={classes.labels}>
          {params.value}
        </Typography>
      ),
      sortComparator: (v1, v2) => dateComparator(v1, v2, DATE_NUMBERS_HOURS),
      flex: 0.75,
      align: 'center',
      headerAlign: 'center',
      resizable: false,
    },
    {
      field: 'insertion_date',
      renderHeader: () => (
        <StyledHeader label="Dernière analyse" />
      ),
      valueGetter: (value, row) => row.last_scrapping,
      renderCell: (params) => (
        <Typography className={classes.labels}>
          {
            params.value
            && params.value !== ''
              ? convertDate(
                new Date(params.value),
                DATE_NUMBERS_HOURS,
              )
              : ''
          }

        </Typography>
      ),
      flex: 0.75,
      align: 'center',
      headerAlign: 'center',
      resizable: false,
    },
    {
      field: 'device_type',
      renderHeader: () => (
        <StyledHeader label="Sources" />
      ),
      valueGetter: (value, row) => row.device_type_id,
      renderCell: (params) => (
        <Stack
          direction="row"
          justifyContent="center"
          width="100%"
          alignItems="center"
          spacing={1}
        >
          <CountryFlag
            index={params.row.serp_analysis_index}
            width={16}
            height={16}
          />
          <DeviceIcon deviceTypeId={params.row.device_type_id} />
        </Stack>
      ),
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      resizable: false,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      renderCell: (params) => (
        <AnalysesDatagridActions
          projectId={projectId}
          scopeId={scopeId}
          projectName={projectName}
          analysis={params.row}
          projectBudget={projectBudget}
          keywordPrice={keywordPrice}
        />
      ),
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      resizable: false,
    },
  ];

  return (
    <StyledBox>
      <DataGrid
        density="compact"
        hideFooterSelectedRowCount
        disableRowSelectionOnClick
        disableColumnMenu
        checkboxSelection
        autoHeight
        disableVirtualization
        getRowHeight={() => 'auto'}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
          sorting: {
            sortModel: [{ field: 'label', sort: 'asc' }],
          },
        }}
        slotProps={{
          pagination: {
            labelRowsPerPage: ('Nombre par page :'),
          },
          loadingOverlay: {
            isImportingKeywords,
          } as LoadingOverlayPropsOverrides,
        }}
        slots={{
          loadingOverlay: LoadingOverlay as GridSlots['loadingOverlay'],
          baseCheckbox: CheckBoxDataGrid,
        }}
        sx={{ '--DataGrid-overlayHeight': '150px' }}
        pageSizeOptions={[25, 50, 100]}
        rows={isImportingKeywords ? [] : rows}
        columns={columns}
        localeText={{
          noRowsLabel:
            'Il n’existe aucune étude de mots clés pour ce projet',
        }}
        onRowSelectionModelChange={(saSelected) => {
          handleSelect(saSelected);
        }}
        rowSelectionModel={idsSelected}
        isRowSelectable={
          (params) => (
            (params.row.serp_analysis_status_id !== ANALYSIS_STATUS.IN_PROGRESS)
            && !isRecalculatingKPIs
          )
        }
        loading={loadingSerpAnalyses || isImportingKeywords}
        data-testid="serp-analysis-datagrid"
      />
    </StyledBox>
  );
}
