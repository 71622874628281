/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchTransactions, Transaction } from '../types';

export interface State {
  isLoading: boolean;
  transactions: Transaction[];
  error: string;
}

const initialState: State = {
  isLoading: false,
  transactions: [],
  error: '',
};

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    fetchTransactions: (state: State, action: PayloadAction<FetchTransactions>) => {
      state.transactions = [];
      state.isLoading = true;
    },
    fetchTransactionsSuccess: (state: State, action: PayloadAction<Transaction[]>) => {
      state.transactions = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    fetchTransactionsFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchTransactions,
  fetchTransactionsSuccess,
  fetchTransactionsFailure,
} = transactionSlice.actions;

export default transactionSlice.reducer;
