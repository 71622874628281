import React, { ReactNode } from 'react';

import {
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlProps,
  styled,
  SelectChangeEvent,
} from '@mui/material';

import { useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import CATEGORY_TYPE_ID from 'features/categoryGroups/CONSTANTS';
import { selectCategoryGroupById } from 'features/categoryGroups/state/slice';

const PREFIX = 'SelectCategory';

const classes = {
  select: `${PREFIX}-select`,
};

const StyledFormControl = styled(FormControl)<FormControlProps>(() => ({
  [`&.${classes.select}`]: {
    marginTop: '.5rem',
    width: '100%',
  },
}));

type SelectCategoryProps = {
  name: string,
  groupId: number,
  groupType: number,
  value: number | '',
  handleChange: (event: SelectChangeEvent<number>, child: ReactNode) => void,
  error?: boolean,
  emptyValue?: boolean,
}

function SelectCategory (props: SelectCategoryProps) {
  const {
    name,
    groupId,
    value,
    handleChange,
    groupType,
    error = false,
    emptyValue = false,
  } = props;

  let catList = useAppSelector(
    (state) => selectCategoryGroupById(state.categoryGroups, groupId),
  )?.categories ?? [];
  catList = [...catList].sort((cat1, cat2) => cat1.name.localeCompare(cat2.name));

  const labels = {
    [CATEGORY_TYPE_ID.keywords]: 'Catégorie de mots clés',
    [CATEGORY_TYPE_ID.url]: 'Catégorie d\'URL',
    [CATEGORY_TYPE_ID.brand]: 'Catégorie de requête Marque',
  };

  return (
    <StyledFormControl className={classes.select}>
      <InputLabel htmlFor="category" size="small">{labels[groupType]}</InputLabel>
      <Select
        label="Catégorie d'URL"
        size="small"
        value={value}
        onChange={handleChange}
        name={name}
        id="category"
        error={error}
        data-testid="category-selector"
      >
        { emptyValue && (
          <MenuItem
            value={-1}
            data-testid="cat_option_new"
          >
            <Typography sx={{ fontSize: globalTheme.fontSize.medium, textAlign: 'left' }}>
              Sélectionner ou créer une nouvelle catégorie
            </Typography>
          </MenuItem>
        )}
        {catList && catList.length > 0 && catList.map((cat) => (
          <MenuItem
            key={cat.id}
            value={cat.id}
            data-testid="cat_option"
          >
            <Typography sx={{ fontSize: globalTheme.fontSize.medium, textAlign: 'left' }}>
              {cat.name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}

export default SelectCategory;
