import React from 'react';

import {
  Stack,
  StackProps,
  Typography,
  styled,
} from '@mui/material';

import UserAvatarView from './AvatarView';
import { UserType } from '../types';
import User from '../User';

const PREFIX = 'UserAvatarWithLabelView';

const classes = {
  labelsClassic: `${PREFIX}-labelsClassic`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`& .${classes.labelsClassic}`]: {
    fontSize: theme.fontSize.medium,
    fontWeight: '400',
    marginLeft: '20px',
  },
}));

type AvatarWithLabelViewProps = {
  userItem: UserType,
  isExternal?: boolean,
}

export default function UserAvatarWithLabelView(props: AvatarWithLabelViewProps): JSX.Element {
  const { userItem, isExternal = false } = props;
  const user = new User(userItem);

  return (
    <StyledStack direction="row" alignItems="center">
      <UserAvatarView user={user} size="default" />
      <Typography className={classes.labelsClassic}>
        {isExternal ? 'Utilisateur externe' : user.userFullName}
      </Typography>
    </StyledStack>
  );
}
