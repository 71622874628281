import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import { RootState } from 'common/store';

import { fetchDataSourcesRequested } from './slice';

const useDataSources = () => {
  const dispatch = useAppDispatch();

  const { isLoading, error, dataSources } = useAppSelector(
    (state: RootState) => state.dataSources,
  );

  useEffect(() => {
    if (dataSources.length === 0) {
      dispatch(fetchDataSourcesRequested());
    }
  }, [dispatch, fetchDataSourcesRequested]);

  const sortedDataSources = dataSources ? [...dataSources] : [];
  sortedDataSources?.sort((a, b) => (
    a.name.localeCompare(b.name)
  ));

  return {
    isLoading,
    error,
    dataSources: sortedDataSources,
  };
};

export default useDataSources;
