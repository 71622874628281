import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Box, Typography,
} from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import { useAppSelector } from 'common/reduxHooks';
import MainBox from 'components/layout/MainBox';
import DataGridGroupActions
  from 'features/categoryGroups/categoryValues/actions/DataGridGroupActions';
import CategoryValuesDataGrid
  from 'features/categoryGroups/categoryValues/list/CategoryValuesDataGrid';
import CategoryGroup from 'features/categoryGroups/classes/CategoryGroup';
import CATEGORY_TYPE_ID from 'features/categoryGroups/CONSTANTS';
import useCategoryGroups from 'features/categoryGroups/state/useCategoryGroups';
import { selectProjectById } from 'features/projects/state/slice';
import { splitIdName } from 'utils/urlManipulation';

function SetupCategoryValues () {
  const { idNameProject, idCategoryGroup } = useParams();
  const { id: idProject } = splitIdName(idNameProject);
  const { id: idCategory } = splitIdName(idCategoryGroup);
  const [selection, setSelection] = useState<number[]>([]);

  const project = useAppSelector((state) => (
    selectProjectById(state.projects, idProject)));

  const scopeId = project?.scopes[0]?.id ? project?.scopes[0]?.id : 0;

  const {
    isLoading,
    categoryGroups: tempCatGroups,
  } = useCategoryGroups(scopeId, CATEGORY_TYPE_ID.url);

  const categoryGroups = tempCatGroups.map((categoryGroup) => new CategoryGroup(categoryGroup));
  const currentCategoryGroup = categoryGroups.find(
    (categoryGroup) => categoryGroup.catGroupId === Number(idCategory),
  );
  const categoryGroupName = currentCategoryGroup ? currentCategoryGroup.catGroupName : '';

  useDocumentTitle(`${categoryGroupName} | benhur`);

  const handleSelectionModelChange = (rowSelectionModel: number[]) => {
    setSelection(rowSelectionModel as number[]);
  };

  return (
    <Box>
      <Typography variant="h1">
        {`Configurer les URL du groupe ${categoryGroupName}`}
      </Typography>
      <MainBox>
        <DataGridGroupActions
          selection={selection}
          scopeId={scopeId}
          categoryGroupType={CATEGORY_TYPE_ID.url}
          categoryGroup={currentCategoryGroup}
        />
        <CategoryValuesDataGrid
          rows={currentCategoryGroup?.formatCategoryValuesForDataGrid() ?? []}
          handleSelectionModelChange={handleSelectionModelChange}
          selectionModel={selection}
          isLoading={isLoading}
          categoryGroupType={CATEGORY_TYPE_ID.url}
        />
      </MainBox>
    </Box>
  );
}

export default SetupCategoryValues;
