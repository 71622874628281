import globalTheme from 'common/theme';
import { rankingModeSlice } from 'features/dataviz/CONSTANTS';
import { RANK_SLICES } from 'features/rankSlice/CONSTANT';

const generateDatakeys = (data: any[], mode?: number) => {
  const dataKeys = Object.keys(data[0] || {})?.filter(
    (key) => key !== 'periode' && key !== 'Total scrappés',
  )?.map((key, i) => ({
    dataKey: key,
    color: globalTheme.palette.dataviz[i],
  }));

  if (mode === rankingModeSlice) {
    return [
      {
        dataKey: RANK_SLICES[0].label,
        color: globalTheme.palette.dataviz[5],
        stackId: 1,
      },
      {
        dataKey: RANK_SLICES[1].label,
        color: globalTheme.palette.dataviz[4],
        stackId: 1,
      },
      {
        dataKey: RANK_SLICES[2].label,
        color: globalTheme.palette.dataviz[3],
        stackId: 1,
      },
      {
        dataKey: RANK_SLICES[3].label,
        color: globalTheme.palette.dataviz[2],
        stackId: 1,
      },
      {
        dataKey: RANK_SLICES[4].label,
        color: globalTheme.palette.dataviz[1],
        stackId: 1,
      },
    ].filter((keySlice) => dataKeys.find((key) => keySlice.dataKey === key.dataKey));
  }
  return dataKeys;
};

export default generateDatakeys;
