import axios, { AxiosError, AxiosResponse } from 'axios';

import { API_BASE_ADDRESS } from './config';

/*
* We create an instance of axios
* that we will use for the routes
* that don't need to be logged in
*/
const axiosBasicInstance = axios.create({
  baseURL: API_BASE_ADDRESS,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
});

// Response interceptor for API calls
axiosBasicInstance.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  async (error: AxiosError) => {
    let message = 'An unknown error occurred';
    if (error instanceof Error) {
      message = error.message;
    }
    return Promise.reject(message);
  },
);

export default axiosBasicInstance;
