import React, { useEffect, useState } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { FormikProps, useFormik } from 'formik';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import Modal from 'components/modal/Modal';
import ValidateActionMessage from 'components/modal/ValidateActionMessage';

import AddDataFileModalForm from '../components/AddDataFilesForm';
import {
  handleUploadSubmit,
  getInitialValues,
  validationSchema,
} from '../components/setupAddDataFiles';
import { DataFileForm } from '../types';

type AddDataFileProps = {
  scopeId: number,
}

function AddDataFile (props: AddDataFileProps) {
  const { scopeId } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { isLoading: dataFilesLoading } = useAppSelector(
    (state) => state.dataFiles,
  );

  const handleConfirmationClose = () => {
    setIsOpen(false);
    setConfirmationOpen(false);
  };

  const handleSubmit = (values: DataFileForm) => {
    setShowLoading(true);
    handleUploadSubmit(values, dispatch);
  };

  const formik: FormikProps<DataFileForm> = useFormik<DataFileForm>({
    initialValues: getInitialValues(scopeId),
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (showLoading && !dataFilesLoading) {
      setIsOpen(false);
      setShowLoading(false);
      setConfirmationOpen(true);
      formik.resetForm();
    }
  }, [dataFilesLoading]);

  return (
    <Box>
      <Button
        variant="contained"
        onClick={
          () => { setIsOpen(true); setConfirmationOpen(false); }
        }
        size="small"
        startIcon={<AddCircleOutlineIcon />}
        data-testid="add-data-file-button"
      >
        Ajouter des fichiers CSV
      </Button>
      {isConfirmationOpen ? (
        <Modal
          title={<ValidateActionMessage />}
          displayCancel={false}
          actionConfirm={handleConfirmationClose}
          isOpen={isConfirmationOpen}
          dataTestId="add-data-file-confirm-modal"
        />
      )
        : isOpen && (
          <Modal
            title={(
              <Typography>
                <Typography component="span" variant="bold">
                  Importer
                </Typography>
                {' '}
                des fichiers CSV
              </Typography>
            )}
            actionConfirm={formik.handleSubmit}
            actionCancel={() => { setIsOpen(false); formik.resetForm(); }}
            isOpen={isOpen}
            displayLoading={showLoading}
            isProcessLoading={showLoading}
            dataTestId="add-data-file-modal"
          >
            <AddDataFileModalForm
              scopeId={scopeId}
              values={formik.values}
              handleChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
              errors={formik.errors}
            />
          </Modal>
        )}
    </Box>
  );
}

export default AddDataFile;
