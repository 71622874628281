import React, { useEffect, useState } from 'react';

import {
  Button,
  CircularProgress,
  Stack,
  StackProps,
  styled,
  TextField,
  Typography,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import { fetchIsInternal } from 'features/authentication/state/slice';

type LoginEmailCheckerFormProps = {
  defaultEmail: string,
  onUserInfoChange: Function,
}

const PREFIX = 'LoginEmailCheckerForm';

const classes = {
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  text: `${PREFIX}-text`,
  spinner: `${PREFIX}-spinner`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`& .${classes.input}`]: {
    fontSize: theme.fontSize.medium,
  },

  [`& .${classes.button}`]: {
    fontSize: theme.fontSize.medium,
    height: '36px',
    width: '25%',
  },

  [`& .${classes.text}`]: {
    fontSize: theme.fontSize.medium,
  },

  [`&.${classes.spinner}`]: {
    color: theme.palette.primary.light,
  },
}));

function LoginEmailCheckerForm(props: LoginEmailCheckerFormProps) {
  const { defaultEmail, onUserInfoChange } = props;

  const dispatch = useAppDispatch();
  const [email, setEmail] = useState(defaultEmail);
  const [internalLoading, setInternalLoading] = useState(false);

  const {
    isInternalError,
    isInternalLoading,
    isInternal,
  } = useAppSelector(
    (state) => state.authentication,
  );

  const checkEmail = (e: any) => {
    e.preventDefault();
    setInternalLoading(true);
    dispatch(fetchIsInternal(email));
  };

  useEffect(() => {
    if (!isInternalLoading && internalLoading && !isInternalError) {
      onUserInfoChange({
        email,
        isInternal,
      });
    } else if (!isInternalLoading && internalLoading && isInternalError) {
      setInternalLoading(false);
    }
  }, [isInternalLoading, internalLoading]);

  return (
    <StyledStack
      direction="column"
      spacing={2}
      alignItems="center"
      component="form"
      onSubmit={checkEmail}
      data-testid="login-email-checker-form"
    >
      <Typography className={classes.text}>
        Saisir votre
        <Typography className={classes.text} component="span" variant="bold">
          {' '}
          adresse email
        </Typography>
        {' '}
        pour vous connecter
      </Typography>
      <TextField
        label="Email"
        type="email"
        slotProps={{
          input: {
            autoComplete: 'username',
            className: classes.input,
          },
        }}
        size="small"
        variant="outlined"
        fullWidth
        value={email}
        onChange={(e) => {
          e.preventDefault();
          setEmail(e.target.value);
        }}
        disabled={internalLoading}
        error={!!isInternalError}
        helperText={isInternalError ? 'Email non valide' : undefined}
        autoFocus
        data-testid="login-email-checker-email-textfield"
      />
      <Button
        variant="contained"
        className={classes.button}
        type="submit"
        disabled={internalLoading}
        data-testid="login-email-checker-button"
      >
        {internalLoading
          ? <CircularProgress className={classes.spinner} size={25} />
          : 'Valider'}
      </Button>
    </StyledStack>
  );
}

export default LoginEmailCheckerForm;
