import { axiosAuthInstance } from 'common/axiosAuthInstance';

import { PeriodApiResponse } from '../types';

export default class ApiPerformance {
  static fetchPeriodByDataSource(scopeId: number, dataSourceIds: number[]) {
    return axiosAuthInstance.post<never, PeriodApiResponse>(
      'dataviz/analyze/overall/get_period_list_by_data_source',
      {
        scope_id: scopeId,
        data_source_ids: dataSourceIds,
      },
    );
  }
}
