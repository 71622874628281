export const PATTERN_START_TYPES = {
  exclude_subdomains: 1,
  include_subdomains: 2,
};

export const MODAL_MODE = {
  create: 1, // create an actor
  add: 2, // add a site_url_pattern to an actor
  update: 3, // update a site_url_pattern
};
