import React from 'react';

import {
  Box,
  BoxProps,
  styled,
  Typography,
} from '@mui/material';
import { FormikProps, FormikProvider } from 'formik';

import USER_TYPES from 'features/users/CONSTANTS';
import UsersSelect from 'features/users/list/Select';
import useUsersFetch from 'features/users/state/useUsersFetch';

import { ManageUsersForm } from './manageUsersConfig';

const PREFIX = 'ManageUsersModal';

const classes = {
  box: `${PREFIX}-box`,
};

const StyledBox = styled(Box)<BoxProps<'form'>>(() => ({
  [`&.${classes.box}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
}));

type FormEditProjectProps = {
  formik: FormikProps<ManageUsersForm>,
  isUserInProject?: boolean,
  canUserEditProject?: boolean,
}

function ManageUsersModalView(props: FormEditProjectProps) {
  const {
    formik,
    isUserInProject,
    canUserEditProject,
  } = props;
  const { handleChange } = formik;

  useUsersFetch({ withInactive: false });

  return (
    isUserInProject
      ? (
        <StyledBox
          data-testid="modal-manage-user-project"
          className={classes.box}
        >
          <FormikProvider value={formik}>
            <UsersSelect
              handleChange={handleChange}
              name="dircli"
              usersSelected={formik.values.dircli ?? ''}
              userTypeIds={[USER_TYPES.dircli.id]}
              doFetch={false}
              label="Directeur de clientèle"
              disabled={!canUserEditProject}
            />
          </FormikProvider>
          <FormikProvider value={formik}>
            <UsersSelect
              handleChange={handleChange}
              name="manager"
              usersSelected={formik.values.manager ?? ''}
              userTypeIds={[USER_TYPES.internal.id]}
              toRemove={[formik.values.dircli ?? -1]}
              doFetch={false}
              label="Chef(fe) de projet"
            />
          </FormikProvider>
          <FormikProvider value={formik}>
            <UsersSelect
              handleChange={handleChange}
              name="users"
              usersSelected={formik.values.users}
              userTypeIds={[USER_TYPES.internal.id, USER_TYPES.dircli.id, USER_TYPES.admin.id]}
              multiple
              doFetch={false}
              label="Equipe"
            />
          </FormikProvider>
        </StyledBox>
      )
      : (
        <StyledBox data-testid="modal-manage-user-project-unauth" className={classes.box}>
          <Typography variant="body2">
            Vous n&apos;avez pas les droits sur ce projet
          </Typography>
        </StyledBox>
      )
  );
}

export default ManageUsersModalView;
