import React, { useEffect } from 'react';

import { SelectInputProps } from '@mui/material/Select/SelectInput';

import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import DropDown from 'components/dropDown/DropDown';

import { fetchProjects } from '../state/slice';

type SelectProjectsDropdownProps = {
  value: number | (number | string)[],
  onChange: SelectInputProps<any>['onChange'],
  name?: string,
  multiple?: boolean,
  emptyOption?: string
  size?: 'small' | 'medium',
  margin?: number,
  height?: string,
  width?: string,
  fontSize?: keyof typeof globalTheme.fontSize,
  label?: string,
  className?: {
    select?: string,
  },
  error: boolean,
  displayGlobalCheckbox?: boolean,
  errorText?: string,
};

function SelectProjectsDropdown (props: SelectProjectsDropdownProps) {
  const {
    value,
    onChange,
    name,
    multiple = false,
    emptyOption = '',
    size,
    margin,
    fontSize,
    height,
    width,
    label,
    className,
    error,
    displayGlobalCheckbox = true,
    errorText,
  } = props;

  const dispatch = useAppDispatch();

  const projects = useAppSelector(
    ((state) => state.projects.projects),
  );

  useEffect(() => {
    if (!projects.length) {
      dispatch(fetchProjects());
    }
  }, []);

  const sortedProjects: any[] = [...projects].sort((a, b) => a.name.localeCompare(b.name));
  sortedProjects.unshift({
    id: '',
    name: emptyOption,
  });

  return (
    <DropDown<typeof sortedProjects[number]>
      value={value}
      onChange={onChange}
      options={sortedProjects}
      getOptionLabel={(project) => project.name}
      getOptionValue={(project) => (project ? project.id : '')}
      label={label}
      name={name ?? 'projects'}
      fullWidth
      optionEmptyHelper="Aucun projet"
      multiple={multiple}
      size={size}
      fontSize={fontSize}
      margin={margin}
      height={height}
      width={width}
      className={className}
      error={error}
      data-testid="select-projects-drop-down"
      displayGlobalCheckbox={displayGlobalCheckbox}
      errorText={errorText}
    />
  );
}

export default SelectProjectsDropdown;
