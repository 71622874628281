import React from 'react';

import globalTheme from 'common/theme';
import DropDown from 'components/dropDown/DropDown';

import useRankingTypes from '../state/useRankingTypes';

type RankingTypesDropDownProps = {
  value: number[],
  label: string,
  onChange: any,
  name: string,
  size?: 'medium' | 'small',
  height?: string,
  multiple?: boolean,
  emptyOption?: string,
  margin?: number,
  width?: string,
  fontSize?: keyof typeof globalTheme.fontSize,
  disabled?: boolean,
  className?: {
    select?: string,
  },
  optionsFilter?: any[],
}

function RankingTypesDropDown(props: RankingTypesDropDownProps) {
  const { rankingTypes } = useRankingTypes();
  const {
    name,
    label,
    onChange,
    size,
    margin,
    value,
    height,
    multiple = false,
    emptyOption,
    fontSize,
    width,
    className,
    optionsFilter = [],
    disabled = false,
  } = props;

  const rankingTypesWithBonus = (optionsFilter && optionsFilter.length > 0)
    ? [...rankingTypes.filter((rt) => rt.id !== 0)].filter(
      (rt) => !optionsFilter.includes(parseInt(rt.id.toString(), 10)),
    )
    : [...rankingTypes.filter((rt) => rt.id !== 0)];
  rankingTypesWithBonus.splice(1, 0, {
    id: -1,
    name: 'organic + FS',
  });

  const sortedRankingTypes = [...rankingTypes].sort((a, b) => a.name.localeCompare(b.name));

  rankingTypesWithBonus.sort((a, b) => a.name.localeCompare(b.name));

  const handleChange = (e: any) => {
    if (!multiple && e.target.value === -1) e.target.value = [1, 2];
    else if (!Array.isArray(e.target.value)) e.target.value = [e.target.value];

    onChange(e);
  };

  const formatValue = () => {
    if (!rankingTypes.length) return '';

    // If organic + fs are selected, return -1 value
    if (!multiple && value.length === 2 && value.every((rt) => [1, 2].includes(rt))) return [-1];

    return value;
  };

  return (
    <DropDown<typeof rankingTypes[number]>
      name={name}
      fullWidth
      value={formatValue()}
      options={multiple ? sortedRankingTypes : rankingTypesWithBonus}
      label={label}
      onChange={handleChange}
      getOptionLabel={(rt) => rt.name}
      getOptionValue={(rt) => rt.id}
      multiple={multiple}
      emptyOption={emptyOption}
      size={size}
      fontSize={fontSize}
      margin={margin}
      height={height}
      width={width}
      className={className}
      disabled={disabled}
      data-testid="select-ranking-type-drop-down"
    />
  );
}

export default RankingTypesDropDown;
