import { RankingEvolutionFilters } from 'features/serpAnalysis/types';

import { DatavizApiRequest, DatavizFilters } from '../types';

const getDatavizRequestPayload = (
  requests: DatavizApiRequest[],
  isExport: boolean,
  periode: string | undefined,
  datavizFilters?: DatavizFilters | RankingEvolutionFilters,
) => {
  let result: DatavizApiRequest[] = requests;
  if (datavizFilters && datavizFilters.type === 'datavizFilters') {
    result = requests.map((request) => {
      request.payload = {
        ...request.payload,
        sites_count: datavizFilters.sitesCount || '0',
        sa_keyword_intention_ids:
              datavizFilters.searchIntentId && datavizFilters.searchIntentId !== '0'
                ? [parseInt(datavizFilters?.searchIntentId, 10)]
                : [],
        min_rank: datavizFilters.rankMin || '0',
        max_rank: datavizFilters.rankMax || '0',
        min_vol: datavizFilters.volMin || '0',
        max_vol: datavizFilters.volMax || '-1',
        periode: periode !== '' ? periode : undefined,
        best_rank_mode: isExport ? false : datavizFilters.bestRankMode,
        serp_analysis_scrapping_ids: datavizFilters.serpAnalysisScrappingIds ?? [],
        device_type_ids: datavizFilters.deviceTypeId ? [datavizFilters.deviceTypeId] : undefined,
      };

      return request;
    });
  } else if (datavizFilters && datavizFilters.type === 'rankingEvolutionFilters') {
    result = requests.map((request) => {
      request.payload = {
        ...request.payload,
        actor_ids: datavizFilters.actorIds ?? [],
        device_type_ids: [datavizFilters.deviceTypeId],
        periode_begin: datavizFilters.periodeBegin,
        periode_end: datavizFilters.periodeEnd,
        sa_keyword_intention_ids:
            datavizFilters.intentionIds && JSON.stringify(datavizFilters.intentionIds) !== '[0]'
              ? datavizFilters.intentionIds
              : [],
        time_serie: datavizFilters.timeSeries,
        sa_rank_slice_ids: datavizFilters.rankSliceIds,
        keyword_ids: datavizFilters.keywordIds,
        data_source_ids: datavizFilters.dataSourceIds ?? [],
        category_url_ids: datavizFilters.categoryIds ?? [],
        category_group_url_id: datavizFilters.categoryGroupId,
        serp_analysis_ids: (
          datavizFilters.serpAnalysisIds
            ? request.payload.serp_analysis_ids.concat(datavizFilters.serpAnalysisIds)
            : request.payload.serp_analysis_ids
        ),
        ranking_type_ids: datavizFilters.rankingTypeId,
        best_rank_mode: datavizFilters.bestRankMode ?? false,
        include_uncategorized: datavizFilters.includeUncategorized ?? false,
      };

      return request;
    });
  }

  return result;
};

export default getDatavizRequestPayload;
