import React from 'react';

import { Alert, AlertProps, styled } from '@mui/material';

interface Props {
  variant?: 'filled' | 'outlined' | 'standard',
  severity: 'error' | 'info' | 'success' | 'warning',
  color?: 'error' | 'info' | 'success' | 'warning',
  fullWidth?: boolean,
  children: JSX.Element,
}

const PREFIX = 'CustomAlert';

const classes = {
  core: `${PREFIX}-core`,
};

const StyledAlert = styled(Alert)<AlertProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    marginTop: theme.spacing(3),
  },
}));

function CustomAlert(props: Props) {
  const {
    variant = 'standard',
    severity,
    color,
    fullWidth,
    children,
  } = props;

  return (
    <StyledAlert
      className={classes.core}
      variant={variant}
      severity={severity}
      color={color || severity}
      sx={{ width: fullWidth ? 1 : undefined }}
    >
      {children}
    </StyledAlert>
  );
}

export default CustomAlert;
