import React from 'react';

import { Box } from '@mui/material';
import { FormikProps } from 'formik';

import Modal from 'components/modal/Modal';
import ValidateActionMessage from 'components/modal/ValidateActionMessage';

import { SerpAnalysisForm } from './createAnalysisConfig';
import CreateSaFormModal from './FormModal';
import SeparatorMessage from './SeparatorMessage';
import WrongSepTitle from './WrongSepTitle';

type CreateSaProps = {
  formik: FormikProps<SerpAnalysisForm>,
  openModal: boolean,
  isLoading: boolean,
  isAnalysisLoading: boolean,
  analysisError: string,
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
  setIsConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>,
  handleSuccessClose: Function,
  isConfirmationOpen: boolean,
  handleFixWrongSteps: Function,
  hasWrongSeparator: boolean,
  handleOpenMainModal: Function,
  isImportingKw: boolean,
  isButton?: boolean,
  buttonText?: string,
  disabled?: boolean,
}

function CreateSerpAnalysisModalsChoiceView(props: CreateSaProps) {
  const {
    isLoading,
    formik,
    openModal,
    isAnalysisLoading,
    analysisError,
    setOpenModal,
    setIsConfirmationOpen,
    handleSuccessClose,
    isConfirmationOpen,
    handleFixWrongSteps,
    hasWrongSeparator,
    handleOpenMainModal,
    isImportingKw,
    isButton,
    buttonText,
    disabled,
  } = props;

  return (
    <Box>
      {
        !isLoading && (
          <Box>
            <CreateSaFormModal
              formik={formik}
              openModal={openModal}
              isAnalysisLoading={isAnalysisLoading}
              analysisError={analysisError}
              setOpenModal={setOpenModal}
              handleOpenMainModal={handleOpenMainModal}
              setIsConfirmationOpen={setIsConfirmationOpen}
              isImportingKw={isImportingKw}
              isButton={isButton}
              buttonText={buttonText}
              disabled={disabled}
            />
            <Modal
              title={<ValidateActionMessage />}
              displayCancel={false}
              actionConfirm={handleSuccessClose}
              isOpen={isConfirmationOpen && !hasWrongSeparator}
              dataTestId="create-sa-confirmation-modal"
            />
            <Modal
              title={<WrongSepTitle />}
              actionConfirm={formik.handleSubmit}
              actionCancel={handleFixWrongSteps}
              isOpen={hasWrongSeparator && !openModal}
              dataTestId="create-sa-wrong-sep-modal"
            >
              <SeparatorMessage />
            </Modal>
          </Box>
        )
      }
    </Box>
  );
}

export default CreateSerpAnalysisModalsChoiceView;
