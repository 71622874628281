import React from 'react';

import { Box, Typography } from '@mui/material';

import UserChipView from './ChipView';
import { UserType } from '../types';

type UserChipsProps = {
  selected: number[],
  users: UserType[],
}

export default function UserChips(props: UserChipsProps): JSX.Element {
  const { selected, users } = props;

  return (
    <Box>
      {selected.length === 0 ? (
        <Typography component="em">
          Il n&apos;y a pas encore d&apos;utilisateurs associés à ce projet
        </Typography>
      ) : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {users.filter((user) => selected.includes(user.id))
            .map((user) => (
              <UserChipView
                key={user.id}
                userItem={user}
                projectManagerId={undefined}
              />
            ))}
        </Box>
      )}
    </Box>
  );
}
