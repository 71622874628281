import React from 'react';

import {
  TextField,
  Box,
  Typography,
  styled,
  BoxProps,
  Stack,
} from '@mui/material';
import { FormikProps, FormikProvider } from 'formik';

import { devicesList } from 'features/deviceType/CONSTANTS';
import SelectDeviceTypeView from 'features/deviceType/SelectView';
import SearchEngineDropDown from 'features/searchEngines/components/SearchEngineDropDown';
import SelectIndex from 'features/serpAnalysisIndex/components/SelectIndex';

import { SerpAnalysisForm } from './createAnalysisConfig';

const PREFIX = 'CreateSerpAnalysisFormView';

const classes = {
  input: `${PREFIX}-input`,
  inputContent: `${PREFIX}-inputContent`,
  box: `${PREFIX}-box`,
  error: `${PREFIX}-error`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.box}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },

  [`& .${classes.input}`]: {
    width: '100%',
  },

  [`& .${classes.inputContent}`]: {
    fontSize: theme.fontSize.medium,
  },

  [`& .${classes.error}`]: {
    borderRadius: '5px',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    color: theme.palette.error.main,
  },
}));

type FormAnalysisProps = {
  formik: FormikProps<SerpAnalysisForm>,
  error: string,
}

function CreateSAFormView(props: FormAnalysisProps) {
  const {
    formik,
    error,
  } = props;
  const { handleChange } = formik;

  return (
    <StyledBox className={classes.box}>
      <FormikProvider value={formik}>
        <TextField
          margin="normal"
          id="label"
          label="Nom de la catégorie"
          type="text"
          variant="outlined"
          size="small"
          onChange={handleChange}
          value={formik.values.label}
          className={classes.input}
          slotProps={{
            input: {
              classes: {
                input: classes.inputContent,
              },
            },
          }}
          onBlur={formik.handleBlur}
          error={
                  formik.touched.label
                  && Boolean(formik.errors.label)
                }
          helperText={
                  formik.touched.label
                  && formik.errors.label
                }
        />
        <TextField
          margin="normal"
          id="keywords"
          label="Mots clés à ajouter (1 mot clé = 1 ligne)"
          multiline
          variant="outlined"
          size="small"
          rows={10}
          className={classes.input}
          slotProps={{
            input: {
              classes: {
                input: classes.inputContent,
              },
            },
          }}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.keywords}
          error={
                  formik.touched.keywords
                  && Boolean(formik.errors.keywords)
                }
          helperText={
                  formik.touched.keywords
                  && formik.errors.keywords
                }
        />
        <Stack direction="column" spacing={2} marginTop="1rem" width="100%">
          <SelectIndex
            values={formik.values.indexId}
            handleChange={formik.handleChange}
          />
          <SelectDeviceTypeView
            handleDeviceChange={formik.handleChange}
            deviceSelected={formik.values.deviceType}
            devices={devicesList}
            variant="outlined"
            isInputLabel
            selectName="deviceType"
            uniqueText
            menuItemFontSize=".875rem"
          />
          <SearchEngineDropDown
            name="searchEngineId"
            onChange={formik.handleChange}
            value={formik.values.searchEngineId}
          />
        </Stack>
        {error && (
        <Box className={classes.error}>
          <Typography>
            {error}
          </Typography>
        </Box>
        )}
      </FormikProvider>
    </StyledBox>
  );
}

export default CreateSAFormView;
