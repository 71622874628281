import moment from 'moment';

import { Project } from 'features/projects/types';
import { RankingEvolutionFilters } from 'features/serpAnalysis/types';
import { DATE_YEAR_MONTH, convertDate } from 'utils/formatDate';
import formatProjectNameForUrl from 'utils/formatProjectNameForUrl';

import { API_METRIC } from '../CONSTANTS';
import { DatavizFilters, Metric } from '../types';

function formatExportName(
  exportMetric?: Metric,
  datavizFilters?: DatavizFilters | RankingEvolutionFilters,
  project?: Project,
  featureLabel?: string,
  analysisLabel?: string,
  exportName?: string,
) {
  if (exportName) return exportName;

  const labelPart: string[] = [];

  if (exportMetric?.metric === API_METRIC.get_performance_export) {
    if (datavizFilters?.type === 'rankingEvolutionFilters') {
      labelPart.push(convertDate(moment(datavizFilters.periodeEnd).toDate(), DATE_YEAR_MONTH));
    }

    if (project) {
      labelPart.push(formatProjectNameForUrl(project.name));
    }

    labelPart.push('performance.csv');

    return labelPart.join('-');
  }

  labelPart.push('export');

  if (featureLabel?.length) labelPart.push(featureLabel);

  if (analysisLabel) labelPart.push(formatProjectNameForUrl(analysisLabel));

  return labelPart.join('-');
}

export default formatExportName;
