import React from 'react';

import { Box, Typography, Link } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';

import globalTheme from 'common/theme';
import GridCellExpand
  from 'features/dataviz/components/generic/datagrid/GridCellExpand/GridCellExpand';
import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';
import { convertDate, DATE_NO_HOUR } from 'utils/formatDate';
import formatThousands from 'utils/formatThousands';
import { generateSerpUrl } from 'utils/urlManipulation';

export const fields = {
  keyword: 'keyword',
  rank: 'rank',
  title: 'title',
  description: 'description',
  expanded_title: 'expanded_title',
  source: 'source',
  content_date: 'content_date',
  url: 'url',
  has_image: 'has_image',
  has_table: 'has_table',
  image_url: 'image_url',
  volume: 'volume',
  intention: 'intention',
};

export const columnsDatasByRankingType = (searchEngineId?: number): GridColDef<any>[] => ([
  {
    field: fields.keyword,
    headerName: 'Mots clés',
    renderHeader: () => (
      <StyledHeader label="Mots clés" />
    ),
    type: 'string',
    editable: false,
    flex: 1,
    minWidth: 100,
    renderCell: (params: GridCellParams<any>) => (
      <Link
        href={
          params.row.serp_url
            ? params.row.serp_url
            : generateSerpUrl(params.row.keyword, searchEngineId)
        }
        target="_blank"
        rel="noopener"
        variant="body3"
      >
        {params.row.keyword}
      </Link>
    ),
  },
  {
    field: fields.rank,
    headerName: 'Position',
    renderHeader: () => (
      <StyledHeader label="Position" />
    ),
    type: 'number',
    editable: false,
    flex: 0.5,
    minWidth: 100,
    renderCell: undefined,
  },
  {
    field: fields.volume,
    headerName: 'Volume',
    renderHeader: () => (
      <StyledHeader label="Volume" />
    ),
    type: 'string',
    editable: false,
    flex: 0.5,
    minWidth: 100,
    renderCell: (params: GridCellParams) => params.row.volume && formatThousands(
      params.row.volume,
    ),
  },
  {
    field: fields.intention,
    headerName: 'Intention',
    renderHeader: () => (
      <StyledHeader label="Intention" />
    ),
    type: 'string',
    editable: false,
    flex: 1,
    minWidth: 100,
    renderCell: (params: GridCellParams) => (
      <GridCellExpand
        width={params.colDef.computedWidth}
      >
        <Typography variant="body3">
          {params.row.intention}
        </Typography>
      </GridCellExpand>
    ),
  },
  {
    field: fields.title,
    headerName: 'Titre',
    renderHeader: () => (
      <StyledHeader label="Titre" />
    ),
    type: 'string',
    editable: false,
    flex: 1,
    minWidth: 100,
    renderCell: (params: GridCellParams) => (
      <GridCellExpand
        width={params.colDef.computedWidth}
      >
        <Typography variant="body3">
          {params.row.title}
        </Typography>
      </GridCellExpand>
    ),
  },
  {
    field: fields.expanded_title,
    headerName: 'Titre 2',
    renderHeader: () => (
      <StyledHeader label="Titre 2" />
    ),
    type: 'string',
    editable: false,
    flex: 1,
    minWidth: 100,
    renderCell: (params: GridCellParams) => (
      <GridCellExpand
        width={params.colDef.computedWidth}
      >
        <Typography variant="body3">
          {params.row.expanded_title}
        </Typography>
      </GridCellExpand>
    ),
  },
  {
    field: fields.description,
    headerName: 'Description',
    renderHeader: () => (
      <StyledHeader label="Description" />
    ),
    type: 'string',
    editable: false,
    flex: 1,
    minWidth: 100,
    renderCell: (params: GridCellParams) => (
      <GridCellExpand
        width={params.colDef.computedWidth}
      >
        <Typography variant="body3">
          {params.row.description}
        </Typography>
      </GridCellExpand>
    ),
  },
  {
    field: fields.source,
    headerName: 'Source',
    renderHeader: () => (
      <StyledHeader label="Source" />
    ),
    type: 'string',
    editable: false,
    flex: 1,
    minWidth: 100,
    renderCell: (params: GridCellParams) => (
      <GridCellExpand
        width={params.colDef.computedWidth}
      >
        <Typography variant="body3">
          {params.row.source}
        </Typography>
      </GridCellExpand>
    ),
  },
  {
    field: fields.content_date,
    headerName: 'Date',
    renderHeader: () => (
      <StyledHeader label="Date" />
    ),
    type: 'string',
    editable: false,
    flex: 1,
    minWidth: 100,
    renderCell: (params: GridCellParams) => {
      if (typeof params.row.content_date === typeof ''
      && params.row.content_date !== '') {
        return (
          <Typography sx={{ fontSize: globalTheme.fontSize.medium }}>
            {convertDate(params.row.content_date, DATE_NO_HOUR)}
          </Typography>
        );
      }
      return (
        <Box> </Box>
      );
    },
  },
  {
    field: fields.url,
    headerName: 'Url',
    renderHeader: () => (
      <StyledHeader label="Url" />
    ),
    type: 'string',
    editable: false,
    flex: 2,
    minWidth: 150,
    renderCell: (params: GridCellParams) => (
      <GridCellExpand
        width={params.colDef.computedWidth}
      >
        <Link href={`${params.value}`} target="_blank" rel="noopener" variant="body3">
          {params.row.url}
        </Link>
      </GridCellExpand>
    ),
  },
  {
    field: fields.has_image,
    headerName: 'Images',
    renderHeader: () => (
      <StyledHeader label="Images" />
    ),
    type: 'boolean',
    editable: false,
    flex: 0.5,
    minWidth: 100,
  },
  {
    field: fields.has_table,
    headerName: 'Table',
    renderHeader: () => (
      <StyledHeader label="Table" />
    ),
    type: 'boolean',
    editable: false,
    flex: 0.5,
    minWidth: 100,
  },
  {
    field: fields.image_url,
    headerName: 'Image',
    renderHeader: () => (
      <StyledHeader label="Image" />
    ),
    type: 'string',
    editable: false,
    flex: 2,
    minWidth: 150,
    renderCell: (params: GridCellParams) => (
      <GridCellExpand
        width={params.colDef.computedWidth}
      >
        <Typography variant="body3">
          {params.row.image_url}
        </Typography>
      </GridCellExpand>
    ),
  },
]);
