import React, { useState } from 'react';

import { MenuItem, Box, Typography } from '@mui/material';
import { FormikHelpers, FormikProps, useFormik } from 'formik';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import ValidateActionMessage from 'components/modal/ValidateActionMessage';

import ActorModal from './ActorModal';
import {
  validationSchema,
  initValuesAdd,
} from './setupConfig';
import Actor from '../Actor';
import { MODAL_MODE } from '../CONSTANTS';
import {
  selectActorById,
} from '../state/slice';
import { ActorForm, ActorPatternsDataGrid } from '../types';

type AddPatternsProps = {
  scopeId: number,
  siteId: number,
  rowData: ActorPatternsDataGrid,
  handleCloseMenu: Function,
  disabled?: boolean,
}

export default function AddPatterns(props: AddPatternsProps): JSX.Element {
  const {
    scopeId,
    siteId,
    rowData,
    handleCloseMenu,
    disabled,
  } = props;

  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);

  const temp = useAppSelector((state) => selectActorById(state.actors, rowData.actorId));
  const actor = temp ? new Actor(temp) : undefined;
  const patternCount = actor?.formatPatternsForDatagrid().length;

  const handleModalSubmit = (values: ActorForm, formikHelpers: FormikHelpers<ActorForm>) => {
    if (scopeId === 0 || !actor) return;

    values.domain = values.domain.trim().replace(/^(https?:\/\/)/, '');
    actor?.addSiteUrlPattern(dispatch, values, siteId);

    setIsConfirmationOpen(true);
    formikHelpers.resetForm();
  };
  const handleConfirmationClose = () => {
    setIsOpen(false);
  };
  const formik: FormikProps<ActorForm> = useFormik<ActorForm>({
    initialValues: initValuesAdd(rowData.actorName, rowData.pattern_start_type_id === 2),
    validationSchema,
    onSubmit: handleModalSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => { setIsOpen(true); handleCloseMenu(); setIsConfirmationOpen(false); }
        }
        data-testid="add-actor-pattern-button"
        disabled={disabled}
      >
        Ajouter une règle
      </MenuItem>
      {
        isConfirmationOpen
          ? (
            <Modal
              title={<ValidateActionMessage />}
              displayCancel={false}
              actionConfirm={handleConfirmationClose}
              isOpen={isOpen}
              dataTestId="add-actor-pattern-confirm-modal"
            >
              <Box>
                <Typography>La règle a bien été ajoutée</Typography>
              </Box>
            </Modal>
          )
          : (
            <Modal
              title={(
                <Typography>
                  <Typography component="span" variant="bold">Ajouter</Typography>
                  {' '}
                  une règle d’identification à cet acteur
                </Typography>
              )}
              actionConfirm={formik.handleSubmit}
              actionCancel={() => {
                setIsOpen(false);
                formik.resetForm();
              }}
              isOpen={isOpen}
              dataTestId="add-actor-pattern-modal"
            >
              <ActorModal
                mode={MODAL_MODE.add}
                patternCount={patternCount}
                values={formik.values}
                touched={formik.touched}
                errors={formik.errors}
                onChange={formik.handleChange}
                setFieldValue={formik.setFieldValue}
                onBlur={formik.handleBlur}
              />
            </Modal>
          )
      }
    </>
  );
}
