import React from 'react';

import {
  DialogContent,
  Typography,
  styled,
  DialogContentProps,
} from '@mui/material';

const PREFIX = 'SeparatorMessageAddSaModal';

const classes = {
  typo: `${PREFIX}-typo`,
};

const StyledDialogContent = styled(DialogContent)<DialogContentProps>(({ theme }) => ({
  [`& .${classes.typo}`]: {
    fontSize: theme.fontSize.xsmall,
  },
}));

export default function SeparatorMessage() {
  return (
    <StyledDialogContent>
      <Typography className={classes.typo}>
        Le saut de ligne permet de séparer les mots clés. Enregistrer malgré tout ?
      </Typography>
    </StyledDialogContent>
  );
}
