import React from 'react';

import {
  Button,
  CircularProgress,
  SelectChangeEvent,
  Stack,
} from '@mui/material';

import DropDown from 'components/dropDown/DropDown';
import MainBox from 'components/layout/MainBox';
import ActorsDropDown from 'features/actors/components/ActorsDropDown';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';
import DeviceDropDown from 'features/deviceType/components/DeviceDropDown';
import SelectScrapping from 'features/serpAnalysisScrapping/components/SelectScrapping';
import useSerpAnalysisScrappings
  from 'features/serpAnalysisScrapping/state/useSerpAnalysisScrappings';
import { sortScrappingsByDate } from 'features/serpAnalysisScrapping/utils';

import organicLimit from '../CONSTANT';
import { PhysionomyFiltersForm } from '../types';
import getScrappingPeriodeExclude from '../utils';

type PhysionomyFiltersProps = {
  scopeId: number,
  values: PhysionomyFiltersForm,
  onChange: (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => void,
  onSubmit: VoidFunction,
  isLoading: boolean,
  serpAnalysisId: number,
}

const title = 'Sources des données';

function PhysionomyFilters(props: PhysionomyFiltersProps) {
  const {
    scopeId,
    values,
    onChange,
    onSubmit,
    isLoading,
    serpAnalysisId,
  } = props;

  const { scrappings } = useSerpAnalysisScrappings(serpAnalysisId);

  if (isLoading) {
    return (
      <MainBox BoxProps={{ justifyItems: 'center' }}>
        <TitleComponent title={title} capitalLetter />
        <CircularProgress data-testid="physionomy-filters-spinner" />
      </MainBox>
    );
  }

  const sortedScrappings = sortScrappingsByDate(scrappings);

  const scrappingPeriodeOne = getScrappingPeriodeExclude(sortedScrappings, values.periodeTwo);
  const scrappingPeriodeTwo = getScrappingPeriodeExclude(sortedScrappings, values.periodeOne);

  return (
    <MainBox>
      <TitleComponent
        title={title}
        capitalLetter
        direction="column"
      />
      <Stack
        direction="column"
        spacing={2}
        my={2}
        data-testid="physionomy-filters-form"
      >
        <ActorsDropDown
          name="actors"
          scopeId={scopeId}
          value={values.actors}
          onChange={onChange}
          multiple
          size="small"
          label="Acteurs"
        />
        <DeviceDropDown
          onChange={onChange}
          value={values.deviceId}
          name="deviceId"
          size="small"
        />
        <DropDown
          onChange={onChange}
          options={organicLimit}
          value={values.rankLimit}
          name="rankLimit"
          size="small"
          label="Limite organique"
        />
        <SelectScrapping
          value={values.periodeOne ?? ''}
          onChange={onChange}
          name="periodeOne"
          size="small"
          scrappings={scrappingPeriodeOne}
          variant="outlined"
          fullWidth
          label="Analyse N°1"
          data-testid="select-scrapping-physionomy-1"
        />
        <SelectScrapping
          value={values.periodeTwo ?? ''}
          onChange={onChange}
          name="periodeTwo"
          size="small"
          scrappings={scrappingPeriodeTwo}
          variant="outlined"
          fullWidth
          label="Analyse N°2"
          data-testid="select-scrapping-physionomy-2"
        />
      </Stack>
      <Stack alignItems="end">
        <Button onClick={onSubmit} variant="contained">
          Analyser
        </Button>
      </Stack>
    </MainBox>
  );
}

export default PhysionomyFilters;
