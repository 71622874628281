export const DEFAULT_SCRAP_DATE = 'Manuelle';
export const NB_MAX_KEYWORD = 500;
export const DEFAULT_PRICE_PER_KEYWORD = 0.003;
export const ANALYSIS_STATUS = {
  SUCCESS: 1,
  DRAFT: 2,
  ERROR: 3,
  IN_PROGRESS: 4,
  BUDGET_INSUFFICIENT: 5,
};
export const SCRAPPING_STATUS = {
  TO_SCRAPE: 1,
  SENT: 2,
  TO_DOWNLOAD: 3,
  DOWNLOADING: 4,
  TO_IMPORT: 5,
  IMPORTING: 6,
  DONE: 7,
};

export const DFS_DATA_TYPE_VALUES = [
  { id: 1, label: 'standard' },
  { id: 2, label: 'standard + pixels' },
  { id: 3, label: 'live' },
];

export const COMPUTED_VALUES = [
  { id: 'computed-disable', value: 'disable', label: 'manuelle' },
  { id: 'computed-enable', value: 'enable', label: 'automatisée' },
];
