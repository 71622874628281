import React from 'react';

import { Chip } from '@mui/material';

import { ANALYSIS_STATUS } from 'features/serpAnalysis/CONSTANT';

interface StatusFlagProps {
  variant: any,
  scrappingRunStatus?: number,
  isRecalculatingKPIs: boolean,
}

export default function StatusFlag(props: StatusFlagProps): JSX.Element {
  const { variant, isRecalculatingKPIs, scrappingRunStatus } = props;
  let label: string = '';
  let color: 'default' | 'error' | 'success' | 'warning';
  switch (variant) {
    case ANALYSIS_STATUS.SUCCESS:
      label = 'succès';
      color = 'success';
      break;
    case ANALYSIS_STATUS.DRAFT:
      label = 'brouillon';
      color = 'default';
      break;
    case ANALYSIS_STATUS.IN_PROGRESS:
      label = 'en cours...';
      color = 'warning';
      break;
    case ANALYSIS_STATUS.BUDGET_INSUFFICIENT:
      label = 'Budget insuffisant';
      color = 'error';
      break;
    default:
      label = 'échec';
      color = 'error';
      break;
  }
  if (scrappingRunStatus === 1) {
    label = 'démarrage...';
    color = 'warning';
  }
  if (isRecalculatingKPIs) {
    label = 'Actualisation';
    color = 'success';
  }
  return (
    <Chip size="small" label={label} color={color} />
  );
}
