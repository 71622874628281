import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Button,
  Menu,
  Box,
  styled,
  BoxProps,
} from '@mui/material';

import AddPatterns from './AddPattern';
import DeletePatterns from './DeletePattern';
import UpdatePatterns from './UpdatePattern';
import { ActorPatternsDataGrid } from '../types';

const PREFIX = 'ActorsDataGridActions';

const classes = {
  dropdown: `${PREFIX}-dropdown`,
  button: `${PREFIX}-button`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.dropdown}`]: {
    fontSize: theme.fontSize.xlarge,
  },

  [`& .${classes.button}`]: {
    color: '#737476',
    fontSize: theme.fontSize.medium,
    height: '30px',
    width: '115px',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      color: '#737476',
      backgroundColor: '#e8e8e8',
    },
  },
}));

type ActorDatagridActionsProps = {
  pattern: ActorPatternsDataGrid,
  scopeId: number,
  actionsDisabled?: boolean,
}

export default function ActorDatagridActions(props: ActorDatagridActionsProps): JSX.Element {
  const { pattern, scopeId, actionsDisabled } = props;
  const [anchorMenu, setAnchorMenu] = useState(null);
  const openMenu = Boolean(anchorMenu);

  const handleOpenMenu = (event: any) => {
    setAnchorMenu(event.target);
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <StyledBox>
      <Button
        variant="contained"
        disableElevation
        onClick={handleOpenMenu}
        endIcon={<KeyboardArrowDownIcon className={classes.dropdown} />}
        size="small"
        className={classes.button}
        color="info"
        data-testid="actor-datagrid-action-button"
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        keepMounted
        id="menuActionsActors"
        data-testid="actor-datagrid-action-menu"
      >
        <AddPatterns
          scopeId={scopeId}
          siteId={pattern.site_id}
          rowData={pattern}
          handleCloseMenu={handleCloseMenu}
          disabled={actionsDisabled}
        />
        <UpdatePatterns
          scopeId={scopeId}
          siteId={pattern.site_id}
          rowData={pattern}
          handleCloseMenu={handleCloseMenu}
          disabled={actionsDisabled}
        />
        <DeletePatterns
          patternId={pattern.id}
          actorId={pattern.actorId}
          siteId={pattern.site_id}
          handleCloseMenu={handleCloseMenu}
          disabled={actionsDisabled}
        />
      </Menu>
    </StyledBox>
  );
}
