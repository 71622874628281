import React from 'react';

import { ArrowForward, ArrowBack } from '@mui/icons-material';
import {
  Button, styled,
} from '@mui/material';

import { leftDrawerWidth, rightDrawerWidth, buttonLength } from '../config';

const PREFIX = 'SlidingButton';

const classes = {
  core: `${PREFIX}-core`,
  icon: `${PREFIX}-icon`,
};

const halfLength = buttonLength / 2;

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen?: boolean }>(({ theme, isOpen }) => ({
  [`&.${classes.core}`]: {
    zIndex: 1000,
    position: 'fixed',
    minWidth: 0,
    bottom: '20px',
    width: buttonLength,
    height: buttonLength,
    left: isOpen
      ? (leftDrawerWidth + rightDrawerWidth - halfLength)
      : (leftDrawerWidth - halfLength),
    transition: 'left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    backgroundColor: theme.palette.primary.light,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.icon}`]: {
    width: '20px',
  },
}));

type SlideButtonProps = {
  isOpen: boolean,
  toggleRightDrawer: Function,
}

function SlidingButton({ isOpen, toggleRightDrawer }: SlideButtonProps): JSX.Element {
  return (
    <StyledButton
      variant="contained"
      onClick={toggleRightDrawer(!isOpen)}
      className={classes.core}
      isOpen={isOpen}
    >
      { isOpen
        ? (<ArrowBack className={classes.icon} />)
        : (<ArrowForward className={classes.icon} />)}
    </StyledButton>
  );
}

export default SlidingButton;
