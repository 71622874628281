import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Button,
  Menu,
  Box,
  styled,
  BoxProps,
} from '@mui/material';

import DeleteCatGroups from './DeleteCatGroup';
import UpdateCatGroups from './UpdateCatGroup';
import { CategoryGroup } from '../types';

const PREFIX = 'CategoryGroupsDatagridActions';

const classes = {
  dropdown: `${PREFIX}-dropdown`,
  button: `${PREFIX}-button`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.dropdown}`]: {
    fontSize: theme.fontSize.xlarge,
  },

  [`& .${classes.button}`]: {
    color: '#737476',
    fontSize: theme.fontSize.medium,
    height: '30px',
    width: '115px',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      color: '#737476',
      backgroundColor: '#e8e8e8',
    },
  },
}));

type CategoryGroupsDatagridActionsProps = {
  catGroup: CategoryGroup,
  scopeId: number,
}

function CategoryGroupsActions(props: CategoryGroupsDatagridActionsProps) {
  const { catGroup, scopeId } = props;
  const [openModals, setOpenModals] = useState<Array<number>>([]);
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const openMenu = Boolean(anchorMenu);

  const handleOpenMenu = (event: any) => {
    setAnchorMenu(event.target);
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <StyledBox>
      <Button
        id={catGroup.id.toString()}
        variant="contained"
        disableElevation
        onClick={(e) => { setOpenModals([...openModals, catGroup.id]); handleOpenMenu(e); }}
        endIcon={<KeyboardArrowDownIcon className={classes.dropdown} />}
        size="small"
        className={classes.button}
        color="info"
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorMenu}
        open={openMenu && openModals.includes(catGroup.id)}
        onClose={handleCloseMenu}
        keepMounted
        id="menuActionsActors"
      >
        <UpdateCatGroups
          catGroup={catGroup}
          scopeId={scopeId}
          handleCloseMenu={handleCloseMenu}
        />
        <DeleteCatGroups
          ids={[catGroup.id]}
          scopeId={scopeId}
          handleCloseMenu={handleCloseMenu}
          isButton={false}
        />
      </Menu>
    </StyledBox>
  );
}

export default CategoryGroupsActions;
