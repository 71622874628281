import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';
import { FormikProps, useFormik } from 'formik';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import { selectProjectById } from 'features/projects/state/slice';
import {
  initialValues,
  validationSchema,
  SerpAnalysisForm,
  formAnalysisToAnalysis,
} from 'features/serpAnalysis/components/modals/create/createAnalysisConfig';
import { createAnalysis } from 'features/serpAnalysis/state/slice';
import { splitIdName } from 'utils/urlManipulation';

import CreateSerpAnalysisModalsChoiceView from './ModalsChoiceView';

type CreateSaModalProps = {
  handleCloseMenu?: Function,
  isImportingKw: boolean,
  isButton?: boolean,
  buttonText?: string,
  disabled?: boolean,
}

function CreateSerpAnalysisModalsChoice(props: CreateSaModalProps) {
  const {
    handleCloseMenu,
    isImportingKw,
    isButton,
    buttonText,
    disabled,
  } = props;

  const dispatch = useAppDispatch();

  const { idNameProject } = useParams();
  const { id: idProject } = splitIdName(idNameProject);

  const { isLoading } = useAppSelector((state) => state.projects);
  const {
    isLoading: isAnalysisLoading,
    error: analysisError,
  } = useAppSelector((state) => state.serpAnalyses);
  const project = useAppSelector((state) => (
    selectProjectById(state.projects, idProject)));

  const [hasCalledCreate, setHasCalledCreate] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [hasWrongSeparator, setHasWrongSeparator] = useState<boolean>(false);
  const [forceValidation, setForceValidation] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [currentlyEditing, setCurrentlyEditing] = useState(false);

  const handleSubmit = (values: SerpAnalysisForm) => {
    setForceValidation(true);
    if (hasWrongSeparator) {
      setHasCalledCreate(true);
      return;
    }
    const scopeId = project?.scopes[0]?.id ?? null;
    const payloadAnalysis = formAnalysisToAnalysis(values, scopeId);
    dispatch(createAnalysis(payloadAnalysis));
    setHasCalledCreate(true);
  };

  const handleFixWrongSteps = () => {
    setOpenModal(true);
    setIsConfirmationOpen(false);
    setForceValidation(false);
    setHasCalledCreate(false);
    setHasWrongSeparator(false);
  };

  const formik: FormikProps<SerpAnalysisForm> = useFormik<SerpAnalysisForm>({
    initialValues,
    validationSchema: validationSchema(setHasWrongSeparator, forceValidation, false),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (hasCalledCreate && !isAnalysisLoading && !analysisError && currentlyEditing) {
      setOpenModal(false);
      setIsConfirmationOpen(true);
      formik.resetForm();
      setCurrentlyEditing(false);
    }
  }, [hasCalledCreate, isAnalysisLoading, analysisError]);

  const handleSuccessClose = () => {
    setIsConfirmationOpen(false);
    setCurrentlyEditing(false);
  };

  const handleOpenMainModal = () => {
    setCurrentlyEditing(true);
    setOpenModal(true);
    handleCloseMenu?.();
  };

  return (
    <Box>
      {
        !isLoading
        && (
          <CreateSerpAnalysisModalsChoiceView
            formik={formik}
            isLoading={isLoading}
            openModal={openModal}
            isAnalysisLoading={isAnalysisLoading}
            analysisError={analysisError}
            setOpenModal={setOpenModal}
            setIsConfirmationOpen={setIsConfirmationOpen}
            handleSuccessClose={handleSuccessClose}
            isConfirmationOpen={isConfirmationOpen}
            handleFixWrongSteps={handleFixWrongSteps}
            hasWrongSeparator={hasWrongSeparator}
            handleOpenMainModal={handleOpenMainModal}
            isImportingKw={isImportingKw}
            isButton={isButton}
            buttonText={buttonText}
            disabled={disabled}
          />
        )
      }
    </Box>
  );
}

export default CreateSerpAnalysisModalsChoice;
