import { axiosAuthInstance } from 'common/axiosAuthInstance';
import { UserType } from 'features/users/types';

import {
  Project,
  ProjectCreateWithScope,
  ProjectCredit,
  ProjectUpdate,
  UserProject,
} from '../types';

export default class ApiProjects {
  static fetchProjects() {
    return axiosAuthInstance.get<Project[]>(
      'projects/with_scopes?skip=0&limit=10000',
    );
  }

  static createProject(payload: ProjectCreateWithScope) {
    return axiosAuthInstance.post<Project>('projects/with_scopes', payload);
  }

  static updateProject(projectId: number, payload: ProjectUpdate) {
    return axiosAuthInstance.put<Project>(`projects/${projectId}`, payload);
  }

  static deleteProject(payload: number) {
    return axiosAuthInstance.delete<Project>(`projects/${payload}`);
  }

  static updateProjectUsers(projectId: number, payload: UserProject[]) {
    return axiosAuthInstance
      .put<UserType[]>(`projects/${projectId}/user_projects`, payload);
  }

  static addUserToProject(payload: UserProject) {
    return axiosAuthInstance.post<UserType>('user_project/', payload);
  }

  static removeUserFromProject(payload: UserProject) {
    return axiosAuthInstance.delete<UserProject>('user_project/', {
      data: payload,
    });
  }

  static creditProject(projectId: number, payload: ProjectCredit) {
    return axiosAuthInstance.post<Project>(`projects/${projectId}/credit`, payload);
  }
}
