import React from 'react';

import {
  Box,
  BoxProps,
  styled,
} from '@mui/material';

import MainBox from 'components/layout/MainBox';

import UsersListDataGrid from './DataGrid';
import useUsersFetch from '../state/useUsersFetch';

const PREFIX = 'UsersList';

const classes = {
  spaceDataGrid: `${PREFIX}-spaceDataGrid`,
};

const StyledBox = styled(Box)<BoxProps>(() => ({
  [`& .${classes.spaceDataGrid}`]: {
    marginTop: '',
  },
}));

type UsersListProps = {
  isExternal?: boolean,
}

export default function UsersList(props: UsersListProps) {
  const { isExternal = false } = props;
  const { users, isLoading } = useUsersFetch({ withInactive: true, isExternal });

  return (
    <StyledBox>
      <MainBox>
        <Box className={classes.spaceDataGrid}>
          <UsersListDataGrid
            rows={users}
            loadingUsers={isLoading}
            isExternal={isExternal}
          />
        </Box>
      </MainBox>
    </StyledBox>
  );
}
