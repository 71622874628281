import React, { useEffect, useState } from 'react';

import { MenuItem } from '@mui/material';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import ValidateActionMessage from 'components/modal/ValidateActionMessage';
import UpdateSearchVolumeComputedModal
  from 'features/serpAnalysis/options/components/UpdateSearchVolumeModal';
import { updateSerpAnalyses } from 'features/serpAnalysis/state/slice';
import useSerpAnalyses from 'features/serpAnalysis/state/useSerpAnalyses';
import { Analysis } from 'features/serpAnalysis/types';

type UpdateSearchVolumeComputedProps = {
  serpAnalysis: Analysis,
  handleCloseMenu: Function,
}

function UpdateSearchVolumeComputedAction(props: UpdateSearchVolumeComputedProps) {
  const {
    serpAnalysis,
    handleCloseMenu,
  } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { isLoading } = useSerpAnalyses({ scopeId: serpAnalysis.scope_id });

  const handleConfirm = (newValue: string) => {
    dispatch(updateSerpAnalyses([
      {
        id: serpAnalysis.id,
        is_search_volume_computed: newValue === 'enable',
      },
    ]));
    setInternalLoading(true);
  };

  useEffect(() => {
    if (!isLoading && internalLoading) {
      setConfirmationModalOpen(true);
      setInternalLoading(false);
    }
  }, [isLoading, internalLoading]);

  const handleClose = () => {
    setOpenModal(false);
    setConfirmationModalOpen(false);
  };

  return (
    <MenuItem
      sx={{ fontSize: globalTheme.fontSize.middlesmall }}
      onClick={() => { if (!openModal) setOpenModal(true); handleCloseMenu(); }}
      data-testid="update-search-volume-computed-menu-item"
    >
      Demande mensuelle
      {
            confirmationModalOpen
              ? (
                <Modal
                  title={<ValidateActionMessage />}
                  displayCancel={false}
                  actionConfirm={handleClose}
                  isOpen={confirmationModalOpen}
                  dataTestId="update-search-volume-computed-confirm-modal"
                />
              )
              : (
                <UpdateSearchVolumeComputedModal
                  currentValue={serpAnalysis.is_search_volume_computed ? 'enable' : 'disable'}
                  isOpen={openModal}
                  isLoading={isLoading}
                  handleConfirm={handleConfirm}
                  handleClose={handleClose}
                />
              )
          }
    </MenuItem>
  );
}

export default UpdateSearchVolumeComputedAction;
