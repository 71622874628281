import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  Box,
  Typography,
} from '@mui/material';

import CustomAlert from 'components/alert/CustomAlert';
import CATEGORY_TYPE_ID from 'features/categoryGroups/CONSTANTS';
import useCategoryGroups from 'features/categoryGroups/state/useCategoryGroups';
import usePeriod from 'features/period/state/usePeriod';
import { Project } from 'features/projects/types';
import { generateProjectBaseUrl } from 'utils/urlManipulation';

type AlertNoPeriodsOrCategoryGroupsProps = {
  scopeId: number,
  dataSourceIds: number[],
  categoryTypeId: typeof CATEGORY_TYPE_ID[keyof typeof CATEGORY_TYPE_ID],
  project?: Project,
  doFetch?: boolean,
}

function AlertNoPeriodsOrCategoryGroups(props: AlertNoPeriodsOrCategoryGroupsProps) {
  const {
    scopeId,
    dataSourceIds,
    categoryTypeId,
    project,
    doFetch = true,
  } = props;

  const { categoryGroups } = useCategoryGroups(scopeId, categoryTypeId, doFetch);
  const { periods } = usePeriod(scopeId, dataSourceIds, doFetch);

  const projectUrl = project ? generateProjectBaseUrl(project.id, project.name) : '';

  return (
    (!categoryGroups.length || !periods.length) && (
      <Box marginBottom={2} data-testid="alert-no-periods-or-category-groups">
        <CustomAlert severity="error">
          <Typography variant="body2">
            Vous ne pouvez pas visualiser les performances SEO car vous n&apos;avez pas uploadé
            de fichiers Semrush ou vous n&apos;avez pas catégorisé les URL -
            <NavLink to={`${projectUrl}setting/url`}> Paramètres</NavLink>
          </Typography>
        </CustomAlert>
      </Box>
    )
  );
}

export default AlertNoPeriodsOrCategoryGroups;
