import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Box,
  MenuItem,
  Typography,
} from '@mui/material';

import { useAppSelector } from 'common/reduxHooks';
import { KpiViewActionsWording, KpiViewType } from 'features/kpiView/types';
import serializeSearchParams from 'utils/querySerializer';

import KpiView from '../../KpiView';
import { selectKpiViewById } from '../../state/slice';

interface LoadViewProps {
  idView: number,
  wordings?: KpiViewActionsWording,
  onLoad?: (kpiView: KpiViewType) => void,
}

function LoadView(props: LoadViewProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const { idView, wordings, onLoad } = props;

  const kpiView = useAppSelector((state) => selectKpiViewById(state.kpiViews, idView));
  const kpiViewObj = kpiView && new KpiView(kpiView);

  const handleNavigate = () => {
    if (kpiViewObj) {
      onLoad?.(kpiView);
      setSearchParams(serializeSearchParams(kpiViewObj.formatToObjectFilters()));
    }
  };

  return (
    <Box>
      <MenuItem
        onClick={handleNavigate}
      >
        <Typography variant="body2">
          {wordings?.load?.button ?? 'Voir'}
        </Typography>
      </MenuItem>
    </Box>
  );
}

export default LoadView;
