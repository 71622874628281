import React from 'react';

import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';
import { convertDate, DATE_FRENCH } from 'utils/formatDate';

import DataFileDatagridActions from '../actions/DataFileDataGridAction';
import StatusFlag from '../components/StatusFlag';
import { DELETABLE_DATA_SOURCE_STATUS } from '../CONSTANT';
import { DataFileType } from '../types';

const columns: GridColDef<DataFileType>[] = [
  {
    field: 'name',
    renderHeader: () => (
      <StyledHeader label="FICHIERS" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">{params.value}</Typography>
    ),
    valueGetter: (value, row) => row.original_name,
    align: 'left',
    headerAlign: 'left',
    flex: 4,
  },
  {
    field: 'source',
    renderHeader: () => (
      <StyledHeader label="SOURCES" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">{params.value}</Typography>
    ),
    valueGetter: (value, row) => row.data_source?.name,
    align: 'left',
    headerAlign: 'left',
    flex: 1.1,
  },
  {
    field: 'actor',
    renderHeader: () => (
      <StyledHeader label="ACTEURS" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">{params.value}</Typography>
    ),
    valueGetter: (value, row) => row.actor?.name,
    align: 'left',
    headerAlign: 'left',
    flex: 1.1,
  },
  {
    field: 'period',
    renderHeader: () => (
      <StyledHeader label="PÉRIODES" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">
        {params.value
          ? convertDate(new Date(params.value), DATE_FRENCH)
          : ''}
      </Typography>
    ),
    valueGetter: (value, row) => row.periode,
    align: 'left',
    headerAlign: 'left',
    flex: 1.1,
  },
  {
    field: 'status',
    renderHeader: () => (
      <StyledHeader label="ÉTATS" />
    ),
    renderCell: (params) => (
      <StatusFlag statusId={params.value} />
    ),
    valueGetter: (value, row) => row.data_file_status_id,
    align: 'left',
    headerAlign: 'left',
    flex: 1.1,
  },
  {
    field: 'actions',
    headerName: '',
    renderCell: (params) => (
      <DataFileDatagridActions
        displayUpdate={false}
        displayDelete={DELETABLE_DATA_SOURCE_STATUS.includes(params.row.data_file_status_id)}
        dataFile={params.row}
      />
    ),
    align: 'left',
    headerAlign: 'left',
    sortable: false,
    flex: 1.1,
  },
];

export default columns;
