/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  KeywordsDelete, KeywordsAdd, SerpAnalysisKeyword,
} from 'features/keywords/types';

export interface State {
  isLoading: boolean;
  isAdding: boolean;
  keywords: SerpAnalysisKeyword[];
  error: string;
}

const initialState: State = {
  isLoading: false,
  isAdding: false,
  keywords: [],
  error: '',
};

export const keywordsSlice = createSlice({
  name: 'keywords',
  initialState,
  reducers: {
    deleteKeywords: (state: State, action: PayloadAction<KeywordsDelete>) => {
      state.isLoading = true;
    },
    deleteKeywordsSuccess: (state: State, action: PayloadAction<KeywordsDelete>) => {
      state.keywords = state.keywords.filter(
        (kw) => !action.payload.find((itemToDelete) => kw.keyword_id === itemToDelete.keyword_id),
      );
      state.isLoading = false;
    },
    deleteKeywordsFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    addKeywords: (state: State, action: PayloadAction<KeywordsAdd[]>) => {
      state.isAdding = true;
    },
    addKeywordsSuccess: (state: State, action: PayloadAction<SerpAnalysisKeyword[]>) => {
      state.isAdding = false;
      state.keywords = [...state.keywords, ...action.payload];
    },
    addKeywordsFailure: (state: State, action: PayloadAction<string>) => {
      state.isAdding = false;
      state.error = action.payload;
    },
    fetchKeywords: (state: State, action: PayloadAction<number>) => {
      state.isLoading = true;
    },
    fetchKeywordsSuccess: (state: State, action: PayloadAction<SerpAnalysisKeyword[]>) => {
      state.isLoading = false;
      state.keywords = action.payload;
    },
    fetchKeywordsFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const selectKeywordsByAnalysis = createSelector(
  (state: State) => state.keywords,
  (_: any, id: number) => id,
  (keywords, id) => keywords.filter((kw) => kw.serp_analysis_id === id),
);

export const {
  deleteKeywords,
  deleteKeywordsSuccess,
  deleteKeywordsFailure,
  addKeywords,
  addKeywordsSuccess,
  addKeywordsFailure,
  fetchKeywords,
  fetchKeywordsSuccess,
  fetchKeywordsFailure,
} = keywordsSlice.actions;

export default keywordsSlice.reducer;
