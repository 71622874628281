import React from 'react';

import { DataGrid } from '@mui/x-data-grid';

import createUrlsByActorColumns from './columns';
import UrlsByResultType from '../type';

type UrlsByActorDataGridProps = {
  rows: UrlsByResultType[]
}

function UrlsByActorDataGrid(props: UrlsByActorDataGridProps) {
  const { rows } = props;

  return (
    <DataGrid
      density="compact"
      rows={rows}
      getRowId={(row) => row.url}
      columns={createUrlsByActorColumns()}
      disableRowSelectionOnClick
      disableColumnMenu
      autoHeight
      disableVirtualization
      pageSizeOptions={[100]}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 100,
          },
        },
        sorting: {
          sortModel: [{ field: 'url', sort: 'asc' }],
        },
      }}
      localeText={{
        noRowsLabel: 'Aucune URL n\'est définie',
      }}
    />
  );
}

export default UrlsByActorDataGrid;
