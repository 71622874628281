import React, { useEffect, useState } from 'react';

import { MenuItem, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';

import { downloadDataFile } from '../state/slice';

type DownloadDataFileProps = {
  dataFileId: number,
  handleCloseMenu: Function,
  fileName: string,
}

export default function DownloadDataFile(props: DownloadDataFileProps): JSX.Element {
  const {
    dataFileId,
    handleCloseMenu,
    fileName,
  } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isDownloadSent, setDownloadSent] = useState<boolean>(false);
  const [isPostDownload, setPostDownload] = useState<boolean>(false);

  const { isLoading: dataFilesLoading } = useAppSelector(
    (state) => state.dataFiles,
  );

  const dispatch = useAppDispatch();

  const handleConfirm = () => {
    dispatch(downloadDataFile({ dataFileId, fileName }));
    setPostDownload(true);
  };

  useEffect(() => {
    if (isDownloadSent && !dataFilesLoading) {
      setOpenModal(false);
      setDownloadSent(false);
    }
    if (dataFilesLoading && isPostDownload) {
      setDownloadSent(true);
    }
  }, [dataFilesLoading, isDownloadSent, isPostDownload]);

  return (
    <MenuItem
      sx={{ fontSize: globalTheme.fontSize.middlesmall }}
      onClick={() => { if (!openModal) setOpenModal(true); handleCloseMenu(); }}
      data-testid="download-data-file-menu-item"
    >
      Télécharger
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">Télécharger</Typography>
            {' '}
            ce fichier
          </Typography>
        )}
        actionConfirm={handleConfirm}
        actionCancel={() => setOpenModal(false)}
        isOpen={openModal}
        dataTestId="download-data-file-modal"
        displayLoading={dataFilesLoading}
        isProcessLoading={dataFilesLoading}
      />
    </MenuItem>
  );
}
