import React from 'react';

import { SelectChangeEvent } from '@mui/material';

import DropDown from 'components/dropDown/DropDown';

import TRANSACTION_TYPES from '../CONSTANTS';

type TransactionDropDownProps = {
  value: number | '',
  onChange: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void),
  width?: string,
}

function TransactionDropDown(props: TransactionDropDownProps) {
  const { value, onChange, width } = props;

  return (
    <DropDown
      options={[
        {
          description: 'Aucun filtre',
          id: 0,
        },
        ...TRANSACTION_TYPES,
      ]}
      getOptionLabel={(tt) => tt.description}
      getOptionValue={(tt) => tt.id}
      onChange={onChange}
      value={value}
      fullWidth
      size="small"
      width={width}
      label="Choisir"
      data-testid="select-transaction-type-drop-down"
    />
  );
}

export default TransactionDropDown;
