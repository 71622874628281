import React from 'react';

import { Box, Stack } from '@mui/system';

import globalTheme from 'common/theme';
import CategoryGroup from 'features/categoryGroups/classes/CategoryGroup';
import CATEGORY_TYPE_ID from 'features/categoryGroups/CONSTANTS';

import AddCategoryValue from './AddCategoryValue';
import GlobalDeleteCategoryValue from './GlobalDeleteCategoryValue';
import GlobalUpdateCategoryValue from './GlobalUpdateCategoryValue';
import RenameCategory from './RenameCategory';

type DataGridGroupActionsProps = {
  selection: number[]
  scopeId: number,
  categoryGroupType: number,
  categoryGroup?: CategoryGroup,
}

function DataGridGroupActions(props: DataGridGroupActionsProps) {
  const {
    selection,
    scopeId,
    categoryGroupType,
    categoryGroup,
  } = props;

  const formatHelperText = () => {
    const plurial = selection.length > 1;
    let label = '';
    if (categoryGroupType === CATEGORY_TYPE_ID.url) {
      label = `${selection.length} règle${plurial ? 's' : ''} d'URL`
              + ` sélectionnée${plurial ? 's' : ''}`;
    } else if (categoryGroupType === CATEGORY_TYPE_ID.keywords) {
      label = `${selection.length} règle${plurial ? 's' : ''} de mot-clé`
            + ` sélectionnée${plurial ? 's' : ''}`;
    } else if (categoryGroupType === CATEGORY_TYPE_ID.brand) {
      label = `${selection.length} requête${plurial ? 's' : ''} sélectionnée${plurial ? 's' : ''}`;
    }
    return label;
  };

  return (
    <Stack direction="row">
      <Stack
        direction="row"
        spacing={1}
        width="40%"
        height={35}
      >
        <AddCategoryValue
          categoryGroupType={categoryGroupType}
          scopeId={scopeId}
          categoryGroup={categoryGroup}
        />
        { categoryGroupType !== CATEGORY_TYPE_ID.brand
          && (
          <RenameCategory
            categoryGroupId={categoryGroup?.catGroupId ?? 0}
            categoryGroupType={categoryGroupType}
            disabled={categoryGroup?.catGroupCategories.length === 0}
          />
          )}
      </Stack>
      {selection.length > 0 && (
      <Stack
        direction="row"
        justifyContent="end"
        alignItems="center"
        spacing={1}
        width="60%"
      >
        <Box
          sx={{
            marginRight: '18px',
            fontSize: globalTheme.fontSize.middlesmall,
            fontWeight: '500',
          }}
          data-testid="help-label-selection-count"
        >
          {formatHelperText()}
        </Box>
        <GlobalUpdateCategoryValue
          selection={selection}
          categoryGroupId={categoryGroup?.catGroupId || 0}
          modalType="category_value_type_id"
        />
        <GlobalUpdateCategoryValue
          selection={selection}
          categoryGroupId={categoryGroup?.catGroupId || 0}
          modalType="include"
        />
        <GlobalDeleteCategoryValue selection={selection} categoryGroupType={categoryGroupType} />
      </Stack>
      )}
    </Stack>
  );
}

export default DataGridGroupActions;
