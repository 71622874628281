import React from 'react';

import {
  Box,
  Stack,
  StackProps,
  styled,
} from '@mui/material';

import CreateCatGroup from '../actions/CreateCatGroup';
import DeleteCatGroups from '../actions/DeleteCatGroup';

const PREFIX = 'ActionButton';

const classes = {
  core: `${PREFIX}-core`,
  button: `${PREFIX}-secondButton`,
  containedSuppr: `${PREFIX}-containedSuppr`,
  numberSelected: `${PREFIX}-numberSelected`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    marginRight: '1rem',
    width: '100%',
  },

  [`& .${classes.button}`]: {
    textTransform: 'none',
    fontSize: theme.fontSize.large,
    width: '120px',
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },

  [`& .${classes.containedSuppr}`]: {
    display: 'flex',
  },

  [`& .${classes.numberSelected}`]: {
    margin: 'auto 0 auto 0',
    marginRight: '18px',
    fontSize: theme.fontSize.middlesmall,
    fontWeight: '500',
  },
}));

type ButtonActionsProps = {
  idsSelected: number[],
  scopeId: number,
}

export default function ActionButton(props: ButtonActionsProps) {
  const {
    idsSelected,
    scopeId,
  } = props;

  return (
    <StyledStack
      direction="row"
      className={classes.core}
      spacing={2}
    >
      <Stack direction="row" width="40%">
        <CreateCatGroup scopeId={scopeId} />
      </Stack>
      {idsSelected.length !== 0 && (
        <Stack
          direction="row"
          width="60%"
          className={classes.containedSuppr}
          justifyContent="end"
          spacing={1}
        >
          <Box className={classes.numberSelected}>
            {`${idsSelected.length} `}
            groupe
            {
              (idsSelected.length > 1
                ? 's '
                : ' '
              )
            }
            sélectionné
            {
              (idsSelected.length > 1
                ? 's'
                : ''
              )
            }
          </Box>
          <DeleteCatGroups
            ids={idsSelected}
            scopeId={scopeId}
            isButton
          />
        </Stack>
      )}
    </StyledStack>
  );
}
