import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Button,
  Menu,
  Box,
  styled,
  BoxProps,
} from '@mui/material';

import { UserType } from 'features/users/types';

import DeleteUser from '../actions/DeleteUser';
import ModifyExternalUser from '../actions/ModifyExternalUser';
import UpdateUserProjects from '../actions/UpdateUserProjects';
import UpdateUserRole from '../actions/UpdateUserRole';
import UpdateUserStatus from '../actions/UpdateUserStatus';

const PREFIX = 'UsersDatagridActions';

const classes = {
  dropdown: `${PREFIX}-dropdown`,
  button: `${PREFIX}-button`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.dropdown}`]: {
    fontSize: theme.fontSize.xlarge,
  },

  [`& .${classes.button}`]: {
    color: '#737476',
    fontSize: theme.fontSize.medium,
    height: '30px',
    width: '115px',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      color: '#737476',
      backgroundColor: '#e8e8e8',
    },
  },
}));

type UsersDatagridActionsProps = {
  user: UserType,
  isExternal?: boolean,
  showEditUser?: boolean,
}

export default function UsersDatagridActions(props: UsersDatagridActionsProps): JSX.Element {
  const {
    user,
    isExternal,
    showEditUser,
  } = props;

  const [openModals, setOpenModals] = useState<Array<number>>([]);
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const openMenu = Boolean(anchorMenu);

  const handleOpenMenu = (event: any) => {
    setAnchorMenu(event.target);
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <StyledBox>
      <Button
        id={user.id.toString()}
        variant="contained"
        disableElevation
        onClick={(e) => { setOpenModals([...openModals, user.id]); handleOpenMenu(e); }}
        endIcon={<KeyboardArrowDownIcon className={classes.dropdown} />}
        size="small"
        className={classes.button}
        color="info"
        component="button"
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorMenu}
        open={openMenu && openModals.includes(user.id)}
        onClose={handleCloseMenu}
        keepMounted
        id="menuActionsUsers"
        data-testid="menu-users-project"
      >
        <UpdateUserStatus
          userItem={user}
          handleCloseMenu={handleCloseMenu}
        />
        {showEditUser && (
        <ModifyExternalUser
          user={user}
          handleCloseMenu={handleCloseMenu}
        />
        )}
        {isExternal && (
          <UpdateUserProjects
            user={user}
            handleCloseMenu={handleCloseMenu}
          />
        )}
        {!isExternal && (
          <UpdateUserRole
            userItem={user}
            handleCloseMenu={handleCloseMenu}
          />
        )}
        <DeleteUser
          id={user.id}
          handleCloseMenu={handleCloseMenu}
        />
      </Menu>
    </StyledBox>
  );
}
