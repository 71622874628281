import React from 'react';

import { Chip } from '@mui/material';

import globalTheme from 'common/theme';

import { UserType } from '../types';
import User from '../User';

type ChipViewProps = {
  userItem: UserType,
  projectManagerId: number | undefined,
}

export default function UserChipView(props: ChipViewProps): JSX.Element {
  const { userItem, projectManagerId } = props;
  const user = new User(userItem);

  return (
    <Chip
      sx={{ fontSize: globalTheme.fontSize.medium }}
      label={user.fullNameWithManagerInfos(projectManagerId)}
    />
  );
}
