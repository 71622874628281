import React from 'react';

import {
  Box,
  BoxProps,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  styled,
  Typography,
  Stack,
  SelectChangeEvent,
} from '@mui/material';

import UploadInput from 'components/input/UploadInput';
import { devicesList } from 'features/deviceType/CONSTANTS';
import SelectDeviceTypeView from 'features/deviceType/SelectView';
import SearchEngineDropDown from 'features/searchEngines/components/SearchEngineDropDown';
import SelectIndex from 'features/serpAnalysisIndex/components/SelectIndex';

import { AddSerpAnalysisDataFileFormData } from './types';

const PREFIX = 'ImportCSVProjectView';

const classes = {
  box: `${PREFIX}-box`,
  boldText: `${PREFIX}-boldText`,
  smallText: `${PREFIX}-smallText`,
  smallBoldText: `${PREFIX}-smallBoldText`,
  inputContainer: `${PREFIX}-inputContainer`,
  inputTitle: `${PREFIX}-inputTitle`,
  link: `${PREFIX}-link`,
  blockRadio: `${PREFIX}-radioBlock`,
  lineRadio: `${PREFIX}-lineRadio`,
  baselineRadio: `${PREFIX}-baselineRadio`,
  selectBox: `${PREFIX}-selectBox`,
  emphasis: `${PREFIX}-emphasis`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.box}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '600px',
    width: '100%',
    justifyContent: 'space-between',
  },

  [`& .${classes.boldText}`]: {
    fontWeight: 'bold',
    fontSize: theme.fontSize.medium,
  },

  [`& .${classes.smallText}`]: {
    fontSize: theme.fontSize.xsmall,
  },

  [`& .${classes.smallBoldText}`]: {
    fontSize: theme.fontSize.xsmall,
    fontWeight: 'bold',
  },

  [`& .${classes.inputContainer}`]: {
    margin: '2rem 0',
  },

  [`& .${classes.link}`]: {
    fontSize: theme.fontSize.xsmall,
    textDecoration: 'underline',
    marginTop: '0.3rem',
  },

  [`& .${classes.blockRadio}`]: {
    textAlign: 'left',
  },

  [`& .${classes.lineRadio}`]: {
    marginTop: '10px',
    marginRight: '0',
  },

  [`& .${classes.baselineRadio}`]: {
    fontSize: theme.fontSize.xsmall,
  },

  [`& .${classes.selectBox}`]: {
    margin: '.6rem',
    width: '100%',
  },

  [`& .${classes.emphasis}`]: {
    color: 'red',
  },
}));

export type AddSerpAnalysisDataFileFormProps = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  formProjectUpload: AddSerpAnalysisDataFileFormData,
  handleSelectChange:(event: SelectChangeEvent) => void
}

function AddSerpAnalysisDataFileForm(props: AddSerpAnalysisDataFileFormProps) {
  const {
    formProjectUpload,
    handleChange,
    handleSelectChange,
  } = props;

  return (
    <StyledBox data-testid="modal-add-serpanalysis-form" className={classes.box}>
      <RadioGroup
        defaultValue="2"
        value={formProjectUpload.data_file_mode_id}
        name="data_file_mode_id"
        className={classes.blockRadio}
        onChange={handleChange}
      >
        <FormControlLabel
          className={classes.lineRadio}
          sx={{ marginRight: 0 }}
          value="2"
          control={<Radio />}
          label={(
            <>
              <Typography variant="body2">
                <Typography className={classes.boldText} component="span">Ajouter </Typography>
                (mettre à jour)
              </Typography>
              <Typography className={classes.baselineRadio}>
                Utile pour mettre à jour les volumes et les intentions de recherche.
              </Typography>
              <Typography className={classes.baselineRadio}>
                Vous pouvez également ajouter des nouveaux groupes et des nouveaux mots clés.
              </Typography>
            </>
          )}
        />
        <FormControlLabel
          className={classes.lineRadio}
          value="1"
          control={<Radio />}
          label={(
            <>
              <Typography>
                <Typography className={classes.boldText} component="span">Remplacer </Typography>
                <Typography className={classes.emphasis} component="span" variant="body2">
                  (avec suppression)
                </Typography>
              </Typography>
              <Typography className={classes.baselineRadio}>
                Les groupes et mots clés absents du fichier
                CSV seront effacés définitivement du projet.
              </Typography>
              <Typography className={classes.baselineRadio}>
                Soyez prudent, ne faîtes pas cela si vous ne voulez pas perdre vos analyses.
              </Typography>
            </>
          )}
        />
      </RadioGroup>
      <Box className={classes.inputContainer}>
        <Typography className={classes.boldText}>
          Sélectionner votre fichier CSV
        </Typography>
        <UploadInput
          form={formProjectUpload}
          handleChange={handleChange}
        />
        <Typography className={classes.smallText}>
          <Typography
            component="span"
            className={classes.smallBoldText}
          >
            Format CSV 4 colonnes :
          </Typography>
          &quot;groupe&quot;; &quot;mot cle&quot;;
          &quot;volume mensuel&quot;; &quot;intention&quot;
        </Typography>
        <Link
          className={classes.link}
          href="/serp_analysis_sample.csv"
          download="exemple.csv"
        >
          utiliser ce fichier préformaté
        </Link>
      </Box>
      <Stack className={classes.selectBox} direction="column" spacing={2}>
        <SelectIndex
          values={formProjectUpload.indexId.toString()}
          handleChange={handleSelectChange}
        />
        <SelectDeviceTypeView
          handleDeviceChange={handleSelectChange}
          deviceSelected={formProjectUpload.deviceType.toString()}
          devices={devicesList}
          variant="outlined"
          isInputLabel
          selectName="deviceType"
          uniqueText
          menuItemFontSize=".875rem"
        />
        <SearchEngineDropDown
          onChange={handleSelectChange}
          value={formProjectUpload.searchEngineId}
          name="searchEngineId"
        />
      </Stack>
    </StyledBox>
  );
}

export default AddSerpAnalysisDataFileForm;
