import favicon1 from 'assets/images/favicon1.png';
import favicon2 from 'assets/images/favicon2.png';

function useFaviconChange() {
  const favicon:any = document.getElementById('favicon');
  const isDark = window.matchMedia('(prefers-color-scheme: dark)');
  const changeFavicon = () => {
    if (favicon) favicon.href = isDark.matches ? favicon1 : favicon2;
  };
  changeFavicon();
  isDark.addEventListener('change', changeFavicon);
}

export default useFaviconChange;
