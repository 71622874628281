import React from 'react';

import { SelectInputProps } from '@mui/material/Select/SelectInput';

import DropDown from 'components/dropDown/DropDown';

import SEARCH_ENGINES from '../CONSTANT';

type SearchEngineDropDownProps = {
  value: number,
  onChange: SelectInputProps<any>['onChange'],
  name?: string,
}

const options = Object.values(SEARCH_ENGINES);

function SearchEngineDropDown(props: SearchEngineDropDownProps) {
  const { value, onChange, name } = props;

  return (
    <DropDown
      size="small"
      label="Écosystème"
      name={name}
      onChange={onChange}
      options={options}
      getOptionLabel={(se) => se.label}
      getOptionValue={(se) => se.id}
      value={value}
    />
  );
}

export default SearchEngineDropDown;
