type SerializeSearchParamsObject = {
  [key: string]: string | string[] | number | number[] | boolean | undefined
}

function serializeSearchParams(
  obj: SerializeSearchParamsObject,
): Record<string, string | string[]> {
  return Object.keys(obj).reduce((previous, current) => {
    const currentValue = obj[current];

    if (typeof currentValue === 'string') previous[current] = currentValue;
    else if (typeof currentValue === 'number') previous[current] = currentValue.toString();
    else if (typeof currentValue === 'boolean') previous[current] = currentValue.toString();
    else if (Array.isArray(currentValue)) previous[current] = currentValue.map(String);

    return previous;
  }, {} as Record<string, string | string[]>);
}

export default serializeSearchParams;
