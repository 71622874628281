import React from 'react';

import {
  TextField,
  Box,
  styled,
  BoxProps,
} from '@mui/material';
import { FormikProps, FormikProvider } from 'formik';

import { UpdateProjectForm } from './renameProjectConfig';

const PREFIX = 'EditProjectModal';

const classes = {
  input: `${PREFIX}-input`,
  inputContent: `${PREFIX}-inputContent`,
  box: `${PREFIX}-box`,
  rename: `${PREFIX}-rename`,
};

const StyledBox = styled(Box)<BoxProps<'form'>>(({ theme }) => ({
  [`&.${classes.box}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },

  [`& .${classes.input}`]: {
    width: '100%',
  },

  [`& .${classes.inputContent}`]: {
    fontSize: theme.fontSize.medium,
  },

}));

type FormEditProjectProps = {
  formik: FormikProps<UpdateProjectForm>,
}

function EditProjectModal(props: FormEditProjectProps) {
  const {
    formik,
  } = props;
  const { handleChange } = formik;

  return (
    <StyledBox
      className={classes.box}
      data-testid="modal-rename-project"
    >
      <FormikProvider value={formik}>
        <TextField
          margin="normal"
          id="name"
          label="Nouveau nom"
          type="text"
          variant="outlined"
          size="small"
          onChange={handleChange}
          value={formik.values.name}
          className={classes.input}
          data-testid="textfield-modal-rename-project"
          slotProps={{
            input: {
              className: classes.inputContent,
            },
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.name
            && Boolean(formik.errors.name)
          }
          helperText={
            formik.touched.name
            && formik.errors.name
          }
        />
      </FormikProvider>
    </StyledBox>

  );
}

export default EditProjectModal;
