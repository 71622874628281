import moment from 'moment';

import { DEVICE_TYPE } from 'features/deviceType/CONSTANTS';
import PLANNING_TYPE from 'features/serpAnalysisPlanning/state/CONSTANTS';
import { convertDate, DATE_NUMBERS_HOURS } from 'utils/formatDate';

import { DEFAULT_SCRAP_DATE } from '../CONSTANT';

const getNextScrapDate = (
  lastScrapDate: string | null,
  planDate: string | null,
  planningId: number | null,
  planningDelay: number | null,
  isBudgetEnough: boolean = true,
  dateFormat: number = DATE_NUMBERS_HOURS,
  default_empty: boolean = false,
) => {
  if (!planningId || (!planDate && planningDelay === null) || !isBudgetEnough) {
    return default_empty ? '' : DEFAULT_SCRAP_DATE;
  }

  const planMom = moment(planDate);
  const lastScrapMom = moment(lastScrapDate);

  if (!lastScrapDate || planMom > lastScrapMom) {
    return convertDate(planMom.toDate(), dateFormat);
  }

  let newDate;
  switch (planningId) {
    case PLANNING_TYPE.QUARTERLY:
      newDate = lastScrapMom.add(planningDelay ?? 3, 'M');
      return convertDate(newDate.toDate(), dateFormat);
    case PLANNING_TYPE.MONTHLY:
      newDate = lastScrapMom.add(planningDelay ?? 1, 'M');
      return convertDate(newDate.toDate(), dateFormat);
    case PLANNING_TYPE.WEEKLY:
      newDate = lastScrapMom.add(planningDelay ?? 1, 'w');
      return convertDate(newDate.toDate(), dateFormat);
    case PLANNING_TYPE.DAILY:
      newDate = moment().set({
        hour: planMom.get('hour'),
        minute: planMom.get('minute'),
        second: planMom.get('second'),
      });
      if (newDate < moment()) {
        newDate = newDate.add(planningDelay ?? 1, 'd');
      }
      return convertDate(newDate.toDate(), dateFormat);
    default:
      return default_empty ? '' : DEFAULT_SCRAP_DATE;
  }
};

export const isBudgetEnoughForSerpAnalysis = (
  projectBudget: number,
  keywordPrice: number,
  keywordCount: number,
  deviceType: number,
) => {
  if (projectBudget <= 0) return false;

  const deviceCount = deviceType === DEVICE_TYPE.DESKTOP_MOBILE ? 2 : 1;
  const priceEstimatation = keywordPrice * keywordCount * deviceCount;

  return projectBudget >= priceEstimatation;
};

export default getNextScrapDate;
