import React, { useState } from 'react';

import {
  Box,
  Typography,
  MenuItem,
} from '@mui/material';
import { FormikProps, useFormik } from 'formik';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';

import UpdateUserProjectsModal from './UpdateUserProjectModal';
import { UpdateUserProjectsForm, initValues, validationSchema } from './updateUserprojectsConfig';
import { updateUserProjects } from '../state/slice';
import { UserType, UserUpdateProjectsPayload } from '../types';

type UpdateUserProjectsProps = {
  user: UserType,
  handleCloseMenu: Function,
}

function UpdateUserProjects(props: UpdateUserProjectsProps) {
  const {
    user,
    handleCloseMenu,

  } = props;
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSubmit = (values: UpdateUserProjectsForm) => {
    const payload: UserUpdateProjectsPayload = {
      id: user.id,
      userProjects: [],
    };
    if (values.projectsConsult?.length) {
      payload.userProjects = values.projectsConsult
        .filter((projectId) => typeof projectId !== 'string')
        .map(
          (projectId) => ({
            project_id: projectId,
            is_consultant: true,
            user_id: user.id,
          }),
        );
    }

    if (values.projectsEdit?.length) {
      payload.userProjects = [
        ...payload.userProjects,
        ...values.projectsEdit
          .filter((projectId) => typeof projectId !== 'string')
          .map(
            (projectId) => ({
              project_id: projectId,
              is_consultant: false,
              user_id: user.id,
            }),
          ),
      ];
    }

    dispatch(updateUserProjects(payload));
    setIsOpen(false);
  };

  const userProjects = user.projects.map((project) => ({
    project_id: project.id,
    is_consultant: project.is_consultant,
  }));

  const formik: FormikProps<UpdateUserProjectsForm> = useFormik<UpdateUserProjectsForm>(
    {
      initialValues: initValues(userProjects),
      validationSchema,
      onSubmit: handleSubmit,
      enableReinitialize: true,
    },
  );

  return (
    <Box>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => {
            setIsOpen(true);
            handleCloseMenu();
          }
        }
      >
        Modifier les projets
      </MenuItem>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">
              Modifier
            </Typography>
            {' '}
            les projets attribués
          </Typography>
        )}
        actionConfirm={formik.handleSubmit}
        actionCancel={() => setIsOpen(false)}
        isOpen={isOpen}
        dataTestId="update-user-projects-modal"
      >
        <UpdateUserProjectsModal
          formik={formik}
        />
      </Modal>
    </Box>
  );
}

export default UpdateUserProjects;
