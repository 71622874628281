import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import { RootState } from 'common/store';

import { fetchRankingTypes } from './slice';

const useRankingTypes = () => {
  const dispatch = useAppDispatch();

  const { isLoading, error, rankingTypes } = useAppSelector(
    (state: RootState) => state.rankingTypes,
  );

  useEffect(() => {
    if (!isLoading && rankingTypes.length === 0) {
      dispatch(fetchRankingTypes());
    }
  }, [dispatch, fetchRankingTypes]);

  return {
    isLoading,
    error,
    rankingTypes,
  };
};

export default useRankingTypes;
