import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import downloadFile from 'utils/downloadFile';

import ApiDataviz from './api';
import {
  fetchChartData,
  fetchChartDataSuccess,
  fetchChartDataFailure,
  fetchExportData,
  fetchExportDataFailure,
  fetchExportDataSuccess,
} from './slice';
import {
  DatavizRequest,
} from '../types';

export function* workFetchChartData(action: PayloadAction<DatavizRequest>) {
  try {
    const chartData: any[] = [];
    for (let i = 0; i < action.payload.requests.length; i += 1) {
      const request = action.payload.requests[i];
      const response: AxiosResponse = yield call(
        ApiDataviz.fetchChartData,
        request,
      );
      chartData.push(...response.data.values);
    }

    const result = {
      values: chartData,
      key: action.payload.key,
    };

    yield put(fetchChartDataSuccess(result));
  } catch (e: any) {
    const chartError = {
      key: action.payload.key,
      error: e.message,
    };
    yield put(fetchChartDataFailure(chartError));
  }
}

export function* workFetchExportData(action: PayloadAction<DatavizRequest>) {
  try {
    const chartData: any[] = [];
    for (let i = 0; i < action.payload.requests.length; i += 1) {
      const request = action.payload.requests[i];
      const response: AxiosResponse = yield call(
        ApiDataviz.fetchExportData,
        request,
      );
      chartData.push(response.data);
    }

    const file = new Blob(chartData, {
      type: 'text/csv;charset=utf-8;',
    });

    downloadFile(file, action.payload.exportName || 'benhur_export.csv');

    const result = {
      key: action.payload.key,
      values: [],
    };
    yield put(fetchExportDataSuccess(result));
    // call le success
  } catch (e: any) {
    const chartError = {
      key: action.payload.key,
      error: e.message,
    };
    yield put(fetchExportDataFailure(chartError));
  }
}

export function* sagaFetchChartData() {
  yield takeEvery(fetchChartData, workFetchChartData);
}

export function* sagaFetchExportData() {
  yield takeEvery(fetchExportData, workFetchExportData);
}
