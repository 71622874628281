import React from 'react';

import { GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid';

interface GridCustomToolbarProps {
  setColumnButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
}

function GridCustomToolbar({ setColumnButtonEl }: GridCustomToolbarProps) {
  return (
    <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <GridToolbarColumnsButton ref={setColumnButtonEl} />
    </GridToolbarContainer>
  );
}

export default GridCustomToolbar;
