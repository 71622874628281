import { AppDispatch } from 'common/store';
import { ActorType } from 'features/actors/types';
import { Category, CategoryGroup } from 'features/categoryGroups/types';
import { KeywordRedux } from 'features/keywords/types';
import { RANK_SLICES } from 'features/rankSlice/CONSTANT';
import { Analysis } from 'features/serpAnalysis/types';

import { KPI_VIEW_TYPE } from './CONSTANTS';
import {
  deleteKpiViewRequested,
  downloadKpiViewRequested,
  updateKpiViewRequested,
} from './state/slice';
import {
  KpiViewCreateUpdate,
  KpiViewType,
  RankingType,
  SaKeywordIntention,
  SaRankSlice,
} from './types';

export default class KpiView {
  private id: number;

  private time_serie: string = '';

  private label: string = '';

  private start_date: string = '';

  private last_export_date: string | undefined;

  private scope_id: number | undefined;

  private kpi_view_export_id: number | undefined;

  private kpi_view_export_status_id: number | undefined;

  private kpi_view_type_id: number | undefined;

  private device_type_id: number | undefined;

  private best_rank: boolean = false;

  private category_group_id: number | undefined;

  private category_group: CategoryGroup | undefined;

  private categories: Category[] = [];

  private serp_analyses: Analysis[] = [];

  private actors: ActorType[] = [];

  private keywords: KeywordRedux[] = [];

  private sa_rank_slices: SaRankSlice[] = [];

  private sa_keyword_intentions: SaKeywordIntention[] = [];

  private ranking_types: RankingType[] = [];

  constructor(kpiView: KpiViewType) {
    this.id = kpiView.id;
    this.time_serie = kpiView.time_serie;
    this.label = kpiView.label;
    this.start_date = kpiView.start_date;
    this.last_export_date = kpiView.last_export_date;
    this.scope_id = kpiView.scope_id;
    this.kpi_view_export_id = kpiView.kpi_view_export_id;
    this.kpi_view_export_status_id = kpiView.kpi_view_export_status_id;
    this.kpi_view_type_id = kpiView.kpi_view_type_id;
    this.device_type_id = kpiView.device_type_id;
    this.best_rank = kpiView.best_rank;
    this.category_group_id = kpiView.category_group_id;
    this.category_group = kpiView.category_group;
    this.categories = kpiView.categories;
    this.serp_analyses = kpiView.serp_analyses;
    this.actors = kpiView.actors;
    this.keywords = kpiView.keywords;
    this.sa_rank_slices = kpiView.sa_rank_slices;
    this.sa_keyword_intentions = kpiView.sa_keyword_intentions;
    this.ranking_types = kpiView.ranking_types;
  }

  get kpiViewId() {
    return this.id;
  }

  get kpiViewTimeSerie() {
    return this.time_serie;
  }

  get kpiViewLabel() {
    return this.label;
  }

  get kpiViewStartDate() {
    return this.start_date;
  }

  get kpiViewLastExportDate() {
    return this.last_export_date;
  }

  get kpiViewScopeId() {
    return this.scope_id;
  }

  get kpiViewKpiViewExportId() {
    return this.kpi_view_export_id;
  }

  get kpiViewKpiViewExportStatusId() {
    return this.kpi_view_export_status_id;
  }

  get kpiViewKpiViewTypeId() {
    return this.kpi_view_type_id;
  }

  get kpiViewDeviceTypeId() {
    return this.device_type_id;
  }

  get kpiViewBestRank() {
    return this.best_rank;
  }

  get kpiViewCategoryGroupId() {
    return this.category_group_id;
  }

  get kpiViewCategoryGroup() {
    return this.category_group;
  }

  get kpiViewCategories() {
    return this.categories;
  }

  get kpiViewSerpAnalyses() {
    return this.serp_analyses;
  }

  get kpiViewActors() {
    return this.actors;
  }

  get kpiViewKeywords() {
    return this.keywords;
  }

  get kpiViewSaRankSlices() {
    return this.sa_rank_slices;
  }

  get kpiViewSaKeywordIntetions() {
    return this.sa_keyword_intentions;
  }

  get kpiViewRankingTypes() {
    return this.ranking_types;
  }

  formatToQueryFilters() {
    const query: URLSearchParams = new URLSearchParams();
    query.set('rankingTypeId', `${this.ranking_types.map((rt) => rt.id).join('-')}`);
    query.set('actorIds', `${this.actors.map((act) => act.id).join('-')}`);
    query.set('intentionIds', `${this.sa_keyword_intentions.map((ki) => ki.id).join('$')}`);
    query.set('timeSeries', `${this.time_serie}`);

    // Default ranking slice must be TOP 20 for serp tracking kpi view
    if (this.kpi_view_type_id === KPI_VIEW_TYPE.serpTracking.id) {
      query.set('rankSliceIds', `${
        [RANK_SLICES[0], RANK_SLICES[1], RANK_SLICES[2]].map((rs) => rs.id).join('-')
      }`);
    } else {
      query.set('rankSliceIds', `${this.sa_rank_slices.map((rs) => rs.id).join('-')}`);
    }
    query.set('deviceTypeId', `${this.device_type_id}`);
    query.set('keywordIds', `${this.keywords.map((ke) => ke.id).join('-')}`);

    return query;
  }

  formatToObjectFilters() {
    return {
      rankingTypeId: this.ranking_types.map((rt) => rt.id),
      actorIds: this.actors.map((act) => act.id).filter((id) => id !== undefined),
      intentionIds: this.sa_keyword_intentions.map((ki) => ki.id),
      timeSeries: this.time_serie,
      rankSliceIds: this.kpi_view_type_id === KPI_VIEW_TYPE.serpTracking.id
        ? [RANK_SLICES[0], RANK_SLICES[1], RANK_SLICES[2]].map((rs) => rs.id)
        : this.sa_rank_slices.map((rs) => rs.id),
      deviceTypeId: this.device_type_id,
      keywordIds: this.keywords.map((ke) => ke.id),
    };
  }

  delete(dispatch: AppDispatch, deleteFiles: boolean) {
    const payload = {
      viewId: this.id,
      deleteFiles,
    };
    dispatch(deleteKpiViewRequested(payload));
  }

  update(dispatch: AppDispatch, newView: KpiViewCreateUpdate) {
    dispatch(updateKpiViewRequested({
      viewId: this.id,
      view: newView,
    }));
  }

  download(dispatch: AppDispatch) {
    dispatch(downloadKpiViewRequested({
      viewId: this.id,
    }));
  }
}
