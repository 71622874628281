import React from 'react';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box, Button } from '@mui/material';
import { FormikProps } from 'formik';

import Modal from 'components/modal/Modal';

import { AddKeywordsFormType } from './config';
import AddKeywordsForm from './Form';
import AddKwModalTitle from './ModalTitle';

type FormModalProps = {
  nameAnalysis: string | undefined,
  formik: FormikProps<AddKeywordsFormType>,
  openModal: boolean,
  isKeywordsLoading: boolean,
  keywordsError: string,
  disabled?: boolean,
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
  setIsConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

function AddKeywordsFormModal(props: FormModalProps) {
  const {
    nameAnalysis,
    setOpenModal,
    formik,
    setIsConfirmationOpen,
    openModal,
    isKeywordsLoading,
    keywordsError,
    disabled,
  } = props;
  const { handleSubmit } = formik;

  return (
    <Box>
      <Button
        variant="contained"
        disableElevation
        onClick={
          () => { setOpenModal(true); }
        }
        startIcon={<AddOutlinedIcon />}
        disabled={disabled}
      >
        Ajouter des mots clés
      </Button>
      <Modal
        title={<AddKwModalTitle />}
        actionConfirm={handleSubmit}
        actionCancel={() => {
          setOpenModal(false);
          setIsConfirmationOpen(false);
        }}
        isOpen={openModal}
        isProcessLoading={isKeywordsLoading}
        displayLoading
      >
        <AddKeywordsForm
          formik={formik}
          error={keywordsError}
          nameAnalysis={nameAnalysis}
        />
      </Modal>
    </Box>
  );
}

export default AddKeywordsFormModal;
