import React from 'react';

import { Chip } from '@mui/material';

import { DATA_FILE_STATUS } from '../CONSTANT';

interface StatusFlagProps {
  statusId: number,
}

export default function StatusFlag(props: StatusFlagProps): JSX.Element {
  const { statusId } = props;
  let label: string = '';
  let color: 'default' | 'error' | 'success' | 'warning';
  switch (statusId) {
    case DATA_FILE_STATUS.initial_state:
      label = 'Non traité';
      color = 'default';
      break;
    case DATA_FILE_STATUS.to_import:
    case DATA_FILE_STATUS.importing:
    case DATA_FILE_STATUS.import_error:
      label = 'En cours';
      color = 'warning';
      break;
    case DATA_FILE_STATUS.imported:
      label = 'Succès';
      color = 'success';
      break;
    case DATA_FILE_STATUS.to_delete:
    case DATA_FILE_STATUS.deleting:
    case DATA_FILE_STATUS.delete_error:
      label = 'Suppression';
      color = 'warning';
      break;
    default:
      label = 'Erreur';
      color = 'error';
      break;
  }

  return (
    <Chip size="small" label={label} color={color} />
  );
}
