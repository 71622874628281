/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  PeriodFetchByDataSourceIdsPayload,
  PeriodValuesStateType,
} from '../types';

export interface State {
  isLoading: boolean;
  periodsValues: PeriodValuesStateType[];
  error: string;
}

const initialState: State = {
  isLoading: false,
  periodsValues: [],
  error: '',
};

export const periodSlice = createSlice({
  name: 'periods',
  initialState,
  reducers: {
    fetchPeriodByScopeAndDataSource: (
      state: State,
      action: PayloadAction<PeriodFetchByDataSourceIdsPayload>,
    ) => {
      state.isLoading = true;
    },
    fetchPeriodByScopeAndDataSourceSuccess: (
      state: State,
      action: PayloadAction<PeriodValuesStateType>,
    ) => {
      if (state.periodsValues.find(
        (pr) => pr.scopeId === action.payload.scopeId
        && action.payload.dataSourceIds.every((result) => pr.dataSourceIds.includes(result))
        && pr.dataSourceIds.every((result) => action.payload.dataSourceIds.includes(result)),
      )) return;
      state.periodsValues.push(action.payload);
      state.isLoading = false;
    },
    fetchPeriodByScopeAndDataSourceFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const selectPeriodsByScopeIdAndDataSourceIds = createSelector(
  (state: State) => state.periodsValues,
  (_: any, scopeId: number, dataSourceIds: number[]) => scopeId,
  (_: any, scopeId: number, dataSourceIds: number[]) => dataSourceIds,
  (periodsBlock, scopeId, dataSourceIds) => periodsBlock.filter(
    (periods) => (
      periods.scopeId === scopeId
      && dataSourceIds.every((id) => periods.dataSourceIds.includes(id))
    ),
  ),
);

export const {
  fetchPeriodByScopeAndDataSource,
  fetchPeriodByScopeAndDataSourceSuccess,
  fetchPeriodByScopeAndDataSourceFailure,
} = periodSlice.actions;

export default periodSlice.reducer;
