import React from 'react';

import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';

import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';
import { convertDate, DATE_NUMBERS_HOURS } from 'utils/formatDate';

import { Transaction } from '../types';

const columns: GridColDef<Transaction>[] = [
  {
    field: 'insertion_date',
    renderHeader: () => (
      <StyledHeader label="Date" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">
        {convertDate(moment(params.value).toDate(), DATE_NUMBERS_HOURS)}
      </Typography>
    ),
    valueGetter: (value, row) => row.insertion_date,
    flex: 1,
    minWidth: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'transaction_type',
    renderHeader: () => (
      <StyledHeader label="TRANSACTIONS" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">
        {params.value}
      </Typography>
    ),
    valueGetter: (value, row) => row.transaction_type.description,
    flex: 1.5,
    minWidth: 160,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'serp_analysis',
    renderHeader: () => (
      <StyledHeader label="ETUDE DE MOTS CLÉS" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">
        {params.value}
      </Typography>
    ),
    valueGetter: (value, row) => row.serp_analysis?.label,
    flex: 2.5,
    minWidth: 200,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'keyword_count',
    renderHeader: () => (
      <StyledHeader label="SERP" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">
        {params.value}
      </Typography>
    ),
    valueGetter: (value, row) => row.keyword_total_count,
    flex: 1,
    minWidth: 100,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'keyword_error',
    renderHeader: () => (
      <StyledHeader label="Erreurs" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">
        {params.value}
      </Typography>
    ),
    valueGetter: (value, row) => row.keyword_error_count,
    flex: 1,
    minWidth: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'keyword_price',
    renderHeader: () => (
      <StyledHeader label="COÛT/SERP" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">
        {params.value > 0 && `${params.value.toFixed(3)}€`}
      </Typography>
    ),
    valueGetter: (value, row) => (Math.ceil(Number(row.keyword_price) * 1000) / 1000),
    flex: 1.5,
    minWidth: 120,
    headerAlign: 'center',
    align: 'right',
  },
  {
    field: 'value',
    renderHeader: () => (
      <StyledHeader label="Montant" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">
        {params.value > 0 && '+'}
        {(Math.ceil(Number(params.value) * 1000) / 1000).toFixed(3)}
        €
      </Typography>
    ),
    valueGetter: (value, row) => row.value,
    flex: 5,
    headerAlign: 'center',
    align: 'right',
  },
];

export default columns;
