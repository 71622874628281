import React from 'react';

import { Box, Paper } from '@mui/material';

import globalTheme from 'common/theme';
import { METRICS } from 'features/performance/CONSTANT';
import formatThousands from 'utils/formatThousands';

export interface SeoPerformanceTooltipProps {
  active?: boolean,
  payload?: any[],
  tooltipLabel?: string,
  datavizMode?: string,
  percentLabel?: boolean,
}

function SeoPerformanceTooltipComponent(props: SeoPerformanceTooltipProps) {
  const {
    active = false,
    payload = [],
    tooltipLabel = '',
    datavizMode = 'rank',
    percentLabel = false,
  } = props;

  const plurial = (value: any) => (value > 1 ? 's' : '');

  const formatLabel = (pl: any) => {
    const baseLabel = `${tooltipLabel || pl.name} : `;
    let prefixLabel: string;

    if (percentLabel) {
      prefixLabel = `${pl.payload[`${pl.dataKey}-percentage`]}%`;
    } else if (datavizMode === METRICS[0]) {
      prefixLabel = `${formatThousands(pl.value)} position${plurial(pl.value)}`;
    } else if (datavizMode === METRICS[1]) {
      prefixLabel = `${formatThousands(pl.value)} demande${plurial(pl.value)}`;
    } else if (datavizMode === METRICS[2]) {
      prefixLabel = `${formatThousands(pl.value)}
      visite${plurial(pl.value)} estimée${plurial(pl.value)}`;
    } else prefixLabel = '';

    return baseLabel + prefixLabel;
  };

  if (active && payload && payload.length) {
    return (
      <Box
        component={Paper}
        sx={{
          padding: '0px 10px',
          textAlign: 'left',
          fontSize: globalTheme.fontSize.xsmall,
          border: 'none',
        }}
      >
        {
          payload.map((pl) => (
            <Box
              key={`barTooltip-${pl.name}-${Math.random() * 10000}`}
              color={pl.color}
            >
              {formatLabel(pl)}
            </Box>
          ))
        }
      </Box>
    );
  }
  return null;
}

export default SeoPerformanceTooltipComponent;
