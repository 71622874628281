/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  KpiViewType,
  KpiViewCreateUpdate,
  KpiViewDeletePayload,
  UpdatePayload,
  KpiViewDownloadPayload,
} from '../types';

export interface State {
  isLoading: boolean;
  error: string;
  kpiViews: KpiViewType[],
}

const initialState: State = {
  isLoading: false,
  error: '',
  kpiViews: [],
};

export const kpiViewSlice = createSlice({
  name: 'kpi_views',
  initialState,
  reducers: {
    fetchKpiViewRequested: (state: State, action: PayloadAction<number>) => {
      state.isLoading = true;
    },
    fetchKpiViewSuccess: (state: State, action: PayloadAction<KpiViewType[]>) => {
      state.isLoading = false;
      state.error = '';
      state.kpiViews = action.payload;
    },
    fetchKpiViewFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createKpiViewRequested: (state: State, action: PayloadAction<KpiViewCreateUpdate>) => {
      state.isLoading = true;
    },
    createKpiViewSuccess: (state: State, action: PayloadAction<KpiViewType>) => {
      state.isLoading = false;
      state.error = '';
      state.kpiViews.push(action.payload);
    },
    createKpiViewFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateKpiViewRequested: (state: State, action: PayloadAction<UpdatePayload>) => {
      state.isLoading = true;
    },
    updateKpiViewSuccess: (state: State, action: PayloadAction<KpiViewType>) => {
      state.isLoading = false;
      state.error = '';
      state.kpiViews = state.kpiViews.filter((view) => view.id !== action.payload.id);
      state.kpiViews.push(action.payload);
    },
    updateKpiViewFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteKpiViewRequested: (state: State, action: PayloadAction<KpiViewDeletePayload>) => {
      state.isLoading = true;
    },
    deleteKpiViewSuccess: (state: State, action: PayloadAction<KpiViewType>) => {
      state.isLoading = false;
      state.error = '';
      state.kpiViews = state.kpiViews.filter((view) => view.id !== action.payload.id);
    },
    deleteKpiViewFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    downloadKpiViewRequested: (state: State, action: PayloadAction<KpiViewDownloadPayload>) => {
      state.isLoading = true;
    },
    downloadKpiViewSuccess: (state: State) => {
      state.isLoading = false;
    },
    downloadKpiViewFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const selectKpiViewByAnalysisAndType = createSelector(
  (state: State) => state.kpiViews,
  (_: any, serpAnalysisId: number | null) => serpAnalysisId,
  (_: any, serpAnalysisId: number | null, typeIds: number[] | null) => typeIds,
  (kpiViews, serpAnalysisId, typeIds) => kpiViews.filter(
    (kpiView) => (
      typeIds?.includes(kpiView.kpi_view_type_id)
      && kpiView.serp_analyses.find((sa) => sa.id === serpAnalysisId)
    ),
  ),
);

export const selectKpiViewById = createSelector(
  (state: State) => state.kpiViews,
  (_: any, viewId: number | null) => viewId,
  (kpiViews, viewId) => kpiViews.find(
    (kpiView) => kpiView.id === viewId,
  ),
);

export const selectKpiViewByScopeAndType = createSelector(
  (state: State) => state.kpiViews,
  (_: any, scopeId: number | null) => scopeId,
  (_: any, scopeId: number | null, typeIds: number[] | null) => typeIds,
  (kpiViews, scopeId, typeIds) => kpiViews.filter(
    (kpiView) => kpiView.scope_id === scopeId && typeIds?.includes(kpiView.kpi_view_type_id),
  ),
);

export const {
  fetchKpiViewRequested,
  fetchKpiViewSuccess,
  fetchKpiViewFailure,
  createKpiViewRequested,
  createKpiViewSuccess,
  createKpiViewFailure,
  updateKpiViewRequested,
  updateKpiViewSuccess,
  updateKpiViewFailure,
  deleteKpiViewRequested,
  deleteKpiViewSuccess,
  deleteKpiViewFailure,
  downloadKpiViewRequested,
  downloadKpiViewSuccess,
  downloadKpiViewFailure,
} = kpiViewSlice.actions;

export default kpiViewSlice.reducer;
