import { useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import { RootState } from 'common/store';

import { fetchActors, selectActorsByScopeId } from './slice';

type ActorsHookProps = {
  scopeId: number,
  /**
    * Used as a quick fix to prevent an infinite loop if the previous fetch data is empty
    * @todo: Remove doFetch when the store values will be updated
    */
  doFetch?: boolean,
}

const useActors = (props: ActorsHookProps) => {
  const { scopeId, doFetch = true } = props;
  const dispatch = useAppDispatch();
  const [currentLoading, setCurrentLoading] = useState(true);

  const { isLoading: isLoadingState, error } = useAppSelector(
    (state: RootState) => state.actors,
  );

  const actors = useAppSelector(
    (state) => selectActorsByScopeId(state.actors, scopeId),
  );

  useEffect(() => {
    if (scopeId !== 0 && !isLoadingState && actors.length === 0 && doFetch) {
      dispatch(fetchActors({ scopeId }));
      setCurrentLoading(true);
    }
  }, [scopeId]);

  useEffect(() => {
    if (!isLoadingState && currentLoading) {
      setCurrentLoading(false);
    }
  }, [isLoadingState]);

  const hasActors = actors.length > 0;

  const isLoading = isLoadingState || currentLoading;

  return {
    isLoading,
    error,
    actors,
    hasActors,
  };
};

export default useActors;
