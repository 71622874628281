import React, { MouseEventHandler } from 'react';

import { Button, Stack } from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';

import ActorsDropDown from 'features/actors/components/ActorsDropDown';
import SelectDataSource from 'features/dataSource/components/SelectDataSource';
import PeriodsDropDown from 'features/period/component/PeriodsDropDown';

import DataFileStatusDropDown from './DataFileStatusDropDown';
import { DATA_SOURCE } from '../CONSTANT';
import { DataFileFilters } from '../types';

type DataFileFiltersBarProps = {
  filters: DataFileFilters,
  handleChange: SelectInputProps<any>['onChange'],
  scopeId: number,
  resetFilters: MouseEventHandler<HTMLButtonElement>,
};

function DataFileFiltersBar (props: DataFileFiltersBarProps) {
  const {
    filters,
    handleChange,
    scopeId,
    resetFilters,
  } = props;

  return (
    <Stack direction="row" gap={2} width="75%" justifyContent="flex-start">
      <Stack direction="row" gap={2} width="75%" justifyContent="flex-start">
        <ActorsDropDown
          value={filters.actor_id || 0}
          onChange={handleChange}
          scopeId={scopeId}
          size="small"
          name="actor_id"
          emptyOption="Aucun filtre"
          label="Acteurs"
          displayEmptyInList
          noActorsOption={{
            id: -1,
            name: 'Fichiers sans acteur',
          }}
        />
        <PeriodsDropDown
          value={filters.periode || ''}
          onChange={handleChange}
          scopeId={scopeId}
          dataSources={[DATA_SOURCE.semrush.id]}
          size="small"
          name="periode"
          emptyOption="Aucun filtre"
          label="Périodes"
          displayEmptyInList
          noPeriodsOption={{
            value: -1,
            label: 'Fichiers sans période',
          }}
        />
        <SelectDataSource
          value={filters.data_source_id || 0}
          onChange={handleChange}
          size="small"
          name="data_source_id"
          filterOptions={[]}
          emptyOption="Aucun filtre"
          label="Sources"
          displayEmptyInList
        />
        <DataFileStatusDropDown
          value={filters.data_file_status_id || 0}
          onChange={handleChange}
          size="small"
          name="data_file_status_id"
          emptyOption="Aucun filtre"
          label="États"
          displayEmptyInList
        />
      </Stack>
      <Button
        component="button"
        onClick={resetFilters}
        variant="text"
      >
        Réinitialiser
      </Button>
    </Stack>
  );
}

export default DataFileFiltersBar;
