import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FormikProps, useFormik } from 'formik';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import useCurrentUser from 'features/authentication/state/useCurrentUser';
import { resetDataviz } from 'features/dataviz/state/slice';
import useKeywords from 'features/keywords/state/useKeywords';
import { selectProjectById } from 'features/projects/state/slice';
import { splitIdName } from 'utils/urlManipulation';

import {
  initialValues,
  AddKeywordsFormType,
  validationSchema,
} from './config';
import AddKeywordsModalsChoiceView from './ModalsChoiceView';

type AddKeywordsModalProps = {
  nameAnalysis: string | undefined,
  serpAnalysisId: number | undefined,
}

function AddKeywordsModalsChoice(props: AddKeywordsModalProps) {
  const {
    nameAnalysis,
    serpAnalysisId,
  } = props;

  const dispatch = useAppDispatch();
  const { addingKeywords } = useKeywords({ idAnalysis: serpAnalysisId || 0 });
  const { idNameProject } = useParams();
  const { id: idProject } = splitIdName(idNameProject);
  const { isLoading } = useAppSelector(
    (state) => state.projects,
  );
  const {
    isAdding: isKeywordsLoading,
    error: keywordsError,
  } = useAppSelector((state) => state.keywords);

  const currentUser = useCurrentUser();

  const project = useAppSelector((state) => (
    selectProjectById(state.projects, idProject)));

  const [hasCalledModif, setHasCalledModif] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [hasWrongSeparator, setHasWrongSeparator] = useState<boolean>(false);
  const [forceValidation, setForceValidation] = useState<boolean>(false);

  const handleSubmit = (values: AddKeywordsFormType) => {
    setForceValidation(true);
    if (hasWrongSeparator) {
      setHasCalledModif(true);
      return;
    }
    const scopeId = project?.scopes[0]?.id ?? null;
    addingKeywords(values, scopeId);
    setHasCalledModif(true);
  };

  const handleFixWrongSteps = () => {
    setForceValidation(false);
    setHasCalledModif(false);
    setHasWrongSeparator(false);
    setOpenModal(true);
  };

  useEffect(() => {
    if (hasCalledModif && !isKeywordsLoading && !keywordsError) {
      setOpenModal(false);
      setIsConfirmationOpen(true);
    }
  }, [hasCalledModif, isKeywordsLoading, keywordsError]);

  const formik: FormikProps<AddKeywordsFormType> = useFormik<AddKeywordsFormType>({
    initialValues,
    validationSchema: validationSchema(setHasWrongSeparator, forceValidation),
    onSubmit: handleSubmit,
  });

  const handleSuccessClose = () => {
    setIsConfirmationOpen(false);
    dispatch(resetDataviz());
  };

  return (
    <AddKeywordsModalsChoiceView
      nameAnalysis={nameAnalysis}
      isLoading={isLoading}
      formik={formik}
      openModal={openModal}
      isKeywordsLoading={isKeywordsLoading}
      keywordsError={keywordsError}
      setOpenModal={setOpenModal}
      setIsConfirmationOpen={setIsConfirmationOpen}
      handleSuccessClose={handleSuccessClose}
      isConfirmationOpen={isConfirmationOpen}
      handleFixWrongSteps={handleFixWrongSteps}
      hasWrongSeparator={hasWrongSeparator}
      disabledAddKeyword={currentUser?.isConsultant(project?.id ?? 0)}
    />
  );
}

export default AddKeywordsModalsChoice;
