import React from 'react';

import {
  Select, MenuItem, styled, Typography, SelectChangeEvent, InputLabel, FormControl,
} from '@mui/material';

import theme from 'common/theme';
import { DEVICE_TYPE, devicesList } from 'features/deviceType/CONSTANTS';

import DeviceListType from './types';

const PREFIX = 'SelectDeviceType';

const classes = {
  title: `${PREFIX}-title`,
  form: `${PREFIX}-form`,
  select: `${PREFIX}-select`,
};

const StyledForm = styled(
  FormControl,
  { shouldForwardProp: (prop) => prop !== 'open' },
)<{ width?: string; custom_margin?: string, height?: string, menuitemfontsize?: string }>(({
  width, custom_margin, height, menuitemfontsize,
}) => ({
  [`& .${classes.title}`]: {
    fontSize: theme.fontSize.xsmall,
    position: 'relative',
  },

  [`& .${classes.form}`]: {
    minWidth: 120,
  },

  [`& .${classes.select}`]: {
    height,
    width,
    margin: custom_margin,
    fontSize: menuitemfontsize,
  },
}));

type SelectDeviceTypeViewProps = {
  deviceSelected: string,
  handleDeviceChange:(e: SelectChangeEvent<string>) => void,
  devices?: DeviceListType;
  disableSelect?: boolean,
  variant?: 'standard' | 'outlined' | 'filled' | undefined,
  width?: string,
  height?: string,
  margin?: string,
  isInputLabel?: boolean,
  selectName?: string,
  uniqueText?: boolean,
  menuItemFontSize?: string,
  label?: string,
}

function SelectDeviceTypeView(props: SelectDeviceTypeViewProps) {
  const {
    deviceSelected,
    handleDeviceChange,
    devices = devicesList,
    disableSelect = false,
    variant = 'standard',
    width = '',
    height = '',
    margin = '',
    isInputLabel = false,
    selectName = 'deviceTypeId',
    uniqueText = false,
    menuItemFontSize = theme.fontSize.xsmall,
    label = 'Appareil',
  } = props;

  const devicesSorted = [...devices];
  devicesSorted.sort((a, b) => b.id - a.id);

  return (
    <StyledForm
      className={classes.form}
      width={width}
      custom_margin={margin}
      height={height}
      menuitemfontsize={menuItemFontSize}
    >
      {(!isInputLabel && (
        <Typography className={classes.title} id="select-device-label">
          {label}
        </Typography>
      )) || (
        <InputLabel id="select-device-label" size="small" data-testid="label">
          {label}
        </InputLabel>
      )}
      <Select
        labelId="select-device-label"
        variant={variant}
        value={deviceSelected}
        onChange={handleDeviceChange}
        size="small"
        label={label}
        className={classes.select}
        name={selectName}
        disabled={disableSelect}
        data-testid="select-device-drop-down"
      >
        {devicesSorted.map((device) => (
          <MenuItem key={device.id} value={device.id}>
            <Typography sx={{ fontSize: menuItemFontSize, textAlign: 'left' }}>
              {device.label}
              {uniqueText && device.id !== DEVICE_TYPE.DESKTOP_MOBILE && ' uniquement'}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </StyledForm>
  );
}

export default SelectDeviceTypeView;
