import React, { useState } from 'react';

import {
  Box,
  Typography,
  MenuItem,
} from '@mui/material';
import { FormikHelpers, FormikProps, useFormik } from 'formik';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';

import {
  CreateModifyExternalUserForm,
  initValuesModify,
  validationSchemaModify,
} from './createModifyExternalUserConfig';
import CreateModifyExternalUserModal from './CreateModifyForm';
import { updateUser } from '../state/slice';
import { UserType } from '../types';

type ModifyExternalUserProps = {
  user: UserType,
  handleCloseMenu: Function,
}

function ModifyExternalUser(props: ModifyExternalUserProps) {
  const {
    user,
    handleCloseMenu,

  } = props;
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSubmit = (
    values: CreateModifyExternalUserForm,
    formikHelpers: FormikHelpers<CreateModifyExternalUserForm>,
  ) => {
    dispatch(updateUser({
      id: user.id,
      user: values,
    }));
    setIsOpen(false);
    formikHelpers.resetForm();
  };

  const formik: FormikProps<CreateModifyExternalUserForm> = useFormik<CreateModifyExternalUserForm>(
    {
      initialValues: initValuesModify(user),
      validationSchema: validationSchemaModify,
      onSubmit: handleSubmit,
      enableReinitialize: true,
    },
  );

  return (
    <Box>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => {
            setIsOpen(true);
            handleCloseMenu();
          }
        }
      >
        Modifier les identifiants
      </MenuItem>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">
              Modifier
            </Typography>
            {' '}
            les identifiants
          </Typography>
        )}
        actionConfirm={formik.handleSubmit}
        actionCancel={() => {
          setIsOpen(false);
          formik.resetForm();
        }}
        isOpen={isOpen}
        dataTestId="modify-external-user-modal"
      >
        <CreateModifyExternalUserModal
          formik={formik}
          isModif
        />
      </Modal>
    </Box>
  );
}

export default ModifyExternalUser;
