import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Button,
  Menu,
  Box,
  styled,
  BoxProps,
} from '@mui/material';

import CategoryValue from 'features/categoryGroups/classes/CategoryValue';

import DeleteCategoryValueAction from './DeleteCategoryValueAction';
import EditCategoryValueAction from './EditCategoryValueAction';

const PREFIX = 'DataFileDatagridActions';

const classes = {
  dropdown: `${PREFIX}-dropdown`,
  button: `${PREFIX}-button`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.dropdown}`]: {
    fontSize: theme.fontSize.xlarge,
  },

  [`& .${classes.button}`]: {
    color: '#737476',
    fontSize: theme.fontSize.medium,
    height: '30px',
    width: '115px',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      color: '#737476',
      backgroundColor: '#e8e8e8',
    },
  },
}));

type DataGridActionsProps = {
  categoryValue: CategoryValue,
  onEditRowValueSelected: (id: number) => void,
  categoryGroupType: number,
}

function DataGridActions(
  props: DataGridActionsProps,
): JSX.Element {
  const {
    categoryValue,
    onEditRowValueSelected,
    categoryGroupType,
  } = props;
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const openMenu = Boolean(anchorMenu);

  const handleOpenMenu = (event: any) => {
    setAnchorMenu(event.target);
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <StyledBox>
      <Button
        variant="contained"
        disableElevation
        onClick={handleOpenMenu}
        endIcon={<KeyboardArrowDownIcon className={classes.dropdown} />}
        size="small"
        className={classes.button}
        color="info"
        data-testid="category-values-data-grid-actions-button"
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        keepMounted
        data-testid="category-values-data-grid-actions-menu"
      >
        <EditCategoryValueAction
          categoryValue={categoryValue}
          handleCloseMenu={handleCloseMenu}
          onEditRowValueSelected={onEditRowValueSelected}
        />
        <DeleteCategoryValueAction
          categoryValueId={categoryValue.catValId}
          handleCloseMenu={handleCloseMenu}
          categoryGroupType={categoryGroupType}
        />
      </Menu>
    </StyledBox>
  );
}

export default DataGridActions;
