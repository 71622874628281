/* eslint-disable no-nested-ternary */
export const API_BASE_ADDRESS = import.meta.env.VITE_APP_BASE_ADDRESS
  ? import.meta.env.VITE_APP_BASE_ADDRESS
  : 'http://localhost/api/v1/';

export const ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT
  ? import.meta.env.VITE_APP_ENVIRONMENT
  : 'development';

// API Key for Google 0Auth
export const GOOGLE_CLIENT_ID = (
  ENVIRONMENT === 'production'
    ? '332834095673-ilpv36fs6uslcsqt5epacf4tset8djhm.apps.googleusercontent.com' // production
    : (
      (ENVIRONMENT === 'recette')
        ? '600086214070-73v78bmch9r4uv182l19c8eq9t12icth.apps.googleusercontent.com' // recette
        : '600086214070-jkge6d2mj2hd2ubegbn2rqo2k4hkskj5.apps.googleusercontent.com' // dev
    )
);
// Bucket name from Cloud storage
export const BUCKET_EXPORT_KPI = (
  ENVIRONMENT === 'production' ? 'agence-wam-prod-export-kpi' // production
    : (
      (ENVIRONMENT === 'recette')
        ? 'agence-wam-recette-export-kpi' // recette
        : 'agence-wam-dev-export-kpi' // dev
    )
);
