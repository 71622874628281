import React from 'react';

import { Typography } from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';

export default function Profile(): JSX.Element {
  useDocumentTitle('benhur | profil WAM');
  return (
    <Typography component="h1">Profile Page</Typography>
  );
}
