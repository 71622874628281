import React from 'react';

import { MenuItem } from '@mui/material';

import globalTheme from 'common/theme';
import CategoryValue from 'features/categoryGroups/classes/CategoryValue';

type UpdateCategoryValueActionProps = {
  categoryValue: CategoryValue,
  handleCloseMenu: Function,
  onEditRowValueSelected: (id: number) => void,
}

function EditCategoryValueAction(props: UpdateCategoryValueActionProps) {
  const {
    categoryValue,
    handleCloseMenu,
    onEditRowValueSelected,
  } = props;

  return (
    <MenuItem
      sx={{ fontSize: globalTheme.fontSize.middlesmall }}
      onClick={() => { onEditRowValueSelected(categoryValue.catValId); handleCloseMenu(); }}
      data-testid="edit-category-value-menu-item"
    >
      Modifier
    </MenuItem>
  );
}

export default EditCategoryValueAction;
