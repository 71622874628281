import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { useAppSelector } from 'common/reduxHooks';

import canUserAcessRoute from './utils';

type PrivateRouteProps = {
  element: JSX.Element,
}

function PrivateRoute({ element }: PrivateRouteProps): JSX.Element {
  const {
    isLoggedIn,
    currentUser,
    isLoading,
    refreshTokenLoading,
  } = useAppSelector(
    (state) => state.authentication,
  );

  const location = useLocation();

  const redirectAuthentication = () => {
    if (!isLoggedIn) {
      return <Navigate to="/" />;
    }

    if (!currentUser) {
      return <Box data-testid="private-route-loading" />;
    }

    if (canUserAcessRoute(currentUser, location.pathname)) {
      return element;
    }

    return <Navigate to="/" />;
  };

  return (
    <Box>
      {!isLoading && !refreshTokenLoading && redirectAuthentication()}
    </Box>
  );
}

export default PrivateRoute;
