import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import ApiUsers from './api';
import {
  fetchUsers,
  fetchSuccess,
  fetchFailure,
  createUser,
  createUserSuccess,
  createUserFailure,
  updateUser,
  updateSuccess,
  updateFailure,
  updateUserProjects,
  updateUserProjectsSuccess,
  updateUserProjectsFailure,
  deleteUser,
  deleteUserSuccess,
  deleteUserFailure,
} from './slice';
import {
  UserUpdatePayload,
  UsersFetchPayload,
  UserDeletePayload,
  UserUpdateProjectsPayload,
  UserCreatePayload,
} from '../types';

export default function* workUsersFetch(action: PayloadAction<UsersFetchPayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiUsers.fetchUsers,
      action.payload,
    );
    const users = response.data;
    yield put(fetchSuccess(users));
  } catch (e:any) {
    yield put(fetchFailure(e.message));
  }
}

export function* workUserCreate(action: PayloadAction<UserCreatePayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiUsers.createUser,
      action.payload,
    );
    yield put(createUserSuccess(response.data));
  } catch (e: any) {
    yield put(createUserFailure(e.message));
  }
}

export function* workUserUpdate(action: PayloadAction<UserUpdatePayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiUsers.updateUser,
      action.payload,
    );
    yield put(updateSuccess(response.data));
  } catch (e: any) {
    yield put(updateFailure(e.message));
  }
}

export function* workUserUpdateProjects(action: PayloadAction<UserUpdateProjectsPayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiUsers.updateUserprojects,
      action.payload,
    );
    yield put(updateUserProjectsSuccess(response.data));
  } catch (e: any) {
    yield put(updateUserProjectsFailure(e.message));
  }
}

export function* workUserDelete(action: PayloadAction<UserDeletePayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiUsers.deleteUser,
      action.payload,
    );
    yield put(deleteUserSuccess(response.data));
  } catch (e: any) {
    yield put(deleteUserFailure(e.message));
  }
}

export function* sagaFetchUsers() {
  yield takeEvery(fetchUsers, workUsersFetch);
}

export function* sagaCreateUser() {
  yield takeEvery(createUser.type, workUserCreate);
}

export function* sagaUpdateUser() {
  yield takeEvery(updateUser, workUserUpdate);
}

export function* sagaUpdateUserProjects() {
  yield takeEvery(updateUserProjects.type, workUserUpdateProjects);
}

export function* sagaDeleteUser() {
  yield takeEvery(deleteUser.type, workUserDelete);
}
