import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  BoxProps,
  styled,
  Typography,
  CircularProgress,
  Button,
} from '@mui/material';

type LoadingOverlayProps = {
  isImportingKeywords: boolean,
}

const PREFIX = 'LoadingOverlayDGSA';

const classes = {
  loading: `${PREFIX}-loading`,
  loadingText: `${PREFIX}-loadingText`,
  loadingSpinner: `${PREFIX}-loadingSpinner`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.loading}`]: {
    height: '100%',
    margin: '0 auto',
    padding: '15px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  [`& .${classes.loadingText}`]: {
    color: theme.palette.info.dark,
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.loadingSpinner}`]: {
    color: theme.palette.primary.light,
  },
}));

export default function LoadingOverlay(props: LoadingOverlayProps): JSX.Element {
  const {
    isImportingKeywords,
  } = props;

  const navigate = useNavigate();

  return (
    <StyledBox className={classes.loading}>
      {
        isImportingKeywords && (
          <Typography className={classes.loadingText}>
            Une étude de mots clés est en cours d&apos;import...
            <br />
            Merci de patienter jusqu&apos;à la fin du traitement
          </Typography>
        )
      }
      <CircularProgress size={20} className={classes.loadingSpinner} />
      {
        isImportingKeywords && (
          <Button
            size="small"
            variant="contained"
            onClick={() => navigate(0)}
          >
            Actualiser
          </Button>
        )
      }
    </StyledBox>
  );
}
