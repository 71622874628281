import React from 'react';

import { Avatar } from '@mui/material';
import * as Flags from 'country-flag-icons/react/3x2';

import { SerpAnalysisIndex } from '../types';

interface CountryFlagProps {
  index?: SerpAnalysisIndex,
  height: number,
  width: number,
}

export default function CountryFlag(props: CountryFlagProps): JSX.Element {
  const { index, height, width } = props;
  const Flag = index ? Flags[index.country_code as keyof typeof Flags] : Flags.FR;

  return (
    <Avatar sx={{ width, height }}>
      <Flag
        width={width + (width / 4)}
        height={height + (height / 4)}
        title={index?.country || 'France'}
        style={{
          position: 'absolute',
        }}
      />

    </Avatar>
  );
}
