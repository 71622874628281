/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DataFileDownloadPayload,
  DataFilesDeletePayload,
  DataFilesFetchPayload,
  DataFileType,
  DataFileUpdatePayload,
  DataFileUploadPayload,
} from '../types';

export interface State {
  isLoading: boolean;
  dataFiles: DataFileType[];
  error: string;
  isSuccess: boolean;
}

const initialState: State = {
  isLoading: false,
  dataFiles: [],
  error: '',
  isSuccess: false,
};

export const dataFilesSlice = createSlice({
  name: 'dataFiles',
  initialState,
  reducers: {
    fetchDataFiles: (state: State, action: PayloadAction<DataFilesFetchPayload>) => {
      state.isLoading = true;
    },
    fetchDataFilesSuccess: (state: State, action: PayloadAction<DataFileType[]>) => {
      state.dataFiles = action.payload;
      state.isLoading = false;
    },
    fetchDataFilesFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateDataFiles: (state: State, action: PayloadAction<DataFileUpdatePayload>) => {
      state.isLoading = true;
    },
    updateDataFilesSuccess: (state: State, action: PayloadAction<DataFileType[]>) => {
      const updatedDataFiles = action.payload;
      state.dataFiles = state.dataFiles.filter(
        (df) => !updatedDataFiles.find((newDf) => newDf.id === df.id),
      );
      state.dataFiles.push(...updatedDataFiles);
      state.isLoading = false;
    },
    updateDataFilesFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteDataFiles: (state: State, action: PayloadAction<DataFilesDeletePayload>) => {
      state.isLoading = true;
    },
    deleteDataFilesSuccess: (state: State, action: PayloadAction<DataFileType[]>) => {
      const deletedDataFilesId = action.payload.map((dataFile) => dataFile.id);

      // Create a new DataFiles with only remaning non deleted DataFiles
      const newDataFiles = state.dataFiles.map(
        (dataFile) => (deletedDataFilesId.includes(dataFile.id) ? null : dataFile),
      ).filter((value): value is DataFileType => value !== null);

      state.dataFiles = newDataFiles;
      state.isLoading = false;
    },
    deleteDataFilesFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    uploadDataFiles: (state: State, action: PayloadAction<DataFileUploadPayload>) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    uploadDataFilesSuccess: (state: State, action: PayloadAction<DataFileType[]>) => {
      state.dataFiles.push(...action.payload);
      state.isLoading = false;
      state.isSuccess = true;
    },
    uploadDataFilesFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isSuccess = false;
    },
    downloadDataFile: (state: State, action: PayloadAction<DataFileDownloadPayload>) => {
      state.isLoading = true;
    },
    downloadDataFileSuccess: (state: State) => {
      state.isLoading = false;
    },
    downloadDataFileFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetIsSuccess: (state: State) => {
      state.isSuccess = false;
    },
    resetError: (state: State) => {
      state.isSuccess = false;
      state.error = '';
    },
  },
});

export const selectDataFilesByScopeId = createSelector(
  (state: State) => state.dataFiles,
  (_: any, scopeId: number) => scopeId,
  (dataFiles, scopeId) => dataFiles.filter((df) => df.scope_id === scopeId),
);

export const selectDataFilesByScopeIdAndDataSourceId = createSelector(
  (state: State) => state.dataFiles,
  (_: any, scopeId: number) => scopeId,
  (_: any, scopeId: number, dataSourceId: number) => dataSourceId,
  (dataFiles, scopeId, dataSourceId) => dataFiles.filter((df) => (
    df.scope_id === scopeId
    && df.data_source_id === dataSourceId
  )),
);

export const selectDataFilesByScopeIdAndActorId = createSelector(
  (state: State) => state.dataFiles,
  (_: any, scopeId: number) => scopeId,
  (_: any, scopeId: number, actorId: number) => actorId,
  (dataFiles, scopeId, actorId) => dataFiles.filter((df) => (
    df.scope_id === scopeId
    && df.actor_id === actorId
  )),
);

export const {
  fetchDataFiles,
  fetchDataFilesSuccess,
  fetchDataFilesFailure,
  deleteDataFiles,
  deleteDataFilesSuccess,
  deleteDataFilesFailure,
  updateDataFiles,
  updateDataFilesSuccess,
  updateDataFilesFailure,
  uploadDataFiles,
  uploadDataFilesSuccess,
  uploadDataFilesFailure,
  downloadDataFile,
  downloadDataFileSuccess,
  downloadDataFileFailure,
  resetIsSuccess,
  resetError,
} = dataFilesSlice.actions;

export default dataFilesSlice.reducer;
