import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import ApiCategoryGroups from './api';
import {
  fetchCategoryGroups,
  fetchCategoryGroupsSuccess,
  fetchCategoryGroupsFailure,
  updateCategoryGroup,
  updateCategoryGroupSuccess,
  updateCategoryGroupFailure,
  addCategoryGroup,
  addCategoryGroupSuccess,
  addCategoryGroupFailure,
  deleteCategoryGroups,
  deleteCategoryGroupsSuccess,
  deleteCategoryGroupsFailure,
  updateCategoryValueSuccess,
  updateCategoryValueFailure,
  updateCategoryValue,
  deleteCategoryValues,
  deleteCategoryValuesSuccess,
  deleteCategoryValuesFailure,
  deleteCategorySuccess,
  createCategoryWithValues,
  createCategoryWithValuesSuccess,
  createCategoryWithValuesFailure,
  addCategoryValues,
  addCategoryValuesSuccess,
  addCategoryValuesFailure,
  updateCategoryFailure,
  updateCategorySuccess,
  updateCategory,
  updateCategoryValueMultiple,
  updateCategoryValueMultipleSuccess,
  updateCategoryValueMultipleFailure,
} from './slice';
import {
  CategoryCreateWithCategoryValues,
  CategoryGroupsFetchPayload,
  CategoryGroupUpdatePayload,
  CategoryValue,
  CategoryValueUpdatedPayload,
  CategoryValueUpdatePayload,
  CategoryValuesCreatePayload,
  CategoryValuesDeletePayload,
  CategoryGroupCreatePayload,
  Category,
  CategoryUpdatePayload,
  CategoryValueUpdateMultiplePayload,
  CategoryValueUpdatedMultiplePayload,
} from '../types';

export function* workFetchCategoryGroups(action: PayloadAction<CategoryGroupsFetchPayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiCategoryGroups.fetchCategoryGroups,
      action.payload.scopeId,
    );

    yield put(fetchCategoryGroupsSuccess(response.data));
  } catch (e: any) {
    yield put(fetchCategoryGroupsFailure(e.message));
  }
}

export function* workUpdateCategoryGroup(
  action: PayloadAction<CategoryGroupUpdatePayload>,
) {
  try {
    const response: AxiosResponse = yield call(
      ApiCategoryGroups.updateCategoryGroup,
      action.payload.id,
      action.payload.category_group,
    );

    yield put(updateCategoryGroupSuccess(response.data));
  } catch (e: any) {
    yield put(updateCategoryGroupFailure(e.message));
  }
}

export function* workAddCategoryGroup(action: PayloadAction<CategoryGroupCreatePayload>) {
  try {
    const response: AxiosResponse = yield call(
      action.payload.withCategories
        ? ApiCategoryGroups.addCategoryGroupWithCategories
        : ApiCategoryGroups.addCategoryGroup,
      action.payload.value,
    );
    const { data } = response;
    yield put(addCategoryGroupSuccess(data));
  } catch (e: any) {
    yield put(addCategoryGroupFailure(e.message));
  }
}

export function* workDeleteCategoryGroups(action: PayloadAction<number[]>) {
  try {
    const doCall = () => Promise.all(action.payload.map(
      (id: number) => ApiCategoryGroups.deleteCategoryGroup({ groupId: id }),
    ));
    const responses: AxiosResponse[] = yield call(doCall);

    const deletedDataFiles = responses.map((response) => response.data);

    yield put(deleteCategoryGroupsSuccess(deletedDataFiles));
  } catch (e: any) {
    yield put(deleteCategoryGroupsFailure(e.message));
  }
}

export function* workUpdateCategory(action: PayloadAction<CategoryUpdatePayload>) {
  try {
    const response: AxiosResponse<Category> = yield call(
      ApiCategoryGroups.updateCategory,
      action.payload.category_id,
      action.payload.category,
    );

    const data = {
      category_group_id: response.data.category_group_id,
      category: response.data,
    };

    yield put(updateCategorySuccess(data));
  } catch (e: any) {
    yield put(updateCategoryFailure(e.message));
  }
}

export function* workUpdateCategoryValue(action: PayloadAction<CategoryValueUpdatePayload>) {
  try {
    const response: AxiosResponse<CategoryValue> = yield call(
      ApiCategoryGroups.updateCategoryValue,
      action.payload.id,
      action.payload.category_value,
    );

    const data: CategoryValueUpdatedPayload = {
      category_group_id: action.payload.category_group_id,
      category_id: action.payload.category_id,
      category_value: response.data,
    };

    yield put(updateCategoryValueSuccess(data));
  } catch (e: any) {
    yield put(updateCategoryValueFailure(e.message));
  }
}

export function* workDeleteCategoryValues(action: PayloadAction<CategoryValuesDeletePayload>) {
  try {
    const deletedCategoryValuesResponse: AxiosResponse = yield call(
      ApiCategoryGroups.deleteCategoryValues,
      action.payload.category_value_ids,
    );
    const { data: deletedCategoryValues } = deletedCategoryValuesResponse;
    yield put(deleteCategoryValuesSuccess(deletedCategoryValues));

    if (action.payload.category_to_delete && action.payload.category_to_delete.length > 0) {
      const doCall = () => Promise.all(action.payload.category_to_delete!.map(
        (id: number) => ApiCategoryGroups.deleteCategory({ categoryId: id }),
      ));

      const deletedCategoriesResponses: AxiosResponse<Category>[] = yield call(doCall);
      const deletedCategories = deletedCategoriesResponses.map((response) => response.data);

      yield put(deleteCategorySuccess(deletedCategories));
    }
  } catch (e: any) {
    yield put(deleteCategoryValuesFailure(e.message));
  }
}

export function* workCreateCategoryWithValues(
  action: PayloadAction<CategoryCreateWithCategoryValues>,
) {
  try {
    const response: AxiosResponse = yield call(
      ApiCategoryGroups.createCategoryWithValues,
      action.payload,
    );
    const { data } = response;
    yield put(createCategoryWithValuesSuccess(data));
  } catch (e: any) {
    yield put(createCategoryWithValuesFailure(e.message));
  }
}

export function* workCreateCategoryValues(action: PayloadAction<CategoryValuesCreatePayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiCategoryGroups.createMultipleCategoryValue,
      action.payload.values,
    );
    const { data } = response;
    // get groupId and reformat payload
    const payload = {
      values: data,
      groupId: action.payload.groupId,
    };
    yield put(addCategoryValuesSuccess(payload));
  } catch (e: any) {
    yield put(addCategoryValuesFailure(e.message));
  }
}

export function* workUpdateCategoryValuesMultiple(
  action: PayloadAction<CategoryValueUpdateMultiplePayload>,
) {
  try {
    const response: AxiosResponse<CategoryValue[]> = yield call(
      ApiCategoryGroups.updateCategoryValuesMultiple,
      action.payload.category_values,
    );

    const data: CategoryValueUpdatedMultiplePayload = {
      category_group_id: action.payload.category_group_id,
      category_ids: action.payload.category_ids,
      category_values: response.data,
    };

    yield put(updateCategoryValueMultipleSuccess(data));
  } catch (e: any) {
    yield put(updateCategoryValueMultipleFailure(e.message));
  }
}

function* sagaFetchCategoryGroups() {
  yield takeEvery(
    fetchCategoryGroups.type,
    workFetchCategoryGroups,
  );
}

function* sagaUpdateCategoryGroup() {
  yield takeEvery(
    updateCategoryGroup,
    workUpdateCategoryGroup,
  );
}

function* sagaAddCategoryGroup() {
  yield takeEvery(
    addCategoryGroup.type,
    workAddCategoryGroup,
  );
}

function* sagaDeleteCategoryGroups() {
  yield takeEvery(
    deleteCategoryGroups,
    workDeleteCategoryGroups,
  );
}

function* sagaUpdateCategory() {
  yield takeEvery(
    updateCategory,
    workUpdateCategory,
  );
}

function* sagaUpdateCategoryValue() {
  yield takeEvery(
    updateCategoryValue,
    workUpdateCategoryValue,
  );
}

function* sagaDeleteCategoryValues() {
  yield takeEvery(
    deleteCategoryValues,
    workDeleteCategoryValues,
  );
}

function* sagaCreateCategoryWithValues() {
  yield takeEvery(
    createCategoryWithValues,
    workCreateCategoryWithValues,
  );
}

function* sagaCreateCategoryValues() {
  yield takeEvery(
    addCategoryValues.type,
    workCreateCategoryValues,
  );
}

function* sagaUpdateCategoryValueMultiple() {
  yield takeEvery(
    updateCategoryValueMultiple,
    workUpdateCategoryValuesMultiple,
  );
}

export {
  sagaFetchCategoryGroups,
  sagaUpdateCategoryGroup,
  sagaAddCategoryGroup,
  sagaDeleteCategoryGroups,
  sagaUpdateCategory,
  sagaUpdateCategoryValue,
  sagaDeleteCategoryValues,
  sagaCreateCategoryWithValues,
  sagaCreateCategoryValues,
  sagaUpdateCategoryValueMultiple,
};
