import React from 'react';

import {
  Box,
  Stack,
  StackProps,
  styled,
  Typography,
} from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import globalTheme from 'common/theme';
import Spinner from 'components/loading/Spinner';
import { DataInfos } from 'features/dataviz/types';
import formatThousands from 'utils/formatThousands';
import getTextContrast from 'utils/getTextContrast';

import TitleComponent from './common/Title';
import TooltipComponent from './common/Tooltip';
import useChartOpacity from './useChartOpacity';
import ChartErrorHandler from '../ChartErrorHandler';

const PREFIX = 'BarChart';

const classes = {
  root: `${PREFIX}-root`,
  typography: `${PREFIX}-typography`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    height: '100%',
    padding: '20px',
  },
  [`& .${classes.typography}`]: {
    fontSize: theme.fontSize.xsmall,
  },
}));

export interface BarChartComponentProps {
  datas: any[],
  title: string | JSX.Element,
  link?: string,
  dataKeys: any[],
  size?: string,
  showLegend?: boolean,
  setBarColor?: Function,
  width?: string | undefined,
  tooltipLabel?: string,
  yAxisDatakey?: string,
  isPageLoading?: boolean | undefined,
  hideAxis?: boolean | undefined,
  topLabelName?: string | undefined,
  tickMargin?: number | undefined,
  handleClick?: Function | undefined,
  isAxisLabelBold?: boolean | undefined,
  withTooltip?: boolean,
  hideXAxisLabel?: boolean,
  tooltipContent?: JSX.Element,
  isLoading?: boolean | undefined,
  dataObject?: DataInfos,
  isChartWithoutDatas?: Function,
  serpAnalysisStatus?: number | undefined,
  radioFilter?: JSX.Element | undefined,
  xAxisInterval?: 'preserveStart' | 'preserveEnd'
  | 'preserveStartEnd' | 'equidistantPreserveStart' | number | undefined,
  showCursor?: boolean,
  yAxisLabel?: string,
  containerAspect?: number | undefined,
  centerLabelName?: boolean | undefined
  percentLabel?: boolean | undefined,
  truncateTickLabel?: boolean,
  isAnimationActive?: boolean,
}

function BarChartVerticalComponent(props: BarChartComponentProps) {
  const {
    datas,
    title,
    link,
    dataKeys,
    size = '',
    showLegend = false,
    setBarColor,
    width,
    tooltipLabel = '',
    yAxisDatakey = 'name',
    isPageLoading = false,
    hideAxis = false,
    topLabelName,
    tickMargin = 25,
    handleClick,
    isAxisLabelBold,
    withTooltip = false,
    hideXAxisLabel = false,
    tooltipContent,
    isLoading = false,
    xAxisInterval = 0,
    dataObject,
    isChartWithoutDatas,
    serpAnalysisStatus = 0,
    radioFilter,
    showCursor = false,
    yAxisLabel = '',
    containerAspect,
    centerLabelName,
    percentLabel,
    truncateTickLabel = true,
    isAnimationActive = true,
  } = props;

  const {
    handleMouseEnter,
    handleMouseLeave,
  } = useChartOpacity(dataKeys);

  const determineWidth = () => {
    if (width) return width;
    if (size === 'big') return '100%';
    if (size === 'small') return '80%';
    return '100%';
  };

  const formatLegendText = (value: string) => (<Typography variant="body3">{value}</Typography>);

  if (!datas.length) {
    return (
      <ChartErrorHandler
        title={title}
        chartData={dataObject}
        serpAnalysisStatus={serpAnalysisStatus}
        isChartWithoutDatas={isChartWithoutDatas}
        isPageLoading={isPageLoading}
      />
    );
  }

  if (isPageLoading || isLoading) {
    return (
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <Spinner />
      </Box>
    );
  }

  return (
    <StyledStack
      alignItems="center"
      justifyContent="center"
      className={classes.root}
      sx={{ width: determineWidth(), height: '100%' }}
    >
      <Box sx={{ marginBottom: '.5rem' }}>
        <TitleComponent title={title} link={link} />
      </Box>
      <ResponsiveContainer
        aspect={containerAspect}
      >
        <BarChart
          width={500}
          height={500}
          data={datas}
          onClick={
            handleClick
              ? ((e) => handleClick(e, e?.activeLabel))
              : undefined
          }
          layout="vertical"
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            allowDecimals={false}
            hide={hideAxis}
            fontSize={globalTheme.fontSize.xsmall}
            tickFormatter={
              (tick) => Intl.NumberFormat('fr', {
                notation: 'compact',
                compactDisplay: 'short',
              }).format(tick)
            }
            label={{
              value: yAxisLabel,
              angle: -90,
              position: 'insideLeft',
              style: {
                fontSize: globalTheme.fontSize.xsmall,
              },
            }}
          />
          <YAxis
            type="category"
            dataKey={yAxisDatakey}
            fontSize={globalTheme.fontSize.medium}
            interval={xAxisInterval}
            minTickGap={1}
            tickMargin={tickMargin}
            tick={
              !hideXAxisLabel
              && {
                fontWeight: isAxisLabelBold ? 'bold' : 'normal',
                fill: handleClick ? globalTheme.palette.primary.light : 'inherit',
                width: 100,
              }
            }
            tickFormatter={(tick) => {
              if (tick.length <= 10 || !truncateTickLabel) {
                return tick;
              }
              return `${tick.slice(0, 10)}...`;
            }}
            onClick={handleClick && (
              (e: any) => handleClick(e, e?.value)
            )}
            style={{
              cursor: handleClick ? 'pointer' : '',
              fontSize: globalTheme.fontSize.xsmall,
            }}
          />
          {
            withTooltip
            && (
              <Tooltip
                // Tooltip is overlapped by the other graph if the height is too big
                wrapperStyle={{ zIndex: 2 }}
                cursor={showCursor}
                content={
                  tooltipContent
                    ? React.cloneElement(tooltipContent, { tooltipLabel })
                    : <TooltipComponent tooltipLabel={tooltipLabel} percentLabel={percentLabel} />
                }
              />
            )
          }
          {dataKeys.map((dataKey) => (
            <Bar
              dataKey={dataKey.dataKey}
              fill={dataKey.color}
              stackId={dataKey.stackId || null}
              isAnimationActive={isAnimationActive}
              key={`cell-${dataKey.dataKey}-${Math.random() * 10000}`}
            >
              {topLabelName && (
                <LabelList
                  dataKey={topLabelName}
                  position="top"
                  style={{
                    cursor: handleClick ? 'pointer' : '',
                    fontSize: globalTheme.fontSize.xsmall,
                  }}
                  fill={globalTheme.palette.info.main}
                  formatter={(value: number) => formatThousands(value)}
                />
              )}
              {centerLabelName && (
                <LabelList
                  dataKey={percentLabel
                    ? `${dataKey.dataKey}-percentage`
                    : dataKey.dataKey}
                  position="center"
                  style={{
                    fontSize: globalTheme.fontSize.xsmall,
                  }}
                  fill={getTextContrast(dataKey.color)}
                  formatter={
                    (value: number) => (percentLabel
                      ? (`${value}%`)
                      : formatThousands(value)
                    )
                  }
                />
              )}
              {datas.map((entry) => (
                !dataKey.color
                && (
                <Cell
                  fill={setBarColor && setBarColor(entry)}
                  key={`cell-${dataKey.dataKey}-${Math.random() * 10000}`}
                />
                )
              ))}
            </Bar>
          ))}
          {showLegend && (
          <Legend
            layout="horizontal"
            align="center"
            formatter={formatLegendText}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          )}
        </BarChart>
      </ResponsiveContainer>
      {
        radioFilter && React.cloneElement(radioFilter)
      }
    </StyledStack>
  );
}

export default BarChartVerticalComponent;
