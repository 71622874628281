import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Box,
  Typography,
  styled,
  BoxProps,
} from '@mui/material';
import moment from 'moment';

import { useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import { DATA_SOURCE } from 'features/dataFiles/CONSTANT';
import {
  METRICS,
  GRAPH_TYPES,
  DEFAULT_PERIODE,
  PERFORMANCE_QUERIES,
} from 'features/performance/CONSTANT';
import usePeriod from 'features/period/state/usePeriod';
import { RANK_SLICES } from 'features/rankSlice/CONSTANT';
import {
  DATE_ENGLISH,
  DATE_FULL_NAME_MONTH_YEAR,
  convertDate,
} from 'utils/formatDate';

import { getBarColor, getMissingActors } from './performanceByActorFormat';
import { ChartComponentProps } from '../../../types';
import { formatByActorsData, formatByActorsNotoriety } from '../../../utils/formatData';
import SeoPerformanceBarChart from '../SeoPerformanceBarChart';

const PREFIX = 'PerformanceByActors';

const classes = {
  allCaps: `${PREFIX}-allCaps`,
};

const StyledBox = styled(Box)<BoxProps>(() => ({
  [`& .${classes.allCaps}`]: {
    textTransform: 'uppercase',
  },
}));

function PerformanceByActors(props: ChartComponentProps) {
  const {
    datas,
    link,
    isPageLoading,
    isLoading,
    size,
    isChartWithoutDatas,
    datavizFilters,
    scopeId,
    handleExport,
    isExportLoading,
  } = props;

  const { periods, hasPeriods } = usePeriod(scopeId!, [DATA_SOURCE.semrush.id]);
  const [searchParams] = useSearchParams();
  const period = convertDate(
    moment(searchParams.get(PERFORMANCE_QUERIES.period) ?? (
      hasPeriods
        ? convertDate(moment(periods[0].period).toDate(), DATE_ENGLISH)
        : DEFAULT_PERIODE
    )).toDate(),
    DATE_FULL_NAME_MONTH_YEAR,
  );
  const actorIds = searchParams
    .getAll(PERFORMANCE_QUERIES.actorIds)
    .map((actorId) => Number(actorId));

  const performanceMetric: string = (datavizFilters?.type === 'rankingEvolutionFilters'
  && datavizFilters?.datavizMode) || METRICS[0];
  const rankSliceIdsFilter = (
    datavizFilters?.type === 'rankingEvolutionFilters'
      ? datavizFilters?.rankSliceIds
      : []
  );
  const generateDatakeys = () => {
    const dataKeys = [
      {
        id: RANK_SLICES[0].id,
        dataKey: RANK_SLICES[0].label,
        color: globalTheme.palette.dataviz[5],
        stackId: 1,
      },
      {
        id: RANK_SLICES[1].id,
        dataKey: RANK_SLICES[1].label,
        color: globalTheme.palette.dataviz[4],
        stackId: 1,
      },
      {
        id: RANK_SLICES[2].id,
        dataKey: RANK_SLICES[2].label,
        color: globalTheme.palette.dataviz[3],
        stackId: 1,
      },
      {
        id: RANK_SLICES[3].id,
        dataKey: RANK_SLICES[3].label,
        color: globalTheme.palette.dataviz[2],
        stackId: 1,
      },
    ].filter((keySlice) => rankSliceIdsFilter.find((id) => keySlice.id === id));
    return dataKeys;
  };

  const notorietyDataKeys = [
    {
      dataKey: 'Marque',
      color: globalTheme.palette.primary.light,
      stackId: 1,
    },
    {
      dataKey: 'Hors-Marque',
      color: globalTheme.palette.info.main,
      stackId: 1,
    },
  ];

  const actorsList = useAppSelector((state) => state.actors.actors);
  const setBarColor = (actorName: string) => getBarColor(actorName, actorsList);

  const actorsWithValues = datas.data.values?.map((value) => value.actor_name);
  const missingActors = getMissingActors(actorsList, actorIds, actorsWithValues);

  const rankList = RANK_SLICES
    .filter((slice) => rankSliceIdsFilter.includes(slice.id))
    .map((slice) => slice.label);

  const getFormattedData = (currentGraphType: keyof typeof GRAPH_TYPES) => (
    currentGraphType === GRAPH_TYPES.notoriety.label
      ? formatByActorsNotoriety(
        datas.data.values || [],
        performanceMetric,
      )
      : formatByActorsData(
        datas.data.values || [],
        performanceMetric,
        currentGraphType,
        rankList,
      )
  );

  return (
    <StyledBox>
      <SeoPerformanceBarChart
        keySubject="actor_name"
        datas={datas}
        missingValues={missingActors}
        formattedData={getFormattedData(GRAPH_TYPES.rank_all.label)}
        title={(
          <Typography variant="bold" className={classes.allCaps}>
            {
              GRAPH_TYPES.rank_all.titles[
                performanceMetric as keyof typeof GRAPH_TYPES.rank_all.titles
              ]
            }
            {' '}
            <Typography
              color={globalTheme.palette.primary.light}
              variant="bold"
              className={classes.allCaps}
            >
              {period}
            </Typography>
          </Typography>
        )}
        link={link}
        isPageLoading={isPageLoading}
        isLoading={isLoading}
        size={size}
        isChartWithoutDatas={isChartWithoutDatas}
        setBarColor={setBarColor}
        performanceMetric={performanceMetric}
        generateDatakeys={generateDatakeys}
        graphType={GRAPH_TYPES.rank_all.label}
        handleExport={handleExport}
        isExportLoading={isExportLoading}
      />
      <SeoPerformanceBarChart
        keySubject="actor_name"
        datas={datas}
        missingValues={missingActors}
        formattedData={getFormattedData(GRAPH_TYPES.rank_brand.label)}
        title={(
          <Typography variant="bold" className={classes.allCaps}>
            {
              GRAPH_TYPES.rank_brand.titles[
                performanceMetric as keyof typeof GRAPH_TYPES.rank_brand.titles
              ]
            }
            {' '}
            <Typography
              color={globalTheme.palette.primary.light}
              variant="bold"
              className={classes.allCaps}
            >
              {period}
            </Typography>
          </Typography>
        )}
        link={link}
        isPageLoading={isPageLoading}
        isLoading={isLoading}
        size={size}
        isChartWithoutDatas={isChartWithoutDatas}
        setBarColor={setBarColor}
        performanceMetric={performanceMetric}
        generateDatakeys={generateDatakeys}
        graphType={GRAPH_TYPES.rank_brand.label}
        handleExport={handleExport}
        isExportLoading={isExportLoading}
      />
      <SeoPerformanceBarChart
        keySubject="actor_name"
        datas={datas}
        missingValues={missingActors}
        formattedData={getFormattedData(GRAPH_TYPES.rank_not_brand.label)}
        title={(
          <Typography variant="bold" className={classes.allCaps}>
            {
              GRAPH_TYPES.rank_not_brand.titles[
                performanceMetric as keyof typeof GRAPH_TYPES.rank_not_brand.titles
              ]
            }
            {' '}
            <Typography
              color={globalTheme.palette.primary.light}
              variant="bold"
              className={classes.allCaps}
            >
              {period}
            </Typography>
          </Typography>
        )}
        link={link}
        isPageLoading={isPageLoading}
        isLoading={isLoading}
        size={size}
        isChartWithoutDatas={isChartWithoutDatas}
        setBarColor={setBarColor}
        performanceMetric={performanceMetric}
        generateDatakeys={generateDatakeys}
        graphType={GRAPH_TYPES.rank_not_brand.label}
        handleExport={handleExport}
        isExportLoading={isExportLoading}
      />
      <SeoPerformanceBarChart
        keySubject="actor_name"
        datas={datas}
        missingValues={missingActors}
        formattedData={getFormattedData(GRAPH_TYPES.notoriety.label)}
        title={(
          <Typography variant="bold" className={classes.allCaps}>
            {
              GRAPH_TYPES.notoriety.titles[
                performanceMetric as keyof typeof GRAPH_TYPES.notoriety.titles
              ]
            }
            {' '}
            <Typography
              color={globalTheme.palette.primary.light}
              variant="bold"
              className={classes.allCaps}
            >
              {period}
            </Typography>
          </Typography>
        )}
        link={link}
        isPageLoading={isPageLoading}
        isLoading={isLoading}
        size={size}
        isChartWithoutDatas={isChartWithoutDatas}
        setBarColor={setBarColor}
        performanceMetric={performanceMetric}
        generateDatakeys={() => notorietyDataKeys}
        graphType={GRAPH_TYPES.notoriety.label}
        handleExport={handleExport}
        isExportLoading={isExportLoading}
      />
    </StyledBox>
  );
}

export default PerformanceByActors;
