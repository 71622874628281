import React from 'react';
import { useParams } from 'react-router-dom';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CategoryIcon from '@mui/icons-material/Category';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import UploadIcon from '@mui/icons-material/Upload';
import { Typography, Box } from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import { useAppSelector } from 'common/reduxHooks';
import { selectProjectById } from 'features/projects/state/slice';
import SettingsView from 'features/settings/SettingsView';
import { Setting } from 'features/settings/types';
import { generateProjectBaseUrl, splitIdName } from 'utils/urlManipulation';

const settings: Setting[] = [
  {
    title: 'DÉFINIR LES ACTEURS',
    Icon: GroupsIcon,
    description: (
      <Typography component="span" variant="body2">
        Saisissez les
        {' '}
        <Typography component="span" variant="bold">
          noms de domaine
        </Typography>
        {' '}
        que vous désirez surveiller dans les différentes vues
      </Typography>
    ),
    buttonTitle: 'Définir',
    redirectUrl: (project) => (
      `${generateProjectBaseUrl(project!.id, project!.name)}setting/competitor`
    ),
    required: true,
  },
  {
    title: 'IMPORTER DES FICHIERS',
    Icon: UploadIcon,
    description: (
      <Typography component="span" variant="body2">
        Chargez les
        {' '}
        <Typography component="span" variant="bold">
          fichiers CSV
          {' '}
        </Typography>
        provenant des outils SEO pour obtenir des analyses avancées
      </Typography>
    ),
    buttonTitle: 'Importer',
    redirectUrl: (project) => (
      `${generateProjectBaseUrl(project!.id, project!.name)}setting/upload`
    ),
    required: false,
    externalBlocked: true,
  },
  {
    title: 'CATÉGORISER LES URL',
    Icon: CategoryIcon,
    description: (
      <Typography component="span" variant="body2">
        Créez
        {' '}
        <Typography component="span" variant="bold">
          un nombre illimité de groupes
          {' '}
        </Typography>
        pour tracker des URL stratégiques (arborescence, plan de publication, etc...)
      </Typography>
    ),
    buttonTitle: 'Catégoriser',
    redirectUrl: (project) => (
      `${generateProjectBaseUrl(project!.id, project!.name)}setting/url`
    ),
    required: false,
    externalBlocked: true,
  },
  {
    title: 'MESURER LA NOTORIÉTÉ',
    Icon: AutoAwesomeIcon,
    description: (
      <Typography component="span" variant="body2">
        Listez les
        {' '}
        <Typography component="span" variant="bold">
          mots clés de MARQUE
          {' '}
        </Typography>
        pour distinguer les types de requêtes dans les différentes vues
      </Typography>
    ),
    buttonTitle: 'Mesurer',
    redirectUrl: (project) => (
      `${generateProjectBaseUrl(project!.id, project!.name)}setting/brand`
    ),
    required: false,
    externalBlocked: true,
  },
  {
    title: 'ACTIVER DES DONNÉES',
    Icon: SettingsSuggestIcon,
    description: (
      <Typography component="span" variant="body2">
        Configurez
        {' '}
        <Typography component="span" variant="bold">
          l’analyse de SERP
          {' '}
        </Typography>
        pour activer des nouvelles fonctionnalités
      </Typography>
    ),
    buttonTitle: 'Activer',
    redirectUrl: (project) => (
      `${generateProjectBaseUrl(project!.id, project!.name)}setting/option`
    ),
    required: false,
    externalBlocked: true,
  },
];

export default function Settings(): JSX.Element {
  const { idNameProject } = useParams();
  const { id: idProject } = splitIdName(idNameProject);

  const project = useAppSelector((state) => (
    selectProjectById(state.projects, idProject)
  ));

  const currentUser = useAppSelector((state) => state.authentication.currentUser);

  useDocumentTitle(`Paramétrage du projet ${project?.name} | benhur`);

  return (
    <Box>
      <Typography variant="h1">
        Configurer le projet en fonction de vos besoins
      </Typography>
      <SettingsView
        project={project}
        settings={settings}
        currentUser={currentUser}
      />
    </Box>
  );
}
