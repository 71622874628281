import React, { useState } from 'react';

import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';

import globalTheme from 'common/theme';
import CheckBoxDataGrid from 'components/checkbox/CheckBoxDataGrid';
import LinearLoadingOverlay from 'components/loading/LinearLoadingOverlay';

import columns from './configHistoDatagrid';
import GlobalDeleteDataFile from '../actions/GlobalDeleteDataFile';
import DataFileFilterBar from '../components/DataFileFiltersBar';
import { DELETABLE_DATA_SOURCE_STATUS } from '../CONSTANT';
import { DataFileFilters, DataFileType } from '../types';
import filterDataFilesList from '../utils/filterDataFilesList';

type HistoDatagridProps = {
  dataFiles: DataFileType[],
  isLoading: boolean,
  scopeId: number,
};

function HistoDatagrid(props: HistoDatagridProps) {
  const { dataFiles, isLoading, scopeId } = props;
  const [idsSelected, setIdsSelected] = useState<number[]>([]);

  const emptyFilters: DataFileFilters = {
    actor_id: undefined,
    periode: undefined,
    data_source_id: undefined,
    data_file_status_id: undefined,
  };
  const [filters, setFilters] = useState<DataFileFilters>(emptyFilters);

  const handleFilters = (e: any) => {
    const { value, name } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetFilters = () => {
    setFilters(emptyFilters);
  };

  const handleSelect = (ids: GridRowSelectionModel) => {
    setIdsSelected(ids.map((id) => parseInt(`${id}`, 10)));
  };

  const filteredDataFiles = filterDataFilesList(filters, dataFiles);

  return (
    <Box>
      <Stack direction="row">
        <Stack
          direction="row"
          spacing={1}
          width="40%"
          height={35}
        >
          <Typography
            sx={{
              fontSize: globalTheme.fontSize.medium,
              fontWeight: '500',
            }}
          >
            LISTE DES FICHIERS TRAIT&Eacute;S
          </Typography>
        </Stack>
        {idsSelected.length !== 0 && (
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={1}
            width="60%"
          >
            <Box
              sx={{
                marginRight: '18px',
                fontSize: globalTheme.fontSize.middlesmall,
                fontWeight: '500',
              }}
            >
              {`${idsSelected.length} `}
              fichier
              {
                (idsSelected.length > 1
                  ? 's '
                  : ' '
                )
              }
              sélectionné
              {
                (idsSelected.length > 1
                  ? 's'
                  : ''
                )
              }
            </Box>
            <GlobalDeleteDataFile dataFileIds={idsSelected} />
          </Stack>
        )}
      </Stack>
      {
        dataFiles.length > 0 && (
          <DataFileFilterBar
            filters={filters}
            handleChange={handleFilters}
            scopeId={scopeId}
            resetFilters={resetFilters}
          />
        )
      }
      <DataGrid
        getRowId={(row) => row.id}
        loading={isLoading}
        density="compact"
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        disableColumnMenu
        autoHeight
        disableVirtualization
        checkboxSelection
        isRowSelectable={
          (params) => DELETABLE_DATA_SOURCE_STATUS.includes(params.row.data_file_status_id)
        }
        getRowHeight={() => 'auto'}
        columns={columns}
        rows={filteredDataFiles}
        slots={{
          loadingOverlay: LinearLoadingOverlay,
          baseCheckbox: CheckBoxDataGrid,
        }}
        localeText={{
          noRowsLabel: 'Aucun fichier trouvé',
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} sur ${count}`,
          },
        }}
        slotProps={{
          pagination: {
            labelRowsPerPage: ('Nombre par page :'),
          },
        }}
        sx={{ marginTop: '10px' }}
        onRowSelectionModelChange={(dataFileSelected) => {
          handleSelect(dataFileSelected);
        }}
        initialState={{
          sorting: { sortModel: [{ field: 'period', sort: 'desc' }] },
        }}
      />
    </Box>
  );
}

export default HistoDatagrid;
