import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  StackProps,
  Stack,
  styled,
  Typography,
  CircularProgress,
  Button,
} from '@mui/material';

import { GOOGLE_CLIENT_ID } from 'common/config';
import { useAppDispatch } from 'common/reduxHooks';
import CustomAlert from 'components/alert/CustomAlert';
import smallSpinnerSize from 'components/loading/config';
import { fetchLogin } from 'features/authentication/state/slice';
import { HOME, PROJECTS } from 'router/CONSTANTS';

type LoginGoogleFormProps = {
  isLoading: boolean,
  error: string,
  userEmail: string,
  onCancel: Function,
}

const PREFIX = 'LoginView';

const classes = {
  button: `${PREFIX}-button`,
  form: `${PREFIX}-form`,
  oauth: `${PREFIX}-oauth`,
  google: `${PREFIX}-google`,
  text: `${PREFIX}-text`,
  spinner: `${PREFIX}-spinner`,
  error: `${PREFIX}-error`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`& .${classes.button}`]: {
    fontSize: theme.fontSize.medium,
    height: '36px',
    width: '25%',
  },

  [`& .${classes.oauth}`]: {
    height: '20vh',
  },

  [`& .${classes.google}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.text}`]: {
    fontSize: theme.fontSize.medium,
  },

  [`&.${classes.spinner}`]: {
    color: theme.palette.primary.light,
  },

  [`& .${classes.error}`]: {
    padding: theme.spacing(1),
    color: theme.palette.error.main,
    fontSize: theme.fontSize.medium,
  },
}));

function LoginGoogleForm({
  isLoading,
  error,
  userEmail,
  onCancel,
}: LoginGoogleFormProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);

  const handleGoogleSignIn = useCallback(
    (res: any) => {
      if (!res.clientId || !res.credential) return;
      // Sends request to the API
      // Exchanges the google auth code against an authentication token
      dispatch(fetchLogin({
        payload: {
          authCode: res.credential,
        },
        callbackSuccessUrl: PROJECTS,
        callbackFailureUrl: HOME,
      }));
    },
    [fetchLogin],
  );

  useEffect(() => {
    if (gsiScriptLoaded) return;

    // const { google } = (window as any);

    const initializeGsi = () => {
      if (!window.google || gsiScriptLoaded) return;

      setGsiScriptLoaded(true);

      // Initialises the google login button, with the proper settings
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleGoogleSignIn,
        login_hint: userEmail,
      });

      window.google.accounts.id.renderButton(
        document.getElementById('g_id_signin'),
        { theme: 'outline', size: 'large' },
      );
    };

    // initialises the necessary script to run the google login process
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.onload = initializeGsi;
    script.async = true;
    script.id = 'google-client-script';
    document.querySelector('body')?.appendChild(script);

    const cleanup = () => {
      // Cleanup function of the script that runs when component unmounts
      document.getElementById('google-client-script')?.remove();
    };
    cleanup();
  }, [handleGoogleSignIn, gsiScriptLoaded]);

  return (
    <StyledStack
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.oauth}
      data-testid="login-google-form"
      spacing={2}
    >
      <Typography className={classes.text}>
        Connectez-vous avec votre compte Google Wam
      </Typography>
      { isLoading
        ? (
          <CircularProgress
            className={classes.spinner}
            size={smallSpinnerSize}
            data-testid="signin-spinner"
          />
        )
        : (
          <Box
            id="g_id_signin"
            data-testid="signin-button"
            style={{
              display: 'flex',
            }}
            className={classes.google}
          />
        )}
      {error && (
        <CustomAlert severity="error" fullWidth>
          <Typography className={classes.text}>
            {error}
          </Typography>
        </CustomAlert>
      )}
      <Button
        variant="contained"
        color="info"
        className={classes.button}
        onClick={() => onCancel()}
        disabled={isLoading}
        data-testid="login-google-form-cancel-button"
      >
        Annuler
      </Button>
    </StyledStack>
  );
}

export default LoginGoogleForm;
