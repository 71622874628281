import { CategoryTypeType } from '../type';

function isCategoryValueValueValid(
  value: string,
  categoryType: CategoryTypeType,
) {
  if (categoryType === 1) {
    return value.length > 0;
  }

  // Value must be a non empty string
  if (categoryType === 2) {
    return value.length > 0;
  }

  // Value must be a valid regex
  if (categoryType === 3) {
    try {
      RegExp(value);
      return true;
    } catch {
      return false;
    }
  }

  return false;
}

export default isCategoryValueValueValid;
