import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RankingTypeType } from '../types';

export interface State {
  isLoading: boolean;
  rankingTypes: RankingTypeType[];
  error: string;
}

const initialState: State = {
  isLoading: false,
  rankingTypes: [],
  error: '',
};

export const rankingTypeSlice = createSlice({
  name: 'rankingTypes',
  initialState,
  reducers: {
    fetchRankingTypes: (state: State) => {
      state.isLoading = true;
    },
    fetchRankingTypesSuccess: (state: State, action: PayloadAction<RankingTypeType[]>) => {
      state.rankingTypes = action.payload;
      state.isLoading = false;
    },
    fetchRankingTypesFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchRankingTypes,
  fetchRankingTypesSuccess,
  fetchRankingTypesFailure,
} = rankingTypeSlice.actions;

export default rankingTypeSlice.reducer;
