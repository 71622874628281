import React from 'react';

import {
  Box, BoxProps, Typography, styled,
} from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import CATEGORY_TYPE_ID from 'features/categoryGroups/CONSTANTS';
import { DATA_SOURCE } from 'features/dataFiles/CONSTANT';
import DatavizContainer from 'features/dataviz/components/generic/DatavizContainer';
import { METRIC } from 'features/dataviz/CONSTANTS';
import datavizConfig from 'features/dataviz/datavizConfig';
import AlertNoPeriodsOrCategoryGroups
  from 'features/performance/components/AlertNoPeriodsOrCategoryGroups';
import PerformanceFiltersBlock from 'features/performance/components/PerformanceFiltersBlock';
import withPerformanceFilters from 'features/performance/utils/withPerformanceFilters';
import { Project } from 'features/projects/types';
import {
  generatePerformanceUrl,
} from 'utils/urlManipulation';

const PREFIX = 'PerformancePerUrls';

const classes = {
  ctn: `${PREFIX}-ctn`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.ctn}`]: {
    marginTop: theme.spacing(2),
  },
}));

type PerformancePerUrlProps = {
  project?: Project,
};

function PerformancePerURL ({ project }: PerformancePerUrlProps) {
  const scopeId = project?.scopes[0].id ?? 0;
  const baseUrl = generatePerformanceUrl(
    project?.id ?? 0,
    project?.name ?? '',
    undefined,
    undefined,
    'by-url',
  );

  const page = 'url';

  const {
    performanceFilters,
    defaultFilters,
    handleFiltersUpdate,
    resetFilters,
    hasFirstLoadedFilters,
    isFullyLoaded,
  } = withPerformanceFilters(scopeId, page);

  useDocumentTitle(`${project?.name} : comparer les performances SEO | benhur`);

  return (
    <StyledBox>
      <Typography variant="h1">
        Comparez les performances SEO de vos types d&apos;URL
      </Typography>
      {isFullyLoaded && hasFirstLoadedFilters && (
        <>
          <AlertNoPeriodsOrCategoryGroups
            scopeId={scopeId}
            categoryTypeId={CATEGORY_TYPE_ID.url}
            project={project}
            dataSourceIds={[DATA_SOURCE.semrush.id]}
            doFetch={false}
          />
          <PerformanceFiltersBlock
            onFiltersChange={handleFiltersUpdate}
            defaultFilters={defaultFilters}
            resetFilters={resetFilters}
            baseUrl={baseUrl}
            scopeId={scopeId ?? 0}
            page={page}
          />
          <Box className={classes.ctn}>
            <DatavizContainer
              idScope={scopeId || 0}
              datavizComp={
                datavizConfig[METRIC.get_all_by_url_categories.label]
              }
              title=""
              datavizFilters={performanceFilters}
              size="medium"
              periode=""
              height="fit-content"
              project={project}
            />
          </Box>
        </>
      )}
    </StyledBox>
  );
}

export default PerformancePerURL;
