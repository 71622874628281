import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';

import MainBox from 'components/layout/MainBox';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';

import { PhysionomyFiltersForm } from '../types';

type PhysionomyFiltersProps = {
  values: PhysionomyFiltersForm,
  disabled?: boolean,
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FocusEvent<any>) => void,
}

const title = 'Informations';

function PhysionomyInformations(props: PhysionomyFiltersProps) {
  const {
    values,
    disabled,
    onChange,
    onBlur,
  } = props;

  return (
    <MainBox>
      <TitleComponent
        title={title}
        capitalLetter
        direction="column"
      />
      <Stack direction="column">
        <FormControlLabel
          control={<Checkbox />}
          label={(
            <Typography variant="body2">
              Afficher les pourcentages
            </Typography>
          )}
          name="showPercentage"
          checked={values.showPercentage}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          data-testid="physionomy-showPercentage-formcontrol"
        />
        <FormControlLabel
          control={<Checkbox />}
          label={(
            <Typography variant="body2">
              Afficher les valeurs
            </Typography>
          )}
          name="showValues"
          checked={values.showValues}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          data-testid="physionomy-showValues-formcontrol"
        />
      </Stack>
    </MainBox>
  );
}

export default PhysionomyInformations;
