import React from 'react';

import {
  Stack,
  styled,
  StackProps,
  SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import MainBox from 'components/layout/MainBox';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';
import { Project } from 'features/projects/types';

import BudgetChecker from './BudgetChecker';
import ConfigChecker from './ConfigChecker';

const PREFIX = 'SettingBlockView';

const classes = {
  icon: `${PREFIX}-icon`,
  iconSmall: `${PREFIX}-iconSmall`,
  description: `${PREFIX}-description`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`& .${classes.icon}`]: {
    fontSize: '40px',
    color: theme.palette.primary.light,
  },

  [`& .${classes.iconSmall}`]: {
    width: '20px',
    marginRight: '5px',
  },

  [`& .${classes.description}`]: {
    textAlign: 'center',
    fontSize: theme.fontSize.middlesmall,
    minHeight: '3rem',
  },
}));

type ConfigurationBlockProps = {
  title: string | JSX.Element,
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string; },
  blockHeight: number,
  description: string | JSX.Element,
  buttons: JSX.Element[],
  isConfigDone?: boolean,
  isBudget?: boolean,
  project?: Project | undefined,
}

function ConfigurationBlock (props: ConfigurationBlockProps) {
  const {
    title,
    Icon,
    blockHeight,
    description,
    buttons,
    isConfigDone = false,
    isBudget = false,
    project = undefined,
  } = props;

  return (
    <MainBox>
      <StyledStack
        alignItems="center"
        direction="column"
        justifyContent="space-between"
        spacing={2}
        height={blockHeight}
        data-testid="config-block-main-div"
      >
        <TitleComponent
          title={title}
          capitalLetter
        />
        <Stack
          display="flex"
          direction="column"
          alignItems="center"
          spacing={1}
        >
          <Icon className={classes.icon} />
          <Stack
            className={classes.description}
          >
            {description}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          width={1}
        >
          {
            isBudget && project
              ? (
                <BudgetChecker
                  projectBudget={parseFloat(project.project_budget)}
                  keywordPrice={parseFloat(project.keyword_price)}
                />
              )
              : (
                <ConfigChecker isConfigDone={isConfigDone} />
              )
          }
          <Stack
            direction="row"
            justifyContent="flex-end"
            width={320}
            gap={2}
          >
            {
              buttons.map((btn) => btn)
            }
          </Stack>
        </Stack>
      </StyledStack>
    </MainBox>
  );
}

export default ConfigurationBlock;
