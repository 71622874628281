import React from 'react';

import {
  Button,
  Stack,
  Typography,
  styled,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import MainBox from 'components/layout/MainBox';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';

import { PerformancePages } from '../types';

type SaveFiltersBoxProps = {
  onFilter: Function,
  onCancel: Function,
  isFilter: boolean,
  bestRankMode: boolean,
  aggregateMode: boolean,
  onChange: any,
  page: PerformancePages,
  showReset?: boolean,
}

const PREFIX = 'actor-performance-save-filter';

const classes = {
  cancelButton: `${PREFIX}-cancelButton`,
  filterButton: `${PREFIX}-filterButton`,
};

const StyledStack = styled(Stack)(({ theme }) => ({
  [`& .${classes.cancelButton}`]: {
    color: theme.palette.primary.light,
  },

  [`& .${classes.filterButton}`]: {
    backgroundColor: theme.palette.primary.light,
    color: 'white',
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

function SaveFiltersBox(props: SaveFiltersBoxProps) {
  const {
    onFilter,
    onCancel,
    isFilter,
    bestRankMode,
    aggregateMode,
    onChange,
    page,
    showReset = true,
  } = props;

  const description: { [key: string]: JSX.Element } = {
    actor: (
      <Typography component="span" variant="body3">
        Les performances SEO des différents acteurs sont mesurées à partir des fichiers
        {' '}
        <Typography component="span" variant="bold">
          Semrush
          {' '}
        </Typography>
        importés et des paramètres que vous avez définis.
      </Typography>
    ),
    url: (
      <Typography component="span" variant="body3">
        Les performances SEO des URL sont mesurées à partir des
        {' '}
        <Typography component="span" variant="bold">
          fichiers Semrush
          {' '}
        </Typography>
        importés et de la catégorisation des URL que vous avez effectuée.
      </Typography>
    ),
    serp: (
      <Typography component="span" variant="body3">
        Les performances SEO des différentes fonctionnalités SERP sont mesurées à partir des
        {' '}
        <Typography component="span" variant="bold">
          fichiers Semrush
          {' '}
        </Typography>
        importés et des paramètres que vous avez définis.
      </Typography>
    ),
    kpi: (
      <Typography component="span" variant="body3">
        <Typography
          component="span"
          variant="bold"
        >
          Attention, exportez uniquement les données nécessaires
          ou créez plusieurs exports.
          {' '}
        </Typography>
        Une volumétrie trop importante peut engendrer des problèmes.
      </Typography>
    ),
  };

  return (
    <MainBox BoxProps={{ height: '93%' }}>
      <StyledStack
        direction="column"
        justifyContent="space-between"
        spacing={1}
        height={1}
      >
        <TitleComponent
          title={page === 'kpi' ? 'à propos des exports' : 'à propos des données'}
          capitalLetter
          direction="column"
        />
        {description[page]}
        <Stack spacing={1}>
          <FormControlLabel
            sx={{ height: '20px' }}
            control={(
              <Checkbox
                checked={bestRankMode}
                onChange={onChange}
                name="bestRankMode"
              />
            )}
            label={(
              <Typography variant="body3">
                Afficher uniquement les meilleures positions
              </Typography>
            )}
          />
          {page === 'kpi' && (
            <FormControlLabel
              sx={{ height: 20 }}
              control={(
                <Checkbox
                  checked={aggregateMode}
                  onChange={onChange}
                  name="aggregateMode"
                />
              )}
              label={(
                <Typography variant="body3">
                  Agréger les données par acteurs
                </Typography>
              )}
            />
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
        >
          {isFilter && showReset && (
            <Button
              size="small"
              data-testid="save-filter-cancel"
              onClick={() => onCancel()}
              className={classes.cancelButton}
            >
              Réinitialiser
            </Button>
          )}

          <Button
            variant="contained"
            size="small"
            data-testid="save-filters-do-filter"
            onClick={() => onFilter()}
            className={classes.filterButton}
          >
            { page === 'kpi'
              ? 'Créer l\'export'
              : 'Appliquer les filtres' }
          </Button>
        </Stack>
      </StyledStack>
    </MainBox>
  );
}

export default SaveFiltersBox;
