import React from 'react';

import { SelectInputProps } from '@mui/material/Select/SelectInput';

import globalTheme from 'common/theme';
import DropDown from 'components/dropDown/DropDown';

import useDataSources from '../state/useDataSource';

type SelectDataSourceProps = {
  value: number | number[],
  onChange: SelectInputProps<any>['onChange'],
  name?: string,
  multiple?: boolean,
  emptyOption?: string
  size?: 'small' | 'medium',
  margin?: number,
  height?: string,
  width?: string,
  fontSize?: keyof typeof globalTheme.fontSize,
  label?: string,
  className?: {
    select?: string,
  },
  filterOptions: number[],
  displayEmptyInField?: boolean,
  displayEmptyInList?: boolean,
  error?: string,
}

function SelectDataSource (props: SelectDataSourceProps) {
  const { dataSources } = useDataSources();
  const {
    value,
    onChange,
    name,
    multiple = false,
    emptyOption,
    size,
    margin,
    fontSize,
    height,
    width,
    label,
    className,
    filterOptions,
    displayEmptyInField,
    displayEmptyInList,
    error,
  } = props;

  const optionsList = dataSources.filter(
    (ds) => (
      filterOptions.length > 0 ? filterOptions.includes(ds.id) : true
    ),
  );

  return (
    <DropDown<typeof dataSources[number]>
      name={name ?? 'actors'}
      fullWidth
      value={value}
      options={optionsList.slice().sort((a, b) => a.name.localeCompare(b.name))}
      label={label}
      onChange={onChange}
      getOptionLabel={(ds) => ds.name}
      getOptionValue={(ds) => ds.id}
      multiple={multiple}
      emptyOption={emptyOption}
      size={size}
      fontSize={fontSize}
      margin={margin}
      height={height}
      width={width}
      className={className}
      data-testid="select-data-source-drop-down"
      displayEmptyInField={displayEmptyInField}
      displayEmptyInList={displayEmptyInList}
      error={Boolean(error)}
      errorText={error}
    />
  );
}

export default SelectDataSource;
