import * as yup from 'yup';

import REGEX_LABEL from 'utils/formControl';

import { Project } from '../types';

export interface UpdateProjectForm {
  id: number;
  name: string;
}

export const initValues = (project: Project): UpdateProjectForm => ({
  id: project.id,
  name: project.name,
});

export const formUpdateProjectToProject = (form: UpdateProjectForm) => ({
  payload: {
    name: form.name.trim(),
  },
  projectId: form.id,
});

export const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Ce champ est obligatoire').matches(
    REGEX_LABEL,
    'Au moins une lettre ou un chiffre requis',
  ),
});
