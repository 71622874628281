import SEARCH_ENGINES from 'features/searchEngines/CONSTANT';
import {
  featuredSnippetLabel,
  peopleAlsoAskLabel,
  imageLabel,
  videoLabel,
  localPackLabel,
  serpPhysionomyLabel,
  ShareOfVoiceLabel,
  rankingLabel,
  keywordsLabel,
} from 'pages/projects/serpDetails/config';
import { PROJECTS } from 'router/CONSTANTS';
import formatProjectNameForUrl from 'utils/formatProjectNameForUrl';

export const generateProjectBaseUrl = (projectId: number | string, projectName: string) => (
  `${PROJECTS}/`
    + `${projectId}-`
    + `${formatProjectNameForUrl(projectName)}/`
);

export const generateAnalysisUrl = (
  projectId: number | string,
  projectName: string,
  analysisId: number | string,
  analysisName: string,
  feature: string | undefined,
  queryParams: URLSearchParams | undefined,
) => {
  const baseProjectLink = `${generateProjectBaseUrl(projectId, projectName)}`;
  const analysisLink = `${analysisId}-${formatProjectNameForUrl(analysisName || '')}`;
  const featureLink = `${feature ? `/${feature}` : ''}`;
  const queryString = (
    queryParams && queryParams.toString() !== ''
      ? `?${queryParams?.toString()}`
      : ''
  );

  return `${baseProjectLink}${analysisLink}${featureLink}${queryString}`;
};

export const generatePerformanceUrl = (
  projectId: number | string,
  projectName: string,
  queryParams?: URLSearchParams,
  currentPage?: string,
  destination: '' | 'by-url' | 'by-serp' | 'kpi' | 'url-indexability' = '',
) => {
  const baseProjectLink = `${generateProjectBaseUrl(projectId, projectName)}`;

  let queryString: string;

  const pathParts: string[] = [];

  if (
    (currentPage === undefined || currentPage.includes('seo-performance'))
    && destination !== 'kpi'
  ) {
    queryString = (queryParams && queryParams.toString() !== ''
      ? `?${queryParams?.toString()}`
      : ''
    );
  } else {
    queryString = '';
  }

  if (destination !== 'url-indexability') pathParts.push('seo-performance');
  if (destination !== '') pathParts.push(destination);

  const routePath = pathParts.join('-');

  return `${baseProjectLink}${routePath}${queryString}`;
};

export const generateSerpUrl = (
  keyword: string,
  searchEngineId?: number,
) => {
  if (searchEngineId === SEARCH_ENGINES.google.id) {
    return `https://www.google.fr/search?q=${encodeURI(keyword)}`;
  }

  if (searchEngineId === SEARCH_ENGINES.bing.id) {
    return `https://www.bing.com/search?q=${encodeURI(keyword)}`;
  }

  return `https://www.google.fr/search?q=${encodeURI(keyword)}`;
};

export const splitIdName = (param: string | undefined) => {
  if (!param) return { id: undefined, name: '' };

  const temp = param.split('-');
  const id = temp.splice(0, 1);
  const name = temp.join('-');

  return {
    id: id[0],
    name,
  };
};

export const deleteParamsFromQuery = (paramsToDelete: string[], query: URLSearchParams) => {
  paramsToDelete.forEach((param) => query.delete(param));
  return query;
};

export const isOneOrMoreFiltersInQuery = (filters: string[], query: URLSearchParams) => {
  const isFilter = filters.find((filter: string) => !!query.get(filter));
  return !!isFilter;
};

export const findFeatureFromUrl = (currentPage: string) => {
  const feature = [
    featuredSnippetLabel,
    peopleAlsoAskLabel,
    imageLabel,
    videoLabel,
    localPackLabel,
    serpPhysionomyLabel,
    ShareOfVoiceLabel,
    rankingLabel,
    keywordsLabel,
  ].find((ft) => ft === currentPage)
    ? currentPage
    : '';

  return feature;
};
