import React, { useEffect, useState } from 'react';

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Button, Typography } from '@mui/material';
import { useFormik } from 'formik';

import { useAppDispatch } from 'common/reduxHooks';
import RenameModal from 'components/modal/RenameModal';

import { updateActorNameValidationSchema } from './setupConfig';
import { updateActor } from '../state/slice';
import useActors from '../state/useActors';
import { UpdateActorNameForm } from '../types';

type RenameActorProps = {
  scopeId: number,
  disabled?: boolean,
}

function RenameActor(props: RenameActorProps) {
  const {
    scopeId,
    disabled = false,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { actors, isLoading } = useActors({ scopeId });
  const sortedActors = [...actors].sort((a1, a2) => a1.name.localeCompare(a2.name));

  const handleSubmit = (
    values: UpdateActorNameForm,
  ) => {
    dispatch(updateActor({
      actorId: values.actorId as number,
      actor: { name: values.name.trim() },
    }));
    setInternalLoading(true);
  };

  const formik = useFormik<UpdateActorNameForm>({
    initialValues: { actorId: '', name: '' },
    validationSchema: updateActorNameValidationSchema,
    onSubmit: handleSubmit,
  });

  const handleCancel = () => {
    setIsOpen(false);
    formik.resetForm();
  };

  useEffect(() => {
    if (!isLoading && internalLoading) {
      setInternalLoading(false);
      setIsOpen(false);
      formik.resetForm();
    }
  }, [isLoading, internalLoading]);

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        variant="contained"
        size="small"
        startIcon={<DriveFileRenameOutlineIcon />}
        disabled={disabled}
        data-testid="rename-actor-value-button"
      >
        Renommer un acteur
      </Button>
      <RenameModal
        title={(
          <Typography>
            <Typography component="span" variant="bold">
              Renommer
              {' '}
            </Typography>
            un acteur
          </Typography>
        )}
        label="Acteur"
        selectOptions={sortedActors}
        field={{
          select: {
            getLabel: (actor) => actor.name,
            getValue: (actor) => actor.id ?? 0,
            name: 'actorId',
          },
          textField: { name: 'name' },
        }}
        value={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        onChange={formik.handleChange}
        onSubmit={formik.handleSubmit}
        isOpen={isOpen}
        onCancel={handleCancel}
        isLoading={internalLoading}
      />
    </>
  );
}

export default RenameActor;
