import React, { useState } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';
import { FormikHelpers, FormikProps, useFormik } from 'formik';

import { useAppDispatch } from 'common/reduxHooks';
import Modal from 'components/modal/Modal';

import {
  CreateModifyExternalUserForm,
  initValuesCreate,
  validationSchemaCreate,
} from './createModifyExternalUserConfig';
import CreateModifyExternalUserModal from './CreateModifyForm';
import { createUser } from '../state/slice';
import { UserType } from '../types';
import UsersUtils from '../UsersUtils';

function CreateExternalUser({ userList }: { userList: UserType[] }) {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSubmit = (
    values: CreateModifyExternalUserForm,
    formikHelpers: FormikHelpers<CreateModifyExternalUserForm>,
  ) => {
    const payload = UsersUtils.createUserPayload(values);

    dispatch(
      createUser(payload),
    );
    setIsOpen(false);
    formikHelpers.resetForm();
  };

  const formik: FormikProps<CreateModifyExternalUserForm> = useFormik<CreateModifyExternalUserForm>(
    {
      initialValues: initValuesCreate,
      validationSchema: validationSchemaCreate(userList),
      onSubmit: handleSubmit,
      enableReinitialize: true,
    },
  );

  return (
    <Box>
      <Button
        variant="contained"
        size="small"
        startIcon={<AddCircleOutlineIcon />}
        onClick={
          () => {
            setIsOpen(true);
          }
        }
      >
        Créer un accès client
      </Button>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">
              Créer
            </Typography>
            {' '}
            un accès client
          </Typography>
        )}
        actionConfirm={formik.handleSubmit}
        actionCancel={() => {
          setIsOpen(false);
          formik.resetForm();
        }}
        isOpen={isOpen}
        dataTestId="create-external-user-modal"
      >
        <CreateModifyExternalUserModal
          formik={formik}
          isModif={false}
        />
      </Modal>
    </Box>
  );
}

export default CreateExternalUser;
