import React from 'react';

import { Typography, Box } from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import UsersList from 'features/users/list/List';

function ExternalUsers () {
  useDocumentTitle('Admin - gestion des accès client | benhur');

  return (
    <Box>
      <Typography variant="h1">Gestion des accès client</Typography>
      <UsersList isExternal />
    </Box>
  );
}

export default ExternalUsers;
