import React from 'react';
import { Link } from 'react-router-dom';

import {
  Typography, styled, StackProps, Stack, Box,
} from '@mui/material';

import Spinner from 'components/loading/Spinner';
import { keywordsLabel } from 'pages/projects/serpDetails/config';
import formatThousands from 'utils/formatThousands';

const PREFIX = 'KeywordsCount';

const classes = {
  bigText: `${PREFIX}-bigText`,
  smallText: `${PREFIX}-smalltext`,
  container: `${PREFIX}-container`,
  spinnerContainer: `${PREFIX}-spinnerContainer`,
  link: `${PREFIX}-link`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`& .${classes.smallText}`]: {
    fontSize: theme.fontSize.xsmall,
  },

  [`& .${classes.bigText}`]: {
    fontSize: theme.fontSize.xlarge,
    fontWeight: 'bold',
  },

  [`& .${classes.container}`]: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-end',
  },

  [`& .${classes.spinnerContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    height: '100%',
    justifyContent: 'center',
  },

  [`& .${classes.link}`]: {
    color: theme.palette.primary.light,
    fontSize: theme.fontSize.xsmall,
  },
}));

type CountProps = {
  scrappedKeywords: number,
  keywords: number,
  isLoading: boolean | undefined,
  baseUrl: string,
}

function Count(props: CountProps) {
  const {
    scrappedKeywords, keywords, isLoading, baseUrl,
  } = props;

  return (
    <StyledStack direction="row" alignItems="center" justifyContent="center">
      {(!isLoading && (
        <Box className={classes.container}>
          <Typography className={classes.bigText}>
            {formatThousands(scrappedKeywords)}
          </Typography>
          <Typography className={classes.smallText}>
            /
            {formatThousands(keywords)}
            &nbsp;
            <Link to={`${baseUrl}/${keywordsLabel}`} className={classes.link}>Mots Clés</Link>
          </Typography>
        </Box>
      )) || (
        <Box className={classes.spinnerContainer} data-testid="spinner">
          <Spinner />
        </Box>
      )}
    </StyledStack>
  );
}

export default Count;
