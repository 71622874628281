import React from 'react';

import {
  FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';

import globalTheme from 'common/theme';
import { DFS_DATA_TYPE_VALUES } from 'features/serpAnalysis/CONSTANT';

type DFSDataTypeDropDownProps = {
  value: number | '',
  onChange: (e: SelectChangeEvent<number>) => void,
  error?: boolean,
}

function DFSDataTypeDropDown(props: DFSDataTypeDropDownProps) {
  const {
    value,
    onChange,
    error,
  } = props;

  return (
    <FormControl
      size="small"
      fullWidth
      sx={{ m: '2px' }}
      error={error}
    >
      <InputLabel
        id="select-dfs-data-type"
        data-testid="select-dfs-data-type"
        size="small"
      >
        Type de SERP
      </InputLabel>
      <Select
        value={value}
        name="dfs-data-type-dropdown"
        label="Type de SERP"
        onChange={onChange}
        inputProps={{
          sx: {
            fontSize: globalTheme.fontSize.medium,
          },
        }}
      >
        { DFS_DATA_TYPE_VALUES.map((dfsDataType) => (
          <MenuItem
            key={`dfs-data-type-${dfsDataType.label}`}
            value={dfsDataType.id}
            sx={{
              fontSize: globalTheme.fontSize.medium,
            }}
          >
            {dfsDataType.label}
          </MenuItem>
        )) }
      </Select>
    </FormControl>
  );
}

export default DFSDataTypeDropDown;
