import { axiosAuthInstance } from 'common/axiosAuthInstance';

import SerpAnalysisScrapping from '../types';

export default class ApiAnalysisScrapping {
  static fetchAnalysisPlanning(payload: number) {
    return axiosAuthInstance.get<never, SerpAnalysisScrapping[]>(
      `serp_analysis/${payload}/serp_analysis_scrapping`,
    );
  }
}
