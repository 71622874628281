import React from 'react';
import { useSearchParams } from 'react-router-dom';

import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Typography,
  Stack,
  styled,
  BoxProps,
  Link,
  Divider,
} from '@mui/material';

import Spinner from 'components/loading/Spinner';
import { ActorType } from 'features/actors/types';
import ChartErrorHandler from 'features/dataviz/components/generic/ChartErrorHandler';
import { DataInfos } from 'features/dataviz/types';
import { DEFAULT_SITE_COUNT }
  from 'features/serpAnalysis/components/serpDetails/parametersBlock/CONSTANT';
import formatThousands from 'utils/formatThousands';

const PREFIX = 'Legend';

const classes = {
  core: `${PREFIX}-core`,
  item: `${PREFIX}-item`,
  typography: `${PREFIX}-typography`,
  details: `${PREFIX}-details`,
  title: `${PREFIX}-title`,
  icon: `${PREFIX}-icon`,
  iconActor: `${PREFIX}-iconActor`,
  iconCompetitor: `${PREFIX}-iconCompetitor`,
  divider: `${PREFIX}-divider`,
  scrollbox: `${PREFIX}-scrollbox`,
  buttonCtn: `${PREFIX}-buttonCtn`,
  iconCross: `${PREFIX}-iconCross`,
  number: `${PREFIX}-number`,
  numberBold: `${PREFIX}-numberBold`,
  spinnerCtn: `${PREFIX}-spinnerCtn`,
  container: `${PREFIX}-container`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.item}`]: {
    marginTop: '.1rem',
    alignItems: 'center',
  },
  [`& .${classes.typography}`]: {
    fontSize: theme.fontSize.xsmall,
  },
  [`& .${classes.details}`]: {
    fontSize: theme.fontSize.xsmall,
    color: theme.palette.info.dark,
  },
  [`& .${classes.title}`]: {
    fontSize: theme.fontSize.medium,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(1),
    width: '15px',
    height: '15px',
  },
  [`& .${classes.iconActor}`]: {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
    width: '15px',
    height: '15px',
  },
  [`& .${classes.iconCompetitor}`]: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.light,
    width: '15px',
    height: '15px',
  },
  [`& .${classes.divider}`]: {
    margin: theme.spacing(1),
  },
  [`& .${classes.scrollbox}`]: {
    height: '100%',
    width: '90%',
    alignSelf: 'end',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      width: '10px',
      height: 0,
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.info.light,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.info.main,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.info.dark,
    },
  },
  [`& .${classes.iconCross}`]: {
    color: theme.palette.error.main,
    width: '15px',
    height: '15px',
  },
  [`& .${classes.number}`]: {
    color: theme.palette.info.dark,
    fontSize: theme.fontSize.xsmall,
  },
  [`& .${classes.numberBold}`]: {
    color: theme.palette.info.dark,
    fontWeight: 'bold',
    fontSize: theme.fontSize.xsmall,
  },
  [`& .${classes.spinnerCtn}`]: {
    width: '100%',
    height: '100%',
  },
  [`& .${classes.container}`]: {
    width: '100%',
    height: '100%',
    display: ' flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
}));

type LegendProps = {
  datas: DataInfos,
  title: string,
  isLoading?: boolean | undefined,
  isPageLoading?: boolean | undefined,
  missingActors?: ActorType[] | undefined,
  setBarColor: (actor: any, actorBasedType?: boolean) => string,
  serpAnalysisStatus: number | undefined,
  isChartWithoutDatas: Function,
  label: string,
  labelPlural: string,
}

function Legend (props: LegendProps) {
  const {
    datas,
    title,
    isLoading = false,
    isPageLoading = false,
    missingActors,
    setBarColor,
    serpAnalysisStatus,
    isChartWithoutDatas,
    label,
    labelPlural,
  } = props;

  const [searchParams] = useSearchParams();

  const sitesCount = parseInt(searchParams.get('sitesCount') ?? `${DEFAULT_SITE_COUNT}`, 10);

  const { values } = datas.data;

  return (
    <StyledBox className={classes.core}>
      {(isLoading || isPageLoading)
        ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            className={classes.spinnerCtn}
            data-testid="spinner"
          >
            <Spinner />
          </Stack>
        )
        : (
          <Box className={classes.container}>
            {
              (values && values.length > 0)
                ? (
                  <>
                    <Typography className={classes.title}>
                      {title}
                    </Typography>
                    <Stack className={classes.scrollbox}>
                      <Stack data-testid="legend-list">
                        { values?.slice(0, sitesCount).map((data: any) => (
                          <Stack
                            className={classes.item}
                            direction="row"
                            key={data.row_number}
                          >
                            <CircleIcon
                              fontSize="small"
                              className={classes.icon}
                              sx={{ color: setBarColor(data) }}
                            />
                            <Typography className={classes.typography}>
                              <Typography className={classes.numberBold} component="span">
                                {
                                (data.row_number) < 10
                                  ? `0${data.row_number}`
                                  : (data.row_number)
                                }
                              </Typography>
                              .
                              {' '}
                              <Link
                                href={`https://${data.name}`}
                                underline="none"
                                color="primary"
                                target="_blank"
                              >
                                {data.name}
                              </Link>
                              {' '}
                              <Typography
                                className={classes.details}
                                component="span"
                                data-testid="data-value"
                              >
                                &#32;
                                &bull;
                                {' '}
                                {formatThousands(data.value)}
                                {' '}
                                {data.value > 1 ? labelPlural : label}
                              </Typography>
                            </Typography>
                          </Stack>
                        ))}
                      </Stack>
                      <Divider variant="middle" className={classes.divider} />
                      <Stack>
                        { values?.slice(sitesCount).map((data: any) => (
                          <Stack className={classes.item} direction="row" key={data.row_number}>
                            <CircleIcon
                              fontSize="small"
                              className={classes.icon}
                              sx={{ color: setBarColor(data) }}
                            />
                            <Typography className={classes.typography}>
                              <Typography className={classes.number} component="span">
                                {data.row_number}
                              </Typography>
                              .
                              {' '}
                              <Link
                                href={`https://${data.name}`}
                                underline="none"
                                color="primary"
                                target="_blank"
                              >
                                {data.name}
                              </Link>
                              {' '}
                              <Typography className={classes.details} component="span">
                                &#32;
                                &bull;
                                {' '}
                                {data.value}
                                {' '}
                                {data.value > 1 ? labelPlural : label}
                              </Typography>
                            </Typography>
                          </Stack>
                        ))}
                      </Stack>
                      <Stack>
                        { missingActors?.map((data) => (
                          <Stack
                            className={classes.item}
                            direction="row"
                            key={`missing-${data.id}`}
                            alignItems="baseline"
                          >
                            <CircleIcon
                              fontSize="small"
                              className={classes.icon}
                              sx={{ color: setBarColor(data, true) }}
                            />
                            <ClearIcon fontSize="small" className={classes.iconCross} />
                            <Typography className={classes.typography} data-testid="actors-list">
                              .
                              {' '}
                              {data.name}
                            </Typography>
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  </>
                )
                : (
                  <ChartErrorHandler
                    title={title}
                    chartData={datas}
                    serpAnalysisStatus={serpAnalysisStatus}
                    isChartWithoutDatas={isChartWithoutDatas}
                    isPageLoading={isPageLoading}
                  />
                )
            }
          </Box>
        )}
    </StyledBox>
  );
}

export default Legend;
