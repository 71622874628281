import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Button,
  Menu,
  Box,
  styled,
  BoxProps,
} from '@mui/material';
import moment from 'moment';

import useCurrentUser from 'features/authentication/state/useCurrentUser';
import { ANALYSIS_STATUS } from 'features/serpAnalysis/CONSTANT';
import { Analysis } from 'features/serpAnalysis/types';
import { isBudgetEnoughForSerpAnalysis } from 'features/serpAnalysis/utils/serpAnalysis';
import PLANNING_TYPE from 'features/serpAnalysisPlanning/state/CONSTANTS';

import DeleteAnalysis from './DeleteAnalysis';
import DownloadVolumes from './DownloadVolumes';
import PlanScrapping from './PlanScrapping';
import RenameAnalysis from './RenameAnalysis';
import StopPlanification from './StopPlanification';

const PREFIX = 'ProjectsDatagridActions';

const classes = {
  dropdown: `${PREFIX}-dropdown`,
  button: `${PREFIX}-button`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.dropdown}`]: {
    fontSize: theme.fontSize.xlarge,
  },

  [`& .${classes.button}`]: {
    color: '#737476',
    fontSize: theme.fontSize.medium,
    height: '30px',
    width: '115px',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      color: '#737476',
      backgroundColor: '#e8e8e8',
    },
  },
}));

type AnalysisDatagridActionsProps = {
  analysis: Analysis,
  projectId: number,
  scopeId: number,
  projectName: string,
  projectBudget: number,
  keywordPrice: number,
}

export default function AnalysesDatagridActions(props: AnalysisDatagridActionsProps): JSX.Element {
  const {
    analysis,
    projectId,
    scopeId,
    projectName,
    projectBudget,
    keywordPrice,
  } = props;

  const [openModals, setOpenModals] = useState<Array<number>>([]);
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const [isBudgetEnough, setIsBudgetEnough] = React.useState(false);
  const openMenu = Boolean(anchorMenu);

  const currentUser = useCurrentUser();
  const isExternalConsultant = currentUser?.isConsultant(projectId);

  const handleOpenMenu = (event: any) => {
    setAnchorMenu(event.target);
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  const hasSchedule = analysis.serp_analysis_planning_id && (
    analysis.serp_analysis_planning_id !== PLANNING_TYPE.NO_REPEAT
    || moment(analysis.planning_date) > moment()
  );

  React.useEffect(() => {
    setIsBudgetEnough(isBudgetEnoughForSerpAnalysis(
      projectBudget,
      keywordPrice,
      analysis.keywords_count,
      analysis.device_type_id,
    ));
  }, [projectBudget, keywordPrice]);

  return (
    <StyledBox>
      <Button
        id={analysis.id.toString()}
        variant="contained"
        disableElevation
        onClick={(e) => { setOpenModals([...openModals, analysis.id]); handleOpenMenu(e); }}
        endIcon={<KeyboardArrowDownIcon className={classes.dropdown} />}
        size="small"
        className={classes.button}
        color="info"
        component="button"
        disabled={analysis.serp_analysis_status_id === ANALYSIS_STATUS.IN_PROGRESS}
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorMenu}
        open={openMenu && openModals.includes(analysis.id)}
        onClose={handleCloseMenu}
        keepMounted
        id="menuActionsProjects"
        data-testid="analyses-datagrid-actions"
      >
        <PlanScrapping
          ids={[analysis.id]}
          isButton={false}
          projectId={projectId}
          scopeId={scopeId}
          projectName={projectName}
          hasIcon
          handleCloseMenu={handleCloseMenu}
          isProjectBudgetEnough={isBudgetEnough}
          planningChoice="serpManual"
        />
        {
          hasSchedule && (
            <StopPlanification
              ids={[analysis.id]}
              handleCloseMenu={handleCloseMenu}
              isButton={false}
              disabled={isExternalConsultant}
            />
          )
        }
        {
          analysis.is_search_volume_computed && (
            <DownloadVolumes
              ids={[analysis.id]}
              handleCloseMenu={handleCloseMenu}
              scopeId={scopeId}
              disabled={currentUser?.isExternal()}
            />
          )
        }
        <RenameAnalysis
          id={analysis.id}
          scopeId={analysis.scope_id}
          label={analysis.label}
          handleCloseMenu={handleCloseMenu}
          disabled={isExternalConsultant}
        />
        <DeleteAnalysis
          ids={[analysis.id]}
          handleCloseMenu={handleCloseMenu}
          isButton={false}
          disabled={isExternalConsultant}
        />
      </Menu>
    </StyledBox>
  );
}
