import * as Yup from 'yup';

import { PROJECT_INDEX_ID, PROJECT_DEVICE_TYPE_ID } from 'features/projects/CONSTANTS';
import { ProjectCreateWithScope } from 'features/projects/types';

export interface ProjectForm {
  name: string,
  description: string,
  dateBegin: string,
  dateEnd: string,
  projectTypeId: number,
  projectStatusId: number,
  fiscalYearId: number,
  scopes: [
    {
      name: string,
      projectId: number,
      indexId: number,
      deviceTypeId: number
    }
  ]
}

export interface ProjectType {
  id: number,
  label: string
}

export const initialValues: ProjectForm = {
  name: '',
  description: '',
  dateBegin: '',
  dateEnd: '',
  projectTypeId: 2,
  projectStatusId: 1,
  fiscalYearId: 0,
  scopes: [
    {
      name: '',
      projectId: 0,
      indexId: PROJECT_INDEX_ID,
      deviceTypeId: PROJECT_DEVICE_TYPE_ID,
    },
  ],
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1)
    .max(70)
    .matches(
      /^(?=.*[A-Za-z0-9])/,
      'Le nom de projet doit contenir au moins un caractère alphanumérique, merci de rectifier.',
    )
    .required('Le champ est vide, merci de rectifier.'),
});

export function formatProjectFormWithScope(form: ProjectForm): ProjectCreateWithScope {
  const project: ProjectCreateWithScope = {
    name: form.name.trim(),
    description: form.description,
    date_begin: form.dateBegin,
    date_end: form.dateEnd,
    project_type_id: form.projectTypeId,
    project_status_id: form.projectStatusId,
    fiscal_year_id: form.fiscalYearId,
    scopes: form.scopes.map((scope) => ({
      name: scope.name,
      project_id: scope.projectId,
      index_id: scope.indexId,
      device_type_id: scope.deviceTypeId,
    })),
  };

  return project;
}
