import React from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import useDocumentTitle from 'common/documentTitleHook';
import { DATA_SOURCE } from 'features/dataFiles/CONSTANT';
import DatavizContainer from 'features/dataviz/components/generic/DatavizContainer';
import { METRIC } from 'features/dataviz/CONSTANTS';
import datavizConfig from 'features/dataviz/datavizConfig';
import { Project } from 'features/projects/types';

type UrlsListProps = {
  project?: Project,
};

function UrlsList(props: UrlsListProps) {
  const { project } = props;
  const scopeId = project?.scopes[0].id ?? 0;

  useDocumentTitle(`${project?.name} : toutes les URL du projet | benhur`);

  return (
    <Box>
      <Typography variant="h1">
        Liste des URL utilisées dans le projet
      </Typography>
      <DatavizContainer
        datavizComp={datavizConfig[METRIC.get_all_urls_by_actors.label]}
        idScope={scopeId}
        project={project}
        periode=""
        title=""
        idSerpAnalysis={1}
        datavizFilters={{
          type: 'rankingEvolutionFilters',
          dataSourceIds: [
            DATA_SOURCE.semrush.id,
            DATA_SOURCE.screaming_frog.id,
            DATA_SOURCE.data_for_seo_serp.id,
          ],
          actorIds: [],
          datavizMode: '',
          deviceTypeId: 1,
          intentionIds: [],
          keywordIds: [],
          periodeBegin: '2024-01-01T00:00:00',
          periodeEnd: '2024-01-01T00:00:00',
          rankingTypeId: [],
          rankSliceIds: [],
          timeSeries: '',
        }}
        exportName="export-url.csv"
        height="auto"
      />
    </Box>
  );
}

export default UrlsList;
