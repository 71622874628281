import React from 'react';

import { Avatar, styled } from '@mui/material';

import BasicUser from '../BasicUser';
import User from '../User';

const PREFIX = 'UserAvatarView';

const classes = {
  avatar: `${PREFIX}-avatar`,
};

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size?: string }>(({ size }) => ({

  [`&.${classes.avatar}`]: {
    height: size === 'big' ? '50px' : '',
    width: size === 'big' ? '50px' : '',
  },
}));

type AvatarViewProps = {
  user: User | BasicUser,
  isTitle?: boolean,
  size?: 'big' | 'default',
}

export default function UserAvatarView(props: AvatarViewProps): JSX.Element {
  const { user, size = 'default', isTitle = false } = props;

  return (
    <StyledAvatar
      size={size}
      className={classes.avatar}
      alt={user.userFullName}
      src={user.userAvatar || ''}
      title={isTitle ? user.userFullName : ''}
    />
  );
}
