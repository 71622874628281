import React from 'react';
import { NavLink } from 'react-router-dom';

import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GroupIcon from '@mui/icons-material/Group';
import {
  Button,
  Grid2 as Grid,
  Typography,
  Stack,
} from '@mui/material';

import { useAppSelector } from 'common/reduxHooks';
import useActors from 'features/actors/state/useActors';
import useCurrentUser from 'features/authentication/state/useCurrentUser';
import AddSerpAnalysisDataFile
  from 'features/dataFiles/actions/AddSerpAnalysisDataFile/AddSerpAnalysisDataFile';
import CreditProject from 'features/projects/components/CreditProject';
import { selectProjectById } from 'features/projects/state/slice';
import { generateProjectBaseUrl } from 'utils/urlManipulation';

import ConfigurationBlock from './ConfigurationBlock';
import CreateSerpAnalysisModalsChoice from '../components/modals/create/ModalsChoice';
import useSerpAnalyses from '../state/useSerpAnalyses';

type ConfigurationContainerProps = {
  scopeId: number,
  projectId: number,
  projectName: string,
  isImportingKeywords?: boolean,
}

function ConfigurationContainer (props: ConfigurationContainerProps) {
  const {
    scopeId,
    projectId,
    projectName,
    isImportingKeywords,
  } = props;

  const { hasSerpAnalyses } = useSerpAnalyses({ scopeId, doFetch: false });
  const { hasActors } = useActors({ scopeId, doFetch: true });

  const project = useAppSelector((state) => selectProjectById(state.projects, projectId));

  const currentUser = useCurrentUser();
  const importKeywordDisabled = currentUser?.isConsultant(projectId)
    || isImportingKeywords;

  const roundValue = parseFloat(project?.project_budget || '0') < 0.01
    ? 1000
    : 100;

  return (
    <Grid container direction="row" spacing={2} data-testid="settings-container">
      <Grid size={4}>
        <ConfigurationBlock
          title="Vérification du paramétrage"
          Icon={EuroSymbolIcon}
          blockHeight={200}
          description={(
            <Stack direction="column">
              <Typography variant="body2">
                <Typography variant="bold">
                  {
                  Math.trunc(
                    parseFloat(
                      project?.project_budget || '0',
                    ) * roundValue,
                  )
                    / roundValue
                  }
                </Typography>
                €
                {' '}
                est alloué au projet.
              </Typography>
              <CreditProject
                key="creditProject"
                projectId={project?.id || 0}
                keywordPrice={Number(project?.keyword_price) ?? undefined}
              />
            </Stack>
          )}
          buttons={[
            <NavLink
              to={`${generateProjectBaseUrl(projectId, projectName)}transactions`}
              key="budgetbutton2"
            >
              <Button variant="contained" size="small">
                Suivi conso
              </Button>
            </NavLink>,
          ]}
          isBudget
          project={project}
        />
      </Grid>
      <Grid size={4}>
        <ConfigurationBlock
          title="Vérification des mots clés"
          Icon={FormatListBulletedIcon}
          blockHeight={200}
          description={(
            <Typography variant="body2">
              <Typography variant="bold">Spécifiez les mots clés</Typography>
              {' '}
              à analyser.
              <Typography variant="body2" component="span" sx={{ display: 'block' }}>
                Utilisez un fichier CSV pour indiquer
                les volumes et les intentions de recherche.
              </Typography>
            </Typography>
          )}
          buttons={[
            (
              <CreateSerpAnalysisModalsChoice
                isImportingKw={false}
                isButton
                key="keywordsButton1"
                buttonText="Ajouter"
                disabled={importKeywordDisabled}
              />
            ),
            (
              <AddSerpAnalysisDataFile
                isImportingKeywords={false}
                scopeId={scopeId}
                isButton
                key="keywordsButton2"
                buttonText="Importer"
                disabled={importKeywordDisabled}
              />
            ),
          ]}
          isConfigDone={hasSerpAnalyses}
        />
      </Grid>
      <Grid size={4}>
        <ConfigurationBlock
          title="Vérification des acteurs"
          Icon={GroupIcon}
          blockHeight={200}
          description={(
            <Typography variant="body2">
              <Typography variant="bold">Configurez les noms de domaine</Typography>
              {' '}
              à mettre en évidence au sein du projet.
            </Typography>
          )}
          buttons={[(
            <NavLink
              to={`${generateProjectBaseUrl(projectId, projectName)}setting/competitor`}
              key="actorsButton1"
            >
              <Button
                variant="contained"
                size="small"
              >
                Configurer
              </Button>
            </NavLink>
          )]}
          isConfigDone={hasActors}
        />
      </Grid>
    </Grid>
  );
}

export default ConfigurationContainer;
