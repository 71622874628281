import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Grid2 as Grid, Typography } from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import MainBox from 'components/layout/MainBox';
import ListAll from 'features/keywords/components/ListAll';
import { selectProjectById } from 'features/projects/state/slice';
import { fetchSerpAnalyses, selectAnalysisById } from 'features/serpAnalysis/state/slice';
import { splitIdName } from 'utils/urlManipulation';

function Keywords () {
  const dispatch = useAppDispatch();

  const { idNameProject, idNameAnalysis } = useParams();
  const { id: idProject } = splitIdName(idNameProject);
  const { id: idAnalysis } = splitIdName(idNameAnalysis);

  const project = useAppSelector((state) => (
    selectProjectById(state.projects, idProject)));
  const scopeId = project?.scopes[0]?.id ? project.scopes[0].id : null;
  const analysis = useAppSelector((state) => (
    selectAnalysisById(state.serpAnalyses, idAnalysis)));

  useEffect(() => {
    if (scopeId && idAnalysis !== undefined && !analysis) {
      dispatch(fetchSerpAnalyses({ scopeId }));
    }
  });

  useDocumentTitle(`Gestion des mots clés : ${analysis?.label} | benhur`);

  return (
    <Box>
      <Typography variant="h1">Gestion des mots clés</Typography>
      {analysis && (
        <Grid size={12} paddingBottom="10px">
          <MainBox>
            <ListAll
              analysis={analysis}
              scopeId={scopeId || 0}
              projectId={project?.id ?? 0}
            />
          </MainBox>
        </Grid>
      )}
    </Box>
  );
}

export default Keywords;
