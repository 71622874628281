import { axiosAuthInstance } from 'common/axiosAuthInstance';
import SerpAnalysisScrapping from 'features/serpAnalysisScrapping/types';

import {
  Analysis,
  AnalysisCreate,
  AnalysisDelete,
  SerpAnalysisScrappingCreate,
  SerpAnalysisUpdatePayload,
} from '../types';

export default class ApiAnalyses {
  static fetchSerpAnalyses(scopeId: number) {
    return axiosAuthInstance.get<never, Analysis[]>(`scopes/${scopeId}/serp_analysis`);
  }

  static updateSerpAnalyses(payload: SerpAnalysisUpdatePayload[]) {
    return axiosAuthInstance.put<never, Analysis[]>(
      'serp_analysis/multiple',
      payload,
    );
  }

  static deleteAnalyses(payload: number) {
    return axiosAuthInstance.delete<never, AnalysisDelete>(
      `serp_analysis/${payload}`,
    );
  }

  static createAnalyses(payload: AnalysisCreate) {
    return axiosAuthInstance.post<never, AnalysisCreate>('serp_analysis/with_keywords', payload);
  }

  static createSerpAnalysisScrapping(payload: SerpAnalysisScrappingCreate) {
    return axiosAuthInstance.post<never, SerpAnalysisScrapping>(
      'serp_analysis_scrapping/',
      payload,
    );
  }
}
