import React from 'react';

import { Box, Typography } from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import UsersList from 'features/users/list/List';

export default function UserAdmin(): JSX.Element {
  useDocumentTitle('Admin – gestion des utilisateurs | benhur');

  return (
    <Box>
      <Typography variant="h1">Gestion des utilisateurs</Typography>
      <UsersList />
    </Box>
  );
}
