import * as yup from 'yup';

import USER_TYPES from 'features/users/CONSTANTS';
import { BasicUserType } from 'pages/projects/types';

export interface ManageUsersForm {
  users: Array<number>;
  manager?: number,
  dircli?: number,
}

export const initValues = (
  users: BasicUserType[],
  manager?: BasicUserType | null,
  dircli?: BasicUserType | null,
): ManageUsersForm => ({
  users: users.filter(
    (user) => user.user_type.id !== USER_TYPES.external.id,
  ).map((user) => user.id),
  manager: manager?.id,
  dircli: dircli?.id,
});

export const validationSchema = yup.object().shape({
  users: yup.array(),
  manager: yup.number().optional(),
  dircli: yup.number().optional(),
});
