import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Box, Typography,
} from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import { useAppSelector } from 'common/reduxHooks';
import MainBox from 'components/layout/MainBox';
import DataGridGroupActions
  from 'features/categoryGroups/categoryValues/actions/DataGridGroupActions';
import CategoryValuesDataGrid
  from 'features/categoryGroups/categoryValues/list/CategoryValuesDataGrid';
import CategoryGroup from 'features/categoryGroups/classes/CategoryGroup';
import CATEGORY_TYPE_ID from 'features/categoryGroups/CONSTANTS';
import useCategoryGroups from 'features/categoryGroups/state/useCategoryGroups';
import { selectProjectById } from 'features/projects/state/slice';
import { splitIdName } from 'utils/urlManipulation';

function SetupBrand() {
  const { idNameProject } = useParams();
  const { id: idProject } = splitIdName(idNameProject);
  const [selection, setSelection] = useState<number[]>([]);

  const project = useAppSelector((state) => (
    selectProjectById(state.projects, idProject)));

  const scopeId = project?.scopes[0]?.id ? project?.scopes[0]?.id : 0;

  useDocumentTitle(`${project?.name} : requêtes Marque | benhur`);

  const {
    isLoading,
    categoryGroups: tempCatGroups,
  } = useCategoryGroups(scopeId, CATEGORY_TYPE_ID.brand);

  const catGroupBrand = tempCatGroups.length > 0 ? new CategoryGroup(tempCatGroups[0]) : undefined;

  const handleSelectionModelChange = (rowSelectionModel: number[]) => {
    setSelection(rowSelectionModel as number[]);
  };

  return (
    <Box>
      <Typography variant="h1">
        Listez les requêtes Marque pour les visualiser dans les différentes vues du projet
      </Typography>
      <MainBox>
        <DataGridGroupActions
          selection={selection}
          scopeId={scopeId}
          categoryGroupType={CATEGORY_TYPE_ID.brand}
          categoryGroup={catGroupBrand}
        />
        <CategoryValuesDataGrid
          rows={catGroupBrand?.formatCategoryValuesForDataGrid() ?? []}
          handleSelectionModelChange={handleSelectionModelChange}
          selectionModel={selection}
          isLoading={isLoading}
          categoryGroupType={CATEGORY_TYPE_ID.brand}
        />
      </MainBox>
    </Box>
  );
}

export default SetupBrand;
