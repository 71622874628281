import React from 'react';

import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { frFR } from '@mui/x-date-pickers/locales';
import moment from 'moment';

import globalTheme from 'common/theme';
import ActorsDropDown from 'features/actors/components/ActorsDropDown';
import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';

import DataFileDatagridActions from '../actions/DataFileDataGridAction';
import StatusFlag from '../components/StatusFlag';
import { DATA_SOURCE } from '../CONSTANT';
import { DataFileType } from '../types';

const createColumns = (
  handleFieldUpdate: Function,
  handleUpdateDataFile: Function,
  checkQualifDataFiles: Function,
): GridColDef<DataFileType>[] => [
  {
    field: 'name',
    renderHeader: () => (
      <StyledHeader label="FICHIERS" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">{params.value}</Typography>
    ),
    valueGetter: (value, row) => row.original_name,
    align: 'left',
    headerAlign: 'left',
    flex: 4,
  },
  {
    field: 'source',
    renderHeader: () => (
      <StyledHeader label="SOURCES" />
    ),
    renderCell: (params) => (
      <Typography variant="body3">{params.value}</Typography>
    ),
    valueGetter: (value, row) => row.data_source?.name,
    align: 'left',
    headerAlign: 'left',
    flex: 1.1,
  },
  {
    field: 'actor2',
    renderHeader: () => (
      <StyledHeader label="ACTEURS" />
    ),
    renderCell: (params) => (
      <ActorsDropDown
        scopeId={params.row.scope_id}
        value={params.value}
        onChange={(e: any) => (
          handleFieldUpdate(params.row, 'actor_id', e.target.value)
        )}
        name="actor_id"
        height="25px"
        fontSize="xsmall"
        emptyOption="Acteur"
      />
    ),
    valueGetter: (value, row) => row.actor_id,
    align: 'left',
    headerAlign: 'left',
    flex: 1.1,
  },
  {
    field: 'period',
    renderHeader: () => (
      <StyledHeader label="PÉRIODES" />
    ),
    renderCell: (params) => (
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale="fr"
        localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <DatePicker
          label=""
          value={
            params.value
              ? moment(params.value)
              : undefined
          }
          format={
            params.row.data_source_id === DATA_SOURCE.screaming_frog.id
              ? 'DD/MM/YYYY'
              : 'MM/YYYY'
          }
          views={
            params.row.data_source_id === DATA_SOURCE.screaming_frog.id
              ? ['year', 'month', 'day']
              : ['month', 'year']
          }
          onChange={
            (value) => handleFieldUpdate(
              params.row,
              'periode',
              value && moment(value).isValid()
                ? value.format()
                : moment().format(),
            )
          }
          slotProps={{
            textField: {
              size: 'small',
              error: false,
              InputProps: {
                sx: {
                  fontSize: globalTheme.fontSize.xsmall,
                  height: 25,
                  '& .MuiSvgIcon-root': {
                    width: 18,
                  },
                },
              },
              value: moment(params.value),
            },
          }}
        />
      </LocalizationProvider>
    ),
    valueGetter: (value, row) => row.periode,
    align: 'left',
    headerAlign: 'left',
    flex: 1.1,
  },
  {
    field: 'status',
    renderHeader: () => (
      <StyledHeader label="ÉTATS" />
    ),
    renderCell: (params) => (
      <StatusFlag statusId={params.value} />
    ),
    valueGetter: (value, row) => row.data_file_status_id,
    align: 'left',
    headerAlign: 'left',
    flex: 1.1,
  },
  {
    field: 'actions',
    headerName: '',
    renderCell: (params) => (
      <DataFileDatagridActions
        dataFile={params.row}
        handleUpdate={handleUpdateDataFile}
        checkQualifDataFiles={checkQualifDataFiles}
      />
    ),
    align: 'left',
    headerAlign: 'left',
    sortable: false,
    flex: 1.1,
  },
];

export default createColumns;
