import React from 'react';
import { NavLink } from 'react-router-dom';

import { Box, BoxProps, styled } from '@mui/material';

const PREFIX = 'TextNavLinkDataGrid';

type TextNavLinkProps = {
  link: string,
  label: string,
  testId?: string,
  alwaysUnderlined?: boolean,
}

const classes = {
  link: `${PREFIX}-link`,
  linkUnderlined: `${PREFIX}-linkUnderlined`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.link}`]: {
    color: 'black',
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.link}:hover`]: {
    color: theme.palette.primary.light,
    fontSize: theme.fontSize.medium,
    textDecoration: 'underline',
  },
  [`& .${classes.linkUnderlined}`]: {
    color: theme.palette.primary.main,
    fontSize: theme.fontSize.medium,
    textDecoration: 'underline',
  },
}));

export default function TextNavLinkDataGrid(props: TextNavLinkProps): JSX.Element {
  const {
    link,
    label,
    testId = '',
    alwaysUnderlined = false,
  } = props;
  return (
    <StyledBox>
      <NavLink to={link}>
        <Box
          className={alwaysUnderlined ? classes.linkUnderlined : classes.link}
          data-testid={testId}
        >
          {label}
        </Box>
      </NavLink>
    </StyledBox>
  );
}
