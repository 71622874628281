import { CategoryTypeType, EditCategoryValueType } from '../categoryValues/type';
import { CATEGORY_VALUE_TYPE } from '../CONSTANTS';
import { updateCategoryValue } from '../state/slice';
import {
  CategoryValue as CategoryValueType,
} from '../types';

export default class CategoryValue {
  private id: number;

  private value: string;

  private category_id: number;

  private insertion_date: string;

  private active: boolean;

  private include: boolean;

  private category_value_type_id: CategoryTypeType;

  private category_name?: string;

  private category_group_id?: number;

  constructor(catVal: CategoryValueType, category_name?: string, category_group_id?: number) {
    this.id = catVal.id;
    this.value = catVal.value;
    this.category_id = catVal.category_id;
    this.insertion_date = catVal.insertion_date;
    this.active = catVal.active;
    this.include = catVal.include;
    this.category_value_type_id = catVal.category_value_type_id as CategoryTypeType;
    this.category_name = category_name;
    this.category_group_id = category_group_id;
  }

  get catValValue() {
    return this.value;
  }

  get catValCatId() {
    return this.category_id;
  }

  get catValDate() {
    return this.insertion_date;
  }

  get catValInclude() {
    return this.include;
  }

  get catValActive() {
    return this.active;
  }

  get catValCatTypeId(): CategoryTypeType {
    return this.category_value_type_id;
  }

  get catValCatType(): string {
    return CATEGORY_VALUE_TYPE[this.catValCatTypeId];
  }

  get catValCatGroupId() {
    return this.category_group_id ?? 0;
  }

  get catValId() {
    return this.id;
  }

  get catName() {
    return this.category_name ?? '';
  }

  updateCatVal(dispatch: Function, newcatVal: EditCategoryValueType) {
    dispatch(updateCategoryValue({
      id: this.catValId,
      category_group_id: this.catValCatGroupId,
      category_id: this.catValCatId,
      category_value: {
        category_id: this.catValCatId,
        value: newcatVal.url,
        category_value_type_id: newcatVal.categoryType,
        include: newcatVal.include,
      },
    }));
  }
}
