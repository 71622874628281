import * as yup from 'yup';

import { UserProjectType } from '../types';

export interface UpdateUserProjectsForm {
  projectsEdit?: (number | string)[]
  projectsConsult?: (number | string)[]
}

export const initValues = (userProjects: UserProjectType[]): UpdateUserProjectsForm => {
  const initialValues: UpdateUserProjectsForm = {
    projectsEdit: [],
    projectsConsult: [],
  };
  userProjects.forEach((project) => (
    project.is_consultant
      ? initialValues.projectsConsult?.push(project.project_id)
      : initialValues.projectsEdit?.push(project.project_id)
  ));

  if (!initialValues.projectsConsult?.length) initialValues.projectsConsult?.push('');
  if (!initialValues.projectsEdit?.length) initialValues.projectsEdit?.push('');

  return initialValues;
};

const hasValidValue = (arr: any[]) => (
  (arr.length === 1 && arr[0])
  || arr.length > 1
);

const validateProjectsFields = (projectsConsult: any[], projectsEdit: any[]) => {
  // At least one field must have something in it other than an empty string
  const editFieldIsValid = hasValidValue(projectsEdit);
  const consultFieldIsValid = hasValidValue(projectsConsult);

  return editFieldIsValid || consultFieldIsValid;
};

const validateProjectsFieldsHasDifferentValue = (projectsConsult: any[], projectsEdit: any[]) => {
  // a value cannot be in both fields
  const hasSharedValue = projectsConsult.some((projectId) => projectsEdit.includes(projectId));

  return !hasSharedValue;
};

export const validationSchema = yup.object().shape({
  projectsEdit: yup.array().test(
    'min-projects-edit',
    ' ',
    (code, context) => {
      const { projectsConsult } = context.parent;
      if (!code) return false;

      return validateProjectsFields(projectsConsult, code);
    },
  ).test(
    'fields-share-value',
    ' ',
    (code, context) => {
      const { projectsConsult } = context.parent;
      if (!code) return false;

      return validateProjectsFieldsHasDifferentValue(projectsConsult, code);
    },
  ),
  projectsConsult: yup.array().test(
    'min-projects-consult',
    'L\'utilisateur doit être relié à au moins un projet (consultable ou modifiable)',
    (code, context) => {
      const { projectsEdit } = context.parent;
      if (!code) return false;

      return validateProjectsFields(code, projectsEdit);
    },
  ).test(
    'fields-share-value',
    'Un projet ne peut pas être en même temps modifiable et consultable',
    (code, context) => {
      const { projectsEdit } = context.parent;
      if (!code) return false;

      return validateProjectsFieldsHasDifferentValue(code, projectsEdit);
    },
  ),
});
