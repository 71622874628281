import React from 'react';

import { SelectInputProps } from '@mui/material/Select/SelectInput';

import DropDown from 'components/dropDown/DropDown';

import { RANK_SLICES } from '../CONSTANT';
import { RankSliceType } from '../types';

type RankSliceDropDownProps = {
  values: number[],
  onChange: SelectInputProps<any>['onChange'],
  rankSlicesLimited?: RankSliceType[],
  className?: {
    select?: string,
  },
}

function RankSliceDropDown(props: RankSliceDropDownProps) {
  const {
    values,
    onChange,
    rankSlicesLimited,
    className,
  } = props;

  return (
    <DropDown<typeof RANK_SLICES[number]>
      name="rankSlices"
      fullWidth
      value={values}
      options={rankSlicesLimited ?? RANK_SLICES}
      label="Rang(s)"
      onChange={onChange}
      size="small"
      fontSize="xsmall"
      className={className}
      data-testid="performance-rank-slices-dropdown"
      getOptionLabel={(value) => value.label}
      getOptionValue={(value) => value.id}
      multiple
    />
  );
}

export default RankSliceDropDown;
