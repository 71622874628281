import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import useDocumentTitle from 'common/documentTitleHook';
import { useAppSelector } from 'common/reduxHooks';
import MainBox from 'components/layout/MainBox';
import { selectProjectById } from 'features/projects/state/slice';
import GroupActions from 'features/serpAnalysis/options/actions/GroupActions';
import SerpAnalysesOptionsDataGrid
  from 'features/serpAnalysis/options/list/SerpAnalysesOptionsDataGrid';
import useSerpAnalyses from 'features/serpAnalysis/state/useSerpAnalyses';
import { splitIdName } from 'utils/urlManipulation';

function OptionsPage() {
  const [serpAnalysisIdsSelection, setSerpAnalysisIdsSelection] = useState<number[]>([]);

  const { idNameProject } = useParams();
  const { id: idProject } = splitIdName(idNameProject);

  const project = useAppSelector((state) => (
    selectProjectById(state.projects, idProject)
  ));

  const scopeId = project?.scopes[0].id ? project?.scopes[0].id : 0;

  const { serpAnalyses, isLoading } = useSerpAnalyses({ scopeId });

  useDocumentTitle(`${project?.name} : options d'analyse de SERP | benhur`);

  const handleSelectionModelChange = (
    rowSelectionModel: GridRowSelectionModel,
  ) => {
    setSerpAnalysisIdsSelection(rowSelectionModel as number[]);
  };

  return (
    <div>
      <Typography variant="h1">
        Activez des options spécifiques à l&apos;analyse de SERP
      </Typography>
      <MainBox>
        <GroupActions
          selection={serpAnalysisIdsSelection}
          isLoading={isLoading}
        />
        <SerpAnalysesOptionsDataGrid
          serpAnalyses={serpAnalyses}
          isLoading={isLoading}
          selection={serpAnalysisIdsSelection}
          handleSelectionModelChange={handleSelectionModelChange}
        />
      </MainBox>
    </div>
  );
}

export default OptionsPage;
