import { RECALCUL_KPI_STATUS } from 'features/projects/CONSTANTS';
import { RecalculKPIs } from 'features/projects/types';

const getRecalculKPIsStatus = (kpiArray: RecalculKPIs[] | undefined) => {
  let isRecalculating = false;
  if (kpiArray && kpiArray.length > 0) {
    isRecalculating = kpiArray.filter(
      (kpi) => kpi.recalcul_kpi_status_id === RECALCUL_KPI_STATUS.RECALCULATING
      || kpi.recalcul_kpi_status_id === RECALCUL_KPI_STATUS.TO_RECALCUL,
    ).length > 0;
  }
  return isRecalculating;
};

export default getRecalculKPIsStatus;
