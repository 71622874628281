import { DEVICE_TYPE } from 'features/deviceType/CONSTANTS';

import { PhysionomyFiltersForm } from '../types';

function initForm(
  actors?: number[],
  serpDevice?: number,
  rankLimit?: number,
  periodeOne?: number,
  periodeTwo?: number,
  showValues?: boolean,
  showPercentage?: boolean,
): PhysionomyFiltersForm {
  return {
    actors: actors ?? [],
    deviceId: serpDevice ?? DEVICE_TYPE.DESKTOP_MOBILE,
    rankLimit: rankLimit ?? 50,
    periodeOne,
    periodeTwo,
    showValues: showValues ?? false,
    showPercentage: showPercentage ?? true,
  };
}

export default initForm;
