import { axiosAuthInstance } from 'common/axiosAuthInstance';

import { ActorType, ActorTypeUpdate, SiteUrlPattern } from '../types';

export default class ApiActors {
  static fetchActors(scopeId: number) {
    return axiosAuthInstance.get<ActorType[]>(`scopes/${scopeId}/actors`);
  }

  static updateActor(actorId: number, payload: ActorTypeUpdate) {
    return axiosAuthInstance.put<ActorType>(`actors/${actorId}`, payload);
  }

  static updateActorsByScope(scopeId: number, payload: Array<ActorType>) {
    return axiosAuthInstance.put<ActorType[]>(`scopes/${scopeId}/actors`, payload);
  }

  static addActor(payload: ActorType) {
    return axiosAuthInstance.post<ActorType>('actors/with_sites', payload);
  }

  static addSiteUrlPattern(payload: SiteUrlPattern) {
    return axiosAuthInstance.post<SiteUrlPattern>('site_url_patterns/', payload);
  }

  static updateSiteUrlPattern(payload: SiteUrlPattern) {
    return axiosAuthInstance.put<SiteUrlPattern>(
      `site_url_patterns/${payload.id}`,
      payload,
    );
  }

  static deleteSiteUrlPattern(payload: { patternId: number }) {
    return axiosAuthInstance.delete<SiteUrlPattern>(
      `site_url_patterns/${payload.patternId}`,
    );
  }

  static deleteActor(payload: { actorId: number }) {
    return axiosAuthInstance.delete<ActorType>(`actors/${payload.actorId}/with_sites`);
  }
}
