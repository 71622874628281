import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';

import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';
import TextNavLinkDataGrid
  from 'features/dataviz/components/generic/datagrid/textNavLinkDataGrid/TextNavLinkDataGrid';
import findNextScrappingDate from 'features/serpAnalysis/utils/findNextScrappingDate';
import USER_TYPES from 'features/users/CONSTANTS';
import UserAvatarsList from 'features/users/infos/AvatarListView';
import { UserType } from 'features/users/types';
import { convertDate, dateComparator, DATE_NUMBERS_HOURS } from 'utils/formatDate';
import formatProjectNameForUrl from 'utils/formatProjectNameForUrl';

import ProjectsDatagridActions from '../actions/ProjectsDatagridActions';
import { Project } from '../types';

const roundValue = (budget: string): number => (parseFloat(budget || '0') < 0.01
  ? 1000
  : 100);

const createProjectColumns = (
  currentUser: UserType | undefined,
  classes: any,
): GridColDef<Project>[] => ([
  {
    field: 'name',
    headerClassName: 'super-app-theme--header',
    renderHeader: () => (
      <StyledHeader label="Projets" />
    ),
    renderCell: (params) => (
      (
        (currentUser?.id === params.row.project_manager?.id)
        || (currentUser?.id === params.row.project_dircli?.id)
        || (params.row.users.find((user) => user.id === currentUser?.id))
        || currentUser?.user_type_id === USER_TYPES.admin.id
      )
        ? (
          <TextNavLinkDataGrid
            link={`${params.row.id}-${
              formatProjectNameForUrl(params.row.name)}`}
            label={params.row.name}
            testId="link-name-datagrid-projects"
          />
        )
        : (
          <Typography className={classes.text}>
            {params.row.name}
          </Typography>
        )
    ),
    flex: 1,
  },
  {
    field: 'project_budget',
    headerClassName: 'super-app-theme--header',
    renderHeader: () => (
      <StyledHeader label="Budget Mots Clés" />
    ),
    renderCell: (params) => (
      <Typography className={classes.text}>
        {`${params.value}€`}
      </Typography>
    ),
    valueGetter: (value, row) => Math.trunc(
      parseFloat(
        row.project_budget || '0',
      ) * roundValue(row.project_budget),
    ) / roundValue(row.project_budget),
    width: 160,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'kpi_exports',
    headerClassName: 'super-app-theme--header',
    renderHeader: () => (
      <StyledHeader label="Automatisations" />
    ),
    renderCell: (params) => (
      params.row.kpi_export_count > 0
        ? <CheckCircleIcon color="success" />
        : <DangerousIcon color="error" />
    ),
    width: 150,
    align: 'center',
    headerAlign: 'center',
    valueGetter: (value, row) => row.kpi_export_count,
    sortComparator: (v1, v2) => {
      if (v1 > 0 && v2 <= 0) return -1;
      if (v1 <= 0 && v2 > 0) return 1;
      return 0;
    },
  },
  {
    field: 'externals',
    headerClassName: 'super-app-theme--header',
    renderHeader: () => (
      <StyledHeader label="Accès externes" />
    ),
    renderCell: (params) => (
      <Typography className={classes.text}>
        {params.value}
      </Typography>
    ),
    valueGetter: (value, row) => row.users.filter(
      (user) => user.user_type.id === USER_TYPES.external.id,
    ).length,
    width: 150,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'dircli',
    headerClassName: 'super-app-theme--header',
    renderHeader: () => (
      <StyledHeader label="DirCli" />
    ),
    renderCell: (params) => (
      (params.row.project_dircli?.id && (
        <UserAvatarsList usersList={[params.row.project_dircli]} />
      )) || (<Box />)
    ),
    width: 80,
    align: 'center',
    headerAlign: 'center',
    sortable: true,
    valueGetter: (value, row) => (
      row.project_dircli
        ? row.project_dircli.last_name
        : ''
    ),
    sortComparator: (v1, v2) => v1.localeCompare(v2),
  },
  {
    field: 'manager',
    headerClassName: 'super-app-theme--header',
    renderHeader: () => (
      <StyledHeader label="Chef(fe) de projet" />
    ),
    renderCell: (params) => (
      (params.row.project_manager && (
        <UserAvatarsList usersList={[params.row.project_manager]} />
      )) || (<Box />)
    ),
    width: 160,
    align: 'center',
    headerAlign: 'center',
    sortable: true,
    valueGetter: (value, row) => (
      row.project_manager
        ? row.project_manager.last_name
        : ''
    ),
    sortComparator: (v1, v2) => v1.localeCompare(v2),
  },
  {
    field: 'collaborators',
    headerClassName: 'super-app-theme--header',
    renderHeader: () => (
      <StyledHeader label="Equipe" />
    ),
    sortable: false,
    renderCell: (params) => (
      <UserAvatarsList usersList={params.row.users.filter(
        (user) => user.user_type.id !== USER_TYPES.external.id,
      )}
      />
    ),
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'planning_date',
    renderHeader: () => (
      <StyledHeader label="Planification" />
    ),
    valueGetter: (value, row) => findNextScrappingDate(
      row.project_analyses,
      row.project_budget,
    ),
    renderCell: (params) => (
      <Typography className={classes.text}>
        {params.value}
      </Typography>
    ),
    sortComparator: (v1, v2) => dateComparator(v1, v2, DATE_NUMBERS_HOURS),
    width: 120,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'insertion_date',
    headerClassName: 'super-app-theme--header',
    renderHeader: () => (
      <StyledHeader label="Créé le" />
    ),
    renderCell: (params) => (
      <Typography className={classes.text}>
        {convertDate(
          new Date(params.row.insertion_date),
          DATE_NUMBERS_HOURS,
        )}
      </Typography>
    ),
    width: 170,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    width: 170,
    align: 'right',
    renderCell: (params) => (
      <ProjectsDatagridActions
        project={params.row}
        isManager={
          params.row.project_manager !== undefined
          && params.row.project_manager !== null
          && currentUser?.id === params.row.project_manager.id
        }
        isDircli={
          currentUser?.user_type_id === USER_TYPES.dircli.id
        }
        userId={currentUser?.id || 0}
        currentUser={currentUser}
      />
    ),
  },
]);

export default createProjectColumns;
