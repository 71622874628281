import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import { useAppSelector } from 'common/reduxHooks';
import ListsContainer from 'features/dataFiles/list/ContainerLists';
import { selectProjectById } from 'features/projects/state/slice';
import { splitIdName } from 'utils/urlManipulation';

function SetupDataFiles() {
  const { idNameProject } = useParams();
  const { id: idProject } = splitIdName(idNameProject);

  const project = useAppSelector((state) => (
    selectProjectById(state.projects, idProject)
  ));

  const scopeId = project?.scopes[0].id ? project?.scopes[0].id : 0;

  useDocumentTitle(`${project?.name} : importer des fichiers CSV | benhur`);

  return (
    <Box>
      <Typography variant="h1">
        Importez des nouvelles données de visibilité dans le projet
      </Typography>
      <ListsContainer scopeId={scopeId} project={project} />
    </Box>
  );
}

export default SetupDataFiles;
