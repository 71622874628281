import React, { useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import {
  Stack,
  styled,
  StackProps,
  Typography,
} from '@mui/material';

import { useAppSelector } from 'common/reduxHooks';

import { selectAnalysisWithLeastKeywords } from '../state/slice';
import { isBudgetEnoughForSerpAnalysis } from '../utils/serpAnalysis';

const PREFIX = 'SettingBlockView';

const classes = {
  iconSmall: `${PREFIX}-iconSmall`,
  status: `${PREFIX}-status-text`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`& .${classes.iconSmall}`]: {
    width: '20px',
    marginRight: '5px',
  },

  [`& .${classes.status}`]: {
    fontSize: theme.fontSize.xsmall,
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    alignItems: 'center',
  },
}));

type BudgetCheckerProps = {
  keywordPrice: number,
  projectBudget: number,
}

function BudgetChecker (props: BudgetCheckerProps) {
  const { keywordPrice, projectBudget } = props;
  const [isBudgetEnough, setIsBudgetEnough] = useState(false);

  const analysisWithLeastKeywords = useAppSelector(
    (state) => selectAnalysisWithLeastKeywords(state.serpAnalyses),
  );

  useEffect(() => {
    setIsBudgetEnough(isBudgetEnoughForSerpAnalysis(
      projectBudget,
      keywordPrice,
      analysisWithLeastKeywords?.keywords_count ?? 0,
      analysisWithLeastKeywords?.device_type_id ?? 0,
    ));
  }, [keywordPrice, projectBudget, analysisWithLeastKeywords]);

  return (
    <StyledStack width="100%">
      {(
        isBudgetEnough && (
        <Typography color="green" className={classes.status}>
          <CheckCircleIcon className={classes.iconSmall} />
          Budget suffisant
        </Typography>
        )
      ) || (
        <Typography color="red" className={classes.status}>
          <DangerousIcon className={classes.iconSmall} />
          Budget insuffisant
        </Typography>
      )}
    </StyledStack>
  );
}

export default BudgetChecker;
