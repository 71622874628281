import React from 'react';

import {
  TextField,
  Box,
  styled,
  BoxProps,
} from '@mui/material';
import { FormikProps, FormikProvider } from 'formik';

import { AddCatGroupForm } from './addCatGroupConfig';

const PREFIX = 'AddCatGroupModal';

const classes = {
  input: `${PREFIX}-input`,
  inputContent: `${PREFIX}-inputContent`,
  box: `${PREFIX}-box`,
  rename: `${PREFIX}-rename`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.box}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },

  [`& .${classes.input}`]: {
    width: '100%',
  },

  [`& .${classes.inputContent}`]: {
    fontSize: theme.fontSize.medium,
  },

}));

type FormAddCategoryGroupsProps = {
  formik: FormikProps<AddCatGroupForm>,
}

function AddCatGroupModal(props: FormAddCategoryGroupsProps) {
  const {
    formik,
  } = props;
  const { handleChange } = formik;

  return (
    <StyledBox
      className={classes.box}
    >
      <FormikProvider value={formik}>
        <TextField
          margin="normal"
          id="name"
          label="Nom du groupe"
          type="text"
          variant="outlined"
          size="small"
          name="name"
          onChange={handleChange}
          value={formik.values.name}
          className={classes.input}
          slotProps={{
            input: {
              className: classes.inputContent,
            },
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.name
            && Boolean(formik.errors.name)
          }
          helperText={
            formik.touched.name
            && formik.errors.name
          }
        />
      </FormikProvider>
    </StyledBox>

  );
}

export default AddCatGroupModal;
