import React, { useCallback } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Box,
  Typography,
  Stack,
  styled,
  BoxProps,
  CircularProgress,
  IconButton,
} from '@mui/material';

import { ActorType } from 'features/actors/types';
import useCurrentUser from 'features/authentication/state/useCurrentUser';

import { KPI_VIEW_TYPE } from '../CONSTANTS';
import KpiViewActions from './actions/KpiViewActions';
import ModifyViewModal from './actions/ModifyView';
import useKpiViewByAnalysis from '../state/useKpiViewByAnalysis';
import { KpiViewType } from '../types';
import formatExportPath from '../utils/format';

const PREFIX = 'ViewsList';

const classes = {
  root: `${PREFIX}-root`,
  loading: `${PREFIX}-loading`,
  line: `${PREFIX}-line`,
  clipBoard: `${PREFIX}-clipBoard`,
  iconClipBoard: `${PREFIX}-iconClipBoard`,
  noView: `${PREFIX}-noView`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    maxHeight: '250px',
    overflow: 'auto',
    marginTop: '10px',
  },

  [`& .${classes.loading}`]: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '240px',
  },

  [`& .${classes.line}`]: {
    width: '100%',
    borderTop: '1px solid',
    borderColor: theme.palette.grey[300],
    padding: '5px',
    alignItems: 'center',
  },

  [`& .${classes.clipBoard}`]: {
    color: theme.palette.primary.light,
    fontSize: theme.fontSize.mediumLarge,
  },

  [`& .${classes.iconClipBoard}`]: {
    color: theme.palette.primary.light,
    fontSize: theme.fontSize.mediumLarge,
  },

  [`& .${classes.noView}`]: {
    width: '100%',
    textAlign: 'center',
    padding: '5px',
    fontSize: theme.fontSize.xsmall,
  },
}));

interface ViewsListProps {
  idAnalysis: number,
  idProject: number,
  actors: ActorType[],
  intentList: any,
  scopeId: number,
  analysisDeviceTypeId: number,
  onKpiViewSelected?: (kpiView: KpiViewType) => void,
}

function ViewsList(props: ViewsListProps) {
  const {
    idAnalysis,
    idProject,
    actors,
    intentList,
    scopeId,
    analysisDeviceTypeId,
    onKpiViewSelected,
  } = props;
  const { kpiViews, isLoading } = useKpiViewByAnalysis(
    scopeId,
    idAnalysis,
    [KPI_VIEW_TYPE.tracking.id, KPI_VIEW_TYPE.serpTracking.id],
  );

  const currentUser = useCurrentUser();

  const sortedKpiViews = kpiViews;
  sortedKpiViews.sort((a, b) => (
    a.label.localeCompare(b.label)
  ));
  const copyToClipboard = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
  };

  const ModifyViewModalComponent = useCallback((
    handleCloseMenu: Function,
    kv: KpiViewType,
    disabled: boolean,
  ) => (
    <ModifyViewModal
      actors={actors}
      kpiView={kv}
      intentList={intentList}
      analysisId={idAnalysis}
      scopeId={scopeId}
      analysisDeviceTypeId={analysisDeviceTypeId}
      handleCloseMenu={handleCloseMenu}
      isCurrentUserExternal={currentUser?.isExternal()}
      disabled={disabled}
    />
  ), [kpiViews]);

  return (
    <StyledBox className={classes.root}>
      {
        (
          isLoading
          && <Stack className={classes.loading}><CircularProgress /></Stack>
        )
        || (
          sortedKpiViews.length
            ? (
              <>
                {
                  sortedKpiViews.map((kv) => (
                    <Stack className={classes.line} direction="row" spacing={2} key={kv.id}>
                      <Typography variant="body3" flex={1}>
                        {kv.label}
                      </Typography>
                      <Stack direction="row" flex={3} alignItems="center">
                        {
                          kv.kpi_view_export_id === 2
                          && (
                            <>
                              <IconButton
                                onClick={
                                  () => copyToClipboard(
                                    formatExportPath(
                                      idProject,
                                      kv.scope_id,
                                      kv.id,
                                      {
                                        type: kv.kpi_view_type_id === KPI_VIEW_TYPE.tracking.id
                                          ? KPI_VIEW_TYPE.tracking.label
                                          : KPI_VIEW_TYPE.serpTracking.label,
                                        serpAnalysisId: idAnalysis,
                                      },
                                    ),
                                  )
                                }
                                className={classes.clipBoard}
                                color="primary"
                              >
                                <ContentCopyIcon className={classes.iconClipBoard} />
                              </IconButton>
                              <Typography variant="body3">
                                {
                                  kv.kpi_view_export_id === 2
                                  && formatExportPath(
                                    idProject,
                                    kv.scope_id,
                                    kv.id,
                                    {
                                      type: kv.kpi_view_type_id === KPI_VIEW_TYPE.tracking.id
                                        ? KPI_VIEW_TYPE.tracking.label
                                        : KPI_VIEW_TYPE.serpTracking.label,
                                      serpAnalysisId: idAnalysis,
                                    },
                                  )
                                }
                              </Typography>
                            </>
                          )
                        }
                      </Stack>
                      <Box flex={0.5} textAlign="right">
                        <KpiViewActions
                          view={kv}
                          extraActions={(handleCloseMenu: Function) => (
                            ModifyViewModalComponent(
                              handleCloseMenu,
                              kv,
                              !!currentUser?.isExternal(),
                            )
                          )}
                          hideDownload
                          onKpiViewLoad={onKpiViewSelected}
                          disabledEditActions={currentUser?.isExternal()}
                        />
                      </Box>
                    </Stack>
                  ))
                }
                <Box className={classes.line} />
              </>
            )
            : (
              <>
                <Box className={classes.line}>
                  <Typography className={classes.noView} variant="body2">
                    Aucune vue
                  </Typography>
                </Box>
                <Box className={classes.line} />
              </>
            )
        )
      }
    </StyledBox>
  );
}

export default ViewsList;
