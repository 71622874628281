import globalTheme from 'common/theme';

import { PATTERN_START_TYPES } from '../CONSTANTS';

const getActorColor = (isClient: boolean, color?: string | null) => color
?? (
  isClient
    ? globalTheme.palette.secondary.main
    : globalTheme.palette.primary.light
);

export const formatUrlDomain = (url: string, startType: number) => {
  const urlStart = startType === PATTERN_START_TYPES.include_subdomains
    ? '(.*).'
    : 'http(s)://';

  return `${urlStart}${url}(.*)`;
};

export default getActorColor;
