import React from 'react';

import { Box, Typography } from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import MainBox from 'components/layout/MainBox';
import { Project } from 'features/projects/types';
import ProjectTransactionsDataGrid
  from 'features/projectTransaction/list/ProjectTransactionsDataGrid';

type ProjectTransactionsProps = {
  project?: Project,
}

function ProjectTransactions(props: ProjectTransactionsProps) {
  const { project } = props;

  useDocumentTitle(`${project?.name} : suivi de consommation | benhur`);

  return (
    <Box>
      <Typography variant="h1">
        Suivi de consommation
      </Typography>
      <MainBox>
        <ProjectTransactionsDataGrid
          project={project}
        />
      </MainBox>
    </Box>
  );
}

export default ProjectTransactions;
