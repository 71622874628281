import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import {
  ActorForm,
} from 'features/actors/types';
import REGEX_LABEL, { REGEX_COLOR_HEX } from 'utils/formControl';

const DEFAULT_ACTOR: ActorForm = {
  key: '',
  name: '',
  domain: '',
  isClient: false,
  subdomains: false,
  exclude: false,
  datavizColor: null,
  datavizColorDefault: true,
};

export function newActor(): ActorForm {
  return {
    ...DEFAULT_ACTOR,
    key: uuidv4(),
  };
}

export const initialValues: ActorForm = {
  ...newActor(),
};

export const initValuesAdd = (actorName: string, subdomains: boolean) => ({
  key: '',
  name: actorName,
  domain: '',
  isClient: false,
  subdomains,
  exclude: false,
  datavizColor: null,
  datavizColorDefault: true,
});

export const initValuesUpdate = (pattern: ActorForm) => ({
  key: pattern.key,
  name: pattern.name,
  domain: pattern.domain,
  isClient: pattern.isClient,
  subdomains: pattern.subdomains,
  exclude: pattern.exclude,
  datavizColor: pattern.datavizColor,
  datavizColorDefault: pattern.datavizColorDefault,
});

export function checkUrlFormat(line: string | undefined): boolean {
  return line !== ''
  && Boolean(
    // eslint-disable-next-line max-len
    line?.match(/^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/),
  );
}

export const validationSchema = yup.object().shape({
  key: yup.string(),
  name: yup.string().required('Ce champ est obligatoire').trim().matches(
    REGEX_LABEL,
    'Au moins une lettre ou un chiffre requis',
  ),
  domain: yup.string().required('Ce champ est obligatoire').trim().test(
    'check-url',
    'Url valide requise',
    (value) => checkUrlFormat(value),
  ),
  isClient: yup.boolean().required('Ce champ est obligatoire'),
  subdomains: yup.boolean().required('Ce champ est obligatoire'),
  exclude: yup.boolean().required('Ce champ est obligatoire'),
  datavizColor: yup.string().nullable().optional().trim()
    .matches(
      REGEX_COLOR_HEX,
      'Le code couleur sélectionnée n\'est pas valide',
    ),
});

export const updateActorNameValidationSchema = yup.object().shape({
  actorId: yup.number()
    .required('Merci de sélectionner un acteur')
    .moreThan(0, 'Merci de sélectionner un acteur'),
  name: yup.string()
    .trim()
    .required('Ce champ est obligatoire')
    .max(100, '100 caractères maximum'),
});
