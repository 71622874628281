import { ActorType } from 'features/actors/types';
import { RankSliceType } from 'features/rankSlice/types';

export const getActorNameFromActorId = (ids: number[], actors: ActorType[]) => (
  ids.map(
    (id) => actors?.find((actor) => actor.id === id)?.name,
  ).filter((name) => name)
);

export const getSliceNameFromSliceId = (ids: number[], rankSlices: RankSliceType[]) => ids.sort(
  (a: number, b: number) => a - b,
).map(
  (id) => rankSlices?.find((slice) => slice.id === id)?.label,
).filter((label) => label);

export const getIntentLabelFromIntentId = (
  ids: number[],
  intents: Array<{
    id: number,
    label: string,
  }>,
) => ids.sort(
  (a: number, b: number) => a - b,
).map(
  (id) => intents?.find((intent) => intent.id === id)?.label,
).filter((label) => label);
