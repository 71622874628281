import React from 'react';

import {
  TextField,
  Stack,
  Button,
  styled,
  StackProps,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useFormik } from 'formik';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import CustomAlert from 'components/alert/CustomAlert';
import { HOME, PROJECTS } from 'router/CONSTANTS';

import LoginAskPasswordModal from './LoginAskPasswordModal';
import loginPasswordFormValidationSchema from './setupConfig';
import { fetchLoginPassword, loginResetError } from '../state/slice';
import { LoginPasswordFormType } from '../types';

type LoginPasswordFormProps = {
  email: string;
  onCancel: Function;
};

const PREFIX = 'LoginPasswordForm';

const classes = {
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  text: `${PREFIX}-text`,
  spinner: `${PREFIX}-spinner`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`& .${classes.input}`]: {
    fontSize: theme.fontSize.medium,
  },

  [`& .${classes.button}`]: {
    fontSize: theme.fontSize.medium,
    height: '36px',
    width: '120px',
  },

  [`& .${classes.text}`]: {
    fontSize: theme.fontSize.medium,
  },

  [`&.${classes.spinner}`]: {
    color: theme.palette.primary.light,
  },
}));

function LoginPasswordForm(props: LoginPasswordFormProps) {
  const { email, onCancel } = props;

  const dispatch = useAppDispatch();
  const {
    isLoading,
    error,
  } = useAppSelector(
    (state) => state.authentication,
  );

  const handleSubmit = (values: LoginPasswordFormType) => {
    dispatch(fetchLoginPassword({
      payload: {
        username: values.email,
        password: values.password,
      },
      callbackFailureUrl: HOME,
      callbackSuccessUrl: PROJECTS,
    }));
  };

  const formik = useFormik<LoginPasswordFormType>({
    initialValues: { email, password: '' },
    onSubmit: handleSubmit,
    validationSchema: loginPasswordFormValidationSchema,
  });

  const handleCancel = () => {
    dispatch(loginResetError());
    onCancel();
  };

  return (
    <StyledStack
      direction="column"
      alignItems="center"
      spacing={2}
      component="form"
      onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(); }}
      data-testid="login-password-form"
    >
      <Typography className={classes.text}>
        Saisir votre
        <Typography className={classes.text} component="span" variant="bold">
          {' '}
          mot de passe
        </Typography>
      </Typography>
      <TextField
        name="email"
        label="Email"
        type="email"
        slotProps={{
          input: {
            autoComplete: 'username',
            className: classes.input,
          },
        }}
        size="small"
        variant="outlined"
        fullWidth
        value={formik.values.email}
        disabled
        data-testid="login-email-text-field"
      />
      <TextField
        name="password"
        label="Mot de passe"
        type="password"
        slotProps={{
          input: {
            autoComplete: 'password',
            className: classes.input,
          },
        }}
        size="small"
        variant="outlined"
        fullWidth
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && !!formik.errors.password}
        helperText={formik.touched.password && formik.errors.password}
        autoFocus
        data-testid="login-pasword-text-field"
      />
      {error && (
        <CustomAlert severity="error" fullWidth>
          <Typography className={classes.text}>
            Email ou mot de passe incorrect
          </Typography>
        </CustomAlert>
      )}
      <Stack direction="row" spacing={1}>
        <Button
          type="submit"
          variant="contained"
          size="small"
          className={classes.button}
          disabled={isLoading}
          data-testid="login-password-form-confirm-button"
        >
          {isLoading
            ? <CircularProgress className={classes.spinner} size={25} />
            : 'Se connecter'}
        </Button>
        <Button
          variant="contained"
          color="info"
          className={classes.button}
          onClick={handleCancel}
          disabled={isLoading}
          data-testid="login-password-form-cancel-button"
        >
          Annuler
        </Button>
      </Stack>
      <LoginAskPasswordModal />
    </StyledStack>
  );
}

export default LoginPasswordForm;
