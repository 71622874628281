import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import { RootState } from 'common/store';

import {
  createKpiViewRequested,
  fetchKpiViewRequested,
  updateKpiViewRequested,
  deleteKpiViewRequested,
  selectKpiViewByScopeAndType,
} from './slice';
import { KpiViewCreateUpdate, KpiViewType } from '../types';

const useKpiViewByType = (idScope: number, idTypes: number[]) => {
  const dispatch = useAppDispatch();

  const { isLoading, error } = useAppSelector(
    (state: RootState) => state.kpiViews,
  );

  const kpiViews: KpiViewType[] = useAppSelector(
    (state) => selectKpiViewByScopeAndType(state.kpiViews, idScope, idTypes),
  );

  useEffect(() => {
    if (idScope && !isLoading && kpiViews.length === 0) {
      dispatch(fetchKpiViewRequested(idScope));
    }
  }, [dispatch, fetchKpiViewRequested, idScope]);

  const createKpiView = (newView: KpiViewCreateUpdate) => {
    dispatch(createKpiViewRequested(newView));
  };

  const updateKpiView = (viewId: number, newView: KpiViewCreateUpdate) => {
    dispatch(updateKpiViewRequested({
      viewId,
      view: newView,
    }));
  };

  const deleteKpiView = (viewId: number, deleteFiles: boolean) => {
    const payload = {
      viewId,
      deleteFiles,
    };
    dispatch(deleteKpiViewRequested(payload));
  };

  return {
    isLoading,
    error,
    kpiViews,
    createKpiView,
    updateKpiView,
    deleteKpiView,
  };
};

export default useKpiViewByType;
