import React from 'react';

import {
  Box,
  styled,
  Typography,
} from '@mui/material';
import { BoxProps } from '@mui/system';

import { wrongHeaders } from './config';

const PREFIX = 'ModalErrorMessage';

const classes = {
  box: `${PREFIX}-box`,
  label: `${PREFIX}-label`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.box}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: theme.fontSize.xsmall,
  },

  [`& .${classes.label}`]: {
    fontSize: theme.fontSize.xsmall,
  },
}));

type ModalErrorProps = {
  error: string,
}

function ModalErrorMessage(props: ModalErrorProps) {
  const { error } = props;

  const headersError = wrongHeaders === error;

  return (
    <StyledBox data-testid="modal-add-serpanalysis-error" className={classes.box}>
      <Typography className={classes.label}>
        {(headersError
          && 'Merci d\'utiliser l\'exemple fourni pour structurer l\'étude de mots clés'
        ) || (
          'Aucune donnée n\'a été ajoutée ou supprimée.'
        )}
      </Typography>
    </StyledBox>
  );
}

export default ModalErrorMessage;
