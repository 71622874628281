import React from 'react';

import { Typography, TypographyProps, styled } from '@mui/material';

const PREFIX = 'CreateSaModalTitle';

const classes = {
  title: `${PREFIX}-title`,
};

const StyledTypo = styled(Typography)<TypographyProps>(({ theme }) => ({
  [`&.${classes.title}`]: {
    fontSize: theme.fontSize.mediumLarge,
  },
}));

export default function AddSaModalTitle() {
  return (
    <StyledTypo className={classes.title}>
      <Typography component="span" variant="bold" className={classes.title}>
        Ajouter manuellement
      </Typography>
      {' '}
      un ou plusieurs mots clés
    </StyledTypo>
  );
}
