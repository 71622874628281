import React, { useEffect, useState } from 'react';

import { MenuItem, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import ValidateActionMessage from 'components/modal/ValidateActionMessage';

import { DATA_FILE_STATUS } from '../CONSTANT';
import { updateDataFiles } from '../state/slice';

type DeleteDataFileProps = {
  dataFileId: number,
  handleCloseMenu: Function,
}

export default function DeleteDataFile(props: DeleteDataFileProps): JSX.Element {
  const {
    dataFileId,
    handleCloseMenu,
  } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(
    (state) => state.dataFiles,
  );

  useEffect(() => {
    if (showLoading && !isLoading) {
      setShowLoading(false);
      setConfirmationModalOpen(true);
    }
  }, [isLoading]);

  const handleConfirm = () => {
    setShowLoading(true);
    dispatch(
      updateDataFiles(
        {
          dataFiles: [
            {
              id: dataFileId,
              data_file_status_id: DATA_FILE_STATUS.to_delete,
            },
          ],
        },
      ),
    );
  };

  const handleClose = () => {
    setOpenModal(false);
    setConfirmationModalOpen(false);
  };

  return (
    <MenuItem
      sx={{ fontSize: globalTheme.fontSize.middlesmall }}
      onClick={() => { if (!openModal) setOpenModal(true); handleCloseMenu(); }}
      data-testid="delete-data-file-menu-item"
    >
      Supprimer
      {
        confirmationModalOpen
          ? (
            <Modal
              title={<ValidateActionMessage />}
              displayCancel={false}
              actionConfirm={handleClose}
              isOpen={confirmationModalOpen}
              dataTestId="delete-data-file-delete-confirm-modal"
            />
          )
          : (
            <Modal
              title={(
                <Typography>
                  <Typography component="span" variant="bold">
                    Supprimer le fichier
                  </Typography>
                  {' '}
                  et les données associées ?
                </Typography>
              )}
              actionConfirm={handleConfirm}
              actionCancel={() => setOpenModal(false)}
              isOpen={openModal}
              isProcessLoading={isLoading}
              displayLoading={showLoading}
              dataTestId="delete-data-file-delete-modal"
            />
          )
      }
    </MenuItem>
  );
}
