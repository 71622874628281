import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import ApiPeriod from './api';
import {
  fetchPeriodByScopeAndDataSource,
  fetchPeriodByScopeAndDataSourceSuccess,
  fetchPeriodByScopeAndDataSourceFailure,
} from './slice';
import {
  PeriodFetchByDataSourceIdsPayload, PeriodValuesStateType,
} from '../types';

function* workFetchPeriodByScopeAndDataSource(
  action: PayloadAction<PeriodFetchByDataSourceIdsPayload>,
) {
  try {
    const response: AxiosResponse = yield call(
      ApiPeriod.fetchPeriodByDataSource,
      action.payload.scopeId,
      action.payload.dataSourceIds,
    );

    const data: PeriodValuesStateType = {
      scopeId: action.payload.scopeId,
      dataSourceIds: action.payload.dataSourceIds,
      values: response.data.values.map((value: any) => ({ period: value.periode })),
    };

    yield put(fetchPeriodByScopeAndDataSourceSuccess(data));
  } catch (e: any) {
    yield put(fetchPeriodByScopeAndDataSourceFailure(e.message));
  }
}

function* sagaFetchPeriodByScopeAndDataSource() {
  yield takeEvery(
    fetchPeriodByScopeAndDataSource.type,
    workFetchPeriodByScopeAndDataSource,
  );
}

export default sagaFetchPeriodByScopeAndDataSource;
