import React, { useEffect, useState } from 'react';

import {
  Stack,
  Typography,
  styled,
  StackProps,
} from '@mui/material';

import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';
import DatavizContainer from 'features/dataviz/components/generic/DatavizContainer';
import CountWithScraps from 'features/dataviz/components/keyword/CountWithScraps';
import { METRIC } from 'features/dataviz/CONSTANTS';
import datavizConfig from 'features/dataviz/datavizConfig';
import { Project } from 'features/projects/types';
import PlanScrapping from 'features/serpAnalysis/components/modals/PlanScrapping';
import { Analysis } from 'features/serpAnalysis/types';
import getNextScrapDate, {
  isBudgetEnoughForSerpAnalysis,
} from 'features/serpAnalysis/utils/serpAnalysis';
import SerpAnalysisScrapping from 'features/serpAnalysisScrapping/types';

import DevicePerimeter from './DevicePerimeter';
import SelectAnalysis from './SelectAnalysis';

const PREFIX = 'DSBlockView';

const classes = {
  core: `${PREFIX}-core`,
  content: `${PREFIX}-content`,
  form: `${PREFIX}-form`,
  select: `${PREFIX}-select`,
  text: `${PREFIX}-text`,
  date: `${PREFIX}-date`,
  fullWidth: `${PREFIX}-fullWidth`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },

  [`& .${classes.content}`]: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },

  [`& .${classes.form}`]: {
    minWidth: 120,
  },

  [`& .${classes.select}`]: {
    fontSize: theme.fontSize.xsmall,
  },

  [`& .${classes.text}`]: {
    fontSize: theme.fontSize.xsmall,
    width: '40%',
  },

  [`& .${classes.date}`]: {
    fontSize: theme.fontSize.xsmall,
    fontWeight: 'bold',
    display: 'block',
  },

  [`& .${classes.fullWidth}`]: {
    width: '100%',
  },
}));

type DSBlockProps = {
  scrappingsSorted: SerpAnalysisScrapping[],
  scrappingSelected: SerpAnalysisScrapping | undefined,
  baseUrl: string,
  analysis: Analysis,
  project: Project | undefined,
  isRecalculatingKPIs: boolean,
  scopeId: number | null,
  isLoading: boolean,
  blockHeight: number,
}

function DSBlock(props: DSBlockProps) {
  const {
    scrappingsSorted,
    scrappingSelected,
    baseUrl,
    analysis,
    project,
    scopeId,
    isRecalculatingKPIs,
    isLoading,
    blockHeight,
  } = props;

  const [isProjectBudgetEnough, setIsProjectBudgetEnough] = useState(false);

  useEffect(() => {
    if (project) {
      const isBudgetEnoughForSerp = isBudgetEnoughForSerpAnalysis(
        parseFloat(project.project_budget),
        parseFloat(project.keyword_price),
        analysis.keywords_count,
        analysis.device_type_id,
      );
      setIsProjectBudgetEnough(isBudgetEnoughForSerp);
    }
  }, [project]);

  return (
    <StyledStack
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      height={blockHeight}
    >
      <TitleComponent
        title="Sources des données"
        capitalLetter
      />
      <Stack
        direction="column"
        spacing={2}
        className={classes.content}
        justifyContent="space-between"
        height="100%"
      >
        <SelectAnalysis
          scrappingsSorted={scrappingsSorted}
          scrappingSelected={scrappingSelected}
          baseUrl={baseUrl}
          analysisDevice={analysis.device_type_id}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          className={classes.fullWidth}
        >
          {(scrappingsSorted.length !== 0 && (
            <DatavizContainer
              idScope={scopeId || 0}
              datavizComp={datavizConfig[METRIC.count_with_scrapped.label]}
              idSerpAnalysis={analysis.id}
              serpAnalysisStatus={analysis.serp_analysis_status_id}
              periode={scrappingSelected?.scrapping_date || ''}
              scrappingId={scrappingSelected?.id}
              title=""
              isPageLoading={isLoading}
              height=""
              project={project}
              analysis={analysis}
            />
          )) || (
            <CountWithScraps
              title=""
              chartKey=""
              rankingTypeIds={[]}
              datas={{
                isLoading: false,
                error: '',
                data: {
                  values: [{
                    scrapped_keywords: 0,
                    keywords: analysis.keywords_count,
                  }],
                },
              }}
              isPageLoading={false}
              analysis={analysis}
              project={project}
            />
          )}
          <Typography className={classes.text}>
            Prochaine analyse
            <Typography className={classes.date} component="span">
              {
                getNextScrapDate(
                  analysis.last_scrapping,
                  analysis.planning_date,
                  analysis.serp_analysis_planning_id,
                  analysis.planning_delay,
                  isProjectBudgetEnough,
                )
              }
            </Typography>
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="stretch"
          justifyContent="space-between"
          width="100%"
          maxWidth="500px"
        >
          <DevicePerimeter
            index={analysis?.serp_analysis_index}
            deviceTypeId={analysis.device_type_id}
            searchEngineId={analysis.search_engine_id}
          />
          <PlanScrapping
            ids={[analysis.id]}
            isButton
            hasIcon={false}
            scopeId={scopeId || 0}
            projectId={project?.id || 0}
            projectName={project?.name || ''}
            isRecalculatingKPIs={isRecalculatingKPIs}
            isProjectBudgetEnough={isProjectBudgetEnough}
            planningChoice="serpManual"
          />
        </Stack>
      </Stack>
    </StyledStack>
  );
}

export default DSBlock;
