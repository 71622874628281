import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  Box,
  Typography,
} from '@mui/material';

import CustomAlert from 'components/alert/CustomAlert';
import { Project } from 'features/projects/types';
import { generateProjectBaseUrl } from 'utils/urlManipulation';

import useActors from '../state/useActors';

type AlertNoActorsProps = {
  scopeId: number,
  project?: Project,
}

function AlertNoActors(props: AlertNoActorsProps) {
  const {
    scopeId,
    project,
  } = props;
  const { actors, isLoading } = useActors({ scopeId });

  const projectUrl = project ? generateProjectBaseUrl(project.id, project.name) : '';

  return (
    !actors.length && !isLoading && project?.id && (
      <Box marginBottom={2}>
        <CustomAlert severity="error">
          <Typography variant="body2">
            Vous ne pouvez pas effectuer d’analyse
            tant qu’aucun acteur n’est paramétré -
            {' '}
            <NavLink to={`${projectUrl}setting/competitor`}>Paramétrer</NavLink>
          </Typography>
        </CustomAlert>
      </Box>
    )
  );
}

export default AlertNoActors;
