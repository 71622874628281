import React from 'react';

import {
  Stack,
  StackProps,
  styled,
  Typography,
} from '@mui/material';

import { getFirstScrappingDate } from 'features/serpAnalysisScrapping/utils';
import { convertDate, DATE_NO_HOUR } from 'utils/formatDate';

import useSerpAnalysisScrappings from '../state/useSerpAnalysisScrappings';

const PREFIX = 'AvailableDataDate';

const classes = {
  footerText: `${PREFIX}-footerText`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({

  [`& .${classes.footerText}`]: {
    fontSize: theme.fontSize.xsmall,
    color: theme.palette.info.main,
  },
}));

interface AvailableDataDateProps {
  idAnalysis: number,
}

function AvailableDataDate (props: AvailableDataDateProps) {
  const { idAnalysis } = props;

  const { scrappings: serpAnalysisScrappings } = useSerpAnalysisScrappings(idAnalysis);
  return (
    <StyledStack>
      {(serpAnalysisScrappings && serpAnalysisScrappings.length > 0)
        ? (
          <Typography className={classes.footerText}>
            Données disponibles à partir du
            {' '}
            {
              convertDate(
                new Date(getFirstScrappingDate(serpAnalysisScrappings)),
                DATE_NO_HOUR,
              )
            }
          </Typography>
        )
        : (
          <Typography className={classes.footerText}>
            Aucune données disponibles
          </Typography>
        )}
    </StyledStack>
  );
}

export default AvailableDataDate;
