import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  BoxProps,
  styled,
  Typography,
} from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import { useAppSelector } from 'common/reduxHooks';
import MainBox from 'components/layout/MainBox';
import ProjectsDatagrid from 'features/projects/list/ProjectsDatagrid';
import { selectProjectByUser } from 'features/projects/state/slice';
import { generateProjectBaseUrl } from 'utils/urlManipulation';

const PREFIX = 'ProjectListView';

const classes = {
  core: `${PREFIX}-core`,
  lineContent: `${PREFIX}-lineContent`,
  withoutBreadcrumb: `${PREFIX}-withoutBreadcrumb`,
  listElement: `${PREFIX}-listElement`,
  listElementContent: `${PREFIX}-listElementContent`,
  projectName: `${PREFIX}-projectName`,
  iconAction: `${PREFIX}-iconAction`,
  manager: `${PREFIX}-manager`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    width: '100%',
  },

  [`& .${classes.lineContent}`]: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    position: 'relative',
    marginTop: theme.spacing(2),
  },

  [`& .${classes.withoutBreadcrumb}`]: {
    marginBottom: '10px',
  },

  [`& .${classes.listElement}`]: {
    width: '40%',
    marginTop: '4px',
    height: '45px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.info.light,
    paddingLeft: '10px',
    marginRight: '10px',
  },

  [`& .${classes.projectName}`]: {
    color: theme.palette.info.dark,
    fontSize: theme.fontSize.medium,
  },

  [`& .${classes.projectName}:hover`]: {
    color: theme.palette.primary.light,
  },

  [`& .${classes.listElementContent}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyCcontent: 'center',
  },

  [`& .${classes.iconAction}`]: {
    color: theme.palette.primary.light,
  },
  [`& .${classes.manager}`]: {
    outline: '2px solid',
    outlineColor: theme.palette.primary.light,
    outlineOffset: '-4px',
  },
}));

function ProjectsList() {
  useDocumentTitle('benhur : pilotez votre visibilité par les SERP Google');

  const navigate = useNavigate();

  const { currentUser } = useAppSelector(
    (state) => state.authentication,
  );

  const projects = useAppSelector(
    ((state) => selectProjectByUser(state.projects, currentUser)),
  );

  useEffect(() => {
    if (currentUser && currentUser.user_type.is_external && projects.length > 0) {
      navigate(generateProjectBaseUrl(projects[0].id, projects[0].name));
    }
  }, [currentUser, projects]);

  return (
    (
      currentUser && !currentUser.user_type.is_external && (
        <StyledBox className={classes.core}>
          <Typography variant="h1">
            Contrôlez la visibilité de vos projets avec Benhur
          </Typography>
          <MainBox>
            <ProjectsDatagrid />
          </MainBox>
        </StyledBox>
      )
    )
    || <Box />
  );
}

export default ProjectsList;
