import React from 'react';

import { FormikProps, useFormik } from 'formik';
import moment from 'moment';

import { useAppDispatch } from 'common/reduxHooks';
import useFiscalYears from 'features/fiscalYears/state/useFiscalYears';
import { createProject } from 'features/projects/state/slice';
import { ProjectCreateWithScope } from 'features/projects/types';
import { PROJECTS } from 'router/CONSTANTS';
import { convertDate, DATE_ENGLISH } from 'utils/formatDate';

import {
  formatProjectFormWithScope,
  ProjectForm,
  validationSchema,
  initialValues,
} from './CreateProjectConfig';
import CreateProjectView from './CreateProjectView';

function CreateProject() {
  const dispatch = useAppDispatch();
  const { fiscalYears } = useFiscalYears();

  const handleSubmit = (values: ProjectForm) => {
    const projectData: ProjectForm = initialValues;
    const currentYear = moment().year();
    const fiscalYear = fiscalYears.find((fy) => fy.year === currentYear);
    const beginDate = convertDate(moment().toDate(), DATE_ENGLISH);
    const endDate = convertDate(
      moment()
        .endOf('year')
        .toDate(),
      DATE_ENGLISH,
    );
    projectData.name = values.name;
    projectData.dateBegin = beginDate;
    projectData.dateEnd = endDate;
    projectData.fiscalYearId = fiscalYear?.id || 0;

    const payloadProject: ProjectCreateWithScope = formatProjectFormWithScope(projectData);

    dispatch(createProject({
      payload: payloadProject,
      callbackSuccessUrl: PROJECTS,
      callbackFailureUrl: '',
    }));
  };

  const formik: FormikProps<ProjectForm> = useFormik<ProjectForm>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <CreateProjectView
      formik={formik}
    />
  );
}

export default CreateProject;
