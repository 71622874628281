import React, { useState } from 'react';

import { Box, Typography, Button } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import SimulatorForm from 'features/simulator/SimulatorForm';
import useSimulator from 'features/simulator/useSimulator';
import isUserAdminOrDircli from 'utils/isUserAdminOrDircli';

import { creditProject } from '../state/slice';

type CreditProjectProps = {
  projectId: number,
  keywordPrice?: number,
}

function CreditProject ({ projectId, keywordPrice }: CreditProjectProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const simulator = useSimulator(keywordPrice);
  const dispatch = useAppDispatch();

  const { currentUser } = useAppSelector((state) => state.authentication);

  const handleSubmit = () => {
    dispatch(creditProject({
      id: projectId,
      payload: {
        label: 'credit',
        value: simulator.result!, // It is a number, otherwise the button is disabled
        keyword_price: simulator.ppk,
      },
    }));
    setIsOpen(false);
    simulator.resetFields();
  };

  return (
    <Box>
      {
        isUserAdminOrDircli(currentUser?.user_type_id || 0) && (
          <Button
            onClick={() => setIsOpen(true)}
            size="small"
            data-testid="credit-project-button"
            sx={{
              fontWeight: 'bold',
              color: globalTheme.palette.primary.light,
              fontSize: globalTheme.fontSize.medium,
            }}
          >
            Créditer
          </Button>
        )
      }
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">Créditer</Typography>
            {' '}
            le projet
          </Typography>
        )}
        actionConfirm={handleSubmit}
        disabledConfirm={simulator.isError()}
        actionCancel={() => {
          setIsOpen(false);
          simulator.resetFields();
        }}
        isOpen={isOpen}
        dataTestId="credit-project-modal"
      >
        <SimulatorForm
          simulator={simulator}
        />
      </Modal>
    </Box>
  );
}

export default CreditProject;
