export const CREATE_PROJECTS = 'create';
export const PROJECTS_PROJECT = ':idNameProject';
export const SETTING_PROJECT = ':idNameProject/setting';
export const SETTING_PROJECT_ACTOR = ':idNameProject/setting/competitor';
export const SETTING_PROJECT_UPLOAD = ':idNameProject/setting/upload';
export const SETTING_PROJECT_CATEGORIES = ':idNameProject/setting/url';
export const SETTING_PROJECT_CATEGORIES_VALUES = ':idNameProject/setting/url/:idCategoryGroup';
export const SETTING_PROJECT_OPTION = ':idNameProject/setting/option';
export const SETTING_PROJECT_BRAND = ':idNameProject/setting/brand';
export const SERP_ANALYSIS = ':idNameProject/';
export const ORGANIC = ':idNameProject/:idNameAnalysis';
export const ANALYSIS_DETAILS = ':idNameProject/:idNameAnalysis/:feature';
export const SERP_PHYSIONOMY = ':idNameProject/:idNameAnalysis/serp';
export const SHARE_OF_VOICE = ':idNameProject/:idNameAnalysis/share-of-voice';
export const RANKING = ':idNameProject/:idNameAnalysis/ranking';
export const KEYWORDS = ':idNameProject/:idNameAnalysis/keywords';
export const PERFORMANCE_PER_ACTOR = ':idNameProject/seo-performance';
export const PERFORMANCE_PER_URL = ':idNameProject/seo-performance-by-url';
export const PERFORMANCE_PER_SERP = ':idNameProject/seo-performance-by-serp';
export const PERFORMANCE_URL_INDEXABILITY = ':idNameProject/url-indexability';
export const PERFORMANCE_LOOKER_STUDIO = ':idNameProject/seo-performance-kpi';
export const PAGE_URL_LIST = ':idNameProject/url';
export const PROJECT_BUDGET = ':idNameProject/transactions';
