import * as yup from 'yup';

import REGEX_LABEL from 'utils/formControl';

import { CategoryGroup } from '../../types';

export interface UpdateCatGroupForm {
  id: number;
  name: string;
}

export const initValues = (catGroup: CategoryGroup): UpdateCatGroupForm => ({
  id: catGroup.id,
  name: catGroup.name,
});

export const formUpdateProjectToProject = (form: UpdateCatGroupForm) => ({
  payload: {
    name: form.name.trim(),
  },
  projectId: form.id,
});

export const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Ce champ est obligatoire').matches(
    REGEX_LABEL,
    'Au moins une lettre ou un chiffre requis',
  ),
});
