import React from 'react';

import LanguageIcon from '@mui/icons-material/Language';
import {
  Stack, Typography, styled, StackProps,
} from '@mui/material';

import DeviceIcon from 'features/deviceType/Icon';
import SearchEngineInfo from 'features/searchEngines/components/SearchEngineInfo';
import CountryFlag from 'features/serpAnalysisIndex/components/CountryFlag';
import { SerpAnalysisIndex } from 'features/serpAnalysisIndex/types';

const PREFIX = 'DevicePerimeterBlock';

const classes = {
  text: `${PREFIX}-text`,
  avatar: `${PREFIX}-avatar`,
  flag: `${PREFIX}-flag`,
  ctn: `${PREFIX}-ctn`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`& .${classes.text}`]: {
    fontSize: theme.fontSize.xsmall,
  },

  [`& .${classes.avatar}`]: {
    height: '15px',
    width: '15px',
  },

  [`& .${classes.flag}`]: {
    width: '19px',
    position: 'absolute',
  },

  [`& .${classes.ctn}`]: {
  },
}));

type DevicePerimeterProps = {
  index: SerpAnalysisIndex | undefined,
  deviceTypeId: number,
  searchEngineId: number,
}

function DevicePerimeter(props: DevicePerimeterProps) {
  const { index, deviceTypeId, searchEngineId } = props;

  return (
    <StyledStack
      direction="row"
      alignItems="stretch"
      justifyContent="space-between"
      spacing={2}
    >
      <SearchEngineInfo searchEngineId={searchEngineId} />
      <Stack direction="row" alignItems="center" gap={0.5}>
        <CountryFlag
          index={index}
          width={16}
          height={16}
        />
        <Typography className={classes.text}>
          {index ? index.country : 'France'}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={0.5}>
        <LanguageIcon className={classes.avatar} />
        <Typography className={classes.text}>
          {index ? index.language : 'Français'}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={0.5}>
        <DeviceIcon deviceTypeId={deviceTypeId} />
      </Stack>
    </StyledStack>
  );
}

export default DevicePerimeter;
