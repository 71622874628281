import React, { useState } from 'react';

import { Box, MenuItem, Typography } from '@mui/material';
import { FormikProps, useFormik } from 'formik';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import { updateProject } from 'features/projects/state/slice';
import { Project } from 'features/projects/types';

import {
  UpdateProjectForm,
  initValues,
  validationSchema,
  formUpdateProjectToProject,
} from './renameProjectConfig';
import EditProjectModal from './RenameProjectModal';

type EditProjectModalProps = {
  project: Project,
  handleCloseMenu: Function,
}

function RenameProject(props: EditProjectModalProps) {
  const { project, handleCloseMenu } = props;

  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSubmit = (values: UpdateProjectForm) => {
    const payloadUpdateProject = formUpdateProjectToProject(values);
    dispatch(updateProject(payloadUpdateProject));
    setIsOpen(false);
  };

  const formik: FormikProps<UpdateProjectForm> = useFormik<UpdateProjectForm>({
    initialValues: initValues(project),
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Box>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => { if (!isOpen) setIsOpen(true); handleCloseMenu(); }
        }
      >
        Renommer ce projet
      </MenuItem>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">
              Renommer
            </Typography>
            {' '}
            ce projet
          </Typography>
        )}
        actionConfirm={formik.handleSubmit}
        actionCancel={() => setIsOpen(false)}
        isOpen={isOpen}
      >
        <EditProjectModal
          formik={formik}
        />
      </Modal>
    </Box>
  );
}

export default RenameProject;
