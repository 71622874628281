import React, { useState } from 'react';

import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Button } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import {
  CATEGORY_VALUE_EXCLUDE,
  CATEGORY_VALUE_INCLUDE,
  CATEGORY_VALUE_TYPE,
  CATEGORY_VALUE_TYPE_CONTAINS,
} from 'features/categoryGroups/CONSTANTS';

import {
  selectCategoryValuesById,
  updateCategoryValueMultiple,
} from '../../state/slice';
import EditCategoryValueReachModal from '../components/EditCategoryValueMultipleModal';

type GlobalUpdateCategoryValueReachProps = {
  selection: number[],
  categoryGroupId: number,
  modalType: 'include' | 'category_value_type_id',
}

function GlobalUpdateCategoryValueReach(props: GlobalUpdateCategoryValueReachProps): JSX.Element {
  const {
    categoryGroupId,
    selection,
    modalType,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const defaultValue: number = modalType === 'include'
    ? CATEGORY_VALUE_INCLUDE
    : CATEGORY_VALUE_TYPE_CONTAINS;
  const [value, setValue] = useState<number>(defaultValue);

  const dispatch = useAppDispatch();
  const categoryGroups = useAppSelector((state) => state.categoryGroups);

  const handleConfirm = () => {
    const categoryValuesToUpdate = selectCategoryValuesById(
      categoryGroups,
      selection,
    );

    const payload = categoryValuesToUpdate.map((catVal) => ({
      ...catVal,
      [modalType]: value,
    }));

    if (categoryGroupId === 0) {
      setIsOpen(false);
      return;
    }

    const categoryIds = new Set(payload.map((catVal) => catVal.category_id));

    dispatch(updateCategoryValueMultiple({
      category_group_id: categoryGroupId,
      category_ids: [...categoryIds],
      category_values: payload,
    }));

    setIsOpen(false);
    setValue(defaultValue);
  };

  const optionsValueType = Object.keys(CATEGORY_VALUE_TYPE).map((key) => ({
    id: parseInt(key, 10),
    label: CATEGORY_VALUE_TYPE[key as unknown as keyof typeof CATEGORY_VALUE_TYPE],
  }));

  const optionsInclude = [
    {
      id: CATEGORY_VALUE_INCLUDE,
      label: 'Non',
    },
    {
      id: CATEGORY_VALUE_EXCLUDE,
      label: 'Oui',
    },
  ];

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={
          () => { setIsOpen(true); }
        }
        size="small"
        startIcon={<SettingsIcon />}
        data-testid="update-multiple-category-values-button"
      >
        {
          modalType === 'include' ? 'Exclure' : 'Portée'
        }
      </Button>
      <EditCategoryValueReachModal
        isOpen={isOpen}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onSubmit={handleConfirm}
        onCancel={() => { setIsOpen(false); }}
        isLoading={false}
        options={modalType === 'include' ? optionsInclude : optionsValueType}
        modalType={modalType}
      />
    </Box>
  );
}

export default GlobalUpdateCategoryValueReach;
