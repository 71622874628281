import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import {
  Box,
  BoxProps,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { useAppSelector } from 'common/reduxHooks';
import { selectLoggedInformation } from 'features/authentication/state/slice';
import LeftDrawer from 'features/navbar/Drawers/LeftDrawer';

const PREFIX = 'Logged';

const classes = {
  container: `${PREFIX}-container`,
};

const StyledBox = styled(Box)<BoxProps>(() => ({
  [`& .${classes.container}`]: {
    width: '100%',
  },
}));

function Logged(): JSX.Element {
  const { isLoggedIn, isLoading } = useAppSelector(
    (state) => selectLoggedInformation(state.authentication),
  );
  const [firstLoaded, setFirstLoaded] = useState(isLoggedIn);
  const [isInternalLoading, setIsInternalLoading] = useState(false);

  useEffect(() => {
    if (!firstLoaded && !isLoading && isInternalLoading) {
      // This is the first time the authentication state has fully loaded
      setFirstLoaded(true);
      setIsInternalLoading(false);
    } else if (!firstLoaded && isLoading && !isInternalLoading) {
      // The authentication state has never been fully loaded
      setIsInternalLoading(true);
    }
  }, [isLoading]);

  // isLoading can be updated multiple time on the same session so we use an internal state
  if (!firstLoaded) {
    return (
      <div />
    );
  }

  return (
    <StyledBox data-testid="root-container">
      {isLoggedIn && (
        <>
          <Typography component="span" id="back-to-top-anchor" />
          <Stack direction="row" className={classes.container}>
            <LeftDrawer />
            <Outlet />
          </Stack>
        </>
      )}
      {!isLoggedIn && (
        <Outlet />
      )}
    </StyledBox>
  );
}

export default Logged;
