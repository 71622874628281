const SCRAPPING_STATUS = {
  TO_SCRAP: 1,
  SENDING: 2,
  TO_DOWNLOAD: 3,
  DOWNLOADING: 4,
  TO_IMPORT: 5,
  IMPORTING: 6,
  DONE: 7,
  ERROR_SEND_WITH_RETRY: 102,
  ERROR_DOWNLOAD_WITH_RETRY: 104,
  ERROR_IMPORT_WITH_RETRY: 106,
  ERROR_SEND_FINAL: 202,
  ERROR_DOWNLOAD_FINAL: 204,
  ERROR_IMPORT_FINAL: 206,
};

export const NB_MAX_KEYWORD = 500;

export default SCRAPPING_STATUS;
