import React, { MouseEventHandler } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Box, Button, BoxProps, styled,
} from '@mui/material';

const PREFIX = 'DeleteAnalysesButton';

const classes = {
  core: `${PREFIX}-core`,
  button: `${PREFIX}-button`,
  spinner: `${PREFIX}-spinner`,
  spinning: `${PREFIX}-spinning`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({

  [`& .${classes.spinner}`]: {
    color: theme.palette.primary.light,
  },

  [`& .${classes.spinning}`]: {
    backgroundColor: theme.palette.secondary.main,
    cursor: 'default',
  },

}));

type DeleteAnalysesButtonProps = {
  disabled?: boolean,
  handleOpenModal: MouseEventHandler<HTMLButtonElement>,
}

function DeleteAnalysesButton(props: DeleteAnalysesButtonProps): JSX.Element {
  const {
    disabled,
    handleOpenModal,
  } = props;

  return (
    <StyledBox>
      <Button
        onClick={handleOpenModal}
        variant="contained"
        color="error"
        size="small"
        startIcon={<DeleteForeverIcon />}
        data-testid="delete-analyses-action-button"
        disabled={disabled}
      >
        Supprimer
      </Button>
    </StyledBox>
  );
}

export default DeleteAnalysesButton;
