import React from 'react';

import PrivateRoute from 'components/privateRoute/PrivateRoute';
import Settings from 'pages/settings/Settings';

import SetupActors from './actors/SetupActors';
import SetupBrand from './brand/SetupBrand';
import SetupCategoryGroups from './categoryGroups/SetupCategoryGroups';
import SetupCategoryValues from './categoryValues/SetupCategoryValues';
import SetupDataFiles from './dataFiles/SetupDataFiles';
import OptionsPage from './options/Options';
import LookerStudio from './performance/LookerStudio';
import PerformancePerActor from './performance/PerformancePerActor';
import PerformancePerSerp from './performance/PerformancePerSerp';
import PerformancePerURL from './performance/PerformancePerUrl';
import UrlIndexability from './performance/UrlIndexability';
import Create from './project/Create';
import ProjectsList from './project/ProjectsList';
import ProjectTransactions from './project/ProjectTransactions';
import {
  CREATE_PROJECTS,
  ORGANIC,
  SERP_ANALYSIS,
  SETTING_PROJECT_ACTOR,
  ANALYSIS_DETAILS,
  SERP_PHYSIONOMY,
  RANKING,
  KEYWORDS,
  SETTING_PROJECT,
  SETTING_PROJECT_UPLOAD,
  SETTING_PROJECT_CATEGORIES,
  SETTING_PROJECT_OPTION,
  SETTING_PROJECT_BRAND,
  SETTING_PROJECT_CATEGORIES_VALUES,
  PERFORMANCE_PER_ACTOR,
  PERFORMANCE_PER_URL,
  PERFORMANCE_PER_SERP,
  PERFORMANCE_LOOKER_STUDIO,
  PERFORMANCE_URL_INDEXABILITY,
  SHARE_OF_VOICE,
  PAGE_URL_LIST,
  PROJECT_BUDGET,
} from './ROUTES';
import SerpAnalysis from './serpAnalysis/SerpAnalysis';
import Keywords from './serpDetails/Keywords';
import Ranking from './serpDetails/Ranking/Ranking';
import SerpAnalysisDatavizDetails from './serpDetails/SerpAnalysisDatavizDetails';
import NewSerpPhysionomy from './serpDetails/SerpPhysionomy';
import ShareOfVoice from './ShareOfVoice/ShareOfVoice';
import { CustomRouteObject } from './types';
import UrlsList from './urlsList/UrlsList';
import WithProject from '../../features/projects/components/WithProjects';

const routes: CustomRouteObject[] = [
  {
    path: '',
    element: <PrivateRoute element={<ProjectsList />} />,
    defaultCloseMenu: true,
  },
  {
    path: CREATE_PROJECTS,
    element: <PrivateRoute element={<Create />} />,
    defaultCloseMenu: true,
    externalBlocked: true,
  },
  {
    path: SETTING_PROJECT,
    element: <PrivateRoute element={<Settings />} />,
    externalBlocked: true,
  },
  {
    path: SETTING_PROJECT_ACTOR,
    element: <PrivateRoute element={<WithProject Component={SetupActors} />} />,
  },
  {
    path: SETTING_PROJECT_UPLOAD,
    element: <PrivateRoute element={<SetupDataFiles />} />,
    externalBlocked: true,
  },
  {
    path: SETTING_PROJECT_CATEGORIES,
    element: <PrivateRoute element={<SetupCategoryGroups />} />,
    externalBlocked: true,
  },
  {
    path: SETTING_PROJECT_CATEGORIES_VALUES,
    element: <PrivateRoute element={<SetupCategoryValues />} />,
  },
  {
    path: SETTING_PROJECT_OPTION,
    element: <PrivateRoute element={<OptionsPage />} />,
    externalBlocked: true,
  },
  {
    path: SETTING_PROJECT_BRAND,
    element: <PrivateRoute element={<SetupBrand />} />,
    externalBlocked: true,
  },
  {
    path: SERP_ANALYSIS,
    element: <PrivateRoute element={<WithProject Component={SerpAnalysis} />} />,
  },
  {
    path: ORGANIC,
    element: <PrivateRoute element={<SerpAnalysisDatavizDetails />} />,
  },
  {
    path: ANALYSIS_DETAILS,
    element: <PrivateRoute element={<SerpAnalysisDatavizDetails />} />,
  },
  {
    path: SERP_PHYSIONOMY,
    element: <PrivateRoute element={
      <WithProject Component={NewSerpPhysionomy} />
    }
    />,
    externalBlocked: true,
    defaultCloseMenu: true,
  },
  {
    path: SHARE_OF_VOICE,
    element: <PrivateRoute element={<ShareOfVoice />} />,
    externalBlocked: true,
  },
  {
    path: RANKING,
    element: <PrivateRoute element={<Ranking />} />,
  },
  {
    path: KEYWORDS,
    element: <PrivateRoute element={<Keywords />} />,
  },
  {
    path: PERFORMANCE_PER_ACTOR,
    element: <PrivateRoute element={<WithProject Component={PerformancePerActor} />} />,
  },
  {
    path: PERFORMANCE_PER_URL,
    element: <PrivateRoute element={<WithProject Component={PerformancePerURL} />} />,
  },
  {
    path: PERFORMANCE_PER_SERP,
    element: <PrivateRoute element={<WithProject Component={PerformancePerSerp} />} />,
  },
  {
    path: PERFORMANCE_URL_INDEXABILITY,
    element: <PrivateRoute element={<WithProject Component={UrlIndexability} />} />,
    externalBlocked: true,
  },
  {
    path: PERFORMANCE_LOOKER_STUDIO,
    element: <PrivateRoute element={<WithProject Component={LookerStudio} />} />,
    externalBlocked: true,
  },
  {
    path: PAGE_URL_LIST,
    element: <PrivateRoute element={<WithProject Component={UrlsList} />} />,
    externalBlocked: true,
  },
  {
    path: PROJECT_BUDGET,
    element: <PrivateRoute element={<WithProject Component={ProjectTransactions} />} />,
  },
];

export default routes;
