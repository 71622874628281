import React from 'react';

import {
  FormControl,
} from '@mui/material';

import DropDown from 'components/dropDown/DropDown';

type NbMonthsDropDownProps = {
  value: number | '',
  handleChange: any,
  name?: string,
  width?: string,
  error?: boolean,
  size?: 'medium' | 'small',
  label?: string,
}

function NbMonthsDropDown(props: NbMonthsDropDownProps) {
  const {
    value,
    handleChange,
    width,
    error,
    name,
    size,
    label,
  } = props;

  const options = [
    {
      value: 1,
      label: '1 mois',
    },
    {
      value: 3,
      label: '3 mois',
    },
    {
      value: 6,
      label: '6 mois',
    },
    {
      value: 12,
      label: '12 mois',
    },
  ];

  return (
    <FormControl>
      <DropDown<typeof options[number]>
        value={value}
        name={name}
        options={options}
        onChange={handleChange}
        getOptionValue={(pl) => pl.value}
        getOptionLabel={(pl) => pl.label}
        size={size}
        label={label}
        fontSize="medium"
        width={width}
        error={error}
        data-testid="nb-month-drop-down"
      />
    </FormControl>
  );
}

export default NbMonthsDropDown;
