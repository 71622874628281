import React, { useState } from 'react';

import { TextField } from '@mui/material';

import globalTheme from 'common/theme';

import { CategoryTypeType } from '../type';
import isCategoryValueValueValid from '../utils/checkCategoryValueValueIsValid';

type EditPatternFieldProps = {
  value: string,
  categoryType: CategoryTypeType,
  onUpdate: (value: string) => void,
}

function EditPatternField(props: EditPatternFieldProps) {
  const {
    value,
    categoryType,
    onUpdate,
  } = props;
  // This state is for preventing the cursor to jump to the end
  const [internalValue, setInternalValue] = useState(value);

  const handleChange = (newValue: string) => {
    setInternalValue(newValue);
    onUpdate(newValue);
  };

  return (
    <TextField
      value={internalValue}
      onChange={(e) => handleChange(e.target.value)}
      onKeyDown={(e) => e.stopPropagation()}
      size="small"
      error={!isCategoryValueValueValid(internalValue, categoryType)}
      sx={{
        width: 0.75,
        boxSizing: null,
      }}
      slotProps={{
        input: {
          style: {
            fontSize: globalTheme.fontSize.xsmall,
            height: '30px',
            padding: 0,
            paddingLeft: 14,
          },
        },
      }}
      data-testid="edit-pattern-field-textfield"
    />
  );
}

export default EditPatternField;
