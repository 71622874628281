import React from 'react';

import moment from 'moment';

import DropDown from 'components/dropDown/DropDown';
import { DefaultDropDownItemsType } from 'components/dropDown/type';
import { DATA_SOURCE } from 'features/dataFiles/CONSTANT';
import usePeriod from 'features/period/state/usePeriod';
import { convertDate, DATE_ENGLISH, DATE_MONTH_YEAR } from 'utils/formatDate';

type EditViewBegindateProps = {
  value: string,
  onUpdate: (value: string) => void,
  scopeId: number,
}

function EditViewBeginDate (props: EditViewBegindateProps) {
  const { value, onUpdate, scopeId } = props;

  const {
    periods,
    hasPeriods,
    isLoading: isPeriodLoading,
  } = usePeriod(scopeId, [DATA_SOURCE.semrush.id], false);

  let formatedPeriods: DefaultDropDownItemsType[] = [];
  if (isPeriodLoading) formatedPeriods = [];
  else if (hasPeriods) {
    formatedPeriods = periods.map(({ period }) => ({
      label: convertDate(moment(period).utc().toDate(), DATE_MONTH_YEAR),
      value: convertDate(moment(period).utc().toDate(), DATE_ENGLISH),
    }));
  }

  return (
    <DropDown
      name="period"
      fullWidth
      disabled={isPeriodLoading}
      value={convertDate(moment(value).utc().toDate(), DATE_ENGLISH)}
      options={formatedPeriods}
      label=""
      onChange={(e) => onUpdate(e.target.value)}
      size="small"
      fontSize="xsmall"
      data-testid="kpiview-benchmark-startdate-dropdown"
      height="30px"
    />
  );
}

export default EditViewBeginDate;
