import moment from 'moment';
import * as yup from 'yup';

import { AppDispatch } from 'common/store';
import { UPLOADABLE_DATA_SOURCE } from 'features/dataFiles/CONSTANT';
import { DATE_ENGLISH, convertDate } from 'utils/formatDate';

import { uploadDataFiles } from '../state/slice';
import { DataFileForm } from '../types';

export const initFormDataFilesUpload = {
  file: {
    value: '',
    error: false,
    error_label: '',
    file: undefined,
  },
  data_file_mode_id: 2,
  deviceType: 1,
  indexId: 1,
  searchEngineId: 1,
};

export const getInitialValues = (scopeId: number): DataFileForm => ({
  scopeId,
  actorId: 0,
  dataSourceId: 0,
  periode: null,
  files: initFormDataFilesUpload,
});

const isFileCsv = (fileType: string) => fileType === 'text/csv'
  || fileType === 'application/vnd.ms-excel';

export const validationSchema = yup.object().shape({
  actorId: yup.number().nullable(),
  periode: yup.string().nullable(),
  dataSourceId: yup.number().required('Vous devez sélectionner un format de fichier.'),
  files: yup.object().shape({
    file: yup.object().shape({
      file: yup.mixed().required('Fichier(s) requis').test(
        'fileType',
        'Seul les fichiers .csv sont supporté',
        (files: any) => (Array.isArray(files)
          ? Array.from(files).find((file: any) => !isFileCsv(file.type)) === undefined
          : isFileCsv(files.type)),
      ).test(
        'fileLength',
        'Vous ne pouvez pas sélectionner plus de 5 fichiers',
        (files: any) => (Array.isArray(files)
          ? files.length <= 5
          : true),
      ),
    }),
  }),
});

export const formatNewDataFileDate = (value: moment.Moment, dataSourceId: number) => {
  let formatedValue;
  if (!value.isValid()) {
    formatedValue = null;
  } else if (dataSourceId === UPLOADABLE_DATA_SOURCE.screaming_frog.id) {
    formatedValue = value.startOf('day');
  } else {
    formatedValue = value.startOf('month');
  }

  return formatedValue;
};

export const handleUploadSubmit = (
  values: DataFileForm,
  dispatch: AppDispatch,
) => {
  const formData = new FormData();
  const files = values.files.file.file as File[];

  const periode = formatNewDataFileDate(
    moment(values.periode),
    typeof values.dataSourceId === 'number' ? values.dataSourceId : 0,
  );

  let formatedPeriode;

  if (periode && periode.isValid()) {
    formatedPeriode = convertDate(
      periode.toDate(),
      DATE_ENGLISH,
    );
  } else {
    formatedPeriode = null;
  }

  Array.from(files).forEach((file) => formData.append('files', file));

  dispatch(uploadDataFiles({
    payload: formData,
    query: {
      data_source_id: typeof values.dataSourceId === 'number' ? values.dataSourceId : 0,
      scope_id: values.scopeId,
      actor_id: values.actorId !== 0 ? values.actorId : undefined,
      periode: formatedPeriode,
    },
  }));
};
