import React, { ReactNode } from 'react';

import {
  SelectChangeEvent,
  Stack, StackProps, Typography, styled,
} from '@mui/material';

import DropDown from 'components/dropDown/DropDown';
import Modal from 'components/modal/Modal';

const PREFIX = 'EditCategoryValueReachModalForm';

const classes = {
  textField: `${PREFIX}-error`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`& .${classes.textField}`]: {
    fontSize: theme.fontSize.medium,
  },
}));

type EditCategoryValueReachModalProps = {
  isOpen: boolean,
  value: number,
  onChange: (event: SelectChangeEvent<any>, child: ReactNode) => void,
  onSubmit: Function,
  onCancel: Function,
  isLoading: boolean,
  options: {
    id: any,
    label: string,
  }[]
  modalType: 'include' | 'category_value_type_id',
}

function EditCategoryValueReachModal(props: EditCategoryValueReachModalProps) {
  const {
    isOpen,
    value,
    onChange,
    onSubmit,
    onCancel,
    isLoading,
    options,
    modalType,
  } = props;

  return (
    <Modal
      title={(
        <Typography>
          <Typography component="span" variant="bold">
            {
              modalType === 'include' ? 'Exclure ' : 'Paramétrer '
            }
          </Typography>
          {
              modalType === 'include' ? 'les règles' : 'la portée des règles'
            }
        </Typography>
      )}
      actionConfirm={onSubmit}
      actionCancel={onCancel}
      isOpen={isOpen}
      displayLoading={isLoading}
      isProcessLoading={isLoading}
      dataTestId="update-category-value-modal"
    >
      <StyledStack
        direction="column"
        alignItems="center"
        p={1}
        spacing={1}
        sx={{ width: 1 }}
      >
        <DropDown<typeof options[number]>
          onChange={onChange}
          value={value}
          options={options}
          width="20rem"
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          data-testid="update-multiple-category-value-dropdown"
        />
      </StyledStack>
    </Modal>
  );
}

export default EditCategoryValueReachModal;
