import moment from 'moment';

import { DATA_SOURCE } from 'features/dataFiles/CONSTANT';
import { RANK_SLICES } from 'features/rankSlice/CONSTANT';
import { RankingEvolutionFilters } from 'features/serpAnalysis/types';
import { DATE_ENGLISH, convertDate } from 'utils/formatDate';

import { MetricType, PerformancePages } from './types';

export const METRICS = [
  'Nombre de positions',
  'Demande mensuelle',
  'Trafic estimé',
] as MetricType[];

export const NOTORIETY = [
  'Marque',
  'Hors-Marque',
] as const;

export type NotorietyType = typeof NOTORIETY[number];

export const DEFAULT_PERIODE = convertDate(moment().startOf('month').toDate(), DATE_ENGLISH);
export const DEFAULT_METRIC = METRICS[0];

export const DEFAULT_RANK_SLICES = RANK_SLICES.filter((rankSlice) => rankSlice.id <= 3);
export const RANK_SLICES_LIMITED_20 = RANK_SLICES.filter((rankSlice) => rankSlice.range[1] <= 20);

export const defaultFiltersValue: RankingEvolutionFilters = {
  type: 'rankingEvolutionFilters',
  datavizMode: DEFAULT_METRIC,
  rankingTypeId: [],
  actorIds: [],
  periodeBegin: moment(DEFAULT_PERIODE).startOf('month').format(),
  periodeEnd: moment(DEFAULT_PERIODE).endOf('month').format(),
  intentionIds: [],
  deviceTypeId: 1,
  timeSeries: 'day',
  rankSliceIds: DEFAULT_RANK_SLICES.map((rankSlice) => rankSlice.id),
  keywordIds: [],
  dataSourceIds: [DATA_SOURCE.semrush.id],
  categoryIds: undefined,
  categoryGroupId: undefined,
  serpAnalysisIds: [],
  bestRankMode: false,
};

export const PERFORMANCE_QUERIES = {
  period: 'period',
  metric: 'metric',
  rankSlice: 'rank_slice',
  actorIds: 'actor_ids',
  categoryGroupId: 'category_group_id',
  categoryIds: 'category_ids',
  serpAnalysisIds: 'serp_analysis_ids',
  rankingTypeIds: 'ranking_type_ids',
  bestRankMode: 'best_rank_mode',
  includeUncategorized: 'include_uncategorized',
} as const;

export const GRAPH_TYPES = {
  rank_all: {
    label: 'rank_all',
    titles: {
      [METRICS[0]]: 'Nombre de mots clés positionnés',
      [METRICS[1]]: 'Demande mensuelle couverte par les mots clés',
      [METRICS[2]]: 'Trafic estimé pour les mots clés positionnés',
    },
  },
  rank_brand: {
    label: 'rank_brand',
    titles: {
      [METRICS[0]]: 'Nombre de mots clés MARQUE positionnés',
      [METRICS[1]]: 'Demande mensuelle couverte par les mots clés MARQUE',
      [METRICS[2]]: 'Trafic estimé pour les mots clés MARQUE positionnés',
    },
  },
  rank_not_brand: {
    label: 'rank_not_brand',
    titles: {
      [METRICS[0]]: 'Nombre de mots clés HORS-MARQUE positionnés',
      [METRICS[1]]: 'Demande mensuelle couverte par les mots clés HORS-MARQUE',
      [METRICS[2]]: 'Trafic estimé pour les mots clés HORS-MARQUE positionnés',
    },
  },
  notoriety: {
    label: 'notoriety',
    titles: {
      [METRICS[0]]: 'Nombre de mots clés positionnés',
      [METRICS[1]]: 'Demande mensuelle couverte par les mots clés',
      [METRICS[2]]: 'Trafic estimé pour les mots clés positionnés',
    },
  },
} as const;

export const filtersStatus = {
  period: { enabled: true },
  metric: { enabled: true },
  actors: { enabled: true },
  rankSlice: { enabled: true },
  category_Group: { enabled: true },
  category: { enabled: true },
  serpAnalysis: { enabled: true },
  rankingType: { enabled: true },
};

export const filterStatusPerPage = {
  actor: filtersStatus,
  keyword: filtersStatus,
  metric: filtersStatus,
  period: filtersStatus,
  serp: filtersStatus,
  url: filtersStatus,
  kpi: {
    ...filtersStatus,
    metric: { enabled: false },
  },
} as Record<PerformancePages, typeof filtersStatus>;
