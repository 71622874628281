import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import ApiHarvestProject from './api';
import {
  fetchHarvestProjects,
  fetchHarvestProjectsSuccess,
  fetchHarvestProjectsFailure,
} from './slice';
import { HarvestProjectType } from '../types';

function* workFetchHarvestProject() {
  try {
    const response: AxiosResponse = yield call(ApiHarvestProject.fetchHarvestProjects);
    const harvestProjects: HarvestProjectType[] = response.data;
    const harvestProjectsSorted = harvestProjects.sort((a, b) => a.label.localeCompare(b.label));
    yield put(fetchHarvestProjectsSuccess(harvestProjectsSorted));
  } catch (e: any) {
    yield put(fetchHarvestProjectsFailure(e.message));
  }
}

export default function* sagaFetchHarvestProjects() {
  yield takeEvery(fetchHarvestProjects, workFetchHarvestProject);
}
