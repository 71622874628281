import React, { MouseEventHandler } from 'react';

import BlockIcon from '@mui/icons-material/Block';
import {
  Box, Button, BoxProps, styled,
} from '@mui/material';

const PREFIX = 'StopScheduleAnalysesButton';

const classes = {
  core: `${PREFIX}-core`,
  button: `${PREFIX}-button`,
  spinner: `${PREFIX}-spinner`,
  spinning: `${PREFIX}-spinning`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({

  [`& .${classes.spinner}`]: {
    color: theme.palette.primary.light,
  },

  [`& .${classes.spinning}`]: {
    backgroundColor: theme.palette.secondary.main,
    cursor: 'default',
  },

}));

type StopScheduleAnalysesButtonProps = {
  handleOpenModal: MouseEventHandler<HTMLButtonElement>,
  disabled?: boolean,
}

function StopScheduleAnalysesButton(props: StopScheduleAnalysesButtonProps): JSX.Element {
  const {
    handleOpenModal,
    disabled,
  } = props;

  return (
    <StyledBox>
      <Button
        onClick={handleOpenModal}
        variant="contained"
        size="small"
        className={
          classes.button
        }
        startIcon={<BlockIcon />}
        data-testid="stop-schedule-analyses-button"
        disabled={disabled}
      >
        Stopper la planification
      </Button>
    </StyledBox>
  );
}

export default StopScheduleAnalysesButton;
