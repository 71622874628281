import React from 'react';

import globalTheme from 'common/theme';
import BarChartComponent from 'features/dataviz/components/generic/charts/BarChart';
import { RANKING_TYPE } from 'features/dataviz/CONSTANTS';
import {
  featuredSnippetAbbr,
  peopleAlsoAskAbbr,
  imageAbbr,
  videoAbbr,
  localPackAbbr,
} from 'pages/projects/serpDetails/config';

import { ChartComponentProps } from '../../types';

function GroupResultsByRankingType(props: ChartComponentProps): JSX.Element {
  const {
    datas,
    title,
    link,
    isPageLoading,
    size,
    handleClick,
    isAxisLabelBold,
    containerAspect,
  } = props;

  let correctedDatas = [
    {
      ranking_type_id: RANKING_TYPE.FEATURED_SNIPPET,
      label: featuredSnippetAbbr,
      Total: 0,
    },
    {
      ranking_type_id: RANKING_TYPE.PEOPLE_ALSO_ASK,
      label: peopleAlsoAskAbbr,
      Total: 0,
    },
    {
      ranking_type_id: RANKING_TYPE.IMAGES,
      label: imageAbbr,
      Total: 0,
    },
    {
      ranking_type_id: RANKING_TYPE.VIDEO,
      label: videoAbbr,
      Total: 0,
    },
    {
      ranking_type_id: RANKING_TYPE.LOCAL_PACK,
      label: localPackAbbr,
      Total: 0,
    },
  ];
  if (datas) {
    correctedDatas = correctedDatas.map((data) => {
      const match: any = datas.data.values?.filter(
        (values) => values.ranking_type_id === data.ranking_type_id,
      )[0];
      if (match) {
        return {
          ...data,
          Total: match.client + match.competitor + match.other,
        };
      }
      return data;
    });
  }

  const dataKeys = [
    {
      dataKey: 'Total',
      color: globalTheme.palette.primary.dark,
    },
  ];
  return (
    <BarChartComponent
      datas={correctedDatas}
      title={title}
      link={link}
      dataKeys={dataKeys}
      xAxisDatakey="label"
      isPageLoading={isPageLoading}
      size={size}
      hideAxis
      topLabelName={dataKeys[0].dataKey}
      legendAngle={0}
      tickMargin={5}
      handleClick={handleClick}
      isAxisLabelBold={isAxisLabelBold}
      withTooltip
      tooltipLabel="Dans les SERP"
      containerAspect={containerAspect}
    />
  );
}

export default GroupResultsByRankingType;
