import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import ApiTransaction from './api';
import {
  fetchTransactions,
  fetchTransactionsFailure,
  fetchTransactionsSuccess,
} from './slice';
import {
  FetchTransactions,
  Transaction,
} from '../types';

export function* workFetchTransactions(action: PayloadAction<FetchTransactions>) {
  try {
    const response: AxiosResponse<Transaction[]> = yield call(
      ApiTransaction.fetchTransactions,
      action.payload.projectId,
    );
    const transactions = response.data;
    yield put(fetchTransactionsSuccess(transactions));
  } catch (e: any) {
    yield put(fetchTransactionsFailure(e.message));
  }
}

export function* sagaFetchTransactions() {
  yield takeEvery(fetchTransactions, workFetchTransactions);
}
