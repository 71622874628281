import React, { forwardRef } from 'react';

import { Check, Remove } from '@mui/icons-material';
import {
  Checkbox, CheckboxProps, styled,
} from '@mui/material';

const StyledCheckBox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  '&.MuiCheckbox-root svg': {
    width: 16,
    height: 16,
    backgroundColor: 'transparent',
    border: `1px solid ${
      theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
    }`,
    borderRadius: 5,
  },
  '&.MuiCheckbox-root svg path': {
    display: 'none',
  },
  '&.MuiCheckbox-root.Mui-checked svg': {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
  },
  '&.MuiCheckbox-root.Mui-checked svg path': {
    display: 'block',
    color: 'white',
  },
  '&.MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
    position: 'absolute',
    display: 'table',
    border: '2px solid #fff',
    borderTop: 0,
    borderLeft: 0,
    transform: 'rotate(45deg) translate(-50%,-50%)',
    opacity: 1,
    transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
    content: '""',
    top: '50%',
    left: '39%',
    width: 5.71428571,
    height: 9.14285714,
  },
}));

const CheckBoxDataGrid = forwardRef((props: any, ref): JSX.Element => (
  <StyledCheckBox
      // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    icon={<Check />}
    checkedIcon={<Check />}
    indeterminateIcon={<Remove />}
    ref={ref}
  />
));

export default CheckBoxDataGrid;
