import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  Stack, styled, StackProps, Button,
} from '@mui/material';

import MenuTitle from 'features/navbar/components/MenuTitle';
import ProjectDropdown from 'features/projects/components/ProjectDropdown';
import { CREATE_PROJECTS } from 'pages/projects/ROUTES';
import { PROJECTS } from 'router/CONSTANTS';

const PREFIX = 'ProjectChoice';

const classes = {
  core: `${PREFIX}-core`,
  createButton: `${PREFIX}-createButton`,
  link: `${PREFIX}-link`,
  select: `${PREFIX}-select`,
  menuItem: `${PREFIX}-menuItem`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    margin: theme.spacing(4),
  },

  [`& .${classes.createButton}`]: {
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },

  [`& .${classes.link}`]: {
    fontSize: theme.fontSize.medium,
  },

  [`& .${classes.select}`]: {
    backgroundColor: 'white',
    fontSize: theme.fontSize.medium,
    maxWidth: '280px',
    textTransform: 'lowercase',
  },

}));

export default function ProjectChoice(): JSX.Element {
  return (
    <StyledStack
      spacing={2}
      alignItems="center"
      className={classes.core}
    >
      <MenuTitle title="Accès rapides" />
      <NavLink
        to={`${PROJECTS}/${CREATE_PROJECTS}`}
        className={classes.link}
      >
        <Button variant="contained">
          Créer un projet
        </Button>
      </NavLink>
      <MenuTitle title="Ou" />

      <ProjectDropdown
        projectId={0}
      />
    </StyledStack>
  );
}
