import React from 'react';

import { Typography } from '@mui/material';

import User from '../User';

interface UserStatusModalContentProps {
  user: User,
}

export default function UserUpdateStatusModalTitle(
  props: UserStatusModalContentProps,
): JSX.Element {
  const { user } = props;

  return (
    <Typography>
      <Typography component="span" variant="bold">
        {
        user.isActive
          ? 'Désactiver '
          : 'Activer '
      }
      </Typography>
      {` ${user.userFullName}`}
    </Typography>
  );
}
