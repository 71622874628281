import React from 'react';

import { Box, Chip } from '@mui/material';

import { UserType } from '../types';
import User from '../User';

type ChipProps = {
  userItem: UserType,
}

export default function UserStatusChip (props: ChipProps): JSX.Element {
  const {
    userItem,
  } = props;

  const user = new User(userItem);

  return (
    <Box>
      <Chip
        size="small"
        label={user.userStatus}
        color={user.isActive ? 'success' : 'default'}
      />
    </Box>
  );
}
