/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import PayloadWithCallback from 'common/types';
import { UserType } from 'features/users/types';
import User from 'features/users/User';
import router from 'router/RouterConfig';

import { LoginPasswordPayload, LoginPayload, LoginResponse } from '../types';

export interface State {
  isLoggedIn: boolean;
  isLoading: boolean;
  isLoggedOut: boolean,
  currentUser?: UserType;
  refreshTokenLoading: boolean;
  token: LoginResponse;
  error: string;
  errorRefresh: string;
  isInternal: boolean;
  isInternalLoading: boolean;
  isInternalError: string;
}

const initialState: State = {
  isLoggedIn: false,
  isLoading: false,
  isLoggedOut: false,
  currentUser: undefined,
  token: {
    token_expiry: 0,
    access_token: '',
    token_type: '',
  },
  error: '',

  refreshTokenLoading: true,
  errorRefresh: '',

  isInternal: false,
  isInternalLoading: false,
  isInternalError: '',
};

export const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    fetchLogin: (state: State, action: PayloadAction<LoginPayload>) => {
      state.isLoading = true;
      state.error = '';
    },
    fetchLoginSuccess: (state: State, action: PayloadAction<LoginResponse>) => {
      state.token = action.payload;
      state.isLoggedIn = true;
      state.isLoading = false;
    },
    fetchLoginFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isLoggedIn = false;
    },
    fetchLoginPassword: (state: State, action: PayloadAction<LoginPasswordPayload>) => {
      state.isLoading = true;
      state.error = '';
    },
    fetchLoginPasswordSuccess: (state: State, action: PayloadAction<LoginResponse>) => {
      state.token = action.payload;
      state.isLoggedIn = true;
      state.isLoading = false;
    },
    fetchLoginPasswordFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isLoggedIn = false;
    },
    fetchRefreshToken: (state: State) => {
      state.isLoading = true;
      state.refreshTokenLoading = true;
    },
    fetchRefreshTokenSuccess: (state: State, action: PayloadAction<LoginResponse>) => {
      state.token = action.payload;
      state.isLoggedIn = true;
      state.isLoading = false;
      state.refreshTokenLoading = false;
    },
    fetchRefreshTokenFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorRefresh = action.payload;
      state.isLoggedIn = false;
      state.refreshTokenLoading = false;
    },
    fetchLogout: (state: State, action: PayloadAction<PayloadWithCallback>) => {
      state.isLoading = true;
      state.token = initialState.token;
      state.isLoggedOut = true;
      state.isLoggedIn = false;
    },
    fetchLogoutSuccess: (state: State) => {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.isLoggedOut = true;
    },
    fetchLogoutFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.isLoggedOut = true;
      state.error = action.payload;
    },
    fetchCurrentUser: (state: State, action: PayloadAction) => {
      state.isLoading = true;
    },
    fetchCurrentUserSuccess: (state: State, action: PayloadAction<UserType>) => {
      if (!state.isLoggedOut) {
        state.currentUser = action.payload;
        state.isLoggedIn = true;
      }

      state.isLoading = false;
    },
    fetchCurrentUserFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.error = action.payload;
      state.currentUser = initialState.currentUser;
    },
    fetchIsInternal: (state: State, action: PayloadAction<string>) => {
      state.isInternalError = '';
      state.isInternalLoading = true;
    },
    fetchIsInternalSuccess: (state: State, action: PayloadAction<string>) => {
      state.isInternalLoading = false;
      state.isInternalError = '';
      state.isInternal = action.payload === 'internal';
    },
    fetchIsInternalFailure: (state: State, action: PayloadAction<string>) => {
      state.isInternalLoading = false;
      state.isInternalError = action.payload;
    },
    loginResetError: (state: State, action: PayloadAction) => {
      state.error = '';
    },
  },
});

// Specifics selectors
export const selectIsLoggedIn = (state: State) => state.isLoggedIn;
export const selectIsLoading = (state: State) => state.isLoading;
export const selectRefreshTokenLoading = (state: State) => state.refreshTokenLoading;
export const selectIsLoggedOut = (state: State) => state.isLoggedOut;
export const selectCurrentUser = (state: State) => state.currentUser;

// Combine selector
export const selectLoggedInformation = createSelector(
  [
    selectIsLoggedIn,
    selectIsLoading,
    selectRefreshTokenLoading,
    selectIsLoggedOut,
  ],
  (isLoggedIn, isLoading, refreshTokenLoading, isLoggedOut) => ({
    isLoggedIn,
    isLoading,
    refreshTokenLoading,
    isLoggedOut,
  }),
);

export const selectNewCurrentUser = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser && new User(currentUser),
);

export const {
  fetchLogin,
  fetchLoginSuccess,
  fetchLoginFailure,
  fetchLoginPassword,
  fetchLoginPasswordSuccess,
  fetchLoginPasswordFailure,
  fetchRefreshToken,
  fetchRefreshTokenSuccess,
  fetchRefreshTokenFailure,
  fetchLogout,
  fetchLogoutSuccess,
  fetchLogoutFailure,
  fetchCurrentUser,
  fetchCurrentUserSuccess,
  fetchCurrentUserFailure,
  fetchIsInternal,
  fetchIsInternalSuccess,
  fetchIsInternalFailure,
  loginResetError,
} = authSlice.actions;

export default authSlice.reducer;
