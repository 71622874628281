import { useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import { RootState } from 'common/store';

import { fetchPeriodByScopeAndDataSource, selectPeriodsByScopeIdAndDataSourceIds } from './slice';

/**
  * doFetch is used as a quick fix to prevent an infinite loop if the previous fetch data is empty
  * @todo: Remove doFetch when the store values will be updated
  */
const usePeriod = (scopeId: number, dataSourceIds: number[], doFetch: boolean = true) => {
  const dispatch = useAppDispatch();
  const [currentLoading, setCurrentLoading] = useState(true);

  const { isLoading: isLoadingState, error } = useAppSelector(
    (state: RootState) => state.period,
  );

  const periodsValues = useAppSelector(
    (state) => selectPeriodsByScopeIdAndDataSourceIds(state.period, scopeId, dataSourceIds),
  );

  useEffect(() => {
    if (
      scopeId !== 0
      && dataSourceIds.length > 0
      && !isLoadingState
      && periodsValues.length === 0
      && doFetch
    ) {
      dispatch(fetchPeriodByScopeAndDataSource({ scopeId, dataSourceIds }));
      setCurrentLoading(true);
    }
  }, [scopeId, dataSourceIds]);

  useEffect(() => {
    if (!isLoadingState && currentLoading) {
      setCurrentLoading(false);
    }
  }, [isLoadingState]);

  const periods = periodsValues?.flatMap((pv) => pv.values) ?? [];
  const hasPeriods = periods.length > 0;

  return {
    isLoading: currentLoading || isLoadingState,
    error,
    periods: periods.slice().reverse(),
    hasPeriods,
  };
};

export default usePeriod;
