import React from 'react';

import { AvatarGroup } from '@mui/material';

import { BasicUserType } from 'pages/projects/types';

import UserAvatarView from './AvatarView';
import BasicUser from '../BasicUser';
import { UserType } from '../types';

type AvatarsListViewProps = {
  usersList: UserType[] | BasicUserType[]
}

export default function UserAvatarsListView(props: AvatarsListViewProps): JSX.Element {
  const { usersList } = props;

  return (
    <AvatarGroup max={4}>
      {usersList.map((user) => (
        <UserAvatarView
          key={user.id}
          user={new BasicUser(user)}
          isTitle
        />
      ))}
    </AvatarGroup>
  );
}
