import React from 'react';

import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';

import CheckBoxDataGrid from 'components/checkbox/CheckBoxDataGrid';
import LinearLoadingOverlay from 'components/loading/LinearLoadingOverlay';

import createColumns from './configSerpAnalysesOptionsDataGrid';
import { Analysis } from '../../types';

type SerpAnalysesOptionsDataGridProps = {
  serpAnalyses: Analysis[],
  isLoading: boolean,
  selection: number[],
  handleSelectionModelChange: (rowSelectionModel: GridRowSelectionModel) => void,
}

function SerpAnalysesOptionsDataGrid(props: SerpAnalysesOptionsDataGridProps) {
  const {
    serpAnalyses,
    isLoading,
    selection,
    handleSelectionModelChange,
  } = props;

  return (
    <DataGrid
      density="compact"
      hideFooterSelectedRowCount
      disableRowSelectionOnClick
      disableColumnMenu
      checkboxSelection
      autoHeight
      disableVirtualization
      getRowHeight={() => 'auto'}
      getRowId={(row) => row.id}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 25,
          },
        },
        sorting: {
          sortModel: [{ field: 'label', sort: 'asc' }],
        },
      }}
      slots={{
        loadingOverlay: LinearLoadingOverlay,
        baseCheckbox: CheckBoxDataGrid,
      }}
      pageSizeOptions={[25, 50, 100]}
      rows={serpAnalyses}
      columns={createColumns()}
      slotProps={{
        pagination: {
          labelRowsPerPage: ('Nombre par page :'),
        },
      }}
      loading={isLoading}
      localeText={{
        noRowsLabel: 'Il n’existe aucune étude de mots clés pour ce projet',
      }}
      rowSelectionModel={selection}
      onRowSelectionModelChange={handleSelectionModelChange}
    />
  );
}

export default SerpAnalysesOptionsDataGrid;
