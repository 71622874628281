import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Button,
  Menu,
  Box,
  styled,
  BoxProps,
} from '@mui/material';

import KpiView from 'features/kpiView/KpiView';

import DeleteView from './DeleteView';
import DownloadView from './DownloadView';
import EditKpiViewAction from './EditKpiViewAction';
import LoadView from './LoadView';
import { KpiViewActionsWording, KpiViewType } from '../../types';

const PREFIX = 'KpiViewActions';

const classes = {
  dropdown: `${PREFIX}-dropdown`,
  button: `${PREFIX}-button`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.dropdown}`]: {
    fontSize: theme.fontSize.xlarge,
  },

  [`& .${classes.button}`]: {
    color: '#737476',
    fontSize: theme.fontSize.medium,
    height: '30px',
    width: '115px',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      color: '#737476',
      backgroundColor: '#e8e8e8',
    },
  },
}));

type KpiViewActionsProps = {
  view: KpiViewType,
  extraActions?: (handleCloseMenu: Function) => JSX.Element,
  disabled?: boolean,
  hideLoad?: boolean,
  hideDownload?: boolean,
  hideDelete?: boolean,
  wordings?: KpiViewActionsWording,
  onKpiViewLoad?: (kpiView: KpiViewType) => void,
  onEditRowValueSelected?: any,
  disabledEditActions?: boolean,
}

function KpiViewActions(props: KpiViewActionsProps): JSX.Element {
  const {
    view,
    extraActions,
    disabled = false,
    hideLoad = false,
    hideDownload = false,
    hideDelete = false,
    wordings,
    onKpiViewLoad,
    onEditRowValueSelected,
    disabledEditActions,
  } = props;
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const openMenu = Boolean(anchorMenu);

  const handleOpenMenu = (event: any) => {
    if (anchorMenu === event.target) {
      setAnchorMenu(null);
    } else {
      setAnchorMenu(event.target);
    }
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };
  return (
    <StyledBox>
      <Button
        id={view.id.toString()}
        variant="contained"
        disableElevation
        onClick={(e) => { handleOpenMenu(e); }}
        endIcon={<KeyboardArrowDownIcon className={classes.dropdown} />}
        size="small"
        className={classes.button}
        color="info"
        disabled={disabled}
      >
        {wordings?.button ?? 'Actions'}
      </Button>
      <Menu
        anchorEl={anchorMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        keepMounted
        id="menuActionsKpiView"
      >
        {!hideLoad && (
          <LoadView
            idView={view.id}
            wordings={wordings}
            onLoad={onKpiViewLoad}
          />
        )}
        {!hideDownload && (
          <DownloadView
            kpiView={view}
            handleCloseMenu={handleCloseMenu}
            wordings={wordings}
          />
        )}
        {
          onEditRowValueSelected && (
            <EditKpiViewAction
              kpiView={new KpiView(view)}
              handleCloseMenu={handleCloseMenu}
              onEditRowValueSelected={onEditRowValueSelected}
              disabled={disabledEditActions}
            />
          )
        }
        {extraActions?.(handleCloseMenu)}
        {!hideDelete && (
          <DeleteView
            idView={view.id}
            handleCloseMenu={handleCloseMenu}
            wordings={wordings}
            disabled={disabledEditActions}
          />
        )}
      </Menu>
    </StyledBox>
  );
}

export default KpiViewActions;
