// Initial data of the form
export const initFormProjectUpload = {
  file: {
    value: '',
    error: false,
    error_label: '',
    file: undefined,
  },
  data_file_mode_id: 2,
  deviceType: 1,
  indexId: 1,
  searchEngineId: 1,
};

// API error message not correct file headers
export const wrongHeaders = 'DataFile headers are not correct';
