import React from 'react';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from 'common/reduxHooks';

import ProjectActionsView from './ProjectActionsView';
import { SubMenuList } from './types';

type ProjectActionProps = {
  menu: SubMenuList[],
  projectName: string,
  projectId: number,
  analysisId: number | undefined,
  scopeId: number,
  currentPage: string,
}

function ProjectActions({
  menu,
  projectName,
  projectId,
  analysisId,
  scopeId,
  currentPage,
}: ProjectActionProps): JSX.Element {
  const location = useLocation();

  const { currentUser } = useAppSelector((state) => state.authentication);

  const menuToOpen = menu.find((menuItem) => (
    `${menuItem.link}` === `${location.pathname}${location.search}`
    || menuItem?.subMenu.find(
      (subMenu) => (
        (`${subMenu.link}` === `${location.pathname}${location.search}`)
        || (
          subMenu.includeSubLink
          && `${location.pathname}${location.search}`.includes(subMenu.link)
        )
      ),
    )
  ));

  const [expanded, setExpanded] = React.useState<string>('');
  React.useEffect(() => {
    setExpanded(menuToOpen?.name || '');
  }, [menuToOpen]);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : '');
  };

  return (
    <ProjectActionsView
      menu={menu}
      projectName={projectName}
      expanded={expanded}
      handleChange={handleChange}
      projectId={projectId}
      analysisId={analysisId}
      scopeId={scopeId}
      currentPage={currentPage}
      currentUser={currentUser}
    />
  );
}

export default ProjectActions;
