import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import useCurrentUser from 'features/authentication/state/useCurrentUser';
import AnalysisDetailsDataGrid
  from 'features/dataviz/components/generic/datagrid/AnalysisDetailsDataGrid';
import GridCellExpand
  from 'features/dataviz/components/generic/datagrid/GridCellExpand/GridCellExpand';
import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';
import { SerpAnalysisKeyword } from 'features/keywords/types';
import { Analysis } from 'features/serpAnalysis/types';
import formatThousands from 'utils/formatThousands';
import useInputSearch from 'utils/useInputSearch';

import ActionButton from './KeywordButtonActions';
import useKeywords from '../state/useKeywords';

type ListAllProps = {
  analysis: Analysis,
  scopeId: number,
  projectId: number,
}

function ListAll(props: ListAllProps): JSX.Element {
  const {
    analysis,
    scopeId,
    projectId,
  } = props;

  const { keywords, isLoading } = useKeywords({ idAnalysis: analysis.id });

  const columnData: GridColDef<SerpAnalysisKeyword>[] = [
    {
      field: 'mot cle',
      flex: 1,
      type: 'string',
      editable: false,
      valueGetter: (value, row) => row.keyword.label,
      renderHeader: () => (
        <StyledHeader label="Mots clés" />
      ),
      renderCell: (params) => (
        <GridCellExpand
          width={params.colDef.computedWidth}
        >
          <Typography variant="body3">
            {params.value}
          </Typography>
        </GridCellExpand>
      ),
    },
    {
      field: 'volume mensuel',
      flex: 1,
      editable: false,
      valueGetter: (value, row) => row.keyword.default_search_volume,
      renderHeader: () => (
        <StyledHeader label="Volume mensuel" />
      ),
      renderCell: (params) => (
        <GridCellExpand
          width={params.colDef.computedWidth}
        >
          <Typography variant="body3">
            {formatThousands(params.value)}
          </Typography>
        </GridCellExpand>
      ),
    },
    {
      field: 'intention',
      renderHeader: () => (
        <StyledHeader label="Intentions de recherche" />
      ),
      type: 'string',
      editable: false,
      valueGetter: (value, row) => (
        row.sa_keyword_intention ? row.sa_keyword_intention.label : ''
      ),
      flex: 1,
      renderCell: (params) => (
        <GridCellExpand
          width={params.colDef.computedWidth}
        >
          <Typography variant="body3">
            {params.value}
          </Typography>
        </GridCellExpand>
      ),
    },
  ];

  const [idsKwSelected, setIdsKwSelected] = useState<number[]>([]);

  const handleSelect = (ids: Array<any>) => {
    setIdsKwSelected(ids);
  };

  const currentUser = useCurrentUser();

  const selectRows = (inputSearch: string) => keywords.filter(
    (kw) => kw.keyword.label.toLowerCase().includes(
      inputSearch.trim().toLocaleLowerCase(),
    ),
  );

  const { handleInputChange, filterRows } = useInputSearch(keywords, selectRows);

  return (
    <Box>
      <AnalysisDetailsDataGrid
        handleInputChange={handleInputChange}
        rows={filterRows()}
        columnData={columnData}
        isLoading={isLoading}
        isSelectActivated
        showToolbar={false}
        handleSelect={handleSelect}
        getRowId={(row: SerpAnalysisKeyword) => row.keyword_id}
      >
        <ActionButton
          idsKwSelected={idsKwSelected}
          scopeId={scopeId}
          handleInputChange={handleInputChange}
          analysis={analysis}
          disabled={currentUser?.isConsultant(projectId)}
        />
      </AnalysisDetailsDataGrid>
    </Box>
  );
}

export default ListAll;
