import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import {
  Box,
  Typography,
  Stack,
  styled,
  BoxProps,
  Divider,
} from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';

import globalTheme from 'common/theme';
import Spinner from 'components/loading/Spinner';
import { DataInfos } from 'features/dataviz/types';
import { formatLegend } from 'features/dataviz/utils/formatData';
import { DataType } from 'features/dataviz/utils/types';
import { GRAPH_TYPES, METRICS } from 'features/performance/CONSTANT';
import formatThousands from 'utils/formatThousands';

const PREFIX = 'Legend';

const classes = {
  core: `${PREFIX}-core`,
  item: `${PREFIX}-item`,
  typography: `${PREFIX}-typography`,
  details: `${PREFIX}-details`,
  title: `${PREFIX}-title`,
  icon: `${PREFIX}-icon`,
  iconCross: `${PREFIX}-cross-icon`,
  scrollbox: `${PREFIX}-scrollbox`,
  number: `${PREFIX}-number`,
  numberBold: `${PREFIX}-numberBold`,
  spinnerCtn: `${PREFIX}-spinnerCtn`,
  container: `${PREFIX}-container`,
  divider: `${PREFIX}-divider`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    height: '310px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.item}`]: {
    marginTop: '.1rem',
    alignItems: 'center',
  },
  [`& .${classes.typography}`]: {
    fontSize: theme.fontSize.xsmall,
  },
  [`& .${classes.details}`]: {
    fontSize: theme.fontSize.xsmall,
    color: theme.palette.info.dark,
  },
  [`& .${classes.title}`]: {
    fontSize: theme.fontSize.medium,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(1),
    width: '15px',
    height: '15px',
  },
  [`& .${classes.iconCross}`]: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
    width: '15px',
    height: '15px',
  },
  [`& .${classes.scrollbox}`]: {
    height: '100%',
    width: '90%',
    alignSelf: 'end',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
      height: 0,
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.info.light,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.info.main,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.info.dark,
    },
  },
  [`& .${classes.number}`]: {
    color: theme.palette.info.dark,
    fontSize: theme.fontSize.xsmall,
  },
  [`& .${classes.numberBold}`]: {
    color: theme.palette.info.dark,
    fontWeight: 'bold',
    fontSize: theme.fontSize.xsmall,
  },
  [`& .${classes.spinnerCtn}`]: {
    width: '100%',
    height: '100%',
  },
  [`& .${classes.container}`]: {
    width: '100%',
    height: '100%',
    display: ' flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.divider}`]: {
    margin: theme.spacing(1),
  },
}));

type LegendProps = {
  datas: DataInfos,
  title: string,
  isLoading?: boolean | undefined,
  isPageLoading?: boolean | undefined,
  setBarColor: (value: any) => string,
  label: string,
  labelPlural: string,
  performanceMetric: string,
  graphType: keyof typeof GRAPH_TYPES,
  missingValues?: string[],
  keySubject: keyof DataType,
  noData?: boolean,
}

function PerformanceLegend(props: LegendProps) {
  const {
    datas,
    title,
    isLoading = false,
    isPageLoading = false,
    setBarColor,
    label,
    labelPlural,
    performanceMetric,
    graphType,
    missingValues,
    keySubject,
    noData = false,
  } = props;

  const field = (
    performanceMetric === METRICS[1] && 'search_volume'
  )
  || (
    performanceMetric === METRICS[2] && 'traffic'
  ) || 'rank';

  const getLegendValues = () => {
    const formatedLegend = formatLegend(
      (datas.data.values || []) as DataType[],
      keySubject,
      ['rank', 'search_volume', 'traffic'],
      graphType,
    );

    if (keySubject === 'category_name') {
      const uncategorized = formatedLegend.find((data) => !data.category_name);

      if (uncategorized) {
        uncategorized.category_name = 'Non catégorisée';
      }
    }

    return formatedLegend;
  };

  const values = getLegendValues();
  values.sort((a: any, b: any) => b[field] - a[field]);

  return (
    <StyledBox className={classes.core}>
      {(isLoading || isPageLoading)
        ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            className={classes.spinnerCtn}
            data-testid="spinner"
          >
            <Spinner />
          </Stack>
        )
        : (
          <Box className={classes.container}>
            <Typography className={classes.title}>
              {title}
            </Typography>
            {
              (values.length > 0 && !noData)
                ? (
                  <Stack className={classes.scrollbox}>
                    { values?.map((data: any, i: number) => (
                      <Stack
                        className={classes.item}
                        direction="row"
                        key={data[keySubject]}
                      >
                        <CircleIcon
                          fontSize="small"
                          className={classes.icon}
                          sx={{ color: setBarColor(data[keySubject]) }}
                        />
                        <Typography className={classes.typography}>
                          <Typography className={classes.numberBold} component="span">
                            {(i + 1).toString().padStart(2, '0')}
                          </Typography>
                          .
                          {' '}
                          <Typography component="span" className={classes.typography}>
                            {data[keySubject]}
                          </Typography>
                          {' '}
                          <Typography
                            className={classes.details}
                            component="span"
                            data-testid="data-value"
                          >
                            &#32;
                            &bull;
                            {' '}
                            {formatThousands(data[field])}
                            {' '}
                            {(data[field]) > 1 ? labelPlural : label}
                          </Typography>
                        </Typography>
                      </Stack>
                    ))}
                    {missingValues && missingValues.length > 0 && (
                      <>
                        <Divider variant="middle" className={classes.divider} />
                        {missingValues.map((name: string) => (
                          <Stack
                            className={classes.item}
                            direction="row"
                            key={`missing-${name}`}
                          >
                            <CircleIcon
                              fontSize="small"
                              className={classes.icon}
                              sx={{ color: setBarColor(name) }}
                            />
                            <ClearIcon fontSize="small" className={classes.iconCross} />
                            <Typography className={classes.typography} data-testid="data-missing">
                              {name}
                            </Typography>
                          </Stack>
                        ))}
                      </>
                    )}
                  </Stack>
                )
                : (
                  <Typography
                    color={globalTheme.palette.error.main}
                    variant="body3"
                    data-testid="legend-error"
                  >
                    Les options sélectionnées ne permettent pas d&apos;afficher des données
                  </Typography>
                )
            }
          </Box>
        )}
    </StyledBox>
  );
}

export default PerformanceLegend;
