import React, { useState } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Typography, Button } from '@mui/material';
import { FormikHelpers, FormikProps, useFormik } from 'formik';

import Modal from 'components/modal/Modal';

import CATEGORY_TYPE_ID from '../CONSTANTS';
import { initValues, validationSchema, AddCatGroupForm } from './modals/addCatGroupConfig';
import AddCatGroupModal from './modals/AddCatGroupModal';
import useCategoryGroups from '../state/useCategoryGroups';
import { CategoryGroupCreate } from '../types';

type CreateCatGroupProps = {
  scopeId: number,
}

function CreateCatGroup(props: CreateCatGroupProps) {
  const { scopeId } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { createCategoryGroup } = useCategoryGroups(scopeId, CATEGORY_TYPE_ID.url);

  const handleModalSubmit = (
    values: AddCatGroupForm,
    formikHelpers: FormikHelpers<AddCatGroupForm>,
  ) => {
    if (scopeId === 0) return;
    const payload: CategoryGroupCreate = {
      name: values.name.trim(),
      scope_id: scopeId,
      category_type_id: CATEGORY_TYPE_ID.url,
      categories: [],
    };
    createCategoryGroup(payload);
    formikHelpers.resetForm();
    setIsOpen(false);
  };

  const formik: FormikProps<AddCatGroupForm> = useFormik<AddCatGroupForm>({
    initialValues: initValues,
    validationSchema,
    onSubmit: handleModalSubmit,
    enableReinitialize: true,
  });

  return (
    <Box>
      <Button
        variant="contained"
        onClick={
        () => { setIsOpen(true); }
        }
        size="small"
        startIcon={<AddCircleOutlineIcon />}
        data-testid="create-category-group-create-modal-button"
      >
        Ajouter
      </Button>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">Ajouter manuellement</Typography>
            {' '}
            un groupe d&apos;URL
          </Typography>
        )}
        actionConfirm={formik.handleSubmit}
        actionCancel={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        dataTestId="create-category-group-create-modal"
      >
        <AddCatGroupModal
          formik={formik}
        />
      </Modal>
    </Box>
  );
}

export default CreateCatGroup;
