import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';

import ApiSerpAnalysis from './api';
import {
  fetchSerpAnalyses,
  fetchSerpAnalysesSuccess,
  fetchSerpAnalysesFailure,
  updateSerpAnalyses,
  updateSerpAnalysesSuccess,
  updateSerpAnalysesFailure,
  deleteAnalyses,
  deleteAnalysesSuccess,
  deleteAnalysesFailure,
  createAnalysis,
  createAnalysisSuccess,
  createAnalysisFailure,
  createSerpAnalysisScrappingSuccess,
  createSerpAnalysisScrappingFailure,
  createSerpAnalysisScrapping,
} from './slice';
import {
  AnalysisCreate,
  Scrape,
  SerpAnalysisFetchPayload, SerpAnalysisUpdatePayload,
} from '../types';

export function* workFetchSerpAnalyses(action: PayloadAction<SerpAnalysisFetchPayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiSerpAnalysis.fetchSerpAnalyses,
      action.payload.scopeId,
    );

    yield put(fetchSerpAnalysesSuccess(response.data));
  } catch (e: any) {
    yield put(fetchSerpAnalysesFailure(e.message));
  }
}

export function* workUpdateSerpAnalyses(action: PayloadAction<SerpAnalysisUpdatePayload[]>) {
  try {
    const response: AxiosResponse = yield call(
      ApiSerpAnalysis.updateSerpAnalyses,
      action.payload,
    );

    yield put(updateSerpAnalysesSuccess(response.data));
  } catch (e: any) {
    yield put(updateSerpAnalysesFailure(e.message));
  }
}

export function* workCreateSerpAnalysis(action: PayloadAction<AnalysisCreate>) {
  try {
    const response: AxiosResponse = yield call(
      ApiSerpAnalysis.createAnalyses,
      action.payload,
    );
    const analysis = response.data;
    yield put(createAnalysisSuccess(analysis));
  } catch (e: any) {
    yield put(createAnalysisFailure(e.message));
  }
}

export function* workDeleteSerpAnalyses(action: PayloadAction<Array<number>>) {
  try {
    for (let i = 0; i < action.payload.length; i += 1) {
      yield call(
        ApiSerpAnalysis.deleteAnalyses,
        action.payload[i],
      );
    }
    yield put(deleteAnalysesSuccess(action.payload));
  } catch (e: any) {
    yield put(deleteAnalysesFailure(e.message));
  }
}

export function* workCreateSerpAnalysisScrapping(
  action: PayloadAction<Scrape>,
) {
  try {
    const responseAnalyse: AxiosResponse = yield call(
      ApiSerpAnalysis.createSerpAnalysisScrapping,
      action.payload.serp_analysis_scrapping,
    );
    const serpAnalysisScrapping = responseAnalyse.data;
    yield put(createSerpAnalysisScrappingSuccess(serpAnalysisScrapping));

    const response: AxiosResponse = yield call(
      ApiSerpAnalysis.updateSerpAnalyses,
      [action.payload.analysis],
    );
    const analysis = response.data;
    yield put(updateSerpAnalysesSuccess(analysis));
  } catch (e: any) {
    yield put(createSerpAnalysisScrappingFailure(e.message));
  }
}

export function* sagaFetchSerpAnalyses() {
  yield takeEvery(
    fetchSerpAnalyses,
    workFetchSerpAnalyses,
  );
}

export function* sagaUpdateSerpAnalyses() {
  yield takeEvery(
    updateSerpAnalyses,
    workUpdateSerpAnalyses,
  );
}

export function* sagaCreateSerpAnalysis() {
  yield takeEvery(createAnalysis, workCreateSerpAnalysis);
}

export function* sagaDeleteSerpAnalyses() {
  yield takeEvery(deleteAnalyses, workDeleteSerpAnalyses);
}

export function* sagaCreateSerpAnalysisScrapping() {
  yield takeEvery(createSerpAnalysisScrapping, workCreateSerpAnalysisScrapping);
}
