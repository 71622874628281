import React from 'react';

import { Box } from '@mui/material';

import Count from 'features/dataviz/components/generic/components/Count';
import formatProjectNameForUrl from 'utils/formatProjectNameForUrl';
import { generateProjectBaseUrl } from 'utils/urlManipulation';

import { ChartComponentProps } from '../../types';

function CountWithScraps(props: ChartComponentProps): JSX.Element {
  const {
    datas,
    isPageLoading,
    analysis,
    project,
  } = props;
  const {
    keywords,
    scrapped_keywords: scrappedKeywords,
  } = datas.data.values ? datas.data.values[0] : { keywords: 0, scrapped_keywords: 0 };

  const baseUrl: string = (analysis && project && (`${
    generateProjectBaseUrl(project.id, project.name)}${analysis.id}-${
    formatProjectNameForUrl(analysis.label)}`)) || '';

  return (
    <Box>
      <Count
        baseUrl={baseUrl}
        keywords={keywords || 0}
        scrappedKeywords={scrappedKeywords || 0}
        isLoading={isPageLoading && datas.isLoading}
      />
    </Box>
  );
}

export default CountWithScraps;
