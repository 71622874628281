/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import isEqual from 'lodash/isEqual';

import useFetchAnalysis from 'features/dataviz/state/useFetchAnalysis';

import ChartErrorHandler from './ChartErrorHandler';
import { DatavizComponentProps } from '../../types';

function DatavizContainer(props: DatavizComponentProps) {
  const {
    datavizComp,
    idScope,
    rankingTypeIds,
    serpAnalysisStatus,
    title,
    link,
    isPageLoading = false,
    height = '285px',
    size,
    handleClick,
    isAxisLabelBold,
    analysis,
    actors,
    project,
    scrappingId,
    feature,
    datavizFilters,
    datavizMode,
    rankingMode,
    yAxisLabel,
    containerAspect,
    searchEngineId,
    showValue,
    showPercentage,
  } = props;
  const {
    chartData,
    chartKey,
    currentExport,
    handleExport,
    isChartWithoutDatas,
    isMonthly,
    showChart,
  } = useFetchAnalysis(props);
  return (
    <Box sx={{ height }}>
      {(datavizComp.element
        && (showChart
          ? (
            <datavizComp.element
              datas={chartData}
              rankingTypeIds={rankingTypeIds ?? []}
              title={title}
              link={isPageLoading ? undefined : link}
              handleExport={handleExport}
              isLoading={chartData.isLoading}
              isPageLoading={isPageLoading}
              size={size}
              handleClick={handleClick}
              isAxisLabelBold={isAxisLabelBold}
              analysis={analysis}
              scopeId={idScope}
              chartKey={chartKey}
              actors={actors}
              project={project}
              scrappingId={scrappingId}
              isExportLoading={currentExport?.isLoading}
              isChartWithoutDatas={isChartWithoutDatas}
              feature={feature}
              datavizFilters={datavizFilters}
              datavizMode={datavizMode}
              rankingMode={rankingMode}
              isMonthly={isMonthly}
              yAxisLabel={yAxisLabel}
              containerAspect={containerAspect}
              searchEngineId={searchEngineId}
              showPercentage={showPercentage}
              showValue={showValue}
            />
          )
          : (
            <ChartErrorHandler
              title={title}
              chartData={chartData}
              serpAnalysisStatus={serpAnalysisStatus}
              isChartWithoutDatas={isChartWithoutDatas}
              isPageLoading={isPageLoading || chartData?.isLoading}
            />
          )
        )
      )}
    </Box>
  );
}

export default React.memo(
  DatavizContainer,
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
