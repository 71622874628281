import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  Stack,
  styled,
  StackProps,
  Typography,
} from '@mui/material';

import { useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import MenuTitle from 'features/navbar/components/MenuTitle';
import USER_TYPES from 'features/users/CONSTANTS';

import UserMenu from './types';

interface AdminMenuViewProps {
  menuList: UserMenu[],
}

const PREFIX = 'ProjectActionsView';

const classes = {
  core: `${PREFIX}-core`,
  link: `${PREFIX}-link`,
  firstLevelAcc: `${PREFIX}-firstLevelAcc`,
  firstLevelLink: `${PREFIX}-firstLevelLink`,
  activeFirstLevelLink: `${PREFIX}-activeFirstLevelLink`,
  accordion: `${PREFIX}-accordion`,
  accordionSummary: `${PREFIX}-accordionSummary`,
  accDetail: `${PREFIX}-accDetail`,
  menuTitle: `${PREFIX}-menuTitle`,
  menuName: `${PREFIX}-menuName`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    margin: theme.spacing(4),
  },

  [`& .${classes.menuName}`]: {
    fontSize: theme.fontSize.medium,
    lineHeight: '1.8em',
    fontWeight: 400,
  },

  [`& .${classes.menuTitle}`]: {
    color: '#a7aaba',
    marginBottom: '18px',
  },

  [`& .${classes.link}`]: {
    color: 'white',
    textDecoration: 'none',
    display: 'block',
    cursor: 'pointer',
    width: '100%',
    marginTop: '5px',
  },

  [`& .${classes.firstLevelLink}`]: {
    justifyContent: 'left',
    padding: '5px',
    width: '250px',
    '&:hover': {
      color: theme.palette.primary.light,
      fontSize: theme.fontSize.medium,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '5px',
    },
  },

  [`& .${classes.firstLevelAcc}`]: {
    padding: '5px',
  },

  [`& .${classes.activeFirstLevelLink}`]: {
    color: theme.palette.primary.light,
    justifyContent: 'left',
    fontSize: theme.fontSize.medium,
    padding: '5px',
    width: '250px',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '5px',
  },

  [`& .${classes.accordion}`]: {
    color: 'white',
    width: '250px',
    lineHeight: '1.6em',
    backgroundColor: theme.palette.primary.main,
    padding: '0',
  },

  [`& .${classes.accordionSummary}`]: {
    color: 'white',
    fontSize: theme.fontSize.medium,
    padding: '0',
    margin: '0',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    '&.Mui-expanded': {
      color: theme.palette.secondary.main,
    },
    '&.MuiAccordionSummary-expandIconWrapper': {
      color: 'white',
    },
  },

  [`& .${classes.accDetail}`]: {
    padding: '0',
  },
}));

function AdminMenuView(props: AdminMenuViewProps) {
  const {
    menuList,
  } = props;

  const { currentUser } = useAppSelector(
    (state) => state.authentication,
  );

  const isUserDircli = currentUser?.user_type_id === USER_TYPES.dircli.id;

  return (
    <StyledStack
      alignItems="center"
      className={classes.core}
    >
      <MenuTitle title="ADMIN" titleColor={globalTheme.palette.secondary.main} />
      {
        menuList
          .filter((menu) => !(menu.blockedDircli && isUserDircli))
          .map((menu) => (
            <NavLink to={menu.link} className={classes.link} key={menu.name}>
              {({ isActive }) => (
                <Stack
                  direction="row"
                  justifyContent="center"
                  spacing={1}
                  className={
                              isActive ? classes.activeFirstLevelLink : classes.firstLevelLink
                            }
                >
                  {menu.icon}
                  <Typography className={classes.menuName}>{menu.name}</Typography>
                </Stack>
              )}
            </NavLink>
          ))
      }
    </StyledStack>
  );
}

export default AdminMenuView;
