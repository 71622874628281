import React from 'react';

import {
  Typography, TypographyProps, styled,
} from '@mui/material';

const StyledHeader = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.info.main,
  textTransform: 'uppercase',
  fontSize: theme.fontSize.medium,
}));

type HeaderProps = {
  label: string,
}

function HeaderDataGrid(props: HeaderProps): JSX.Element {
  const { label } = props;

  return (
    <StyledHeader variant="h2" data-testid="dg-header">{label}</StyledHeader>
  );
}

export default HeaderDataGrid;
