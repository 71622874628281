import React from 'react';

import {
  TextField,
  Box,
  Typography,
  styled,
  BoxProps,
} from '@mui/material';
import { FormikProps, FormikProvider } from 'formik';

import { AddKeywordsFormType } from './config';

const PREFIX = 'CreateSerpAnalysisFormView';

const classes = {
  input: `${PREFIX}-input`,
  inputContent: `${PREFIX}-inputContent`,
  box: `${PREFIX}-box`,
  error: `${PREFIX}-error`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.box}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },

  [`& .${classes.input}`]: {
    width: '100%',
  },

  [`& .${classes.inputContent}`]: {
    fontSize: theme.fontSize.medium,
  },

  [`& .${classes.error}`]: {
    borderRadius: '5px',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    color: theme.palette.error.main,
    fontSize: theme.fontSize.medium,
  },
}));

type FormAnalysisProps = {
  formik: FormikProps<AddKeywordsFormType>,
  error: string,
  nameAnalysis: string | undefined,
}

function AddKeywordsForm(props: FormAnalysisProps) {
  const {
    formik,
    error,
    nameAnalysis,
  } = props;
  const { handleChange } = formik;

  return (
    <StyledBox className={classes.box}>
      <FormikProvider value={formik}>
        <TextField
          variant="outlined"
          size="small"
          margin="normal"
          id="label"
          label="Nom de la catégorie"
          type="text"
          onChange={handleChange}
          value={nameAnalysis}
          className={classes.input}
          slotProps={{
            input: {
              classes: {
                input: classes.inputContent,
              },
            },
          }}
          disabled
        />
        <TextField
          margin="normal"
          id="keywords"
          label="Mots clés à ajouter (1 mot clé = 1 ligne)"
          multiline
          variant="outlined"
          size="small"
          rows={10}
          className={classes.input}
          slotProps={{
            input: {
              classes: {
                input: classes.inputContent,
              },
            },
          }}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.keywords}
          error={
                  formik.touched.keywords
                  && Boolean(formik.errors.keywords)
                }
          helperText={
                  formik.touched.keywords
                  && formik.errors.keywords
                }
        />
        {error && (
          <Box className={classes.error}>
            <Typography>
              {error}
            </Typography>
          </Box>
        )}
      </FormikProvider>
    </StyledBox>
  );
}

export default AddKeywordsForm;
