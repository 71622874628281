import React from 'react';

import {
  TextField,
  Box,
  styled,
  BoxProps,
} from '@mui/material';
import { FormikProps, FormikProvider } from 'formik';

import CategoryGroup from 'features/categoryGroups/classes/CategoryGroup';
import CATEGORY_TYPE_ID from 'features/categoryGroups/CONSTANTS';

import { AddCatValuesForm } from './addCatValueConfig';
import SelectCategory from './SelectCategory';

const PREFIX = 'AddCatValuesModal';

const classes = {
  input: `${PREFIX}-input`,
  inputContent: `${PREFIX}-inputContent`,
  box: `${PREFIX}-box`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.box}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },

  [`& .${classes.input}`]: {
    width: '100%',
  },

  [`& .${classes.inputContent}`]: {
    fontSize: theme.fontSize.medium,
  },
}));

type FormAddCategoryValuesProps = {
  formik: FormikProps<AddCatValuesForm>,
  catGroup: CategoryGroup | undefined,
  categoryGroupType: number,
}

function AddCatValueModal(props: FormAddCategoryValuesProps) {
  const {
    formik,
    catGroup,
    categoryGroupType,
  } = props;
  const { handleChange } = formik;

  const catList = catGroup?.catGroupCategories || [];

  const getCatNameFromId = (id: number) => catList.find((cat) => cat.catId === id)?.catName;

  return (
    <StyledBox
      className={classes.box}
    >
      <FormikProvider value={formik}>
        { (categoryGroupType !== CATEGORY_TYPE_ID.brand) && (
          <>
            <SelectCategory
              name="category"
              value={formik.values.category}
              handleChange={formik.handleChange}
              groupId={catGroup?.catGroupId || 0}
              groupType={categoryGroupType}
              emptyValue
            />
            <TextField
              margin="normal"
              id="name"
              label="Saisir le nom de la nouvelle catégorie"
              type="text"
              variant="outlined"
              size="small"
              name="name"
              disabled={formik.values.category !== -1}
              onChange={handleChange}
              value={
                formik.values.category === -1
                  ? formik.values.name
                  : getCatNameFromId(formik.values.category)
              }
              className={classes.input}
              slotProps={{
                input: {
                  className: classes.inputContent,
                },
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.name
                && Boolean(formik.errors.name)
                && formik.values.category === -1
              }
              helperText={
                formik.touched.name
                && (formik.values.category === -1)
                && formik.errors.name
              }
              data-testid="add-cat-value-name-input"
            />
          </>
        )}
        <TextField
          margin="normal"
          id="patterns"
          label={`${
            categoryGroupType !== CATEGORY_TYPE_ID.brand
              ? 'Règles à ajouter (1 règle = 1 ligne)'
              : 'Mots clés à ajouter (1 mot clé = 1 ligne)'
          }`}
          type="text"
          rows={10}
          variant="outlined"
          size="small"
          name="patterns"
          multiline
          onChange={handleChange}
          value={formik.values.patterns}
          className={classes.input}
          slotProps={{
            input: {
              className: classes.inputContent,
            },
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.patterns
            && Boolean(formik.errors.patterns)
          }
          helperText={
            formik.touched.patterns
            && formik.errors.patterns
          }
          data-testid="add-cat-value-patterns-input"
        />
      </FormikProvider>
    </StyledBox>

  );
}

export default AddCatValueModal;
