import axios, { AxiosInstance, AxiosResponse } from 'axios';

import ApiAuth from 'features/authentication/state/api';
import {
  fetchRefreshTokenFailure,
  fetchRefreshTokenSuccess,
} from 'features/authentication/state/slice';
import { LoginResponse } from 'features/authentication/types';

import { API_BASE_ADDRESS } from './config';
import { AppStore } from './store';

const UNAUTHENTICATED_STATUS_CODE = 401;

// We create an instance of axios that we will use for all the protected routes
const axiosAuthInstance = axios.create({
  baseURL: API_BASE_ADDRESS,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
});

function setUpAxiosAuthInstance(store: AppStore, instance: AxiosInstance) {
  // Request interceptor for API calls : intercepts before the request is sent
  instance.interceptors.request.use(
    async (config) => {
      const { access_token: accessToken } = store.getState().authentication.token;

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  // Response interceptor for API calls
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: any) => {
      // First we get the original request
      const originalRequest = error.config;
      // We get the status code of the error if we have one
      const statusCode = error.response?.status;
      // We get the retry flag to avoid infinite lood
      const isRetry = originalRequest.retry;

      // If we receive an unauthenticated status code and we didn't try this request yet
      if (
        !isRetry
        && statusCode === UNAUTHENTICATED_STATUS_CODE
      ) {
        // We flag the request for the second try
        originalRequest.retry = true;
        let accessToken: LoginResponse = {
          token_expiry: 0,
          access_token: '',
          token_type: '',
        };

        let errorRefTok: Error = new Error('Unknown error');

        try {
          accessToken = await ApiAuth.refreshToken();
        } catch (err: any) {
          if (err instanceof Error) {
            errorRefTok = err;
          }
        }

        if (accessToken.access_token) {
          store.dispatch(fetchRefreshTokenSuccess(accessToken));
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken.access_token}`;
          return axiosAuthInstance(originalRequest);
        }

        store.dispatch(fetchRefreshTokenFailure(errorRefTok.message));
        return Promise.reject(errorRefTok);
      }
      // If we have an other error or it's the second try of the request
      return Promise.reject(error);
    },
  );
}

export { axiosAuthInstance, setUpAxiosAuthInstance };
