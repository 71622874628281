import React, { SyntheticEvent, useState } from 'react';

import {
  Autocomplete,
  TextField,
  Checkbox,
  MenuItem,
  Typography,
  Chip,
  Box,
} from '@mui/material';

import globalTheme from 'common/theme';

import useKeywords from '../state/useKeywords';
import { SerpAnalysisKeyword } from '../types';

type KeywordAutocompleteProps = {
  disabled?: boolean,
  serpAnalysisId: number,
  name: string,
  label: string,
  keywordIdsSelected: number[],
  handleChange: (
    event: SyntheticEvent<Element, Event>,
    value: (SerpAnalysisKeyword | string)[],
    selected: any[],
    allOpt: any[],
    filter: string,
  ) => void,
  inputValue?: string,
  onInputValueChanged?: Function,
}

function KeywordAutocomplete(props: KeywordAutocompleteProps) {
  const {
    serpAnalysisId,
    name,
    label,
    keywordIdsSelected,
    handleChange,
    disabled = false,
    inputValue: parentInputValue,
    onInputValueChanged,
  } = props;
  const [inputValue, setInputValue] = useState('');

  const isParentHandlingInput = parentInputValue !== undefined && !!onInputValueChanged;

  const { keywords } = useKeywords({ idAnalysis: serpAnalysisId });
  const keyworList = [...keywords];
  const keywordsSorted = React.useMemo(
    () => keyworList.sort(
      (a, b) => a.keyword.label.localeCompare(b.keyword.label),
    ),
    [keywords],
  );
  const keywordSelected = React.useMemo(
    () => keywords.filter((k) => keywordIdsSelected.includes(k.keyword_id)).sort(
      (a, b) => a.keyword.label.localeCompare(b.keyword.label),
    ),
    [keywords, keywordIdsSelected],
  );

  const getInputValue = () => {
    if (isParentHandlingInput) return parentInputValue;
    return inputValue;
  };

  const handeChangeInput = (value: string) => {
    if (isParentHandlingInput) return onInputValueChanged(value);
    return setInputValue(value);
  };

  return (
    <Box>
      <Autocomplete
        disabled={disabled}
        disableCloseOnSelect
        inputValue={getInputValue()}
        onInputChange={(e, value, reason) => reason !== 'reset' && handeChangeInput(value)}
        multiple
        limitTags={1}
        value={keywordSelected}
        options={['all', ...keywordsSorted]}
        onChange={(e, value) => {
          handleChange(
            e,
            value,
            keywordSelected,
            keywordsSorted,
            getInputValue(),
          );
        }}
        getOptionLabel={
          (option) => (
            typeof option === 'string'
              ? 'Tous / Aucun'
              : option.keyword.label
          )
        }
        noOptionsText={(
          <Typography sx={{ fontSize: globalTheme.fontSize.xsmall }}>
            Aucun mot clé correspondant
          </Typography>
        )}
        forcePopupIcon={false}
        disableClearable
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {
              ...params
            }
            name={name}
            variant="outlined"
            label={label}
            hiddenLabel
            size="small"
            slotProps={{
              inputLabel: {
                ...params.InputLabelProps,
                shrink: true,
                sx: {
                  fontSize: globalTheme.fontSize.xsmall,
                  zIndex: 1000,
                },
              },
              input: {
                ...params.InputProps,
                notched: true,
                sx: {
                  fontSize: globalTheme.fontSize.xsmall,
                  backgroundColor: 'white',
                  zIndex: 100,
                  height: '2rem',
                  '&.Mui-focused ': {
                    height: 'auto',
                  },
                },
                endAdornment: false,
              },
            }}
          />
        )}
        size="small"
        sx={{
          height: '2rem',
          fontSize: globalTheme.fontSize.xsmall,
          backgroundColor: 'white',
        }}
        renderOption={
          (renderProps, option, { selected }) => (
            <MenuItem
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...renderProps}
              disableGutters
              dense
              key={
                typeof option === 'string'
                  ? 'all'
                  : option.keyword_id
              }
              sx={{
                height: '2rem',
                width: '100%',
              }}
            >
              <Checkbox
                checked={
                  typeof option === 'string'
                    ? keywordSelected.length === keywordsSorted.length
                    : selected
                }
                indeterminate={
                  typeof option === 'string'
                  && keywordSelected.length > 0
                  && keywordSelected.length < keywordsSorted.length
                }
                size="small"
              />
              <Typography
                variant="body2"
                component="span"
                sx={{ fontSize: globalTheme.fontSize.xsmall }}
              >
                {typeof option === 'string' ? 'Tous / Aucun' : option.keyword.label}
              </Typography>
            </MenuItem>
          )
        }
        renderTags={
          (value: readonly (SerpAnalysisKeyword | string)[], getTagProps) => (
            <>
              <Typography
                key={`chip${typeof value[0] === 'string' ? 'all' : value[0].keyword_id}`}
                component="span"
                sx={{ fontSize: globalTheme.fontSize.xsmall, maxWidth: '60%' }}
              >
                <Chip
                  variant="filled"
                  size="small"
                  label={typeof value[0] === 'string' ? 'Tous' : value[0].keyword.label}
                  key={getTagProps({ index: 0 }).key}
                  className={getTagProps({ index: 0 }).className}
                  data-tag-index={getTagProps({ index: 0 })['data-tag-index']}
                  disabled={getTagProps({ index: 0 }).disabled}
                  onDelete={getTagProps({ index: 0 }).onDelete}
                  tabIndex={getTagProps({ index: 0 }).tabIndex}
                  sx={{ height: '1.2rem', fontSize: '0.75rem' }}
                />
              </Typography>
              {
                value.length > 1 && (
                  <Typography variant="body3">
                    +
                    {value.length - 1}
                  </Typography>
                )
              }
            </>
          )
        }
        filterOptions={
          (options, state) => {
            const newOptions: any[] = [];
            options.forEach((opt) => {
              if (typeof opt === 'string') newOptions.unshift(opt);
              if (typeof opt !== 'string' && opt.keyword.label.toLowerCase().includes(
                state.inputValue.toLowerCase(),
              )) newOptions.push(opt);
            });
            return newOptions;
          }
        }
      />
    </Box>
  );
}

export default KeywordAutocomplete;
