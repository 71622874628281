export function formatObjectToUri(objectToFormat: Record<string, any>): string {
  const uriList = Object.keys(objectToFormat).map((objectKey) => {
    const value = objectToFormat[objectKey];

    if (value === undefined || value === null) return null;

    const stringifyValue = String(objectToFormat[objectKey]);
    return `${objectKey}=${encodeURIComponent(stringifyValue)}`;
  });

  const uri = uriList.filter((value) => value !== null).join('&');

  return uri;
}

export default formatObjectToUri;
