import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import DataSource from '../types';

export interface State {
  isLoading: boolean;
  error: string;
  dataSources: DataSource[],
}

const initialState: State = {
  isLoading: false,
  error: '',
  dataSources: [],
};

export const dataSourcesSlice = createSlice({
  name: 'serp_analysis_index',
  initialState,
  reducers: {
    fetchDataSourcesRequested: (state: State) => {
      state.isLoading = true;
    },
    fetchDataSourcesSuccess: (state: State, action: PayloadAction<DataSource[]>) => {
      state.isLoading = false;
      state.error = '';
      state.dataSources = action.payload;
    },
    fetchDataSourcesFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchDataSourcesRequested,
  fetchDataSourcesSuccess,
  fetchDataSourcesFailure,
} = dataSourcesSlice.actions;

export default dataSourcesSlice.reducer;
