/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  Box,
  BoxProps as BoxPropsProps,
  styled,
} from '@mui/material';

type MainBoxProps = {
  children: JSX.Element | Array<JSX.Element>,
  BoxProps?: BoxPropsProps,
  'data-testid'?: string,
}
const PREFIX = 'MainBox';

const classes = {
  whiteContainer: `${PREFIX}-whiteContainer`,
};

const StyledBox = styled(Box)<BoxPropsProps>(({ theme }) => ({
  [`&.${classes.whiteContainer}`]: {
    width: '100%',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: 'white',
    borderRadius: '15px',
  },
}));

function MainBox({ children, BoxProps, 'data-testid': dataTestId }: MainBoxProps): JSX.Element {
  return (
    <StyledBox className={classes.whiteContainer} {...BoxProps} data-testid={dataTestId}>
      {children}
    </StyledBox>
  );
}

export default MainBox;
