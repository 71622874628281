import React, { useState } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';

import {
  selectCategoryIdToDeleteByCategoryValueIds,
  deleteCategoryValues,
} from '../../state/slice';
import DeleteCategoryValueModal from '../components/DeleteCategoryValueModal';

type GlobalDeleteDataFileProps = {
  selection: number[],
  categoryGroupType: number,
}

function GlobalDeleteCategoryValue(props: GlobalDeleteDataFileProps): JSX.Element {
  const {
    selection,
    categoryGroupType,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const categoryGroups = useAppSelector((state) => state.categoryGroups);

  const handleConfirm = () => {
    const categoryIdsToDelete = selectCategoryIdToDeleteByCategoryValueIds(
      categoryGroups,
      selection,
    );
    dispatch(deleteCategoryValues({
      category_value_ids: selection,
      category_to_delete: categoryIdsToDelete,
    }));
    setIsOpen(false);
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="error"
        onClick={
          () => { setIsOpen(true); }
        }
        size="small"
        startIcon={<DeleteForeverIcon />}
        data-testid="delete-multiple-category-values-button"
      >
        Supprimer
      </Button>
      <DeleteCategoryValueModal
        categoryValueIds={selection}
        handleClose={() => setIsOpen(false)}
        isOpen={isOpen}
        onConfirm={handleConfirm}
        categoryGroupType={categoryGroupType}
      />
    </Box>
  );
}

export default GlobalDeleteCategoryValue;
