import React from 'react';

import {
  InputLabel,
  FormControl,
  FormControlProps,
  MenuItem,
  Select,
  styled,
  SelectChangeEvent,
} from '@mui/material';

import { useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';

import UserChips from '../infos/Chips';
import { selectUserFilteredByType } from '../state/slice';
import useUsersFetch from '../state/useUsersFetch';
import { UserType } from '../types';
import User from '../User';
import UsersUtils from '../UsersUtils';

const PREFIX = 'UsersSelectView';

const classes = {
  form: `${PREFIX}-form`,
  input: `${PREFIX}-input`,
};

const StyledForm = styled(FormControl)<FormControlProps>(() => ({
  [`&.${classes.form}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '1rem',
  },

  [`& .${classes.input}`]: {
    width: '100%',
  },
}));

type UsersSelectProps = {
  usersSelected: number[] | number | '',
  handleChange: (event: SelectChangeEvent<number[] | number>, child: React.ReactNode) => void,
  name: string,
  userTypeIds?: number[],
  multiple?: boolean,
  toRemove?: number[],
  doFetch?: boolean,
  label?: string,
  disabled?: boolean,
}

export default function UsersSelect(props: UsersSelectProps) {
  const {
    usersSelected,
    handleChange,
    userTypeIds = [],
    multiple,
    name,
    toRemove,
    doFetch = true,
    label = 'Utilisateurs',
    disabled,
  } = props;

  const { usersSortedByFirstName } = useUsersFetch({ withInactive: false, doFetch });
  const users = useAppSelector((state) => selectUserFilteredByType(state.users, userTypeIds));

  const usersFilteredSortedByFirstName = UsersUtils.sortedByFirstName(users);

  const renderChips = (selected: number[] | number | '') => {
    if (Array.isArray(selected) && Array.isArray(usersSelected)) {
      return <UserChips users={usersSortedByFirstName} selected={usersSelected} />;
    }
    const selectedUser = users.find((user) => user.id === usersSelected);
    return selectedUser ? new User(selectedUser).fullNameWithManagerInfos(undefined) : '';
  };

  return (
    <StyledForm className={classes.form} variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        labelId="users"
        id="users"
        name={name}
        label={label}
        className={classes.input}
        multiple={multiple}
        value={(users.length && usersSelected) || (multiple ? [] : '')}
        onChange={handleChange}
        renderValue={renderChips}
        disabled={disabled}
      >
        {usersFilteredSortedByFirstName.filter((user) => !toRemove?.includes(user.id))
          .map((user: UserType) => (
            <MenuItem
              key={user.id}
              value={user.id}
              sx={{ fontSize: globalTheme.fontSize.medium }}
            >
              {(user.first_name && user.last_name)
                ? `${user.first_name} ${user.last_name}`
                : user.email}
            </MenuItem>
          ))}
      </Select>
    </StyledForm>
  );
}
