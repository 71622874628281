import { combineReducers } from '@reduxjs/toolkit';

import actorsReducer from 'features/actors/state/slice';
import authReducer, {
  fetchLogout,
  fetchLoginPasswordSuccess,
  fetchLoginSuccess,
} from 'features/authentication/state/slice';
import categoryGroupsReducer from 'features/categoryGroups/state/slice';
import dataFilesReducer from 'features/dataFiles/state/slice';
import dataSourcesReducer from 'features/dataSource/state/slice';
import datavizReducer from 'features/dataviz/state/slice';
import fiscalYearReducer from 'features/fiscalYears/state/slice';
import harvestProjectReducer from 'features/harvestProjects/state/slice';
import keywordsReducer from 'features/keywords/state/slice';
import kpiViewReducer from 'features/kpiView/state/slice';
import navbarReducer from 'features/navbar/state/slice';
import periodReducer from 'features/period/state/slice';
import projectsReducer from 'features/projects/state/slice';
import transactionReducer from 'features/projectTransaction/state/slice';
import rankingTypesReducer from 'features/rankingTypes/state/slice';
import serpAnalysesReducer from 'features/serpAnalysis/state/slice';
import serpAnalysisIndexreducer from 'features/serpAnalysisIndex/state/slice';
import serpAnalysisPlanningReducer from 'features/serpAnalysisPlanning/state/slice';
import serpAnalysisScrappingReducer from 'features/serpAnalysisScrapping/state/slice';
import usersReducer from 'features/users/state/slice';

const resetStoreToActions = [
  fetchLogout.type,
  fetchLoginPasswordSuccess.type,
  fetchLoginSuccess.type,
];

const appReducer = combineReducers({
  actors: actorsReducer,
  authentication: authReducer,
  categoryGroups: categoryGroupsReducer,
  dataFiles: dataFilesReducer,
  dataSources: dataSourcesReducer,
  dataviz: datavizReducer,
  fiscalYears: fiscalYearReducer,
  harvestProjects: harvestProjectReducer,
  keywords: keywordsReducer,
  kpiViews: kpiViewReducer,
  navbar: navbarReducer,
  period: periodReducer,
  projects: projectsReducer,
  rankingTypes: rankingTypesReducer,
  serpAnalyses: serpAnalysesReducer,
  serpAnalysisIndex: serpAnalysisIndexreducer,
  serpAnalysisPlanning: serpAnalysisPlanningReducer,
  serpAnalysisScrapping: serpAnalysisScrappingReducer,
  transaction: transactionReducer,
  users: usersReducer,
});

const rootReducer = (state: any, action: any) => {
  if (resetStoreToActions.includes(action.type)) {
    // Reset the store to remove datas from previous user
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
