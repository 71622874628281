import { DATA_FILE_STATUS } from 'features/dataFiles/CONSTANT';
import { DataFileType } from 'features/dataFiles/types';

const emptyDataFile: DataFileType = {
  name: '',
  periode: '',
  original_name: '',
  data_file_status_id: 0,
  data_source_id: 0,
  data_file_group_id: null,
  actor_id: 0,
  scope_id: 1,
  id: 1,
  active: true,
  insertion_date: '',
  data_file_status: {
    name: '',
    description: '',
    id: 0,
    active: true,
    insertion_date: '',
  },
  data_source: {
    name: '',
    id: 0,
    insertion_date: '',
    active: true,
  },
  data_file_group: null,
  error_label: null,
};

export const getDataFileImportStatus = (dfArray: DataFileType[] | undefined) => {
  let isRecalculating = false;
  if (dfArray && dfArray.length > 0) {
    const lastDataFile = dfArray.reduce(
      (a, b) => (
        a.insertion_date > b.insertion_date ? a : b
      ),
      emptyDataFile,
    );

    if ([
      DATA_FILE_STATUS.initial_state,
      DATA_FILE_STATUS.to_import,
      DATA_FILE_STATUS.importing,
    ].includes(lastDataFile.data_file_status_id)) isRecalculating = true;
  }
  return isRecalculating;
};

export const getDataFileErrorStatus = (dfArray: DataFileType[] | undefined) => {
  let isError = false;
  if (dfArray && dfArray.length > 0) {
    const lastDataFile = dfArray.reduce(
      (a, b) => (
        a.insertion_date > b.insertion_date ? a : b
      ),
      emptyDataFile,
    );

    if ([
      DATA_FILE_STATUS.import_error,
      DATA_FILE_STATUS.import_error_no_retry,
    ].includes(lastDataFile.data_file_status_id)) isError = true;
  }
  return isError;
};

export const getDataFileErrorMessage = (dfArray: DataFileType[] | undefined) => {
  let errorMessage: string | null = null;
  if (dfArray && dfArray.length > 0) {
    const lastDataFile = dfArray.reduce(
      (a, b) => (
        a.insertion_date > b.insertion_date ? a : b
      ),
      emptyDataFile,
    );

    if ([
      DATA_FILE_STATUS.import_error,
      DATA_FILE_STATUS.import_error_no_retry,
    ].includes(lastDataFile.data_file_status_id)) errorMessage = lastDataFile.error_label ?? '';
  }
  return errorMessage;
};
