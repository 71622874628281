import React from 'react';
import { NavLink } from 'react-router-dom';

import { styled, Typography, TypographyProps } from '@mui/material';

const PREFIX = 'MenuTitle';

const classes = {
  title: `${PREFIX}-title`,
  projectTitle: `${PREFIX}-projectTitle`,
};

const StyledTypo = styled(Typography)<TypographyProps>(({ theme }) => ({
  [`&.${classes.title}`]: {
    textTransform: 'uppercase',
    letterSpacing: '0.1rem',
    fontSize: theme.fontSize.mediumLarge,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },

}));

type MenuTitleProps = {
  title: string | undefined,
  titleAlign?: string,
  titleColor?: string,
  titleWeight?: string,
  link?: string | undefined,
}

function MenuTitle(props: MenuTitleProps) {
  const {
    title,
    titleAlign = 'center',
    titleColor = 'info.main',
    titleWeight = 'fontWeightBold',
    link,
  } = props;

  return (
    <StyledTypo
      variant="h2"
      className={classes.title}
      sx={{
        textAlign: titleAlign,
        color: titleColor,
        fontWeight: titleWeight,
      }}
    >
      {
        link
          ? (
            <NavLink
              to={link}
            >
              <Typography
                sx={{
                  textAlign: titleAlign,
                  color: titleColor,
                  fontWeight: titleWeight,
                }}
              >
                {title}
              </Typography>
            </NavLink>
          )
          : title
      }
    </StyledTypo>
  );
}

export default MenuTitle;
