import React from 'react';

import {
  FormControl,
} from '@mui/material';

import DropDown from 'components/dropDown/DropDown';

import { PLANNING_MANUEL } from '../state/CONSTANTS';
import useSerpAnalysisPlannings from '../state/useSerpAnalysisPlannings';

type PlanningsDropDownProps = {
  value: number | '',
  handleChange: any,
  name?: string,
  width?: string,
  error?: boolean,
  size?: 'medium' | 'small',
  label?: string,
  optionsChoice?: 'serpPlanning' | 'serpManual',
  optionsFilter?: number[],
}

function PlanningsDropDown(props: PlanningsDropDownProps) {
  const { plannings } = useSerpAnalysisPlannings();
  const {
    value,
    handleChange,
    width,
    error,
    name,
    size = 'medium',
    label = 'Fréquence',
    optionsChoice = 'serpPlanning',
    optionsFilter = [],
  } = props;

  const options = optionsChoice === 'serpPlanning' ? plannings : Object.values(PLANNING_MANUEL);

  const filteredPlannings = optionsFilter && optionsFilter.length
    ? options.filter((pln) => optionsFilter.includes(pln.id)).sort((a, b) => b.id - a.id)
    : options;

  return (
    <FormControl>
      <DropDown
        value={value}
        name={name}
        options={filteredPlannings}
        onChange={handleChange}
        getOptionValue={(pl) => pl.id}
        getOptionLabel={(pl) => pl.label}
        size={size}
        label={label}
        fontSize="medium"
        width={width}
        error={error}
        data-testid="planning-option"
      />
    </FormControl>
  );
}

export default PlanningsDropDown;
