import * as React from 'react';

import {
  Box, Paper, Popper, styled, BoxProps,
} from '@mui/material';

export interface GridCellExpandProps {
  width: number;
  children: JSX.Element,
}

const PREFIX = 'GridCellExpand';

const classes = {
  core: `${PREFIX}-core`,
  container: `${PREFIX}-container`,
  subContainer: `${PREFIX}-subContainer`,
  popper: `${PREFIX}-popper`,
  text: `${PREFIX}-text`,
  contentPopper: `${PREFIX}-contentPopper`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    alignItems: 'center',
    lineHeight: '24px',
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
  },
  [`& .${classes.container}`]: {
    display: 'block',
    position: 'absolute',
    top: 0,
  },
  [`& .${classes.subContainer}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.fontSize.xsmall,
  },
  [`& .${classes.popper}`]: {
    marginLeft: -17,
  },
  [`& .${classes.contentPopper}`]: {
    fontSize: theme.fontSize.xsmall,
  },
}));

function isOverflown(element: Element): boolean {
  return (
    element.scrollHeight > element.clientHeight
    || element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo((
  props: GridCellExpandProps,
) => {
  const { width, children } = props;
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };
  return (
    <StyledBox
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classes.core}
      data-testid="container-box"
    >
      <Box
        ref={cellDiv}
        width={width}
        className={classes.container}
      />
      <Box
        ref={cellValue}
        className={classes.subContainer}
        data-testid="cell-value"
      >
        {children}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          className={classes.popper}
          sx={{ maxWidth: '300px' }}
          data-testid="popper-grid-cell-expand"
        >
          <Paper
            elevation={1}
            sx={{
              minHeight: wrapper.current!.offsetHeight - 3,
              padding: '8px',
            }}
            className={classes.contentPopper}
          >
            <Box className={classes.contentPopper}>
              {children}
            </Box>
          </Paper>
        </Popper>
      )}
    </StyledBox>
  );
});

export default GridCellExpand;
