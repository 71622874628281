import React from 'react';

import {
  Box, styled, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Stack,
} from '@mui/material';

import formatThousands from 'utils/formatThousands';

interface RadioFilterProps {
  onChange: Function,
  name: string,
  value: string | undefined,
  defaultValue: string,
  elements: Array<{
    label: string,
    value: string,
  }>,
  filtersLegend: {
    rankMin: string | undefined,
    rankMax: string | undefined,
    volMin: string | undefined,
    volMax: string | undefined,
    searchIntent: string | undefined,
  }
}

const PREFIX = 'RadioFilter';

const classes = {
  radioGroup: `${PREFIX}-radioGroup`,
  label: `${PREFIX}-label`,
  lightText: `${PREFIX}-lightText`,
  boldText: `${PREFIX}-boldText`,
};

const StyledStack = styled(Stack)(({ theme }) => ({
  [`& .${classes.radioGroup}`]: {
    display: 'flex',
    flexDirection: 'row',
  },

  [`& .${classes.label}`]: {
    fontSize: theme.fontSize.medium,
  },

  [`& .${classes.lightText}`]: {
    display: 'inline',
    fontSize: theme.fontSize.medium,
    color: theme.palette.info.main,
  },

  [`& .${classes.boldText}`]: {
    display: 'inline',
    fontSize: theme.fontSize.medium,
    fontWeight: 'bold',
  },
}));

function RadioFilterComponent(props: RadioFilterProps) {
  const {
    onChange,
    name,
    value,
    defaultValue,
    elements,
    filtersLegend,
  } = props;

  return (
    <StyledStack direction="column" alignItems="center">
      <FormControl>
        <RadioGroup
          onChange={(e) => onChange(e.target.value)}
          defaultValue={defaultValue}
          name={name}
          className={classes.radioGroup}
          value={value && value !== '' ? value : undefined}
        >
          {
          elements.map((element) => (
            <FormControlLabel
              key={element.value}
              value={element.value}
              control={<Radio />}
              label={(
                <Typography className={classes.label}>{element.label}</Typography>
              )}
            />
          ))
        }
        </RadioGroup>
      </FormControl>
      {filtersLegend && (
        <Box>
          <Typography className={classes.lightText}>
            Intention de recherche :
            {' '}
          </Typography>
          <Typography className={classes.boldText} data-testid="searchIntent">
            {filtersLegend.searchIntent}
            {' '}
            &bull;
            {' '}
          </Typography>
          <Typography className={classes.lightText}>
            Positions
            {' '}
          </Typography>
          <Typography className={classes.boldText} data-testid="rankingValue">
            [
            {filtersLegend.rankMin}
            -
            {filtersLegend.rankMax}
            ]
            {' '}
            &bull;
            {' '}
          </Typography>
          <Typography className={classes.lightText}>
            Demandes mensuelles
            {' '}
          </Typography>
          <Typography className={classes.boldText} data-testid="filtersValue">
            [
            {formatThousands(parseInt(filtersLegend.volMin || '0', 10))}
            -
            {formatThousands(parseInt(filtersLegend.volMax || '0', 10))}
            ]
          </Typography>
        </Box>
      )}
    </StyledStack>
  );
}

export default RadioFilterComponent;
