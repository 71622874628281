import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import NotFound from 'components/notFound/NotFound';
import AdminMenu from 'features/navbar/admin/AdminMenu';
import RightDrawer from 'features/navbar/Drawers/RightDrawer';
import ProjectMenu from 'features/navbar/project/ProjectMenu';
import routesAdmin from 'pages/admin/router';
import AgenceWamMenu from 'pages/agencewam/navbar/AgenceWamMenu';
import routesAgenceWam from 'pages/agencewam/Router';
import DataWamMenu from 'pages/datawam/navbar/DataWamMenu';
import routesDataWam from 'pages/datawam/Router';
import Home from 'pages/home/Home';
import Logged from 'pages/layout/Logged';
import ProfileMenu from 'pages/profile/navbar/ProfileMenu';
import routesProfile from 'pages/profile/Router';
import routesProject from 'pages/projects/Router';
import { CustomRouteObject } from 'pages/projects/types';
import TeamMenu from 'pages/team/navbar/TeamMenu';
import routesTeam from 'pages/team/Router';

import {
  ADMIN,
  AGENCE_WAM,
  DATAWAM,
  HOME,
  PROFILE,
  PROJECTS,
  TEAM,
} from './CONSTANTS';

const routesConfig: CustomRouteObject[] = [
  {
    path: HOME,
    element: <Logged />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: PROJECTS,
        element: <RightDrawer menu={<ProjectMenu />} />,
        children: routesProject,
      },
      {
        path: DATAWAM,
        element: <RightDrawer menu={<DataWamMenu />} />,
        children: routesDataWam,
      },
      {
        path: AGENCE_WAM,
        element: <RightDrawer menu={<AgenceWamMenu />} />,
        children: routesAgenceWam,
      },
      {
        path: TEAM,
        element: <RightDrawer menu={<TeamMenu />} />,
        children: routesTeam,
      },
      {
        path: PROFILE,
        element: <RightDrawer menu={<ProfileMenu />} />,
        children: routesProfile,
      },
      {
        path: ADMIN,
        element: <RightDrawer menu={<AdminMenu />} />,
        children: routesAdmin,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

const router = createBrowserRouter(routesConfig);

export default router;
export {
  routesConfig,
};
