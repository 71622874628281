import React from 'react';

import {
  Box,
  BoxProps,
  Typography,
  styled,
} from '@mui/material';

import { UserType } from '../types';
import User from '../User';

const PREFIX = 'UserLastLoginView';

const classes = {
  labels: `${PREFIX}-labels`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.labels}`]: {
    color: theme.palette.info.main,
    fontSize: theme.fontSize.medium,
  },
}));

type LastLoginViewProps = {
  userItem: UserType
}

export default function UserLastLoginView(props: LastLoginViewProps): JSX.Element {
  const { userItem } = props;
  const user = new User(userItem);

  return (
    <StyledBox>
      {user.lastLoginInfos && (
        <Typography className={classes.labels}>
          {user.lastLoginInfos}
        </Typography>
      )}
    </StyledBox>
  );
}
