/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createSlice, PayloadAction, createSelector, current,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import {
  DatavizRequest, DatavizResult, DatavizFailure, DataInfos,
} from '../types';

export interface State {
  isLoading: boolean;
  charts: {
    [key: string]: DataInfos,
  };
  exports: {
    [key: string]: DataInfos,
  };
}

const initialState: State = {
  isLoading: false,
  charts: {},
  exports: {},
};

export const datavizSlice = createSlice({
  name: 'dataviz',
  initialState,
  reducers: {
    resetDataviz: (state: State) => {
      state.isLoading = true;
      state.charts = {};
      state.exports = {};
    },
    fetchChartData: (state: State, action: PayloadAction<DatavizRequest>) => {
      state.isLoading = true;
      state.charts = {
        ...state.charts,
        [action.payload.key]: {
          isLoading: true,
          error: '',
          data: {
            values: undefined,
          },
        },
      };
    },
    fetchChartDataSuccess: (state: State, action: PayloadAction<DatavizResult>) => {
      state.isLoading = false;
      state.charts = {
        ...state.charts,
        [action.payload.key]: {
          error: '',
          isLoading: false,
          data: {
            values: action.payload.values,
          },
        },
      };
    },
    fetchChartDataFailure: (state: State, action: PayloadAction<DatavizFailure>) => {
      state.isLoading = false;
      state.charts = {
        ...state.charts,
        [action.payload.key]: {
          isLoading: false,
          error: action.payload.error,
          data: {
            values: [],
          },
        },
      };
    },
    fetchExportData: (state: State, action: PayloadAction<DatavizRequest>) => {
      state.exports = {
        ...state.exports,
        [action.payload.key]: {
          isLoading: true,
          error: '',
          data: {
            values: undefined,
          },
        },
      };
    },
    fetchExportDataSuccess: (state: State, action: PayloadAction<DatavizResult>) => {
      state.exports = {
        ...state.exports,
        [action.payload.key]: {
          error: '',
          isLoading: false,
          data: {
            values: action.payload.values,
          },
        },
      };
    },
    fetchExportDataFailure: (state: State, action: PayloadAction<DatavizFailure>) => {
      state.exports = {
        ...state.exports,
        [action.payload.key]: {
          isLoading: false,
          error: action.payload.error,
          data: {
            values: [],
          },
        },
      };
    },
  },
});

export const selectDatavizDataWithTmpId = createSelector(
  (state: State) => state.charts,
  (_: any, chartKey: string) => chartKey,
  (charts, chartKey) => charts[chartKey].data.values?.map((kw) => ({
    ...kw,
    tmpId: uuidv4(),
  })) ?? [],
);

export const selectExportByKey = createSelector(
  (state: State) => state.exports,
  (_: any, chartKey: string) => chartKey,
  (exports, chartKey) => exports[chartKey],
);

export const selectDatasByKey = createSelector(
  (state: State) => state.charts,
  (_: any, chartKey: string) => chartKey,
  (charts, chartKey) => charts[chartKey],
);

export const {
  fetchChartData,
  fetchChartDataSuccess,
  fetchChartDataFailure,
  fetchExportData,
  fetchExportDataSuccess,
  fetchExportDataFailure,
  resetDataviz,
} = datavizSlice.actions;

export default datavizSlice.reducer;
