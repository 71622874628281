import capitalize from 'lodash/capitalize';

import { CreateModifyExternalUserForm } from './actions/createModifyExternalUserConfig';
import USER_TYPES, { PREMIERE_PLACE_DOMAIN } from './CONSTANTS';
import { UserCreatePayload, UserType } from './types';

export default class UsersUtils {
  static sortedByFirstName (users: UserType[]) {
    return users.filter((user) => user.active)
      .sort((a, b) => {
        if (!a.first_name || !b.first_name) return 0;
        return a.first_name.localeCompare(b.first_name);
      });
  }

  static removeManagerFromList (users: UserType[], managerId: number | undefined) {
    return users.filter((user) => user.id !== managerId);
  }

  static removeManagerFromIdsList (users: number[], managerId: number | undefined) {
    return users.filter((user) => user !== managerId);
  }

  static findById (userId: number, users: UserType[]) {
    return users.find((user) => user.id === userId);
  }

  static findByIdFromEmployees (
    userId: number,
    managerId: number | undefined,
    users: UserType[],
  ) {
    const employees = UsersUtils.removeManagerFromList(users, managerId);
    return UsersUtils.findById(userId, employees);
  }

  static createUserPayload(formValues: CreateModifyExternalUserForm): UserCreatePayload {
    const payload: UserCreatePayload = {
      email: formValues.email,
      password: formValues.password,
      projects: [],
      user_type_id: USER_TYPES.external.id,
    };

    const [userName, domain] = formValues.email.split('@');

    // If the user is from PP, make it internal + get first and last name
    if (domain === PREMIERE_PLACE_DOMAIN) {
      const [firstName, lastName] = userName.split('.');
      payload.user_type_id = USER_TYPES.internal.id;

      // Check that at last one of the two is not empty
      if (firstName || lastName) {
        payload.first_name = capitalize(firstName);
        payload.last_name = capitalize(lastName);
      }
    }

    if (formValues.projectsConsult?.length) {
      payload.projects = formValues.projectsConsult
        .filter((projectId) => typeof projectId !== 'string')
        .map(
          (projectId) => ({ project_id: projectId, is_consultant: true }),
        );
    }

    if (formValues.projectsEdit?.length) {
      payload.projects = [
        ...payload.projects,
        ...formValues.projectsEdit
          .filter((projectId) => typeof projectId !== 'string')
          .map(
            (projectId) => ({ project_id: projectId, is_consultant: false }),
          ),
      ];
    }

    return payload;
  }

  static isUserPP(email: string) {
    // eslint-disable-next-line
    const [_, domain] = email.split('@');

    return domain !== undefined && domain === PREMIERE_PLACE_DOMAIN;
  }
}
