import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import moment from 'moment';

import useDocumentTitle from 'common/documentTitleHook';
import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import { AppDispatch } from 'common/store';
import { selectClients } from 'features/actors/state/slice';
import useActors from 'features/actors/state/useActors';
import { DEVICE_TYPE } from 'features/deviceType/CONSTANTS';
import { selectProjectById } from 'features/projects/state/slice';
import { RANK_SLICES } from 'features/rankSlice/CONSTANT';
import { SI_NC } from 'features/serpAnalysis/components/serpDetails/parametersBlock/CONSTANT';
import RankingEvolution from 'features/serpAnalysis/components/serpDetails/RankingEvolution';
import { fetchSerpAnalyses, selectAnalysisById } from 'features/serpAnalysis/state/slice';
import { RankingEvolutionFilters } from 'features/serpAnalysis/types';
import { convertDate, DATE_NUMBERS_HOURS_SC } from 'utils/formatDate';
import { splitIdName } from 'utils/urlManipulation';

function Ranking () {
  const dispatch: AppDispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const { idNameProject, idNameAnalysis } = useParams();
  const { id: idProject } = splitIdName(idNameProject);
  const { id: idAnalysis } = splitIdName(idNameAnalysis);

  const project = useAppSelector((state) => (
    selectProjectById(state.projects, idProject)));
  const scopeId = project?.scopes[0]?.id ?? 0;
  const analysis = useAppSelector((state) => (
    selectAnalysisById(state.serpAnalyses, idAnalysis)));

  useActors({ scopeId });
  const clients = useAppSelector((state) => selectClients(state.actors));

  const intentList = analysis?.sa_keyword_intentions || [];
  const siAllList = (intentList.length > 0)
    ? [
      ...intentList,
      SI_NC,
    ]
    : [SI_NC];

  const firstDayOfMonth = convertDate(moment().startOf('month').toDate(), DATE_NUMBERS_HOURS_SC);

  const lastDayOfMonth = convertDate(moment().endOf('month').toDate(), DATE_NUMBERS_HOURS_SC);

  const rankingFilters: RankingEvolutionFilters = {
    type: 'rankingEvolutionFilters',
    datavizMode: searchParams.get('datavizMode') || 'rank',
    rankingTypeId: searchParams.get('rankingTypeId')
      ? searchParams.getAll('rankingTypeId').map((id) => parseInt(id, 10))
      : [1],
    actorIds: searchParams.get('actorIds')
      ? searchParams.getAll('actorIds').map((id) => parseInt(id, 10))
      : clients.map((client) => client.id ?? 0),
    periodeBegin: searchParams.get('periodeBegin') || firstDayOfMonth,
    periodeEnd: searchParams.get('periodeEnd') || lastDayOfMonth,
    intentionIds: searchParams.get('intentionIds')
      ? searchParams.getAll('intentionIds').map((id) => parseInt(id, 10))
      : [],
    deviceTypeId: parseInt(searchParams.get('deviceTypeId')
    || JSON.stringify(
      analysis?.device_type_id !== 3
        ? analysis?.device_type_id
        : DEVICE_TYPE.MOBILE,
    ), 10) || 2,
    timeSeries: searchParams.get('timeSeries') || 'day',
    rankSliceIds: searchParams.get('rankSliceIds')
      ? searchParams.getAll('rankSliceIds').map((id) => parseInt(id, 10))
      : RANK_SLICES.map((rs) => rs.id),
    keywordIds: searchParams.get('keywordIds')
      ? searchParams.getAll('keywordIds').map((id) => parseInt(id, 10))
      : [],
  };

  useEffect(() => {
    if (scopeId && idAnalysis !== undefined && !analysis) {
      dispatch(fetchSerpAnalyses({ scopeId }));
    }
  }, [scopeId, idAnalysis, analysis]);

  useDocumentTitle(`Suivi du positionnement : ${analysis?.label} | benhur`);

  return (
    <Box>
      <Typography variant="h1">Suivi du positionnement</Typography>
      <RankingEvolution
        scopeId={scopeId}
        analysis={analysis}
        project={project}
        rankingFilters={rankingFilters}
        intentList={siAllList}
      />
    </Box>
  );
}

export default Ranking;
