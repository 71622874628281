import React from 'react';

import {
  Box,
  Typography,
} from '@mui/material';

import MainBox from 'components/layout/MainBox';
import ActorsDatagrid from 'features/actors/list/ActorsDatagrid';

type FormActorsProps = {
  scopeId: number,
  projectId: number,
}

function SetupActorsView(props: FormActorsProps) {
  const {
    scopeId,
    projectId,
  } = props;

  return (
    <Box>
      <Typography variant="h1">Indiquez les acteurs à surveiller dans vos KPI</Typography>
      <MainBox>
        <ActorsDatagrid scopeId={scopeId} projectId={projectId} />
      </MainBox>
    </Box>
  );
}

export default SetupActorsView;
