import React from 'react';

import { Typography } from '@mui/material';

export default function ValidateActionMessage(): JSX.Element {
  return (
    <Typography>
      <Typography component="span" variant="bold">
        Opérations terminées
      </Typography>
      {' '}
      avec succès
    </Typography>
  );
}
