import React, { useState } from 'react';

import { MenuItem, Typography } from '@mui/material';

import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';

type UpdateDataFileProps = {
  dataFileId: number,
  handleCloseMenu: Function,
  handleConfirm: Function,
  error: boolean,
}

export default function UpdateDataFile(props: UpdateDataFileProps): JSX.Element {
  const {
    dataFileId,
    handleCloseMenu,
    handleConfirm,
    error,
  } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <MenuItem
      sx={{ fontSize: globalTheme.fontSize.middlesmall }}
      onClick={() => { if (!openModal) setOpenModal(true); handleCloseMenu(); }}
      data-testid="update-data-file-menu-item"
    >
      Traiter
      <Modal
        title={(
          (
            error && (
              <Typography>
                Vous devez
                {' '}
                <Typography component="span" variant="bold">
                  qualifier
                </Typography>
                {' '}
                le fichier
              </Typography>
            )
          ) || (
            <Typography>
              <Typography component="span" variant="bold">Traiter</Typography>
              {' '}
              ce fichier
            </Typography>
          )
        )}
        displayConfirm={!error}
        actionConfirm={
          () => {
            handleConfirm(dataFileId);
            setOpenModal(false);
          }
        }
        actionCancel={() => setOpenModal(false)}
        isOpen={openModal}
        dataTestId="update-data-file-update-modal"
      />
    </MenuItem>
  );
}
