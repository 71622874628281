import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Stack, Typography } from '@mui/material';
import moment from 'moment';

import useDocumentTitle from 'common/documentTitleHook';
import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import MainBox from 'components/layout/MainBox';
import { KPI_VIEW_TYPE } from 'features/kpiView/CONSTANTS';
import { createColumnsShareOfVoice } from 'features/kpiView/list/columns';
import ViewDataGrid from 'features/kpiView/list/ViewDataGrid';
import useKpiViewByAnalysis from 'features/kpiView/state/useKpiViewByAnalysis';
import { KpiViewType } from 'features/kpiView/types';
import { selectProjectById } from 'features/projects/state/slice';
import { fetchSerpAnalyses, selectAnalysisById } from 'features/serpAnalysis/state/slice';
import { convertDate, DATE_API } from 'utils/formatDate';
import { splitIdName } from 'utils/urlManipulation';

export const notEligibleKpiView: KpiViewType = {
  id: -1,
  device_type_id: 0,
  start_date: convertDate(moment().toDate(), DATE_API),
  insertion_date: '',
  kpi_view_export_id: 0,
  kpi_view_export_status_id: 0,
  kpi_view_type_id: 0,
  scope_id: 0,
  time_serie: '',
  label: 'disponible le mois prochain',
  active: false,
  best_rank: false,
  sa_rank_slices: [],
  keywords: [],
  categories: [],
  actors: [],
  ranking_types: [],
  sa_keyword_intentions: [],
  serp_analyses: [],
};

export const generatedKpiView = (): KpiViewType => {
  const startDate = moment();
  startDate.subtract(1, 'M');

  return {
    id: 0,
    device_type_id: 0,
    start_date: convertDate(startDate.toDate(), DATE_API),
    insertion_date: '',
    kpi_view_export_id: 0,
    kpi_view_export_status_id: 0,
    kpi_view_type_id: 0,
    scope_id: 0,
    time_serie: '',
    label: `génération le 3 ${moment().format('MMMM')}`,
    active: false,
    best_rank: false,
    sa_rank_slices: [],
    keywords: [],
    categories: [],
    actors: [],
    ranking_types: [],
    sa_keyword_intentions: [],
    serp_analyses: [],
  };
};

function ShareOfVoice() {
  const dispatch = useAppDispatch();
  const { idNameProject, idNameAnalysis } = useParams();
  const { id: idProject } = splitIdName(idNameProject);
  const { id: idAnalysis } = splitIdName(idNameAnalysis);

  const project = useAppSelector((state) => (
    selectProjectById(state.projects, idProject)));

  const scopeId = project?.scopes[0].id ?? 0;

  const analysis = useAppSelector((state) => (
    selectAnalysisById(state.serpAnalyses, idAnalysis)));

  useEffect(() => {
    if (scopeId && idAnalysis !== undefined && !analysis) {
      dispatch(fetchSerpAnalyses({ scopeId }));
    }
  });

  const { kpiViews, isLoading } = useKpiViewByAnalysis(
    scopeId,
    analysis?.id ?? 0,
    [KPI_VIEW_TYPE.captation.id],
  );

  const isEligible = [2, 3].includes(analysis?.dfs_data_type.id ?? 0)
  && analysis?.is_search_volume_computed;

  useDocumentTitle(`${project?.name} : part de voix de la catégorie ${analysis?.label} | benhur`);

  const getKpiViews = () => {
    if (isLoading) return [];

    const now = moment();

    if (!isEligible) return [notEligibleKpiView];

    const formatedView = [...kpiViews];

    // Check if the kpi view for the previous month has been generated
    const isViewCurrentMonthOk = kpiViews.find((kv) => {
      const date = moment(kv.start_date).subtract(1, 'M');
      return date.year() === now.year() && date.month() === now.month();
    });

    // If the kpi view for the current month is not generated yet, we add a placeholder row
    if (!isViewCurrentMonthOk && now.date() <= 3) {
      formatedView.push(generatedKpiView());
    }

    formatedView.push(notEligibleKpiView);
    formatedView.sort((a, b) => moment(b.start_date).diff(moment(a.start_date)));

    return formatedView;
  };

  return (
    <Box>
      <Typography variant="h1">
        Suivez la part de voix de vos acteurs
      </Typography>
      <Stack spacing={2}>
        <MainBox>
          <ViewDataGrid
            columns={createColumnsShareOfVoice(project?.id ?? 0, scopeId, isEligible)}
            isLoading={isLoading}
            kpiViews={getKpiViews()}
          />
        </MainBox>
      </Stack>
    </Box>
  );
}

export default ShareOfVoice;
