import React, { useState } from 'react';

import { MenuItem, Typography, Stack } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import useDataFiles from 'features/dataFiles/state/useDataFiles';
import USER_TYPES from 'features/users/CONSTANTS';

import Actor from '../Actor';
import { selectActorById, deleteActor } from '../state/slice';

type DeletePatternsProps = {
  patternId: number,
  actorId: number,
  siteId: number,
  handleCloseMenu: Function,
  disabled?: boolean,
}

export default function DeletePatterns(props: DeletePatternsProps): JSX.Element {
  const {
    patternId,
    handleCloseMenu,
    actorId,
    siteId,
    disabled,
  } = props;
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { currentUser } = useAppSelector(
    (state) => state.authentication,
  );

  const temp = useAppSelector((state) => selectActorById(state.actors, actorId));
  const actor = temp && new Actor(temp);

  const { actorDataFiles } = useDataFiles({ scopeId: actor?.actorScope || 0, actorId });

  const handleConfirm = () => {
    if (actor && actor.formatPatternsForDatagrid().length > 1) {
      actor.deleteSiteUrlPattern(
        dispatch,
        patternId,
        siteId,
      );
    } else if (actor) {
      dispatch(deleteActor({ actorId: actor.actorId! }));
    }

    setOpenModal(false);
  };

  return (
    <>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={() => { setOpenModal(true); handleCloseMenu(); }}
        data-testid="delete-pattern-menu-button"
        disabled={disabled}
      >
        Supprimer la règle
      </MenuItem>
      {
        (
          currentUser
          && currentUser.user_type_id === USER_TYPES.external.id
          && actorDataFiles
          && actorDataFiles.length > 0
        )
          ? (
            <Modal
              title={(
                <Stack direction="column">
                  <Typography>
                    <Typography component="span" variant="bold">Suppression impossible</Typography>
                    {' '}
                    : contactez votre chef de projet Wam
                  </Typography>
                </Stack>
              )}
              actionConfirm={() => setOpenModal(false)}
              isOpen={openModal}
              dataTestId="delete-pattern-modal"
              displayCancel={false}
            />
          )
          : (
            <Modal
              title={(
                <Stack direction="column">
                  <Typography>
                    <Typography component="span" variant="bold">Supprimer</Typography>
                    {' '}
                    cet acteur et ses données CSV importées
                  </Typography>
                  <Typography
                    variant="body3"
                    color={globalTheme.palette.error.main}
                    style={{ fontWeight: 'bold' }}
                  >
                    attention, action irréversible !
                  </Typography>
                </Stack>
              )}
              actionConfirm={handleConfirm}
              actionCancel={() => setOpenModal(false)}
              isOpen={openModal}
              dataTestId="delete-pattern-modal"
            />
          )
      }
    </>
  );
}
