import React from 'react';

import { DriveFolderUploadOutlined } from '@mui/icons-material';
import {
  Box, Button, Stack, StackProps, styled,
} from '@mui/material';

import InputSearch from 'components/input/inputSearch';
import AddKeywordsModalsChoice from 'features/keywords/modals/addKeywords/ModalsChoice';
import { ANALYSIS_STATUS } from 'features/serpAnalysis/CONSTANT';
import { Analysis } from 'features/serpAnalysis/types';

import KeywordsDeleteModal from '../modals/KeywordsDeleteModal';

const PREFIX = 'KeywordButtonActions';

const classes = {
  core: `${PREFIX}-core`,
  numberSelected: `${PREFIX}-numberSelected`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: '42px',
  },

  [`& .${classes.numberSelected}`]: {
    marginRight: '18px',
    fontSize: theme.fontSize.medium,
  },
}));

type KeywordButtonActionsProps = {
  handleExport?: Function,
  idsKwSelected: number[],
  keywordsSelected: number,
  handleInputChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  analysis: Analysis | undefined,
  disabled?: boolean,
}

function KeywordButtonActionsView(props: KeywordButtonActionsProps): JSX.Element {
  const {
    handleExport,
    idsKwSelected,
    keywordsSelected,
    handleInputChange,
    analysis,
    disabled,
  } = props;

  return (
    <StyledStack
      direction="row"
      justifyContent="space-between"
      className={classes.core}
    >
      <InputSearch
        handleInputChange={handleInputChange}
        label="Chercher un mot clé"
      />
      <Stack direction="row" spacing={2}>
        {keywordsSelected !== 0 && (
          <Stack direction="row" alignItems="center">
            <Box className={classes.numberSelected}>
              {`${keywordsSelected} `}
              mot
              {
                (keywordsSelected > 1
                  ? 's '
                  : ' '
                )
              }
              clé
              {
                (keywordsSelected > 1
                  ? 's'
                  : ' '
                )
              }
            </Box>
            <KeywordsDeleteModal
              keywordsSelected={keywordsSelected}
              keywordsToDelete={idsKwSelected}
              analysis={analysis}
              disabled={disabled}
            />
          </Stack>
        )}
        {keywordsSelected === 0 && (
        <>
          {handleExport && (
            <Button
              onClick={() => handleExport()}
              variant="contained"
              startIcon={<DriveFolderUploadOutlined />}
              disabled={(
                analysis?.serp_analysis_status_id === ANALYSIS_STATUS.IN_PROGRESS
              )}
            >
              Export CSV
            </Button>
          )}
          <AddKeywordsModalsChoice
            nameAnalysis={analysis?.label}
            serpAnalysisId={analysis?.id}
          />
        </>
        )}
      </Stack>
    </StyledStack>
  );
}

export default KeywordButtonActionsView;
