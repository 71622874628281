import { useCallback, useEffect, useState } from 'react';

import { DEVICE_TYPE } from 'features/deviceType/CONSTANTS';
import { DEFAULT_PRICE_PER_KEYWORD } from 'features/serpAnalysis/CONSTANT';
import PLANNING_TYPE from 'features/serpAnalysisPlanning/state/CONSTANTS';

import { Simulator } from './types';

const useSimulator = (keywordPrice?: number): Simulator => {
  const [keywordNumber, setKeywordNumber] = useState<number>(0);
  const [deviceType, setDeviceType] = useState<number>(DEVICE_TYPE.DESKTOP_MOBILE);
  const [frequency, setFrequency] = useState<number>(3);
  const [nbMonths, setNbMonths] = useState<number>(1);
  const [ppk, setPpk] = useState<number>(keywordPrice || DEFAULT_PRICE_PER_KEYWORD);

  const [operations, setOperations] = useState<string>('');
  const [result, setResult] = useState<number>(0);

  const resetFields = () => {
    setKeywordNumber(0);
    setFrequency(1);
    setDeviceType(DEVICE_TYPE.DESKTOP_MOBILE);
    setNbMonths(1);
    setPpk(keywordPrice || DEFAULT_PRICE_PER_KEYWORD);
  };

  const calculate: Function = () => {
    let periodeMultiplier = 1;
    switch (frequency) {
      case PLANNING_TYPE.DAILY:
        periodeMultiplier = 31 * nbMonths;
        break;
      case PLANNING_TYPE.MONTHLY:
        periodeMultiplier = 1 * nbMonths;
        break;
      default:
        periodeMultiplier = 1;
    }

    setOperations(
      `${keywordNumber ?? 0} * `
      + `${deviceType === DEVICE_TYPE.DESKTOP_MOBILE ? 2 : 1} * `
      + `${periodeMultiplier} * `
      + `${ppk && ppk >= DEFAULT_PRICE_PER_KEYWORD ? ppk : DEFAULT_PRICE_PER_KEYWORD}€`,
    );
    setResult(
      Math.round(
        (keywordNumber ?? 0)
        * (deviceType === 3 ? 2 : 1)
        * periodeMultiplier
        * (ppk && ppk >= DEFAULT_PRICE_PER_KEYWORD ? ppk : DEFAULT_PRICE_PER_KEYWORD) * 100,
      ) / 100,
    );
  };

  const isError = useCallback(() => (
    !keywordNumber || keywordNumber <= 0
    || ppk < DEFAULT_PRICE_PER_KEYWORD
    || !result
    || result <= 0
  ), [keywordNumber, ppk, result]);

  useEffect(() => calculate(), [
    keywordNumber,
    deviceType,
    frequency,
    nbMonths,
    ppk,
  ]);

  useEffect(() => {
    if (Number(keywordPrice)) setPpk(Number(keywordPrice));
  }, [keywordPrice]);

  return {
    keywordNumber,
    setKeywordNumber,
    deviceType,
    setDeviceType,
    frequency,
    setFrequency,
    nbMonths,
    setNbMonths,
    ppk,
    setPpk,
    operations,
    result,
    calculate,
    setResult,
    resetFields,
    isError,
  };
};

export default useSimulator;
