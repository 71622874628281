import * as yup from 'yup';

import { KPI_VIEW_TYPE } from 'features/kpiView/CONSTANTS';
import { KpiViewType, ModifyKpiViewFormType } from 'features/kpiView/types';

export const initValuesModifyView = (view: KpiViewType): ModifyKpiViewFormType => ({
  timeSerie: view.time_serie,
  startDate: view.start_date,
  viewName: view.label,
  serpAnalysisIds: view.serp_analyses.map((anl) => anl.id).sort((a, b) => a - b),
  keywords: view.keywords.map((kw) => kw.id).sort((a, b) => a - b),
  actors: view.actors.map((act) => act.id || 0).sort((a, b) => a - b),
  searchIntentions: view.sa_keyword_intentions.map((intn) => intn.id).sort((a, b) => a - b),
  ranks: view.sa_rank_slices.map((rs) => rs.id).sort((a, b) => a - b),
  rankingTypes: view.ranking_types.map((rt) => rt.id).sort((a, b) => a - b),
  categories: view.categories.map((cat) => cat.id).sort((a, b) => a - b),
  deviceType: view.device_type_id,
  export: view.kpi_view_export_id === 2,
  best_rank: view.kpi_view_type_id === KPI_VIEW_TYPE.tracking.id ? false : view.best_rank,
});

export const validationSchemaModifyView = yup.object().shape({
  timeSerie: yup.string().required('Champ requis'),
  startDate: yup.string()
    .required('La date de démarrage est obligatoire'),
  viewName: yup.string().trim().max(75, '75 caractères maximum')
    .required('Le nom de la vue est obligatoire'),
  serpAnalysisIds: yup.array().of(yup.number())
    .min(1, 'Vous devez sélectionner au moins une catégorie'),
  keywords: yup.array().of(yup.number()),
  actors: yup.array().of(yup.number()),
  searchIntentions: yup.array().of(yup.number()),
  ranks: yup.array().of(yup.number()),
  rankingTypes: yup.array().of(yup.number()),
  deviceType: yup.number(),
  export: yup.bool(),
});
