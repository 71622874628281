import React, { ChangeEventHandler } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  Button,
  Stack,
  StackProps,
  styled,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import theme from 'common/theme';
import InputSearch from 'components/input/inputSearch';
import { CREATE_PROJECTS } from 'pages/projects/ROUTES';
import { PROJECTS } from 'router/CONSTANTS';

const PREFIX = 'ProjectsDatagridToolbar';

const classes = {
  button: `${PREFIX}-secondButton`,
  label: `${PREFIX}-label`,
};

const StyledStack = styled(Stack)<StackProps>(() => ({
  [`& .${classes.button}`]: {
    textTransform: 'none',
    fontSize: theme.fontSize.middlesmall,
  },

  [`& .${classes.label}`]: {
    fontSize: theme.fontSize.middlesmall,
  },

}));

type ProjectsDatagridToolbarProps = {
  handleInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  setShowUserProjects: React.Dispatch<React.SetStateAction<boolean>>,
  showUserProjects: boolean,
};

function ProjectsDatagridToolbar (props: ProjectsDatagridToolbarProps) {
  const { handleInputChange, setShowUserProjects, showUserProjects } = props;

  const navigate = useNavigate();

  return (
    <StyledStack direction="row" spacing={2}>
      <NavLink
        to={`${PROJECTS}/${CREATE_PROJECTS}`}
        className={classes.label}
      >
        <Button
          variant="contained"
          className={classes.button}
          startIcon={<AddCircleOutlineIcon />}
          size="small"
        >
          Créer un projet
        </Button>
      </NavLink>
      <Button
        size="small"
        variant="contained"
        onClick={() => navigate(0)}
      >
        Actualiser
      </Button>
      <InputSearch
        handleInputChange={handleInputChange}
        label="Chercher un projet..."
      />
      <Box>
        <FormControlLabel
          control={(
            <Checkbox
              data-testid="checkbox-my-projects"
              onChange={(e) => setShowUserProjects(e.target.checked)}
              checked={showUserProjects}
              sx={{
                '&.Mui-checked': {
                  color: theme.palette.primary.light,
                },
                padding: '4px',
              }}
            />
            )}
          label="Afficher uniquement mes projets"
          slotProps={{
            typography: {
              className: classes.label,
            },
          }}
        />
      </Box>
    </StyledStack>
  );
}

export default ProjectsDatagridToolbar;
