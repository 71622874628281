import CategoryValue from './CategoryValue';
import {
  Category as CategoryType,
} from '../types';

export default class Category {
  private id: number;

  private name: string;

  private category_group_id: number;

  private insertion_date: string;

  private active: boolean;

  private category_values: CategoryValue[] = [];

  constructor(cat: CategoryType) {
    this.id = cat?.id;
    this.name = cat.name;
    this.category_group_id = cat.category_group_id;
    this.insertion_date = cat.insertion_date;
    this.active = cat.active;
    this.category_values = cat.category_values.map(
      (value) => new CategoryValue(value, cat?.name, cat?.category_group_id),
    );
  }

  get catName() {
    return this.name;
  }

  get catGroupId() {
    return this.category_group_id;
  }

  get catDate() {
    return this.insertion_date;
  }

  get catId() {
    return this.id;
  }

  get catActive() {
    return this.active;
  }

  get catValues() {
    return this.category_values;
  }
}
