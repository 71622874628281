import React from 'react';

import { MenuItem, Select } from '@mui/material';

import globalTheme from 'common/theme';

import getCategoryValueInputLabel from '../utils/includeLabel';

type EditCategoryIncludeFielddProps = {
  value: boolean,
  onUpdate: (value: boolean) => void,
}

function EditCategoryIncludeField(props: EditCategoryIncludeFielddProps) {
  const { value, onUpdate } = props;

  return (
    <Select
      value={value ? 1 : 0}
      onChange={(e) => onUpdate(Boolean(e.target.value))}
      size="small"
      sx={{
        height: 30,
      }}
      inputProps={{
        sx: {
          fontSize: globalTheme.fontSize.xsmall,
          font: undefined,
        },
      }}
      data-testid="edit-include-field-select"
    >
      <MenuItem
        value={1}
        sx={{
          fontSize: globalTheme.fontSize.xsmall,
        }}
      >
        {getCategoryValueInputLabel(true)}
      </MenuItem>
      <MenuItem
        value={0}
        sx={{
          fontSize: globalTheme.fontSize.xsmall,
        }}
      >
        {getCategoryValueInputLabel(false)}
      </MenuItem>
    </Select>
  );
}

export default EditCategoryIncludeField;
