const formatProjectNameForUrl = (projName: string): string => {
  const tempValue = projName
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/gi, '')
    .replace(/[^a-z0-9]/g, '-')
    .replace(/-{2,}/g, '-')
    .replace(/^[^a-z0-9]|[^a-z0-9]$/g, '');
  return tempValue;
};

export default formatProjectNameForUrl;
