import { axiosAuthInstance } from 'common/axiosAuthInstance';
import axiosBasicInstance from 'common/axiosBasicInstance';
import { UserType } from 'features/users/types';

import { CheckEmailResponse, LoginResponse } from '../types';

export default class ApiAuth {
  static loginPassword(username: string, password: string) {
    const formData = new FormData();

    formData.append('username', username);
    formData.append('password', password);

    return axiosBasicInstance.post<never, LoginResponse>(
      'login/',
      formData,
      {
        headers: {
          'content-Type': 'multipart/form-data',
        },
      },
    );
  }

  static login(authCode: string) {
    return axiosBasicInstance.get<never, LoginResponse>(`login/google?code=${authCode}`);
  }

  static refreshToken() {
    return axiosBasicInstance.get<never, LoginResponse>('login/refresh-token');
  }

  static logout() {
    return axiosBasicInstance.get('logout/');
  }

  static fetchCurrentUser() {
    return axiosAuthInstance.get<UserType>(
      'users/current',
    );
  }

  static checkEmail(email: string) {
    return axiosAuthInstance.get<CheckEmailResponse>(
      `login/check_email?email=${email}`,
    );
  }
}
