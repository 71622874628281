import globalTheme from 'common/theme';
import { ActorType } from 'features/actors/types';

export function getMissingActors(values: any[], actorsInScope: ActorType[]) {
  const missingActors: ActorType[] = [];
  actorsInScope.forEach((actor) => {
    const temp = values.filter(
      (value) => value.actor_name === actor.name,
    );
    if (temp.length === 0) missingActors.push(actor);
  });
  return missingActors;
}

export function setBarColor(actor: any, actorBasedType = false) {
  if (actor.actor_color) return actor.actor_color;
  if (actor.dataviz_color) return actor.dataviz_color;
  if (!actor.actor_name && !(actorBasedType && actor.name)) return globalTheme.palette.info.main;
  if (!actor.actor_is_client) return globalTheme.palette.primary.light;
  return globalTheme.palette.secondary.main;
}
