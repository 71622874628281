import { axiosAuthInstance } from 'common/axiosAuthInstance';

import {
  UserType, UserUpdate, UserUpdatePayload, UsersFetchPayload, UserDeletePayload,
  UserUpdateProjectsPayload,
  UserCreatePayload,
} from '../types';

export default class ApiUsers {
  static fetchUsers(payload: UsersFetchPayload) {
    return axiosAuthInstance.get<never, UserType[]>(
      `users/?skip=0&limit=100&with_inactive=${payload.with_inactive}`,
    );
  }

  static createUser(payload: UserCreatePayload) {
    return axiosAuthInstance.post<never, UserType>('users/', payload);
  }

  static updateUser(payload: UserUpdatePayload) {
    return axiosAuthInstance.put<never, UserUpdate>(`users/${payload.id}`, payload.user);
  }

  static updateUserprojects(payload: UserUpdateProjectsPayload) {
    return axiosAuthInstance.put<never, UserUpdate>(
      `users/${payload.id}/projects`,
      payload.userProjects,
    );
  }

  static deleteUser(payload: UserDeletePayload) {
    return axiosAuthInstance.delete<never, UserType>(`users/${payload.id}`);
  }
}
