import React, { useState } from 'react';

import { Box, Typography, MenuItem } from '@mui/material';
import { FormikProps, useFormik } from 'formik';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import { updateProjectManagerAndDircli, updateUsers } from 'features/projects/state/slice';
import { Project, UserProject } from 'features/projects/types';
import { UserType } from 'features/users/types';
import User from 'features/users/User';
import UsersUtils from 'features/users/UsersUtils';

import {
  ManageUsersForm,
  initValues,
  validationSchema,
} from './manageUsersConfig';
import ManageUsersModal from './ManageUsersModal';

type ManageUsersModalProps = {
  project: Project,
  currentUser: UserType | undefined,
  handleCloseMenu: Function,
}

function ManageUsers(props: ManageUsersModalProps) {
  const {
    project,
    currentUser,
    handleCloseMenu,
  } = props;

  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const user = currentUser && new User(currentUser);

  const handleSubmit = (values: ManageUsersForm) => {
    const newUsersWithoutManager = UsersUtils.removeManagerFromIdsList(
      values.users,
      project.project_manager?.id,
    );

    const payloadUpdateUsers = {
      projectId: project.id,
      payload: newUsersWithoutManager.map(
        (userId) => {
          const userProject: UserProject = {
            project_id: project.id,
            user_id: userId,
            is_consultant: false,
          };
          return userProject;
        },
      ),
    };

    dispatch(updateUsers(payloadUpdateUsers));
    dispatch(updateProjectManagerAndDircli({
      payload: { project_dircli_id: values.dircli, project_manager_id: values.manager },
      projectId: project.id,
    }));
    setIsOpen(false);
  };
  const formik: FormikProps<ManageUsersForm> = useFormik<ManageUsersForm>({
    initialValues: initValues(project.users, project.project_manager, project.project_dircli),
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Box>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => { if (!isOpen) setIsOpen(true); handleCloseMenu(); }
        }
      >
        Gérer les utilisateurs
      </MenuItem>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">
              Ajouter ou supprimer
            </Typography>
            {' '}
            un utilisateur à ce projet
          </Typography>
        )}
        actionConfirm={() => (
          (user?.isInProject(project) || user?.isDircli())
            ? formik.handleSubmit()
            : setIsOpen(false)
        )}
        actionCancel={() => setIsOpen(false)}
        displayCancel={user?.isInProject(project) || user?.isDircli()}
        isOpen={isOpen}
      >
        <ManageUsersModal
          formik={formik}
          isUserInProject={user?.isInProject(project) || user?.isDircli()}
          canUserEditProject={user?.canUpdateProject(project.id)}
        />
      </Modal>
    </Box>
  );
}

export default ManageUsers;
