import React from 'react';

import { Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';

import UrlsByResultType from '../type';

const createUrlsByActorColumns = (): GridColDef<UrlsByResultType>[] => [
  {
    field: 'url',
    renderHeader: () => (
      <StyledHeader label="URL" />
    ),
    renderCell: (params: GridRenderCellParams<UrlsByResultType>) => (
      <Typography variant="body2">{params.value}</Typography>
    ),
    flex: 1,
  },
];

export default createUrlsByActorColumns;
