/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ActorType,
  ActorsFetchPayload,
  ActorsUpdatePayload,
  ActorUpdatePayload,
  SiteUrlPattern,
  SiteUrlPatternDelete,
  SiteUrlPatternPayload,
} from '../types';

export interface State {
  isLoading: boolean;
  actors: ActorType[];
  error: string;
}

const initialState: State = {
  isLoading: false,
  actors: [],
  error: '',
};

export const actorsSlice = createSlice({
  name: 'actors',
  initialState,
  reducers: {
    fetchActors: (state: State, action: PayloadAction<ActorsFetchPayload>) => {
      state.isLoading = true;
    },
    fetchActorsSuccess: (state: State, action: PayloadAction<ActorType[]>) => {
      state.actors = action.payload;
      state.isLoading = false;
    },
    fetchActorsFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateActors: (state: State, action: PayloadAction<ActorsUpdatePayload>) => {
      state.isLoading = true;
    },
    updateActorsSuccess: (state: State, action: PayloadAction<ActorType[]>) => {
      state.actors = action.payload;
      state.isLoading = false;
    },
    updateActorsFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    addActor: (state: State, action: PayloadAction<ActorType>) => {
      state.isLoading = true;
    },
    addActorSuccess: (state: State, action: PayloadAction<ActorType>) => {
      state.actors.push(action.payload);
      state.isLoading = false;
    },
    addActorFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateActor: (state: State, action: PayloadAction<ActorUpdatePayload>) => {
      state.isLoading = true;
    },
    updateActorSuccess: (state: State, action: PayloadAction<ActorType>) => {
      const oldActorIndex = state.actors.findIndex(
        (actor) => actor.id && (actor.id === action.payload.id),
      );
      state.actors[oldActorIndex] = {
        ...state.actors[oldActorIndex],
        ...action.payload,
      };
      state.isLoading = false;
    },
    updateActorFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    addSiteUrlPattern: (state: State, action: PayloadAction<SiteUrlPatternPayload>) => {
      state.isLoading = true;
    },
    addSiteUrlPatternSuccess: (state: State, action: PayloadAction<SiteUrlPatternPayload>) => {
      const actorToModify = state.actors.find(
        (actor) => actor.id === action.payload.actorId,
      );

      if (!actorToModify) {
        state.isLoading = false;
        state.error = 'L\'acteur à modifier n\'existe pas';
        return;
      }

      const siteIndex = actorToModify.sites.findIndex((site) => site.id === action.payload.siteId);

      if (siteIndex === -1) {
        state.isLoading = false;
        state.error = 'Le site à modifier n\'existe pas';
        return;
      }

      state.actors = state.actors.filter(
        (actor) => actor.id !== action.payload.actorId,
      );

      const newSiteUrlPatterns = actorToModify.sites[siteIndex].site_url_patterns;
      newSiteUrlPatterns.push(action.payload.pattern);
      if (actorToModify) {
        actorToModify.sites[siteIndex].site_url_patterns = newSiteUrlPatterns;
        state.actors.push(actorToModify);
      }

      state.isLoading = false;
    },
    addSiteUrlPatternFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateSiteUrlPattern: (state: State, action: PayloadAction<SiteUrlPatternPayload>) => {
      state.isLoading = true;
    },
    updateSiteUrlPatternSuccess: (state: State, action: PayloadAction<SiteUrlPatternPayload>) => {
      const actorToModify = state.actors.find(
        (actor) => actor.id === action.payload.actorId,
      );

      if (!actorToModify) {
        state.isLoading = false;
        state.error = 'L\'acteur à modifier n\'existe pas';
        return;
      }

      const siteIndex = actorToModify.sites.findIndex((site) => site.id === action.payload.siteId);

      if (siteIndex === -1) {
        state.isLoading = false;
        state.error = 'Le site à mettre à jour n\'existe pas';
        return;
      }

      state.actors = state.actors.filter(
        (actor) => actor.id !== action.payload.actorId,
      );

      const newSiteUrlPatterns = actorToModify.sites[siteIndex].site_url_patterns.filter(
        (sup) => sup.id !== action.payload.pattern.id,
      );
      newSiteUrlPatterns.push(action.payload.pattern);
      if (actorToModify) {
        actorToModify.sites[siteIndex].site_url_patterns = newSiteUrlPatterns;
        state.actors.push(actorToModify);
      }
      state.isLoading = false;
    },
    updateSiteUrlPatternFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteSiteUrlPattern: (state: State, action: PayloadAction<SiteUrlPatternDelete>) => {
      state.isLoading = true;
    },
    deleteSiteUrlPatternSuccess: (state: State, action: PayloadAction<SiteUrlPatternPayload>) => {
      const actorToModify = state.actors.find(
        (actor) => actor.id === action.payload.actorId,
      );

      if (!actorToModify) {
        state.isLoading = false;
        state.error = 'L\'acteur à modifier n\'existe pas';
        return;
      }

      const siteIndex = actorToModify.sites.findIndex((site) => site.id === action.payload.siteId);

      if (siteIndex === -1) {
        state.isLoading = false;
        state.error = 'Le site à supprimer n\'existe pas';
        return;
      }

      state.actors = state.actors.filter(
        (actor) => actor.id !== action.payload.actorId,
      );

      const newSiteUrlPatterns = actorToModify.sites[siteIndex].site_url_patterns.filter(
        (sup) => sup.id !== action.payload.pattern.id,
      );

      if (actorToModify) {
        actorToModify.sites[siteIndex].site_url_patterns = newSiteUrlPatterns;
        state.actors.push(actorToModify);
      }
      state.isLoading = false;
    },
    deleteSiteUrlPatternFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteActor: (state: State, action: PayloadAction<{ actorId: number }>) => {
      state.isLoading = true;
    },
    deleteActorSuccess: (state: State, action: PayloadAction<ActorType>) => {
      state.actors = state.actors.filter((actor) => actor.id !== action.payload.id);
      state.isLoading = false;
    },
    deleteActorFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const selectActorsByScopeId = createSelector(
  (state: State) => state.actors,
  (_: any, scopeId: number | null) => scopeId,
  (actors, scopeId) => actors.filter((actor) => actor.scope_id === scopeId),
);

export const selectActorById = createSelector(
  (state: State) => state.actors,
  (_: any, id: number) => id,
  (actors, id) => actors.find((actor) => actor.id === id),
);

export const selectClients = createSelector(
  (state: State) => state.actors,
  (actors) => actors.filter((actor) => actor.is_client === true),
);

export const {
  fetchActors,
  fetchActorsSuccess,
  fetchActorsFailure,
  updateActors,
  updateActorsSuccess,
  updateActorsFailure,
  updateActor,
  updateActorSuccess,
  updateActorFailure,
  addActor,
  addActorSuccess,
  addActorFailure,
  addSiteUrlPattern,
  addSiteUrlPatternSuccess,
  addSiteUrlPatternFailure,
  updateSiteUrlPattern,
  updateSiteUrlPatternSuccess,
  updateSiteUrlPatternFailure,
  deleteSiteUrlPattern,
  deleteSiteUrlPatternSuccess,
  deleteSiteUrlPatternFailure,
  deleteActor,
  deleteActorSuccess,
  deleteActorFailure,
} = actorsSlice.actions;

export default actorsSlice.reducer;
