function hexStringToByteArray(hexString: string) {
  if (hexString.length % 2 !== 0) {
    throw new Error('Must have an even number');
  }

  const bytesCount = hexString.length / 2;
  const byteArray = new Uint8Array(bytesCount);

  for (let i = 0; i < bytesCount; i += 1) {
    byteArray[i] = parseInt(hexString.substring(i * 2, (i * 2 + 2)), 16);
  }

  return byteArray;
}

export default hexStringToByteArray;
