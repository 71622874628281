import React from 'react';
import { clarity } from 'react-microsoft-clarity';

import Hotjar from '@hotjar/browser';

import useFaviconChange from 'common/faviconChangeHook';

import RouterBase from './router/RouterBase';

const siteId = import.meta.env.VITE_SITE_ID;
const hotjarVersion = import.meta.env.VITE_HOTJAR_VERSION;
const clarityId = import.meta.env.VITE_CLARITY_ID;

function App(): JSX.Element {
  useFaviconChange();
  if (siteId && hotjarVersion) Hotjar.init(siteId, hotjarVersion);
  if (clarityId) clarity.init(clarityId);

  return (
    <RouterBase />
  );
}

export default App;
