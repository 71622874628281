import React, { useState } from 'react';

import { Box, Link, Typography } from '@mui/material';

import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';

function LoginAskPasswordModal() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box>
      <Typography
        component="a"
        href="#"
        onClick={() => setIsOpen(true)}
        sx={{
          fontSize: globalTheme.fontSize.xsmall,
          color: globalTheme.palette.primary.light,
        }}
        data-testid="login-ask-password-modal-button"
      >
        Mot de passe oublié ?
      </Typography>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">
              Contactez
            </Typography>
            {' '}
            votre directeur de clientèle pour demander vos identifiants de connexion ou
            {' '}
            <Link
              variant="bold"
              href="https://agence-wam.fr/contact/"
              target="_blank"
            >
              contactez nous
            </Link>
            {' '}
            pour toute autre question
          </Typography>
        )}
        actionConfirm={() => setIsOpen(false)}
        isOpen={isOpen}
        displayCancel={false}
        dataTestId="login-ask-password-modal"
      />
    </Box>
  );
}

export default LoginAskPasswordModal;
