import React from 'react';

import { Typography } from '@mui/material';

import Modal from 'components/modal/Modal';
import CATEGORY_TYPE_ID from 'features/categoryGroups/CONSTANTS';

type DeleteCategoryValueModalProps = {
  categoryValueIds: number[],
  isOpen: boolean,
  onConfirm: Function,
  handleClose: Function,
  categoryGroupType: number,
}

function DeleteCategoryValueModal(props: DeleteCategoryValueModalProps) {
  const {
    categoryValueIds,
    isOpen,
    onConfirm,
    handleClose,
    categoryGroupType,
  } = props;

  let catValLabel = '';

  if (categoryGroupType === CATEGORY_TYPE_ID.url) {
    catValLabel = categoryValueIds.length > 1 ? 'ces règles d\'URL' : 'cette règle d\'URL';
  } else if (categoryGroupType === CATEGORY_TYPE_ID.keywords) {
    catValLabel = categoryValueIds.length > 1 ? 'ces règles de mot-clé' : 'cette règle de mot-clé';
  } else if (categoryGroupType === CATEGORY_TYPE_ID.brand) {
    catValLabel = categoryValueIds.length > 1 ? 'ces requêtes marque' : 'cette requête marque';
  }

  return (
    <Modal
      title={(
        <Typography>
          <Typography component="span" variant="bold">
            {
              `Supprimer ${catValLabel} `
            }
          </Typography>
          et les données associées
        </Typography>
      )}
      actionConfirm={onConfirm}
      actionCancel={handleClose}
      isOpen={isOpen}
      dataTestId="delete-category-value-modal"
    />
  );
}

export default DeleteCategoryValueModal;
