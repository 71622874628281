import { KpiViewType } from '../types';

function getFiltersList(kpiView: KpiViewType) {
  const filterPart: string[] = [];

  const actorsInFilter = kpiView.actors.map((actor) => actor.name);
  if (actorsInFilter.length) filterPart.push(...actorsInFilter);

  const rankSliceInFilter = kpiView.sa_rank_slices.map((rankSlice) => rankSlice.label);
  if (rankSliceInFilter.length) filterPart.push(...rankSliceInFilter);

  const categoryGroupInFilter = kpiView.category_group?.name;
  if (categoryGroupInFilter) filterPart.push(categoryGroupInFilter);

  const categoriesInFilter = kpiView.categories.map((cat) => cat.name);
  if (categoriesInFilter.length) filterPart.push(...categoriesInFilter);

  const serpAnalysisInFilter = kpiView.serp_analyses.map((serp) => serp.label);
  if (serpAnalysisInFilter.length) filterPart.push(...serpAnalysisInFilter);

  const rankingTypeInFilter = kpiView.ranking_types.map((rankingType) => rankingType.name);
  if (rankingTypeInFilter.length) filterPart.push(...rankingTypeInFilter);

  if (kpiView.best_rank) filterPart.push('Meilleure position');

  return filterPart.join(', ');
}

export default getFiltersList;
