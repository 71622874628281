import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Stack } from '@mui/material';
import { useFormik } from 'formik';

import { Analysis } from 'features/serpAnalysis/types';
import serializeSearchParams from 'utils/querySerializer';

import initForm from './config';
import PhysionomyFilters from './PhysionomyFilters';
import PhysionomyInformations from './PhysionomyInformations';
import { PhysionomyFiltersForm } from '../types';

type PhysionomyFiltersContainerProps = {
  scopeId: number,
  isLoading: boolean,
  serpAnalysis?: Analysis,
  disabledInformations?: boolean,
  onSubmit: (filters: PhysionomyFiltersForm) => void,
  onFiltersChanged?: (filters: PhysionomyFiltersForm) => void,
}

function PhysionomyFiltersContainer(props: PhysionomyFiltersContainerProps) {
  const {
    scopeId,
    serpAnalysis,
    isLoading,
    disabledInformations,
    onFiltersChanged,
    onSubmit,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const actorsId = searchParams.getAll('actors')
    .filter((id) => !Number.isInteger(id))
    .map((id) => Number(id));
  const deviceId = Number(searchParams.get('deviceId')) || undefined;
  const rankLimit = Number(searchParams.get('rankLimit')) || undefined;
  const periodeOne = Number(searchParams.get('periodeOne')) || undefined;
  const periodeTwo = Number(searchParams.get('periodeTwo')) || undefined;
  const showValues = searchParams.get('showValues')
    ? searchParams.get('showValues') === 'true'
    : undefined;
  const showPercentage = searchParams.get('showPercentage')
    ? searchParams.get('showPercentage') === 'true'
    : undefined;

  const handleSubmit = (values: PhysionomyFiltersForm) => {
    onSubmit(values);
    setSearchParams(serializeSearchParams(values));
  };

  const formik = useFormik<PhysionomyFiltersForm>({
    initialValues: initForm(
      actorsId,
      deviceId ?? serpAnalysis?.device_type_id,
      rankLimit,
      periodeOne,
      periodeTwo,
      showValues,
      showPercentage,
    ),
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    onFiltersChanged?.(formik.values);
  }, [formik.values]);

  return (
    <Stack direction="column" spacing={2}>
      <PhysionomyFilters
        scopeId={scopeId}
        values={formik.values}
        onChange={formik.handleChange}
        isLoading={isLoading}
        serpAnalysisId={serpAnalysis?.id ?? 0}
        onSubmit={formik.handleSubmit}
      />
      <PhysionomyInformations
        values={formik.values}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={disabledInformations}
      />
    </Stack>
  );
}

export default PhysionomyFiltersContainer;
