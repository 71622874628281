import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  Box,
  Typography,
} from '@mui/material';

import CustomAlert from 'components/alert/CustomAlert';
import { Project } from 'features/projects/types';
import { generateProjectBaseUrl } from 'utils/urlManipulation';

import usePeriod from '../state/usePeriod';

type AlertNoPeriodsProps = {
  scopeId: number,
  dataSourceIds: number[],
  project?: Project,
}

function AlertNoPeriods(props: AlertNoPeriodsProps) {
  const {
    scopeId,
    dataSourceIds,
    project,
  } = props;
  const { hasPeriods, isLoading } = usePeriod(scopeId, dataSourceIds);

  const projectUrl = project ? generateProjectBaseUrl(project.id, project.name) : '';

  return (
    !hasPeriods && !isLoading && (
      <Box marginBottom={2}>
        <CustomAlert severity="error">
          <Typography variant="body2">
            Vous devez importer des fichiers Semrush
            pour visualiser les performances SEO -
            <NavLink to={`${projectUrl}setting/upload`}> Paramètres</NavLink>
          </Typography>
        </CustomAlert>
      </Box>
    )
  );
}

export default AlertNoPeriods;
