import { axiosAuthInstance } from 'common/axiosAuthInstance';

import {
  KpiViewType,
  KpiViewCreateUpdate,
  UpdatePayload,
  KpiViewDeletePayload,
} from '../types';

export default class ApiKpiView {
  static fetchKpiViews(payload: number) {
    return axiosAuthInstance.get<KpiViewType[]>(`/scopes/${payload}/kpi_views`);
  }

  static createKpiView(payload: KpiViewCreateUpdate) {
    return axiosAuthInstance.post<KpiViewType>('/kpi_views/', payload);
  }

  static updateKpiView(payload: UpdatePayload) {
    return axiosAuthInstance.put<KpiViewType>(`/kpi_views/${payload.viewId}`, payload.view);
  }

  static deleteKpiViews(payload: KpiViewDeletePayload) {
    if (payload.deleteFiles) {
      return axiosAuthInstance.delete<KpiViewType>(
        `/kpi_views/${payload.viewId}/with_files`,
      );
    }
    return axiosAuthInstance.delete<KpiViewType>(`/kpi_views/${payload.viewId}`);
  }

  static downloadKpiView(viewId: number) {
    return axiosAuthInstance.get<Blob>(
      `/kpi_views/${viewId}/csv`,
    );
  }
}
