import React from 'react';

import Grid from '@mui/material/Grid2';

import { Project } from 'features/projects/types';
import { UserType } from 'features/users/types';

import SettingBlock from './SettingBlock';
import { Setting } from './types';

type SettingsViewProps = {
  project: Project | undefined,
  settings: Setting[]
  currentUser?: UserType,
}

function SettingsView({ project, settings, currentUser }: SettingsViewProps) {
  return (
    <Grid container direction="row" spacing={2} data-testid="settings-container">
      {
        project && currentUser && settings
          .filter((setting) => !(setting.externalBlocked && currentUser.user_type.is_external))
          .map((setting) => (
            <Grid
              size={4}
              key={`setting-${setting.title}`}
              data-testid={`setting-${setting.title.replace(/ /g, '-')}`}
            >
              <SettingBlock
                title={setting.title}
                description={setting.description}
                buttonTitle={setting.buttonTitle}
                Icon={setting.Icon}
                redirectUrl={setting.redirectUrl(project)}
                required={setting.required}
                blockHeight={200}
              />
            </Grid>
          ))
      }
    </Grid>
  );
}

export default SettingsView;
