import { AppDispatch } from 'common/store';
import { MinimalProject, Project } from 'features/projects/types';
import { DATE_NUMBERS_HOURS, convertDate } from 'utils/formatDate';

import BasicUser from './BasicUser';
import USER_TYPES from './CONSTANTS';
import { updateUser } from './state/slice';
import { UserType, UserUpdate } from './types';

export default class User extends BasicUser {
  private insertion_date: string | undefined;

  private last_login: string | undefined;

  private active: boolean | undefined;

  private userTypeId: number | undefined;

  private userProjects: MinimalProject[] = [];

  constructor(user: UserType) {
    super({
      id: user.id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      avatar: user.avatar,
      user_type: user.user_type,
    });
    this.insertion_date = user.insertion_date;
    this.last_login = user.last_login;
    this.active = user.active;
    this.userTypeId = user.user_type_id;
    this.userProjects = user.projects;
  }

  get isActive() {
    return this.active;
  }

  get userStatus() {
    return this.active ? 'Actif' : 'Inactif';
  }

  get lastLoginInfos () {
    if (typeof this.last_login === 'string' && this.last_login.length !== 0) {
      return convertDate(new Date(this.last_login), DATE_NUMBERS_HOURS);
    }
    return false;
  }

  isAdmin() {
    return this.userTypeId === USER_TYPES.admin.id;
  }

  isDircli() {
    return this.userTypeId === USER_TYPES.dircli.id;
  }

  isInternal() {
    return this.userTypeId === USER_TYPES.internal.id;
  }

  isExternal() {
    return this.userTypeId === USER_TYPES.external.id;
  }

  isConsultant(projectId: number) {
    return !!this.userProjects
      .find((project) => project.id === projectId)
      ?.is_consultant;
  }

  isExternalConsultant(projectId: number) {
    return this.isExternal() && this.isConsultant(projectId);
  }

  isInProject(project: Project) {
    return this.isAdmin() || project.users.filter(
      (user) => user.id === this.userId,
    ).length > 0
      || project?.project_manager?.id === this.userId;
  }

  isProjectManager(projectId: number) {
    return this.userProjects
      .find((project) => project.id === projectId)
      ?.project_manager_id === this.userId;
  }

  isProjectDircli(projectId: number) {
    return this.userProjects
      .find((project) => project.id === projectId)
      ?.project_dircli_id === this.userId;
  }

  canUpdateProject(projectId: number) {
    return this.isProjectManager(projectId) || this.isDircli() || this.isAdmin();
  }

  updateStatus(dispatch: AppDispatch) {
    const userSendingFormat: UserUpdate = {
      active: !this.active,
    };
    const payload = {
      user: userSendingFormat,
      id: this.userId,
    };
    dispatch(updateUser(payload));
  }

  updateRole(dispatch: AppDispatch, newRoleId: number) {
    const payload = {
      user: {
        user_type_id: newRoleId,
      },
      id: this.userId,
    };
    dispatch(updateUser(payload));
  }
}
