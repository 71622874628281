import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import {
  fetchRefreshToken,
  fetchLogout,
  fetchCurrentUser,
  selectLoggedInformation,
} from 'features/authentication/state/slice';

import { HOME } from './CONSTANTS';
import router from './RouterConfig';

function RouterBase(): JSX.Element {
  const dispatch = useAppDispatch();

  const syncLogout = (e: StorageEvent) => {
    const currentPage: string = window.location.pathname;
    if (currentPage !== HOME) {
      if (e.key === 'logout') {
        localStorage.removeItem('logout');
        dispatch(fetchLogout({
          callbackSuccessUrl: HOME,
          callbackFailureUrl: HOME,
        }));
      }
    }
  };

  const { isLoggedIn, refreshTokenLoading, isLoggedOut } = useAppSelector(
    (state) => selectLoggedInformation(state.authentication),
  );

  /*
  * When the user logs out in another tab,
  * this event listener will detect it and logout this tab too
  */
  window.addEventListener('storage', syncLogout);

  useEffect(() => {
    if (!isLoggedOut) dispatch(fetchRefreshToken());
  }, [dispatch, fetchRefreshToken, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && !refreshTokenLoading) {
      dispatch(fetchCurrentUser());
    }
  }, [dispatch, fetchCurrentUser, isLoggedIn, refreshTokenLoading]);

  return (
    <RouterProvider router={router} />
  );
}

export default RouterBase;
