import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';

import downloadFile from 'utils/downloadFile';

import ApiDataFiles from './api';
import {
  downloadDataFile,
  downloadDataFileFailure,
  updateDataFiles,
  updateDataFilesFailure,
  updateDataFilesSuccess,
  uploadDataFiles,
  uploadDataFilesFailure,
  uploadDataFilesSuccess,
  deleteDataFiles,
  deleteDataFilesFailure,
  deleteDataFilesSuccess,
  fetchDataFiles,
  fetchDataFilesFailure,
  fetchDataFilesSuccess,
  downloadDataFileSuccess,
} from './slice';
import {
  DataFileDownloadPayload,
  DataFileUpdatePayload,
  DataFileUploadPayload,
  DataFilesDeletePayload,
  DataFilesFetchPayload,
} from '../types';

function* workFetchDataFiles(action: PayloadAction<DataFilesFetchPayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiDataFiles.fetchDataFiles,
      action.payload.scopeId,
    );

    yield put(fetchDataFilesSuccess(response.data));
  } catch (e: any) {
    yield put(fetchDataFilesFailure(e.message));
  }
}

function* workUploadDataFile(action: PayloadAction<DataFileUploadPayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiDataFiles.uploadDataFile,
      action.payload,
    );

    yield put(uploadDataFilesSuccess(response.data));
  } catch (e: any) {
    yield put(uploadDataFilesFailure(e.message));
  }
}

function* workUpdateDataFile(action: PayloadAction<DataFileUpdatePayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiDataFiles.updateDataFiles,
      action.payload.dataFiles,
    );

    yield put(updateDataFilesSuccess(response.data));
  } catch (e: any) {
    yield put(updateDataFilesFailure(e.message));
  }
}

function* workDeleteDataFiles(action: PayloadAction<DataFilesDeletePayload>) {
  const { dataFileIds } = action.payload;
  try {
    const doCall = () => Promise.all(dataFileIds.map(
      (dataFileId) => ApiDataFiles.deleteDataFile(dataFileId),
    ));
    const responses: AxiosResponse[] = yield call(doCall);

    const deletedDataFiles = responses.map((response) => response.data);

    yield put(deleteDataFilesSuccess(deletedDataFiles));
  } catch (e: any) {
    yield put(deleteDataFilesFailure(e.message));
  }
}

function* workDownloadDataFile(action: PayloadAction<DataFileDownloadPayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiDataFiles.downloadDataFile,
      action.payload.dataFileId,
    );

    const file = new Blob([response.data], {
      type: 'text/csv;charset=utf-8;',
    });

    downloadFile(file, action.payload.fileName);
    yield put(downloadDataFileSuccess());
  } catch (e: any) {
    yield put(downloadDataFileFailure(e.message));
  }
}

function* sagaFetchDataFiles() {
  yield takeEvery(
    fetchDataFiles,
    workFetchDataFiles,
  );
}

function* sagaUploadDataFiles() {
  yield takeEvery(
    uploadDataFiles,
    workUploadDataFile,
  );
}

function* sagaUpdateDataFiles() {
  yield takeEvery(
    updateDataFiles,
    workUpdateDataFile,
  );
}

function* sagaDeleteDataFiles() {
  yield takeEvery(
    deleteDataFiles,
    workDeleteDataFiles,
  );
}

function* sagaDownloadDataFile() {
  yield takeEvery(
    downloadDataFile,
    workDownloadDataFile,
  );
}

export {
  sagaFetchDataFiles,
  sagaUploadDataFiles,
  sagaUpdateDataFiles,
  sagaDeleteDataFiles,
  sagaDownloadDataFile,
};
