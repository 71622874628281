import * as React from 'react';

import {
  Box, Drawer, List, Stack, StackProps, styled,
} from '@mui/material';

import BackToTopButton from 'components/buttons/BackToTopButton';
import USER_TYPES from 'features/users/CONSTANTS';
import { UserType } from 'features/users/types';

import LinkMenu from '../components/LinkMenu';
import { leftDrawerWidth } from '../config';
import { MenuList } from '../types';

const PREFIX = 'LeftDrawerView';

const classes = {
  core: `${PREFIX}-core`,
  container: `${PREFIX}-container`,
  drawer: `${PREFIX}-drawer`,
  flexContainer: `${PREFIX}-flexContainer`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    margin: theme.spacing(4),
  },

  [`& .${classes.container}`]: {
    width: leftDrawerWidth,
  },

  [`& .${classes.drawer}`]: {
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: leftDrawerWidth,
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '0px 15px 15px 0px',
      overflow: 'visible',
      paddingBottom: theme.spacing(5),
    },
  },

  [`& .${classes.flexContainer}`]: {
    height: '100vh ',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
}));

type LeftDrawerProps = {
  currentUser: UserType | undefined,
  handleLogout: React.MouseEventHandler<HTMLButtonElement>,
  menuList: MenuList[],
};

export default function LeftDrawerView(props: LeftDrawerProps): JSX.Element {
  const {
    currentUser,
    handleLogout,
    menuList,
  } = props;

  let userIsAdmin: boolean = false;
  let userIsDircli: boolean = false;

  if (currentUser) {
    userIsAdmin = currentUser.user_type_id === USER_TYPES.admin.id;
    userIsDircli = currentUser.user_type_id === USER_TYPES.dircli.id;
  }

  return (
    <StyledStack direction="row">
      <Box
        component="nav"
        className={classes.container}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          className={classes.drawer}
          open
        >
          <Stack
            alignItems="center"
            justifyContent="space-between"
            className={classes.flexContainer}
          >
            {menuList.map((block) => (
              <List key={block.position}>
                {block.menu.map((item) => {
                  if (
                    (!item.isAdminRequired || userIsAdmin)
                    || (!item.isDircliRequired || userIsDircli)
                  ) {
                    return (
                      <Box
                        key={item.name}
                        onClick={() => {
                          if (item.logout) {
                            item.logout(handleLogout);
                          }
                        }}
                      >
                        <LinkMenu
                          menuItem={item}
                        />
                      </Box>
                    );
                  }
                  return undefined;
                })}
              </List>
            ))}
          </Stack>
        </Drawer>
      </Box>
      <BackToTopButton />
    </StyledStack>
  );
}
