import React from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import LinearLoadingOverlay from 'components/loading/LinearLoadingOverlay';

import { KpiViewType } from '../types';

type ViewDataGridProps = {
  kpiViews: KpiViewType[],
  isLoading: boolean,
  noRowsLabel?: string,
  columns: GridColDef<KpiViewType>[],
}

function ViewDataGrid(props: ViewDataGridProps) {
  const {
    kpiViews,
    isLoading,
    noRowsLabel = 'Aucun enregistrement trouvé.',
    columns,
  } = props;

  return (
    <DataGrid
      getRowId={(kpiView) => kpiView.id}
      disableRowSelectionOnClick
      disableColumnMenu
      autoHeight
      disableVirtualization
      getRowHeight={() => 'auto'}
      rows={kpiViews}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [{ field: 'insertion_date', sort: 'desc' }],
        },
      }}
      slots={{
        loadingOverlay: LinearLoadingOverlay,
      }}
      localeText={{
        noRowsLabel,
        MuiTablePagination: {
          labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} sur ${count}`,
        },
      }}
      slotProps={{
        pagination: {
          labelRowsPerPage: ('Nombre par page :'),
        },
        toolbar: {
          sx: {
            display: 'flex',
            justifyContent: 'flex-end',
          },
        },
        panel: {
          placement: 'bottom-end',
        },
      }}
      loading={isLoading}
      sx={{
        marginTop: 0,
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '5px' },
      }}
    />
  );
}

export default ViewDataGrid;
