import { axiosAuthInstance } from 'common/axiosAuthInstance';
import { formatObjectToUri } from 'utils/formatUri';

import {
  DataFileType,
  DataFileUpdate,
  DataFileUploadPayload,
} from '../types';

export default class ApiDataFiles {
  static fetchDataFiles(scopeId: number) {
    return axiosAuthInstance.get<never, DataFileType[]>(`scopes/${scopeId}/data_files`);
  }

  static uploadDataFile({ query, payload }: DataFileUploadPayload) {
    const searchParams = new URLSearchParams(formatObjectToUri(query));

    return axiosAuthInstance.post<never, DataFileType[]>(
      `data_files/upload_files?${searchParams}`,
      payload,
      {
        headers: {
          'content-Type': 'multipart/form-data',
        },
      },
    );
  }

  static updateDataFiles(payload: DataFileUpdate[]) {
    return axiosAuthInstance.put<never, DataFileType[]>('data_files/multiple', payload);
  }

  static deleteDataFile(dataFileId: number) {
    return axiosAuthInstance.delete<never, DataFileType>(`data_files/${dataFileId}`);
  }

  static downloadDataFile(dataFileId: number) {
    return axiosAuthInstance.get<never, any>(`data_files/${dataFileId}/download`);
  }
}
