import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import { KPI_VIEW_EXPORT_STATUS } from 'features/kpiView/CONSTANTS';
import KpiView from 'features/kpiView/KpiView';
import { KpiViewInlineEditType, KpiViewType } from 'features/kpiView/types';

type SaveKpiViewEditProps = {
  kpiView: KpiViewType,
  editedRowValue: KpiViewInlineEditType,
  onKpiViewSave: Function,
}

function SaveKpiViewEdit(props: SaveKpiViewEditProps) {
  const {
    kpiView,
    editedRowValue,
    onKpiViewSave,
  } = props;

  const [internalLoading, setInternalLoading] = useState(false);

  const { isLoading } = useAppSelector((state) => (state.categoryGroups));

  const kpiViewClass = new KpiView(kpiView);

  const dispatch = useAppDispatch();

  const handleSaveNewRowValue = () => {
    const payload = {
      label: kpiView.label,
      time_serie: kpiView.time_serie,
      start_date: editedRowValue.start_date || kpiView.start_date,
      last_export_date: kpiView.last_export_date,
      scope_id: kpiView.scope_id,
      device_type_id: kpiView.device_type_id,
      best_rank: kpiView.best_rank,
      kpi_view_export_id: kpiView.kpi_view_export_id,
      kpi_view_type_id: kpiView.kpi_view_type_id,
      kpi_view_export_status_id: KPI_VIEW_EXPORT_STATUS.toExport.id,
      category_group_id: kpiView.category_group_id,
      include_uncategorized: kpiView.categories.find((cat) => cat.id === 0) === undefined,
      kpi_view_actors: kpiView.actors.map((actor) => ({ actor_id: actor.id || 0 })),
      kpi_view_categories: kpiView.categories.map((cat) => ({ category_id: cat.id })),
      kpi_view_keywords: kpiView.keywords.map((keyword) => ({ keyword_id: keyword.id })),
      kpi_view_sa_rank_slices: kpiView.sa_rank_slices.map(
        (slice) => ({ sa_rank_slice_id: slice.id }),
      ),
      kpi_view_sa_keyword_intentions: kpiView.sa_keyword_intentions
        .map((intention) => ({ sa_keyword_intention_id: intention.id })),
      kpi_view_serp_analyses: kpiView.serp_analyses.map(
        (analysis) => ({ serp_analysis_id: analysis.id }),
      ),
      kpi_view_ranking_types: kpiView.ranking_types.map(
        (ranking_type) => ({ ranking_type_id: ranking_type.id }),
      ),
    };

    kpiViewClass.update(
      dispatch,
      payload,
    );

    setInternalLoading(true);
  };

  useEffect(() => {
    if (!isLoading && internalLoading) {
      setInternalLoading(false);
      onKpiViewSave(0);
    }
  }, [isLoading, internalLoading]);

  return (
    <Button
      variant="contained"
      onClick={handleSaveNewRowValue}
      size="small"
      data-testid="edit-kpi-view-save-button"
      disabled={isLoading}
    >
      Enregistrer
    </Button>
  );
}

export default SaveKpiViewEdit;
