/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataFileType } from 'features/dataFiles/types';
import USER_TYPES from 'features/users/CONSTANTS';
import { UserType } from 'features/users/types';

import {
  Project,
  ProjectCreatePayload,
  ProjectCreditPayload,
  ProjectCreditResultPayload,
  ProjectUpdateManagerAndDircliPayload,
  ProjectUpdatePayload,
  ProjectUserUpdate,
  ProjectUserUpdatePayload,
  UserProject,
} from '../types';

export interface State {
  isLoading: boolean;
  projects: Array<Project>;
  idProjectsDeleting: Array<number>;
  error: string;
}

const initialState: State = {
  isLoading: false,
  projects: [],
  idProjectsDeleting: [],
  error: '',
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    fetchProjects: (state: State) => {
      state.isLoading = true;
    },
    fetchProjectsSuccess: (state: State, action: PayloadAction<Project[]>) => {
      state.projects = action.payload;
      state.isLoading = false;
    },
    fetchProjectsFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createProject: (state: State, action: PayloadAction<ProjectCreatePayload>) => {
      state.isLoading = true;
    },
    createProjectSuccess: (state: State, action: PayloadAction<Project>) => {
      state.projects.push(action.payload);
      state.isLoading = false;
    },
    createProjectFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateProject: (state: State, action: PayloadAction<ProjectUpdatePayload>) => {
      state.isLoading = true;
    },
    updateProjectSuccess: (state: State, action: PayloadAction<Project>) => {
      state.projects = state.projects.filter(
        (p) => action.payload.id !== p.id,
      );
      state.projects.push(action.payload);
      state.isLoading = false;
    },
    updateProjectFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateProjectManagerAndDircli: (
      state: State,
      action: PayloadAction<ProjectUpdateManagerAndDircliPayload>,
    ) => {
      state.isLoading = true;
    },
    updateProjectManagerAndDircliSuccess: (state: State, action: PayloadAction<Project>) => {
      const updatedProject = state.projects.find((project) => project.id === action.payload.id);
      if (updatedProject) {
        updatedProject.project_manager = action.payload.project_manager;
        updatedProject.project_dircli = action.payload.project_dircli;
      }
      state.isLoading = false;
    },
    updateProjectManagerAndDircliFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteProject: (state: State, action: PayloadAction<number>) => {
      state.isLoading = true;
      state.idProjectsDeleting.push(action.payload);
    },
    deleteProjectSuccess: (state: State, action: PayloadAction<number>) => {
      state.projects = state.projects.filter(
        (an) => action.payload !== an.id,
      );
      state.idProjectsDeleting = state.idProjectsDeleting.filter(
        (id) => action.payload !== id,
      );
      state.isLoading = false;
    },
    deleteProjectFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    addDataFileToProject: (state: State, action: PayloadAction<DataFileType>) => {
      const projectToUpdate = state.projects.filter(
        (project) => project.scopes[0].id === action.payload.scope_id,
      )[0];
      state.projects = state.projects.filter(
        (project) => project.id !== projectToUpdate.id,
      );
      projectToUpdate.scopes[0].data_files.push(action.payload);
      state.projects.push(projectToUpdate);
    },
    updateUsers: (state: State, action: PayloadAction<ProjectUserUpdatePayload>) => {
      state.isLoading = true;
    },
    updateUsersSuccess: (state: State, action: PayloadAction<ProjectUserUpdate>) => {
      const projectToUpdate = state.projects.find(
        (p) => action.payload.id === p.id,
      );
      if (projectToUpdate) {
        projectToUpdate.users = action.payload.users;
      }
      state.isLoading = false;
    },
    updateUsersFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    addUser: (state: State, action: PayloadAction<UserProject>) => {
      state.isLoading = true;
    },
    addUserSuccess: (state: State, action: PayloadAction<ProjectUserUpdate>) => {
      let projectToUpdate = state.projects.filter(
        (p) => action.payload.id === p.id,
      )[0];
      state.projects = state.projects.filter(
        (p) => action.payload.id !== p.id,
      );
      projectToUpdate = {
        ...projectToUpdate,
        users: [...projectToUpdate.users, action.payload.users[0]],
      };
      state.projects.push(projectToUpdate);
      state.isLoading = false;
    },
    addUserFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    removeUser: (state: State, action: PayloadAction<UserProject>) => {
      state.isLoading = true;
    },
    removeUserSuccess: (state: State, action: PayloadAction<ProjectUserUpdatePayload>) => {
      let projectToUpdate = state.projects.filter(
        (p) => action.payload.projectId === p.id,
      )[0];
      state.projects = state.projects.filter(
        (p) => action.payload.projectId !== p.id,
      );

      projectToUpdate = {
        ...projectToUpdate,
        users: projectToUpdate.users.filter(
          (user) => user.id !== action.payload.payload[0].user_id,
        ),
      };

      state.projects.push(projectToUpdate);
      state.isLoading = false;
    },
    removeUserFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    creditProject: (state: State, action: PayloadAction<ProjectCreditPayload>) => {
      state.isLoading = true;
    },
    creditProjectSuccess: (state: State, action: PayloadAction<ProjectCreditResultPayload>) => {
      state.projects = state.projects.map((project) => (
        project.id === action.payload.id
          ? {
            ...project,
            project_budget: action.payload.payload.new_budget,
            keyword_price: action.payload.payload.new_keyword_price,
          }
          : project
      ));
      state.isLoading = false;
    },
    creditProjectFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const selectProjectById = createSelector(
  (state: State) => state.projects,
  (_: any, projectId: string | number | undefined) => projectId,
  (projects, projectId) => {
    if (projectId === undefined) return undefined;
    let id = projectId;
    if (typeof projectId === 'string') {
      id = parseInt(projectId, 10);
    }
    const project = projects.find((proj) => proj.id === id);
    return project;
  },
);

export const selectProjectStateById = createSelector(
  (state: State) => state,
  (_: any, projectId: string | number | undefined) => projectId,
  (state, projectId) => {
    if (projectId === undefined) {
      return { project: undefined, isLoading: state.isLoading, error: state.error };
    }
    let id = projectId;
    if (typeof projectId === 'string') {
      id = parseInt(projectId, 10);
    }
    const project = state.projects.find((proj) => proj.id === id);
    return { project, isLoading: state.isLoading, error: state.error };
  },
);

export const selectProjectByUser = createSelector(
  (state: State) => state,
  (_: any, currentUser: UserType | undefined) => currentUser,
  (state, currentUser) => {
    if (currentUser === undefined) {
      return [];
    }

    if (currentUser.user_type_id === USER_TYPES.admin.id) {
      return state.projects;
    }

    const projects = state.projects.filter(
      (proj) => (
        proj.project_manager?.id === currentUser.id
        || proj.users.find((user) => user.id === currentUser.id)
      ),
    );
    return projects;
  },
);

export const {
  fetchProjects,
  fetchProjectsSuccess,
  fetchProjectsFailure,
  createProject,
  createProjectSuccess,
  createProjectFailure,
  updateProject,
  updateProjectSuccess,
  updateProjectFailure,
  updateProjectManagerAndDircli,
  updateProjectManagerAndDircliSuccess,
  updateProjectManagerAndDircliFailure,
  deleteProject,
  deleteProjectSuccess,
  deleteProjectFailure,
  addDataFileToProject,
  updateUsers,
  updateUsersSuccess,
  updateUsersFailure,
  addUser,
  addUserSuccess,
  addUserFailure,
  removeUser,
  removeUserSuccess,
  removeUserFailure,
  creditProject,
  creditProjectSuccess,
  creditProjectFailure,
} = projectsSlice.actions;

export default projectsSlice.reducer;
