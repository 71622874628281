import React, { useEffect, useState } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import Modal from 'components/modal/Modal';
import ValidateActionMessage from 'components/modal/ValidateActionMessage';

import { DATA_FILE_STATUS } from '../CONSTANT';
import { updateDataFiles } from '../state/slice';

type GlobalDeleteDataFileProps = {
  dataFileIds: number[],
}

export default function GlobalDeleteDataFile(props: GlobalDeleteDataFileProps): JSX.Element {
  const {
    dataFileIds,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(
    (state) => state.dataFiles,
  );
  useEffect(() => {
    if (showLoading && !isLoading) {
      setShowLoading(false);
      setIsConfirmationOpen(true);
    }
  }, [isLoading]);

  const handleConfirmationClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    dispatch(
      updateDataFiles(
        {
          dataFiles: dataFileIds.map(
            (id) => ({
              id,
              data_file_status_id: DATA_FILE_STATUS.to_delete,
            }),
          ),
        },
      ),
    );
    setShowLoading(true);
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="error"
        onClick={
          () => { setIsOpen(true); setIsConfirmationOpen(false); }
        }
        size="small"
        startIcon={<DeleteForeverIcon />}
        data-testid="global-delete-df-button"
      >
        Supprimer
      </Button>
      {
        isConfirmationOpen
          ? (
            <Modal
              title={<ValidateActionMessage />}
              displayCancel={false}
              actionConfirm={handleConfirmationClose}
              isOpen={isOpen}
              dataTestId="global-delete-data-file-delete-confirm-modal"
            />
          )
          : (
            <Modal
              title={(
                <Typography>
                  <Typography component="span" variant="bold">
                    Supprimer les fichiers
                  </Typography>
                  {' '}
                  et les données associées ?
                </Typography>
              )}
              actionConfirm={handleConfirm}
              actionCancel={() => {
                setIsOpen(false);
              }}
              isOpen={isOpen}
              dataTestId="global-delete-data-file-delete-modal"
            />
          )
      }
    </Box>
  );
}
