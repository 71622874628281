import React, { MouseEventHandler } from 'react';

import {
  Stack,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';

import ExportPerformanceButton from './ExportPerformanceButton';

interface PerformanceChartOptionsProps {
  values: {
    labels: boolean,
    percents: boolean,
  },
  handleChange: any,
  handleExport: MouseEventHandler<HTMLElement> | undefined,
  isExportLoading: boolean | undefined,
}

function PerformanceChartOptions(props: PerformanceChartOptionsProps) {
  const {
    values,
    handleChange,
    handleExport,
    isExportLoading,
  } = props;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      data-testid="performance-chart-options"
    >
      <FormControlLabel
        control={(
          <Checkbox
            size="small"
            checked={values.labels}
            data-testid="chart-options-checkbox-labels"
          />
        )}
        name="labels"
        label={(
          <Typography variant="body3">
            Valeurs
          </Typography>
        )}
        onChange={handleChange}
      />
      <FormControlLabel
        control={(
          <Checkbox
            size="small"
            checked={values.percents}
            data-testid="chart-options-checkbox-percents"
          />
        )}
        name="percents"
        label={(
          <Typography variant="body3">
            Pourcentage
          </Typography>
        )}
        onChange={handleChange}
      />
      <ExportPerformanceButton
        handleExport={handleExport}
        isExportLoading={isExportLoading}
      />
    </Stack>
  );
}

export default PerformanceChartOptions;
