import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PeopleIcon from '@mui/icons-material/People';
import {
  Box,
  Stack,
  styled,
  Button,
  Grid2 as Grid,
  Grid2Props as GridProps,
} from '@mui/material';

import MainBox from 'components/layout/MainBox';
import BarChartComponent from 'features/dataviz/components/generic/charts/BarChart';
import RadioFilterComponent from 'features/dataviz/components/generic/charts/common/RadioFilter';
import SimpleTooltipComponent
  from 'features/dataviz/components/generic/charts/common/SimpleTooltip';
import Legend from 'features/dataviz/components/generic/charts/legend/Legend';
import {
  DEFAULT_SA_ID,
  DEFAULT_SITE_COUNT,
  SI_ALL,
  SI_NC,
} from 'features/serpAnalysis/components/serpDetails/parametersBlock/CONSTANT';
import { PROJECTS } from 'router/CONSTANTS';
import formatProjectNameForUrl from 'utils/formatProjectNameForUrl';

import { setBarColor, getMissingActors } from './GroupByDomainFormat';
import { ChartComponentProps } from '../../types';

const PREFIX = 'GroupByDomain';

const classes = {
  container: `${PREFIX}-container`,
  buttonCtn: `${PREFIX}-buttonCtn`,
  button: `${PREFIX}-button`,
};

const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({
  [`& .${classes.container}`]: {
    height: '350px',
  },
  [`& .${classes.buttonCtn}`]: {
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  [`& .${classes.button}`]: {
    marginLeft: theme.spacing(1),
  },
}));

function GroupByDomain(props: ChartComponentProps): JSX.Element {
  const {
    datas,
    title,
    link,
    isPageLoading,
    isLoading,
    size,
    actors,
    analysis,
    project,
    isChartWithoutDatas,
    datavizFilters,
    containerAspect,
    isAnimationActive,
  } = props;

  const dataKeys = [
    {
      dataKey: 'value',
      color: '',
    },
  ];

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const dvMode = searchParams.get('dvMode') ?? '';
  const searchIntentId = searchParams.get('searchIntentId') ?? `${DEFAULT_SA_ID}`;

  const saKwIntentions = analysis ? analysis.sa_keyword_intentions : [];

  const siAllList = (saKwIntentions && saKwIntentions.length > 0)
    ? [
      SI_ALL,
      ...saKwIntentions,
      SI_NC,
    ]
    : [SI_ALL];

  const searchIntent = siAllList.find((kw_intent) => (
    kw_intent.id === parseInt(searchIntentId, 10)
  ));
  let filtersLegend = {
    rankMin: '0',
    rankMax: '0',
    volMin: '0',
    volMax: analysis?.max_volume.toString(),
    searchIntent: searchIntent?.label,
    bestRankMode: false,
  };
  if (datavizFilters && datavizFilters.type === 'datavizFilters') {
    filtersLegend = {
      rankMin: datavizFilters.rankMin,
      rankMax: datavizFilters.rankMax,
      volMin: datavizFilters.volMin,
      volMax: (
        datavizFilters.volMax !== '-1'
          ? datavizFilters.volMax
          : `${analysis?.max_volume}`
      ),
      searchIntent: searchIntent?.label,
      bestRankMode: datavizFilters.bestRankMode,
    };
  }

  return (
    <StyledGrid container spacing={2}>
      <Grid size={8} className={classes.container}>
        <MainBox>
          <Box className={classes.container}>
            <BarChartComponent
              isAnimationActive={isAnimationActive}
              containerAspect={containerAspect}
              datas={
                datas.data.values?.slice(
                  0,
                  (
                    (datavizFilters?.type === 'datavizFilters' && datavizFilters?.sitesCount)
                      ? parseInt(datavizFilters?.sitesCount, 10)
                      : DEFAULT_SITE_COUNT
                  ),
                )
                || []
              }
              title={title}
              link={link}
              dataKeys={dataKeys}
              topLabelName={dataKeys[0].dataKey}
              setBarColor={setBarColor}
              isLoading={isLoading}
              isPageLoading={isPageLoading}
              tooltipContent={<SimpleTooltipComponent />}
              size={size}
              withTooltip
              hideXAxisLabel
              dataObject={datas}
              isChartWithoutDatas={isChartWithoutDatas || (() => false)}
              serpAnalysisStatus={analysis?.serp_analysis_status_id}
              radioFilter={(
                <RadioFilterComponent
                  onChange={
                    (value: string) => {
                      searchParams.set('dvMode', value);
                      setSearchParams(searchParams);
                    }
                  }
                  name="dvMode"
                  value={dvMode}
                  defaultValue="rank"
                  elements={[
                    {
                      value: 'rank',
                      label: 'Nombre de positions',
                    },
                    {
                      value: 'volume',
                      label: 'Demandes mensuelles',
                    },
                  ]}
                  filtersLegend={filtersLegend}
                />
              )}
            />
          </Box>
        </MainBox>
      </Grid>
      <Grid size={4}>
        <MainBox>
          <Box className={classes.container}>
            <Legend
              datas={datas}
              title="PAYSAGE CONCURRENTIEL"
              isPageLoading={isPageLoading}
              isLoading={isLoading}
              missingActors={getMissingActors(datas.data.values || [], actors || [])}
              setBarColor={setBarColor}
              serpAnalysisStatus={analysis?.serp_analysis_status_id}
              isChartWithoutDatas={isChartWithoutDatas || (() => false)}
              label={dvMode && dvMode === 'volume' ? 'demande' : 'position'}
              labelPlural={dvMode && dvMode === 'volume' ? 'demandes' : 'positions'}
            />
            <Stack
              direction="row"
              justifyContent="space-evenly"
              className={classes.buttonCtn}
            >
              <Button
                variant="contained"
                size="small"
                startIcon={<PeopleIcon />}
                className={classes.button}
                onClick={
                  () => navigate(
                    `${
                      PROJECTS
                    }/${
                      project?.id
                    }-${
                      formatProjectNameForUrl(project?.name || '')
                    }/setting/competitor`,
                  )
                }
              >
                Acteurs
              </Button>
            </Stack>
          </Box>
        </MainBox>
      </Grid>
    </StyledGrid>
  );
}

export default GroupByDomain;
