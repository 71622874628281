import React, { useCallback, useMemo } from 'react';

import {
  Box,
  BoxProps,
  styled,
  Stack,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import InputSearch from 'components/input/inputSearch';
import LinearLoadingOverlay from 'components/loading/LinearLoadingOverlay';
import useActors from 'features/actors/state/useActors';
import useCurrentUser from 'features/authentication/state/useCurrentUser';
import useInputSearch from 'utils/useInputSearch';

import createActorColumns from './columns';
import CreateActor from '../actions/CreateActor';
import RenameActor from '../actions/RenameActor';
import Actor from '../Actor';
import { ActorPatternsDataGrid } from '../types';

const PREFIX = 'ActorsDataGrid';

const classes = {
  labels: `${PREFIX}-labels`,
  text: `${PREFIX}-text`,
  icon: `${PREFIX}-icon`,
  exclude: `${PREFIX}-exclude`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.labels}`]: {
    color: theme.palette.info.main,
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.text}`]: {
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.icon}`]: {
    fontSize: '1.2rem',
  },
  [`& .${classes.exclude}`]: {
    color: theme.palette.error.main,
    fontSize: theme.fontSize.medium,
  },
}));

type ActorsDatagridProps = {
  scopeId: number,
  projectId: number,
}

function ActorsDatagrid(props: ActorsDatagridProps) {
  const { scopeId, projectId } = props;

  const {
    isLoading: loadingActors,
    actors,
  } = useActors({ scopeId });

  const currentUser = useCurrentUser();
  const editDisabled = currentUser?.isConsultant(projectId);

  const rows = useMemo(() => {
    const tempsRows: ActorPatternsDataGrid[] = [];
    actors.forEach((actor) => {
      const actorClass = new Actor(actor);
      tempsRows.push(...actorClass.formatPatternsForDatagrid());
    });

    return tempsRows;
  }, [actors]);

  const filterMethod = useCallback((inputSearch: string) => rows.filter(
    (actor) => (actor.actorName.toLowerCase().includes(inputSearch.trim().toLowerCase())),
  ), [actors]);

  const { handleInputChange, filterRows } = useInputSearch(rows, filterMethod);

  const columns = createActorColumns(scopeId, classes, editDisabled);

  return (
    <StyledBox>
      <Stack
        direction="row"
        spacing={1}
      >
        <CreateActor
          scopeId={scopeId}
          disabled={editDisabled}
        />
        <RenameActor
          scopeId={scopeId}
          disabled={rows.length === 0 || editDisabled}
        />
        <InputSearch
          handleInputChange={handleInputChange}
          label="Chercher un acteur"
        />
      </Stack>
      <DataGrid
        hideFooterSelectedRowCount
        disableRowSelectionOnClick
        disableColumnMenu
        autoHeight
        disableVirtualization
        getRowHeight={() => 50}
        getRowId={(row) => row.id ?? 0}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
          sorting: {
            sortModel: [{ field: 'actors', sort: 'asc' }],
          },
        }}
        slots={{
          loadingOverlay: LinearLoadingOverlay,
        }}
        pageSizeOptions={[50, 100]}
        rows={filterRows()}
        columns={columns}
        slotProps={{
          pagination: {
            labelRowsPerPage: ('Nombre par page :'),
          },
        }}
        loading={loadingActors}
        localeText={{
          noRowsLabel:
            `Vous n'avez encore créé aucun acteur.
            Cliquez sur le bouton "Créer un acteur" pour commencer.`,
        }}
      />
    </StyledBox>
  );
}

export default ActorsDatagrid;
