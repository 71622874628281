import React, { useState } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Typography, Button } from '@mui/material';
import { FormikHelpers, FormikProps, useFormik } from 'formik';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import Modal from 'components/modal/Modal';
import ValidateActionMessage from 'components/modal/ValidateActionMessage';
import { ActorForm } from 'features/actors/types';

import ActorModal from './ActorModal';
import {
  validationSchema,
  initialValues,
} from './setupConfig';
import Actor from '../Actor';
import { MODAL_MODE } from '../CONSTANTS';
import { addActor } from '../state/slice';

type CreateActorProps = {
  scopeId: number,
  disabled?: boolean,
}

export default function CreateActor (props: CreateActorProps) {
  const { scopeId, disabled } = props;
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);

  const actors = useAppSelector((state) => state.actors.actors);

  const handleModalSubmit = (values: ActorForm, formikHelpers: FormikHelpers<ActorForm>) => {
    if (scopeId === 0) return;

    values.domain = values.domain.trim().replace(/^(https?:\/\/)/, '');

    // check if actor already exists
    const existingActor = actors.find((a) => a.name.trim() === values.name.trim());

    if (existingActor) {
      const actor = new Actor(existingActor);
      actor.addSiteUrlPattern(dispatch, values, actor.actorSites[0]?.id ?? 0);
      setIsConfirmationOpen(true);
      formikHelpers.resetForm();
      return;
    }

    const payload = {
      name: values.name.trim(),
      scope_id: scopeId,
      is_client: values.isClient,
      dataviz_color: values.datavizColorDefault
        ? null
        : values.datavizColor,
      sites: [
        {
          name: values.name,
          actor_id: 0,
          site_url_patterns: [
            {
              url_pattern: values.domain.trim(),
              include: true,
              site_url_pattern_type_id: 2,
              pattern_start_type_id: values.subdomains ? 2 : 1,
              site_id: 0,
            },
          ],
        },
      ],
    };
    dispatch(addActor(payload));
    setIsConfirmationOpen(true);
    formikHelpers.resetForm();
  };
  const handleConfirmationClose = () => {
    setIsOpen(false);
  };

  const formik: FormikProps<ActorForm> = useFormik<ActorForm>({
    initialValues,
    validationSchema,
    onSubmit: handleModalSubmit,
    enableReinitialize: true,
  });

  return (
    <Box>
      <Button
        variant="contained"
        onClick={
        () => { setIsOpen(true); setIsConfirmationOpen(false); }
        }
        size="small"
        startIcon={<AddCircleOutlineIcon />}
        data-testid="create-actor-button"
        disabled={disabled}
      >
        Ajouter
      </Button>
      {
        isConfirmationOpen
          ? (
            <Modal
              title={<ValidateActionMessage />}
              displayCancel={false}
              actionConfirm={handleConfirmationClose}
              isOpen={isOpen}
              dataTestId="create-actor-confirm-modal"
            />
          )
          : (
            <Modal
              title={(
                <Typography>
                  <Typography component="span" variant="bold">Ajouter</Typography>
                  {' '}
                  un acteur
                </Typography>
              )}
              actionConfirm={formik.handleSubmit}
              actionCancel={() => {
                setIsOpen(false);
                formik.resetForm();
              }}
              isOpen={isOpen}
              dataTestId="create-actor-modal"
            >
              <ActorModal
                mode={MODAL_MODE.create}
                values={formik.values}
                touched={formik.touched}
                errors={formik.errors}
                onChange={formik.handleChange}
                setFieldValue={formik.setFieldValue}
                onBlur={formik.handleBlur}
              />
            </Modal>
          )
      }
    </Box>
  );
}
