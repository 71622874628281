import moment from 'moment';

import getNextScrapDate from './serpAnalysis';
import { DEFAULT_SCRAP_DATE } from '../CONSTANT';
import { AnalysisMinimal } from '../types';

const findNextScrappingDate = (
  analyses: AnalysisMinimal[],
  projectBudget: string,
): string => {
  if (!analyses.length) return '';

  let temp: any[] = [...analyses];
  temp = temp.map((anl) => ({
    ...anl,
    next_scrapping: getNextScrapDate(
      anl.last_scrapping,
      anl.planning_date,
      anl.serp_analysis_planning_id,
      anl.planningDelay,
      (parseInt(projectBudget, 10) > 0),
    ),
  })).filter((anl) => anl.next_scrapping !== DEFAULT_SCRAP_DATE);

  temp.sort((a, b) => (moment(b).diff(moment(a)) < 0 ? 1 : -1));

  return temp[0]?.next_scrapping || '';
};

export default findNextScrappingDate;
