import React, { useCallback, useEffect, useState } from 'react';

import {
  Grid2 as Grid,
  Stack,
} from '@mui/material';

import RefreshButton from 'components/buttons/RefreshButton';
import InputSearch from 'components/input/inputSearch';
import MainBox from 'components/layout/MainBox';
import { ChartComponentProps } from 'features/dataviz/types';
import getRecalculKPIsStatus from 'utils/getRecalculKPIsStatus';
import useInputSearch from 'utils/useInputSearch';

import UrlsByActorDataGrid from './dataGrid/UrlsByActorDataGrid';
import UrlsByResultType from './type';
import UrlsByActorDownloadButton from './UrlsByActorDownloadButton';

function UrlsByActor(props: ChartComponentProps) {
  const {
    datas,
    handleExport,
    isExportLoading,
    project,
  } = props;
  const [isRecalculatingKPIs, setIsRecalculatingKPIs] = useState<boolean>(false);

  const rows = (datas.data.values ?? []) as UrlsByResultType[];

  const filterMethod = useCallback((inputSearch: string) => rows.filter(
    (url) => (url.url.toLowerCase().includes(inputSearch.trim().toLowerCase())),
  ), [datas]);

  const { handleInputChange, filterRows } = useInputSearch(rows, filterMethod);

  const recalculKPIS = project?.scopes[0]?.recalcul_kpis_running;

  useEffect(() => {
    setIsRecalculatingKPIs(getRecalculKPIsStatus(recalculKPIS));
  }, [recalculKPIS]);

  return (
    <Grid size={12} paddingBottom="10px">
      <MainBox>
        <Stack
          direction="row"
          spacing={2}
        >
          {
            isRecalculatingKPIs && (
              <RefreshButton
                text="Actualiser la page"
                size="small"
              />
            )
          }
          <UrlsByActorDownloadButton
            onClick={handleExport}
            isExportLoading={isExportLoading}
            disabled={isRecalculatingKPIs}
          />
          <InputSearch
            handleInputChange={handleInputChange}
            label="Chercher des URL"
          />
        </Stack>
        <UrlsByActorDataGrid
          rows={filterRows()}
        />
      </MainBox>
    </Grid>
  );
}

export default UrlsByActor;
