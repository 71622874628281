import React from 'react';

import PrivateRoute from 'components/privateRoute/PrivateRoute';
import { CustomRouteObject } from 'pages/projects/types';

import { USER_ADMIN, EXTERNAL_USERS } from './ROUTES';
import ExternalUsers from './userAdmin/ExternalUsers';
import UserAdmin from './userAdmin/UserAdmin';

const routes: CustomRouteObject[] = [
  {
    path: USER_ADMIN,
    element: <PrivateRoute element={<UserAdmin />} />,
    adminOnly: true,
  },
  {
    path: EXTERNAL_USERS,
    element: <PrivateRoute element={<ExternalUsers />} />,
    adminOnly: true,
    dircliOnly: true,
  },
];

export default routes;
