import React from 'react';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  TextField, InputAdornment, TextFieldProps, styled,
} from '@mui/material';

import globalTheme from 'common/theme';

const InputAdorned = styled(TextField)<TextFieldProps>(() => ({
  width: '250px',
}));

type InputSearchProps = {
  handleInputChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  label: string
}

function InputSearch(props: InputSearchProps): JSX.Element {
  const { handleInputChange, label } = props;

  return (
    <InputAdorned
      variant="filled"
      size="small"
      hiddenLabel
      onChange={handleInputChange}
      slotProps={{
        input: {
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          ),
          sx: {
            fontSize: globalTheme.fontSize.middlesmall,
            height: '32px',
          },
        },
      }}
      placeholder={label}
      data-testid="input-search"
    />
  );
}

export default InputSearch;
