import { BUCKET_EXPORT_KPI } from 'common/config';

import { KPI_VIEW_TYPE } from '../CONSTANTS';

type KpiViewTypeParamsRankingTracking = {
  type: typeof KPI_VIEW_TYPE.tracking.label,
  serpAnalysisId: number,
}

type KpiViewTypeParamsBenchmark = {
  type: typeof KPI_VIEW_TYPE.benchmark.label,
}

type KpiViewTypeParamsCaptation = {
  type: typeof KPI_VIEW_TYPE.captation.label,
  serpAnalysisId: number,
  periode: string,
}

type KpiViewTypeParamsIndexability = {
  type: typeof KPI_VIEW_TYPE.indexability.label,
}

type KpiViewTypeParamsSerpTracking = {
  type: typeof KPI_VIEW_TYPE.serpTracking.label,
  serpAnalysisId: number,
}

type KpiViewTypeParamsBenchmarkLight = {
  type: typeof KPI_VIEW_TYPE.benchmarkLight.label,
}

export type KpiViewTypeParams = KpiViewTypeParamsRankingTracking | KpiViewTypeParamsBenchmark
| KpiViewTypeParamsCaptation | KpiViewTypeParamsIndexability | KpiViewTypeParamsSerpTracking
| KpiViewTypeParamsBenchmarkLight;

function formatExportPath(
  projectId: number,
  scopeId: number,
  viewId: number,
  params?: KpiViewTypeParams,
) {
  const basePath = `${BUCKET_EXPORT_KPI}/export-kpi/v3`
  + `/project-${projectId}`
  + `/scope-${scopeId}`;

  if (params?.type === KPI_VIEW_TYPE.tracking.label) {
    return `${basePath}/kpi-view-${viewId}/serp-analysis-${params.serpAnalysisId}.csv`;
  }

  if (params?.type === KPI_VIEW_TYPE.benchmark.label) {
    return `${basePath}/SEO-performance/data-${viewId}.csv`;
  }

  if (params?.type === KPI_VIEW_TYPE.captation.label) {
    return `${basePath}/share-of-voice/share-of-voice-`
    + `${params.periode}-${params.serpAnalysisId}.csv`;
  }

  if (params?.type === KPI_VIEW_TYPE.indexability.label) {
    return `${basePath}/URL-indexability/data-${viewId}.csv`;
  }

  if (params?.type === KPI_VIEW_TYPE.serpTracking.label) {
    return `${basePath}/serp-view-${viewId}/serp-analysis-${params.serpAnalysisId}.csv`;
  }

  if (params?.type === KPI_VIEW_TYPE.benchmarkLight.label) {
    return `${basePath}/SEO-performance-light/data-${viewId}.csv`;
  }

  return basePath;
}

export default formatExportPath;
