import moment from 'moment';
import * as yup from 'yup';

import { ScrapeModalFormikProps } from './types';

const DEFAULT_PLANNING: ScrapeModalFormikProps = {
  date: moment().toISOString(),
  planningId: 5,
  planningDelay: 1,
};

export const initialValues = (
  planningId: number | null,
  planningDate: string | null,
  planningDelay: number | null,
): ScrapeModalFormikProps => {
  if (planningDate && planningId && planningDelay) {
    return ({
      date: planningDate,
      planningId: [3, 5].includes(planningId) ? planningId : 5,
      planningDelay,
    });
  }
  return DEFAULT_PLANNING;
};

export const validationSchema = yup.object().shape({
  date: yup.string().required('Ce champ est obligatoire')
    .test(
      'is date in past',
      'La date choisie doit être dans le futur',
      (date) => {
        if (moment(date).isAfter(moment().subtract(5, 'm'))) return true;
        return false;
      },
    ),
  planningId: yup.number().required('Ce champ est obligatoire'),
});
