import React from 'react';

import { MenuItem, Typography } from '@mui/material';

import globalTheme from 'common/theme';
import KpiView from 'features/kpiView/KpiView';

type UpdateKpiViewActionProps = {
  kpiView: KpiView,
  handleCloseMenu: Function,
  onEditRowValueSelected: (id: number) => void,
  disabled?: boolean,
}

function EditKpiViewAction(props: UpdateKpiViewActionProps) {
  const {
    kpiView,
    handleCloseMenu,
    onEditRowValueSelected,
    disabled,
  } = props;

  return (
    <MenuItem
      sx={{ fontSize: globalTheme.fontSize.medium }}
      onClick={() => { onEditRowValueSelected(kpiView.kpiViewId); handleCloseMenu(); }}
      data-testid="edit-kpi-view-menu-item"
      disabled={disabled}
    >
      <Typography variant="body2">
        Modifier la vue
      </Typography>
    </MenuItem>
  );
}

export default EditKpiViewAction;
