import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';

import ActorDatagridActions from '../actions/ActorDatagridActions';
import { ActorPatternsDataGrid } from '../types';
import getActorColor, { formatUrlDomain } from '../utils/format';

const createActorColumns = (
  scopeId: number,
  classes: any,
  actionsDisabled?: boolean,
): GridColDef<ActorPatternsDataGrid>[] => ([
  {
    field: 'is_client',
    headerName: '',
    renderCell: (params) => (
      <CircleIcon
        className={classes.icon}
        sx={{
          color: getActorColor(params.row.isClient, params.row.datavizColor),
        }}
      />
    ),
    flex: 0.25,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'actors',
    renderHeader: () => (
      <StyledHeader label="Acteurs" />
    ),
    renderCell: (params) => (
      <Typography className={classes.text}>{params.row.actorName}</Typography>
    ),
    flex: 2,
    sortable: true,
    valueGetter: (value, row) => row.actorName,
    sortComparator: (v1, v2) => v1.localeCompare(v2),
  },
  {
    field: 'domain',
    renderHeader: () => (
      <StyledHeader label="Domaine/Url" />
    ),
    renderCell: (params) => (
      <Typography variant="body2">
        {params.value}
      </Typography>
    ),
    valueGetter: (value, row) => formatUrlDomain(
      row.url_pattern,
      row.pattern_start_type_id,
    ),
    flex: 6,
  },
  {
    field: 'exclusion',
    renderHeader: () => (
      <StyledHeader label="Exclusion" />
    ),
    sortable: false,
    renderCell: (params) => (
      <Typography
        className={classes.exclude}
      >
        {params.row.include ? '' : 'URL ignorées'}
      </Typography>
    ),
    flex: 1,
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    flex: 1.5,
    align: 'right',
    renderCell: (params) => (
      <ActorDatagridActions
        scopeId={scopeId}
        pattern={params.row}
        actionsDisabled={actionsDisabled}
      />
    ),
  },
]);

export default createActorColumns;
