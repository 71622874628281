import React from 'react';

import {
  Box,
  BoxProps,
  styled,
  Stack,
  FormControl,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

import globalTheme from 'common/theme';
import DropDown from 'components/dropDown/DropDown';
import { ScrapeModalContentProps } from 'components/modal/types';
import PlanningsDropDown from 'features/serpAnalysisPlanning/select/PlanningsDropDown';

const PREFIX = 'ScrapeModalContent';

const classes = {
  subTitle: `${PREFIX}-subTitle`,
  smallKeywords: `${PREFIX}-smallKeywords`,
  smallPrice: `${PREFIX}-smallPrice`,
  input: `${PREFIX}-input`,
  planningSelect: `${PREFIX}-planningSelect`,
  datePicker: `${PREFIX}-datePicker`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.subTitle}`]: {
    fontSize: theme.fontSize.xsmall,
  },

  [`& .${classes.smallKeywords}`]: {
    fontSize: theme.fontSize.xsmall,
  },

  [`& .${classes.smallPrice}`]: {
    fontWeight: 'bold',
    fontSize: theme.fontSize.xsmall,
  },

  [`& .${classes.input}`]: {
    fontSize: theme.fontSize.medium,
    textAlign: 'left',
  },

  [`& .${classes.planningSelect}`]: {
    textAlign: 'left',
  },

  [`& .${classes.datePicker}`]: {
    '& .MuiOutlinedInput-input': {
      fontSize: theme.fontSize.medium,
    },
  },
}));

export default function ScrapeModalContent(props: ScrapeModalContentProps): JSX.Element {
  const {
    values,
    handleChange,
    setFieldValue,
    touched,
    errors,
    planningChoice,
  } = props;

  return (
    <StyledBox
      maxWidth="sm"
      width="100%"
      textAlign="center"
      padding={1}
      data-testid="test-stack"
    >
      <Stack gap={2} alignItems="stretch" width="100%" margin="auto">
        <Stack direction="row" gap={2} justifyContent="center">
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={moment.locale('fr')}
          >
            <DateTimePicker
              label="À partir du"
              value={moment(values.date)}
              onChange={(val) => setFieldValue('date', moment(val).toISOString())}
              format="DD/MM/YYYY HH:mm"
              minDateTime={moment()}
              ampm={false}
              sx={{ width: '50%' }}
              slotProps={{
                textField: {
                  size: 'small',
                  error: Boolean(touched.date) && Boolean(errors.date),
                  helperText: (
                    touched.date && Boolean(errors.date) && errors.date
                  ),
                  label: 'À partir du',
                  value: moment(values.date),
                  placeholder: 'dd/mm/yyyy hh:mm',
                  onChange: (val: any) => setFieldValue('date', moment(val).toISOString()),
                  className: classes.datePicker,
                },
                layout: {
                  sx: {
                    '.MuiMultiSectionDigitalClock-root': {
                      width: '144px',
                      '.MuiMultiSectionDigitalClockSection-root': {
                        width: '70px',
                      },
                      '.MuiMenuItem-root': {
                        width: '65px',
                        fontSize: globalTheme.fontSize.xsmall,
                      },
                    },
                  },
                },
              }}
              data-testid="scrape-modal-planning-field-date"
            />
          </LocalizationProvider>
          {planningChoice === 'serpManual' && (
            <FormControl size="small" className={classes.planningSelect}>
              <DropDown
                value={values.planningDelay}
                onChange={handleChange}
                width="70px"
                size="small"
                error={
                  touched.planningDelay
                  && Boolean(errors.planningDelay)
                }
                options={[...Array(31).keys()].map((i) => ({ label: i.toString(), value: i }))}
                label="Tous les"
                name="planningDelay"
              />
            </FormControl>
          )}
          <FormControl size="small" className={classes.planningSelect}>
            <PlanningsDropDown
              value={values.planningId}
              handleChange={handleChange}
              width={planningChoice === 'serpManual' ? '100px' : '100%'}
              size="small"
              error={
                touched.planningId
                && Boolean(errors.planningId)
              }
              label=""
              optionsChoice={planningChoice}
              name="planningId"
            />
          </FormControl>
        </Stack>
      </Stack>
    </StyledBox>
  );
}
