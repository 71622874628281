import { matchRoutes } from 'react-router-dom';

import USER_TYPES from 'features/users/CONSTANTS';
import { UserType } from 'features/users/types';
import { routesConfig } from 'router/RouterConfig';

function canUserAcessRoute(currentUser: UserType, path: string) {
  const route = matchRoutes(routesConfig, path)?.pop();

  if (!route) return false;

  const { externalBlocked, adminOnly, dircliOnly } = route.route;

  if (
    externalBlocked
    && currentUser.user_type.is_external
  ) return false;

  if (
    adminOnly
    && currentUser.user_type_id === USER_TYPES.admin.id
  ) return true;

  if (
    dircliOnly
    && currentUser.user_type_id === USER_TYPES.dircli.id
  ) return true;

  // If any of the limited access params is used, block the access
  if ([adminOnly, dircliOnly].some(Boolean)) return false;

  return true;
}

export default canUserAcessRoute;
