import { axiosAuthInstance } from 'common/axiosAuthInstance';

import {
  KeywordsList, KeywordsDelete, KeywordsAdd,
} from '../types';

export default class ApiKeywords {
  static deleteKeywords(payload: KeywordsDelete) {
    return axiosAuthInstance.delete<never, KeywordsList>(
      'serp_analysis_keyword/multiple',
      { data: payload },
    );
  }

  static addKeywords(payload: KeywordsAdd[]) {
    return axiosAuthInstance.post<never, KeywordsAdd[]>(
      'serp_analysis_keyword/multiple/with_keyword',
      payload,
    );
  }

  static fetchKeywords(payload: number) {
    return axiosAuthInstance.get<never, KeywordsAdd[]>(
      `serp_analysis/${payload}/keywords`,
    );
  }
}
