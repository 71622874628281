import { useState } from 'react';

function useChartOpacity(dataKeys: any[]) {
  const generateOpacity = (val: number) => {
    let obj = {};
    for (let i = 0; i < dataKeys.length; i += 1) {
      obj = {
        ...obj,
        [dataKeys[i].dataKey]: val,
      };
    }
    return obj;
  };
  const [opacity, setOpacity] = useState(generateOpacity(1));
  const [playAnimation, setPlayAnimation] = useState(true);

  const handleMouseEnter = (o: any) => {
    setPlayAnimation(false);
    const { dataKey } = o;
    setOpacity({
      ...generateOpacity(0.4),
      [dataKey]: 1,
    });
  };
  const handleMouseLeave = () => {
    setOpacity(generateOpacity(1));
    setPlayAnimation(true);
  };

  return {
    opacity,
    handleMouseEnter,
    handleMouseLeave,
    playAnimation,
    generateOpacity,
  };
}

export default useChartOpacity;
