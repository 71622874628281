import { BasicUserType } from 'pages/projects/types';

import UserType from './UserType';

export default class BasicUser {
  private id: number;

  private email: string;

  private first_name: string | null;

  private last_name: string | null;

  private avatar: string | null;

  private userType: UserType;

  constructor(user: BasicUserType) {
    this.id = user.id;
    this.email = user.email;
    this.first_name = user.first_name;
    this.last_name = user.last_name;
    this.avatar = user.avatar;
    this.userType = new UserType(user.user_type);
  }

  get userId() {
    return this.id;
  }

  get firstName() {
    return this.first_name;
  }

  get userAvatar() {
    return this.avatar;
  }

  get userFullName() {
    if (this.userType.is_external) return 'Utilisateur externe';
    if (!this.first_name && !this.last_name) return this.email;

    return `${this.first_name ?? ''} ${this.last_name ?? ''}`.trim();
  }

  fullNameWithManagerInfos(managerId: number | undefined) {
    if (this.id === managerId) return `${this.first_name} ${this.last_name} (manager)`;
    return this.userFullName;
  }
}
