import React, { useState, useEffect, MouseEventHandler } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import {
  Button,
  Typography,
  styled,
  Box,
  BoxProps,
} from '@mui/material';

import Modal from 'components/modal/Modal';

const PREFIX = 'ExportKwButton';

const classes = {
  button: `${PREFIX}-button`,
  spinner: `${PREFIX}-spinner`,
  spinning: `${PREFIX}-spinning`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.button}`]: {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
  },

  [`& .${classes.spinner}`]: {
    color: theme.palette.primary.light,
  },

  [`& .${classes.spinning}`]: {
    backgroundColor: theme.palette.secondary.main,
    cursor: 'default',
  },

}));

type ExportPerformanceButtonProps = {
  handleExport: MouseEventHandler<HTMLElement> | undefined,
  isExportLoading: boolean | undefined,
}

export default function ExportPerformanceButton(props: ExportPerformanceButtonProps): JSX.Element {
  const {
    handleExport,
    isExportLoading,
  } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isPostDownload, setPostDownload] = useState<boolean>(false);

  const handleConfirm = (e: any) => {
    if (handleExport) handleExport(e);
    setPostDownload(true);
  };

  useEffect(() => {
    if (isPostDownload && !isExportLoading) {
      setOpenModal(false);
      setPostDownload(false);
    }
  }, [isExportLoading, isPostDownload]);

  return (
    <StyledBox>
      <Button
        onClick={() => { if (!openModal) setOpenModal(true); }}
        size="small"
        variant="outlined"
        startIcon={<DownloadIcon />}
        className={classes.button}
        data-testid="export-seo-performance-button"
      >
        Voir les données
      </Button>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">Télécharger</Typography>
            {' '}
            ce fichier
          </Typography>
        )}
        actionConfirm={handleConfirm}
        actionCancel={() => { setOpenModal(false); setPostDownload(false); }}
        isOpen={openModal}
        dataTestId="export-seo-performance-modal"
        displayLoading={isExportLoading}
        isProcessLoading={isExportLoading}
      />
    </StyledBox>
  );
}
