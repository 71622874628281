import React from 'react';

import { LinearProgress } from '@mui/material';

function LinearLoadingOverlay() {
  return (
    <LinearProgress />
  );
}

export default LinearLoadingOverlay;
