import * as React from 'react';

import { Box } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import { fetchLogout } from 'features/authentication/state/slice';
import { HOME } from 'router/CONSTANTS';

import LeftDrawerView from './LeftDrawerView';
import { leftMenu } from '../config';

export default function LeftDrawer(): JSX.Element {
  const dispatch = useAppDispatch();

  const { currentUser } = useAppSelector(
    (state) => state.authentication,
  );

  const handleLogout = () => {
    dispatch(fetchLogout({
      callbackSuccessUrl: HOME,
      callbackFailureUrl: HOME,
    }));
  };

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 100,
      }}
      data-testid="left-drawer"
    >
      <LeftDrawerView
        currentUser={currentUser}
        handleLogout={handleLogout}
        menuList={leftMenu(currentUser)}
      />
    </Box>
  );
}
