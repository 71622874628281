import React, { useState } from 'react';

import {
  Box, Stack,
} from '@mui/material';
import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
import {
  FormikProps,
  useFormik,
} from 'formik';
import moment from 'moment';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import CheckBoxDataGrid from 'components/checkbox/CheckBoxDataGrid';
import LinearLoadingOverlay from 'components/loading/LinearLoadingOverlay';
import { DATE_ENGLISH, convertDate } from 'utils/formatDate';

import createColumns from './configFormDatagrid';
import AddDataFile from '../actions/AddDataFile';
import GlobalDeleteDataFile from '../actions/GlobalDeleteDataFile';
import GlobalUpdateDataFile from '../actions/GlobalUpdateDataFile';
import { formatNewDataFileDate } from '../components/setupAddDataFiles';
import { DATA_FILE_STATUS } from '../CONSTANT';
import { updateDataFiles } from '../state/slice';
import { DataFileFormUpdate, DataFileType } from '../types';

type FormDatagridProps = {
  scopeId: number,
  dataFiles: DataFileType[],
  isLoading: boolean,
};

function FormDatagrid(props: FormDatagridProps) {
  const {
    scopeId,
    dataFiles,
    isLoading,
  } = props;
  const dispatch = useAppDispatch();
  const [idsSelected, setIdsSelected] = useState<number[]>([]);

  const handleSelect = (ids: GridRowSelectionModel) => {
    setIdsSelected(ids.map((id) => parseInt(`${id}`, 10)));
  };

  const handleSubmit = (values: DataFileFormUpdate, idsToSubmit: number[]) => {
    const payload = {
      dataFiles: values.dataFiles.filter(
        (dataFile) => (
          dataFile.actor_id
          && dataFile.periode
          && dataFile.periode !== ''
          && idsToSubmit.includes(dataFile.id)
        ),
      ).map(
        (dataFile) => {
          const formatedPeriode = formatNewDataFileDate(
            moment(dataFile.periode),
            dataFile.data_source_id,
          );
          return {
            id: dataFile.id,
            periode: (
              formatedPeriode !== null
                ? convertDate(
                  formatedPeriode.toDate(),
                  DATE_ENGLISH,
                )
                : undefined
            ),
            actor_id: dataFile.actor_id,
            data_file_status_id: DATA_FILE_STATUS.to_import,
          };
        },
      ),
    };
    dispatch(updateDataFiles(payload));
  };

  const formik: FormikProps<DataFileFormUpdate> = useFormik<DataFileFormUpdate>({
    initialValues: {
      dataFiles,
    },
    onSubmit: (values) => handleSubmit(values, idsSelected),
    enableReinitialize: true,
  });

  const handleFieldUpdate = (
    dataFile: DataFileType,
    name: string,
    value: string | number,
  ) => {
    const fieldName = `dataFiles[${
      formik.values.dataFiles.indexOf(dataFile)
    }].${name}`;
    formik.setFieldValue(fieldName, value);
  };

  const handleUpdateDataFile = (dataFileId: number) => (
    handleSubmit(formik.values, [dataFileId])
  );

  const checkQualifDataFiles = (ids: number[]) => (
    !formik.values.dataFiles.find(
      (dataFile) => (
        dataFile.actor_id
        && dataFile.periode
        && dataFile.periode !== ''
        && ids.includes(dataFile.id)
      ),
    )
  );

  return (
    <Box>
      <Stack direction="row">
        <Stack
          direction="row"
          spacing={1}
          width="40%"
          height={35}
        >
          <AddDataFile scopeId={scopeId} />
        </Stack>
        {idsSelected.length !== 0 && (
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={1}
            width="60%"
          >
            <Box
              sx={{
                marginRight: '18px',
                fontSize: globalTheme.fontSize.middlesmall,
                fontWeight: '500',
              }}
            >
              {`${idsSelected.length} `}
              fichier
              {
                (idsSelected.length > 1
                  ? 's '
                  : ' '
                )
              }
              sélectionné
              {
                (idsSelected.length > 1
                  ? 's'
                  : ''
                )
              }
            </Box>
            <GlobalUpdateDataFile
              dataFileIds={idsSelected}
              handleConfirm={formik.handleSubmit}
              error={checkQualifDataFiles(idsSelected)}
            />
            <GlobalDeleteDataFile dataFileIds={idsSelected} />
          </Stack>
        )}
      </Stack>
      <DataGrid
        getRowId={(row) => row.id}
        loading={isLoading}
        density="compact"
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        disableColumnMenu
        autoHeight
        disableVirtualization
        checkboxSelection
        getRowHeight={() => 'auto'}
        columns={
          createColumns(
            handleFieldUpdate,
            handleUpdateDataFile,
            checkQualifDataFiles,
          )
        }
        rows={formik.values.dataFiles}
        slots={{
          loadingOverlay: LinearLoadingOverlay,
          baseCheckbox: CheckBoxDataGrid,
        }}
        localeText={{
          noRowsLabel: 'Aucun fichier trouvé',
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} sur ${count}`,
          },
        }}
        slotProps={{
          pagination: {
            labelRowsPerPage: ('Nombre par page :'),
          },
        }}
        sx={{ marginTop: '10px' }}
        onRowSelectionModelChange={(dataFileSelected) => {
          handleSelect(dataFileSelected);
        }}
        initialState={{
          sorting: { sortModel: [{ field: 'period', sort: 'desc' }] },
        }}
      />
    </Box>
  );
}

export default FormDatagrid;
