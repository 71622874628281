import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HarvestProjectType } from '../types';

export interface State {
  isLoading: boolean;
  harvestProjects: HarvestProjectType[];
  error: string;
}

const initialState: State = {
  isLoading: false,
  harvestProjects: [],
  error: '',
};

export const harvestProjectSlice = createSlice({
  name: 'harvestProject',
  initialState,
  reducers: {
    fetchHarvestProjects: (state: State) => {
      state.isLoading = true;
    },
    fetchHarvestProjectsSuccess: (state: State, action: PayloadAction<HarvestProjectType[]>) => {
      state.harvestProjects = action.payload;
      state.isLoading = false;
    },
    fetchHarvestProjectsFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    cleanHarvestProjects: (state: State) => {
      state.harvestProjects = [];
    },
  },
});

export const {
  fetchHarvestProjects,
  fetchHarvestProjectsSuccess,
  fetchHarvestProjectsFailure,
  cleanHarvestProjects,
} = harvestProjectSlice.actions;

export default harvestProjectSlice.reducer;
