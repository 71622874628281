import { axiosAuthInstance } from 'common/axiosAuthInstance';

import { Dataviz, DatavizApiRequest } from '../types';

export default class ApiDataviz {
  static fetchChartData(payload: DatavizApiRequest) {
    return axiosAuthInstance.post<never, Dataviz>(
      `dataviz/analyze/${payload.resource}/${payload.metric}`,
      payload.payload,
    );
  }

  static fetchExportData(payload: DatavizApiRequest) {
    return axiosAuthInstance.post<never, Dataviz>(
      `dataviz/analyze/${payload.resource}/${payload.metric}/csv`,
      payload.payload,
    );
  }
}
