import hexStringToByteArray from './hexStringToByteArray';

function getTextContrast(rgbValue: number[] | string | undefined) {
  let rgb: number[] | Uint8Array;

  if (!rgbValue) {
    rgb = [0, 0, 0];
  } else if (typeof rgbValue === 'string') {
    let formatedStringRgb = rgbValue;
    if (rgbValue.includes('#', 0)) {
      formatedStringRgb = rgbValue.slice(1);
    }
    if (formatedStringRgb.length === 3) {
      formatedStringRgb.split('').map((byte) => byte + byte);
    }

    try {
      rgb = hexStringToByteArray(formatedStringRgb);
    } catch {
      rgb = [0, 0, 0];
    }
  } else {
    rgb = rgbValue;
  }
  // algorithm from https://www.w3.org/TR/AERT/#color-contrast
  const brightness = Math.round((
    (rgb[0] * 299)
    + (rgb[1] * 587)
    + (rgb[2] * 114)
  ) / 1000);

  return brightness > 125 ? '#000000' : '#FFFFFF';
}

export default getTextContrast;
