import React from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';

import globalTheme from 'common/theme';

type CopyToClipboardButtonProps = {
  textToCopy: string,
}

function CopyToClipboardButton(props: CopyToClipboardButtonProps) {
  const { textToCopy } = props;
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <IconButton
      onClick={() => copyToClipboard(textToCopy)}
      color="primary"
      data-testid="copy-to-clipboard-button"
    >
      <ContentCopyIcon
        sx={{
          color: globalTheme.palette.primary.light,
          fontSize: globalTheme.fontSize.mediumLarge,
        }}
      />
    </IconButton>
  );
}

export default CopyToClipboardButton;
