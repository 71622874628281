import React, { useState } from 'react';

import { MenuItem, Box, Typography } from '@mui/material';
import { FormikHelpers, FormikProps, useFormik } from 'formik';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import ValidateActionMessage from 'components/modal/ValidateActionMessage';

import ActorModal from './ActorModal';
import {
  validationSchema,
  initValuesUpdate,
} from './setupConfig';
import Actor from '../Actor';
import { MODAL_MODE } from '../CONSTANTS';
import {
  updateActor,
  selectActorById,
} from '../state/slice';
import { ActorForm, ActorPatternsDataGrid } from '../types';

type UpdatePatternsProps = {
  scopeId: number,
  siteId: number,
  rowData: ActorPatternsDataGrid,
  handleCloseMenu: Function,
  disabled?: boolean,
}

export default function UpdatePatterns(props: UpdatePatternsProps): JSX.Element {
  const {
    scopeId,
    siteId,
    rowData,
    handleCloseMenu,
    disabled,
  } = props;

  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);

  const temp = useAppSelector((state) => selectActorById(state.actors, rowData.actorId));
  const actor = temp ? new Actor(temp) : undefined;
  const patternCount = actor?.formatPatternsForDatagrid().length;

  const handleModalSubmit = (values: ActorForm, formikHelpers: FormikHelpers<ActorForm>) => {
    if (scopeId === 0 || !actor) return;

    values.domain = values.domain.trim().replace(/^(https?:\/\/)/, '');
    actor.updateSiteUrlPattern(dispatch, values, siteId, rowData.id);

    if (values.datavizColor !== rowData.datavizColor) {
      dispatch(updateActor({
        actor: {
          name: undefined,
          scope_id: undefined,
          is_client: undefined,
          dataviz_color: values.datavizColorDefault ? null : values.datavizColor,
        },
        actorId: rowData.actorId,
      }));
    }

    setIsConfirmationOpen(true);
    formikHelpers.resetForm();
  };
  const handleConfirmationClose = () => {
    setOpenModal(false);
  };
  const formik: FormikProps<ActorForm> = useFormik<ActorForm>({
    initialValues: initValuesUpdate({
      key: '',
      name: rowData.actorName,
      domain: rowData.url_pattern,
      isClient: rowData.isClient,
      subdomains: rowData.pattern_start_type_id === 2,
      exclude: !rowData.include,
      datavizColor: rowData.datavizColor,
      datavizColorDefault: !rowData.datavizColor,
    }),
    validationSchema,
    onSubmit: handleModalSubmit,
    enableReinitialize: true,
  });
  return (
    <>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => {
            if (!openModal) setOpenModal(true); handleCloseMenu(); setIsConfirmationOpen(false);
          }
        }
        data-testid="update-pattern-menu-button"
        disabled={disabled}
      >
        Modifier la règle
      </MenuItem>
      {
        isConfirmationOpen
          ? (
            <Modal
              title={<ValidateActionMessage />}
              displayCancel={false}
              actionConfirm={handleConfirmationClose}
              isOpen={openModal}
              dataTestId="update-pattern-confirm-modal"
            >
              <Box>
                <Typography>L&apos;acteur a bien été modifié</Typography>
              </Box>
            </Modal>
          )
          : (
            <Modal
              title={(
                <Typography>
                  <Typography component="span" variant="bold">Modifier</Typography>
                  {' '}
                  cette règle
                </Typography>
              )}
              actionConfirm={formik.handleSubmit}
              actionCancel={() => {
                setOpenModal(false);
                formik.resetForm();
              }}
              isOpen={openModal}
              dataTestId="update-pattern-modal"
            >
              <ActorModal
                mode={MODAL_MODE.update}
                patternCount={patternCount}
                values={formik.values}
                touched={formik.touched}
                errors={formik.errors}
                onChange={formik.handleChange}
                setFieldValue={formik.setFieldValue}
                onBlur={formik.handleBlur}
              />
            </Modal>
          )
      }
    </>
  );
}
