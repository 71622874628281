import { axiosAuthInstance } from 'common/axiosAuthInstance';

import { Transaction } from '../types';

export default class ApiTransaction {
  static fetchTransactions(projectId: number) {
    return axiosAuthInstance.get<Transaction[]>(
      `projects/${projectId}/transactions`,
    );
  }
}
