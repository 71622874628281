import React from 'react';

import {
  AppRegistrationOutlined,
  LogoutOutlined,
  SettingsOutlined,
} from '@mui/icons-material';

import USER_TYPES from 'features/users/CONSTANTS';
import { UserType } from 'features/users/types';
import { EXTERNAL_USERS, USER_ADMIN } from 'pages/admin/ROUTES';
import {
  PROJECTS,
} from 'router/CONSTANTS';

import { MenuList } from './types';

const leftDrawerWidth = 60;
const rightDrawerWidth = 300;
const buttonLength = 30;

const leftMenu = (currentUser?: UserType): MenuList[] => [
  {
    position: 'top',
    menu: [
      {
        name: 'Projets',
        icon: <AppRegistrationOutlined />,
        link: PROJECTS,
      },
    ],
  },
  {
    position: 'bottom',
    menu: [
      {
        name: 'Admin',
        icon: <SettingsOutlined />,
        link: currentUser?.user_type_id === USER_TYPES.admin.id
          ? USER_ADMIN
          : EXTERNAL_USERS,
        isAdminRequired: true,
        isDircliRequired: true,
      },
      {
        name: 'Déconnexion',
        icon: <LogoutOutlined />,
        link: '/',
        logout: (handleLogout: Function) => handleLogout(),
      },
    ],
  },
];

export {
  leftMenu,
  leftDrawerWidth,
  rightDrawerWidth,
  buttonLength,
};
