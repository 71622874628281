import React from 'react';

import {
  Checkbox,
  FormControl,
  FormControlPropsSizeOverrides,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@mui/material';
import { SelectChangeEvent, SelectInputProps } from '@mui/material/Select/SelectInput';
import { StandardCSSProperties } from '@mui/system/styleFunctionSx';
import { OverridableStringUnion } from '@mui/types';
import { Property } from 'csstype';

import globalTheme from 'common/theme';

import { DefaultDropDownItemsType, DropDownValueType } from './type';

export type DropDownProps<T = DefaultDropDownItemsType> = {
  /**
   * The `input` value. Providing an empty string will select no options.
   * Set to an empty string `''` if you don't want any of the available options to be selected.
   */
  value: DropDownValueType | DropDownValueType[],
  onChange: SelectInputProps<any>['onChange'],
  options: T[],
  /**
   * The `getLabel` value. Allow you to get a label from any key from the items
   */
  getOptionLabel?: (value: T) => string,
  /**
   * The `getValue` value. Allow you to get a value from any key from the items
   */
  getOptionValue?: (value: T) => DropDownValueType,
  /**
   * The `name` value. Providing a name will set it to the Select component
   */
  name?: string,
  /**
   * The `label` value. Not providing this value will hide the DropDown label
   */
  label?: string,
  /**
   * The `emptyOption` value. Providing it will add an option to set the value to empty
   */
  emptyOption?: string,
  /**
   * The `optionEmptyHelper` value. Providing it will add an option when the item list is empty
   */
  optionEmptyHelper?: string,
  /**
   * The `displayEmptyInField` value. Providing it will display the empty value in the field
   */
  displayEmptyInField?: boolean,
  /**
   * The `displayEmptyInList` value. Providing it will display the empty value in the options list
   */
  displayEmptyInList?: boolean,
  multiple?: boolean,
  size?: OverridableStringUnion<'small' | 'medium', FormControlPropsSizeOverrides>,
  fullWidth?: boolean,
  height?: Property.Height | undefined,
  width?: Property.Width | undefined,
  margin?: StandardCSSProperties['margin'],
  fontSize?: keyof typeof globalTheme.fontSize,
  error?: boolean,
  className?: {
    select?: string,
  },
  disabled?: boolean,
  'data-testid'?: string,
  variant?: 'filled' | 'outlined' | 'standard',
  /**
   * The `displayGlobalCheckbox` value. Setting it to false will hide the checkbox for global select/unselect
   */
  displayGlobalCheckbox?: boolean,
  errorText?: string,
}

/**
 * {@link https://agencewam.atlassian.net/wiki/spaces/AWAM/pages/356679684/DropDown Link} to the technical documentation
 */
function DropDown<T extends object>(props: DropDownProps<T>) {
  const {
    value,
    label,
    emptyOption,
    optionEmptyHelper,
    displayEmptyInField,
    displayEmptyInList,
    multiple = false,
    onChange,
    options,
    getOptionLabel = (option: any) => option.label,
    getOptionValue = (option: any) => option.value,
    name,
    size,
    fullWidth,
    height,
    width,
    margin,
    fontSize = 'medium',
    error,
    className,
    disabled = false,
    'data-testid': dataTestId,
    variant = 'outlined',
    displayGlobalCheckbox = true,
    errorText = '',
  } = props;

  const isMultiple = multiple && Array.isArray(value);
  const renderValue = (selected: DropDownValueType | DropDownValueType[]) => {
    let optionLabel: string;
    if (selected === '' && !options.length && !label) return optionEmptyHelper;
    if (selected === '' && options.length && !label) return emptyOption;
    if (Array.isArray(selected)) {
      const selectedItems = options.filter((option) => selected.includes(getOptionValue!(option)));
      optionLabel = selectedItems.map((selectedItem) => getOptionLabel(selectedItem)).join(', ');
    } else {
      const selectedItem = options.find((option) => getOptionValue!(option) === selected);
      optionLabel = selectedItem ? getOptionLabel(selectedItem) : '';
    }

    return optionLabel;
  };

  const getReturnValue = () => {
    let returnValue: DropDownValueType | DropDownValueType[];
    if (Array.isArray(value)) {
      returnValue = value.filter((v) => options.find((option) => getOptionValue!(option) === v));
    } else {
      returnValue = options.find((option) => getOptionValue!(option) === value) ? value : '';
    }

    return returnValue;
  };

  const handleSelectChange = (
    event: SelectChangeEvent<string | number | DropDownValueType[]>,
    child: any,
  ) => {
    if (isMultiple && Array.isArray(event.target.value)) {
      let selected = event.target.value;

      if (selected.find((option) => option === '') !== undefined) {
        selected = selected.filter((option) => option !== '');
      }

      if (selected.find((option) => option === 'all')) {
        selected = selected.length - 1 === options.length
          ? []
          : options.map((option) => getOptionValue!(option));
      }
      event.target.value = selected;
      onChange?.(event, child);
    } else {
      onChange?.(event, child);
    }
  };

  return (
    <FormControl
      size={size}
      error={error}
      fullWidth={fullWidth}
      sx={{ m: margin, width }}
      data-testid={dataTestId}
      disabled={disabled}
    >
      { label && (
        <InputLabel
          size={size === 'small' ? 'small' : 'normal'}
          sx={{ fontSize: globalTheme.fontSize[fontSize!] }}
          id={`${name}-label`}
        >
          {label}
        </InputLabel>
      )}
      <Select
        value={getReturnValue()}
        multiple={multiple}
        name={name}
        variant={variant}
        label={label}
        labelId={`${name}-label`}
        displayEmpty={displayEmptyInField}
        onChange={handleSelectChange}
        renderValue={(selected) => renderValue(selected)}
        sx={{
          height,
          width,
          fontSize: globalTheme.fontSize[fontSize!],
        }}
        inputProps={{
          sx: {
            fontSize: globalTheme.fontSize[fontSize!],
          },
        }}
        className={className?.select}
      >
        {isMultiple && options.length > 0 && displayGlobalCheckbox && (
          <MenuItem
            key="menu-option-global-checkbox"
            value="all"
            data-testid="menu-option-global-checkbox"
            dense={multiple}
            sx={{
              fontSize: globalTheme.fontSize[fontSize!],
              height: (multiple && size === 'small') ? '1rem' : '',
            }}
          >
            <div>
              <Checkbox
                checked={value.length === options.length}
                indeterminate={value.length > 0 && value.length < options.length}
              />
              Tous / Aucun
            </div>
          </MenuItem>
        )}
        {optionEmptyHelper && options.length === 0 && (
          <MenuItem
            key={`empty-label-${optionEmptyHelper}`}
            data-testid={`empty-label-${optionEmptyHelper}`}
            value=""
            sx={{
              fontSize: globalTheme.fontSize[fontSize!],
            }}
          >
            {optionEmptyHelper}
          </MenuItem>
        )}
        {emptyOption && displayEmptyInList && (
          <MenuItem
            key={`empty-choice-${emptyOption}`}
            data-testid={`empty-choice-${emptyOption}`}
            value=""
            sx={{
              fontSize: globalTheme.fontSize[fontSize!],
            }}
          >
            {emptyOption}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            key={`${getOptionLabel(option)}-${Math.random()}`}
            value={getOptionValue(option)}
            dense={multiple}
            sx={{
              fontSize: globalTheme.fontSize[fontSize!],
              height: (multiple && size === 'small') ? '1rem' : '',
            }}
          >
            {isMultiple && getOptionValue(option) !== '' && (
              <Checkbox checked={value.indexOf(getOptionValue(option)) > -1} />
            )}
            {getOptionLabel(option)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
}

export default DropDown;
