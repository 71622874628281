import React, { useState } from 'react';

import {
  Box, Button, Typography,
} from '@mui/material';

import Modal from 'components/modal/Modal';
import { ANALYSIS_STATUS } from 'features/serpAnalysis/CONSTANT';
import { Analysis } from 'features/serpAnalysis/types';

import useKeywords from '../state/useKeywords';

type KwDeleteProps = {
  analysis: Analysis | undefined,
  keywordsSelected: number,
  keywordsToDelete: number[],
  disabled?: boolean,
}

export default function KeywordsDeleteModal (props: KwDeleteProps): JSX.Element {
  const {
    analysis,
    keywordsSelected,
    keywordsToDelete,
    disabled,
  } = props;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const { deletingKeywords } = useKeywords({ idAnalysis: analysis?.id || 0 });

  return (
    <Box>
      <Button
        variant="contained"
        onClick={() => setOpenModal(true)}
        color="error"
        size="large"
        disabled={analysis?.serp_analysis_status_id === ANALYSIS_STATUS.IN_PROGRESS || disabled}
      >
        Suppression
      </Button>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">
              Supprimer
              {' '}
              {keywordsSelected}
              {' '}
              mot
              {keywordsSelected > 1 ? 's ' : ' '}
              clé
              {keywordsSelected > 1 ? 's ' : ' '}
            </Typography>
            {' '}
            et les données associées
          </Typography>
        )}
        actionConfirm={() => {
          deletingKeywords(keywordsToDelete);
          setOpenModal(false);
        }}
        actionCancel={() => setOpenModal(false)}
        isOpen={openModal}
      />
    </Box>
  );
}
