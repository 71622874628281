import React from 'react';

import {
  Box,
  styled,
  BoxProps,
  Divider,
  Stack,
} from '@mui/material';
import img from 'assets/images/login_page_img_2_OPTI.png';
import logo from 'assets/images/logo_bh.svg';

import useDocumentTitle from 'common/documentTitleHook';
import LoginGlobalForm from 'features/authentication/components/LoginGlobalForm';

const PREFIX = 'LoginView';

const classes = {
  root: `${PREFIX}-root`,
  stack: `${PREFIX}-stack`,
  form: `${PREFIX}-form`,
  img: `${PREFIX}-img`,
  logo: `${PREFIX}-logo`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({

  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100vh',
    width: '100vw',
  },

  [`& .${classes.stack}`]: {
    alignSelf: 'center',
    justifySelf: 'stretch',
    width: '25vw',
    position: 'absolute',
    right: '10vw',
    backgroundColor: 'white',
    padding: theme.spacing(4),
    borderRadius: '10px',
    border: '2px solid #CBCDFE',
    display: 'flex',
  },

  [`& .${classes.img}`]: {
    height: '100vh',
    width: '100vw',
  },

  [`& .${classes.logo}`]: {
    width: '150px',
    margin: '0 auto',
    color: theme.palette.primary.dark,
  },
}));

function Login(): JSX.Element {
  useDocumentTitle('benhur | connectez vous !');

  return (
    <StyledBox className={classes.root}>
      <Box
        className={classes.img}
        component="img"
        src={img}
      />
      <Stack className={classes.stack} spacing={2} divider={<Divider orientation="horizontal" />}>
        <Box
          className={classes.logo}
          component="img"
          src={logo}
        />
        <LoginGlobalForm />
      </Stack>
    </StyledBox>
  );
}

export default Login;
