import React, { useState } from 'react';

import { Box, Typography, MenuItem } from '@mui/material';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import { updateSerpAnalyses } from 'features/serpAnalysis/state/slice';
import { SerpAnalysisUpdatePayload } from 'features/serpAnalysis/types';

import StopScheduleAnalysesButton from '../buttons/StopScheduleAnalysesButton';

type StopPlanificationProps = {
  ids: number[],
  handleCloseMenu?: Function,
  isButton: boolean,
  disabled?: boolean,
}

function StopPlanification (props: StopPlanificationProps) {
  const {
    ids,
    handleCloseMenu,
    isButton,
    disabled,
  } = props;
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleConfirm = () => {
    if (ids.length === 0) return;
    const payload: SerpAnalysisUpdatePayload[] = ids.map((id) => (
      {
        id,
        serp_analysis_planning_id: 1,
        planning_date: null,
      }
    ));
    dispatch(updateSerpAnalyses(payload));
    setOpenModal(false);
    setOpenModal(false);
  };

  return (
    <Box data-testid="stop-serp-planification-action">
      {
        isButton
          ? (
            <StopScheduleAnalysesButton
              handleOpenModal={() => { if (!openModal) setOpenModal(true); }}
              disabled={disabled}
            />
          )
          : (
            <MenuItem
              sx={{ fontSize: globalTheme.fontSize.middlesmall }}
              onClick={
                () => { if (!openModal) setOpenModal(true); handleCloseMenu?.(); }
              }
              disabled={disabled}
            >
              Stopper la planification
            </MenuItem>
          )
      }

      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">Annuler la planification</Typography>
            {' '}
            des analyses de mots clés
          </Typography>
        )}
        actionConfirm={handleConfirm}
        actionCancel={() => setOpenModal(false)}
        isOpen={openModal}
        dataTestId="stop-planification-modal"
      />
    </Box>
  );
}

export default StopPlanification;
