import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import ApiAnalysisPlanning from './api';
import {
  fetchSerpAnalysisPlanning,
  fetchSerpAnalysisPlanningSuccess,
  fetchSerpAnalysisPlanningFailure,
} from './slice';
import SerpAnalysisPlanning from '../types';

function* workFetchSerpAnalysisPlanning() {
  try {
    const response: AxiosResponse = yield call(ApiAnalysisPlanning.fetchAnalysisPlanning);
    const projects: SerpAnalysisPlanning[] = response.data;
    yield put(fetchSerpAnalysisPlanningSuccess(projects));
  } catch (e: any) {
    yield put(fetchSerpAnalysisPlanningFailure(e.message));
  }
}

export default function* sagaFetchSerpAnalysisPlanning() {
  yield takeEvery(fetchSerpAnalysisPlanning, workFetchSerpAnalysisPlanning);
}
