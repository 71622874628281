import React from 'react';

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
} from '@mui/material';
import { GridCellParams, GridTreeNode } from '@mui/x-data-grid';
import moment from 'moment';

import { useAppSelector } from 'common/reduxHooks';
import InputSearch from 'components/input/inputSearch';
import smallSpinnerSize from 'components/loading/config';
import useCurrentUser from 'features/authentication/state/useCurrentUser';
import AnalysisDetailsDataGrid
  from 'features/dataviz/components/generic/datagrid/AnalysisDetailsDataGrid';
import AddViewModal from 'features/kpiView/components/AddView';
import { KPI_VIEW_TYPE } from 'features/kpiView/CONSTANTS';
import { RANK_SLICES } from 'features/rankSlice/CONSTANT';
import { DATE_ENGLISH, convertDate } from 'utils/formatDate';
import useInputSearch from 'utils/useInputSearch';

import { columnsDetailedRankTracking, generationColsPeriode } from './columns';
import { selectDatavizDataWithTmpId } from '../../state/slice';
import { ChartComponentProps } from '../../types';

const cellStyle = Object.keys(RANK_SLICES).reduce((previousValue, key: string) => {
  previousValue[`& .${RANK_SLICES[parseInt(key, 10)].className}`] = {
    backgroundColor: RANK_SLICES[parseInt(key, 10)].backgroundColor,
    color: RANK_SLICES[parseInt(key, 10)].color,
  };
  return previousValue;
}, { } as Record<string, any>);

function DetailedRankTracking(props: ChartComponentProps): JSX.Element {
  const {
    handleExport,
    isLoading,
    title,
    chartKey,
    isExportLoading,
    datavizFilters,
    analysis,
    project,
    searchEngineId,
  } = props;

  const currentUser = useCurrentUser();

  const basedColumn = columnsDetailedRankTracking(searchEngineId).map((column) => column.field);

  const keywords = useAppSelector((state) => (
    selectDatavizDataWithTmpId(state.dataviz, chartKey)));

  const selectRows = (inputSearch: string) => keywords?.filter(
    (fSnippet: any) => fSnippet.keyword_label.toLowerCase().includes(
      inputSearch.trim().toLocaleLowerCase(),
    ),
  );

  const { handleInputChange, filterRows } = useInputSearch(keywords, selectRows);

  const sortedRows = [...filterRows()].sort((a, b) => (
    (b.volume - a.volume) === 0 ? (a.rank - b.rank) : (b.volume - a.volume)
  ));

  const periodeBegin = (
    datavizFilters && datavizFilters.type === 'rankingEvolutionFilters'
      ? datavizFilters.periodeBegin
      : convertDate(moment().toDate(), DATE_ENGLISH)
  );

  const periodeEnd = (
    datavizFilters && datavizFilters.type === 'rankingEvolutionFilters'
      ? datavizFilters.periodeEnd
      : convertDate(moment().toDate(), DATE_ENGLISH)
  );

  const timeSeries = (
    datavizFilters && datavizFilters.type === 'rankingEvolutionFilters'
    && datavizFilters.timeSeries === 'month'
      ? 'months'
      : 'days'
  );

  const getCellColor = (params: GridCellParams<any, any, any, GridTreeNode>) => {
    const value = parseInt(params.value, 10);
    const isDefaultField = basedColumn.includes(params.field);

    if (!Number.isInteger(value) || isDefaultField) {
      return 'cell-color-default';
    }
    return RANK_SLICES
      .filter((range_with_color) => value >= range_with_color.range[0]
        && value <= range_with_color.range[1])
      ?.[0]?.className ?? 'cell-color-default';
  };

  return (
    <Box sx={cellStyle}>
      <AnalysisDetailsDataGrid
        handleInputChange={handleInputChange}
        rows={sortedRows}
        title={title}
        handleExport={handleExport}
        columnData={[
          ...columnsDetailedRankTracking(searchEngineId),
          ...generationColsPeriode(
            periodeBegin,
            periodeEnd,
            timeSeries,
          ),
        ]}
        isLoading={isLoading}
        isExportLoading={isExportLoading}
        showToolbar={false}
        getCellClassName={(params) => getCellColor(params)}
      >
        <Stack
          direction="row"
          spacing={2}
          marginTop={2}
          marginBottom={2}
        >
          <Button
            onClick={
              !isExportLoading
                ? handleExport
                : undefined
            }
            variant="contained"
            color="primary"
            size="small"
            startIcon={
              !isExportLoading
                ? <SaveAltIcon />
                : undefined
            }
          >
            {
              !isExportLoading
                ? (
                  'Télécharger XLS'
                )
                : (
                  <CircularProgress color="inherit" size={smallSpinnerSize} />
                )
            }
          </Button>
          <AddViewModal
            analysisId={analysis ? analysis.id : 0}
            scopeId={analysis ? analysis.scope_id : 0}
            rankingEvolutionFilters={datavizFilters}
            kpiViewType={KPI_VIEW_TYPE.tracking.id}
            isCurrentUserExternal={currentUser?.isExternal()}
            isCurrentUserConsultant={currentUser?.isConsultant(project?.id ?? 0)}
          />
          {
            !currentUser?.isExternal() && (
              <AddViewModal
                analysisId={analysis ? analysis.id : 0}
                scopeId={analysis ? analysis.scope_id : 0}
                rankingEvolutionFilters={datavizFilters}
                kpiViewType={KPI_VIEW_TYPE.serpTracking.id}
                isCurrentUserExternal={currentUser?.isExternal()}
                isCurrentUserConsultant={currentUser?.isConsultant(project?.id ?? 0)}
              />
            )
          }
          <InputSearch
            handleInputChange={handleInputChange}
            label="Chercher un mot clé"
          />
        </Stack>
      </AnalysisDetailsDataGrid>
    </Box>
  );
}

export default DetailedRankTracking;
