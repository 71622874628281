import React from 'react';

import { Box } from '@mui/material';

import { useAppSelector } from 'common/reduxHooks';
import AnalysisDetailsDataGrid
  from 'features/dataviz/components/generic/datagrid/AnalysisDetailsDataGrid';
import useInputSearch from 'utils/useInputSearch';

import { columnsDatasByRankingType, fields } from './columns';
import { RANKING_TYPE } from '../../CONSTANTS';
import { selectDatavizDataWithTmpId } from '../../state/slice';
import { ChartComponentProps } from '../../types';

function DatasByRankingType(props: ChartComponentProps): JSX.Element {
  const {
    rankingTypeIds,
    handleExport,
    isLoading,
    title,
    chartKey,
    isExportLoading,
    searchEngineId,
  } = props;

  const columnsNotVisible = {
    default: [fields.expanded_title, fields.source, fields.content_date, fields.image_url],
    [RANKING_TYPE.FEATURED_SNIPPET]: [
      fields.volume,
      fields.intention,
      fields.expanded_title,
      fields.source,
      fields.content_date,
      fields.image_url,
    ],
    [RANKING_TYPE.PEOPLE_ALSO_ASK]: [
      fields.volume, fields.intention, fields.source, fields.content_date, fields.image_url,
    ],
    [RANKING_TYPE.VIDEO]: [
      fields.volume,
      fields.intention,
      fields.expanded_title,
      fields.has_image,
      fields.has_table,
      fields.image_url,
    ],
    [RANKING_TYPE.IMAGES]: [
      fields.volume,
      fields.intention,
      fields.title,
      fields.expanded_title,
      fields.description,
      fields.source,
      fields.content_date,
      fields.has_image,
      fields.has_table,
    ],
  };

  const columnsSelected = columnsNotVisible[rankingTypeIds[0]]
    ? columnsNotVisible[rankingTypeIds[0]]
    : columnsNotVisible.default;

  const columnsToHide = columnsSelected.reduce(
    (obj, item) => ({
      ...obj,
      [item]: false,
    }),
    {},
  );

  const keywords = useAppSelector((state) => (
    selectDatavizDataWithTmpId(state.dataviz, chartKey)));

  const selectRows = (inputSearch: string) => keywords?.filter(
    (fSnippet: any) => fSnippet.keyword.toLowerCase().includes(
      inputSearch.trim().toLocaleLowerCase(),
    ),
  );

  const { handleInputChange, filterRows } = useInputSearch(keywords, selectRows);

  const sortedRows = [...filterRows()].sort((a, b) => (
    (b.volume - a.volume) === 0 ? (a.rank - b.rank) : (b.volume - a.volume)
  ));

  return (
    <Box>
      <AnalysisDetailsDataGrid
        handleInputChange={handleInputChange}
        rows={sortedRows}
        title={title}
        handleExport={handleExport}
        columnData={columnsDatasByRankingType(searchEngineId)}
        isLoading={isLoading}
        isExportLoading={isExportLoading}
        columnsToHide={columnsToHide}
      />
    </Box>
  );
}

export default DatasByRankingType;
