export const PROJECT_STATUS_ID = 1;
export const PROJECT_INDEX_ID = 13;
export const PROJECT_DEVICE_TYPE_ID = 1;
export const PROJECT_TYPE_LIST = [
  { id: 1, label: 'Avant-vente' },
  { id: 2, label: 'Prestation' },
  { id: 5, label: 'Interne' },
];

export const RECALCUL_KPI_STATUS = {
  TO_RECALCUL: 1,
  RECALCULATING: 2,
  DONE: 3,
};
