import { Category } from './types';

const CATEGORY_TYPE_ID = {
  keywords: 1,
  url: 2,
  brand: 3,
};

export const CATEGORY_VALUE_TYPE_EXACT = 1;
export const CATEGORY_VALUE_TYPE_CONTAINS = 2;
export const CATEGORY_VALUE_TYPE_REGEX = 3;

export const CATEGORY_VALUE_TYPE = {
  [CATEGORY_VALUE_TYPE_EXACT]: 'Exact',
  [CATEGORY_VALUE_TYPE_CONTAINS]: 'Contient',
  [CATEGORY_VALUE_TYPE_REGEX]: 'Regex',
};

export const CATEGORY_VALUE_INCLUDE = 1;
export const CATEGORY_VALUE_EXCLUDE = 0;

export const UNCATEGORIZED_CATEGORY:Category = {
  id: 0,
  name: 'Non catégorisée',
  category_group_id: 0,
  insertion_date: '',
  category_values: [],
  active: true,
};

export default CATEGORY_TYPE_ID;
