import React from 'react';

import Grid from '@mui/material/Grid2';

import MainBox from 'components/layout/MainBox';
import { DatavizFilters } from 'features/dataviz/types';
import { Project } from 'features/projects/types';
import { Analysis } from 'features/serpAnalysis/types';
import SerpAnalysisScrapping from 'features/serpAnalysisScrapping/types';

import DSBlock from './dataSourceBlock/DSBlock';
import ParamsBlock from './parametersBlock/ParamsBlock';
import ResultPhysionomy from './ResultPhysionomy';

type BlocksContainerProps = {
  project: Project,
  scopeId: number,
  isRecalculatingKPIs: boolean,
  analysis: Analysis,
  isLoading: boolean,
  scrappingsSorted: SerpAnalysisScrapping[],
  scrappingSelected: SerpAnalysisScrapping | undefined,
  baseUrl: string,
  datavizFilters: DatavizFilters,
}

function BlocksContainer(props: BlocksContainerProps): JSX.Element {
  const {
    project,
    scopeId,
    isRecalculatingKPIs,
    analysis,
    isLoading,
    scrappingsSorted,
    scrappingSelected,
    baseUrl,
    datavizFilters,
  } = props;

  const blockHeight = 200;

  return (
    <Grid
      container
      columnSpacing={2}
      data-testid="serp-analysis-block-container"
    >
      <Grid size={4}>
        <MainBox>
          <DSBlock
            scrappingsSorted={scrappingsSorted}
            scrappingSelected={scrappingSelected}
            baseUrl={baseUrl}
            analysis={analysis}
            project={project}
            scopeId={scopeId}
            isRecalculatingKPIs={isRecalculatingKPIs}
            isLoading={isLoading}
            blockHeight={blockHeight}
          />
        </MainBox>
      </Grid>
      <Grid size={4}>
        <MainBox>
          <ResultPhysionomy
            scopeId={scopeId}
            analysis={analysis}
            project={project}
            periode={scrappingSelected?.scrapping_date || ''}
            isPageLoading={isLoading}
            blockHeight={blockHeight}
            datavizFilters={datavizFilters}
          />
        </MainBox>
      </Grid>
      <Grid size={4}>
        <MainBox>
          <ParamsBlock
            blockHeight={blockHeight}
            analysis={analysis}
          />
        </MainBox>
      </Grid>
    </Grid>
  );
}

export default BlocksContainer;
