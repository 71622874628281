import { useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import { RootState } from 'common/store';

import { fetchSerpAnalyses, selectSerpAnalysesByScopeId } from './slice';

type SerpAnalysesHookProps = {
  scopeId: number,
  /**
  * Used as a quick fix to prevent an infinite loop if the previous fetch data is empty
  * @todo: Remove doFetch when the store values will be updated
  */
  doFetch?: boolean,
}

const useSerpAnalyses = (props: SerpAnalysesHookProps) => {
  const { scopeId, doFetch = true } = props;
  const dispatch = useAppDispatch();
  const [currentLoading, setCurrentLoading] = useState(true);

  const { isLoading: isLoadingState, error } = useAppSelector(
    (state: RootState) => state.serpAnalyses,
  );

  const serpAnalyses = useAppSelector(
    (state) => selectSerpAnalysesByScopeId(state.serpAnalyses, scopeId),
  );

  useEffect(() => {
    if (scopeId !== 0 && !isLoadingState && serpAnalyses.length === 0 && doFetch) {
      dispatch(fetchSerpAnalyses({ scopeId }));
      setCurrentLoading(true);
    }
  }, [dispatch, fetchSerpAnalyses, scopeId]);

  useEffect(() => {
    if (!isLoadingState && currentLoading) {
      setCurrentLoading(false);
    }
  }, [isLoadingState]);

  const hasSerpAnalyses = serpAnalyses.length > 0;

  const isLoading = isLoadingState || currentLoading;

  return {
    isLoading,
    error,
    serpAnalyses,
    hasSerpAnalyses,
  };
};

export default useSerpAnalyses;
