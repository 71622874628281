import React, { useState } from 'react';

import { MenuItem, TextField, Typography } from '@mui/material';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import { updateSerpAnalyses } from 'features/serpAnalysis/state/slice';

type RenameAnalysisProps = {
  id: number,
  scopeId: number,
  label: string,
  handleCloseMenu: Function,
  disabled?: boolean,
};

function RenameAnalysis(props: RenameAnalysisProps) {
  const {
    id,
    scopeId,
    label,
    handleCloseMenu,
    disabled,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [newLabel, setNewLabel] = useState(label);
  const [isError, setIsError] = useState(false);

  const dispatch = useAppDispatch();

  const handleConfirm = () => {
    if (newLabel.trim()) {
      setIsError(false);
      dispatch(updateSerpAnalyses([
        {
          id,
          scope_id: scopeId,
          label: newLabel.trim(),
        },
      ]));
      setIsOpen(false);
      handleCloseMenu();
    } else {
      setIsError(true);
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    setIsError(false);
    setNewLabel(label);
  };

  return (
    <>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => { if (!isOpen) setIsOpen(true); handleCloseMenu(); }
        }
        disabled={disabled}
      >
        Renommer le groupe
      </MenuItem>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">Renommer</Typography>
            {' '}
            cette catégorie de mots clés
          </Typography>
        )}
        actionConfirm={handleConfirm}
        actionCancel={handleCancel}
        isOpen={isOpen}
        dataTestId="rename-analysis-modal"
      >
        <TextField
          value={newLabel}
          onChange={(e) => setNewLabel(e.target.value)}
          error={isError}
          helperText={isError && 'Ce champ est obligatoire'}
          size="small"
          margin="normal"
          slotProps={{
            input: {
              sx: { fontSize: globalTheme.fontSize.medium },
            },
          }}
          fullWidth
          data-testid="rename-serp-analysis-modal-field"
        />
      </Modal>
    </>
  );
}

export default RenameAnalysis;
