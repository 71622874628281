import React, { useEffect } from 'react';

import useActors from 'features/actors/state/useActors';
import { Analysis } from 'features/serpAnalysis/types';

import KeywordButtonActionsView from './KeywordButtonActionsView';

type KeywordButtonActionsProps = {
  idsKwSelected: number[],
  scopeId: number | null,
  handleInputChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  analysis: Analysis | undefined,
  handleExport?: Function,
  disabled?: boolean,
}

function KeywordButtonActions(props: KeywordButtonActionsProps): JSX.Element {
  const {
    idsKwSelected,
    scopeId,
    handleInputChange,
    analysis,
    handleExport,
    disabled,
  } = props;

  useActors({ scopeId: scopeId || 0 });
  const [keywordsSelected, setKeywordsSelected] = React.useState<number>(0);

  useEffect(() => {
    setKeywordsSelected(idsKwSelected.length);
  }, [idsKwSelected]);

  return (
    <KeywordButtonActionsView
      handleExport={handleExport}
      keywordsSelected={keywordsSelected}
      handleInputChange={handleInputChange}
      analysis={analysis}
      idsKwSelected={idsKwSelected}
      disabled={disabled}
    />
  );
}

export default KeywordButtonActions;
