import React from 'react';

import { Typography } from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';

export default function Team(): JSX.Element {
  useDocumentTitle('benhur | gestion de l\'équipe');
  return (
    <Typography component="h1">Team Page</Typography>
  );
}
