import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  Button,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import {
  uploadDataFiles,
  resetError,
  resetIsSuccess,
} from 'features/dataFiles/state/slice';

import AddSerpAnalysisDataFileForm from './AddSerpAnalysisDataFileForm';
import { initFormProjectUpload, wrongHeaders } from './config';
import ModalErrorMessage from './ModalErrorMessage';
import ModalLoadingMessage from './ModalLoadingMessage';
import { AddSerpAnalysisDataFileFormData } from './types';
import { DATA_FILE_STATUS, DATA_SOURCE } from '../../CONSTANT';

export type AddSerpAnalysisDataFileProps = {
  scopeId: number,
  isImportingKeywords: boolean,
  onClick?: Function,
  isButton?: boolean,
  buttonText?: string,
  disabled?: boolean,
}

function AddSerpAnalysisDataFile(props: AddSerpAnalysisDataFileProps): JSX.Element {
  const {
    scopeId,
    isImportingKeywords,
    onClick,
    disabled,
    isButton = false,
    buttonText = 'Importer un fichier CSV',
  } = props;

  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(false);

  const { isLoading, error, isSuccess } = useAppSelector(
    (state) => state.dataFiles,
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetIsSuccess());
    }
  }, [isSuccess]);

  const [
    formProjectUpload,
    setFormProjectUpload,
  ] = useState<AddSerpAnalysisDataFileFormData>(initFormProjectUpload);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    if (name === 'file') {
      let file;
      if (e.currentTarget !== null && e.currentTarget.files !== null) {
        ({ 0: file } = e.currentTarget.files);
      }
      setFormProjectUpload({
        ...formProjectUpload,
        file: {
          error: false,
          error_label: '',
          value,
          file,
        },
      });
    } else if (name === 'data_file_mode_id') {
      setFormProjectUpload({
        ...formProjectUpload,
        data_file_mode_id: parseInt(value, 10),
      });
    }
  };
  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormProjectUpload({
      ...formProjectUpload,
      [name]: parseInt(value, 10),
    });
  };
  const handleSubmit = () => {
    if (formProjectUpload.file.file === undefined) {
      setFormProjectUpload({
        ...formProjectUpload,
        file: {
          ...formProjectUpload.file,
          error: true,
          error_label: 'Veuillez télécharger un fichier csv',
        },
      });
      return;
    }
    const fileExtension = formProjectUpload.file.value.slice(-3);
    const isExtensionAllowed = fileExtension === 'csv';

    if (!isExtensionAllowed) {
      setFormProjectUpload({
        ...formProjectUpload,
        file: {
          ...formProjectUpload.file,
          error: true,
          error_label: 'le fichier doit être un fichier de type csv',
        },
      });
      return;
    }
    const data = new FormData();
    data.append('files', formProjectUpload.file.file as File);
    const payload = {
      query: {
        data_source_id: DATA_SOURCE.serp_analysis_v3.id,
        data_file_status_id: DATA_FILE_STATUS.to_import,
        scope_id: scopeId,
        data_file_mode_id: formProjectUpload.data_file_mode_id,
        serp_analysis_index_id: formProjectUpload.indexId,
        device_type_id: formProjectUpload.deviceType,
        search_engine_id: formProjectUpload.searchEngineId,
      },
      payload: data,
    };
    dispatch(uploadDataFiles(payload));
  };

  let modalToDisplay;

  const resetModals = () => {
    setOpen(false);
    setFormProjectUpload(initFormProjectUpload);
  };
  switch (`${isLoading}-${error.length > 0}-${isSuccess}-${open}`) {
    case 'false-true-false-true':
      // Error Modal
      modalToDisplay = {
        title: (
          <Typography>
            <Typography component="span" variant="bold">
              {(wrongHeaders === error
                && 'Fichier CSV invalide'
              ) || (
                'Une erreur est survenue pendant le transfert de l\'étude de mots clés.'
              )}
            </Typography>
          </Typography>
        ),
        component: <ModalErrorMessage error={error} />,
        handleConfirm: () => {
          resetModals();
          dispatch(resetError());
        },
        displayCancel: false,
        displayConfirm: true,
      };
      break;
    case 'true-false-false-true':
      // Loading modal
      modalToDisplay = {
        title: (
          <Typography>
            <Typography component="span" variant="bold">
              Import de l&apos;étude de mots clés
            </Typography>
            {' '}
            en cours …
          </Typography>
        ),
        component: <ModalLoadingMessage />,
        displayCancel: false,
        displayConfirm: false,
      };
      break;
    case 'false-false-true-true':
      // Reset values when success
      resetModals();
      break;
    case 'false-false-false-true':
      // Import Modal
      modalToDisplay = {
        title: (
          <Typography>
            <Typography component="span" variant="bold">
              Importer
            </Typography>
            {' '}
            des mots clés avec un fichier CSV
          </Typography>
        ),
        component: (
          <AddSerpAnalysisDataFileForm
            handleChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            formProjectUpload={formProjectUpload}
          />
        ),
        handleConfirm: handleSubmit,
        handleClose: () => {
          resetModals();
        },
        displayCancel: true,
        displayConfirm: true,
      };
      break;
    default:
      break;
  }

  const handleOnClick = () => {
    if (!isImportingKeywords) {
      onClick?.();
      setOpen(true);
    }
  };

  return (
    <Box>
      {
        isButton
          ? (
            <Button
              variant="contained"
              size="small"
              onClick={handleOnClick}
              disabled={isImportingKeywords || disabled}
              data-testid="button-import-serp-csv"
            >
              {buttonText}
            </Button>
          )
          : (
            <MenuItem
              sx={{ fontSize: globalTheme.fontSize.middlesmall }}
              onClick={handleOnClick}
              disabled={isImportingKeywords || disabled}
              data-testid="menu-item-import-serp-csv"
            >
              Import CSV
            </MenuItem>
          )
      }
      {modalToDisplay && (
        <Modal
          title={modalToDisplay?.title}
          isOpen={open}
          actionConfirm={modalToDisplay?.handleConfirm}
          actionCancel={modalToDisplay?.handleClose}
          displayCancel={modalToDisplay?.displayCancel}
          displayConfirm={modalToDisplay?.displayConfirm}
        >
          {modalToDisplay.component}
        </Modal>
      )}
    </Box>
  );
}

export default AddSerpAnalysisDataFile;
