import React from 'react';

import { Box, Typography } from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import AlertNoScreamingFrog from 'features/dataFiles/components/AlertNoScreamingFrog';
import { DATA_SOURCE } from 'features/dataFiles/CONSTANT';
import { KPI_VIEW_TYPE } from 'features/kpiView/CONSTANTS';
import { createColumnsIndexability } from 'features/kpiView/list/columns';
import ViewDataGrid from 'features/kpiView/list/ViewDataGrid';
import useKpiViewByType from 'features/kpiView/state/useKpiViewByType';
import { Project } from 'features/projects/types';

type IndexabilityProps = {
  project?: Project,
};

function UrlIndexability ({ project }: IndexabilityProps) {
  const scopeId = project?.scopes[0].id ?? 0;

  useDocumentTitle(`${project?.name} : suivi des URL indexables | benhur`);

  const { kpiViews, isLoading } = useKpiViewByType(scopeId, [KPI_VIEW_TYPE.indexability.id]);

  return (
    <Box>
      <Typography variant="h1">
        Suivez le nombre d&apos;URL indexables avec Screaming Frog
      </Typography>
      <AlertNoScreamingFrog
        project={project}
        scopeId={scopeId}
        dataSourceId={DATA_SOURCE.screaming_frog.id}
      />
      <ViewDataGrid
        kpiViews={kpiViews}
        columns={createColumnsIndexability(project?.id ?? 0, scopeId)}
        isLoading={isLoading}
        noRowsLabel="Aucun export trouvé"
      />
    </Box>
  );
}

export default UrlIndexability;
