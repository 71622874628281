import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  styled,
  ButtonProps,
  CircularProgress,
} from '@mui/material';

import smallSpinnerSize from 'components/loading/config';

const PREFIX = 'RefreshButton';
const classes = {
  spinner: `${PREFIX}-spinner`,
};

type ButtonBaseProps = {
  text: string,
  size: 'small' | 'medium' | 'large',
};

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: 'black',
  backgroundColor: '#ffc107',

  [`& .${classes.spinner}`]: {
    color: 'black',
  },

  [`&:hover .${classes.spinner}`]: {
    color: theme.palette.primary.light,
  },
}));

export default function RefreshButton(props: ButtonBaseProps): JSX.Element {
  const { text, size } = props;
  const navigate = useNavigate();

  const refreshPage = () => {
    navigate(0);
  };

  return (
    <ColorButton
      variant="contained"
      size={size}
      startIcon={
        <CircularProgress size={smallSpinnerSize} className={classes.spinner} />
      }
      onClick={refreshPage}
    >
      {text}
    </ColorButton>
  );
}
