import React from 'react';

import { CircularProgress, Box, styled } from '@mui/material';

const PREFIX = 'Spinner';

const classes = {
  loadingContainer: `${PREFIX}-loadingContainer`,
};

const StyledBox = styled(Box)(() => ({
  [`&.${classes.loadingContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

function Spinner(): JSX.Element {
  return (
    <StyledBox className={classes.loadingContainer}>
      <CircularProgress size={40} />
    </StyledBox>
  );
}

export default Spinner;
