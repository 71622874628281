import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import {
  ListItem, ListItemButton, Tooltip, Box, styled, Stack,
} from '@mui/material';

import { buttonLength } from '../config';
import { MenuItem } from '../types';

const PREFIX = 'NavLinkMenu';

const classes = {
  activeLink: `${PREFIX}-activeLink`,
  link: `${PREFIX}-link`,
  button: `${PREFIX}-button`,
};

const StyledLink = styled(Box)(({ theme }) => ({
  [`& .${classes.activeLink}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.light,
    borderRadius: '5px',
    height: buttonLength,
    width: buttonLength,
  },

  [`& .${classes.link}`]: {
    color: 'white',
    borderRadius: '5px',
    height: buttonLength,
    width: buttonLength,
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.secondary.main,
    },
  },

  [`& .${classes.button}`]: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 16px',
  },
}));

type MenuLinkProps = {
  menuItem: MenuItem,
}

function LinkMenu(props: MenuLinkProps): JSX.Element {
  const { menuItem } = props;

  const currentPage = useLocation().pathname.split('/')[1];

  return (
    <StyledLink>
      <ListItem disablePadding>
        <ListItemButton
          className={classes.button}
        >
          <Tooltip
            title={menuItem.name}
            placement="right"
            arrow
            slotProps={{
              tooltip: {
                sx: {
                  bgcolor: 'white',
                  color: 'gray',
                  textTransform: 'uppercase',
                  p: 1,
                  opacity: '0.8',
                  fontWeight: 400,
                  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                  '& .MuiTooltip-arrow': {
                    color: 'white',
                    opacity: '0.8',
                  },
                },
              },
            }}
          >
            <NavLink to={menuItem.link}>
              {({ isActive }) => (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  className={
                    isActive
                    || (
                      currentPage.includes('admin') && menuItem.link.includes('admin')
                    ) ? classes.activeLink : classes.link
                  }
                >
                  {menuItem.icon}
                </Stack>
              )}
            </NavLink>
          </Tooltip>
        </ListItemButton>
      </ListItem>
    </StyledLink>
  );
}

export default LinkMenu;
