import * as yup from 'yup';

import { METRICS } from '../CONSTANT';

const validationSchema = yup.object().shape({
  period: yup.string().required(),
  metric: yup.string().required().test(
    'Metric must be a valid metric',
    'La métrique sélectionnée n\'est pas valide',
    (value: any) => METRICS.includes(value),
  ),
  rankSlices: yup.array().required(),
  actorIds: yup.array().required(),
  categoryGroupId: yup.number().required(),
  categoryIds: yup.array().required(),
  serpAnalysisIds: yup.array().required(),
  rankingTypeIds: yup.array().required(),
  bestRankMode: yup.bool().required(),
  aggregateMode: yup.bool().required(),
  includeUncategorized: yup.bool().required(),
});

export default validationSchema;
