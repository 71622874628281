import React, { useState } from 'react';

import { MenuItem, Typography } from '@mui/material';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import DeleteAnalysesButton from 'features/serpAnalysis/components/buttons/DeleteAnalysesButton';

import { deleteCategoryGroups } from '../state/slice';

interface DeleteCatGroupsProps {
  ids: number[],
  scopeId: number,
  handleCloseMenu?: Function,
  isButton: boolean,
}

function DeleteCatGroups(props: DeleteCatGroupsProps) {
  const {
    ids,
    scopeId,
    handleCloseMenu,
    isButton,
  } = props;
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleModalSubmit = () => {
    if (scopeId === 0) return;
    dispatch(deleteCategoryGroups(ids));
    setIsOpen(false);
  };

  return (
    <>
      {
        isButton
          ? (
            <DeleteAnalysesButton
              handleOpenModal={() => { if (!isOpen) setIsOpen(true); }}
            />
          )
          : (
            <MenuItem
              sx={{ fontSize: globalTheme.fontSize.middlesmall }}
              onClick={
                () => { if (!isOpen) setIsOpen(true); if (handleCloseMenu) handleCloseMenu(); }
              }
              data-testid="delete-category-group-menu-item"
            >
              Supprimer
            </MenuItem>
          )
      }
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">
              Supprimer le
              {ids.length > 1 ? 's ' : ' '}
              groupe
              {ids.length > 1 ? 's ' : ' '}
              d&apos;URL
            </Typography>
            {' '}
            et les données associées
          </Typography>
        )}
        actionConfirm={handleModalSubmit}
        actionCancel={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        dataTestId="delete-category-group-delete-modal"
      />
    </>
  );
}

export default DeleteCatGroups;
