import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  Stack,
  styled,
  StackProps,
  Button,
  SvgIconTypeMap,
  Typography,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import MainBox from 'components/layout/MainBox';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';

const PREFIX = 'SettingBlockView';

const classes = {
  icon: `${PREFIX}-icon`,
  required: `${PREFIX}-required-text`,
  optional: `${PREFIX}-optional-text`,
  description: `${PREFIX}-description`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`& .${classes.icon}`]: {
    fontSize: '40px',
    color: theme.palette.primary.light,
  },

  [`& .${classes.required}`]: {
    color: theme.palette.error.main,
    fontSize: theme.fontSize.xsmall,
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${classes.optional}`]: {
    color: theme.palette.warning.light,
    fontSize: theme.fontSize.medium,
  },

  [`& .${classes.description}`]: {
    textAlign: 'center',
  },
}));

type SettingBlockProps = {
  title: string,
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string; },
  description: string | JSX.Element,
  buttonTitle: string,
  redirectUrl: string,
  required?: boolean,
  blockHeight: number,
}

function SettingBlock(props: SettingBlockProps) {
  const {
    title,
    Icon,
    description,
    buttonTitle,
    redirectUrl,
    blockHeight,
    required,
  } = props;

  return (
    <MainBox>
      <StyledStack
        alignItems="center"
        direction="column"
        justifyContent="space-between"
        spacing={2}
        height={blockHeight}
        data-testid="setting-block-main-div"
      >
        <TitleComponent
          title={title}
          capitalLetter
        />
        <Stack
          display="flex"
          direction="column"
          alignItems="center"
          spacing={1}
        >
          <Icon className={classes.icon} />
          <Stack
            className={classes.description}
          >
            {description}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent={required ? 'space-between' : 'flex-end'}
          alignItems="center"
          spacing={2}
          width={1}
        >
          {required && (
            <Typography className={classes.required}>
              OBLIGATOIRE
            </Typography>
          )}
          <NavLink to={redirectUrl}>
            <Button
              variant="contained"
              size="small"
            >
              {buttonTitle}
            </Button>
          </NavLink>
        </Stack>
      </StyledStack>
    </MainBox>
  );
}

export default SettingBlock;
