import React from 'react';

import {
  FormControl,
  Stack,
  StackProps,
  TextField,
  Typography,
  styled,
} from '@mui/material';

import globalTheme from 'common/theme';
import SelectDeviceTypeView from 'features/deviceType/SelectView';
import { DEFAULT_PRICE_PER_KEYWORD } from 'features/serpAnalysis/CONSTANT';
import PlanningsDropDown from 'features/serpAnalysisPlanning/select/PlanningsDropDown';
import PLANNING_TYPE from 'features/serpAnalysisPlanning/state/CONSTANTS';

import NbMonthsDropDown from './NbMonthsDropdown';
import { Simulator } from './types';

const PREFIX = 'SimulatorForm';

const classes = {
  core: `${PREFIX}-core`,
  label: `${PREFIX}-label`,
  textField: `${PREFIX}-textField`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    width: '90%',
    fontSize: theme.fontSize.middlesmall,
  },
  [`& .${classes.label}`]: {
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.textField}`]: {
    fontSize: theme.fontSize.medium,
  },
}));

type SimulatorFormProps = {
  simulator: Simulator,
}

function SimulatorForm(props: SimulatorFormProps) {
  const {
    simulator,
  } = props;

  const checkNumFormat = (
    inputValue: string,
    withPrecision: boolean,
    decimalAmount: number = 0,
  ) => {
    let regexp = /^\d*$/;
    if (withPrecision) {
      regexp = new RegExp(`^\\d+.?\\d{0,${decimalAmount}}$`);
    }
    return regexp.test(inputValue);
  };

  return (
    <StyledStack
      component="form"
      className={classes.core}
      spacing={2}
    >
      <Typography className={classes.label} variant="bold">
        Combien de mots clés (SERP) à analyser ?
      </Typography>
      <FormControl>
        <TextField
          size="small"
          label="Mots clés"
          name="keywordNumber"
          value={simulator.keywordNumber?.toFixed()}
          autoComplete="off"
          type="number"
          onChange={
            (e) => {
              if (e.target.value === '') {
                simulator.setKeywordNumber(0);
              } else if (checkNumFormat(e.target.value, false)) {
                simulator.setKeywordNumber(parseInt(e.target.value, 10));
              }
            }
          }
          className={classes.textField}
          helperText={
            (simulator.keywordNumber === undefined && 'Ce champ est obligatoire')
            || ((simulator.keywordNumber || 0) <= 0 && 'Valeur incorrecte')
          }
          error={
            simulator.keywordNumber === undefined
            || simulator.keywordNumber <= 0
          }
          slotProps={{
            input: {
              sx: {
                fontSize: globalTheme.fontSize.medium,
              },
            },
          }}
        />
      </FormControl>
      <Typography className={classes.label}>
        <Typography variant="bold">Coût de la SERP </Typography>
        (minimum :
        {` ${DEFAULT_PRICE_PER_KEYWORD}`}
        €)
      </Typography>
      <FormControl>
        <TextField
          size="small"
          label="Montant"
          name="ppk"
          autoComplete="off"
          value={simulator.ppk ?? ''}
          onChange={
            (e) => {
              if (e.target.value === '') {
                simulator.setPpk(undefined);
              } else if (checkNumFormat(e.target.value, true, 3)) {
                simulator.setPpk(e.target.value);
              }
            }
          }
          error={
            !simulator.ppk
            || simulator.ppk < DEFAULT_PRICE_PER_KEYWORD
          }
          helperText={
            (!simulator.ppk && 'Ce champ est obligatoire')
            || (
              simulator.ppk && simulator.ppk < DEFAULT_PRICE_PER_KEYWORD
                ? `Le coût minimum est de ${DEFAULT_PRICE_PER_KEYWORD}€`
                : undefined
            )
            || (
              <Typography variant="body3" color="error">
                Ce coût sera utilisé pour débiter le projet à chaque nouvelle analyse
              </Typography>
            )
          }
          slotProps={{
            input: {
              sx: {
                fontSize: globalTheme.fontSize.medium,
              },
            },
          }}
        />
      </FormControl>
      <Typography className={classes.label} variant="bold">
        Combien de déclinaisons facturées ?
      </Typography>
      <FormControl size="small">
        <SelectDeviceTypeView
          isInputLabel
          variant="outlined"
          label="Appareil"
          selectName="deviceType"
          deviceSelected={`${simulator.deviceType}`}
          handleDeviceChange={(e) => simulator.setDeviceType(e.target.value)}
          menuItemFontSize=".875rem"
        />
      </FormControl>
      <Typography className={classes.label} variant="bold">
        Quelle sera la fréquence des analyses ?
      </Typography>
      <FormControl>
        <PlanningsDropDown
          size="small"
          value={simulator.frequency}
          handleChange={(e: any) => simulator.setFrequency(e.target.value)}
          error={false}
          width="100%"
          name="frequency"
          label="Fréquence"
          optionsFilter={[PLANNING_TYPE.DAILY, PLANNING_TYPE.MONTHLY]}
        />
      </FormControl>
      <Typography className={classes.label} variant="bold">
        Prévoir les analyses sur combien de temps ?
      </Typography>
      <NbMonthsDropDown
        value={simulator.nbMonths}
        handleChange={(e: any) => simulator.setNbMonths(e.target.value)}
        size="small"
        width="100%"
        name="nbMonths"
        label="Abonnement"
      />
      <Typography className={classes.label} variant="bold">
        Ajuster le montant que vous voulez créditer
      </Typography>
      <FormControl>
        <TextField
          size="small"
          label="Montant facturé au client"
          name="result"
          value={simulator.result ?? ''}
          autoComplete="off"
          onChange={
            (e) => {
              if (e.target.value === '') {
                simulator.setResult(undefined);
              } else if (checkNumFormat(e.target.value, true, 2)) {
                simulator.setResult(e.target.value);
              }
            }
          }
          className={classes.textField}
          helperText={
            ((simulator.result || 0) <= 0 && 'Le montant crédité doit être supérieur à 0€')
            || (
              <Typography variant="bold" color="error">
                Ceci n&apos;est pas un paiement en ligne,
                n&apos;oubliez pas de facturer votre client !
              </Typography>
            )
          }
          error={
            !simulator.result || simulator.result <= 0
          }
          slotProps={{
            input: {
              sx: {
                fontSize: globalTheme.fontSize.medium,
              },
            },
          }}
        />
      </FormControl>
    </StyledStack>
  );
}

export default SimulatorForm;
