import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SerpAnalysisIndex } from '../types';

export interface State {
  isLoading: boolean;
  error: string;
  serpAnIndexes: SerpAnalysisIndex[],
}

const initialState: State = {
  isLoading: false,
  error: '',
  serpAnIndexes: [],
};

export const serpAnalysisIndexSlice = createSlice({
  name: 'serp_analysis_index',
  initialState,
  reducers: {
    fetchSerpAnalysisIndexRequested: (state: State) => {
      state.isLoading = true;
    },
    fetchSerpAnalysisIndexSuccess: (state: State, action: PayloadAction<SerpAnalysisIndex[]>) => {
      state.isLoading = false;
      state.error = '';
      state.serpAnIndexes = action.payload;
    },
    fetchSerpAnalysisIndexFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchSerpAnalysisIndexRequested,
  fetchSerpAnalysisIndexSuccess,
  fetchSerpAnalysisIndexFailure,
} = serpAnalysisIndexSlice.actions;

export default serpAnalysisIndexSlice.reducer;
