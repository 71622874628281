export const DATA_SOURCE = {
  semrush: { id: 1, name: 'Semrush' },
  screaming_frog: { id: 4, name: 'Screaming frog' },
  monitorank: { id: 6, name: 'Monitorank Audit' },
  monitorank_keyword: { id: 7, name: 'Monitorank - Étude Mots-clés' },
  data_for_seo_serp: { id: 8, name: 'Data For SEO' },
  serp_analysis_v3: { id: 9, name: 'Étude de mots-clefs V3' },
  data_for_seo_search_volume: { id: 10, name: 'Data For SEO Search Volume' },
};

export const DATA_FILE_STATUS = {
  initial_state: 1,
  to_import: 2,
  importing: 3,
  imported: 4,
  to_delete: 11,
  deleting: 12,
  deleted: 13,
  import_error: 101,
  import_error_no_retry: 102,
  delete_error: 111,
  delete_error_no_retry: 112,
};

export const DATA_FILE_STATUS_WITH_LABEL = [
  {
    id: DATA_FILE_STATUS.initial_state,
    label: 'Etat initial',
  },
  {
    id: DATA_FILE_STATUS.to_import,
    label: 'A importer',
  },
  {
    id: DATA_FILE_STATUS.importing,
    label: 'En cours d\'importation',
  },
  {
    id: DATA_FILE_STATUS.imported,
    label: 'Importé',
  },
  {
    id: DATA_FILE_STATUS.to_delete,
    label: 'A supprimer',
  },
  {
    id: DATA_FILE_STATUS.deleting,
    label: 'En cours de suppression',
  },
  {
    id: DATA_FILE_STATUS.deleted,
    label: 'Supprimé',
  },
  {
    id: DATA_FILE_STATUS.import_error,
    label: 'Erreur d\'import',
  },
  {
    id: DATA_FILE_STATUS.import_error_no_retry,
    label: 'Erreur d\'import définitive',
  },
  {
    id: DATA_FILE_STATUS.delete_error,
    label: 'Erreur de suppression',
  },
  {
    id: DATA_FILE_STATUS.delete_error_no_retry,
    label: 'Erreur de suppression définitive',
  },
];

export const UPLOADABLE_DATA_SOURCE = {
  monitorank_keyword: DATA_SOURCE.monitorank_keyword,
  monitorank: DATA_SOURCE.monitorank,
  screaming_frog: DATA_SOURCE.screaming_frog,
  semrush: DATA_SOURCE.semrush,
};

export const UPLOADABLE_DATA_SOURCE_ID = [
  UPLOADABLE_DATA_SOURCE.monitorank_keyword.id,
  UPLOADABLE_DATA_SOURCE.monitorank.id,
  UPLOADABLE_DATA_SOURCE.screaming_frog.id,
  UPLOADABLE_DATA_SOURCE.semrush.id,
];

export const DELETABLE_DATA_SOURCE_STATUS = [
  DATA_FILE_STATUS.initial_state,
  DATA_FILE_STATUS.imported,
  DATA_FILE_STATUS.to_delete,
  DATA_FILE_STATUS.import_error_no_retry,
];
