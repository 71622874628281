import moment from 'moment';
import * as yup from 'yup';

import { KPI_VIEW_TYPE } from 'features/kpiView/CONSTANTS';

export const initValuesAddView = (analysisId: number, kpiViewType: number) => ({
  timeSerie: kpiViewType === KPI_VIEW_TYPE.tracking.id ? 'month' : 'day',
  startDate: moment().startOf('day').format(),
  viewName: '',
  serpAnalysisIds: [analysisId],
  export: true,
});

export const validationSchemaAddView = yup.object().shape({
  timeSerie: yup.string().required('Champ requis'),
  startDate: yup.string()
    .required('La date de démarrage est obligatoire'),
  viewName: yup.string().trim().max(75, '75 caractères maximum')
    .required('Le nom de la vue est obligatoire'),
  serpAnalysisIds: yup.array().of(yup.number())
    .min(1, 'Vous devez sélectionner au moins une catégorie'),
  export: yup.bool(),
});
