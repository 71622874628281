import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  Box, styled, BoxProps, SelectChangeEvent,
} from '@mui/material';

import { DEVICE_TYPE, devicesListWithoutDevicesCombination } from 'features/deviceType/CONSTANTS';
import SelectDeviceTypeView from 'features/deviceType/SelectView';
import SelectScrapping from 'features/serpAnalysisScrapping/components/SelectScrapping';
import SerpAnalysisScrapping from 'features/serpAnalysisScrapping/types';

const PREFIX = 'SelectAnalysisBlock';

const classes = {
  core: `${PREFIX}-core`,
  title: `${PREFIX}-title`,
  form: `${PREFIX}-form`,
  select: `${PREFIX}-select`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },

  [`& .${classes.title}`]: {
    fontSize: theme.fontSize.xsmall,
    position: 'relative',
  },

  [`& .${classes.form}`]: {
    minWidth: 120,
    width: '40%',
  },

  [`& .${classes.select}`]: {
    fontSize: theme.fontSize.xsmall,
  },
}));

type SelectAnalysisProps = {
  scrappingSelected: SerpAnalysisScrapping | undefined,
  scrappingsSorted: SerpAnalysisScrapping[],
  baseUrl: string,
  analysisDevice: number,
}

function SelectAnalysis(props: SelectAnalysisProps) {
  const {
    scrappingSelected,
    baseUrl,
    scrappingsSorted,
    analysisDevice,
  } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const deviceType = searchParams.get('deviceType')
    || scrappingSelected?.device_type.id.toString()
    || DEVICE_TYPE.MOBILE.toString();

  const handleDateChange = (e: any) => {
    searchParams.set('idScrapping', `${e.target.value}`);
    navigate(`${baseUrl}?${searchParams}`);
  };
  const scrappingsFiltered = scrappingsSorted.filter(
    (scr) => scr.device_type.id === parseInt(deviceType, 10),
  );

  const handleDeviceChange = (e: SelectChangeEvent<string>) => {
    searchParams.set('deviceType', `${e.target.value}`);
    searchParams.delete('idScrapping');
    navigate(`${baseUrl}?${searchParams}`);
  };

  return (
    <StyledBox className={classes.core}>
      <SelectDeviceTypeView
        deviceSelected={
          analysisDevice && analysisDevice !== 3 ? analysisDevice.toString() : deviceType
        }
        handleDeviceChange={handleDeviceChange}
        disableSelect={analysisDevice !== DEVICE_TYPE.DESKTOP_MOBILE}
        devices={devicesListWithoutDevicesCombination}
      />
      <Box className={classes.form}>
        <SelectScrapping
          value={scrappingSelected?.id || 0}
          onChange={handleDateChange}
          name="select-scrapping"
          size="small"
          fontSize="xsmall"
          scrappings={scrappingsFiltered}
          className={{
            select: classes.select,
            title: classes.title,
          }}
          variant="standard"
          bigLabel
        />
      </Box>
    </StyledBox>
  );
}

export default SelectAnalysis;
