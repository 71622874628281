import { useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import { RootState } from 'common/store';

import {
  fetchDataFiles,
  selectDataFilesByScopeId,
  selectDataFilesByScopeIdAndActorId,
} from './slice';

type DataFilesHookProps = {
  scopeId: number,
  actorId?: number,
}

const useDataFiles = (props: DataFilesHookProps) => {
  const { scopeId, actorId } = props;
  const dispatch = useAppDispatch();
  const [currentLoading, setCurrentLoading] = useState(true);

  const { isLoading: isLoadingState, error } = useAppSelector(
    (state: RootState) => state.dataFiles,
  );

  const dataFiles = useAppSelector(
    (state) => selectDataFilesByScopeId(state.dataFiles, scopeId),
  );

  const actorDataFiles = actorId && useAppSelector(
    (state) => selectDataFilesByScopeIdAndActorId(state.dataFiles, scopeId, actorId),
  );

  useEffect(() => {
    if (scopeId !== 0 && !isLoadingState && dataFiles.length === 0) {
      dispatch(fetchDataFiles({ scopeId }));
      setCurrentLoading(true);
    }
  }, [dispatch, fetchDataFiles, scopeId]);

  useEffect(() => {
    if (!isLoadingState && currentLoading) {
      setCurrentLoading(false);
    }
  }, [isLoadingState]);

  const hasDataFiles = dataFiles.length > 0;

  const isLoading = isLoadingState || currentLoading;

  return {
    isLoading,
    error,
    dataFiles,
    hasDataFiles,
    actorDataFiles,
  };
};

export default useDataFiles;
