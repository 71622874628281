import React from 'react';

import { Box } from '@mui/material';

import MainBox from 'components/layout/MainBox';
import AlertNoActors from 'features/actors/components/AlertNoActors';
import { Project } from 'features/projects/types';

import FormDatagrid from './FormDatagrid';
import HistoDatagrid from './HistoDatagrid';
import { DATA_FILE_STATUS } from '../CONSTANT';
import useDataFiles from '../state/useDataFiles';

type ListsContainerProps = {
  scopeId: number,
  project?: Project,
};

function ListsContainer(props: ListsContainerProps) {
  const { scopeId, project } = props;
  const { dataFiles, isLoading } = useDataFiles({ scopeId });

  const dataFilesTodo = dataFiles.filter(
    (df) => df.data_file_status_id === DATA_FILE_STATUS.initial_state,
  );

  const dataFilesOther = dataFiles.filter(
    (df) => df.data_file_status_id !== DATA_FILE_STATUS.initial_state,
  );
  return (
    <Box>
      <AlertNoActors scopeId={scopeId} project={project} />
      <MainBox>
        <FormDatagrid
          scopeId={scopeId}
          dataFiles={dataFilesTodo}
          isLoading={isLoading}
        />
      </MainBox>
      <MainBox>
        <HistoDatagrid
          scopeId={scopeId}
          dataFiles={dataFilesOther}
          isLoading={isLoading}
        />
      </MainBox>
    </Box>
  );
}

export default ListsContainer;
