import React from 'react';

import {
  Stack,
  Select,
  MenuItem,
  Typography,
  styled,
  Button,
  Box,
  Grid2 as Grid,
  Input,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import globalTheme from 'common/theme';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';

import { SAFilters } from './types';

const PREFIX = 'SelectParamsBlock';

const classes = {
  label: `${PREFIX}-label`,
  form: `${PREFIX}-form`,
  blocForm: `${PREFIX}-blocForm`,
  select: `${PREFIX}-select`,
  input: `${PREFIX}-input`,
  slider: `${PREFIX}-slider`,
  cancelButton: `${PREFIX}-cancelButton`,
  filterButton: `${PREFIX}-filterButton`,
  fullWidth: `${PREFIX}-fullWidth`,
};

const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isFilter',
})<{ isFilter: boolean }>(({ theme, isFilter }) => ({
  [`& .${classes.label}`]: {
    fontSize: theme.fontSize.xsmall,
  },

  [`& .${classes.form}`]: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },

  [`& .${classes.blocForm}`]: {
    fontSize: theme.fontSize.xsmall,
    width: '45%',
  },

  [`& .${classes.select}`]: {
    fontSize: theme.fontSize.xsmall,
    width: '100%',
  },

  [`& .${classes.input}`]: {
    fontSize: theme.fontSize.xsmall,
    input: { textAlign: 'right' },
    width: '60%',
  },

  [`& .${classes.slider}`]: {
    width: '85%',
    marginLeft: '5%',
    color: theme.palette.primary.light,
  },

  [`& .${classes.cancelButton}`]: {
    color: theme.palette.primary.light,
  },

  [`& .${classes.filterButton}`]: {
    backgroundColor: isFilter ? theme.palette.secondary.main : theme.palette.primary.light,
    color: isFilter ? theme.palette.primary.light : 'white',
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.secondary.main,
    },
  },

  [`& .${classes.fullWidth}`]: {
    width: '100%',
  },
}));

interface ParamsBlockViewProps {
  blockHeight: number,
  filters: SAFilters,
  handleChange: Function,
  handleRangeChange: Function,
  handleSubmit: Function,
  maxVol: number,
  intentList: Array<{
    id: number,
    label: string,
  }>,
  siteCountList: number[],
  resetQuery: React.MouseEventHandler<HTMLElement>,
  isFilter: boolean,
}

function ParamsBlockView(props: ParamsBlockViewProps) {
  const {
    blockHeight,
    filters,
    handleChange,
    handleRangeChange,
    handleSubmit,
    maxVol,
    intentList,
    siteCountList,
    resetQuery,
    isFilter,
  } = props;
  return (
    <StyledStack
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      height={blockHeight}
      isFilter={isFilter}
    >
      <TitleComponent
        title="Paramètres"
        capitalLetter
      />
      <Box component="form" className={classes.form} onSubmit={(e) => handleSubmit(e, filters)}>
        <Stack
          direction="column"
          spacing={2}
          className={classes.fullWidth}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            className={classes.fullWidth}
          >
            <Box className={classes.blocForm}>
              <Typography id="searchIntentId-label" className={classes.label}>
                Intentions de recherche
              </Typography>
              <Select
                labelId="searchIntentId-label"
                id="searchIntentId"
                label="Intentions de recherche"
                name="searchIntentId"
                value={
                  intentList.find(
                    (intent) => intent.id === filters.searchIntentId,
                  )
                    ? filters.searchIntentId
                    : 0
                }
                inputProps={{
                  'data-testid': 'select-search-intent',
                }}
                variant="standard"
                className={classes.select}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              >
                {
                  intentList.map((intent) => (
                    <MenuItem
                      value={intent.id}
                      key={intent.id}
                      sx={{ fontSize: globalTheme.fontSize.xsmall }}
                    >
                      {intent.label}
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>
            <Box className={classes.blocForm}>
              <Typography id="rankRange-label" className={classes.label}>
                Positionnement
              </Typography>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid size={6}>
                  <Typography variant="body3">De </Typography>
                  <Input
                    data-testid="textField-min-rank"
                    value={filters.rankMin}
                    size="small"
                    onChange={(event) => {
                      if (
                        Number.isNaN(Number(event.target.value))
                        || Number(event.target.value) < 0
                      ) {
                        return;
                      }
                      handleRangeChange(
                        'rank',
                        'Min',
                        Math.min(Number(event.target.value), 50),
                      );
                    }}
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 50,
                      'data-testid': 'input-min-rank',
                    }}
                    className={classes.input}
                  />
                </Grid>
                <Grid size={6}>
                  <Typography variant="body3">à </Typography>
                  <Input
                    data-testid="textField-max-rank"
                    value={filters.rankMax}
                    size="small"
                    onChange={(event) => {
                      if (
                        Number.isNaN(Number(event.target.value))
                        || Number(event.target.value) < 0
                      ) {
                        return;
                      }
                      handleRangeChange(
                        'rank',
                        'Max',
                        Math.min(Number(event.target.value), 50),
                      );
                    }}
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 50,
                      'data-testid': 'input-max-rank',
                    }}
                    className={classes.input}
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            className={classes.fullWidth}
          >
            <Box className={classes.blocForm}>
              <Typography id="sitesCount-label" className={classes.label}>
                Nombre de sites
              </Typography>
              <Select
                labelId="sitesCount-label"
                id="sitesCount"
                name="sitesCount"
                value={filters.sitesCount}
                variant="standard"
                className={classes.select}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              >
                {
                  siteCountList.map((value) => (
                    <MenuItem
                      value={value}
                      key={value}
                      sx={{ fontSize: globalTheme.fontSize.xsmall }}
                    >
                      {value}
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>
            <Box className={classes.blocForm}>
              <Typography id="volRange-label" className={classes.label}>
                Demandes mensuelles
              </Typography>
              <Grid container>
                <Grid size={6}>
                  <Typography variant="body3">De </Typography>
                  <Input
                    data-testid="textField-min-volume"
                    value={filters.volMin}
                    size="small"
                    onChange={(event) => {
                      if (
                        Number.isNaN(Number(event.target.value))
                        || Number(event.target.value) < 0
                      ) {
                        return;
                      }
                      handleRangeChange(
                        'vol',
                        'Min',
                        Math.min(Number(event.target.value), maxVol),
                      );
                    }}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: maxVol,
                      'data-testid': 'input-min-volume',
                    }}
                    className={classes.input}
                  />
                </Grid>
                <Grid size={6}>
                  <Typography variant="body3">à </Typography>
                  <Input
                    data-testid="textField-max-volume"
                    value={Math.min(filters.volMax, maxVol)}
                    size="small"
                    onChange={(event) => {
                      if (
                        Number.isNaN(Number(event.target.value))
                        || Number(event.target.value) < 0
                      ) {
                        return;
                      }
                      handleRangeChange(
                        'vol',
                        'Max',
                        Math.min(Number(event.target.value), maxVol),
                      );
                    }}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: maxVol,
                      'data-testid': 'input-max-volume',
                    }}
                    className={classes.input}
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" className={classes.fullWidth} gap={1}>
          <FormControlLabel
            control={(
              <Checkbox
                inputProps={{
                  'data-testid': 'checkbox-best-rank',
                } as React.InputHTMLAttributes<HTMLInputElement>}
                checked={filters.bestRankMode}
                onChange={(e) => handleChange(e.target.name, e.target.checked)}
                name="bestRankMode"
              />
            )}
            label={(
              <Typography variant="body3">
                Les meilleures positions
              </Typography>
            )}
          />
          {isFilter && (
            <Button
              size="small"
              className={classes.cancelButton}
              onClick={resetQuery}
            >
              Par défaut
            </Button>
          )}
          <Button
            size="small"
            variant="contained"
            type="submit"
            className={classes.filterButton}
          >
            Filtrer
          </Button>
        </Stack>
      </Box>
    </StyledStack>
  );
}

export default ParamsBlockView;
