import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAppSelector } from 'common/reduxHooks';
import { RootState } from 'common/store';

import { fetchUsers, selectUserById, selectUserByType } from './slice';
import UsersUtils from '../UsersUtils';

type UsersFetchType = {
  withInactive: boolean,
  idUser?: number | string | undefined,
  isExternal?: boolean | undefined,
  doFetch?: boolean,
}

const useUsersFetch = (props: UsersFetchType) => {
  const {
    withInactive,
    idUser,
    isExternal,
    doFetch = true,
  } = props;

  const dispatch = useDispatch();
  const { isLoading, error } = useSelector(
    (state: RootState) => state.users,
  );

  const users = useAppSelector((state) => selectUserByType(state.users, isExternal));

  const usersSortedByFirstName = UsersUtils.sortedByFirstName(users);

  const userSelected = idUser && useAppSelector((state) => (
    selectUserById(state.users, idUser)
  ));

  useEffect(() => {
    if (doFetch) {
      dispatch(fetchUsers({ with_inactive: withInactive }));
    }
  }, [dispatch]);

  return {
    isLoading, error, users, userSelected, usersSortedByFirstName,
  };
};

export default useUsersFetch;
