import React, { useState } from 'react';

import { Box, Typography, MenuItem } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import { DATA_SOURCE } from 'features/dataFiles/CONSTANT';
import { BASE_DATAVIZ_REQUEST, METRIC } from 'features/dataviz/CONSTANTS';
import datavizConfig from 'features/dataviz/datavizConfig';
import { fetchExportData } from 'features/dataviz/state/slice';
import { DatavizRequest } from 'features/dataviz/types';
import generateChartKey from 'features/dataviz/utils/chartKey';
import { selectAnalysisById } from 'features/serpAnalysis/state/slice';
import formatProjectNameForUrl from 'utils/formatProjectNameForUrl';

type DownloadVolumesProps = {
  ids: number[],
  handleCloseMenu: Function,
  scopeId: number,
  disabled?: boolean,
}

function DownloadVolumes (props: DownloadVolumesProps) {
  const {
    ids,
    handleCloseMenu,
    scopeId,
    disabled,
  } = props;
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const datavizComp = datavizConfig[METRIC.get_all_search_volumes.label];

  const chartKey = generateChartKey(
    datavizComp.label,
    [],
    scopeId,
    '',
  );

  const analysis = useAppSelector((state) => selectAnalysisById(state.serpAnalyses, ids[0]));

  const isExportLoading: boolean | undefined = useAppSelector(
    (state) => state.dataviz.exports[chartKey]?.isLoading,
  );
  const handleExport = () => {
    const payload: DatavizRequest = {
      key: chartKey,
      exportName: `${formatProjectNameForUrl(analysis?.label || '')}_google_ads`,
      requests: [{
        metric: datavizComp.export!.metric,
        resource: datavizComp.export!.resource,
        payload: {
          ...BASE_DATAVIZ_REQUEST,
          scope_id: scopeId,
          serp_analysis_ids: ids,
          sep: 'semicolon',
          data_source_ids: [DATA_SOURCE.data_for_seo_search_volume.id],
        },
      }],
    };
    dispatch(fetchExportData(payload));
  };

  const handleConfirm = () => {
    if (ids.length === 0) return;
    handleExport();
    setOpenModal(false);
  };

  return (
    <Box>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => { if (!openModal) setOpenModal(true); handleCloseMenu(); }
        }
        disabled={disabled}
      >
        Télécharger les volumes
      </MenuItem>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">Télécharger</Typography>
            {' '}
            ce fichier
          </Typography>
        )}
        actionConfirm={handleConfirm}
        actionCancel={() => setOpenModal(false)}
        isOpen={openModal}
        dataTestId="download-data-file-modal"
        displayLoading={isExportLoading}
        isProcessLoading={isExportLoading}
      />
    </Box>
  );
}

export default DownloadVolumes;
