import React from 'react';

import {
  Box,
  BoxProps,
  styled,
  Stack,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import InputSearch from 'components/input/inputSearch';
import { UserType } from 'features/users/types';
import useInputSearch from 'utils/useInputSearch';

import createColumns from './columns';
import CreateExternalUser from '../actions/CreateExternalUser';

type UserAdminDatagridProps = {
  rows: UserType[],
  loadingUsers: boolean,
  isExternal: boolean,
}

const PREFIX = 'UsersDataGrid';

const classes = {
  container: `${PREFIX}-container`,
  labels: `${PREFIX}-labels`,
  labelsClassic: `${PREFIX}-labelsClassic`,
  dataGrid: `${PREFIX}-dataGrid`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.container}`]: {
    height: '100%',
    width: '100%',
    flex: '1',
  },

  [`& .${classes.dataGrid}`]: {
    marginTop: '0rem',
  },

  [`& .${classes.labelsClassic}`]: {
    fontSize: theme.fontSize.medium,
    fontWeight: '400',
  },

  [`& .${classes.labels}`]: {
    color: theme.palette.info.main,
    fontSize: theme.fontSize.medium,
  },
}));

export default function UsersListDataGrid(props: UserAdminDatagridProps): JSX.Element {
  const {
    rows,
    loadingUsers,
    isExternal,
  } = props;

  const columns = createColumns(classes, isExternal);

  const selectRows = (inputSearch: string) => rows.filter(
    (user) => (
      `${user.first_name} ${user.last_name}`.toLowerCase()
        .includes(inputSearch.trim().toLowerCase())
      || `${user.last_name} ${user.first_name}`.toLowerCase()
        .includes(inputSearch.trim().toLowerCase())
      || user.email.includes(inputSearch.trim().toLowerCase())

    ),
  );

  const {
    handleInputChange,
    filterRows,
  } = useInputSearch(rows, selectRows);

  const filteredRows = filterRows();

  return (
    <StyledBox>
      <Stack direction="row" gap={2}>
        {
          isExternal && <CreateExternalUser userList={rows} />
        }
        <InputSearch
          handleInputChange={handleInputChange}
          label="Chercher un utilisateur..."
        />
      </Stack>
      <DataGrid
        className={classes.dataGrid}
        density="comfortable"
        autoHeight
        hideFooterSelectedRowCount
        disableRowSelectionOnClick
        disableColumnMenu
        disableVirtualization
        getRowHeight={() => 50}
        getRowId={(row) => row.id}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
          sorting: {
            sortModel: [{ field: 'first_name', sort: 'asc' }],
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        rows={filteredRows}
        columns={columns}
        slotProps={{
          pagination: {
            labelRowsPerPage: ('Nombre par page :'),
          },
        }}
        localeText={{
          noRowsLabel:
            'Aucun utilisateur',
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} sur ${count}`,
          },
        }}
        loading={loadingUsers}
      />
    </StyledBox>
  );
}
