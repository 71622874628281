import React from 'react';

import { Box, Paper } from '@mui/material';

import globalTheme from 'common/theme';

interface SimpleTooltipProps {
  active?: boolean,
  label?: string,
}

function SimpleTooltipComponent(props: SimpleTooltipProps): JSX.Element | null {
  const {
    active = false,
    label = '',
  } = props;
  if (active) {
    return (
      <Box
        component={Paper}
        sx={{ padding: '0px 10px', textAlign: 'left', fontSize: globalTheme.fontSize.xsmall }}
      >
        <Box>{label}</Box>
      </Box>
    );
  }
  return null;
}

export default SimpleTooltipComponent;
