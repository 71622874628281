import React, { useState } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { FormikProps, useFormik } from 'formik';

import { useAppDispatch } from 'common/reduxHooks';
import Modal from 'components/modal/Modal';
import CategoryGroup from 'features/categoryGroups/classes/CategoryGroup';
import CATEGORY_TYPE_ID from 'features/categoryGroups/CONSTANTS';
import {
  addCategoryGroup,
  addCategoryValues,
  createCategoryWithValues,
} from 'features/categoryGroups/state/slice';

import AddCatValueModal from '../components/AddCategoryValueModal';
import {
  initialValues,
  validationSchema,
  AddCatValuesForm,
  validationSchemaBrand,
  createBrandGroupPayload,
  createNewCategoryPayload,
  createCategoryValuesPayload,
} from '../components/addCatValueConfig';

interface AddCategoryValuesProps {
  categoryGroupType: number,
  scopeId: number,
  categoryGroup: CategoryGroup | undefined,
}

function AddCategoryValue (props: AddCategoryValuesProps) {
  const {
    categoryGroupType,
    scopeId,
    categoryGroup,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();

  const handleSubmit = (values: AddCatValuesForm, { resetForm }: any) => {
    // New brand request group
    if (categoryGroupType === CATEGORY_TYPE_ID.brand && !categoryGroup) {
      dispatch(addCategoryGroup({
        value: createBrandGroupPayload(scopeId, values.patterns),
        withCategories: true,
      }));
      setIsOpen(false);
      resetForm();
      return;
    }
    // New category
    if (values.category === -1) {
      dispatch(
        createCategoryWithValues(createNewCategoryPayload(
          values.patterns,
          values.name,
          categoryGroup?.catGroupId || 0,
        )),
      );
      setIsOpen(false);
      resetForm();
      return;
    }
    // Existing category or brand request group
    const payload = createCategoryValuesPayload(
      values.patterns,
      categoryGroupType === CATEGORY_TYPE_ID.brand
        ? categoryGroup?.catGroupCategories[0].catId || 0
        : values.category,
      categoryGroup?.catGroupId || 0,
    );
    dispatch(addCategoryValues(payload));
    setIsOpen(false);
    resetForm();
  };

  const formik: FormikProps<AddCatValuesForm> = useFormik<AddCatValuesForm>({
    initialValues,
    validationSchema: categoryGroupType === CATEGORY_TYPE_ID.brand
      ? validationSchemaBrand
      : validationSchema(categoryGroup?.catGroupCategories || []),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const labels = {
    [CATEGORY_TYPE_ID.keywords]: 'les règles de catégorisation',
    [CATEGORY_TYPE_ID.url]: 'les règles de catégorisation',
    [CATEGORY_TYPE_ID.brand]: 'une ou plusieurs requêtes Marque',
  };

  return (
    <Box>
      <Button
        variant="contained"
        onClick={
          () => { setIsOpen(true); }
        }
        size="small"
        startIcon={<AddCircleOutlineIcon />}
        data-testid="add-category-value-button"
      >
        Ajouter
      </Button>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">
              Ajouter manuellement
              {' '}
            </Typography>
            {
              labels[categoryGroupType]
            }
          </Typography>
        )}
        actionConfirm={formik.handleSubmit}
        actionCancel={() => { setIsOpen(false); formik.resetForm(); }}
        isOpen={isOpen}
        dataTestId="add-category-value-modal"
      >
        <AddCatValueModal
          formik={formik}
          catGroup={categoryGroup}
          categoryGroupType={categoryGroupType}
        />
      </Modal>
    </Box>
  );
}

export default AddCategoryValue;
