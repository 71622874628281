import React, { useEffect, useState } from 'react';

import Modal from 'components/modal/Modal';

import useKpiViewByType from '../state/useKpiViewByType';
import { KpiViewCreateUpdate } from '../types';

type AddViewModalSimpleProps = {
  scopeId: number,
  typeId: number,
  newView: KpiViewCreateUpdate,
  title: string | JSX.Element,
  isOpen: boolean,
  onConfirm?: Function,
  onClose?: Function,
}

function AddViewModalSimple(props: AddViewModalSimpleProps) {
  const {
    scopeId,
    typeId,
    newView,
    title,
    isOpen,
    onConfirm,
    onClose,
  } = props;

  const [isInternalLoading, setInternalLoading] = useState(false);
  const { isLoading, createKpiView } = useKpiViewByType(scopeId, [typeId]);

  const handleConfirm = () => {
    onConfirm?.();

    createKpiView(newView);
    setInternalLoading(true);
  };

  const handleClose = () => {
    onClose?.();
  };

  useEffect(() => {
    if (!isLoading && isInternalLoading) {
      setInternalLoading(false);
      handleClose();
    }
  }, [isLoading, isInternalLoading]);

  return (
    <Modal
      title={title}
      actionConfirm={handleConfirm}
      actionCancel={handleClose}
      isOpen={isOpen}
      displayLoading={isInternalLoading}
      isProcessLoading={isLoading}
    />

  );
}

export default AddViewModalSimple;
