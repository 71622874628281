import React from 'react';

import { MenuItem, Select } from '@mui/material';

import globalTheme from 'common/theme';
import { CATEGORY_VALUE_TYPE } from 'features/categoryGroups/CONSTANTS';

import { CategoryTypeType } from '../type';

type EditCategoryTypeFieldProps = {
  value: CategoryTypeType,
  onUpdate: (value: CategoryTypeType) => void,
}

function EditCategoryTypeField(props: EditCategoryTypeFieldProps) {
  const { value, onUpdate } = props;

  return (
    <Select
      value={value}
      onChange={(e) => onUpdate(Number(e.target.value) as CategoryTypeType)}
      size="small"
      sx={{
        height: 30,
      }}
      inputProps={{
        sx: {
          fontSize: globalTheme.fontSize.xsmall,
          font: undefined,
        },
      }}
      data-testid="edit-category-type-field-select"
    >
      {Object.entries(CATEGORY_VALUE_TYPE).map(([id, label]) => (
        <MenuItem
          key={label}
          value={id}
          sx={{
            fontSize: globalTheme.fontSize.xsmall,
          }}
        >
          {label}
        </MenuItem>
      ))}
    </Select>
  );
}

export default EditCategoryTypeField;
