import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import ApiDataSources from './api';
import {
  fetchDataSourcesRequested,
  fetchDataSourcesFailure,
  fetchDataSourcesSuccess,
} from './slice';

export function* workFetchDataSources() {
  try {
    const response: AxiosResponse = yield call(ApiDataSources.fetchDataSources);
    const dataSources = response.data;
    yield put(fetchDataSourcesSuccess(dataSources));
  } catch (e: any) {
    yield put(fetchDataSourcesFailure(e.message));
  }
}

export function* sagaFetchDataSources() {
  yield takeEvery(fetchDataSourcesRequested, workFetchDataSources);
}
