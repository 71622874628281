import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Button,
  Menu,
  Box,
  styled,
  BoxProps,
} from '@mui/material';

import DeleteDataFile from './DeleteDataFile';
import DownloadDataFile from './DownloadDataFile';
import UpdateDataFile from './UpdateDataFile';
import { DataFileType } from '../types';

const PREFIX = 'DataFileDatagridActions';

const classes = {
  dropdown: `${PREFIX}-dropdown`,
  button: `${PREFIX}-button`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.dropdown}`]: {
    fontSize: theme.fontSize.xlarge,
  },

  [`& .${classes.button}`]: {
    color: '#737476',
    fontSize: theme.fontSize.medium,
    height: '30px',
    width: '115px',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      color: '#737476',
      backgroundColor: '#e8e8e8',
    },
  },
}));

type DataFileDatagridActionsProps = {
  dataFile: DataFileType,
  handleUpdate?: Function,
  displayUpdate?: boolean,
  displayDelete?: boolean,
  checkQualifDataFiles?: Function,
}

function DataFileDatagridActions(props: DataFileDatagridActionsProps): JSX.Element {
  const {
    dataFile,
    handleUpdate,
    displayUpdate = true,
    displayDelete = true,
    checkQualifDataFiles,
  } = props;
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const openMenu = Boolean(anchorMenu);

  const handleOpenMenu = (event: any) => {
    setAnchorMenu(event.target);
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };
  return (
    <StyledBox>
      <Button
        variant="contained"
        disableElevation
        onClick={(e) => { handleOpenMenu(e); }}
        endIcon={<KeyboardArrowDownIcon className={classes.dropdown} />}
        size="small"
        className={classes.button}
        color="info"
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        keepMounted
        id="menuActionsActors"
      >
        {displayUpdate && (
          <UpdateDataFile
            dataFileId={dataFile.id}
            handleConfirm={handleUpdate ?? (() => undefined)}
            handleCloseMenu={handleCloseMenu}
            error={checkQualifDataFiles && checkQualifDataFiles([dataFile.id])}
          />
        )}
        <DownloadDataFile
          dataFileId={dataFile.id}
          fileName={dataFile.original_name}
          handleCloseMenu={handleCloseMenu}
        />
        {displayDelete && (
          <DeleteDataFile dataFileId={dataFile.id} handleCloseMenu={handleCloseMenu} />
        )}
      </Menu>
    </StyledBox>
  );
}

export default DataFileDatagridActions;
