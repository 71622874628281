import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import MainLayout from 'components/layout/MainLayout';

import RightDrawerView from './RightDrawerView';
import SlidingButton from '../components/SlidingButton';
import useNavbar from '../state/useNavBar';

type RightDrawerProps = {
  menu: React.ReactNode,
};

function RightDrawer({ menu }: RightDrawerProps): JSX.Element {
  const { isOpen, openNavbar, closeNavbar } = useNavbar();

  const toggleRightDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown'
        && ((event as React.KeyboardEvent).key === 'Tab'
          || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    if (open) {
      openNavbar();
    } else {
      closeNavbar();
    }
  };

  // Security if we are on a page without navbar and isOpen is true
  if (isOpen && menu === undefined) {
    closeNavbar();
  }

  return (
    <>
      {
        menu && (
          <Box sx={{ position: 'relative' }}>
            <SlidingButton
              isOpen={isOpen}
              toggleRightDrawer={toggleRightDrawer}
            />
            <Box>
              <RightDrawerView
                toggleDrawer={toggleRightDrawer}
                isOpen={isOpen}
                menu={menu}
              />
            </Box>
          </Box>
        )
      }
      <MainLayout isOpen={isOpen}>
        <Outlet />
      </MainLayout>
    </>
  );
}

export default RightDrawer;
