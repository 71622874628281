import { useAppSelector } from 'common/reduxHooks';
import { RootState } from 'common/store';

import { selectNewCurrentUser } from './slice';

const useCurrentUser = () => {
  const currentUser = useAppSelector(
    (state: RootState) => selectNewCurrentUser(state.authentication),
  );

  return currentUser;
};

export default useCurrentUser;
