/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import SerpAnalysisScrapping from '../types';

export interface State {
  isLoading: boolean;
  scrappings: SerpAnalysisScrapping[]
  error: string;
}

const initialState: State = {
  isLoading: false,
  scrappings: [],
  error: '',
};

export const serpAnalysisScrappingSlice = createSlice({
  name: 'serpAnalysisScrapping',
  initialState,
  reducers: {
    fetchAnalysisScrapping: (state: State, action:PayloadAction<number>) => {
      state.isLoading = true;
    },
    fetchAnalysisScrappingSuccess: (
      state: State,
      action: PayloadAction<SerpAnalysisScrapping[]>,
    ) => {
      state.scrappings = action.payload;
      state.isLoading = false;
    },
    fetchAnalysisScrappingFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const selectAnalysisScrapping = createSelector(
  (state: State) => state.scrappings,
  (scrappings) => scrappings,
);

export const {
  fetchAnalysisScrapping,
  fetchAnalysisScrappingSuccess,
  fetchAnalysisScrappingFailure,
} = serpAnalysisScrappingSlice.actions;

export default serpAnalysisScrappingSlice.reducer;
