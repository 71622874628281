import React from 'react';
import { Navigate } from 'react-router-dom';

import {
  Box,
  Typography,
  Grid2 as Grid,
  Stack,
  styled,
} from '@mui/material';

import MainBox from 'components/layout/MainBox';
import { ActorType } from 'features/actors/types';
import DatavizContainer from 'features/dataviz/components/generic/DatavizContainer';
import { METRIC } from 'features/dataviz/CONSTANTS';
import datavizConfig from 'features/dataviz/datavizConfig';
import { DatavizFilters } from 'features/dataviz/types';
import { Project } from 'features/projects/types';
import ExportKwButton from 'features/serpAnalysis/components/buttons/ExportKwButton';
import BlocksContainer from 'features/serpAnalysis/components/serpDetails/BlockContainer';
import { Analysis } from 'features/serpAnalysis/types';
import SerpAnalysisScrapping from 'features/serpAnalysisScrapping/types';

const PREFIX = 'SerpAnalysisDatavizDetailsView';

const classes = {
  title: `${PREFIX}-title`,
  blueTitle: `${PREFIX}-blueTitle`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.title}`]: {
    fontSize: theme.fontSize.medium,
    fontWeight: '700',
    textTransform: 'uppercase',
    display: 'inline',
  },

  [`& .${classes.blueTitle}`]: {
    fontSize: theme.fontSize.medium,
    fontWeight: '700',
    textTransform: 'uppercase',
    display: 'inline',
    color: theme.palette.primary.light,
  },
}));

type SerpAnalysisDatavizDetailsViewProps = {
  scopeId?: number,
  project?: Project,
  analysis?: Analysis,
  detailsToDisplay?: {
    label: string,
    pageName: string,
    abbr: string,
    type: number,
  },
  scrappingsSorted: SerpAnalysisScrapping[],
  scrappingSelected?: SerpAnalysisScrapping,
  baseUrl: string,
  isRecalculatingKPIs: boolean,
  isLoading: boolean,
  actors: ActorType[],
  scrappingId?: number,
  feature?: string,
  datavizFilters: DatavizFilters,
}

function SerpAnalysisDatavizDetailsView(props: SerpAnalysisDatavizDetailsViewProps): JSX.Element {
  const {
    scopeId,
    project,
    analysis,
    detailsToDisplay,
    scrappingsSorted,
    scrappingSelected,
    baseUrl,
    isRecalculatingKPIs,
    isLoading,
    actors,
    scrappingId,
    feature,
    datavizFilters,
  } = props;

  return (
    <StyledBox>
      <Typography variant="h1">
        {detailsToDisplay?.pageName}
      </Typography>
      <Stack direction="row" justifyContent="flex-end">
        {
        analysis
        && detailsToDisplay?.label === ''
        && scrappingsSorted.length > 0
        && (
          <ExportKwButton
            isRecalculatingKPIs={isRecalculatingKPIs}
            rankingTypeIds={[detailsToDisplay?.type || 0]}
            analysis={analysis}
            datavizComp={datavizConfig[METRIC.get_detailed_datas_by_ranking_type.label]}
            scopeId={scopeId}
            periode={scrappingSelected?.scrapping_date || ''}
            scrappingId={scrappingId}
          />
        )
      }
      </Stack>
      {(project && analysis && scopeId && (
        <Box>
          <BlocksContainer
            project={project}
            scopeId={scopeId}
            isRecalculatingKPIs={isRecalculatingKPIs}
            analysis={analysis}
            isLoading={isLoading}
            scrappingsSorted={scrappingsSorted}
            scrappingSelected={scrappingSelected}
            baseUrl={baseUrl}
            datavizFilters={datavizFilters}
          />
          {scrappingId && (
            <>
              <Grid size={12}>
                <Box>
                  <DatavizContainer
                    idScope={scopeId}
                    datavizComp={
                      datavizConfig[
                        datavizFilters.dvMode === 'volume'
                          ? METRIC.sum_volume_by_ranking_type_group_by_domain.label
                          : METRIC.count_by_ranking_type_group_by_domain.label
                      ]
                    }
                    rankingTypeIds={[detailsToDisplay?.type || 0]}
                    idSerpAnalysis={analysis.id}
                    serpAnalysisStatus={analysis.serp_analysis_status_id}
                    periode={scrappingSelected?.scrapping_date || ''}
                    title={(
                      <Typography component="span" className={classes.title}>
                        SITES LES + REPRÉSENTÉS DANS LES SERP DU
                        {' '}
                        <Typography component="span" className={classes.blueTitle}>
                          TOP
                          {' '}
                          {datavizFilters.rankMax}
                        </Typography>
                      </Typography>
                    )}
                    height="auto"
                    isPageLoading={isLoading}
                    analysis={analysis}
                    actors={actors}
                    project={project}
                    scrappingId={scrappingId}
                    feature={feature}
                    datavizFilters={datavizFilters}
                  />
                </Box>
              </Grid>
              <Grid size={12} paddingBottom="10px">
                <MainBox>
                  <Box>
                    <DatavizContainer
                      datavizComp={
                        datavizConfig[
                          detailsToDisplay?.label === ''
                            ? METRIC.list_keyword_with_rank_and_url.label
                            : METRIC.get_detailed_datas_by_ranking_type.label
                        ]
                      }
                      idScope={scopeId}
                      rankingTypeIds={[detailsToDisplay?.type || 0]}
                      idSerpAnalysis={analysis.id}
                      serpAnalysisStatus={analysis.serp_analysis_status_id}
                      periode={scrappingSelected?.scrapping_date || ''}
                      title="RAPPORT DE POSITIONNEMENT"
                      link=""
                      height="auto"
                      isPageLoading={isLoading}
                      analysis={analysis}
                      actors={actors}
                      project={project}
                      scrappingId={scrappingId}
                      datavizFilters={datavizFilters}
                      searchEngineId={analysis.search_engine_id}
                    />
                  </Box>
                </MainBox>
              </Grid>
            </>
          )}
        </Box>
      )) || (analysis && (
        <Navigate to="/" />
      ))}
    </StyledBox>
  );
}

export default SerpAnalysisDatavizDetailsView;
