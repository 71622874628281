import React from 'react';

import {
  Box,
  Typography,
  styled,
  BoxProps,
} from '@mui/material';

import { Analysis } from 'features/serpAnalysis/types';
import { convertDate, DATE_NO_HOUR } from 'utils/formatDate';

import DevicePerimeter from '../serpDetails/dataSourceBlock/DevicePerimeter';

const PREFIX = 'RankingGraphTitle';

const classes = {
  title: `${PREFIX}-title`,
  blueTitle: `${PREFIX}-blueTitle`,
  perimeter: `${PREFIX}-perimeter`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.title}`]: {
    fontSize: theme.fontSize.medium,
    fontWeight: '700',
    textTransform: 'uppercase',
    display: 'inline',
  },

  [`& .${classes.blueTitle}`]: {
    fontSize: theme.fontSize.medium,
    fontWeight: '700',
    textTransform: 'uppercase',
    display: 'inline',
    color: theme.palette.primary.light,
  },

  [`& .${classes.perimeter}`]: {
    width: '80%',
    margin: '0 auto',
  },
}));
type RankingGraphTitleProps = {
  periodeBegin: string,
  periodeEnd: string,
  analysis: Analysis | undefined,
  deviceTypeId: number,
}

export default function RankingGraphTitle(props: RankingGraphTitleProps) {
  const {
    periodeBegin,
    periodeEnd,
    analysis,
    deviceTypeId,
  } = props;

  return (
    <StyledBox>
      <Typography className={classes.title}>
        EVOLUTION DU POSITIONNEMENT
        {' '}
        <Typography component="span" className={classes.blueTitle}>
          {convertDate(new Date(periodeBegin), DATE_NO_HOUR)}
          {' '}
          -
          {' '}
          {convertDate(new Date(periodeEnd), DATE_NO_HOUR)}
        </Typography>
      </Typography>
      <Box className={classes.perimeter}>
        {
          analysis
          && (
            <DevicePerimeter
              index={analysis.serp_analysis_index}
              deviceTypeId={deviceTypeId}
              searchEngineId={analysis.search_engine_id}
            />
          )
        }
      </Box>
    </StyledBox>
  );
}
