import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Button,
  Menu,
  Box,
  styled,
  BoxProps,
} from '@mui/material';

import UpdateDFSDataTypeAction from './UpdateDFSDataTypeAction';
import UpdateSearchVolumeComputedAction from './UpdateSearchVolumeComputedAction';
import { Analysis } from '../../types';

const PREFIX = 'DataFileDatagridActions';

const classes = {
  dropdown: `${PREFIX}-dropdown`,
  button: `${PREFIX}-button`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.dropdown}`]: {
    fontSize: theme.fontSize.xlarge,
  },

  [`& .${classes.button}`]: {
    color: '#737476',
    fontSize: theme.fontSize.medium,
    height: '30px',
    width: '115px',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      color: '#737476',
      backgroundColor: '#e8e8e8',
    },
  },
}));

type DataGridActionsProps = {
  serpAnalysis: Analysis,
}

function DataGridActions(
  props: DataGridActionsProps,
): JSX.Element {
  const {
    serpAnalysis,
  } = props;
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const openMenu = Boolean(anchorMenu);

  const handleOpenMenu = (event: any) => {
    setAnchorMenu(event.target);
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };
  return (
    <StyledBox>
      <Button
        variant="contained"
        disableElevation
        onClick={handleOpenMenu}
        endIcon={<KeyboardArrowDownIcon className={classes.dropdown} />}
        size="small"
        className={classes.button}
        color="info"
        data-testid="serp-analysis-options-data-grid-actions-button"
      >
        Paramétrer
      </Button>
      <Menu
        anchorEl={anchorMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        keepMounted
        data-testid="serp-analysis-option-data-grid-actions-menu"
      >
        <UpdateSearchVolumeComputedAction
          serpAnalysis={serpAnalysis}
          handleCloseMenu={handleCloseMenu}
        />
        <UpdateDFSDataTypeAction
          serpAnalysis={serpAnalysis}
          handleCloseMenu={handleCloseMenu}
        />
      </Menu>
    </StyledBox>
  );
}

export default DataGridActions;
