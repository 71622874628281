import React from 'react';

import {
  Typography,
  TextField,
  styled,
  Box,
  BoxProps,
  Button,
  Stack,
} from '@mui/material';
import {
  ErrorMessage,
  FormikProps,
  FormikProvider,
  getIn,
} from 'formik';

import { ProjectForm } from './CreateProjectConfig';

type CreateProjectViewProps = {
  formik: FormikProps<ProjectForm>,
}

const PREFIX = 'CreateProjectView';

const classes = {
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  input: `${PREFIX}-input`,
  rightStack: `${PREFIX}-rightStack`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.container}`]: {
    justifyContent: 'space-between',
    position: 'relative',
    direction: 'row',
  },

  [`& .${classes.label}`]: {
    fontSize: theme.fontSize.medium,
  },

  [`& .${classes.input}`]: {
    marginTop: theme.spacing(2),
    width: '40%',
    fontSize: theme.fontSize.medium,
  },

  [`& .${classes.rightStack}`]: {
    direction: 'column',
    alignItems: 'end',
  },

}));

function CreateProjectView({ formik }: CreateProjectViewProps) {
  const { handleSubmit, handleChange } = formik;
  return (
    <StyledBox>
      <Typography className={classes.label}>
        Vous devez saisir le nom de votre projet
        <Typography component="span" color="red"> *</Typography>
      </Typography>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <FormikProvider value={formik}>
          <Stack className={classes.container} direction="column">
            <TextField
              variant="filled"
              className={classes.input}
              slotProps={{
                htmlInput: { maxLength: 70 },
              }}
              label="Nouveau projet"
              name="name"
              id="name"
              size="small"
              onChange={handleChange}
              value={formik.values.name}
              error={
                getIn(formik.errors, 'name')
                && getIn(formik.touched, 'name')
              }
              helperText={
                <ErrorMessage name="name" />
              }
            />
          </Stack>
          <Stack className="rightStack" sx={{ alignItems: 'end' }}>
            <Button
              variant="contained"
              size="large"
              type="submit"
            >
              Enregistrer
            </Button>
          </Stack>
        </FormikProvider>
      </Box>
    </StyledBox>
  );
}

export default CreateProjectView;
