import React, { useState } from 'react';

import { MenuItem, Typography } from '@mui/material';
import { FormikProps, useFormik } from 'formik';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';

import {
  UpdateCatGroupForm,
  initValues,
  validationSchema,
} from './modals/editCatGroupConfig';
import EditCatGroupModal from './modals/EditCatGroupModal';
import CategoryGroup from '../classes/CategoryGroup';
import { CategoryGroup as CategoryGroupType } from '../types';

interface UpdateCatGroupsProps {
  catGroup: CategoryGroupType,
  scopeId: number,
  handleCloseMenu: Function,
}

function UpdateCatGroups(props: UpdateCatGroupsProps) {
  const { catGroup, scopeId, handleCloseMenu } = props;
  const categoryGroup = new CategoryGroup(catGroup);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleModalSubmit = (values: UpdateCatGroupForm) => {
    if (scopeId === 0 || !categoryGroup) return;
    const payload = {
      name: values.name.trim(),
      scope_id: categoryGroup.catGroupScopeId,
      category_type_id: categoryGroup.catGroupType,
    };
    categoryGroup.updateCatVal(dispatch, payload);
    setIsOpen(false);
  };

  const formik: FormikProps<UpdateCatGroupForm> = useFormik<UpdateCatGroupForm>({
    initialValues: initValues(catGroup),
    validationSchema,
    onSubmit: handleModalSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => {
            if (!isOpen) setIsOpen(true); handleCloseMenu();
          }
        }
        data-testid="update-category-group-menu-item"
      >
        Renommer
      </MenuItem>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">Renommer</Typography>
            {' '}
            le groupe d&apos;URL
          </Typography>
        )}
        actionConfirm={formik.handleSubmit}
        actionCancel={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        dataTestId="update-category-group-update-modal"
      >
        <EditCatGroupModal
          formik={formik}
        />
      </Modal>
    </>
  );
}

export default UpdateCatGroups;
