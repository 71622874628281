import React from 'react';

import { styled, Box } from '@mui/material';

import Spinner from 'components/loading/Spinner';
import { DataInfos } from 'features/dataviz/types';
import { ANALYSIS_STATUS } from 'features/serpAnalysis/CONSTANT';

import ChartErrorComponent from './ChartErrorComponent';
import TitleComponent from './charts/common/Title';

const PREFIX = 'ChartErrorHandler';

const classes = {
  root: `${PREFIX}-root`,
  loadingContainer: `${PREFIX}-loadingContainer`,
  spinnerContainer: `${PREFIX}-spinnerContainer`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    position: 'relative',
    height: '100%',
  },

  [`& .${classes.loadingContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },

  [`& .${classes.spinnerContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    height: '100%',
    justifyContent: 'center',
  },
}));

type ChartErrorHandlerProps = {
  title: string | JSX.Element,
  chartData: DataInfos | undefined,
  serpAnalysisStatus: number | undefined,
  isChartWithoutDatas: Function | undefined,
  isPageLoading: boolean | undefined,
}

function ChartErrorHandler(props: ChartErrorHandlerProps) {
  const {
    title,
    chartData,
    serpAnalysisStatus,
    isChartWithoutDatas,
    isPageLoading = false,
  } = props;

  return (
    <Root className={classes.root} data-testid="chart-error-handler">
      { (
        isPageLoading
          && (
            <Box className={classes.loadingContainer}>
              <TitleComponent title={title} />
              <Box className={classes.spinnerContainer} data-testid="spinner">
                <Spinner />
              </Box>
            </Box>
          )
      )
      || ((serpAnalysisStatus === ANALYSIS_STATUS.IN_PROGRESS) && (
        <ChartErrorComponent
          title={title}
        >
          Données en cours d&apos;actualisation
        </ChartErrorComponent>
      ))
      || ((serpAnalysisStatus === ANALYSIS_STATUS.DRAFT) && (
        <ChartErrorComponent
          title={title}
        >
          Aucune donnée disponible
        </ChartErrorComponent>
      ))
      || ((!!chartData?.error) && (
        <ChartErrorComponent
          title={title}
        >
          Erreur base de données
        </ChartErrorComponent>
      ))
      || ((isChartWithoutDatas && isChartWithoutDatas(chartData)) && (
        <ChartErrorComponent
          title={title}
        >
          Aucune donnée disponible
        </ChartErrorComponent>
      ))}
    </Root>
  );
}

export default ChartErrorHandler;
