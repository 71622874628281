import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, MenuItem } from '@mui/material';
import { useFormik, FormikProps } from 'formik';
import moment, { Moment } from 'moment';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import useActors from 'features/actors/state/useActors';
import useCurrentUser from 'features/authentication/state/useCurrentUser';
import { initialValues, validationSchema }
  from 'features/serpAnalysis/components/modals/scrapeModal/scrapeModalConfig';
import ScrapeModalContent
  from 'features/serpAnalysis/components/modals/scrapeModal/ScrapeModalContent';
import { ScrapeModalFormikProps } from 'features/serpAnalysis/components/modals/scrapeModal/types';
import ScrappingButton
  from 'features/serpAnalysis/components/serpDetails/dataSourceBlock/ScrappingButton';
import { ANALYSIS_STATUS } from 'features/serpAnalysis/CONSTANT';
import { updateSerpAnalyses, selectMultipleById, resetError }
  from 'features/serpAnalysis/state/slice';
import { SerpAnalysisUpdatePayload } from 'features/serpAnalysis/types';
import getNextScrapDate from 'features/serpAnalysis/utils/serpAnalysis';
import PLANNING_TYPE from 'features/serpAnalysisPlanning/state/CONSTANTS';
import { DATE_NUMBERS_HOURS_SC_UTC } from 'utils/formatDate';

import ScheduleAnalysesButton from '../buttons/ScheduleAnalysesButton';

type PlanScrappingProps = {
  ids: number[],
  handleCloseMenu?: Function,
  isButton: boolean,
  projectId: number,
  scopeId: number,
  projectName: string,
  isRecalculatingKPIs?: boolean,
  hasIcon: boolean,
  isProjectBudgetEnough: boolean,
  planningChoice?: 'serpPlanning' | 'serpManual',
}

function PlanScrapping(props: PlanScrappingProps) {
  const {
    ids,
    handleCloseMenu,
    isButton,
    projectId,
    scopeId,
    projectName,
    isRecalculatingKPIs,
    hasIcon,
    isProjectBudgetEnough,
    planningChoice,
  } = props;
  const dispatch: any = useAppDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<Moment>(moment());

  const { hasActors } = useActors({ scopeId, doFetch: false });

  const currentUser = useCurrentUser();

  const analyses = useAppSelector((state) => (
    selectMultipleById(state.serpAnalyses, ids)));
  const error = useAppSelector((state) => state.serpAnalyses.error);

  const isLoading = analyses.length
    ? ((analyses[0]?.serp_analysis_status_id === ANALYSIS_STATUS.IN_PROGRESS)
      || isRecalculatingKPIs!)
    : false;

  const handleConfirm = (values: ScrapeModalFormikProps) => {
    const payload: SerpAnalysisUpdatePayload[] = analyses.map((a) => (
      {
        id: a.id,
        serp_analysis_planning_id: values.planningId,
        planning_date: values.date,
        planning_delay: values.planningDelay,
      }
    ));
    dispatch(updateSerpAnalyses(payload));
    setOpenModal(false);
  };

  // If the planning date is in the past, use the current date
  const defaultDate = moment(analyses[0]?.planning_date).diff(moment()) < 0
    ? currentDate.toISOString()
    : analyses[0]?.planning_date;

  const nextScrappingDate = (
    analyses.length === 1
    && analyses[0].planning_date
    && analyses[0].serp_analysis_planning_id
    && analyses[0].serp_analysis_planning_id !== PLANNING_TYPE.NO_REPEAT
  )
    ? getNextScrapDate(
      analyses[0].last_scrapping,
      analyses[0].planning_date,
      analyses[0].serp_analysis_planning_id,
      analyses[0].planning_delay,
      true,
      DATE_NUMBERS_HOURS_SC_UTC,
    )
    : '';

  const formik: FormikProps<ScrapeModalFormikProps> = useFormik<ScrapeModalFormikProps>({
    initialValues: (analyses.length === 1)
      ? initialValues(
        analyses[0].serp_analysis_planning_id,
        nextScrappingDate || defaultDate,
        analyses[0].planning_delay,
      )
      : initialValues(
        null,
        null,
        null,
      ),
    validationSchema,
    onSubmit: handleConfirm,
    enableReinitialize: true,
  });

  const handleNoActorsConfirm = () => {
    navigate(`/projects/${projectId}-${projectName}/setting/competitor`);
    setOpenModal(false);
  };

  const handleErrorConfirm = () => {
    dispatch(resetError());
    setOpenModal(false);
  };

  useEffect(() => {
    if (
      analyses.length === 1
      && (
        !analyses[0].last_scrapping
        || analyses[0].serp_analysis_planning_id === PLANNING_TYPE.NO_REPEAT
      )) {
      formik.setValues({
        ...formik.values,
        date: moment().toISOString(),
      });
    }
  }, [openModal]);

  return (
    <Box data-testid="plan-scrapping">
      {
        (
          (isButton && hasIcon) && (
            <ScheduleAnalysesButton
              handleOpenModal={() => {
                setCurrentDate(moment());
                setOpenModal(true);
              }}
              disabled={!isProjectBudgetEnough || currentUser?.isConsultant(projectId)}
            />
          )
        )
        || (
          (isButton && !hasIcon) && (
            <ScrappingButton
              isLoading={isLoading}
              isRecalculatingKPIs={isRecalculatingKPIs!}
              handleOpenModal={() => {
                setCurrentDate(moment());
                setOpenModal(true);
              }}
              disabled={!isProjectBudgetEnough || currentUser?.isConsultant(projectId)}
            />
          )
        )
        || (
          <MenuItem
            sx={{ fontSize: globalTheme.fontSize.middlesmall }}
            onClick={
              () => {
                setCurrentDate(moment());
                setOpenModal(true);
                handleCloseMenu?.();
              }
            }
            disabled={!isProjectBudgetEnough || currentUser?.isConsultant(projectId)}
            data-testid="menu-item-plan-scrapping"
          >
            Planifier
          </MenuItem>
        )
      }
      {(
        !hasActors
        && (
          <Modal
            title={(
              <Typography>
                <Typography component="span" variant="bold">Ajouter</Typography>
                {' '}
                un acteur
              </Typography>
            )}
            actionConfirm={handleNoActorsConfirm}
            actionCancel={() => setOpenModal(false)}
            isOpen={openModal}
            dataTestId="plan-scrapping-no-actor-modal"
          >
            <Typography variant="body2">
              L’analyse des SERP ne peut pas débuter quand aucun acteur n&apos;est défini.
              Merci de les paramétrer.
            </Typography>
          </Modal>
        ))
        || ((hasActors && !error) && (
          <Modal
            title={(
              <Typography>
                <Typography component="span" variant="bold">Planifier</Typography>
                {' '}
                l&apos;analyse de mots clés
              </Typography>
            )}
            displayCancel
            actionConfirm={formik.handleSubmit}
            actionCancel={() => setOpenModal(false)}
            isOpen={openModal}
            dataTestId="plan-scrapping-actor-modal"
          >
            <ScrapeModalContent
              values={formik.values}
              handleChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
              errors={formik.errors}
              touched={formik.touched}
              planningChoice={planningChoice}
            />
          </Modal>
        ))
        || ((hasActors && error) && (
          <Modal
            title={(
              <Typography>
                <Typography component="span" variant="bold">Planifier</Typography>
                {' '}
                l&apos;analyse de mots clés
              </Typography>
            )}
            actionConfirm={handleErrorConfirm}
            displayCancel={false}
            isOpen={openModal}
            dataTestId="plan-scrapping-error-modal"
          >
            <Typography>
              {error}
            </Typography>
          </Modal>
        ))}
    </Box>
  );
}

export default PlanScrapping;
