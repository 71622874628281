import React, { useState } from 'react';

import { DataGrid } from '@mui/x-data-grid';

import CheckBoxDataGrid from 'components/checkbox/CheckBoxDataGrid';
import LinearLoadingOverlay from 'components/loading/LinearLoadingOverlay';
import CategoryValue from 'features/categoryGroups/classes/CategoryValue';
import CATEGORY_TYPE_ID from 'features/categoryGroups/CONSTANTS';

import createColumns from './ConfigCategoryValuesDataGrid';
import { EditCategoryValueType, HandleUpdateCategoryValueType } from '../type';

type CateGoryValuesDataGridProps = {
  rows: CategoryValue[],
  handleSelectionModelChange: (rowSelectionModel: number[]) => void,
  selectionModel: number[],
  isLoading: boolean,
  categoryGroupType: number,
}

function CategoryValuesDataGrid(props: CateGoryValuesDataGridProps) {
  const {
    rows,
    selectionModel,
    handleSelectionModelChange,
    isLoading,
    categoryGroupType,
  } = props;
  const [editedRowValue, setEditedRowValue] = useState<EditCategoryValueType>({
    id: 0,
    url: '',
    categoryType: 1,
    include: false,
  });

  const handleSetEditRowValue = (categoryValueId: number) => {
    const editedRow = rows.find((row) => row.catValId === categoryValueId);

    if (editedRow) {
      setEditedRowValue({
        id: editedRow.catValId,
        url: editedRow.catValValue,
        categoryType: editedRow.catValCatTypeId,
        include: editedRow.catValInclude,
      });
    } else {
      setEditedRowValue({
        ...editedRowValue,
        id: 0,
      });
    }
  };

  const handleUpdateEditRowValue = ({ key, value }: HandleUpdateCategoryValueType) => {
    setEditedRowValue((previous) => ({
      ...previous,
      [key]: value,
    }));
  };

  const columns = createColumns(
    editedRowValue,
    handleSetEditRowValue,
    handleUpdateEditRowValue,
    categoryGroupType,
  );

  return (
    <DataGrid
      hideFooterSelectedRowCount
      disableRowSelectionOnClick
      disableColumnMenu
      checkboxSelection
      autoHeight
      disableVirtualization
      getRowHeight={() => 'auto'}
      getRowId={(row) => row.catValId}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 25,
          },
        },
        sorting: {
          sortModel: [{
            field: categoryGroupType === CATEGORY_TYPE_ID.brand
              ? 'pattern'
              : 'categories',
            sort: 'asc',
          }],
        },
      }}
      slots={{
        loadingOverlay: LinearLoadingOverlay,
        baseCheckbox: CheckBoxDataGrid,
      }}
      pageSizeOptions={[25, 50, 100]}
      rows={rows}
      columns={columns}
      slotProps={{
        pagination: {
          labelRowsPerPage: ('Nombre par page :'),
        },
      }}
      loading={isLoading}
      localeText={{
        noRowsLabel: (
          (
            categoryGroupType === CATEGORY_TYPE_ID.brand
            && 'Aucune requête Marque n\'est définie'
          ) || (
            categoryGroupType === CATEGORY_TYPE_ID.keywords
            && 'Aucun pattern de mot-clé n\'est défini'
          )
          || 'Aucun pattern d\'URL n\'est défini'
        ),
      }}
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={
        (newSelectionModel) => handleSelectionModelChange(newSelectionModel as number[])
      }
    />
  );
}

export default CategoryValuesDataGrid;
