import { AppDispatch } from 'common/store';

import Category from './Category';
import CategoryValue from './CategoryValue';
import { updateCategoryGroup, deleteCategoryGroups } from '../state/slice';
import {
  CategoryGroup as CategoryGroupType,
  CategoryGroupUpdate,
} from '../types';

export default class CategoryGroup {
  private id: number;

  private name: string;

  private insertion_date: string;

  private scope_id: number;

  private category_type_id: number;

  private categories: Category[];

  private active: boolean;

  constructor(catGroup: CategoryGroupType) {
    this.id = catGroup?.id;
    this.name = catGroup.name;
    this.scope_id = catGroup.scope_id;
    this.insertion_date = catGroup.insertion_date;
    this.active = catGroup.active;
    this.category_type_id = catGroup.category_type_id;
    this.categories = catGroup.categories.map((cat) => new Category(cat));
  }

  get catGroupName() {
    return this.name;
  }

  get catGroupScopeId() {
    return this.scope_id;
  }

  get catGroupDate() {
    return this.insertion_date;
  }

  get catGroupId() {
    return this.id;
  }

  get catGroupActive() {
    return this.active;
  }

  get catGroupCategories() {
    return this.categories;
  }

  get catGroupType() {
    return this.category_type_id;
  }

  formatCategoryValuesForDataGrid() {
    const formattedValues = this.categories.reduce(
      (
        categoriesCategoryValue,
        categorie,
      ) => categoriesCategoryValue.concat(categorie.catValues),
      [] as CategoryValue[],
    );
    formattedValues.sort((a, b) => a.catValId - b.catValId);
    return formattedValues;
  }

  // Finish when real action is written
  updateCatVal(dispatch: AppDispatch, newcatGroup: CategoryGroupUpdate) {
    const payload = {
      category_group: newcatGroup,
      id: this.id,
    };
    dispatch(updateCategoryGroup(payload));
  }

  deleteCatVal(dispatch: AppDispatch) {
    dispatch(deleteCategoryGroups([this.id]));
  }
}
