import React from 'react';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Button, CircularProgress, Stack, StackProps, styled,
} from '@mui/material';

import InputSearch from 'components/input/inputSearch';
import smallSpinnerSize from 'components/loading/config';

const PREFIX = 'KeywordButtonActions';

const classes = {
  core: `${PREFIX}-core`,
  button: `${PREFIX}-button`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    height: '42px',
  },

  [`& .${classes.button}`]: {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
  },
}));

export type DataGridActionButtonsProps = {
  handleInputChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  handleExport: React.MouseEventHandler<HTMLElement> | undefined,
  isLoading: boolean | undefined,
}

function DataGridActionButtons(props: DataGridActionButtonsProps): JSX.Element {
  const {
    handleInputChange,
    handleExport,
    isLoading,
  } = props;

  return (
    <StyledStack
      direction="row"
      justifyContent="space-between"
      className={classes.core}
    >
      <InputSearch
        handleInputChange={handleInputChange}
        label="Chercher un mot clé"
      />
      {handleExport && (
        <Button
          onClick={
            !isLoading
              ? handleExport
              : undefined
          }
          variant="outlined"
          size="small"
          className={classes.button}
          startIcon={
            !isLoading
              ? <FileDownloadIcon data-testid="icon-export" />
              : undefined
          }
          data-testid="button-export"
        >
          {
            !isLoading
              ? (
                'Exporter'
              )
              : (
                <CircularProgress
                  color="inherit"
                  size={smallSpinnerSize}
                  data-testid="export-spinner"
                />
              )
          }
        </Button>
      )}
    </StyledStack>
  );
}

export default DataGridActionButtons;
