import { SCRAPPING_STATUS } from 'features/serpAnalysis/CONSTANT';
import SerpAnalysisScrapping from 'features/serpAnalysisScrapping/types';

export function getScrappingsByStatus(
  scrappings: Array<SerpAnalysisScrapping> | undefined,
  status: number,
): Array<SerpAnalysisScrapping> {
  const scrappingsFiltered = scrappings && scrappings.length
    ? scrappings.filter(
      (scrapping) => (
        scrapping.scrapping_status_id === status
        && scrapping.scrapping_date !== null
      ),
    )
    : [];

  return scrappingsFiltered;
}

export function getLastScrappingDate(
  scrappings: Array<SerpAnalysisScrapping> | undefined,
): string {
  const lastScrapping = getScrappingsByStatus(
    scrappings,
    SCRAPPING_STATUS.DONE,
  ).reduce(
    (a, b) => (a.scrapping_date > b.scrapping_date ? a : b),
    {
      scrapping_date: '',
    },
  ).scrapping_date;
  return lastScrapping;
}

// Newest to oldest
export function sortScrappingsByDate(
  scrappings: Array<SerpAnalysisScrapping> | undefined,
): Array<SerpAnalysisScrapping> {
  if (!scrappings) return [];

  const scrappingsSorted = getScrappingsByStatus(
    scrappings,
    SCRAPPING_STATUS.DONE,
  ).sort((a, b) => new Date(b.scrapping_date).getTime() - new Date(a.scrapping_date).getTime());
  return scrappingsSorted;
}

export const sortScrappingsAndFindScrappingFromUrl = (
  scrappings: SerpAnalysisScrapping[],
  idScrapFromUrl: number | undefined,
  deviceTypeFromUrl: number | undefined,
) => {
  const scrappingsSorted = sortScrappingsByDate(scrappings);
  let scrappingSelected: SerpAnalysisScrapping | undefined = scrappingsSorted[0];
  if (deviceTypeFromUrl) {
    scrappingSelected = scrappingsSorted.find((scrapping) => (
      scrapping.device_type_id === deviceTypeFromUrl
    ));
  }
  if (idScrapFromUrl) {
    scrappingSelected = scrappingsSorted.find((scrapping) => (
      scrapping.id === idScrapFromUrl
    ));
  }
  return { scrappingSelected, scrappingsSorted };
};

export function getFirstScrappingDate(
  scrappings: Array<SerpAnalysisScrapping> | undefined,
): string {
  const firstScrapping = getScrappingsByStatus(
    scrappings,
    SCRAPPING_STATUS.DONE,
  ).reduce(
    (a, b) => (a.scrapping_date < b.scrapping_date ? a : b),
    {
      scrapping_date: new Date(Date.now()).toISOString(),
    },
  ).scrapping_date;
  return firstScrapping;
}
