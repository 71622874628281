import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Box, Typography,
} from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import { useAppSelector } from 'common/reduxHooks';
import MainBox from 'components/layout/MainBox';
import CategoryGroupsActionButtons
  from 'features/categoryGroups/list/CategoryGroupsActionButtons';
import CategoryGroupsDatagrid from 'features/categoryGroups/list/CategoryGroupsDatagrid';
import { selectProjectById } from 'features/projects/state/slice';
import { splitIdName } from 'utils/urlManipulation';

function SetupCategoryGroups () {
  const { idNameProject } = useParams();
  const { id: idProject } = splitIdName(idNameProject);

  const [idsSelected, setIdsSelected] = useState<number[]>([]);

  const project = useAppSelector((state) => (
    selectProjectById(state.projects, idProject)));

  const scopeId = project?.scopes[0]?.id ? project?.scopes[0]?.id : 0;

  useDocumentTitle(`${project?.name} : catégorisation des URL | benhur`);

  const handleSelect = (ids: Array<number>) => {
    setIdsSelected(ids);
  };

  return (
    <Box>
      <Typography variant="h1">
        Créez un nombre illimité de groupe pour organiser vos catégories d&apos;URL
      </Typography>
      <MainBox>
        <CategoryGroupsActionButtons
          scopeId={scopeId}
          idsSelected={idsSelected}
        />
        <CategoryGroupsDatagrid
          scopeId={scopeId || 0}
          handleSelect={handleSelect}
        />
      </MainBox>
    </Box>
  );
}

export default SetupCategoryGroups;
