import React from 'react';

import {
  Box,
  Stack,
  styled,
  StackProps,
  Typography,
} from '@mui/material';
import {
  BarChart,
  ResponsiveContainer,
  Tooltip,
  Bar,
  Legend,
  XAxis,
  YAxis,
  Cell,
  LabelList,
} from 'recharts';

import globalTheme from 'common/theme';
import Spinner from 'components/loading/Spinner';
import ChartErrorHandler from 'features/dataviz/components/generic/ChartErrorHandler';
import { DataInfos } from 'features/dataviz/types';
import formatThousands from 'utils/formatThousands';
import getTextContrast from 'utils/getTextContrast';

import TitleComponent from './common/Title';
import TooltipComponent from './common/Tooltip';
import useChartOpacity from './useChartOpacity';

const PREFIX = 'BarChart';

const classes = {
  root: `${PREFIX}-root`,
  typography: `${PREFIX}-typography`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    height: '100%',
  },
  [`& .${classes.typography}`]: {
    fontSize: theme.fontSize.xsmall,
  },
}));

export interface BarChartComponentProps {
  datas: any[],
  title: string | JSX.Element,
  link?: string,
  dataKeys: any[],
  size?: string,
  showLegend?: boolean,
  setBarColor?: Function,
  width?: string | undefined,
  tooltipLabel?: string,
  xAxisDatakey?: string,
  isPageLoading?: boolean | undefined,
  hideAxis?: boolean | undefined,
  topLabelName?: string | undefined,
  legendAngle?: number | undefined,
  tickMargin?: number | undefined,
  handleClick?: Function | undefined,
  isAxisLabelBold?: boolean | undefined,
  withTooltip?: boolean,
  hideXAxisLabel?: boolean,
  tooltipContent?: JSX.Element,
  isLoading?: boolean | undefined,
  dataObject?: DataInfos,
  isChartWithoutDatas?: Function,
  serpAnalysisStatus?: number | undefined,
  radioFilter?: JSX.Element | undefined,
  xAxisInterval?: 'preserveStart' | 'preserveEnd'
  | 'preserveStartEnd' | 'equidistantPreserveStart' | number | undefined,
  showCursor?: boolean,
  yAxisLabel?: string,
  containerAspect?: number | undefined,
  centerLabelName?: boolean | undefined
  percentLabel?: boolean | undefined,
  truncateTickLabel?: boolean,
  isAnimationActive?: boolean,
}

function BarChartComponent(props: BarChartComponentProps) {
  const {
    datas,
    title,
    link,
    dataKeys,
    size = '',
    showLegend = false,
    setBarColor,
    width,
    tooltipLabel = '',
    xAxisDatakey = 'name',
    isPageLoading = false,
    hideAxis = false,
    topLabelName,
    legendAngle = -45,
    tickMargin = 25,
    handleClick,
    isAxisLabelBold,
    withTooltip = false,
    hideXAxisLabel = false,
    tooltipContent,
    isLoading = false,
    xAxisInterval = 0,
    dataObject,
    isChartWithoutDatas,
    serpAnalysisStatus = 0,
    radioFilter,
    showCursor = false,
    yAxisLabel = '',
    containerAspect,
    centerLabelName,
    percentLabel,
    truncateTickLabel = true,
    isAnimationActive = true,
  } = props;

  const {
    opacity,
    handleMouseEnter,
    handleMouseLeave,
    playAnimation,
  } = useChartOpacity(dataKeys);
  const determineHeight = (height: string | undefined) => {
    if (height === 'big') return 600;
    if (height === 'small') return 110;
    if (height === 'medium') return 300;
    return 250;
  };

  const determineWidth = () => {
    if (width) return width;
    if (size === 'big') return '100%';
    if (size === 'small') return '80%';
    return '100%';
  };

  const formatLegendText = (value: string) => (<Typography variant="body3">{value}</Typography>);
  return (
    <StyledStack
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      {
        datas.length > 0
          ? (
            <Stack
              sx={{ width: determineWidth(), height: '100%' }}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Box sx={{ marginBottom: '.5rem' }}>
                <TitleComponent title={title} link={link} />
              </Box>
              { !isPageLoading && !isLoading
                ? (
                  <>
                    <ResponsiveContainer
                      height={determineHeight(size)}
                      aspect={containerAspect}
                    >
                      <BarChart
                        data={datas}
                        layout="horizontal"
                        onClick={
                          handleClick
                            ? ((e) => handleClick(e, e?.activeLabel))
                            : undefined
                        }
                        margin={{
                          top: 15,
                          right: 20,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <XAxis
                          dataKey={xAxisDatakey}
                          angle={legendAngle}
                          fontSize={globalTheme.fontSize.medium}
                          interval={xAxisInterval}
                          minTickGap={1}
                          tickMargin={tickMargin}
                          height={
                            (hideXAxisLabel && 0)
                            || (!hideXAxisLabel && size !== 'small' ? 70 : 25)
                          }
                          axisLine={!hideAxis}
                          tickLine={!hideAxis}
                          tick={
                            !hideXAxisLabel
                            && {
                              fontWeight: isAxisLabelBold ? 'bold' : 'normal',
                              fill: handleClick ? globalTheme.palette.primary.light : 'inherit',
                              width: 100,
                            }
                          }
                          tickFormatter={(tick) => {
                            if (tick.length <= 10 || !truncateTickLabel) {
                              return tick;
                            }
                            return `${tick.slice(0, 10)}...`;
                          }}
                          onClick={handleClick && (
                            (e: any) => handleClick(e, e?.value)
                          )}
                          style={{
                            cursor: handleClick ? 'pointer' : '',
                            fontSize: globalTheme.fontSize.xsmall,
                          }}
                        />
                        <YAxis
                          allowDecimals={false}
                          hide={hideAxis}
                          fontSize={globalTheme.fontSize.xsmall}
                          tickFormatter={
                            (tick) => Intl.NumberFormat('fr', {
                              notation: 'compact',
                              compactDisplay: 'short',
                            }).format(tick)
                          }
                          label={{
                            value: yAxisLabel,
                            angle: -90,
                            position: 'insideLeft',
                            style: {
                              fontSize: globalTheme.fontSize.xsmall,
                            },
                          }}
                        />
                        {
                        withTooltip
                        && (
                          <Tooltip
                            // Tooltip is overlapped by the other graph if the height is too big
                            wrapperStyle={{ zIndex: 2 }}
                            cursor={showCursor}
                            content={
                              tooltipContent
                                ? React.cloneElement(tooltipContent, { tooltipLabel })
                                : <TooltipComponent tooltipLabel={tooltipLabel} />
                            }
                          />
                        )
                      }
                        {
                        dataKeys.map((dataKey) => (
                          <Bar
                            dataKey={dataKey.dataKey}
                            fill={dataKey.color || ''}
                            key={`cell-${dataKey.dataKey}-${Math.random() * 10000}`}
                            stackId={dataKey.stackId || null}
                            style={{
                              cursor: handleClick ? 'pointer' : '',
                            }}
                            fillOpacity={opacity[dataKey.dataKey as keyof typeof opacity]}
                            isAnimationActive={playAnimation && isAnimationActive}
                          >
                            {topLabelName && (
                              <LabelList
                                dataKey={topLabelName}
                                position="top"
                                style={{
                                  cursor: handleClick ? 'pointer' : '',
                                  fontSize: globalTheme.fontSize.xsmall,
                                }}
                                fill={globalTheme.palette.info.main}
                                formatter={(value: number) => formatThousands(value)}
                              />
                            )}
                            {centerLabelName && (
                              <LabelList
                                dataKey={percentLabel
                                  ? `${dataKey.dataKey}-percentage`
                                  : dataKey.dataKey}
                                position="center"
                                style={{
                                  fontSize: globalTheme.fontSize.xsmall,
                                }}
                                fill={getTextContrast(dataKey.color)}
                                formatter={
                                  (value: number) => (percentLabel
                                    ? (`${value}%`)
                                    : formatThousands(value)
                                  )
                                }
                              />
                            )}
                            {datas.map((entry: any) => (
                              !dataKey.color
                              && (
                              <Cell
                                fill={setBarColor && setBarColor(entry)}
                                key={`cell-${dataKey.dataKey}-${Math.random() * 10000}`}
                              />
                              )
                            ))}
                          </Bar>
                        ))
                      }
                        {showLegend && (
                        <Legend
                          layout="horizontal"
                          align="center"
                          formatter={formatLegendText}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        />
                        )}
                      </BarChart>
                    </ResponsiveContainer>
                    {
                      radioFilter && React.cloneElement(radioFilter)
                    }
                  </>
                )
                : (
                  (isPageLoading || isLoading)
                  && (
                  <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                    <Spinner />
                  </Box>
                  )
                )}
            </Stack>
          )
          : (
            <ChartErrorHandler
              title={title}
              chartData={dataObject}
              serpAnalysisStatus={serpAnalysisStatus}
              isChartWithoutDatas={isChartWithoutDatas}
              isPageLoading={isPageLoading}
            />
          )
        }
    </StyledStack>
  );
}

export default BarChartComponent;
