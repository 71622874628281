import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import { ANALYSIS_STATUS, NB_MAX_KEYWORD } from 'features/serpAnalysis/CONSTANT';
import REGEX_LABEL from 'utils/formControl';

export interface SerpAnalysisForm {
  key: string;
  label: string;
  keywords: string;
  deviceType: string;
  indexId: number;
  searchEngineId: number
}

const DEFAULT_ANALYSIS: SerpAnalysisForm = {
  key: '',
  label: '',
  keywords: '',
  deviceType: '1',
  indexId: 1,
  searchEngineId: 1,
};

export function newAnalysis(): SerpAnalysisForm {
  return {
    ...DEFAULT_ANALYSIS,
    key: uuidv4(),
  };
}

export const initialValues: SerpAnalysisForm = {
  ...newAnalysis(),
};

export const formAnalysisToAnalysis = (form: SerpAnalysisForm, scopeId: number | null) => {
  const analysisObject = {
    scope_id: scopeId,
    label: form.label.trim(),
    serp_analysis_status_id: ANALYSIS_STATUS.DRAFT,
    device_type_id: parseInt(form.deviceType, 10),
    search_engine_id: form.searchEngineId,
    serp_analysis_index_id: form.indexId,
    keywords: form.keywords
      .split('\n')
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword),
  };
  return analysisObject;
};

export function checkKeywordListFormat(keywordList: string | undefined): boolean {
  return keywordList?.split('\n').find(
    (line) => line !== '' && !line.match(REGEX_LABEL),
  ) === undefined;
}

export function checkKeywordMaxSize(
  keywordList: string | undefined,
  maxSize: number,
): boolean {
  return (
    keywordList === undefined
    || keywordList.split('\n').length <= maxSize
  );
}

export function checkSeparator(keywordList: string | undefined): boolean {
  if (keywordList?.includes(',') || keywordList?.includes(';')) {
    return false;
  }
  return true;
}

export const validationSchema = (
  setHasWrongSeparator: Function,
  forceValidation: boolean,
  isModif: boolean,
) => yup.object().shape({
  label: isModif ? yup.string() : yup.string().required('Ce champ est obligatoire').matches(
    REGEX_LABEL,
    'Au moins une lettre ou un chiffre requis',
  ),
  keywords: yup.string().required('Ce champ est obligatoire').trim()
    .test(
      'check-invalid-characters',
      'Au moins une lettre ou un chiffre requis par mot-clé',
      (value) => checkKeywordListFormat(value),
    )
    .test(
      'check-max-size',
      `Un ajout manuel de mots clés est limité à 500.
      Utilisez l’upload CSV pour contourner cette limite`,
      (value) => checkKeywordMaxSize(value, NB_MAX_KEYWORD),
    )
    .test(
      'check-separator',
      '',
      (value) => {
        if (forceValidation) {
          setHasWrongSeparator(false);
          return true;
        }
        if (!checkSeparator(value)) {
          setHasWrongSeparator(true);
        }
        return true;
      },
    ),
  deviceType: isModif ? yup.string().optional() : yup.string().required('Ce champ est obligatoire'),
  searchEngineId: isModif
    ? yup.number().optional()
    : yup.number().required('Ce champ est obligatoire'),
});
