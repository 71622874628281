import React from 'react';

import {
  Box,
  Typography,
  styled,
  BoxProps,
} from '@mui/material';

import { devicesList } from './CONSTANTS';

const PREFIX = 'DeviceIconBlock';

const classes = {
  text: `${PREFIX}-text`,
  ctn: `${PREFIX}-ctn`,
};

const StyledBox = styled(Box)<BoxProps<'span'>>(({ theme }) => ({
  [`& .${classes.text}`]: {
    fontSize: theme.fontSize.xsmall,
    fontWeight: '400',
    marginLeft: '5px',
  },
  [`&.${classes.ctn}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type IconProps = {
  deviceTypeId: number,
  withText?: boolean,
}

function DeviceIcon(props: IconProps) {
  const { deviceTypeId, withText = false } = props;

  return (
    <StyledBox height={24} className={classes.ctn}>
      {devicesList.find((device) => (
        device.id === deviceTypeId
      ))?.icon || ''}
      {
        withText && (
          <Typography component="span" className={classes.text}>
            {devicesList.find((device) => (
              device.id === deviceTypeId
            ))?.label || ''}
          </Typography>
        )
      }
    </StyledBox>
  );
}

export default DeviceIcon;
