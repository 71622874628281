import * as yup from 'yup';

import Category from 'features/categoryGroups/classes/Category';
import CATEGORY_TYPE_ID from 'features/categoryGroups/CONSTANTS';
import {
  CategoryCreateWithCategoryValues,
  CategoryGroupCreateWithCategories,
  CategoryValueCreateWithCategory,
  CategoryValuesCreatePayload,
} from 'features/categoryGroups/types';

export interface AddCatValuesForm {
  category: number;
  name: string;
  patterns: string;
}

export const initialValues = {
  category: -1,
  name: '',
  patterns: '',
};

export const formPatternsToCategoryValue = (values: string, catValType = 2) => {
  const patternsArray: CategoryValueCreateWithCategory[] = [];
  values
    .split('\n')
    .map((value) => value.trim())
    .filter((value) => value)
    .forEach((value) => {
      const patternObject: CategoryValueCreateWithCategory = {
        value,
        include: true,
        category_value_type_id: catValType,
      };
      patternsArray.push(patternObject);
    });
  return patternsArray;
};

export const createBrandGroupPayload = (scopeId: number, patterns: string) => {
  const payload: CategoryGroupCreateWithCategories = {
    name: 'Requêtes marque',
    scope_id: scopeId,
    category_type_id: CATEGORY_TYPE_ID.brand,
    categories: [
      {
        name: 'Requêtes marque',
        category_group_id: 0,
        category_values: formPatternsToCategoryValue(patterns, 2),
      },
    ],
  };
  return payload;
};

export const createNewCategoryPayload = (patterns: string, name: string, groupId: number) => {
  const payload: CategoryCreateWithCategoryValues = {
    name,
    category_values: formPatternsToCategoryValue(patterns, 2),
    category_group_id: groupId,
  };
  return payload;
};

export const createCategoryValuesPayload = (patterns: string, catId: number, groupId: number) => {
  const payload: CategoryValuesCreatePayload = {
    values: formPatternsToCategoryValue(patterns, 2).map((val) => ({
      ...val,
      category_id: catId,
    })),
    groupId,
  };
  return payload;
};

const checkIsExistingCategory = (value: string, categories: Category[]) => {
  const match = categories.find((cat) => cat.catName.toLowerCase() === value.toLowerCase());
  return match === undefined;
};

export const validationSchema = (categories: Category[]) => yup.object().shape({
  category: yup.number().required('Ce champ est obligatoire'),
  name: yup.string().trim().when('category', {
    is: -1,
    then: (schema) => schema.required('Ce champ est obligatoire').test(
      'check-category-exists',
      'Cette catégorie est déjà présente dans la liste déroulante ci-dessus',
      (value) => checkIsExistingCategory(value, categories),
    ),
    otherwise: (schema) => schema,
  }),
  patterns: yup.string().required('Ce champ est obligatoire').trim(),
});

export const validationSchemaBrand = yup.object().shape({
  patterns: yup.string().required('Ce champ est obligatoire').trim(),
});
