import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface State {
  isOpen: boolean | undefined;
}

const initialState: State = {
  isOpen: undefined,
};

export const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    handleNavbar: (state: State, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
});

export const {
  handleNavbar,
} = navbarSlice.actions;

export default navbarSlice.reducer;
