const SEARCH_ENGINES = {
  google: {
    id: 1,
    name: 'google',
    label: 'Google',
    logo: '/logo-google.png',
  },
  bing: {
    id: 2,
    name: 'bing',
    label: 'Bing',
    logo: '/logo-bing.png',
  },
};

export default SEARCH_ENGINES;
