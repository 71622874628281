import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import { RootState } from 'common/store';

import { fetchSerpAnalysisIndexRequested } from './slice';

const useFetchSaIndex = () => {
  const dispatch = useAppDispatch();

  const { isLoading, error, serpAnIndexes } = useAppSelector(
    (state: RootState) => state.serpAnalysisIndex,
  );

  useEffect(() => {
    if (serpAnIndexes.length === 0) {
      dispatch(fetchSerpAnalysisIndexRequested());
    }
  }, [dispatch, fetchSerpAnalysisIndexRequested]);

  const sortedSerpAnIndexes = serpAnIndexes ? [...serpAnIndexes] : [];
  sortedSerpAnIndexes?.sort((a, b) => (
    a.country.localeCompare(b.country)
  ));

  return {
    isLoading,
    error,
    serpAnIndexes: sortedSerpAnIndexes,
  };
};

export default useFetchSaIndex;
