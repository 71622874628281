import React from 'react';

import { SelectInputProps } from '@mui/material/Select/SelectInput';

import globalTheme from 'common/theme';
import DropDown from 'components/dropDown/DropDown';

import useActors from '../state/useActors';

type ActorsDropDownProps = {
  value: number | number[],
  onChange: SelectInputProps<any>['onChange'],
  scopeId: number,
  name?: string,
  multiple?: boolean,
  emptyOption?: string
  size?: 'small' | 'medium',
  margin?: number,
  height?: string,
  width?: string,
  fontSize?: keyof typeof globalTheme.fontSize,
  label?: string,
  doFetch?: boolean,
  className?: {
    select?: string,
  },
  displayEmptyInField?:boolean,
  displayEmptyInList?:boolean,
  noActorsOption?: any,
};

function ActorsDropDown(props: ActorsDropDownProps) {
  const {
    value,
    onChange,
    scopeId,
    name,
    multiple = false,
    emptyOption,
    size,
    margin,
    fontSize,
    height,
    width,
    label,
    doFetch = true,
    className,
    displayEmptyInField,
    displayEmptyInList,
    noActorsOption = undefined,
  } = props;

  const { actors } = useActors({ scopeId, doFetch });
  const sortedOptions: any[] = actors.slice().sort((a, b) => a.name.localeCompare(b.name));
  if (noActorsOption) {
    sortedOptions.unshift(noActorsOption);
  }

  return (
    <DropDown
      name={name ?? 'actors'}
      fullWidth
      value={value}
      options={sortedOptions}
      label={label}
      onChange={onChange}
      getOptionLabel={(actor) => actor.name ?? ''}
      getOptionValue={(actor) => actor.id ?? ''}
      multiple={multiple}
      emptyOption={emptyOption}
      size={size}
      fontSize={fontSize}
      margin={margin}
      height={height}
      width={width}
      className={className}
      displayEmptyInField={displayEmptyInField}
      displayEmptyInList={displayEmptyInList}
      data-testid="select-actor-drop-down"
    />
  );
}

export default ActorsDropDown;
