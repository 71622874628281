import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import ApiRankingTypes from './api';
import {
  fetchRankingTypes,
  fetchRankingTypesSuccess,
  fetchRankingTypesFailure,
} from './slice';
import { RankingTypeType } from '../types';

function* workFetchRankingTypes() {
  try {
    const response: AxiosResponse = yield call(ApiRankingTypes.fetchRankingTypes);
    const rankingTypes: RankingTypeType[] = response.data;
    yield put(fetchRankingTypesSuccess(rankingTypes));
  } catch (e: any) {
    yield put(fetchRankingTypesFailure(e.message));
  }
}

export default function* sagaFetchRankingTypes() {
  yield takeEvery(fetchRankingTypes, workFetchRankingTypes);
}
