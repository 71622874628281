import React from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import {
  Box, Button, BoxProps, styled, CircularProgress,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import smallSpinnerSize from 'components/loading/config';
import { BASE_DATAVIZ_REQUEST } from 'features/dataviz/CONSTANTS';
import { fetchExportData } from 'features/dataviz/state/slice';
import { DatavizComp, DatavizRequest } from 'features/dataviz/types';
import generateChartKey from 'features/dataviz/utils/chartKey';
import formatProjectNameForUrl from 'utils/formatProjectNameForUrl';

const PREFIX = 'ExportAnalysesButton';

const classes = {
  core: `${PREFIX}-core`,
  button: `${PREFIX}-button`,
  spinner: `${PREFIX}-spinner`,
  spinning: `${PREFIX}-spinning`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({

  [`& .${classes.spinner}`]: {
    color: theme.palette.primary.light,
  },

  [`& .${classes.spinning}`]: {
    backgroundColor: theme.palette.secondary.main,
    cursor: 'default',
  },

}));

type ExportAnalysesButtonProps = {
  datavizComp: DatavizComp,
  scopeId: number,
  projectName: string,
  idsSelected: number[],
  disabled?: boolean,
}

function ExportAnalysesButton(props: ExportAnalysesButtonProps): JSX.Element {
  const {
    datavizComp,
    scopeId,
    projectName,
    idsSelected,
    disabled,
  } = props;
  const dispatch = useAppDispatch();

  const chartKey = generateChartKey(
    datavizComp.label,
    [],
    scopeId,
    '',
  );

  const isExportLoading: boolean | undefined = useAppSelector(
    (state) => state.dataviz.exports[chartKey]?.isLoading,
  );
  const handleExport = () => {
    const payload: DatavizRequest = {
      key: chartKey,
      exportName: `etude-mots-cles-${formatProjectNameForUrl(projectName)}`,
      requests: datavizComp.metrics.map((metric) => ({
        metric: metric.metric,
        resource: metric.resource,
        payload: {
          ...BASE_DATAVIZ_REQUEST,
          scope_id: scopeId,
          serp_analysis_ids: idsSelected,
          sep: 'semicolon',
        },
      })),
    };
    dispatch(fetchExportData(payload));
  };

  return (
    <StyledBox>
      <Button
        onClick={() => handleExport()}
        variant="contained"
        size="small"
        disabled={isExportLoading || disabled}
        className={
          isExportLoading
            ? classes.spinning
            : classes.button
        }
        startIcon={<DownloadIcon />}
        data-testid="export-serp-analysis-button"
      >
        {
          isExportLoading
            ? (
              <CircularProgress
                className={classes.spinner}
                size={smallSpinnerSize}
              />
            )
            : 'Télécharger les mots clés'
        }
      </Button>
    </StyledBox>
  );
}

export default ExportAnalysesButton;
