import React, { useState } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FormikProps, useFormik } from 'formik';
import moment from 'moment';

import { useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import { DatavizFilters } from 'features/dataviz/types';
import { DEVICE_TYPE } from 'features/deviceType/CONSTANTS';
import { selectSerpAnalysesByScopeId } from 'features/serpAnalysis/state/slice';
import { RankingEvolutionFilters } from 'features/serpAnalysis/types';

import { KPI_VIEW_TYPE } from '../CONSTANTS';
import { initValuesAddView, validationSchemaAddView } from './FormConfig/FormAddView';
import useKpiViewByAnalysis from '../state/useKpiViewByAnalysis';
import { AddKpiViewFormType, KpiViewCreateUpdate } from '../types';

type AddViewProps = {
  analysisId: number,
  scopeId: number,
  rankingEvolutionFilters: RankingEvolutionFilters | DatavizFilters | undefined
  kpiViewType: number,
  isCurrentUserExternal?: boolean,
  isCurrentUserConsultant?: boolean,
}

function getLabelsFromKpiViewType(kpiViewType: number) {
  const labels = {
    [KPI_VIEW_TYPE.tracking.id]: {
      label: 'Enregistrer la vue',
      title: (
        <Typography>
          <Typography component="span" variant="bold">
            Enregistrer
          </Typography>
          {' '}
          la vue pour le suivi KPI
        </Typography>
      ),
    },
    [KPI_VIEW_TYPE.serpTracking.id]: {
      label: 'Suivre la SERP',
      title: (
        <Typography>
          <Typography component="span" variant="bold">
            Suivre
          </Typography>
          {' '}
          les SERP dans Looker Studio
        </Typography>
      ),
    },
  };

  if (Object.keys(labels).includes(kpiViewType.toString())) {
    return labels[kpiViewType as keyof typeof labels];
  }

  return {
    label: '',
    title: '',
  };
}

export default function AddViewModal (props: AddViewProps): JSX.Element {
  const {
    analysisId,
    scopeId,
    rankingEvolutionFilters,
    kpiViewType,
    isCurrentUserExternal = false,
    isCurrentUserConsultant,
  } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);

  const serpAnalyses = useAppSelector((state) => (
    selectSerpAnalysesByScopeId(state.serpAnalyses, scopeId)));

  const labels = getLabelsFromKpiViewType(kpiViewType);

  const { createKpiView } = useKpiViewByAnalysis(
    scopeId,
    analysisId,
    [KPI_VIEW_TYPE.tracking.id],
  );

  const handleSubmit = (values: AddKpiViewFormType) => {
    const newKpiView: KpiViewCreateUpdate = {
      label: values.viewName,
      time_serie: values.timeSerie,
      start_date: values.startDate,
      scope_id: scopeId,
      kpi_view_export_id: (values.export && !isCurrentUserExternal) ? 2 : 1,
      kpi_view_export_status_id: 1,
      kpi_view_type_id: kpiViewType,
      best_rank: false,
      kpi_view_categories: [],
      device_type_id: (
        (
          rankingEvolutionFilters
          && rankingEvolutionFilters.type === 'rankingEvolutionFilters'
        )
          ? rankingEvolutionFilters.deviceTypeId
          : DEVICE_TYPE.MOBILE
      ),
      kpi_view_serp_analyses: values.serpAnalysisIds.map((id) => ({
        serp_analysis_id: id,
      })),
      kpi_view_actors: (
        (
          rankingEvolutionFilters
          && rankingEvolutionFilters.type === 'rankingEvolutionFilters'
          && rankingEvolutionFilters.actorIds
        )
          ? rankingEvolutionFilters?.actorIds.map((id: number) => ({
            actor_id: id,
          }))
          : []
      ),
      kpi_view_keywords: (
        (
          rankingEvolutionFilters
          && rankingEvolutionFilters.type === 'rankingEvolutionFilters'
          && rankingEvolutionFilters.keywordIds
          && values.serpAnalysisIds.length <= 1
          && kpiViewType === KPI_VIEW_TYPE.tracking.id
        )
          ? rankingEvolutionFilters?.keywordIds.map((id) => ({
            keyword_id: id,
          }))
          : []
      ),
      kpi_view_sa_rank_slices: (
        (
          rankingEvolutionFilters
          && rankingEvolutionFilters.type === 'rankingEvolutionFilters'
          && rankingEvolutionFilters.rankSliceIds
        )
          ? rankingEvolutionFilters?.rankSliceIds.map((id) => ({
            sa_rank_slice_id: id,
          }))
          : []
      ),
      kpi_view_sa_keyword_intentions: (
        (
          rankingEvolutionFilters
          && rankingEvolutionFilters.type === 'rankingEvolutionFilters'
          && rankingEvolutionFilters.intentionIds
          && values.serpAnalysisIds.length <= 1
          && kpiViewType === KPI_VIEW_TYPE.tracking.id
        )
          ? rankingEvolutionFilters?.intentionIds.map((id) => ({
            sa_keyword_intention_id: id,
          }))
          : []
      ),
      kpi_view_ranking_types: (
        (
          rankingEvolutionFilters
          && rankingEvolutionFilters.type === 'rankingEvolutionFilters'
          && rankingEvolutionFilters.rankingTypeId
          && rankingEvolutionFilters.rankingTypeId.length > 0
        )
          ? rankingEvolutionFilters?.rankingTypeId.map((id) => ({
            ranking_type_id: id,
          }))
          : [{
            ranking_type_id: 1,
          }]
      ),
    };
    createKpiView(newKpiView);
    setOpenModal(false);
  };

  const formik: FormikProps<AddKpiViewFormType> = useFormik<AddKpiViewFormType>({
    initialValues: initValuesAddView(analysisId, kpiViewType),
    validationSchema: validationSchemaAddView,
    onSubmit: handleSubmit,
  });

  return (
    <Box>
      <Button
        variant="contained"
        onClick={() => setOpenModal(true)}
        color="primary"
        size="small"
        startIcon={<AddCircleOutlineIcon />}
        data-testid="button-add-kpi-view"
        disabled={isCurrentUserConsultant && isCurrentUserExternal}
      >
        {labels.label}
      </Button>
      <Modal
        title={labels.title}
        actionConfirm={() => {
          formik.handleSubmit();
        }}
        actionCancel={() => setOpenModal(false)}
        isOpen={openModal}
      >
        <Stack
          sx={{ padding: '5px', width: '100%' }}
          component="form"
          direction="column"
          spacing={3}
          data-testid="modal-add-kpi-view-ranking"
        >
          <FormControl>
            <TextField
              label="Nom de la vue"
              type="text"
              name="viewName"
              variant="outlined"
              size="small"
              sx={{
                fontSize: globalTheme.fontSize.xsmall,
              }}
              slotProps={{
                input: {
                  sx: {
                    fontSize: globalTheme.fontSize.xsmall,
                  },
                },
                inputLabel: {
                  sx: {
                    fontSize: globalTheme.fontSize.xsmall,
                  },
                },
              }}
              value={formik.values.viewName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.viewName
                && Boolean(formik.errors.viewName)
              }
              helperText={
                formik.touched.viewName
                && formik.errors.viewName
              }
            />
          </FormControl>

          <FormControl>
            <InputLabel id="addView-serpAnalysis-label" shrink>Catégorie(s)</InputLabel>
            <Select
              multiple
              value={formik.values.serpAnalysisIds}
              onChange={
                (e) => (
                  Array.isArray(e.target.value)
                  && e.target.value.includes(analysisId)
                  && formik.handleChange(e)
                )
              }
              size="small"
              labelId="addView-serpAnalysis-label"
              label="Catégorie(s)"
              name="serpAnalysisIds"
              data-testid="addView-select-multiple-serp-analysis"
              notched
              renderValue={
                (saIds) => serpAnalyses.filter((sa) => (
                  saIds.find((id) => sa.id === id)
                )).map((sa) => (
                  <Chip
                    size="small"
                    key={`chip${sa.id}`}
                    label={sa.label}
                    sx={{ marginRight: '5px' }}
                  />
                ))
              }
            >
              {serpAnalyses.map((serpAnalysis) => (
                <MenuItem
                  key={serpAnalysis.id}
                  value={serpAnalysis.id}
                  dense
                  disableGutters
                  sx={{ height: '1rem' }}
                >
                  <Checkbox
                    checked={formik.values.serpAnalysisIds?.includes(serpAnalysis.id)}
                    size="small"
                    disabled={serpAnalysis.id === analysisId}
                  />
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ fontSize: globalTheme.fontSize.xsmall }}
                  >
                    {serpAnalysis.label}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {
                formik.values.serpAnalysisIds.length > 1
                && (
                  <Typography variant="bold">
                    Attention : Les filtres de mots clés et d&apos;intentions de recherche
                    ne sont pas sauvegardés lorsque vous choisissez plusieurs catégories
                  </Typography>
                )
              }
            </FormHelperText>
          </FormControl>
          <Stack direction="row" spacing={2}>
            <FormControl sx={{ flex: 1 }}>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={moment.locale('fr')}
              >
                <DatePicker
                  label="Démarrage"
                  value={moment(formik.values.startDate)}
                  onChange={
                    (value) => formik.setFieldValue(
                      'startDate',
                      value && moment(value).isValid()
                        ? value.format()
                        : moment().startOf('day').format(),
                    )
                  }
                  format="DD/MM/YYYY"
                  slotProps={{
                    textField: {
                      size: 'small',
                      label: 'Démarrage',
                      error: false,
                      InputProps: {
                        sx: {
                          fontSize: globalTheme.fontSize.xsmall,
                        },
                      },
                      value: moment(formik.values.startDate),
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <InputLabel id="addView-timeSerie-label" shrink>Vue</InputLabel>
              <Select
                value={formik.values.timeSerie}
                onChange={formik.handleChange}
                size="small"
                labelId="addView-timeSerie-label"
                label="Vue"
                name="timeSerie"
                notched
                sx={{
                  fontSize: globalTheme.fontSize.xsmall,
                }}
                disabled={kpiViewType === KPI_VIEW_TYPE.serpTracking.id}
              >
                <MenuItem
                  key="month"
                  value="month"
                  dense
                  sx={{ height: '1rem' }}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      fontSize: globalTheme.fontSize.xsmall,
                    }}
                  >
                    Mensuelle
                  </Typography>
                </MenuItem>
                <MenuItem
                  key="day"
                  value="day"
                  dense
                  sx={{ height: '1rem' }}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      fontSize: globalTheme.fontSize.xsmall,
                    }}
                  >
                    Quotidienne
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
          {
            !isCurrentUserExternal && (
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={formik.values.export}
                  />
                )}
                label={(
                  <Typography variant="body2">
                    Exporter les données pour looker studio
                  </Typography>
                )}
                name="export"
                onChange={formik.handleChange}
                disabled={kpiViewType === KPI_VIEW_TYPE.serpTracking.id}
                data-testid="addView-checkbox-export"
              />
            )
          }
        </Stack>
      </Modal>
    </Box>
  );
}
