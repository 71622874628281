import React from 'react';

import {
  Box,
  BoxProps,
  styled,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import CheckBoxDataGrid from 'components/checkbox/CheckBoxDataGrid';
import LinearLoadingOverlay from 'components/loading/LinearLoadingOverlay';
import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';
import TextNavLinkDataGrid
  from 'features/dataviz/components/generic/datagrid/textNavLinkDataGrid/TextNavLinkDataGrid';
import formatProjectNameForUrl from 'utils/formatProjectNameForUrl';

import CategoryGroupsActions from '../actions/CategoryGroupsActions';
import CATEGORY_TYPE_ID from '../CONSTANTS';
import useCategoryGroups from '../state/useCategoryGroups';
import { CategoryGroup as CategoryGroupType } from '../types';

const PREFIX = 'CategoryGroupsDataGrid';

const classes = {
  labels: `${PREFIX}-labels`,
  text: `${PREFIX}-text`,
  icon: `${PREFIX}-icon`,
  exclude: `${PREFIX}-exclude`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.labels}`]: {
    color: theme.palette.info.main,
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.text}`]: {
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.icon}`]: {
    fontSize: '1.2rem',
  },
  [`& .${classes.exclude}`]: {
    color: theme.palette.error.main,
    fontSize: theme.fontSize.medium,
  },
}));

type CategoryGroupsDatagridProps = {
  scopeId: number,
  handleSelect: Function,
}

function CategoryGroupsDatagrid(props: CategoryGroupsDatagridProps) {
  const { scopeId, handleSelect } = props;
  const { isLoading, categoryGroups } = useCategoryGroups(scopeId, CATEGORY_TYPE_ID.url);

  const columns: GridColDef<CategoryGroupType>[] = [
    {
      field: 'label',
      headerClassName: 'super-app-theme--header',
      renderHeader: () => (
        <StyledHeader label="Catégories" />
      ),
      renderCell: (params) => (
        <TextNavLinkDataGrid
          link={`${params.row.id}-${
            formatProjectNameForUrl(params.value)}`}
          label={params.value}
        />
      ),
      valueGetter: (value, row) => row.name,
      flex: 3,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      flex: 1.5,
      align: 'right',
      renderCell: (params) => (
        <CategoryGroupsActions
          scopeId={scopeId}
          catGroup={params.row}
        />
      ),
    },
  ];
  return (
    <StyledBox>
      <DataGrid
        hideFooterSelectedRowCount
        disableRowSelectionOnClick
        disableColumnMenu
        checkboxSelection
        autoHeight
        disableVirtualization
        getRowHeight={() => 50}
        getRowId={(row) => row.id}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
          sorting: {
            sortModel: [{ field: 'label', sort: 'asc' }],
          },
        }}
        slots={{
          loadingOverlay: LinearLoadingOverlay,
          baseCheckbox: CheckBoxDataGrid,
        }}
        pageSizeOptions={[25, 50, 100]}
        rows={categoryGroups}
        columns={columns}
        slotProps={{
          pagination: {
            labelRowsPerPage: ('Nombre par page :'),
          },
        }}
        loading={isLoading}
        localeText={{
          noRowsLabel: 'Aucun groupe d\'URL n\'est défini',
        }}
        onRowSelectionModelChange={(keywordsSelected) => {
          handleSelect(keywordsSelected);
        }}
      />
    </StyledBox>
  );
}

export default CategoryGroupsDatagrid;
