import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import {
  Stack,
  styled,
  StackProps,
  Typography,
} from '@mui/material';

const PREFIX = 'SettingBlockView';

const classes = {
  iconSmall: `${PREFIX}-iconSmall`,
  status: `${PREFIX}-status-text`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`& .${classes.iconSmall}`]: {
    width: '20px',
    marginRight: '5px',
  },

  [`& .${classes.status}`]: {
    fontSize: theme.fontSize.xsmall,
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    alignItems: 'center',
  },
}));

type ConfigCheckerProps = {
  isConfigDone: boolean,
}

function ConfigChecker (props: ConfigCheckerProps) {
  const { isConfigDone } = props;
  return (
    <StyledStack width="100%">
      {(
        isConfigDone && (
        <Typography color="green" className={classes.status}>
          <CheckCircleIcon className={classes.iconSmall} />
          Configuration effectuée
        </Typography>
        )
      ) || (
        <Typography color="red" className={classes.status}>
          <DangerousIcon className={classes.iconSmall} />
          Configuration obligatoire
        </Typography>
      )}
    </StyledStack>
  );
}

export default ConfigChecker;
