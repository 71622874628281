import React from 'react';

import {
  Box,
  BoxProps,
  Stack,
  styled,
} from '@mui/material';
import { FormikProps, FormikProvider } from 'formik';

import SelectProjectsDropdown from 'features/projects/components/SelectProjectsDropdown';

import { UpdateUserProjectsForm } from './updateUserprojectsConfig';

const PREFIX = 'CreateModifyExternalUserModal';

const classes = {
  box: `${PREFIX}-box`,
};

const StyledBox = styled(Box)<BoxProps<'form'>>(() => ({
  [`&.${classes.box}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: '2%',
  },
}));

type UpdateUserProjectsModalProps = {
  formik: FormikProps<UpdateUserProjectsForm>,
}

function UpdateUserProjectsModal (props: UpdateUserProjectsModalProps) {
  const { formik } = props;

  return (
    <StyledBox
      data-testid="modal-manage-user-project"
      className={classes.box}
    >
      <FormikProvider value={formik}>
        <Stack gap={3} width="100%">
          <SelectProjectsDropdown
            value={
              (formik.values.projectsConsult?.length)
                ? formik.values.projectsConsult
                : ['']
            }
            onChange={formik.handleChange}
            name="projectsConsult"
            label="Projet(s) consultable(s)"
            size="small"
            multiple
            error={
              Boolean(formik.errors.projectsConsult)
              && Boolean(formik.touched.projectsConsult)
            }
            displayGlobalCheckbox={false}
            emptyOption="Sélectionner un ou plusieurs projets"
            errorText={
              (formik.touched.projectsConsult && formik.errors.projectsConsult)
                ? formik.errors.projectsConsult
                : ''
            }
          />
          <SelectProjectsDropdown
            value={
              formik.values.projectsEdit && formik.values.projectsEdit.length > 0
                ? formik.values.projectsEdit
                : ['']
            }
            onChange={formik.handleChange}
            name="projectsEdit"
            label="Projet(s) modifiable(s)"
            size="small"
            multiple
            error={
              Boolean(formik.errors.projectsEdit)
              && Boolean(formik.touched.projectsEdit)
            }
            displayGlobalCheckbox={false}
            emptyOption="Sélectionner un ou plusieurs projets"
            errorText={
              (formik.touched.projectsEdit && formik.errors.projectsEdit)
                ? formik.errors.projectsEdit
                : ''
            }
          />
        </Stack>
      </FormikProvider>
    </StyledBox>
  );
}

export default UpdateUserProjectsModal;
