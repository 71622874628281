const USER_TYPES = {
  admin: {
    label: 'admin',
    id: 1,
  },
  dircli: {
    label: 'dircli',
    id: 2,
  },
  internal: {
    label: 'utilisateur',
    id: 3,
  },
  external: {
    label: 'client',
    id: 4,
  },
};

export const PREMIERE_PLACE_DOMAIN = 'premiere.place';

export default USER_TYPES;
