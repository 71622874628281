import React, { useCallback, useEffect, useState } from 'react';

import {
  Button,
  Grid2 as Grid,
  styled,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import { Project } from 'features/projects/types';

import columns from './columns';
import TransactionDropDown from '../components/TransactionDropDown';
import { fetchTransactions } from '../state/slice';

const PREFIX = 'project-transaction-datagrid';

const classes = {
  cancelButton: `${PREFIX}-cancelButton`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.cancelButton}`]: {
    color: theme.palette.primary.light,
  },
}));

type ProjectTransactionsDataGridProps = {
  project?: Project,
}

function ProjectTransactionsDataGrid(props: ProjectTransactionsDataGridProps) {
  const { project } = props;
  const [selectTransactionType, setSelectTransactionType] = useState<number | ''>(0);
  const [fromDate, setFromDate] = useState<Moment | null>(null);
  const [toDate, setToDate] = useState<Moment | null>(null);

  const dispatch = useAppDispatch();

  const { transactions, isLoading } = useAppSelector(
    (state) => state.transaction,
  );

  const handleResetFilters = () => {
    setSelectTransactionType(0);
    setFromDate(null);
    setToDate(null);
  };

  const filterRows = useCallback(
    () => transactions.filter((t) => (
      (
        !selectTransactionType
        || selectTransactionType === t.transaction_type.id
      ) && (
        !fromDate
        || fromDate.isSameOrBefore(t.insertion_date)
      ) && (
        !toDate
        || toDate.isSameOrAfter(t.insertion_date)
      )
    )),
    [selectTransactionType, transactions, fromDate, toDate],
  );

  useEffect(() => {
    dispatch(fetchTransactions({ projectId: project?.id ?? 0 }));
  }, [project]);

  return (
    <StyledGrid container direction="column">
      <Grid container direction="row" spacing={2}>
        <Grid size={3}>
          <TransactionDropDown
            value={selectTransactionType}
            onChange={(e) => setSelectTransactionType(e.target.value)}
          />
        </Grid>
        <Grid>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={moment.locale('fr')}
          >
            <DatePicker
              label="Date de début"
              value={fromDate && moment(fromDate)}
              onChange={(val) => setFromDate(val?.startOf('day') ?? null)}
              format="DD/MM/YYYY"
              name="start-date-transaction"
              maxDate={toDate ?? undefined}
              slotProps={{
                textField: {
                  size: 'small',
                  InputProps: {
                    sx: {
                      fontSize: globalTheme.fontSize.medium,
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={moment.locale('fr')}
          >
            <DatePicker
              label="Date de fin"
              value={toDate && moment(toDate)}
              onChange={(val) => setToDate(val?.endOf('day') ?? null)}
              format="DD/MM/YYYY"
              name="end-date-transaction"
              minDate={fromDate ?? undefined}
              slotProps={{
                textField: {
                  size: 'small',
                  InputProps: {
                    sx: {
                      fontSize: globalTheme.fontSize.medium,
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid>
          <Button
            data-testid="save-filter-cancel"
            onClick={handleResetFilters}
            className={classes.cancelButton}
          >
            Réinitialiser
          </Button>
        </Grid>
      </Grid>
      <Grid mr={2}>
        <DataGrid
          rows={filterRows()}
          columns={columns}
          density="compact"
          loading={isLoading}
          disableColumnMenu
          autoHeight
          disableVirtualization
          disableRowSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: 'insertion_date', sort: 'desc' }],
            },
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          slotProps={{
            pagination: {
              labelRowsPerPage: ('Nombre par page :'),
            },
          }}
          localeText={{
            noRowsLabel: 'Aucune transaction n\'a été effectuée',
          }}
          sx={{ width: 'auto' }}
        />
      </Grid>
    </StyledGrid>
  );
}

export default ProjectTransactionsDataGrid;
