import React from 'react';

import {
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

import globalTheme from 'common/theme';

import useFetchSaIndex from '../state/useFetchSaIndex';

type SelectIndexProps = {
  values: number | string,
  handleChange: any,
}

function SelectIndex (props: SelectIndexProps) {
  const { serpAnIndexes } = useFetchSaIndex();
  const { values, handleChange } = props;

  return (
    <FormControl>
      <InputLabel htmlFor="indexId">Géolocalisation</InputLabel>
      <Select
        label="Géolocalisation"
        size="small"
        value={serpAnIndexes.length > 0 ? `${values}` : ''}
        onChange={handleChange}
        name="indexId"
        id="indexId"
      >
        {serpAnIndexes && serpAnIndexes.length > 0 && serpAnIndexes.map((index) => (
          <MenuItem
            key={index.id}
            value={index.id}
            data-testid="index_option"
          >
            <Typography sx={{ fontSize: globalTheme.fontSize.medium, textAlign: 'left' }}>
              {`${index.country} / ${index.language}`}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectIndex;
