import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';

import { useAppDispatch } from 'common/reduxHooks';
import Modal from 'components/modal/Modal';
import { KPI_VIEW_EXPORT_STATUS } from 'features/kpiView/CONSTANTS';
import useKpiViewByType from 'features/kpiView/state/useKpiViewByType';
import { KpiViewActionsWording, KpiViewType } from 'features/kpiView/types';

import KpiView from '../../KpiView';

type DownloadViewProps = {
  kpiView: KpiViewType,
  handleCloseMenu: Function,
  wordings?: KpiViewActionsWording,
}

function DownloadView(props: DownloadViewProps) {
  const dispatch = useAppDispatch();
  const { handleCloseMenu, kpiView, wordings } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [internalLoading, setInternalLoading] = useState(false);

  const kpiViewObj = new KpiView(kpiView);
  const isExported = kpiView?.kpi_view_export_status_id === KPI_VIEW_EXPORT_STATUS.exported.id;

  const { isLoading } = useKpiViewByType(kpiView.scope_id, [kpiView.kpi_view_type_id]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (kpiViewObj) {
      kpiViewObj.download(dispatch);
      setInternalLoading(true);
    }
  };

  useEffect(() => {
    if (!isLoading && internalLoading) {
      setInternalLoading(false);
      setOpenModal(false);
    }
  }, [isLoading, internalLoading]);

  const ConditionnalTooltip = useCallback(({ children }: any) => {
    if (isExported) {
      return children;
    }

    return (
      <Tooltip title={KPI_VIEW_EXPORT_STATUS.toExport.helper} arrow>
        <span>
          {children}
        </span>
      </Tooltip>
    );
  }, [kpiView]);

  return (
    <Box>
      <ConditionnalTooltip>
        <MenuItem
          disabled={!isExported}
          onClick={() => { setOpenModal(true); handleCloseMenu(); }}
        >
          <Typography variant="body2">
            {wordings?.download?.button ?? 'Télécharger la vue'}
          </Typography>
        </MenuItem>
      </ConditionnalTooltip>
      <Modal
        title={wordings?.download?.modalTitle ?? (
          <Typography>
            <Typography component="span" variant="bold">
              Télécharger
            </Typography>
            {' '}
            cette vue KPI
          </Typography>
        )}
        actionConfirm={handleSubmit}
        actionCancel={() => setOpenModal(false)}
        isOpen={openModal}
        isProcessLoading={internalLoading}
        displayLoading={isLoading}
      />
    </Box>
  );
}

export default DownloadView;
