import * as React from 'react';

import LayersIcon from '@mui/icons-material/Layers';
import {
  Box,
  styled,
  BoxProps,
  TextField,
  Button,
  Avatar,
} from '@mui/material';

import { AddSerpAnalysisDataFileFormData }
  from 'features/dataFiles/actions/AddSerpAnalysisDataFile/types';

const PREFIX = 'UploadInput';

const classes = {
  container: `${PREFIX}-container`,
  inputUploadForm: `${PREFIX}-inputUploadForm`,
  avatar: `${PREFIX}-avatar`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.container}`]: {
  },

  [`& .${classes.inputUploadForm}`]: {
    flex: 0.7,
    margin: '5px 0',
    width: '100%',
    display: 'flex',
  },

  [`& .${classes.avatar}`]: {
    backgroundColor: theme.palette.primary.light,
  },

}));

type UploadInputProps = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  form: AddSerpAnalysisDataFileFormData,
  multiple?: boolean,
  accept?: string,
}

function UploadInput(props: UploadInputProps): JSX.Element {
  const {
    form,
    handleChange,
    multiple = false,
    accept,
  } = props;

  return (
    <StyledBox>
      <Box className={classes.inputUploadForm}>
        <TextField
          label=""
          variant="standard"
          fullWidth
          value={form.file.value.replace('C:\\fakepath\\', '')}
          error={form.file.error}
          sx={{
            '& .MuiInputBase-input': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
          helperText={
            form.file.error
            && form.file.error_label
          }
          data-testid="upload-input-text-field"
        />
        <Button
          component="label"
        >
          <Avatar className={classes.avatar}>
            <LayersIcon />
          </Avatar>
          <input
            id="uploader"
            title="uploader"
            type="file"
            name="file"
            hidden
            onChange={handleChange}
            multiple={multiple}
            accept={accept}
          />
        </Button>
      </Box>
    </StyledBox>
  );
}

export default UploadInput;
